/* eslint-disable */
import classNames from "classnames/bind";
import React from "react";
import { Tenants } from "./types";

import s from "./CountryFlag.module.css";

const cx = classNames.bind(s);

type Props = {
  country?: Tenants;
  className?: string;
};

function CountryFlag({ className, country = "de" }: Props) {
  const de = (
    <g>
      <path fill="#FFCE05" d="M0,15.3h27V23H0V15.3z" />
      <path fill="#020203" d="M0,0h27v7.7H0V0z" />
      <path fill="#DD0C15" d="M0,7.7h27v7.7H0V7.7z" />
    </g>
  );

  const at = (
    <g>
      <path fill="#FFFFFF" d="M27,23H0V0h27V23z" />
      <path fill="#C91430" d="M27,23H0v-7.7h27V23z M27,7.7H0V0h27V7.7z" />
    </g>
  );

  const ch = (
    <g>
      <path className={s.ch1} d="M0,0h27v23H0V0z" />
      <g>
        <path className={s.ch2} d="M7.2,9.6h12.7v3.8H7.2V9.6z" />
        <path className={s.ch2} d="M11.6,5.2h3.8v12.7h-3.8V5.2z" />
      </g>
    </g>
  );

  const nl = (
    <g>
      <path fill="#22478C" d="M0,0h27v23H0V0z" />
      <path fill="#FFFFFF" d="M0,0h27v15.3H0V0z" />
      <path fill="#AF1E29" d="M0,0h27v7.7H0V0z" />
    </g>
  );

  const globe = (
    <g>
      <rect y="0" fill="#2887CE" width="27" height="23" />
      <path
        fill="#FFFFFF"
        d="M19.9,14.3c1.4-0.5,2.9-1,4.4-1.3c0.2,3,1.2,5.8,2.6,8.2v-2.3c-0.9-1.9-1.4-3.9-1.6-6.1
      c0.5-0.1,1.1-0.2,1.6-0.2v-1.2c-0.5,0.1-1.1,0.1-1.6,0.2c0-0.9,0-1.8,0.1-2.7c0.3-1.1,0.6-2.3,1-3.4c0.2,0,0.4-0.1,0.6-0.1V4.2
      h-0.1c0-0.1,0.1-0.2,0.1-0.3V1.6c-0.5,0.9-1.1,1.9-1.4,2.8c-1.6,0.3-3.1,0.9-4.5,1.4c1.1-2.4,2.8-4.4,4.9-5.9h-4.4
      c-3.5,2.1-6,5.7-6.7,10.1c-0.8,5,1.1,9.7,4.5,12.9h2.6c-2.6-1.6-4.4-4.3-5.3-7.2c0.8-0.3,1.5-0.8,2.3-1.1c0.5,3.2,2.2,6,4.4,8.3
      h1.6C22.4,20.8,20.5,17.7,19.9,14.3z M18.8,9.4c-0.2,1.3-0.2,2.7-0.1,4.1c-0.9,0.3-1.6,0.7-2.4,1c-0.2-1.3-0.2-2.7,0-4.2
      c0.1-0.3,0.1-0.7,0.2-1c0.9-0.4,1.6-1,2.6-1.3C19,8.4,18.9,8.9,18.8,9.4z M17.1,7.6c1.2-3,3.3-5.3,5.9-6.7
      c-1.5,1.5-2.7,3.4-3.5,5.5C18.7,6.8,17.8,7.2,17.1,7.6z M19.9,9.6c0.1-0.8,0.3-1.5,0.5-2.2c1.5-0.7,3-1.1,4.8-1.5
      c-0.3,0.9-0.5,1.9-0.6,2.8c-0.1,1.1-0.2,2.1-0.2,3.1c-1.6,0.3-3.1,0.8-4.5,1.2C19.7,11.9,19.7,10.7,19.9,9.6z"
      />
    </g>
  );

  const int_de = (
    <g>
      {globe}
      <path
        fill="#FFFFFF"
        d="M8.5 6c0 1.2-0.3 2.1-1 2.7S5.9 9.7 4.6 9.7h-2V2.5h2.2c1.2 0 2.1 0.3 2.7 0.9S8.5 4.9 8.5 6zM6.9 6.1c0-1.5-0.7-2.3-2-2.3H4.1v4.6h0.6C6.2 8.4 6.9 7.6 6.9 6.1zM13.6 9.7H9.5V2.5h4.1v1.2H11v1.6h2.4v1.2H11v1.8h2.6V9.7z"
      />
    </g>
  );

  const com = (
    <g>
      {globe}
      <path
        fill="#FFFFFF"
        d="M6.8 9.4H2.7V2.2h4.1v1.2H4.2V5h2.4v1.2H4.2v1.8h2.6V9.4zM14.1 9.4h-1.9L9.1 4h0c0.1 1 0.1 1.6 0.1 2v3.4H7.8V2.2h1.9l3.1 5.3h0c0-0.9-0.1-1.6-0.1-2V2.2h1.4V9.4z"
      />
    </g>
  );

  const uk = (
    <g>
      <path fill="#232F5D" d="M0,0h27v23H0V0z" />
      <path
        fill="#FFFFFF"
        d="M3.2,0l10.3,8.7L23.7,0H27v3l-10.1,8.6L27,20.1V23h-3.4l-10.1-8.6L3.4,23H0v-2.9l10.1-8.5L0,3.1V0H3.2z"
      />
      <path
        fill="#C91430"
        d="M17.9,13.5l9.1,7.6V23l-11.4-9.5H17.9z M10.1,14.4l0.3,1.7L2.3,23H0L10.1,14.4z M27,0v0.1l-10.5,9L16.6,7l8.3-7
    H27z M0,0l10.1,8.4H7.6L0,2V0z"
      />
      <path fill="#FFFFFF" d="M10.2,0v23h6.7V0H10.2z M0,7.7v7.7h27V7.7H0z" />
      <path fill="#C91430" d="M0,9.2v4.6h27V9.2H0z M11.5,0v23h4.1V0H11.5z" />
    </g>
  );

  const dk = (
    <g>
      <path fill="#C91430" d="M0,0h27v23H0V0z" />
      <path fill="#FFFFFF" d="M8.68,0h2.89v23H8.68V0z" />
      <path fill="#FFFFFF" d="M0,9.86h27v3.29H0V9.86z" />
    </g>
  );

  const lu = (
    <g>
      <path fill="#16A1DC" d="M0,11.53h27V23H0V11.53z" />
      <path fill="#E72E3C" d="M0,0.06h27v11.47H0V0.06z" />
      <path fill="#FFFFFF" d="M0,7.71h27v7.65H0V7.71z" />
    </g>
  );

  const fr = (
    <g>
      <path fill="#FFFFFF" d="M0,0h27v23H0V0z" />
      <path fill="#1D2C4E" d="M0,0h9v23H0V0z" />
      <path fill="#CE1327" d="M18,0h9v23h-9V0z" />
    </g>
  );

  const be = (
    <g>
      <path fill="#020203" d="M0,0h9v23H0V0z" />
      <path fill="#FFD910" d="M9,0h9v23H9V0z" />
      <path fill="#E62736" d="M18,0h9v23h-9V0z" />
    </g>
  );

  const mag = (
    <g>
      <path
        fill="#231f20"
        d="M 3.9508075,20.812364 V 2.714886 c 0,-1.0279583 0.8385976,-1.4066798 1.5960407,-1.4878345 h 1.9747622 c 1.3255253,0 1.7042468,1.0820615 1.9206591,1.5419376 l 1.8936075,3.3543906 c 0.568082,1.0820615 0.892701,0.70334 1.40668,0.054103 l 2.46169,-3.8954214 c 0.568082,-0.919752 1.541937,-1.0279582 1.920658,-1.0279582 L 21.967131,1.2 c 0.973855,0 1.082061,1.3525769 1.082061,2.7051537 V 20.37954 c 0,2.055917 -1.352576,1.785402 -1.974761,1.785402 h -1.920659 c -0.973857,0 -1.271424,-0.784495 -1.325526,-1.352578 V 4.0674629 c 0,-1.3525769 -0.513979,-0.7844946 -1.136165,-0.054104 l -3.976575,6.2218541 c -0.568083,0.919753 -1.704247,0.270516 -2.11002,-0.4328242 L 7.4945589,3.7428445 C 7.251095,3.3100199 6.3042912,2.8501437 6.4395489,4.2568237 V 20.947622 C 6.2231366,22.462508 4.1131168,22.814178 3.9508075,20.812364 v 0"
      />
    </g>
  );

  const dictionary = {
    at,
    ch,
    de,
    nl,
    com,
    int_de,
    uk,
    gb: uk,
    intde: int_de,
    dk,
    lu,
    fr,
    be,
    mag,
  };

  const classes = cx(cx("root"), className);

  return (
    <svg className={classes} viewBox="0 0 27 23">
      <rect fill="#FFFFFF" height="23" width="27" />
      {dictionary[country.replace("-", "")]}
    </svg>
  );
}

export default CountryFlag;
