// ase
import backendUrl from "../../pages/utils/AseBackendUrls";
import {
  ORDER_HISTORY_DETAILS_FRAGMENT__LOAD_DATA_STARTED,
  ORDER_HISTORY_DETAILS_FRAGMENT__LOAD_DATA_SUCCESS,
} from "../../pages/constants";

export default function getFragment(apiClient, orderKey) {
  return [
    {
      type: ORDER_HISTORY_DETAILS_FRAGMENT__LOAD_DATA_STARTED,
    },
    apiClient.get(backendUrl.orderHistoryDetails(orderKey), {
      ok: (content) => ({
        payload: content.body,
        type: ORDER_HISTORY_DETAILS_FRAGMENT__LOAD_DATA_SUCCESS,
      }),
    }),
  ];
}
