import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import Text from "stelar/components/Text";

import s from "./Flag.module.css";

const cx = classnames.bind(s);

function Flag({ children, size, variant, ...attrs }) {
  if (!children) return null;
  return (
    <div
      className={cx("root", { [size]: true, [variant]: true })}
      data-test-sell-flag
      {...attrs}
    >
      <Text smallbold inverted>
        {children}
      </Text>
    </div>
  );
}

Flag.propTypes = {
  children: PropTypes.string,
  size: PropTypes.oneOf(["small", "large", "long", "x-large", "xx-large"]),
  variant: PropTypes.oneOf(["label", "new", "exclusive", "moasteries"]),
};

Flag.defaultProps = {
  size: "large",
  variant: "label",
};

export default Flag;
