import update from "immutability-helper";
import { validateInput } from "../utils/Validators";

const inputs = [{ inputPath: "email" }];

export default function validateAll(state) {
  let newState = state;
  newState = update(newState, { submitable: { $set: true } });
  inputs.forEach((input) => {
    newState = validateInput(newState, input);
  });
  return newState;
}
