import Modal from "stelar/components/Modal";
import React, { useCallback, useEffect, useState } from "react"; // eslint-disable-line import/no-extraneous-dependencies
import {
  AseCatalogOrderFragment,
  SellKuratorContentFragment,
  SellLayerContentFragment,
} from "../registry";

export const GLOBAL_MODAL_TYPES = {
  CONTENT_FRAGMENT: "CONTENT_FRAGMENT",
  KURATOR_FRAGMENT: "KURATOR_FRAGMENT",
  CATALOG_ORDER_FRAGMENT: "CATALOG_ORDER_FRAGMENT",
  PLAIN_TEXT: "PLAIN_TEXT",
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const GLOBAL_EVENTS__MODAL_OPEN = "GLOBAL_EVENTS__MODAL_OPEN";
// eslint-disable-next-line @typescript-eslint/naming-convention
const GLOBAL_EVENTS__MODAL_CLOSE = "GLOBAL_EVENTS__MODAL_CLOSE";

export const openModal = (
  type: string,
  payload: string,
  localWindow = typeof window === "undefined" || window
) => {
  (localWindow as Window).dispatchEvent(
    new CustomEvent(GLOBAL_EVENTS__MODAL_OPEN, {
      bubbles: true,
      detail: { type, payload },
    })
  );
};

export const closeModal = (
  localWindow = typeof window === "undefined" || window
) => {
  (localWindow as Window).dispatchEvent(
    new CustomEvent(GLOBAL_EVENTS__MODAL_CLOSE, {
      bubbles: true,
    })
  );
};

function GlobalModal() {
  const [opened, setOpened] = useState<boolean>();
  const [modals, setModals] = useState([]);

  const handleOpen = ({ detail }) => {
    setModals((currentModals) => [...currentModals, detail]);
  };
  const handleClose = useCallback(() => {
    setModals((currentModals) => currentModals.slice(0, -1));
  }, [modals]);

  useEffect(() => {
    setOpened(modals.length > 0);
  }, [modals.length]);

  useEffect(() => {
    window.addEventListener(GLOBAL_EVENTS__MODAL_OPEN, handleOpen);
    window.addEventListener(GLOBAL_EVENTS__MODAL_CLOSE, handleClose);
    return () => {
      window.removeEventListener(GLOBAL_EVENTS__MODAL_OPEN, handleOpen);
      window.removeEventListener(GLOBAL_EVENTS__MODAL_CLOSE, handleClose);
    };
  }, []);

  const activeModal = modals?.length > 0 && modals[modals.length - 1];
  let modalContent;

  switch (activeModal?.type) {
    case GLOBAL_MODAL_TYPES.CONTENT_FRAGMENT: {
      modalContent = (
        <SellLayerContentFragment
          // TODO fix when sell package is using ts
          // @ts-ignore
          lazy
          onCloseModal={handleClose}
          content={activeModal.payload}
        />
      );
      break;
    }
    case GLOBAL_MODAL_TYPES.KURATOR_FRAGMENT: {
      modalContent = (
        <SellKuratorContentFragment
          // TODO fix when sell package is using ts
          // @ts-ignore
          lazy
          onCloseModal={handleClose}
          tag={activeModal.payload}
        />
      );
      break;
    }
    case GLOBAL_MODAL_TYPES.CATALOG_ORDER_FRAGMENT: {
      modalContent = (
        <AseCatalogOrderFragment
          // TODO fix when sell package is using ts
          // @ts-ignore
          lazy
          layout="overlay"
          onCloseModal={handleClose}
          catalogId={activeModal.payload}
        />
      );
      break;
    }
    case GLOBAL_MODAL_TYPES.PLAIN_TEXT: {
      modalContent = (
        <div
          data-test-modal-plain-text
          dangerouslySetInnerHTML={{ __html: activeModal.payload }}
        />
      );
      break;
    }
    default:
      modalContent = null;
  }

  return (
    <Modal opened={opened} onClose={handleClose}>
      {opened && modalContent}
    </Modal>
  );
}

export default GlobalModal;
