import convertSizes from "../imageSizes";

export const FALLBACK_PRESET = "?profile=eel_750";

export const IMAGESIZES = convertSizes({
  default: "100vw",
});

export const IMAGE_PRESETS = [
  {
    sizeKey: "150w",
    preset: "?profile=eel_150",
  },
  {
    sizeKey: "200w",
    preset: "?profile=eel_200",
  },
  {
    sizeKey: "300w",
    preset: "?profile=eel_300",
  },
  {
    sizeKey: "400w",
    preset: "?profile=eel_400",
  },
  {
    sizeKey: "500w",
    preset: "?profile=eel_500",
  },
  {
    sizeKey: "750w",
    preset: "?profile=eel_750",
  },
];
