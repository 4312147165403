import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { connect } from "react-redux";
import prefetch from "shared/ssrPrefetch/prefetch";
import { getClientsideFetchLocals } from "shared/ssrPrefetch/useFetchLocalsStore";
import FeedbackBox from "./component/FeedbackBox";
import {
  loadFeedbackStructure,
  submitRatingFeedback,
  submitTextFeedback,
} from "./actions";

const redial = {
  fetch: ({ dispatch, props, apiClient, isClient }) => {
    if (props.type) {
      return dispatch(loadFeedbackStructure(apiClient, props.type, isClient));
    }
    return null;
  },
};

const mapDispatchToProps = (dispatch, props) => {
  const { apiClient } = getClientsideFetchLocals();
  return {
    submitRating: (payload) =>
      dispatch(
        submitRatingFeedback(apiClient, payload, JSON.parse(props.metadata))
      ),
    submitText: (payload) => dispatch(submitTextFeedback(apiClient, payload)),
  };
};

function mapStateToProps(state) {
  const { uiState, id, structure } = state.feedbackFragment;

  return {
    uiState,
    id,
    structure,
  };
}

function FeedbackFragment(props) {
  return <FeedbackBox {...props} />;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(prefetch(FeedbackFragment, redial));
