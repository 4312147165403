import { validateInput } from "../../pages/utils/Validators";

export function validateQuantity(state) {
  const newState = validateInput(state, {
    inputPath: "data.article.quantity",
  });
  return newState;
}

export function validateFreeText(state) {
  const newState = validateInput(state, {
    inputPath: "data.article.freeText",
  });
  return newState;
}
