/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Footnotes from "./Footnotes";
import Link from "./Link";

import s from "./AppFooter.module.css";

function AppFooter({ legal, onLinkClick, footnotes, bannerFootnotes }) {
  return (
    <div data-test-sell-footer className="footer">
      {legal && (
        <div className={s.root} onClick={onLinkClick}>
          <ul className={s.appLinks} data-test-sell-footer-app-links>
            <li>
              <Link href={legal.agb?.link}>{legal.agb?.name}</Link>
            </li>
            <li>
              <Link href={legal.datenschutz?.link}>
                {legal.datenschutz?.name}
              </Link>
            </li>
            <li>
              <Link href={legal.impressum?.link}>{legal.impressum?.name}</Link>
            </li>
          </ul>
        </div>
      )}
      {footnotes && (
        <Footnotes
          data-test-sell-footer-footnotes
          styleElement="element"
          {...footnotes}
        />
      )}
      {bannerFootnotes && (
        <Footnotes
          data-test-sell-footer-footnotes-banner
          styleElement="bannerElement"
          {...bannerFootnotes}
        />
      )}
    </div>
  );
}

AppFooter.propTypes = {
  legal: PropTypes.object,
  onLinkClick: PropTypes.func,
  footnotes: PropTypes.object,
  bannerFootnotes: PropTypes.object,
};

export default AppFooter;
