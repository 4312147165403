import { SSR_PREFETCHED } from "shared/ssrPrefetch/constants";
import backendUrl from "../../pages/utils/AseBackendUrls";
import {
  NEWSLETTER_SUBSCRIPTION_FRAGMENT__CHANGE_CHECKBOX,
  NEWSLETTER_SUBSCRIPTION_FRAGMENT__CHANGE_INPUT,
  LOAD_NEWSLETTER_SUBSCRIPTION_FRAGMENT_SUCCESS,
  NEWSLETTER_SUBSCRIPTION_FRAGMENT__SUBSCRIBE,
  NEWSLETTER_SUBSCRIPTION_FRAGMENT__SUBSCRIBE_FAILED,
  NEWSLETTER_SUBSCRIPTION_FRAGMENT__VALIDATE,
  LOAD_NEWSLETTER_SUBSCRIPTION_FRAGMENT_STARTED,
} from "../../pages/constants";
import { buildCacheKey } from "./helper";

export function loadNewsletterSubscriptionFragmentData(
  apiClient,
  layout,
  isClient
) {
  const layoutAttr = layout === undefined ? "" : `?layout=${layout}`;
  const url = `${backendUrl.NEWSLETTER_SUBSCRIBE}${layoutAttr}`;
  return [
    { type: LOAD_NEWSLETTER_SUBSCRIPTION_FRAGMENT_STARTED },
    apiClient.get(
      url,
      {
        ok: (content) => [
          ...(!isClient && {
            type: SSR_PREFETCHED,
            payload: buildCacheKey({ layout }),
          }),
          {
            type: LOAD_NEWSLETTER_SUBSCRIPTION_FRAGMENT_SUCCESS,
            payload: content.body,
          },
        ],
      },
      ...(!isClient && { httpClientOptions: { timeout: 1000 } })
    ),
  ];
}

export function submitForm(event, link, values, apiClient) {
  event.preventDefault();
  return (dispatch, getState) => {
    dispatch({
      type: NEWSLETTER_SUBSCRIPTION_FRAGMENT__VALIDATE,
    });
    const state = getState();
    const url = link || backendUrl.NEWSLETTER_SUBSCRIBE;
    if (state.newsletterSubscriptionFragment.submitable) {
      dispatch(
        apiClient.post(url, values, {
          ok: (content) => ({
            type: NEWSLETTER_SUBSCRIPTION_FRAGMENT__SUBSCRIBE,
            payload: content.body,
          }),
          badRequest: (content) => ({
            type: NEWSLETTER_SUBSCRIPTION_FRAGMENT__SUBSCRIBE_FAILED,
            payload: content.body,
          }),
        })
      );
    }
    return true;
  };
}

export function updateCheckbox(event) {
  return {
    type: NEWSLETTER_SUBSCRIPTION_FRAGMENT__CHANGE_CHECKBOX,
    value: event.target.checked,
  };
}

export function updateInput(event) {
  return {
    type: NEWSLETTER_SUBSCRIPTION_FRAGMENT__CHANGE_INPUT,
    value: event.target.value,
  };
}
