import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import IconFacebook from "stelar/components/Icon/IconFacebook";
import IconInstagram from "stelar/components/Icon/IconInstagram";
import IconPinterest from "stelar/components/Icon/IconPinterest";

import Text from "stelar/components/Text";
import s from "./SocialLinks.module.css";

function createLink(element, icon) {
  if (element) {
    return (
      <Text normal className={s.icon} data-test-sell-footer-social-icon>
        <a href={element.link} target="_blank" rel="noopener noreferrer">
          {icon}
        </a>
      </Text>
    );
  }
  return null;
}

function SocialLinks({ label, facebook, instagram, pinterest }) {
  return (
    <div className={s.root}>
      {label && (
        <Text normal className={s.label}>
          {label}
        </Text>
      )}
      <div className={s.icons}>
        {createLink(facebook, <IconFacebook small />)}
        {createLink(instagram, <IconInstagram small />)}
        {createLink(pinterest, <IconPinterest small />)}
      </div>
    </div>
  );
}

SocialLinks.propTypes = {
  label: PropTypes.string,
  facebook: PropTypes.object,
  instagram: PropTypes.object,
  pinterest: PropTypes.object,
};

export default SocialLinks;
