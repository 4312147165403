// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Helmet from "react-helmet";
// mf-stelar
import Message from "stelar/components/Message";
// component
import ContentGutter from "../../../components/ContentGutter";
import CustomContentRowsMapper from "./CustomContentRowsMapper";

const OrderConfirmationPage = ({
  contentRows,
  isLoading,
  page,
  submitNewsletter,
}) => {
  const head = page.headData || {};

  const messages = page.globalMessage
    ? [page.globalMessage]
    : page.globalMessages;

  return (
    <ContentGutter>
      <Helmet
        title={head.title}
        meta={head.meta}
        script={head.script}
        noscript={head.noscript}
      />
      {!isLoading && messages?.map((message) => <Message {...message} />)}
      {contentRows && (
        <CustomContentRowsMapper
          contentRows={contentRows}
          isLoading={isLoading}
          submitNewsletter={submitNewsletter}
        />
      )}
    </ContentGutter>
  );
};

OrderConfirmationPage.propTypes = {
  contentRows: PropTypes.array,
  headData: PropTypes.object,
  isLoading: PropTypes.bool,
  page: PropTypes.object,
  submitNewsletter: PropTypes.func,
};

export default OrderConfirmationPage;
