import classNames from "classnames/bind";
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies

import { ButtonX } from "stelar/components/Button";
import CountryFlag from "stelar/components/CountryFlag";
import LinkButton from "stelar/components/LinkButton";
import Text from "stelar/components/Text";

import s from "./HeaderBanner.module.css";

const cx = classNames.bind(s);

function HeaderBanner({
  links,
  service,
  handleClick,
  country,
  countryToggle,
  setCountryToggle,
}) {
  const pathname =
    typeof document !== "undefined" && document.location
      ? `${document.location.pathname}${document.location.search}`
      : "/";
  return (
    <div className={s.root}>
      <div className={s.inner}>
        <ul className={s.linkList}>
          {links.map(({ name, link }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li className={s.listElement} key={index}>
              <small className={s.linkText}>
                <a
                  className={s.linkLink}
                  data-prio2
                  href={link}
                  onClick={(e) => handleClick(e)}
                >
                  {name}
                </a>
              </small>
            </li>
          ))}
        </ul>
        <div className={cx("serviceContainer")}>
          <ul className={s.linkList}>
            <li className={s.listElement}>
              <small className={cx("linkText")}>
                <a
                  className={s.linkLink}
                  data-prio2
                  href={service.link}
                  onClick={(e) => handleClick(e)}
                >
                  {service.label}
                  <span className={s.phone}>{service.phone}</span>
                </a>
              </small>
            </li>
            {country && (
              <li className={s.listElement}>
                <button
                  data-test-sell-country-flag-button
                  className={cx("countryFlagButton")}
                  type="button"
                  onClick={() => setCountryToggle(!countryToggle)}
                >
                  {country.iconName !== "mag" ? (
                    <CountryFlag
                      className={cx("countryFlagSize")}
                      country={country.iconName}
                    />
                  ) : (
                    <div />
                  )}
                  <Text small>{country.label}</Text>
                </button>
              </li>
            )}
          </ul>

          {country && countryToggle && (
            <div className={cx("countryContainer")}>
              <div className={cx("countrySelectionContainer")}>
                <header className={s.header}>
                  {country.selection.heading && (
                    <span className={cx("title")}>
                      {country.selection.heading}
                    </span>
                  )}
                  <span className={cx("buttonX")}>
                    <ButtonX onClick={() => setCountryToggle(false)} />
                  </span>
                </header>
                <div className={cx("selectionContainer")}>
                  {country.selection.options.map((element) => {
                    const isLabelEqual = country.label === element.label;
                    return (
                      <div className={cx("optionLinkContainer")}>
                        <LinkButton
                          href={`${element.link}${pathname}`}
                          em={isLabelEqual}
                          prio2
                        >
                          {element.label}
                        </LinkButton>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

HeaderBanner.propTypes = {
  handleClick: PropTypes.func,
  links: PropTypes.array,
  service: PropTypes.object,
  country: PropTypes.object,
  countryToggle: PropTypes.bool,
  setCountryToggle: PropTypes.func,
};

HeaderBanner.defaultProps = {
  links: [],
  service: {},
  handleClick: () => {},
};

export default HeaderBanner;
