import queryString from "query-string";
import { setPageTitle } from "shared/webbridge/actions";
import backendUrl from "../utils/AseBackendUrls";
import {
  ACCOUNT_DELETION_PAGE__LOAD_PAGE,
  ACCOUNT_DELETION_PAGE__SUBMIT_FAILED,
  ACCOUNT_DELETION_PAGE__SUBMIT,
} from "../constants";
import { redirectToTarget } from "../utils/Routing";

export function loadAccountDeletionPage(apiClient, query) {
  return apiClient.get(
    backendUrl.ACCOUNT_DELETION,
    {
      ok: (content) => [
        {
          type: ACCOUNT_DELETION_PAGE__LOAD_PAGE,
          payload: content.body,
        },
        setPageTitle(content.body?.page?.headData?.title),
      ],
    },
    {
      httpClientOptions: {
        params: query,
      },
    }
  );
}

export function submitDeletion({ routes, link, formValues }, apiClient) {
  return [
    {
      type: ACCOUNT_DELETION_PAGE__SUBMIT,
    },
    apiClient.post(
      link,
      queryString.stringify(formValues, { sort: false }),
      {
        noContent: (content) => [redirectToTarget(routes, content)],
        badRequest: (content) => [
          {
            type: ACCOUNT_DELETION_PAGE__SUBMIT_FAILED,
            payload: content.body,
          },
        ],
      },
      {
        httpClientOptions: {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        },
      }
    ),
  ];
}
