module.exports = {
  meta: [
    { name: "apple-mobile-web-app-title", content: "manufactum" },
    { name: "application-name", content: "manufactum" },
    {
      name: "msapplication-config",
      content: "/sell/static/manufactum/meta/browserconfig.xml",
    },
    { name: "theme-color", content: "#ffffff" },
  ],
  link: [
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/sell/static/manufactum/meta/apple-touch-icon.png",
    },
    {
      rel: "icon",
      sizes: "32x32",
      href: "/sell/static/manufactum/meta/favicon-32x32.png",
      type: "image/png",
    },
    {
      rel: "icon",
      sizes: "16x16",
      href: "/sell/static/manufactum/meta/favicon-16x16.png",
      type: "image/png",
    },
    {
      rel: "manifest",
      href: "/sell/static/manufactum/meta/site.webmanifest",
    },
    {
      rel: "mask-icon",
      href: "/sell/static/manufactum/meta/safari-pinned-tab.svg",
      color: "#005131",
    },
    { rel: "shortcut icon", href: "/sell/static/manufactum/meta/favicon.ico" },
  ],
};
