/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconInstagram(props: IconType) {
  return (
    <svg
      className={classNames(props)}
      viewBox="0 0 18 18"
      aria-label="Instagram"
      role="img"
    >
      <g>
        <title>Instagram</title>
        <path
          d="M13.3,0.5H4.7c-2.3,0-4.2,1.9-4.2,4.2v8.6c0,2.3,1.9,4.2,4.2,4.2h8.5c2.3,0,4.2-1.9,4.2-4.2V4.7
          C17.5,2.4,15.6,0.5,13.3,0.5z M4.7,2h8.5c1.5,0,2.7,1.2,2.7,2.7v1.5h-4.5C10.8,5.7,10,5.4,9.1,5.4S7.4,5.7,6.8,6.2H2.1V4.7
          C2.1,3.2,3.3,2,4.7,2z M10.8,9.5c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7C10,7.8,10.8,8.5,10.8,9.5z
          M13.3,16H4.7c-1.5,0-2.7-1.2-2.7-2.7V7.7h3.4C5.2,8.3,5.1,8.9,5.1,9.5c0,2.2,1.8,4,4,4s4-1.8,4-4c0-0.6-0.1-1.2-0.4-1.7h3.2v5.5
          C15.9,14.8,14.7,16,13.3,16z"
        />
        <path
          d="M14.7,4.9c0,0.3-0.2,0.5-0.5,0.5h-1.5c-0.3,0-0.5-0.2-0.5-0.5V3.4c0-0.3,0.2-0.5,0.5-0.5h1.5
          c0.3,0,0.5,0.2,0.5,0.5V4.9z"
        />
      </g>
    </svg>
  );
}

export default IconInstagram;
