import React from "react"; // eslint-disable-line import/no-extraneous-dependencies

type Context = {
  renderTarget?: string;
  deviceOs?: string;
};

const DeviceInfoContext = React.createContext<Context>({});

type Props = {
  children?: React.ReactNode;
} & Context;

export function DeviceInfoProvider({
  children,
  renderTarget,
  deviceOs,
}: Props) {
  const value = React.useMemo(
    () => ({ renderTarget, deviceOs }),
    [renderTarget, deviceOs]
  );
  return (
    <DeviceInfoContext.Provider value={value}>
      {children}
    </DeviceInfoContext.Provider>
  );
}

export function useDeviceInfo() {
  const context = React.useContext(DeviceInfoContext);
  if (context === undefined) {
    // eslint-disable-next-line no-console
    console.error("must be used within DeviceInfoProvider");
    return {};
  }
  return context;
}
