import backendUrl from "../../pages/utils/AseBackendUrls";
import { REDUCED_LAYOUT_COMPONTENT__LOAD_REDUCED_LAYOUT } from "../../pages/constants";

export default function loadReducedLayout(apiClient) {
  // const layout = getState().reducedLayout;
  // if (layout && layout.footer && layout.header) {
  //   return null;
  // }
  return apiClient.get(backendUrl.REDUCED_LAYOUT, {
    ok: (content) => ({
      type: REDUCED_LAYOUT_COMPONTENT__LOAD_REDUCED_LAYOUT,
      payload: content.body,
    }),
  });
}
