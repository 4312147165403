import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import imageSizes from "shared/utils/imageSizes";
import AsyncStage from "../../AsyncStage";
import s from "./ElementResponsiveTeasers.module.css";

const cx = classnames.bind(s);

function ElementStage({
  fullWidth,
  id,
  lazy,
  loadedImages,
  onLinkClick,
  onImageLoad,
  slides = [],
}) {
  if (!slides || slides.length === 0) {
    return null;
  }
  // SELL-4682, disable responsiveTeaser in desktop version, for now there is sticky banner only for desktop
  const isId1883 =
    id.includes("1883_") ||
    id.includes("1884_") ||
    id.includes("1885_") ||
    id.includes("1886_") ||
    id.includes("2711_") ||
    id.includes("1441_3");

  const XXLandUpIndentFactor = fullWidth ? 1 : Number((1 - 2 / 12).toFixed(6));
  const LtoXXLIndentFactor = fullWidth ? 1 : Number((1 - 3 / 12).toFixed(6));

  const imgSizes = imageSizes({
    xxxl: `${1500 * XXLandUpIndentFactor}px`,
    xxl: `${100 * XXLandUpIndentFactor}vw`,
    l: `${100 * LtoXXLIndentFactor}vw`,
    default: "100vw",
  });
  return (
    <div
      className={cx(
        "spacing",
        { disableDesktop: isId1883 },
        {
          singleElement: slides.length <= 1,
          multipleElements: !(slides.length <= 1),
        }
      )}
      data-test-sell-element-responsive-teasers
    >
      <AsyncStage
        slides={slides}
        lazy={lazy}
        imgSizes={imgSizes}
        onImageLoad={onImageLoad}
        loadedImages={loadedImages}
        onLinkClick={onLinkClick}
      />
    </div>
  );
}

ElementStage.propTypes = {
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  lazy: PropTypes.bool,
  loadedImages: PropTypes.object,
  onLinkClick: PropTypes.func,
  onImageLoad: PropTypes.func,
  slides: PropTypes.array,
};

ElementStage.defaultProps = {
  id: "",
};

export default ElementStage;
