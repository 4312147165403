import update from "immutability-helper";

function getPropertyByPath(object, pathString) {
  const path = pathString.split(".");
  let prop = object;
  path.forEach((pathElement) => {
    prop = prop[pathElement];
  });
  return prop;
}

function isMandatory(element) {
  return element.mandatory;
}

function isChecked(element) {
  return element.checked;
}

function createObjectByPath(object, pathString) {
  const path = pathString.split(".");
  let newobject = object;
  path.reverse().forEach((pathElement) => {
    newobject = {
      [pathElement]: newobject,
    };
  });
  return newobject;
}

function isEmpty(element) {
  return !element.value;
}

function isRequired(element) {
  return element.required;
}

function hasValidationRegExp(element) {
  return element.validation && !(element.validation.constructor === Array);
}

function hasMultipleValidation(element) {
  return element.validation && element.validation.constructor === Array;
}

function isValid(element) {
  if (element.validation && element.value) {
    const regex = RegExp(element.validation, "i");
    return regex.test(element.value);
  }
  return false;
}

function processMultipleValidators(element, state) {
  let error = "";
  element.validation.forEach((validator) => {
    if (error === "") {
      if ("fieldToMatch" in validator) {
        const comparedEl =
          state[validator.fieldToMatch] ||
          Object.values(state).find(
            (e) => e.name && e.name === validator.fieldToMatch
          );
        if (comparedEl && comparedEl.value !== element.value) {
          error = validator.errorMessage;
        }
      } else {
        const regex = RegExp(validator.value, "i");
        if (!regex.test(element.value)) {
          error = validator.errorMessage;
        }
      }
    }
  });

  return error;
}

function validateCheckbox(state, action, virtual) {
  const element = getPropertyByPath(state, action.checkboxPath);
  const error =
    isMandatory(element) && !isChecked(element)
      ? element.errorUnchecked || "error"
      : ""; // eslint-disable-line
  const errorPath = action.errorPath || action.checkboxPath;
  const updateObject = createObjectByPath(
    {
      error: {
        $set: error,
      },
    },
    errorPath
  );
  if (error) {
    if (virtual) {
      updateObject.virtual = {
        submitable: {
          $set: false,
        },
      };
    } else {
      updateObject.submitable = {
        $set: false,
      };
    }
  }
  return update(state, updateObject);
}

function validateInput(state, action, virtual) {
  const element = getPropertyByPath(state, action.inputPath);

  if (element == null) return state;

  let error = "";

  if (hasMultipleValidation(element)) {
    error = processMultipleValidators(element, state);
  } else if (error === "") {
    if (isEmpty(element)) {
      error = isRequired(element) ? element.errorEmpty : error;
    } else if (hasValidationRegExp(element)) {
      error = !isValid(element) ? element.errorInvalid : error;
    }
  }

  const errorPath = action.errorPath || action.inputPath;
  const updateObject = createObjectByPath(
    {
      error: {
        $set: error,
      },
    },
    errorPath
  );
  if (error) {
    if (virtual) {
      updateObject.virtual = {
        submitable: {
          $set: false,
        },
      };
    } else {
      updateObject.submitable = {
        $set: false,
      };
    }
  }
  return update(state, updateObject);
}

export { validateInput, validateCheckbox };
