import loadable from "@loadable/component";
import merge from "lodash.merge";
import { connect } from "react-redux";
import { provideHooks } from "redial";

import Loading from "shared/components/Loading";
import track from "shared/tracking";
import changeInputAction from "shared/utils/actionReducer/changeInput/ChangeInputAction";

import {
  redeemVoucher as _redeemVoucher,
  loadGutscheinFormData,
} from "../../components/GutscheinForm/actions";
import loadReducedLayout from "../../components/ReducedLayout/actions";
import { LAST_CHECK_PAGE__PAGE_NAME } from "../constants";
import frontendUrls from "../utils/AseFrontendUrls";
import {
  changeDeliveryMethod,
  createOrder,
  deleteGutscheinItem,
  deleteLineItem,
  loadLastCheckPage,
  toggleAgeRelatedAdvice,
  updateEegReturn,
  updateLineItem,
  updateQuantityOfLineItem,
  validateInput,
} from "./actions";

const page = LAST_CHECK_PAGE__PAGE_NAME;

const redial = {
  fetch: ({ apiClient, dispatch, getState, query }) =>
    Promise.all([
      dispatch(loadLastCheckPage(apiClient, query)),
      dispatch(loadGutscheinFormData(apiClient)),
      dispatch(loadReducedLayout(apiClient, getState)),
    ]),
  defer: ({ getState }) => track(getState()?.lastCheckPage?.page?.trackingInfo),
};

const mapDispatchToProps = (dispatch, { apiClient }) => ({
  changeDeliveryMethod: (args) =>
    dispatch(changeDeliveryMethod(args, apiClient)),
  changeInputFunc: (path, field) =>
    changeInputAction(dispatch)(page, path, field),
  createOrder: (event) => dispatch(createOrder(event, apiClient)),
  deleteGutscheinItem: (gutscheinItemId) =>
    dispatch(deleteGutscheinItem(gutscheinItemId, apiClient)),
  lineItemOps: {
    deleteLineItem: (lineItemId) =>
      dispatch(deleteLineItem(lineItemId, apiClient)),
    updateLineItem: (lineItemId, quantity) => {
      dispatch(updateLineItem(apiClient, lineItemId, quantity));
    },
    updateQuantityOfLineItem: (newQuantity, type, lineItemId) =>
      dispatch(updateQuantityOfLineItem(newQuantity, type, lineItemId)),
    updateEegReturn: (changeUrl) =>
      dispatch(updateEegReturn(apiClient, changeUrl)),
  },
  toggleAgeRelatedAdvice: (lineItemId) =>
    dispatch(toggleAgeRelatedAdvice(lineItemId)),
  validateInputFunc: (inputPath) => dispatch(validateInput(inputPath)),
  redeemVoucher: ({ link, context, values }) =>
    dispatch(_redeemVoucher({ link, values, context, apiClient })),
});

const mapStateToProps = (state) => ({
  ...state.lastCheckPage,
  isLoading: !state.lastCheckPage.page.headData,
  reducedLayout: state.reducedLayout,
});

const LoadableLastCheckPage = loadable(
  () =>
    import(
      /* webpackChunkName: "lastcheck-page" */ "./components/LastCheckPage"
    ),
  {
    fallback: Loading,
  }
);

export default {
  path: frontendUrls.LAST_CHECK,
  component: provideHooks(redial)(
    connect(mapStateToProps, mapDispatchToProps, merge)(LoadableLastCheckPage)
  ),
};
