// vendor
import update from "immutability-helper";
// ase
import {
  NEWSLETTER_SUBSCRIPTION_COMPONENT__CHANGE_CHECKBOX,
  NEWSLETTER_SUBSCRIPTION_COMPONENT__CHANGE_INPUT,
  NEWSLETTER_SUBSCRIPTION_COMPONENT__LOAD_NEWSLETTER_SUBSCRIPTION,
  NEWSLETTER_SUBSCRIPTION_COMPONENT__SUBSCRIBE,
  NEWSLETTER_SUBSCRIPTION_COMPONENT__SUBSCRIBE_FAILED,
  NEWSLETTER_SUBSCRIPTION_COMPONENT__VALIDATE,
} from "../../pages/constants";
import { validateDatenschutz, validateEmail } from "./validator";

const initialState = {
  email: {
    required: true,
  },
  datenschutz: {
    checked: false,
  },
  successMessage: undefined,
};

function newsletterSubscriptionReducer(state = initialState, action) {
  let newState;
  let newEmail;
  switch (action.type) {
    case NEWSLETTER_SUBSCRIPTION_COMPONENT__VALIDATE:
      newState = update(state, { submitable: { $set: true } });
      newState = validateDatenschutz(newState);
      return validateEmail(newState);
    case NEWSLETTER_SUBSCRIPTION_COMPONENT__LOAD_NEWSLETTER_SUBSCRIPTION:
      return update(state, {
        newsletterSubscription: { $set: action.payload },
      });
    case NEWSLETTER_SUBSCRIPTION_COMPONENT__SUBSCRIBE:
      return update(state, {
        email: { $set: initialState.email },
        datenschutz: { $set: initialState.datenschutz },
        successMessage: {
          $set: action.payload.successMessage || {
            title: "success",
            text: "missing successMessage",
          },
        },
      });
    case NEWSLETTER_SUBSCRIPTION_COMPONENT__SUBSCRIBE_FAILED:
      return update(state, {
        $set: action.payload,
      });
    case NEWSLETTER_SUBSCRIPTION_COMPONENT__CHANGE_INPUT:
      newEmail = update(action.props || {}, {
        value: { $set: action.value },
      });
      newState = update(state, {
        email: { $merge: newEmail },
        successMessage: { $set: undefined },
      });
      return validateEmail(newState);
    case NEWSLETTER_SUBSCRIPTION_COMPONENT__CHANGE_CHECKBOX:
      return update(state, {
        datenschutz: {
          checked: { $set: action.value },
        },
        successMessage: { $set: undefined },
      });
    default:
      return state;
  }
}

export default newsletterSubscriptionReducer;
