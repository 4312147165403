// vendor
import { connect } from "react-redux";
// component
import prefetch from "shared/ssrPrefetch/prefetch";
import { getClientsideFetchLocals } from "shared/ssrPrefetch/useFetchLocalsStore";
import { getFragment, reGetFragment, submitForm, updateInput } from "./actions";
import LineItemEditFragment from "./components/LineItemEditFragment";

const redial = {
  fetch: ({ apiClient, dispatch, props: { link } }) => {
    dispatch(getFragment(apiClient, link));
  },
};

function mapStateToProps(state) {
  return {
    ...state.lineItemEditFragment.data,
    ...state.lineItemEditFragment.uiState,
  };
}

const mapDispatchToProps = (dispatch) => {
  const { apiClient } = getClientsideFetchLocals();
  return {
    doReGetFragment: (args) => dispatch(reGetFragment(args, apiClient)),
    doUpdateInput: (args) => dispatch(updateInput(args)),
    getFragment: (args) => dispatch(getFragment(apiClient, args)),
    doSubmitForm: (args) => dispatch(submitForm(args, apiClient)),
  };
};

export { attrs, stateKey } from "./components/LineItemEditFragment";

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(prefetch(LineItemEditFragment, redial));
