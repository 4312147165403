import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import HtmlContent from "stelar/components/HtmlContent";
import Text from "stelar/components/Text";
import s from "./ElementText.module.css";
import ContentSpacing from "../../ContentSpacing/ContentSpacing";

function ElementText({ text, fullWidth, onLinkClick, enclosedByBox }) {
  const innerText = enclosedByBox ? (
    <Text normal tag="div" outline>
      <HtmlContent
        dangerouslySetInnerHTML={{ __html: text }}
        onClick={onLinkClick}
      />
    </Text>
  ) : (
    <Text normal>
      <HtmlContent
        dangerouslySetInnerHTML={{ __html: text }}
        onClick={onLinkClick}
      />
    </Text>
  );
  return (
    <ContentSpacing
      alignment="text"
      fullWidth={fullWidth}
      gutters
      className={s.spacing}
      data-test-sell-element-text
    >
      {innerText}
    </ContentSpacing>
  );
}

ElementText.propTypes = {
  text: PropTypes.string,
  fullWidth: PropTypes.bool,
  onLinkClick: PropTypes.func,
  enclosedByBox: PropTypes.bool,
};

ElementText.defaultProps = {};

export default ElementText;
