import { connect } from "react-redux";
import { provideHooks } from "redial";
import track from "shared/tracking";
import { triggerAseBasketUpdatedEvent } from "shared/utils/customEvents";
// ase
import frontendUrls from "../utils/AseFrontendUrls";
// component
import {
  submit,
  validateNewsletterFragment,
} from "../../components/NewsletterSubscription/actions";
import loadOrderConfirmationPage from "./actions";
import OrderConfirmationPage from "./components/OrderConfirmationPage";

const redial = {
  fetch: ({ apiClient, dispatch, params: { paymentMethod }, query }) => {
    triggerAseBasketUpdatedEvent();
    return dispatch(loadOrderConfirmationPage(apiClient, paymentMethod, query));
  },
  defer: ({ getState }) => {
    track(getState()?.orderConfirmationPage?.page?.trackingInfo);
  },
};

const mapDispatchToProps = (dispatch, { apiClient }) => ({
  submitNewsletter: (event, link, data) =>
    dispatch([
      validateNewsletterFragment(event),
      submit(event, link, data, apiClient),
    ]),
});

const mapStateToProps = (state) => ({
  ...state.orderConfirmationPage,
  isLoading: !state.orderConfirmationPage.page.headData,
  reducedLayout: state.reducedLayout,
});

export default {
  component: provideHooks(redial)(
    connect(mapStateToProps, mapDispatchToProps)(OrderConfirmationPage)
  ),
  path: {
    base: frontendUrls.ORDER_CONFIRMATION,
    appendix: "(/:paymentMethod)",
  },
};
