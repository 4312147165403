import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { CookiesProvider } from "./CookieProvider";
import { DeviceInfoProvider } from "./DeviceInfoProvider";

type Props = {
  children?: React.ReactNode;
  renderTarget: string;
  deviceOs: string;
  initialCookies?: string;
};

export function HttpContextProviders({
  children,
  renderTarget,
  deviceOs,
  initialCookies,
}: Props) {
  return (
    <DeviceInfoProvider renderTarget={renderTarget} deviceOs={deviceOs}>
      <CookiesProvider initialCookies={initialCookies}>
        {children}
      </CookiesProvider>
    </DeviceInfoProvider>
  );
}
