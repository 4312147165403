import {
  HIDE_COOKIE_BANNER,
  SHOW_COOKIE_BANNER,
} from "../../../pages/constants";

export default function getCookieBannerActions() {
  function hideCookieBanner() {
    return { type: HIDE_COOKIE_BANNER };
  }

  function showCookieBanner() {
    return { type: SHOW_COOKIE_BANNER };
  }

  return {
    hideCookieBanner,
    showCookieBanner,
  };
}
