import { combineReducers } from "redux";
import update from "immutability-helper";
import {
  LOAD_KURATOR_CONTENT_SUCCESS,
  LOAD_KURATOR_CONTENT_STARTED,
  LOAD_KURATOR_CONTENT_FAILED,
  SHOW_SKELETON_KURATOR_CONTENT,
} from "../../pages/constants";
import uiState from "../../utils/uiState/reducer";

const initialState = {};

const kuratorContents = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_KURATOR_CONTENT_STARTED: {
      const tag = payload?.tag;
      const data = {
        [tag]: {
          loading: true,
          data: null,
        },
      };
      return update(state, { $merge: data });
    }

    case LOAD_KURATOR_CONTENT_SUCCESS: {
      const tag = payload?.tag;
      const data = {
        [tag]: {
          loading: false,
          data: payload,
        },
      };
      return update(state, { $merge: data });
    }

    case LOAD_KURATOR_CONTENT_FAILED: {
      const tag = payload?.tag;
      const data = {
        [tag]: {
          loading: false,
          data: null,
        },
      };
      return update(state, { $merge: data });
    }

    case SHOW_SKELETON_KURATOR_CONTENT: {
      const tag = payload?.tag;
      const data = {
        [tag]: {
          loading: true,
          data: payload,
        },
      };
      return update(state, { $merge: data });
    }

    default:
      return state;
  }
};

export default combineReducers({
  kuratorContents,
  uiState,
});
