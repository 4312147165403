import {
  MINI_ACCOUNT_FRAGMENT__GET,
  MINI_BASKET_FRAGMENT__LOAD_MINI_BASKET,
  MINI_BASKET_FRAGMENT__LOAD_MINI_BASKET_DATA,
  MINI_WISH_LIST_FRAGMENT__GET,
} from "ase/pages/constants";
import update from "immutability-helper";
import {
  LOAD_FULL_NAVIGATION_SUCCESS,
  LOAD_HEADER_FOOTER_BANNER_SUCCESS,
  SET_QUERY_PARAMETER,
  SET_DETAIL_PARAMETER,
  SET_BANNER_FOOTNOTES,
  LOAD_HEADER_SUCCESS,
  LOAD_FOOTER_SUCCESS,
  LOAD_COOKIE_BANNER_SUCCESS,
  SET_TRACKING_ID,
  OPEN_COOKIE_BANNER,
  CLOSE_COOKIE_BANNER,
} from "sell/pages/constants";

const initialState = {
  header: null,
  footer: null,
  bannerId: null,
  query: null,
  params: null,
  miniBasket: {
    baseData: {
      active: false,
      callToAction: {},
      overlayEnabled: false,
      summary: {},
    },
    basketData: {
      callToAction: {
        label: "",
        link: "",
      },
      closeText: "",
      emptyBasketText: "",
      heading: "",
      lineItems: [],
    },
    uiState: {
      overlayLoaded: false,
    },
  },
  cookieBanner: {
    data: null,
    open: false,
  },
};

export default function normalLayout(state = initialState, action) {
  switch (action.type) {
    case LOAD_HEADER_SUCCESS:
      return update(state, { header: { $set: action.payload } });
    case LOAD_FOOTER_SUCCESS:
      return update(state, { footer: { $set: action.payload } });
    case LOAD_FULL_NAVIGATION_SUCCESS:
      return update(state, {
        header: {
          navigation: {
            items: { $merge: action.payload },
          },
        },
      });
    case LOAD_HEADER_FOOTER_BANNER_SUCCESS:
      return update(state, {
        header: {
          banner: { $set: action.payload.banner },
          appInstallBanner: { $set: action.payload.appInstallBanner },
        },
      });
    case SET_BANNER_FOOTNOTES:
      return update(state, {
        footer: { bannerFootnotes: { $set: action.payload } },
      });
    case SET_QUERY_PARAMETER:
      return update(state, { query: { $set: action.payload } });
    case SET_DETAIL_PARAMETER:
      return update(state, { params: { $set: action.payload } });

    case MINI_ACCOUNT_FRAGMENT__GET:
      return update(state, { miniAccount: { $set: action.payload } });

    case MINI_WISH_LIST_FRAGMENT__GET:
      return update(state, { miniWishlist: { $set: action.payload } });

    case MINI_BASKET_FRAGMENT__LOAD_MINI_BASKET:
      return update(state, {
        miniBasket: { baseData: { $set: action.payload } },
      });
    case MINI_BASKET_FRAGMENT__LOAD_MINI_BASKET_DATA: {
      return update(state, {
        miniBasket: {
          basketData: { $set: action.payload },
          uiState: { overlayLoaded: { $set: true } },
        },
      });
    }
    case LOAD_COOKIE_BANNER_SUCCESS: {
      return update(state, {
        cookieBanner: { $set: { data: action.payload } },
      });
    }
    case SET_TRACKING_ID: {
      return !state.cookieBanner.id
        ? update(state, { cookieBanner: { $set: { id: action.payload } } })
        : state;
    }
    case OPEN_COOKIE_BANNER: {
      return update(state, { cookieBanner: { open: { $set: true } } });
    }
    case CLOSE_COOKIE_BANNER: {
      return update(state, { cookieBanner: { open: { $set: false } } });
    }
    default:
      return state;
  }
}
