import { isOptinBannerRequired } from "shared/utils/trackingHelper";

export default function callWhenCookieIsNotSet(
  func,
  cookies,
  document = window.document
) {
  const found = cookies.find((cookie) => document.cookie.includes(cookie));
  if (!found) {
    func();
  }
}

/* Called "Sterntext" should be visible if the cookie banner is shown */
export function renderClientBottom(hidden, hiddenByUser, open) {
  if (typeof document !== "undefined") {
    const footerElement = document.querySelector(".footer");
    const cookieBannerNotVisible =
      document
        .querySelector("[data-test-sell-cookie-banner]")
        ?.getBoundingClientRect()?.width === 0;
    if (footerElement) {
      if (hidden || hiddenByUser || !open || cookieBannerNotVisible) {
        footerElement.classList.remove("cookieBannerMargin");
      } else {
        footerElement.classList.add("cookieBannerMargin");
      }
    }
  }
}

export function openIfNeeded(state, onNeedToShow) {
  if (typeof window === "object") {
    if (state && isOptinBannerRequired(document.cookie)) {
      onNeedToShow();
    }
  }
}
