import React from "react";
import s from "./OffCanvasExtension.module.css";

type Props = {
  children?: React.ReactNode;
};

/**
 * An additional navigation that contains all customer related ASE links.
 * It is similar in appearance to SELL's OffCanvasNavigation and is shown below it.
 */
function OffCanvasExtension({ children }: Props) {
  return <ul className={s.root}>{children}</ul>;
}

export default OffCanvasExtension;
