import { getVersion } from "../utils";

export default function transformClsMetric(metric) {
  return {
    name: metric.name,
    value: metric.value,
    rating: metric.rating,
    count: metric.entries?.length,
    version: getVersion(metric),
    navigationType: metric.navigationType,
  };
}
