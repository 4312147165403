import React from "react";
import classNames from "classnames/bind";
import OffCanvasNavigation from "./OffCanvasNavigation";
import OffCanvasContent from "./OffCanvasContent";
import OffCanvasOverlay from "./OffCanvasOverlay";
import s from "./OffCanvas.module.css";

const cx = classNames.bind(s);

type Props = {
  navigation?: React.ReactNode;
  content?: React.ReactNode;
  active?: boolean;
  headerText?: string;
  headerCloseLabel?: string;
  onClose?: (...args: unknown[]) => unknown;
  country?: object;
  countryToggle?: boolean;
  handleCountryToggle?: (...args: unknown[]) => unknown;
} & React.ComponentPropsWithoutRef<typeof OffCanvasNavigation>;

/**
 * The OffCanvas component can be used, to bring in a navigation from the left-hand side.
 * It is only active in the breakpoints <= XL.
 */
function OffCanvas({ navigation, content, active, onClose, ...props }: Props) {
  return (
    <div className={cx("root", { active })} {...props}>
      {content && (
        <OffCanvasContent active={active}>{content}</OffCanvasContent>
      )}
      {navigation && (
        <OffCanvasNavigation active={active} onClose={onClose} {...props}>
          {navigation}
        </OffCanvasNavigation>
      )}
      <OffCanvasOverlay active={active} onClick={onClose} />
    </div>
  );
}

export default OffCanvas;
