import PropTypes from "prop-types";
/* eslint-disable react/no-array-index-key */
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";

import { IMAGE_PROFILE_DEFAULTS } from "shared/utils/imageUrlBuilder";
import s from "./ProductList.module.css";
import ProductTile from "../ProductTile";

const cx = classnames.bind(s);

const initalProductCount = 16;

function ProductList({ fullWidth, products, onLinkClick }) {
  const containerClasses = cx("container", { fullWidth });

  return (
    <ul className={s.root} data-test-sell-product-list>
      {products
        ? products.map((product, key) => (
            <li className={containerClasses} key={`${product.productId}${key}`}>
              <ProductTile
                {...product}
                imageProfileDefault={IMAGE_PROFILE_DEFAULTS.produktliste}
                nativeLazy={key >= initalProductCount}
                onLinkClick={onLinkClick}
                type="normal"
              />
            </li>
          ))
        : null}
    </ul>
  );
}

ProductList.propTypes = {
  fullWidth: PropTypes.bool,
  products: PropTypes.array,
  onLinkClick: PropTypes.func,
};

export default ProductList;
