import update from "immutability-helper";
import { v4 as uuidv4 } from "uuid";

import {
  initialState,
  LOAD_FEEDBACK_STRUCTURE_FAILED,
  LOAD_FEEDBACK_STRUCTURE_STARTED,
  LOAD_FEEDBACK_STRUCTURE_SUCCESS,
  SEND_FEEDBACK_RATING_FAILED,
  SEND_FEEDBACK_RATING_STARTED,
  SEND_FEEDBACK_RATING_SUCCESS,
  SEND_FEEDBACK_TEXT_FAILED,
  SEND_FEEDBACK_TEXT_STARTED,
  SEND_FEEDBACK_TEXT_SUCCESS,
} from "./constants";

const feedbackFragment = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_FEEDBACK_STRUCTURE_STARTED:
      return update(state, {
        uiState: {
          $set: { loading: true },
        },
      });
    case LOAD_FEEDBACK_STRUCTURE_SUCCESS:
      return update(state, {
        structure: { $set: action.payload },
        id: { $set: uuidv4() },
        uiState: {
          $set: {
            loading: false,
            ratingSent: false,
            textSent: false,
            unexpectedError: false,
          },
        },
      });
    case LOAD_FEEDBACK_STRUCTURE_FAILED:
      return update(state, {
        uiState: {
          $set: {
            loading: false,
            ratingSent: false,
            textSent: false,
            unexpectedError: false,
          },
        },
      });
    case SEND_FEEDBACK_RATING_STARTED:
      return update(state, {
        uiState: {
          $set: { loading: true, ratingSent: false, unexpectedError: false },
        },
      });
    case SEND_FEEDBACK_RATING_SUCCESS:
      return update(state, {
        uiState: { $set: { loading: false, ratingSent: true } },
      });
    case SEND_FEEDBACK_RATING_FAILED:
      return update(state, {
        uiState: {
          $set: { loading: false, ratingSent: false, unexpectedError: true },
        },
      });
    case SEND_FEEDBACK_TEXT_STARTED:
      return update(state, {
        uiState: {
          loading: { $set: true },
          textSent: { $set: false },
          unexpectedError: { $set: false },
        },
      });
    case SEND_FEEDBACK_TEXT_SUCCESS:
      return update(state, {
        uiState: {
          loading: { $set: false },
          textSent: { $set: true },
          unexpectedError: { $set: false },
        },
      });
    case SEND_FEEDBACK_TEXT_FAILED:
      return update(state, {
        uiState: {
          loading: { $set: false },
          textSent: { $set: false },
          unexpectedError: { $set: true },
        },
      });
    default:
      return state;
  }
};

export default feedbackFragment;
