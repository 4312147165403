import {
  ADD_OVERLAY_ACTION,
  REMOVE_OVERLAY_ACTION,
  UPDATE_OVERLAY_ACTION,
} from "./constants";

export function addOverlay(content, scrollPosition) {
  return {
    type: ADD_OVERLAY_ACTION,
    payload: { content, scrollPosition },
  };
}

export function addExtendedOverlay({
  title,
  content,
  iconName,
  scrollPosition,
}) {
  return {
    type: ADD_OVERLAY_ACTION,
    payload: { title, content, iconName, scrollPosition },
  };
}

export function removeOverlay() {
  return { type: REMOVE_OVERLAY_ACTION };
}

export function updateOverlay(content) {
  return {
    type: UPDATE_OVERLAY_ACTION,
    payload: { content },
  };
}

export function updateExtendedOverlay({ content, title, iconName }) {
  return {
    type: UPDATE_OVERLAY_ACTION,
    payload: { title, content, iconName },
  };
}
