import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import imageSizes from "shared/utils/imageSizes";
import s from "./ElementDoubleTeaser.module.css";
import ContentTeasers from "../../Teasers/ContentTeasers";
import ContentSpacing from "../../ContentSpacing";

const ElementDoubleTeaser = ({ fullWidth, ...props }) => {
  const imgSizes = imageSizes({
    xxxl: "500px",
    xxl: "31.5vw",
    xl: fullWidth ? "27.2vw" : "30.5vw",
    l: fullWidth ? "30.7vw" : "34.8vw",
    m: "46vw",
    default: "100vw",
  });
  return (
    <ContentSpacing
      alignment="narrowcolumns"
      className={s.spacing}
      fullWidth={fullWidth}
      data-test-sell-element-double-teaser
    >
      <ContentTeasers
        {...props}
        type="two"
        fullWidth={fullWidth}
        imgSizes={imgSizes}
      />
    </ContentSpacing>
  );
};

ElementDoubleTeaser.propTypes = {
  fullWidth: PropTypes.bool,
};

export default ElementDoubleTeaser;
