import { HTTP_CONTEXT__DEVICE_IDENT } from "../../utils/httpContext/constants";

const globalDeviceIdentHandler = {
  all: (content) => {
    const deviceIdent = content?.body?.page?.di;
    if (deviceIdent) {
      return { type: HTTP_CONTEXT__DEVICE_IDENT, payload: deviceIdent };
    }
    return null; // don't return any action
  },
};

export default globalDeviceIdentHandler;
