const initialState = {
  data: {
    page: {
      headline: "",
      back: {},
    },
    wishList: {
      headline: "",
      emptyWishListMessage: "",
      products: [],
    },
  },
  isLoading: false,
};

export default initialState;
