import React from "react";
import classNames from "classnames/bind";
import s from "./Accordion.module.css";

const cx = classNames.bind(s);

type Props = {
  children?: React.ReactElement[];
  currentValue?: string;
  onChange?: (...args: unknown[]) => unknown;
  radioName?: string;
  skeleton?: boolean;
  smallAccordion?: boolean;
  testStelarAccordionId?: string;
};

function Accordion({
  children,
  currentValue,
  onChange = () => {},
  radioName,
  skeleton,
  smallAccordion,
  testStelarAccordionId,
}: Props) {
  const accordionClasses = cx({ horizontalAtLargeScreens: smallAccordion });
  const result = [];
  React.Children.forEach(children, (item, key) => {
    if (item) {
      const props = {
        controlId: `${key}`,
        isInput: radioName !== undefined,
        key: `${item.key || key}`,
        name: radioName,
        onClick: onChange,
        open: currentValue
          ? item.props.value === currentValue
          : item.props.open,
        skeleton,
        smallAccordionItem: smallAccordion,
      };
      result.push(React.cloneElement(item, props));
    }
  });
  return (
    <div
      className={accordionClasses}
      data-test-stelar-accordion-id={testStelarAccordionId}
    >
      {result}
    </div>
  );
}

export default Accordion;
