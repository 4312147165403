/* eslint-disable no-console */
import logger from "../logger";
import { Headers } from "./headers";

const log = logger(module);
let xsrfToken = null;

export const enableVerboseLogging = (instance) => {
  instance.interceptors.request.use(
    (req) => {
      console.info(
        `[Axios][Request] ${req.method?.toLocaleUpperCase()} ${req.url}`
      );
      console.info("[Axios][Request] headers:", req.headers);
      console.info("[Axios][Request] params:", req.params);
      console.info("[Axios][Request] data:", req.data || {});
      return req;
    },
    (error) => {
      log.error("Request error");
      log.error(error);
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (res) => {
      console.info(
        `[Axios][Response] ${res.config?.method?.toLocaleUpperCase()} ${
          res.config?.url
        } ${res.status}`
      );
      console.info("[Axios][Response] headers:", {
        ...res.config?.headers,
        ...res.headers,
      });
      console.info("[Axios][Response] params:", res.params);
      console.info("[Axios][Response] data:", res.data || {});
      return res;
    },
    (error) => {
      log.error("Response error");
      log.error(error);
      return Promise.reject(error);
    }
  );
};

export const useCsrfToken = (instance) => {
  instance.interceptors.request.use(
    (req) => {
      if (xsrfToken) {
        req.headers = { ...req.headers, [Headers.X_XSRF_TOKEN]: xsrfToken };
      }
      return req;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (res) => {
      const xsrfHeaderValue = res.headers[Headers.X_XSRF_TOKEN];
      if (xsrfHeaderValue) {
        xsrfToken = xsrfHeaderValue;
      }
      return res;
    },
    (error) => Promise.reject(error)
  );
};
