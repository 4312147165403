import React from "react";
import Input from "./Input";

type Props = {
  autoComplete?: string;
  error?: boolean | string;
} & React.ComponentPropsWithoutRef<typeof Input>;

/**
 * InputDateDay
 */
function InputDateDay({
  autoComplete = "bday-day",
  error = "",
  ...attrs
}: Props) {
  return (
    <Input
      autoComplete={autoComplete}
      className="dateDay"
      error={error}
      maxLength={2}
      size={2}
      type="tel"
      {...attrs}
    />
  );
}

export default InputDateDay;
