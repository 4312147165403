import { setPageTitle } from "shared/webbridge/actions";
import backendUrl from "../utils/AseBackendUrls";
import {
  NEWSLETTER_UNSUBSCRIBE_PAGE__CHANGE_EMAIL,
  NEWSLETTER_UNSUBSCRIBE_PAGE__LOAD_PAGE,
  NEWSLETTER_UNSUBSCRIBE_PAGE__REQUEST_NEWSLETTER,
  NEWSLETTER_UNSUBSCRIBE_PAGE__REQUEST_NEWSLETTER_FAILED,
  NEWSLETTER_UNSUBSCRIBE_PAGE__VALIDATE_ALL,
  NEWSLETTER_UNSUBSCRIBE_PAGE__VALIDATE_EMAIL,
} from "../constants";

export function loadNewsletterPage(apiClient, query) {
  return apiClient.get(
    backendUrl.NEWSLETTER_UNSUBSCRIBE,
    {
      ok: (content) => [
        {
          type: NEWSLETTER_UNSUBSCRIBE_PAGE__LOAD_PAGE,
          payload: content.body,
        },
        setPageTitle(content.body?.page?.headData?.title),
      ],
    },
    {
      httpClientOptions: {
        params: query,
      },
    }
  );
}

export function doRequestUnscribeNewsletter({ event, email }, apiClient) {
  return (dispatch, getState) => {
    event.preventDefault();
    dispatch({
      type: NEWSLETTER_UNSUBSCRIBE_PAGE__VALIDATE_ALL,
    });
    const state = getState();
    const postData = { email };
    if (state.newsletterUnsubscribePage.submitable) {
      dispatch(
        apiClient.post(backendUrl.NEWSLETTER_UNSUBSCRIBE, postData, {
          ok: (content) => [
            {
              type: NEWSLETTER_UNSUBSCRIBE_PAGE__REQUEST_NEWSLETTER,
              payload: content.body,
            },
            setPageTitle(content.body?.page?.headData?.title),
          ],
          badRequest: (content) => [
            {
              type: NEWSLETTER_UNSUBSCRIBE_PAGE__REQUEST_NEWSLETTER_FAILED,
              payload: content.body,
            },
            setPageTitle(content.body?.page?.headData?.title),
          ],
        })
      );
    }
    return true;
  };
}

export function changeEmail(email) {
  return {
    type: NEWSLETTER_UNSUBSCRIBE_PAGE__CHANGE_EMAIL,
    value: email,
  };
}

export function validateInput(inputPath) {
  return {
    type: NEWSLETTER_UNSUBSCRIBE_PAGE__VALIDATE_EMAIL,
    inputPath,
  };
}
