import update from "immutability-helper";
import {
  LOGIN_PAGE__LOAD_LOGIN_PAGE,
  LOGIN_PAGE__LOGIN_FAILED,
  LOGIN_PAGE__LOGIN,
  LOGIN_PAGE__REGISTER_FAILED,
  LOGIN_PAGE__REGISTER,
  LOGIN_PAGE__TOGGLE_ACCORDION,
} from "../constants";
import initialState from "./initialState";

export default (state = initialState, action) => {
  let actionPayload;

  switch (action.type) {
    case LOGIN_PAGE__LOGIN:
      return update(state, { loginStarted: { $set: true } });
    case LOGIN_PAGE__LOGIN_FAILED:
      return update(state, {
        $merge: action.payload,
        loginStarted: { $set: false },
      });
    case LOGIN_PAGE__LOAD_LOGIN_PAGE:
      actionPayload = { ...action.payload };
      actionPayload.registerStarted = false;
      actionPayload.loginStarted = false;
      return update(state, { $set: actionPayload });
    case LOGIN_PAGE__TOGGLE_ACCORDION:
      return update(state, { openedItem: { $set: action.itemToOpen } });
    case LOGIN_PAGE__REGISTER:
      return update(state, { registerStarted: { $set: true } });
    case LOGIN_PAGE__REGISTER_FAILED:
      return update(state, {
        $merge: action.payload,
        registerStarted: { $set: false },
      });
    default:
      return state;
  }
};
