import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { connect } from "react-redux";

type Props = {
  isLoading?: boolean;
};

function Testability({ isLoading }: Props) {
  return <meta name="sell:testable" content={isLoading ? "false" : "true"} />;
}

const mapStateToProps = (state) => ({
  isLoading: state.testability.isLoading,
});

Testability.defaultProps = {
  isLoading: false,
};

export default connect(mapStateToProps)(Testability);
