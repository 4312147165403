import React from "react";
import classnames from "classnames/bind";

import s from "./MPointLogo.module.css";

const cx = classnames.bind(s);

type Props = {
  subtitle?: string;
  size?: "small" | "large" | "teaser";
};

function MPointLogo({ subtitle, size, ...attrs }: Props) {
  return (
    <div className={s.root} {...attrs}>
      <svg
        className={cx("root", { [size]: true })}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
        aria-label="Manufactum Product logo"
      >
        <rect
          x="0"
          y="0"
          width="256"
          height="256"
          style={{
            fill: "#005232",
          }}
        />

        <path
          style={{ fill: "#ffffff" }}
          d="M196.52,151.85c-11.6,0-21,9.42-21,21.03c0,11.61,9.41,21.03,21,21.03c11.59,0,21-9.42,21-21.03   C217.52,161.27,208.12,151.85,196.52,151.85"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M72.61,57.8c-4.99-0.43-11.96,1.57-15.58,3.29c-3.6,1.72-7.96,5.29-9.68,11.18   c-1.72,5.91-0.81,26.84-1.14,30.15c-0.35,3.55-2.11,2.54-2.83,6.9c-2.44,15.15-2.77,35.45-3.19,50.45   c-0.06,2.38-2.15,4.34-2.28,6.47c-0.1,1.07-0.12,2.21-0.12,3.39c0.06,5.58,0.89,11.74,1.32,13.7c0.35,1.72,2.34,3.06,5.05,4.05   c1.05,0.37,2.28,3.28,3.5,3.55c6.16,1.45,14.54,1.65,16.61,1.03c3.56-1.07,10.78-10.95,11.01-14.5c0.23-3.55-2.59-10.6-2.19-17.97   c0.43-7.38,0.66-30.87,0.74-38.08c0.02-0.85,0.56-0.41,0.58-0.47c1.94,3.53,5.77,22.23,9.45,29.92c2.34,4.83,4.63,9.11,8.23,10.02   c2.71,0.68,8.83-2.13,10.45-3.08c1.76-1.05,6.33-0.85,8.09-2.17c5.17-3.9,11.48-14.79,14.89-21.16c0.29-0.58,0.64-0.48,0.66,0   c0.29,4.55-0.99,14.75-1.78,25.64c-0.77,10.89,1.82,19.28,2.54,22.29c0.79,3.24,2.92,7.13,5.27,10.54   c2.07,2.98,18.02,2.81,22.76-0.45c2.46-1.67,4.32-4.67,4.26-7.25c-0.12-4.67,2.88-6.26,1.99-11.45c-1.74-9.88-0.15-22.4-0.62-34.36   c-0.5-13.88-2.92-27.31-2.98-39.44c-0.06-11.32-1.68-19.01-3.27-20.04c-3.16-2.05-3.7-5.31-6.83-6.4   c-3.12-1.07-13.14,1.82-16.69,3.57c-2.57,1.26-4.12,4.32-5.69,6.53c-1.1,1.57-3.17,1.24-4.67,3.51   c-3.76,5.7-12.87,23.78-17.23,32.48c-0.33,0.66-0.7,0.66-0.87-0.02c-3.27-13.06-11.3-39.94-15.45-49.44   c-1.16-2.66-4.24-3.16-5.5-5.16C80.47,63.53,78.4,58.3,72.61,57.8"
        />
      </svg>
    </div>
  );
}

export default MPointLogo;
