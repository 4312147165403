import React from "react";
import classNames from "classnames/bind";
import s from "./Text.module.css";

type Props = {
  children: React.ReactNode;
  className?: string;
  em?: boolean;
  inverted?: boolean;
  highContrast?: boolean;
  light?: boolean;
  normal?: boolean;
  skeleton?: boolean;
  small?: boolean;
  strong?: boolean;
  tag?: string;
  smallbold?: boolean;
  outline?: boolean;
  convertNewline?: boolean;
};

/**
 * Text
 */
function Text({
  children,
  className,
  em,
  inverted,
  highContrast,
  light,
  normal,
  skeleton,
  small,
  strong,
  tag,
  smallbold,
  outline,
  convertNewline,
  ...attrs
}: Props) {
  if (!strong && !em && !normal && !light && !small && !smallbold) {
    console.warn(
      "<Text /> must always have one qualifier attribute (e.g. <Text normal />)"
    );
  }

  const cx = classNames.bind(s);
  let classNamesString = cx("root", {
    em,
    inverted,
    highContrast,
    light,
    normal,
    skeleton,
    small,
    strong,
    smallbold,
    outline,
  });

  classNamesString = cx(classNamesString, className);

  let tagOverride = "span";
  if (em) tagOverride = "em";
  if (small) tagOverride = "small";
  if (strong) tagOverride = "strong";
  if (smallbold) tagOverride = "smallbold";
  if (tag) {
    tagOverride = tag;
  }

  if (convertNewline && typeof children === "string") {
    return React.createElement(tagOverride, {
      ...attrs,
      className: classNamesString,
      dangerouslySetInnerHTML: { __html: children.replace(/\n/g, "<br />") },
    });
  }

  return React.createElement(
    tagOverride,
    { ...attrs, className: classNamesString },
    children
  );
}

export default Text;
