import { TESTABILITY_BEGIN, TESTABILITY_END } from "./constants";

const initialState = {
  isLoading: false,
  requests: 0,
};

const updateGlobalRequestCountValue = (addedValue) => {
  if (typeof document === "undefined") {
    return;
  }
  if (!document.testability || !document.testability.requestCount) {
    document.testability = { requestCount: 0 };
  }

  const currentCount = document.testability.requestCount;
  const newCount = currentCount + addedValue;
  document.testability.requestCount = newCount;
};

const testability = (state = initialState, action) => {
  switch (action.type) {
    case TESTABILITY_BEGIN: {
      const requestCount = state.requests + 1;
      updateGlobalRequestCountValue(1);

      const updatedState = {
        isLoading: true,
        requests: requestCount,
      };
      return updatedState;
    }
    case TESTABILITY_END: {
      const currentRequestCount = state.requests - 1;
      updateGlobalRequestCountValue(-1);
      const updatedState = {
        isLoading: currentRequestCount !== 0,
        requests: currentRequestCount,
      };
      return updatedState;
    }
    default:
      return state;
  }
};

export default testability;
