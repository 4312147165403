import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import imageSizes from "shared/utils/imageSizes";
import s from "./ElementTripleTeaser.module.css";
import ContentTeasers from "../../Teasers/ContentTeasers";
import ContentSpacing from "../../ContentSpacing";

function ElementTripleTeaser({ fullWidth, disableSpacings, ...props }) {
  const imgSizes = disableSpacings
    ? imageSizes({
        xxxl: "500px",
        m: "33vw",
        default: "100vw",
      })
    : imageSizes({
        xxxl: "310px",
        xxl: "22.22vw",
        xl: fullWidth ? "22.22vw" : "19vw",
        l: fullWidth ? "22.22vw" : "25vw",
        m: "33vw",
        default: "100vw",
      });
  return (
    <ContentSpacing
      alignment={disableSpacings ? "none" : "narrowcolumns"}
      className={s.spacing}
      fullWidth={fullWidth}
      data-test-sell-element-triple-teaser
    >
      <ContentTeasers
        {...props}
        type="three"
        fullWidth={fullWidth}
        imgSizes={imgSizes}
        customStyle="tripleTeaser"
      />
    </ContentSpacing>
  );
}

ElementTripleTeaser.propTypes = {
  fullWidth: PropTypes.bool,
  disableSpacings: PropTypes.bool,
};

export default ElementTripleTeaser;
