export const PATH = "https://scripts.sirv.com/sirvjs/v3/sirv.js";

export const IMAGE_OPTIONS = "?w=2000&canvas.aspectratio=1:1";

export const IMAGE_SIZES =
  "(min-width: 1500px) 720px, (min-width: 1250px) 50vw, (min-width: 1000px) 58.333vw, (min-width: 840px) 66.666vw, (min-width: 750px) 66.666vw, (min-width: 500px) 58.333vw, 100vw";

export const thumbnailTracking = (position, length) => ({
  googleTagManager: {
    containerId: window.gtmId,
    payload: {
      event: "sirv-viewer-thumbnail-click",
      image_position: `${position}|${length}`,
    },
  },
});
