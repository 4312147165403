const initialState = {
  registerStarted: false,
  months: [{ label: "0", value: "0" }],
  login: {
    headline: "",
    email: { value: "" },
    password: { value: "" },
    passwordRepetition: { value: "" },
  },
  addressForm: {
    personal: {
      headline: "",
      salutations: {
        value: "",
        label: "",
        valid: "",
        error: "",
        values: [
          {
            label: "",
            value: "",
            disabled: false,
          },
        ],
      },
      firstName: {
        value: "",
        label: "",
        required: true,
        error: "",
      },
      lastName: {
        value: "",
        label: "",
        required: true,
        error: "",
      },
      birthdate: {
        label: "",
        error: "",
        errorEmpty: "",
        errorInvalid: "",
        required: true,
        day: {
          label: "",
          value: "",
        },
        month: {
          label: "",
          value: "",
          values: [],
        },
        year: {
          label: "",
          value: "",
        },
      },
    },
    address: {
      headline: "",
      streetInverse: false,
      cityInverse: false,
      streetAppendix: {
        value: "",
        label: "",
        required: false,
        error: "",
      },
      streetName: {
        value: "",
        label: "",
        required: true,
        error: "",
      },
      houseNumber: {
        value: "",
        label: "",
        required: true,
        error: "",
      },
      houseNumber2: {
        value: "",
        label: "",
        required: false,
        error: "",
      },
      states: {
        value: "",
        label: "",
        valid: "",
        required: "",
        error: "",
        values: [
          {
            label: "",
            value: "",
            disabled: false,
          },
        ],
      },
      zipCode: {
        value: "",
        label: "",
        required: true,
        error: "",
      },
      city: {
        value: "",
        label: "",
        required: true,
        error: "",
      },
      countries: {
        value: "",
        label: "",
        valid: "",
        required: "",
        error: "",
        values: [
          {
            label: "",
            value: "",
            disabled: false,
          },
        ],
      },
      phoneNumber: {
        value: "",
        label: "",
        required: false,
        error: "",
      },
    },
  },
  page: {
    back: {},
    submit: {},
    submitButton: {},
    headline: "",
  },
  submitable: true,
  headline: "",
  newsletter: {},
  datenschutz: {
    label: "",
  },
  registerForm: {
    submitButton: {
      label: "",
      link: "",
    },
    values: [],
    structure: [],
  },
};

export default initialState;
