import React from "react";
import classnames from "classnames/bind";

import s from "./MagazinProductLogo.module.css";

const cx = classnames.bind(s);

type Props = {
  subtitle?: string;
  size?: "small" | "medium" | "large";
};

function MagazinProductLogo({ subtitle, size, ...attrs }: Props) {
  return (
    <div className={s.root} {...attrs}>
      <svg
        className={cx("root", { [size]: true })}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
        aria-label="Magazin Product Logo"
      >
        <rect
          x="2.45"
          y="2.46"
          width="245.08"
          height="245.08"
          style={{
            fill: "#FFFFFF",
          }}
        />
        <rect
          className={s.innerrect}
          {...attrs}
          x="2.45"
          y="2.45"
          width="245.1"
          height="245.1"
        />
        <ellipse
          className={s.innereclipse}
          {...attrs}
          transform="matrix(1.253143e-03 -1 1 1.253143e-03 -4.2844 250.3904)"
          cx="123.21"
          cy="127.34"
          rx="90.06"
          ry="90.06"
        />
        <path
          className={s.innerpath}
          {...attrs}
          d="M74.78,171.53V80.84c0-5.15,4.23-7.08,8.13-7.41H93c6.82,0,8.78,5.47,9.76,7.72l9.76,16.72
        c2.92,5.47,4.55,3.55,7.15,0.31l12.7-19.62c2.92-4.49,7.8-5.13,9.76-5.13l24.73-0.31c4.88,0,5.53,6.74,5.53,13.5v82.65
        c0,10.29-6.84,9.02-10.09,9.02h-9.76c-4.88,0-6.51-3.86-6.84-6.76V87.58c0-6.74-2.59-3.86-5.84-0.31l-20.19,31.2
        c-2.92,4.49-8.78,1.27-10.74-2.27L93,85.98c-1.31-2.25-6.19-4.51-5.53,2.57v83.63C86.5,179.89,75.76,181.5,74.78,171.53"
        />
      </svg>
    </div>
  );
}

export default MagazinProductLogo;
