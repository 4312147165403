import React from "react";
import classNames from "classnames/bind";
import s from "./TextArea.module.css";

const cx = classNames.bind(s);

type Props = {
  error?: boolean | string;
  id: string;
} & React.ComponentPropsWithoutRef<"textarea">;

/**
 * TextArea
 */
function TextArea({ error, id, ...attrs }: Props) {
  const classnames = cx("root", {
    error,
  });
  return (
    <textarea
      {...attrs}
      aria-describedby={error ? `${id}-alert` : null}
      className={classnames}
      id={id}
    />
  );
}

TextArea.defaultProps = {
  error: "",
};

export default TextArea;
