import update from "immutability-helper";
import { LOAD_LANGUAGE_SUGGESTIONS_SUCCESS } from "../../pages/constants";

const initialState = {
  data: null,
};

const languageSuggestionFragment = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case LOAD_LANGUAGE_SUGGESTIONS_SUCCESS: {
      return update(state, { $set: payload });
    }

    default:
      return state;
  }
};

export default languageSuggestionFragment;
