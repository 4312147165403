// vendor
import update from "immutability-helper";
// ase
import validateQuantityField from "../../components/validation/numerics";
import { validateInput } from "../utils/Validators";
import {
  BASKET_PAGE__LOAD_BASKET,
  BASKET_PAGE__NAVIGATE_WHEN_UPDATED,
  BASKET_PAGE__SHOW_MESSAGE,
  BASKET_PAGE__TOGGLE_AGE_RELATED_ADVICE,
  BASKET_PAGE__UPDATE_LINE_ITEM,
  BASKET_PAGE__UPDATE_LINE_ITEM_FAILED,
  BASKET_PAGE__UPDATE_LINE_ITEM_QUANTITY,
  BASKET_PAGE__VALIDATE_INPUT,
} from "../constants";
// component
import initialState from "./initialState";

const basket = (state = initialState, action) => {
  const items = {};
  let index;
  switch (action.type) {
    case BASKET_PAGE__LOAD_BASKET:
      return update(state, {
        data: { $merge: action.payload },
      });
    case BASKET_PAGE__TOGGLE_AGE_RELATED_ADVICE: {
      index = state.data.basket.lineItems.findIndex(
        (li) => li.lineItemId === action.lineItemId
      );
      return update(state, {
        data: {
          basket: {
            lineItems: {
              [index]: {
                ageRelatedAdviceVisible: {
                  $apply: (visible) => !visible,
                },
              },
            },
          },
        },
      });
    }
    case BASKET_PAGE__UPDATE_LINE_ITEM_QUANTITY:
      index = state.data.basket.lineItems.findIndex(
        (li) => li.lineItemId === action.lineItemId
      );
      items[index] = {
        quantity: {
          value: { $set: action.quantity },
          valid: {
            $set: validateQuantityField(action.quantity, action.fieldType),
          },
          hasChanged: { $set: true },
        },
      };
      return update(state, {
        data: { basket: { lineItems: items } },
      });
    case BASKET_PAGE__UPDATE_LINE_ITEM:
      return update(state, {
        data: {
          navigateWhenUpdated: { $set: false },
          basket: {
            lineItems: state.data.basket.lineItems.map(() => ({
              quantity: {
                hasChanged: { $set: false },
              },
            })),
          },
        },
      });
    case BASKET_PAGE__UPDATE_LINE_ITEM_FAILED:
      index = state.data.basket.lineItems.findIndex(
        (li) => li.lineItemId === action.payload?.lineItemId
      );
      items[index] = {
        quantity: {
          serverValidationMessage: { $set: action.payload?.errorMessage },
          value: { $set: action.payload?.quantity },
          valid: {
            $set: false,
          },
          hasChanged: { $set: false },
        },
      };
      return update(state, {
        data: {
          navigateWhenUpdated: { $set: false },
          basket: { lineItems: items },
        },
      });
    case BASKET_PAGE__VALIDATE_INPUT:
      return validateInput(state, action);
    case BASKET_PAGE__SHOW_MESSAGE:
      return update(state, {
        data: {
          page: {
            globalMessage: { $set: action.message },
          },
        },
      });
    case BASKET_PAGE__NAVIGATE_WHEN_UPDATED:
      return update(state, {
        data: { navigateWhenUpdated: { $set: true } },
      });
    default:
      return state;
  }
};

export default basket;
