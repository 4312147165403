import React from "react";
import "./Font.theme";
import s from "./AppRoot.module.css";

type Props = {
  children?: React.ReactNode;
};

function AppRoot({ children }: Props) {
  return (
    <div id="appRoot" className={s.root}>
      {children}
    </div>
  );
}

export default AppRoot;
