/* eslint-disable max-len */
import React from "react";
import classnames from "classnames/bind";

import s from "./CompanyBadge.module.css";

const cx = classnames.bind(s);

type Props = {
  company?: "magazin";
  size?: "small" | "medium" | "large";
};

function CompanyBadge({ company, size }: Props) {
  switch (company) {
    case "magazin":
      return (
        <svg
          className={cx("root", { [size]: true })}
          viewBox="0 0 20 20"
          aria-label="Magazin Badge"
          stroke="0"
        >
          <title>Magazin Badge</title>
          <path
            fill="#333"
            d="M5 14.7V5.3c0-.5.4-.7.8-.8h1c.7 0 .9.6 1 .8l1 1.7c.3.6.5.4.7 0l1.3-2c.3-.5.8-.5 1-.5h2.5c.5 0 .6.7.6 1.4v8.6c0 1.1-.7.9-1 .9h-1c-.5 0-.7-.4-.7-.7V6c0-.7-.3-.4-.6 0l-2 3.2c-.3.5-.9.2-1.1-.2L6.9 5.9c-.2-.3-.7-.5-.6.2v8.7c-.1.8-1.2 1-1.3-.1"
          />
          <path
            fill="#333"
            d="M10 19.8c-5.4 0-9.8-4.4-9.8-9.7S4.6.3 10 .3s9.8 4.4 9.8 9.8-4.4 9.7-9.8 9.7zm0-18c-4.5 0-8.2 3.7-8.2 8.2s3.7 8.2 8.2 8.2 8.2-3.7 8.2-8.2-3.7-8.2-8.2-8.2z"
          />
        </svg>
      );

    default:
      return null;
  }
}

export default CompanyBadge;
