/* eslint-disable */
const breakpoints = {
  S: 300,
  M: 500,
  L: 750,
  XL: 1000,
  XXL: 1250,
  XXXL: 1500,
};

const result = {};
result.breakpoint = {};
result.breakpoints = breakpoints;

const upTo = (x) => `all and (max-width: ${x - 1}px)`;
const andUp = (x) => `all and (min-width: ${x}px)`;
const range = (x, y) =>
  `all and (min-width: ${x}px) and (max-width: ${y - 1}px)`;

let lastName = null;
for (const name of Object.keys(breakpoints)) {
  const point = breakpoints[name];
  result[`up_to_${name}`] = upTo(point);
  result[`${name}_and_up`] = andUp(point);
  for (const upperName of Object.keys(breakpoints)) {
    const upperPoint = breakpoints[upperName];
    if (upperPoint > point) {
      result[`${name}_to_${upperName}`] = range(point, upperPoint);
    }
  }
  result.breakpoint[name.toLowerCase()] = { min: point };
  if (lastName) {
    result.breakpoint[lastName.toLowerCase()].max = point - 1;
  }
  lastName = name;
}

module.exports = result;

/* eslint-enable */
