// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { useFormContext } from "react-hook-form";
// mf-stelar
import FormRow from "stelar/components/FormRow";
import { Button } from "stelar/components/Button";
// ase
import { buildFormRow } from "../../../util/reduxFormUtils/reduxFormRowFieldBuilder";
import AddressFormRow from "../../../components/AddressForm/AddressFormRow";

const PasswordChangeFormContent = ({ formDefinition, formName }) => {
  const context = useFormContext();
  const layout = "standard";

  const { structure, values, submit } = formDefinition;

  const valueMap = values.reduce((map, valueObject) => {
    // eslint-disable-next-line no-param-reassign
    map[valueObject.name] = valueObject;
    return map;
  }, {});

  return (
    <>
      {structure.map((element) => {
        const row = [element];

        return (
          <AddressFormRow
            key={element.name}
            row={row}
            valueMap={valueMap}
            formName={formName}
            layout={layout}
          >
            {buildFormRow({
              formName,
              row,
              valueMap,
              context,
            })}
          </AddressFormRow>
        );
      })}

      <FormRow>
        <Button
          data-test-ase-fragment-password-change-submit
          disabled={submit.disabled}
          prio1
          text={submit.label}
          type="submit"
        />
      </FormRow>
    </>
  );
};

export default PasswordChangeFormContent;

PasswordChangeFormContent.propTypes = {
  formDefinition: PropTypes.object,
  formName: PropTypes.string.isRequired,
};
