import React from "react";
import classnames from "classnames/bind";
// @ts-expect-error
import s from "./HeaderIcon.theme";

const cx = classnames.bind(s);

type Props = {
  active?: boolean;
  icon: React.ReactElement;
  link?: string;
  onClick?: (...args: unknown[]) => unknown;
  onTouchEnd?: (...args: unknown[]) => unknown;
  strongText?: boolean;
  testSelector?: string;
  text?: string;
};

function HeaderIcon({
  active,
  icon,
  link,
  onClick,
  onTouchEnd,
  strongText,
  testSelector,
  text,
}: Props) {
  const testSelectorProp = { [testSelector]: active ? "active" : "inactive" };
  return (
    <a
      {...testSelectorProp}
      className={cx("root", { active, strongText })}
      href={link}
      onClick={onClick}
      onTouchEnd={onTouchEnd}
      aria-label={text}
    >
      {React.cloneElement(icon, { className: s.icon })}
      <span className={s.text}>{text}</span>
    </a>
  );
}

export default HeaderIcon;
