import PropTypes from "prop-types";
import React, { useState } from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import imageSizes from "shared/utils/imageSizes";
import Modal from "stelar/components/Modal";
import { SellLayerContentFragment } from "shared/components/registry";
import AsyncStage from "../../AsyncStage";

import s from "./ElementCategoryBanner.module.css";

const cx = classnames.bind(s);

function ElementCategoryBanner({
  image = {},
  breakpointImages = [],
  layerCategoryId,
  fullWidth,
  lazy,
  onImageLoad,
  loadedImages,
}) {
  const [modalOpened, setModalOpened] = useState(false);

  const onClose = () => setModalOpened(false);

  const onLinkClick = layerCategoryId
    ? (e) => {
        e.preventDefault();
        setModalOpened(true);
      }
    : () => {};
  const link = layerCategoryId
    ? {
        link: `/category-c${layerCategoryId}`,
      }
    : {};
  const XXLandUpIndentFactor = fullWidth ? 1 : Number((1 - 2 / 12).toFixed(6));
  const LtoXXLIndentFactor = fullWidth ? 1 : Number((1 - 3 / 12).toFixed(6));

  const imgSizes = imageSizes({
    xxxl: `${1500 * XXLandUpIndentFactor}px`,
    xxl: `${100 * XXLandUpIndentFactor}vw`,
    l: `${100 * LtoXXLIndentFactor}vw`,
    default: "100vw",
  });
  return (
    <div
      className={cx("spacing", {
        singleElement: true,
        multipleElements: false,
      })}
      data-test-sell-element-category-banner
    >
      <AsyncStage
        slides={[
          {
            image,
            breakpointImages,
            link,
          },
        ]}
        lazy={lazy}
        imgSizes={imgSizes}
        onImageLoad={onImageLoad}
        loadedImages={loadedImages}
        onLinkClick={onLinkClick}
      />
      <Modal opened={modalOpened} onClose={onClose}>
        {modalOpened && (
          <SellLayerContentFragment
            lazy
            onCloseModal={onClose}
            content={layerCategoryId}
          />
        )}
      </Modal>
    </div>
  );
}

ElementCategoryBanner.propTypes = {
  breakpointImages: PropTypes.array,
  image: PropTypes.object,
  layerCategoryId: PropTypes.string,
  fullWidth: PropTypes.bool,
  lazy: PropTypes.bool,
  onImageLoad: PropTypes.func,
  loadedImages: PropTypes.object,
};

export default ElementCategoryBanner;
