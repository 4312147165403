// vendor
import track from "shared/tracking";
import { connect } from "react-redux";
import { provideHooks } from "redial";
// ase
import loadReducedLayout from "../../components/ReducedLayout/actions";
import frontendUrls from "../utils/AseFrontendUrls";
import LoginPage from "./components/LoginPage";
// component
import { loadLoginPage, login, register, toggleAccordion } from "./actions";

const redial = {
  fetch: ({ apiClient, dispatch, getState, query }) =>
    Promise.all([
      dispatch(loadLoginPage(apiClient, query)),
      dispatch(loadReducedLayout(apiClient, getState)),
    ]),
  defer: ({ getState }) => track(getState()?.loginPage?.page?.trackingInfo),
};

const mapDispatchToProps = (dispatch, props) => ({
  doLogin: (args) => dispatch(login(args, props.apiClient)),
  doRegister: (args) => dispatch(register(args, props.apiClient)),
  toggleAccordion: (itemToOpen) => dispatch(toggleAccordion(itemToOpen)),
});

const mapStateToProps = (state) => ({
  ...state.loginPage,
  otrToken: state.httpContext.otrToken,
  reducedLayout: state.reducedLayout,
  isLoading: state.loginPage.page.headData.title === "",
});

export default {
  component: provideHooks(redial)(
    connect(mapStateToProps, mapDispatchToProps)(LoginPage)
  ),
  path: frontendUrls.LOGIN,
};
