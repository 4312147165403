import {
  WEB_BRIDGE__UPDATE_BASKET_COUNT,
  WEB_BRIDGE__UPDATE_SHARING,
  WEB_BRIDGE__UPDATE_TITLE,
  WEB_BRIDGE__UPDATE_INFO,
  WEB_BRIDGE__UPDATE_WISHLIST_COUNT,
} from "./constants";

const APP_BASKET = "/app/basket/count";
const APP_WISHLIST = "/app/wishlist/count";
const APP_INFO = "/app/info";

export function getBasketCount(apiClient, dispatch, basketCount, isNavigating) {
  if (isNavigating && basketCount !== undefined) {
    return;
  }
  return dispatch(
    apiClient.get(
      APP_BASKET,
      {
        ok: (content) => ({
          type: WEB_BRIDGE__UPDATE_BASKET_COUNT,
          payload: content.body?.totalLineItems,
        }),
      },
      { apiBase: "/api/ase" }
    )
  );
}

export function getWishlistCount(
  apiClient,
  dispatch,
  wishlistCount,
  isNavigating
) {
  if (isNavigating && wishlistCount !== undefined) {
    return;
  }
  return dispatch(
    apiClient.get(
      APP_WISHLIST,
      {
        ok: (content) => ({
          type: WEB_BRIDGE__UPDATE_WISHLIST_COUNT,
          payload: content.body?.totalLineItems,
        }),
      },
      { apiBase: "/api/ase" }
    )
  );
}

export function getInfo(apiClient, dispatch, info, isNavigating) {
  if (isNavigating && info !== undefined) {
    return;
  }
  return dispatch(
    apiClient.get(
      APP_INFO,
      {
        ok: (content) => ({
          type: WEB_BRIDGE__UPDATE_INFO,
          payload: content.body,
        }),
        unauthorized: () => ({
          type: WEB_BRIDGE__UPDATE_INFO,
          payload: null,
        }),
      },
      { apiBase: "/api/ase" }
    )
  );
}

export function setPageTitle(title) {
  if (title) {
    return { type: WEB_BRIDGE__UPDATE_TITLE, payload: title };
  }
  return null;
}

export function setSharingInformation(info) {
  if (info?.title && info?.link) {
    return { type: WEB_BRIDGE__UPDATE_SHARING, payload: info };
  }
  return null;
}

export function triggerWebBridgeCalls(apiClient, dispatch, state) {
  return [
    getBasketCount(apiClient, dispatch, state?.basketCount, true),
    getWishlistCount(apiClient, dispatch, state?.wishlistCount, true),
    getInfo(apiClient, dispatch, state?.info, true),
  ];
}
