// ase
import { setPageTitle } from "shared/webbridge/actions";
import { setOtrToken } from "shared/utils/httpContext/actions";
import backendUrl from "../utils/AseBackendUrls";
import { redirectToTarget } from "../utils/Routing";
import { triggerLoginChangeEvent } from "../utils/BrowserEvents";
import {
  REGISTER_PAGE__LOAD_REGISTER_PAGE,
  REGISTER_PAGE__REGISTER_FAILED,
  REGISTER_PAGE__REGISTER,
} from "../constants";

export function loadRegisterPage(apiClient, query) {
  return apiClient.get(
    backendUrl.REGISTER,
    {
      ok: (content) => [
        {
          type: REGISTER_PAGE__LOAD_REGISTER_PAGE,
          payload: content.body,
        },
        setOtrToken(content?.headers?.["x-mf-otr"]),
        setPageTitle(content.body?.page?.title),
      ],
    },
    {
      httpClientOptions: {
        params: query,
      },
    }
  );
}

export function submitRegistration(
  { routes, link, values, otrToken },
  apiClient
) {
  return [
    {
      type: REGISTER_PAGE__REGISTER,
    },
    apiClient.post(
      link,
      values,
      {
        accepted: (content) => [
          triggerLoginChangeEvent(),
          redirectToTarget(routes, content),
        ],
        badRequest: (content) => [
          setOtrToken(content?.headers?.["x-mf-otr"]),
          {
            type: REGISTER_PAGE__REGISTER_FAILED,
            payload: content.body,
          },
        ],
      },
      {
        httpClientOptions: {
          headers: {
            "Content-Type": "application/json",
            "x-mf-otr": otrToken,
          },
        },
      }
    ),
  ];
}

export function confirmAddressAlternative(
  { routes, link, values, otrToken },
  apiClient
) {
  const valuesArray = values.slice();
  valuesArray.push({ name: "action", value: "CONFIRMATION" });
  const valuesDict = valuesArray.reduce((result, item) => {
    const key = item.name;
    // eslint-disable-next-line no-param-reassign
    result[key] = item.value;
    return result;
  }, {});
  return apiClient.post(
    link,
    valuesDict,
    {
      accepted: (content) => [
        triggerLoginChangeEvent(),
        redirectToTarget(routes, content),
      ],
      badRequest: (content) => [
        setOtrToken(content?.headers?.["x-mf-otr"]),
        {
          type: REGISTER_PAGE__REGISTER_FAILED,
          payload: content.body,
        },
      ],
    },
    {
      httpClientOptions: {
        headers: {
          "Content-Type": "application/json",
          "x-mf-otr": otrToken,
        },
      },
    }
  );
}

export function editAddressAlternative(
  { routes, link, values, otrToken },
  apiClient
) {
  const valuesArray = values.slice();
  valuesArray.push({ name: "action", value: "EDIT" });
  const valuesDict = valuesArray.reduce((result, item) => {
    const key = item.name;
    // eslint-disable-next-line no-param-reassign
    result[key] = item.value;
    return result;
  }, {});
  return apiClient.post(
    link,
    valuesDict,
    {
      accepted: (content) => [
        triggerLoginChangeEvent(),
        redirectToTarget(routes, content),
      ],
      badRequest: (content) => [
        setOtrToken(content?.headers?.["x-mf-otr"]),
        {
          type: REGISTER_PAGE__REGISTER_FAILED,
          payload: content.body,
        },
      ],
    },
    {
      httpClientOptions: {
        headers: {
          "Content-Type": "application/json",
          "x-mf-otr": otrToken,
        },
      },
    }
  );
}
