export const SELL_AB_COOKIE = "sell-ab-cookie";
export const HYBRID_RECO_TEASER = "hybrid_recomendations_teaser";
export const RECOMMENDATIONS = "recommendations";

export const EXCLUDE_OUTFIT = [
  "G2105",
  "G2106",
  "G2107",
  "G1105",
  "G1106",
  "G1107",
  "G1108",
  "G2111",
];

// eslint-disable-next-line import/prefer-default-export
export const initialState = {
  data: {}, // server responses grouped by key-identifier
  keys: [], // list of the last opened keys, recent ones first
  maxKeys: 20,
  loadedSliderImages: [0, 1],
  currentSliderPosition: 0,
  orderFormInput: {
    quantity: "1",
    freeInput: "",
  },
  loading: false,
  isClientSideLoaded: false,
  isRecoLoaded: false,
  isOutfitInfoLoaded: false,
};
