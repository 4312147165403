export function triggerEvent(name) {
  let event = null;
  if (window.CustomEvent && typeof window.CustomEvent === "function") {
    event = new CustomEvent(name);
  } else {
    event = document.createEvent("CustomEvent");
    event.initCustomEvent(name, true, true, null);
  }
  window.dispatchEvent(event);
}

export function triggerBasketChangeEvent() {
  triggerEvent("ase:basket:changed");
}

export function triggerWishlistChangeEvent() {
  triggerEvent("ase:wishlist:changed");
}

export function triggerLoginChangeEvent() {
  triggerEvent("ase:login:changed");
}

export function triggerLineItemEditFragmentSubmitEvent() {
  triggerEvent("ase:lineitemeditfragment:submit");
}
