import { isOptinBannerRequired } from "shared/utils/trackingHelper";
import {
  clearCookieBanner,
  openCookieBanner,
} from "../../fragments/sell-cookie-banner/actions";

export function isCategoryLoaded(keys, categoryId) {
  return Array.isArray(keys) && keys[0] === categoryId;
}

export function containsFacets(data, categoryId) {
  const result = data[categoryId];
  if (typeof result?.facets !== "undefined") {
    if (result.facets && result.facets.facets.selectedCountSum) {
      return true;
    }
  }
  return false;
}

export function handleCommercialTracking(noCommercialTracking, cookies) {
  if (isOptinBannerRequired(cookies)) {
    // close cookie banner if flag noCommercialTracking is set
    if (noCommercialTracking) {
      clearCookieBanner();
    } else {
      openCookieBanner();
    }
  }
}
