import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classNames from "classnames/bind";
import { renderWithProps } from "../util";
import s from "./TopLevel.module.css";

const cx = classNames.bind(s);

const TopLevel = ({ compact, children }) => (
  <div className={cx("root", { compact, flyout: !compact })}>
    {renderWithProps(children, { compact })}
  </div>
);

TopLevel.propTypes = {
  compact: PropTypes.bool,
  children: PropTypes.node,
};

export default TopLevel;
