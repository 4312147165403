import { REMOVE_OVERLAY_EVENT } from "stelar/components/Overlay/constants";

function triggerEvent(name) {
  if (typeof window !== "object") {
    return;
  }
  let event = null;
  if (window.CustomEvent && typeof window.CustomEvent === "function") {
    event = new CustomEvent(name);
  } else {
    event = document.createEvent("CustomEvent");
    event.initCustomEvent(name, true, true, null);
  }
  window.dispatchEvent(event);
}

export function triggerAseBasketUpdatedEvent() {
  triggerEvent("ase:basket:changed");
}

export function triggerAseNotepadUpdatedEvent() {
  triggerEvent("ase:wishlist:changed");
}

export function triggerCookieBannerConsentChanged() {
  triggerEvent("sell:cookieBanner:consentchanged");
}

export function triggerLocationChange(url, explicitly) {
  window.dispatchEvent(
    new window.CustomEvent("mf:location:change", {
      detail: { url, explicitly },
    })
  );
}

export function triggerOverlayCloseEvent(element, localWindow = window) {
  element.dispatchEvent(
    new localWindow.CustomEvent(REMOVE_OVERLAY_EVENT, {
      bubbles: true,
    })
  );
}
