export const updateFormStructure =
  (
    { addressStatePath, formValues, newValues, type, updateFormURL },
    apiClient
  ) =>
  (dispatch) => {
    const values = { ...formValues, ...newValues };

    return dispatch(
      apiClient.post(updateFormURL, values, {
        ok: (content) => [
          {
            type,
            payload: content.body,
            addressStatePath,
          },
        ],
      })
    );
  };
