import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import IconBubbleCheck from "stelar/components/Icon/IconBubbleCheck";
import s from "./BenefitCommunication.module.css";

const createBenefitElements = (benefit, index) => (
  <li className={s.element} key={index}>
    <span className={s.iconContainer}>
      <IconBubbleCheck large />
    </span>
    <span className={s.text}>{benefit}</span>
  </li>
);

const FooterBenefitCommunication = ({ link, benefits, onClick }) => (
  <a href={link} onClick={onClick} className={s.root}>
    <ul className={s.list}>{benefits.map(createBenefitElements)}</ul>
  </a>
);

FooterBenefitCommunication.propTypes = {
  link: PropTypes.string,
  benefits: PropTypes.array,
  onClick: PropTypes.func,
};

export default FooterBenefitCommunication;
