const initialState = {
  data: {
    article: {
      ageRelated: "",
      articleNumber: {},
      availability: {},
      dimensionSelections: [
        {
          label: "dimension1",
          name: "dimension1",
          selectOptions: [{ label: "label", value: "value" }],
        },
      ],
      freeText: {
        text: {
          description: "",
        },
        value: "",
      },
      imgSrc: "",
      info: {
        descriptions: [],
        heading: "",
      },
      manufacturer: "",
      name: "",
      quantity: {
        label: "quantity",
        name: "quantity",
      },
      skuCode: "",
      unitPrice: {},
      energyEfficiency: {},
    },
    cancelLink: {},
    lineItemId: "",
    submitButton: {},
  },
  uiState: { renderAsSkeleton: true },
};

export default initialState;
