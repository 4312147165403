import StackTrace from "stacktrace-js";
import { getEnv } from "../utils/envVariables";
import sendLogEntry, { CLIENTSIDE_LOG_SERVICE_PATH } from "./logService";

export async function remoteLogging(_errorEvent, additionalParams = {}) {
  // based on https://github.com/i-break-codes/logerr
  async function errorData(errorEvent) {
    let stackTrace = errorEvent.error
      ? errorEvent.error.stack?.toString().replace(/(\r\n|\n|\r)/gm, "")
      : "";

    let firstStackFrame;
    try {
      const stackTraceArray = await StackTrace.fromError(errorEvent.error);
      firstStackFrame =
        stackTraceArray && stackTraceArray.length !== 0 && stackTraceArray[0];

      stackTrace = stackTraceArray.map(
        (entry) =>
          `@ ${entry.functionName} (${entry.fileName}:${entry.lineNumber}:${entry.columnNumber})`
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(
        "An error has occurred while evaluating the stacktrace, using fallback stacktrace"
      );
    }

    const filenameCutoff = (errorEvent.filename?.lastIndexOf("/") || 0) + 1;

    const result = {
      type: errorEvent.type,
      path: errorEvent.filename,
      filename: errorEvent.filename?.substring(filenameCutoff),
      lineNumber: errorEvent.lineno,
      columnNumber: errorEvent.colno,
      message: errorEvent.message,
      stackTrace,
      origin: "client",
    };

    if (firstStackFrame) {
      result.filename = firstStackFrame.fileName || "?";
      result.lineNumber = firstStackFrame.lineNumber || "?";
      result.columnNumber = firstStackFrame.columnNumber || "?";
    }

    return result;
  }

  const data = await errorData(_errorEvent);
  sendLogEntry({
    url: CLIENTSIDE_LOG_SERVICE_PATH,
    payload: { ...data, ...additionalParams },
    config: {
      headers: {
        "x-mf-team": getEnv("TEAM"),
        "x-mf-build-number": getEnv("BUILD_NUMBER"),
      },
    },
  });
}

export default function remoteLogger(additionalParams = {}) {
  async function listener(errorEvent) {
    remoteLogging(errorEvent, additionalParams);
  }

  // init
  window.removeEventListener("error", listener);
  window.addEventListener("error", listener);
}
