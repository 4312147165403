// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import HeaderWishList from "stelar/components/HeaderIcon/HeaderWishlist";

const MiniWishListFragment = ({ active, label, link, onClick }) => (
  <HeaderWishList active={active} link={link} text={label} onClick={onClick} />
);

MiniWishListFragment.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
};

export default MiniWishListFragment;
