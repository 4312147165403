import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classNames from "classnames/bind";
import IconBoldRight from "stelar/components/Icon/IconBoldRight";
import s from "./BottomLevelItem.module.css";

const cx = classNames.bind(s);

function BottomLevelItem({
  name,
  compact,
  active,
  open,
  link,
  onClick,
  ...attrs
}) {
  return (
    <a
      {...attrs}
      className={cx("root", { active, open, compact, flyout: !compact })}
      href={link}
      onClick={onClick}
    >
      <span className={s.arrow}>
        <IconBoldRight tiny />
      </span>
      <span className={s.name}>{name}</span>
    </a>
  );
}

BottomLevelItem.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
  active: PropTypes.bool,
  open: PropTypes.bool,
  compact: PropTypes.bool,
  onClick: PropTypes.func,
};

BottomLevelItem.defaultProps = {};

export default BottomLevelItem;
