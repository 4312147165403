// vendor
import update from "immutability-helper";
// ase
import {
  ORDER_HISTORY_PAGE__LOAD_ORDER_HISTORY_PAGE,
  ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING_FAILED,
  ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING,
  ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING_SUCCESS,
  ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL_FAILED,
  ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL,
  ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL_SUCCESS,
  ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL_FAILED,
  ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL,
  ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL_SUCCESS,
} from "../constants";
// component
import initialState from "./initialState";

const orderHistoryPage = (state = initialState, action) => {
  let orderIndex;
  let deliveryIndex;
  switch (action.type) {
    case ORDER_HISTORY_PAGE__LOAD_ORDER_HISTORY_PAGE:
      return update(state, {
        data: {
          $set: {
            ...action.payload,
            shipmentTracking: state.data.shipmentTracking,
          },
        },
      });
    case ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING:
      return update(state, {
        virtual: {
          isShipmentTrackingLoading: { $set: true },
        },
      });
    case ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING_SUCCESS:
      return update(state, {
        data: { $merge: action.payload },
        virtual: {
          isShipmentTrackingLoading: { $set: false },
        },
      });
    case ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING_FAILED:
      return update(state, {
        virtual: {
          isShipmentTrackingLoading: { $set: false },
        },
      });
    case ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL:
      return update(state, {
        virtual: {
          requestLabelByEmailLoading: { $set: true },
        },
      });
    case ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL_SUCCESS:
      state.data.orderHistory?.orders?.find((order, tmpOrderIndex) =>
        order.returns?.find((returnItem, tmpDeliveryIndex) => {
          if (
            returnItem.returnVoucherByEmail?.deliveryNumber ===
            action.payload.deliveryNumber
          ) {
            orderIndex = tmpOrderIndex;
            deliveryIndex = tmpDeliveryIndex;
            return true;
          }
          return false;
        })
      );
      return update(state, {
        data: {
          orderHistory: {
            orders: {
              [orderIndex]: {
                returns: {
                  [deliveryIndex]: {
                    returnVoucherByEmail: { $set: action.payload },
                  },
                },
              },
            },
          },
        },
        virtual: {
          requestLabelByEmailLoading: { $set: false },
        },
      });
    case ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL_FAILED:
      state.data.orderHistory?.orders?.find((order, tmpOrderIndex) =>
        order.returns?.find((returnItem, tmpDeliveryIndex) => {
          if (
            returnItem.returnVoucherByEmail?.deliveryNumber ===
            action.payload.deliveryNumber
          ) {
            orderIndex = tmpOrderIndex;
            deliveryIndex = tmpDeliveryIndex;
            return true;
          }
          return false;
        })
      );
      return update(state, {
        data: {
          orderHistory: {
            orders: {
              [orderIndex]: {
                returns: {
                  [deliveryIndex]: {
                    returnVoucherByEmail: { $set: action.payload },
                  },
                },
              },
            },
          },
        },
        virtual: {
          requestLabelByEmailLoading: { $set: false },
        },
      });
    case ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL:
      return update(state, {
        virtual: {
          requestLabelByEmailLoading: { $set: true },
        },
      });
    case ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL_SUCCESS:
      state.data.orderHistory?.orders?.find((order, tmpOrderIndex) =>
        order.returns?.find((returnItem, tmpDeliveryIndex) => {
          if (
            returnItem.shipmentLabelByEmail?.deliveryNumber ===
            action.payload.deliveryNumber
          ) {
            orderIndex = tmpOrderIndex;
            deliveryIndex = tmpDeliveryIndex;
            return true;
          }
          return false;
        })
      );
      return update(state, {
        data: {
          orderHistory: {
            orders: {
              [orderIndex]: {
                returns: {
                  [deliveryIndex]: {
                    shipmentLabelByEmail: { $set: action.payload },
                  },
                },
              },
            },
          },
        },
        virtual: {
          requestLabelByEmailLoading: { $set: false },
        },
      });
    case ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL_FAILED:
      state.data.orderHistory?.orders?.find((order, tmpOrderIndex) =>
        order.returns?.find((returnItem, tmpDeliveryIndex) => {
          if (
            returnItem.shipmentLabelByEmail?.deliveryNumber ===
            action.payload.deliveryNumber
          ) {
            orderIndex = tmpOrderIndex;
            deliveryIndex = tmpDeliveryIndex;
            return true;
          }
          return false;
        })
      );
      return update(state, {
        data: {
          orderHistory: {
            orders: {
              [orderIndex]: {
                returns: {
                  [deliveryIndex]: {
                    shipmentLabelByEmail: { $set: action.payload },
                  },
                },
              },
            },
          },
        },
        virtual: {
          requestLabelByEmailLoading: { $set: false },
        },
      });
    default:
      return state;
  }
};

export default orderHistoryPage;
