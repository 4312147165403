import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import imageSizes from "shared/utils/imageSizes";
import { IMAGE_PROFILE_DEFAULTS } from "shared/utils/imageUrlBuilder";
import ContentHeading from "stelar/components/ContentHeading";
import HtmlContent from "stelar/components/HtmlContent";
import RatioImage from "../../../components/RatioImage";
import s from "../../../components/Teasers/DetailTeaser/DetailTeaser.module.css";

function Teaser({ imageUrl, image, title, skeleton, text, onLinkClick, link }) {
  const imgSizes = imageSizes({
    xxxl: "350px",
    xl: "25vw",
    m: "42vw",
    default: "100vw",
  });

  const imageNode = (
    <RatioImage
      image={image}
      imageProfileDefault={IMAGE_PROFILE_DEFAULTS.kurator}
      sizes={imgSizes}
      skeleton={skeleton}
    />
  );

  const imageBlock = link ? (
    <a href={link} onClick={onLinkClick}>
      {imageNode}
    </a>
  ) : (
    imageNode
  );
  return (
    <div className={s.root} data-test-sell-detail-teaser>
      {imageUrl || image ? <div className={s.image}>{imageBlock}</div> : null}
      <div className={s.text}>
        {title && !skeleton ? (
          <ContentHeading prio2 tag="h3" skeleton={skeleton}>
            {title}
          </ContentHeading>
        ) : null}
        {text && !skeleton ? (
          <HtmlContent
            dangerouslySetInnerHTML={{ __html: text }}
            onClick={onLinkClick}
          />
        ) : null}
      </div>
    </div>
  );
}

Teaser.propTypes = {
  imageUrl: PropTypes.string,
  image: PropTypes.object,
  title: PropTypes.string,
  skeleton: PropTypes.bool,
  text: PropTypes.string,
  onLinkClick: PropTypes.func,
  link: PropTypes.string,
};

export default Teaser;
