import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import classnames from "classnames/bind";
import { renderClientBottom, openIfNeeded } from "./helper";
import s from "./CookieBannerLayout.module.css";

const cx = classnames.bind(s);

function CookieBannerLayout({
  banner,
  hidden,
  hiddenByUser,
  open,
  onNeedToShow,
}) {
  React.useEffect(() => {
    openIfNeeded(open, onNeedToShow);
  }, [open]);

  renderClientBottom(hidden, hiddenByUser, open);

  if (!open) {
    return null;
  }

  return (
    !hidden && <div className={cx("root", { hiddenByUser })}>{banner}</div>
  );
}

CookieBannerLayout.propTypes = {
  banner: PropTypes.node,
  hidden: PropTypes.bool,
  hiddenByUser: PropTypes.bool,
  onNeedToShow: PropTypes.func,
  open: PropTypes.bool,
};

export default CookieBannerLayout;
