import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import TextHighlight from "stelar/components/TextHighlight";

import Text from "stelar/components/Text";
import Flag from "../../Flag";
import s from "./ElementInfoBox.module.css";
import ContentSpacing from "../../ContentSpacing/ContentSpacing";

function ElementInfoBox({
  label,
  heading,
  text,
  fullWidth,
  onLinkClick,
  enclosedByBox,
}) {
  const innerText = enclosedByBox ? (
    <Text normal tag="div" outline>
      <div className={s.flag}>
        {label && (
          <Flag variant="label" size="long">
            {label}
          </Flag>
        )}
      </div>
      {heading && <TextHighlight variant="heading">{heading}</TextHighlight>}
      <TextHighlight variant="text" onClick={onLinkClick}>
        {text}
      </TextHighlight>
    </Text>
  ) : (
    <Text normal>
      <div className={s.flag}>
        {label && (
          <Flag variant="label" size="long">
            {label}
          </Flag>
        )}
      </div>
      {heading && <TextHighlight variant="heading">{heading}</TextHighlight>}
      <TextHighlight variant="text" onClick={onLinkClick}>
        {text}
      </TextHighlight>
    </Text>
  );
  return (
    <ContentSpacing
      alignment="text"
      className={s.spacing}
      fullWidth={fullWidth}
      gutters
      data-test-sell-element-info-box
    >
      {innerText}
    </ContentSpacing>
  );
}

ElementInfoBox.propTypes = {
  label: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
  fullWidth: PropTypes.bool,
  onLinkClick: PropTypes.func,
  enclosedByBox: PropTypes.bool,
};

ElementInfoBox.defaultProps = {};

export default ElementInfoBox;
