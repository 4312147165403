// vendor
import PropTypes from "prop-types";
import React, { useCallback } from "react"; // eslint-disable-line import/no-extraneous-dependencies
import {
  SellFeedbackFragment,
  SellKuratorContentFragment,
  SellRecommendationsFragment,
} from "shared/components/registry";
// component
import s from "./ContentRowsMapper.module.css";

const ContentRowsMapper = ({ contentRows, additionalHandlers = {} }) => {
  if (!contentRows || contentRows.length === 0) {
    return null;
  }

  const basicHandlers = {
    Fragment: useCallback(
      ({ content, params }) => (
        <section className={s.section} key={`Fragment-${content}`}>
          <SellKuratorContentFragment tag={content} {...params} />
        </section>
      ),
      [contentRows]
    ),
    SellRecommendations: useCallback(
      ({ params }) => (
        <section className={s.section} key="SellRecommendations">
          <SellRecommendationsFragment lazy {...params} />
        </section>
      ),
      [contentRows]
    ),
    SellFeedback: useCallback(
      ({ params: { type, metadata } }) => (
        <section className={s.section} key="SellFeedback">
          <SellFeedbackFragment
            lazy
            type={type}
            metadata={JSON.stringify(metadata)}
          />
        </section>
      ),
      [contentRows]
    ),
  };

  const handlers = { ...basicHandlers, ...additionalHandlers };
  return contentRows.map((contentRow) => {
    if (handlers[contentRow.type]) {
      return handlers[contentRow.type](contentRow);
    }
    // eslint-disable-next-line no-console
    console.warn(`Warning: contentRow type ${contentRow.type} is unknown`);
    return null;
  });
};

ContentRowsMapper.propTypes = {
  contentRows: PropTypes.array.isRequired,
  additionalHandlers: PropTypes.objectOf(PropTypes.func),
};

export default ContentRowsMapper;
