import { connect } from "react-redux";
import { provideHooks } from "redial";
import track from "shared/tracking";
import loadable from "@loadable/component";
import Loading from "shared/components/Loading";
import changeInputAction from "shared/utils/actionReducer/changeInput/ChangeInputAction";
import frontendUrls from "../utils/AseFrontendUrls";
import { PASSWORD_PAGE__PAGE_NAME } from "../constants";
import {
  doRequestPasswordChange,
  doRequestRecoveryEmail,
  loadPasswordPage,
  validateInput,
  validatePasswordRepetition,
} from "./actions";

// Das hier ist tatsächlich nur für passwort vergessen
const page = PASSWORD_PAGE__PAGE_NAME; // used for changing Input values, don't delete

const redial = {
  fetch: ({ apiClient, dispatch, query }) =>
    dispatch(loadPasswordPage(apiClient, query)),
  defer: ({ getState }) => track(getState()?.passwordPage?.page?.trackingInfo),
};

const mapDispatchToProps = (dispatch, props) => ({
  doRequestPassword: (args) =>
    dispatch(doRequestRecoveryEmail(args, props.apiClient)),
  doChangePassword: (args) =>
    dispatch(doRequestPasswordChange(args, props.apiClient)),
  changeEmail: changeInputAction(dispatch)(page, "", "email"),
  changePassword: changeInputAction(dispatch)(page, "", "password"),
  changePasswordRepetition: changeInputAction(dispatch)(
    page,
    "",
    "passwordRepetition"
  ),
  validateInput: (inputPath, errorPath) =>
    dispatch(validateInput(inputPath, errorPath)),
  validatePasswordRepetition: () => dispatch(validatePasswordRepetition()),
});

const mapStateToProps = (state) => ({
  id: state.passwordPage.id,
  code: state.passwordPage.code,
  buttonNextLabel: state.passwordPage.buttonNextLabel,
  email: state.passwordPage.email,
  errorMessage: state.passwordPage.errorMessage,
  globalMessage: state.passwordPage.globalMessage,
  password: state.passwordPage.password,
  passwordRepetition: state.passwordPage.passwordRepetition,
  headline: state.passwordPage.headline,
  heading: state.passwordPage.heading,
  pageDescription: state.passwordPage.pageDescription,
  reducedLayout: state.reducedLayout,
  successMessage: state.passwordPage.successMessage,
  page: state.passwordPage.page,
  contentRows: state.passwordPage.contentRows,
});

const LoadablePasswordPage = loadable(
  () =>
    import(/* webpackChunkName: "password-page" */ "./components/PasswordPage"),
  {
    fallback: Loading,
  }
);

export default {
  component: provideHooks(redial)(
    connect(mapStateToProps, mapDispatchToProps)(LoadablePasswordPage)
  ),
  path: frontendUrls.PASSWORD,
};
