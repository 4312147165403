import React from "react";
import classNames from "classnames/bind";
import { ButtonX } from "../Button";
import CountryFlag from "../CountryFlag";
import IconDown from "../Icon/IconDown";
import IconUp from "../Icon/IconUp";
import Text from "../Text";
import type { Tenants } from "../CountryFlag/types";

import s from "./OffCanvasNavigation.module.css";

const cx = classNames.bind(s);

type Country = {
  iconName: Tenants;
  label: string;
  selection: ";";
};

type Props = {
  children?: React.ReactNode;
  active?: boolean;
  headerText?: string;
  headerCloseLabel?: string;
  onClose?: (...args: unknown[]) => unknown;
  country?: Country;
  countryToggle?: boolean;
  handleCountryToggle?: (...args: unknown[]) => unknown;
};

function OffCanvasNavigation({
  active,
  children,
  headerText = "Sortiment",
  headerCloseLabel = "Schließen",
  onClose,
  country = null,
  countryToggle,
  handleCountryToggle,
}: Props) {
  return (
    <div className={cx("root", { active })}>
      <div className={s.header}>
        <div className={s.headerText}>
          <Text inverted normal>
            {headerText}
          </Text>
        </div>
        {country && (
          <div className={s.headerText}>
            <button
              className={cx("countryFlagButton")}
              type="button"
              onClick={handleCountryToggle}
            >
              <CountryFlag
                className={cx("countryFlagSize")}
                country={country.iconName}
              />
              <small>{country.label}</small>
              <small className={cx("arrowIcon")}>
                {countryToggle ? <IconUp tiny /> : <IconDown tiny />}
              </small>
            </button>
          </div>
        )}
        <div className={s.headerClose}>
          <ButtonX
            className={s.headerCloseButton}
            onClick={onClose}
            data-test-stelar-offcanvas-close-button
            aria-label={headerCloseLabel}
          />
        </div>
      </div>
      <div className={s.content}>{children}</div>
    </div>
  );
}

export default OffCanvasNavigation;
