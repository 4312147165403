import update from "immutability-helper";
import { NEWSLETTER_PAGE__LOAD_NEWSLETTER_PAGE } from "../constants";

const initialState = {
  datenschutz: {},
  email: {},
  headline: "",
  submitable: true,
  page: {
    back: {},
    submit: {},
    submitButton: {},
  },
};

const newsletterPage = (state = initialState, action) => {
  switch (action.type) {
    case NEWSLETTER_PAGE__LOAD_NEWSLETTER_PAGE:
      return update(state, { $set: action.payload });
    default:
      return state;
  }
};

export default newsletterPage;
