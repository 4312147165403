import { setPageTitle } from "shared/webbridge/actions";
import backendUrl from "../utils/AseBackendUrls";
import { openPage } from "../utils/Routing";
import {
  PAYMENT_PAGE__CHANGE_PAYMENT,
  PAYMENT_PAGE__LOAD_PAYMENT_PAGE,
  PAYMENT_PAGE__SET_PAYMENT,
  PAYMENT_PAGE__VALIDATE_INPUT,
} from "../constants";

export function loadPaymentPage(apiClient, query) {
  return apiClient.get(
    backendUrl.PAYMENT,
    {
      ok: (content) => [
        {
          type: PAYMENT_PAGE__LOAD_PAYMENT_PAGE,
          payload: content.body,
        },
        setPageTitle(content.body?.page?.headData?.title),
      ],
    },
    {
      httpClientOptions: {
        params: query,
      },
    }
  );
}

export function validateInput(inputPath, errorPath) {
  return {
    type: PAYMENT_PAGE__VALIDATE_INPUT,
    inputPath,
    errorPath,
  };
}

export function changePayment(paymentMethod) {
  return {
    type: PAYMENT_PAGE__CHANGE_PAYMENT,
    paymentMethod,
  };
}

export function setPayment({ routes, ...data }, apiClient) {
  return apiClient.post(backendUrl.PAYMENT, data, {
    noContent: (content) => openPage(routes, content.headers.location),
    badRequest: (content) => ({
      type: PAYMENT_PAGE__SET_PAYMENT,
      payload: content.body,
    }),
  });
}
