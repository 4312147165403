import React from "react";
import Input from "./Input";

type Props = {
  disabled?: boolean;
  error?: boolean | string;
} & React.ComponentPropsWithoutRef<typeof Input>;

/**
 * InputPassword
 */
function InputPassword({ disabled, error, innerRef, ...attrs }: Props) {
  return (
    <Input
      disabled={disabled}
      error={error}
      type="password"
      innerRef={innerRef}
      {...attrs}
    />
  );
}

InputPassword.defaultProps = {
  error: "",
};

export default InputPassword;
