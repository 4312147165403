// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { FormProvider } from "react-hook-form";
// mf-stelar
import Form from "stelar/components/Form";
// ase
import { buildBackendGeneratedForm } from "../../../util/reduxFormUtils/reduxFormBuilder";
import PasswordChangeFormContent from "./PasswordChangeFormContent";

const PasswordChangeForm = ({ formDefinition, onSubmit, formName }) => {
  if (formDefinition.structure?.length === 0) {
    return null;
  }

  const { formProviderValue, onSubmitHandler } = buildBackendGeneratedForm({
    formDefinition,
    onSubmit,
    scrollToError: false,
  });

  const layout = "standard";
  const { submit } = formDefinition;

  return (
    <FormProvider {...formProviderValue}>
      <Form
        action={submit.link}
        layout={layout}
        method="post"
        onSubmit={onSubmitHandler}
      >
        <PasswordChangeFormContent
          formDefinition={formDefinition}
          formName={formName}
        />
      </Form>
    </FormProvider>
  );
};

export default PasswordChangeForm;

PasswordChangeForm.propTypes = {
  onSubmit: PropTypes.func,
  formDefinition: PropTypes.object,
  formName: PropTypes.string.isRequired,
};
