import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import mq from "stelar/components/media";

import Slider from "../SliderCore/Slider";
import { getSlidesPerPage } from "../SliderCore/utils";
import SliderButtonWrapper from "../SliderButtonWrapper";

import styles from "./InteractiveProductSlider.module.css";

import { SIZE_FOUR, SIZE_HALF, SIZE_OVERLAY } from "./constants";

const InteractiveProductSlider = ({
  sliderId,
  currentSlide,
  sliderActions,
  children,
  products,
  size,
  sliderState,
  loadedSlides,
}) => {
  const amountProducts = products ? products.length : 0;

  function sizeClassName(sizeName) {
    switch (sizeName) {
      case SIZE_HALF:
        return styles.half;
      case SIZE_FOUR:
        return styles.four;
      case SIZE_OVERLAY:
        return styles.overlay;
      default:
        return styles.full;
    }
  }

  function setSlidesForBreakpoint(name, elementsToShow) {
    return {
      breakpoint: mq.breakpoint[name].max,
      settings: {
        slidesToShow: elementsToShow,
        slidesToScroll: elementsToShow,
        swipe: amountProducts > elementsToShow,
      },
    };
  }

  const settingsSizeFull = {
    slidesToShow: 6,
    slidesToScroll: 6,
    swipe: amountProducts > 6,
    responsive: [
      setSlidesForBreakpoint("s", 2),
      setSlidesForBreakpoint("m", 3),
      setSlidesForBreakpoint("l", 4),
      setSlidesForBreakpoint("xl", 5),
    ],
  };

  const settingsSizeHalf = {
    slidesToShow: 3,
    slidesToScroll: 3,
    swipe: amountProducts > 3,
    responsive: [
      setSlidesForBreakpoint("s", 2),
      setSlidesForBreakpoint("m", 3),
      setSlidesForBreakpoint("l", 4),
      setSlidesForBreakpoint("xl", 3),
    ],
  };

  const settingsSizeFour = {
    slidesToShow: 4,
    slidesToScroll: 4,
    swipe: amountProducts > 4,
    responsive: [
      setSlidesForBreakpoint("s", 2),
      setSlidesForBreakpoint("m", 3),
      setSlidesForBreakpoint("l", 2),
      setSlidesForBreakpoint("xl", 4),
    ],
  };

  const settingsSizeOverlay = {
    slidesToShow: 4,
    slidesToScroll: 4,
    swipe: amountProducts > 4,
    responsive: [
      setSlidesForBreakpoint("s", 2),
      setSlidesForBreakpoint("m", 4),
    ],
  };

  function sizeSettings(sizeName) {
    switch (sizeName) {
      case SIZE_HALF:
        return settingsSizeHalf;
      case SIZE_FOUR:
        return settingsSizeFour;
      case SIZE_OVERLAY:
        return settingsSizeOverlay;
      default:
        return settingsSizeFull;
    }
  }

  const sizeSpecificSettings = sizeSettings(size);

  const buttonPosition =
    size === SIZE_OVERLAY
      ? "centerForSquareItemsOverlay"
      : "centerForSquareItems";

  const settings = {
    ...sizeSpecificSettings,
    infinite: false,
    speed: 500,
    touchThreshold: 20,
    draggable: true,
    accessibility: true,
    adaptiveHeight: true,
    lazyLoad: false,
    arrows: true,
    prevArrow: (
      <SliderButtonWrapper
        prev
        position={buttonPosition}
        testSelector="data-test-sell-slider-prev"
      />
    ),
    nextArrow: (
      <SliderButtonWrapper
        next
        position={buttonPosition}
        testSelector="data-test-sell-slider-next"
      />
    ),
    afterChange(nextSlide) {
      sliderActions.onChangeSliderPosition(
        sliderId,
        nextSlide,
        getSlidesPerPage(settings)
      );
    },
  };

  const forcedPositionZero = getSlidesPerPage(settings) > amountProducts;

  return (
    <div className={sizeClassName(size)}>
      <Slider
        loadedSlides={loadedSlides}
        styles={styles}
        settings={settings}
        slickGoTo={forcedPositionZero ? 0 : currentSlide}
        sliderId={sliderId}
        sliderState={sliderState}
        testSelector="data-test-sell-product-slider"
      >
        {children}
      </Slider>
    </div>
  );
};

InteractiveProductSlider.propTypes = {
  children: PropTypes.node,
  products: PropTypes.array,
  size: PropTypes.oneOf([SIZE_HALF, SIZE_FOUR, SIZE_OVERLAY]),
  sliderActions: PropTypes.object,
  sliderId: PropTypes.string,
  loadedSlides: PropTypes.object,
  currentSlide: PropTypes.number,
  sliderState: PropTypes.object,
};

export default InteractiveProductSlider;
