import { connect } from "react-redux";
import { provideHooks } from "redial";
import loadable from "@loadable/component";

import Loading from "shared/components/Loading";

import frontendUrls from "../utils/AseFrontendUrls";
import { loadAccountDeletionPage, submitDeletion } from "./actions";

const redial = {
  fetch: ({ apiClient, dispatch, query }) =>
    dispatch(loadAccountDeletionPage(apiClient, query)),
};

const mapDispatchToProps = (dispatch, props) => ({
  submitDeletion: (args) => dispatch(submitDeletion(args, props.apiClient)),
});

const mapStateToProps = (state) => ({
  ...state.accountDeletionPage,
});

const LoadableAccountDeletionPage = loadable(
  () =>
    import(
      /* webpackChunkName: "accountdeletion-page" */ "./components/AccountDeletionPage"
    ),
  {
    fallback: Loading,
  }
);

export default {
  component: provideHooks(redial)(
    connect(mapStateToProps, mapDispatchToProps)(LoadableAccountDeletionPage)
  ),
  path: frontendUrls.ACCOUNT_DELETION,
};
