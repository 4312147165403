import update from "immutability-helper";
import { combineReducers } from "redux";
import uiState from "../../utils/uiState/reducer";
import {
  LOAD_RECOMMENDATIONS_FAILED,
  LOAD_RECOMMENDATIONS_STARTED,
  LOAD_RECOMMENDATIONS_SUCCESS,
  LOAD_RECOMMENDATIONS_TIMEOUT,
  RESET_CURRENT_ARTICLE_RECOMMENDATIONS,
  SHOW_SKELETON_RECOMMENDATIONS,
} from "../../pages/constants";
import { mapKeyString } from "./helper"; // eslint-disable-line import/named

const initialState = {};

export function recommendations(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_SKELETON_RECOMMENDATIONS:
    case LOAD_RECOMMENDATIONS_STARTED: {
      const key = mapKeyString(payload.location, payload.articleNumber);
      const identifier = payload.articleNumber
        ? payload.articleNumber
        : payload.location;
      const existingValues = state[key] ? state[key].data : null;
      return update(state, {
        [key]: { $set: { loading: true, data: existingValues } },
        currentArticle: { $set: identifier },
      });
    }

    case LOAD_RECOMMENDATIONS_SUCCESS: {
      const key = mapKeyString(payload.location, payload.articleNumber);
      const data = {
        [key]: {
          loading: false,
          data: payload,
        },
      };
      return update(state, { $merge: data });
    }

    case LOAD_RECOMMENDATIONS_FAILED: {
      const key = mapKeyString(payload.location, payload.articleNumber);
      const data = {
        [key]: {
          loading: false,
          data: null,
        },
      };
      return update(state, { $merge: data });
    }

    case LOAD_RECOMMENDATIONS_TIMEOUT: {
      const key = mapKeyString(payload.location, payload.articleNumber);
      const data = {
        [key]: {
          loading: false,
        },
        error: false,
      };
      return update(state, { $merge: data });
    }

    case RESET_CURRENT_ARTICLE_RECOMMENDATIONS: {
      const data = {
        currentArticle: null,
      };
      return update(state, { $merge: data });
    }

    default:
      return state;
  }
}

export default combineReducers({
  recommendations,
  uiState,
});
