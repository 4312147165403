import React from "react";
import classnames from "classnames/bind";

import {
  masterLogo,
  paypalLogo,
  paypalLogoInverted,
  visaLogo,
  visaLogoInverted,
  idealLogo,
  bancontactLogo,
} from "./iconHelper";
import s from "./PaymentIcon.module.css";

function getLogoSrc(payment) {
  const logos = {
    mastercard: masterLogo,
    paypal: paypalLogo,
    paypalInverted: paypalLogoInverted,
    visa: visaLogo,
    visaInverted: visaLogoInverted,
    ideal: idealLogo,
    bancontact: bancontactLogo,
  };
  return logos[payment];
}

type Props = {
  alt?: string;
  large?: boolean;
  payment?:
    | "mastercard"
    | "paypal"
    | "paypalInverted"
    | "visa"
    | "visaInverted"
    | "ideal"
    | "bancontact";
  small?: boolean;
  long?: boolean;
};

function PaymentIcon({ payment, alt, large, small, long }: Props) {
  const cx = classnames.bind(s);
  const inverted = payment === "paypalInverted" || payment === "visaInverted";
  const className = cx({
    inverted,
    large,
    medium: !(large || small),
    small,
    long,
  });
  return <img src={getLogoSrc(payment)} className={className} alt={alt} />;
}

export default PaymentIcon;
