// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import Heading from "stelar/components/Heading";
import Message from "stelar/components/Message";
import Paragraph from "stelar/components/Paragraph";
// ase
import LoginPrompt from "../../../components/LoginPrompt";
import { formNames } from "../../../pages/constants";
// component
import PasswordChangeForm from "./PasswordChangeForm";
import s from "./PasswordChangeFragment.module.css";

const PasswordChangeFragment = ({
  callToAction,
  description,
  formData,
  globalMessage,
  heading,
  hint,
  login,
  loginPrompt,
  submitFormData,
}) => {
  if (loginPrompt && login) {
    return (
      <LoginPrompt
        hint={hint}
        heading={heading}
        callToAction={callToAction}
        login={login}
      />
    );
  }

  return (
    <div>
      {heading && (
        <Heading className={s.center} prio2>
          {heading}
        </Heading>
      )}
      {description && <Paragraph className={s.center}>{description}</Paragraph>}
      {globalMessage && <Message {...globalMessage} />}
      {formData && (
        <PasswordChangeForm
          formDefinition={formData}
          onSubmit={submitFormData}
          formName={formNames.emailChange}
        />
      )}
    </div>
  );
};

PasswordChangeFragment.propTypes = {
  callToAction: PropTypes.string,
  description: PropTypes.string,
  heading: PropTypes.string,
  formData: PropTypes.object,
  globalMessage: PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
  }),
  hint: PropTypes.string,
  login: PropTypes.objectOf(PropTypes.string),
  loginPrompt: PropTypes.bool,
  submitFormData: PropTypes.func,
};

export default PasswordChangeFragment;
