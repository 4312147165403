import React from "react";
import classNames from "classnames/bind";
import s from "./Button.module.css";
import s2 from "./ButtonX.module.css";
import IconX from "../Icon/IconX";

type Props = {
  alt?: string;
  className?: string;
  disabled?: boolean;
  highContrast?: boolean;
  href?: string;
  onClick?: (...args: unknown[]) => unknown;
  skeleton?: boolean;
  text?: string;
  type?: string;
};

/**
 * Commonly used as modal or drawer close button
 */
function ButtonX({
  alt,
  className,
  disabled,
  highContrast,
  href,
  onClick,
  skeleton,
  text,
  type,
  ...attrs
}: Props) {
  const cx = classNames.bind(s);
  let buttonClass = cx("root", {
    disabled,
    highContrast,
    skeleton,
    prio5: true,
  });
  buttonClass = cx(buttonClass, className);

  const htmlHref = disabled ? null : href;
  const htmlText = text ? <span className={s2.text}>{text}</span> : null;

  let buttoncontent = (
    <span className={s.content}>
      {htmlText}
      {text ? " " : null}
      <span className={s2.icon}>
        <IconX small alt={alt} />
      </span>
    </span>
  );

  buttoncontent = !skeleton ? (
    buttoncontent
  ) : (
    <span className={s.skeleton}>{buttoncontent}</span>
  );

  let htmlTag = "button";
  if (href) {
    htmlTag = disabled ? "span" : "a";
  }

  return React.createElement(
    htmlTag,
    {
      ...attrs,
      className: buttonClass,
      disabled,
      href: htmlHref,
      type: type || "button",
      onClick,
      role: href ? "button" : null,
    },
    buttoncontent
  );
}

export default ButtonX;
