/* eslint-disable */
import setCookieParser from "set-cookie-parser";
// ase
import logger from "../../logger";
import { createHttpClient } from "../HttpClient";
import { logAccess } from "../../tracking/optin";
import { API_BASE } from "../constants";
import { extractPasstroughHeaders, Headers } from "../headers";

const log = logger(module);

export const SESSION_PATH = "/checkout/public/session";
const SESSION_ID_COOKIE_NAME = "sid";

function buildCookie(cookieName, cookieValue) {
  return cookieName && `${cookieName}=${encodeURIComponent(cookieValue)}`;
}

function createNewSession(apiHost, apiBase, sessionCookieName, req, res) {
  function extractSessionCookie(cookieHeader) {
    const cookies = setCookieParser.parse(cookieHeader);
    return (
      cookies &&
      cookies.find((e) => e && e.name && e.name === sessionCookieName)
    );
  }

  const httpClient = createHttpClient({
    apiHost,
    apiBase,
    passthroughHeaders: extractPasstroughHeaders(req.headers),
  });

  return httpClient.post(SESSION_PATH, {}).then((content) => {
    const cookieHeaders = content.headers && content.headers["set-cookie"];
    const newCookie = extractSessionCookie(cookieHeaders);

    if (cookieHeaders?.length > 0) {
      res.append("set-cookie", cookieHeaders);
    }
    return newCookie;
  });
}

function getCookieIfExists(req, cookieName) {
  return req.cookies && req.cookies[cookieName];
}

function getSessionCookie(req) {
  const cookieValue = getCookieIfExists(req, SESSION_ID_COOKIE_NAME);

  if (cookieValue && !cookieValue.startsWith("SID")) {
    return {
      name: SESSION_ID_COOKIE_NAME,
      value: cookieValue,
    };
  }

  return undefined;
}

function ensureSessionCookieExists(req, res, apiHost, apiBase) {
  const session = getSessionCookie(req);
  if (!session && apiBase === API_BASE.ASE) {
    return createNewSession(apiHost, apiBase, SESSION_ID_COOKIE_NAME, req, res);
  }
  return new Promise((resolve) => resolve(session));
}

export function extractCookiesForPassThrough(req, res, apiHost, apiBase) {
  return ensureSessionCookieExists(req, res, apiHost, apiBase)
    .then((sessionCookie) => {
      logAccess(req, res, apiHost);
      return sessionCookie;
    })
    .then((sessionCookie) => {
      const cookies = Object.getOwnPropertyNames(req.cookies)
        .filter((cookieName) => !cookieName.startsWith("sid"))
        .map(
          (cookieName) =>
            `${cookieName}=${encodeURIComponent(req.cookies[cookieName])}`
        );

      if (sessionCookie) {
        cookies.push(buildCookie(sessionCookie.name, sessionCookie.value));
      }
      return cookies;
    });
}

export function mapRequestCookiesToAxios(req) {
  return Object.getOwnPropertyNames(req.cookies || {}).map(
    (cookieName) =>
      `${cookieName}=${encodeURIComponent(req.cookies[cookieName])}`
  );
}

/* eslint-enable */
