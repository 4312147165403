import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { SellFeedbackFragment } from "shared/components/registry";

function ElementFeedbackModule({ feedbackType, categoryId }) {
  if (!feedbackType) {
    return null;
  }

  const metadata = {
    key: "categoryId",
    value: categoryId,
  };

  return (
    <div data-test-sell-element-feedback>
      <SellFeedbackFragment
        lazy
        type={feedbackType}
        metadata={JSON.stringify(metadata)}
      />
    </div>
  );
}

ElementFeedbackModule.propTypes = {
  feedbackType: PropTypes.string,
  categoryId: PropTypes.string,
};

export default ElementFeedbackModule;
