import { onCLS, onFCP, onINP, onLCP, onTTFB } from "web-vitals";
import { getEnv } from "../utils/envVariables";
import transformClsMetric from "./transformers/cls";
import transformFcpMetric from "./transformers/fcp";
import transformInpMetric from "./transformers/inp";
import transformLcpMetric from "./transformers/lcp";
import transformTtfbMetric from "./transformers/ttfb";

function getSelector(node, maxLen = 100) {
  let sel = "";
  try {
    while (node && node.nodeType !== 9) {
      const part = node.id
        ? `#${node.id}`
        : node.nodeName.toLowerCase() +
          (node.className && node.className.length
            ? `.${Array.from(node.classList.values()).join(".")}`
            : "");
      if (sel.length + part.length > maxLen - 1) return sel || part;
      sel = sel ? `${part}>${sel}` : part;
      if (node.id) break;
      // eslint-disable-next-line no-param-reassign
      node = node.parentNode;
    }
  } catch (err) {
    // Do nothing...
  }
  return sel;
}

function getLargestLayoutShiftEntry(entries) {
  return entries.reduce((a, b) => (a && a.value > b.value ? a : b));
}

function getLargestLayoutShiftSource(sources) {
  return sources.reduce((a, b) =>
    a.node &&
    a.previousRect.width * a.previousRect.height >
      b.previousRect.width * b.previousRect.height
      ? a
      : b
  );
}

function wasFIDBeforeDCL(fidEntry) {
  const navEntry = performance.getEntriesByType("navigation")[0];
  return navEntry && fidEntry.startTime < navEntry.domContentLoadedEventStart;
}

function getDebugInfo(name, entries = []) {
  let info;
  if (entries.length) {
    if (name === "LCP") {
      const lastEntry = entries[entries.length - 1];
      info = {
        debug_target: getSelector(lastEntry.element) || null,
        event_startTime: lastEntry.startTime,
      };
    } else if (name === "INP") {
      const firstEntry = entries[0];
      info = {
        debug_target: getSelector(firstEntry.target) || null,
        debug_event: firstEntry.name,
        debug_timing: wasFIDBeforeDCL(firstEntry) ? "pre_dcl" : "post_dcl",
        event_startTime: firstEntry.startTime,
      };
    } else if (name === "CLS") {
      const largestEntry = getLargestLayoutShiftEntry(entries);
      if (largestEntry && largestEntry.sources && largestEntry.sources.length) {
        const largestSource = getLargestLayoutShiftSource(largestEntry.sources);
        if (largestSource) {
          info = {
            debug_target: getSelector(largestSource.node) || null,
            event_startTime: largestEntry.startTime,
          };
        }
      }
    }
  }

  return {
    ...(info || { debug_target: null }),
    debug_pathname: window.location.pathname,
  };
}

function transformMetric(metric) {
  switch (metric?.name) {
    case "FCP":
      return transformFcpMetric(metric);
    case "INP":
      return transformInpMetric(metric);
    case "TTFB":
      return transformTtfbMetric(metric);
    case "CLS":
      return transformClsMetric(metric);
    case "LCP":
      return transformLcpMetric(metric);
    default:
      return {};
  }
}

export function transformMetrics(metrics) {
  return metrics?.map((metric) => transformMetric(metric));
}

export default function setupWebVitals() {
  const queue = new Set();
  function addToQueue(metric) {
    queue.add({
      ...transformMetric(metric),
      ...getDebugInfo(metric.name, metric.entries),
    });
  }

  function flushQueue() {
    if (queue.size > 0) {
      const body = JSON.stringify({
        entries: Array.from(queue),
        additional: {
          "x-mf-team": getEnv("TEAM"),
          "x-mf-build-number": getEnv("BUILD_NUMBER"),
        },
      });

      // Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
      if (navigator.sendBeacon) {
        navigator.sendBeacon("/ui_perf", body);
      } else {
        fetch("/ui_perf", { body, method: "POST", keepalive: true });
      }

      queue.clear();
    }
  }

  onCLS(addToQueue);
  onINP(addToQueue);
  onFCP(addToQueue);
  onLCP(addToQueue);
  onTTFB(addToQueue);

  // Report all available metrics whenever the page is backgrounded or unloaded.
  window.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "hidden") {
      flushQueue();
    }
  });

  // NOTE: Safari does not reliably fire the `visibilitychange` event when the
  // page is being unloaded. If Safari support is needed, you should also flush
  // the queue in the `pagehide` event.
  window.addEventListener("pagehide", flushQueue);
}
