import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { connect } from "react-redux";
import prefetch from "shared/ssrPrefetch/prefetch";
import loadLayerAvailabilities from "./actions";
import LayerAvailability from "./component/LayerAvailability";

const redial = {
  fetch: ({ dispatch, apiClient, props, cookies }) => {
    dispatch(loadLayerAvailabilities(apiClient, props.articleVariant, cookies));
  },
};

function mapStateToProps(state) {
  const { data, loading } = state.availabilityFragment;
  return { ...data, loading };
}

function LayerContentFragment(props) {
  return <LayerAvailability {...props} />;
}

export default connect(mapStateToProps)(prefetch(LayerContentFragment, redial));
