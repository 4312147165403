import AseFrontendUrls from "../utils/AseFrontendUrls";
import { isIncludedInPathObject } from "../utils/Routing";

const queryParameterKey = "target";

const reducedLayoutCondition = (queryParameterObject) => {
  const paramValue = queryParameterObject[queryParameterKey];
  return (
    isIncludedInPathObject(paramValue, AseFrontendUrls.DELIVERY_ADDRESS) ||
    isIncludedInPathObject(paramValue, AseFrontendUrls.PAYMENT) ||
    isIncludedInPathObject(paramValue, AseFrontendUrls.LAST_CHECK) ||
    isIncludedInPathObject(paramValue, AseFrontendUrls.ORDER_CONFIRMATION)
  );
};

export default reducedLayoutCondition;
