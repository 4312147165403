/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconBoldRight(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 12 8" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path d="M11.2,0.8c-0.4-0.4-1-0.4-1.4,0L6,4.8l-3.8-4c-0.4-0.4-1.1-0.4-1.4,0c-0.4,0.4-0.4,1.1,0,1.6l4.5,4.8 C5.5,7.4,5.7,7.5,6,7.5s0.5-0.1,0.7-0.3l4.5-4.8C11.6,2,11.6,1.3,11.2,0.8z" />
    </svg>
  );
}

export default IconBoldRight;
