import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import s from "./Headline.module.css";

const Headline = ({ children }) => <h4 className={s.headline}>{children}</h4>;

Headline.propTypes = {
  children: PropTypes.node,
};

export default Headline;
