import { showLoading, hideLoading } from "react-redux-loading-bar";
import { begin, end } from "./actions";

export default function testability({ dispatch }) {
  return (next) =>
    function (action) {
      const { type } = action;

      if (!type || typeof type !== "string") {
        // Normal action: pass it on
        return next(action);
      }

      if (
        type === "REQUEST_STARTED" ||
        type.endsWith("_STARTED_IN_BACKGROUND")
      ) {
        if (!type.endsWith("_STARTED_IN_BACKGROUND")) {
          dispatch(begin());
          dispatch(showLoading());
        }
        return next(action);
      }

      if (type === "REQUEST_FINISHED") {
        next(action);
        dispatch(hideLoading());
        dispatch(end());
        return null;
      }

      return next(action);
    };
}
