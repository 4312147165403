// vendor
import update from "immutability-helper";
// ase
import { ORDER_CONFIRMATION_PAGE__LOAD_ORDER_CONFIRMATION_PAGE } from "../constants";
// component
import initialState from "./initialState";

const orderConfirmationPage = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_CONFIRMATION_PAGE__LOAD_ORDER_CONFIRMATION_PAGE:
      return update(state, { $set: action.payload });
    default:
      return state;
  }
};

export default orderConfirmationPage;
