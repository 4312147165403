// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { FormProvider } from "react-hook-form";
// mf-stelar
import Form from "stelar/components/Form";
// ase
import { buildBackendGeneratedForm } from "../../../util/reduxFormUtils/reduxFormBuilder";
import EmailChangeFormContent from "./EmailChangeFormContent";

const EmailChangeForm = ({
  emailCurrent,
  formDefinition,
  onSubmit,
  submitting,
  formName,
}) => {
  if (formDefinition.structure?.length === 0) {
    return null;
  }

  const { formProviderValue, onSubmitHandler } = buildBackendGeneratedForm({
    formDefinition,
    onSubmit,
    scrollToError: false,
  });

  const layout = "standard";
  const { submit } = formDefinition;

  return (
    <FormProvider {...formProviderValue}>
      <Form
        action={submit.link}
        method="post"
        layout={layout}
        onSubmit={onSubmitHandler}
      >
        <EmailChangeFormContent
          emailCurrent={emailCurrent}
          formDefinition={formDefinition}
          submitting={submitting}
          formName={formName}
        />
      </Form>
    </FormProvider>
  );
};

export default EmailChangeForm;

EmailChangeForm.propTypes = {
  emailCurrent: PropTypes.object.isRequired,
  formDefinition: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};
