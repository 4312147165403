import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Text from "stelar/components/Text";
import s from "./BottomLine.module.css";

function BottomLine({ line }) {
  return (
    <address className={s.root}>
      <Text small className={s.text}>
        {line}
      </Text>
    </address>
  );
}

BottomLine.propTypes = {
  line: PropTypes.string,
};

export default BottomLine;
