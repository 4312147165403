import update from "immutability-helper";
import { isChangeInput } from "../constants";

const allClosedBrackets = new RegExp("\\]", "g"); // eslint-disable-line prefer-regex-literals
const allOpenBracketsOrPeriods = new RegExp("\\[|\\.", "g"); // eslint-disable-line prefer-regex-literals

function createUpdateObject(path, attribute) {
  return path
    .replace(allClosedBrackets, "")
    .split(allOpenBracketsOrPeriods)
    .reverse()
    .reduce((acc, el) => ({ [el]: acc }), attribute);
}

function isCompanySalutation(salutationValue) {
  return salutationValue === "0004"; // TODO refactor
}

export default (reducer, name) => (state, action) => {
  if (action.name && isChangeInput(action.type, name)) {
    const attribute = {
      [action.name]: {
        [action.contentAttributeName]: { $set: action.value },
      },
    };
    let newState;
    let updateObject;

    if (action.path) {
      updateObject = createUpdateObject(action.path, attribute);
    } else {
      updateObject = attribute;
    }
    newState = update(state, updateObject);
    if (action.name.indexOf("salutation") !== -1) {
      newState = update(newState, {
        isCompanySalutation: {
          $set: isCompanySalutation(action.value),
        },
      });
    }
    return newState;
  }

  return reducer(state, action);
};
