import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PaymentIcon from "stelar/components/PaymentIcon";
import List from "../List";
import ListElement from "../ListElement";
import IconElement from "../IconElement";

import s from "./PaymentList.module.css";

function PaymentList({ payment }) {
  return (
    <List>
      {payment.methodInvoice && (
        <ListElement>{payment.methodInvoice}</ListElement>
      )}
      {payment.methodDirectDebit && (
        <ListElement>{payment.methodDirectDebit}</ListElement>
      )}
      {payment.methodPaymentInAdvance && (
        <ListElement>{payment.methodPaymentInAdvance}</ListElement>
      )}
      {payment.methodIDeal && (
        <IconElement
          name={payment.methodIDeal}
          icon={
            <span className={s.paymentIcon}>
              <PaymentIcon payment="ideal" alt="iDEAL" />{" "}
            </span>
          }
          iconRight
        />
      )}
      {payment.methodBancontact && (
        <IconElement
          name={payment.methodBancontact}
          icon={
            <span className={s.paymentIcon}>
              <PaymentIcon long payment="bancontact" alt="Bancontact" />{" "}
            </span>
          }
          iconRight
        />
      )}
      {payment.methodCreditcard && (
        <IconElement
          name={payment.methodCreditcard}
          icon={
            <span className={s.paymentIcon}>
              <PaymentIcon payment="visa" alt="Visa" />{" "}
              <PaymentIcon payment="mastercard" alt="Mastercard" />
            </span>
          }
          iconRight
        />
      )}
      {payment.methodPaypal && (
        <IconElement
          name={payment.methodPaypal}
          icon={
            <span className={s.paymentIcon}>
              <PaymentIcon payment="paypal" alt="Paypal" />{" "}
            </span>
          }
          iconRight
        />
      )}
    </List>
  );
}

PaymentList.propTypes = {
  payment: PropTypes.object,
};

PaymentList.defaultProps = {};

export default PaymentList;
