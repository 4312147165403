import { getParsedObjectFromCookie } from "./cookie";

export const AB_TEST_NAMES = {
  PDS_ACCORDION: "pds-accordion",
  GO_GREEN: "gogreen",
};

export function isTestgroupA(abTestKey, providedCookieString) {
  const abCookieObject = getParsedObjectFromCookie(
    "mf-ab",
    providedCookieString
  );
  return abCookieObject?.[abTestKey]?.toLocaleLowerCase() === "a" || null;
}

export function isTestgroupB(abTestKey, providedCookieString) {
  const abCookieObject = getParsedObjectFromCookie(
    "mf-ab",
    providedCookieString
  );
  return abCookieObject?.[abTestKey]?.toLocaleLowerCase() === "b" || null;
}
