import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Headline from "../Headline";
import LargePhoneNumber from "../LargePhoneNumber";
import Link from "../Link";
import List from "../List";
import ListElement from "../ListElement";

const ServiceContact = ({ contact }) => {
  const mailto = `mailto:${contact.email.address}`;
  return (
    <div>
      <Headline>{contact.headline}</Headline>
      <LargePhoneNumber
        label={contact.label}
        number={contact.phone}
        note={contact.note}
      />
      <List>
        <ListElement>
          {contact.email.label} <a href={mailto}>{contact.email.address}</a>
        </ListElement>
        <ListElement>
          <Link href={contact.link.link}>{contact.link.name}</Link>
        </ListElement>
      </List>
    </div>
  );
};

ServiceContact.propTypes = {
  contact: PropTypes.object.isRequired,
};

ServiceContact.defaultProps = {};

export default ServiceContact;
