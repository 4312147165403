import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import ContentSpacing from "../../ContentSpacing";
import MainVideo from "../../ProductDetail/MainVideo";
import { parseQueryParamsforSirv } from "./helper";
import s from "./ElementVideoSirv.module.css";

function ElementVideoSirv({ asset, fullWidth }) {
  // SELL-4385 - take query params for options
  // https://assets.manufactum.de/c/manufactum/kampagnen/imagekampagne-2022/MANUFACTUM_Manifest-Film_2022.mp4?thumbnail=8.5
  const options = parseQueryParamsforSirv(asset);
  const manipulateAsset = asset.split("?")[0];
  return (
    asset && (
      <ContentSpacing
        alignment="columns"
        className={s.spacing}
        fullWidth={fullWidth}
        gutters
        data-test-sell-element-video
      >
        <MainVideo asset={manipulateAsset} options={options} />
      </ContentSpacing>
    )
  );
}

ElementVideoSirv.propTypes = {
  asset: PropTypes.string,
  fullWidth: PropTypes.bool,
};

ElementVideoSirv.defaultProps = {};

export default ElementVideoSirv;
