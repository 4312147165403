import track from "shared/tracking";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { IMAGE_PROFILE_DEFAULTS } from "shared/utils/imageUrlBuilder";
import useIsInViewport from "shared/utils/useIsInViewport";
import RatioImage from "../RatioImage";

export default function SlideMosaic({
  mosaics,
  imgSizes,
  onLinkClick,
  onImageLoad,
  loadedImages = {},
  lazy,
  isSingleSlide,
}) {
  const [promoViewTriggered, setPromoViewTriggered] = useState(false);
  const { ref, inViewport } = useIsInViewport();

  useEffect(() => {
    if (inViewport && !promoViewTriggered) {
      if (isSingleSlide) {
        setPromoViewTriggered(true);
        track(mosaics[0].tracking, { isPromoView: true });
      }
    }
  }, [inViewport]);

  const elements = mosaics?.map((mosaic) => {
    const { image, breakpointImages, link, tracking } = mosaic;

    function onClick(e) {
      e.preventDefault();
      onLinkClick(e, tracking);
    }

    const imageNode = (
      <RatioImage
        image={image}
        imageProfileDefault={IMAGE_PROFILE_DEFAULTS.kurator}
        breakpointImages={breakpointImages}
        onImageLoad={onImageLoad}
        hasLoaded={loadedImages[image.pattern]}
        sizes={imgSizes}
        lazy={lazy}
        data-test-sell-stage-slide
      />
    );

    return link.link ? (
      <a href={link.link} onClick={onClick} style={{ width: "100%" }}>
        {imageNode}
      </a>
    ) : (
      imageNode
    );
  });

  return (
    <div ref={ref} style={{ display: "flex", flexDirection: "row" }}>
      {elements}
    </div>
  );
}

SlideMosaic.propTypes = {
  mosaics: PropTypes.arrayOf({
    image: PropTypes.object,
    link: PropTypes.object,
    breakpointImages: PropTypes.array,
    tracking: PropTypes.object,
  }),
  imgSizes: PropTypes.string,
  onLinkClick: PropTypes.func,
  onImageLoad: PropTypes.func,
  loadedImages: PropTypes.object,
  lazy: PropTypes.bool,
  isSingleSlide: PropTypes.bool,
};
