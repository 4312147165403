import React from "react";
import classNames from "classnames/bind";
import Text from "../Text";
import h from "../helpers";
import s from "./Availability.module.css";

const cx = classNames.bind(s);

type Props = {
  additionalInfo?: string;
  additionalInfos?: unknown[];
  info?: string;
  isLoading?: boolean;
  styling?: "available" | "deliverable" | "tempUnavailable" | "soldOut";
  text?: string;
};

function Availability({
  additionalInfo,
  additionalInfos,
  info,
  isLoading,
  styling,
  text,
}: Props) {
  const additionalInfoItem = (itemText, isMapped) =>
    itemText ? (
      <div key={itemText} className={cx({ item: isMapped })}>
        <Text small skeleton={isLoading}>
          {itemText}
        </Text>
      </div>
    ) : null;

  return (
    <div>
      <div data-test-stelar-availability-texts>
        {text ? (
          <Text small skeleton={isLoading} className={cx(styling, "text")}>
            {text}
          </Text>
        ) : null}
        {info ? (
          <Text small skeleton={isLoading}>
            {", "}
            {h.linebreaksToHtmlBrs(info)}
          </Text>
        ) : null}
      </div>
      {additionalInfoItem(additionalInfo, false)}
      {additionalInfos
        ? additionalInfos.map((item) => additionalInfoItem(item, true))
        : null}
    </div>
  );
}

export default Availability;
