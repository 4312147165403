export default {
  page: {
    globalMessage: {
      title: "………… ………………………… ……… ……… ……… …………………………… ………………………………",
      type: "hint",
      text: "……… …………………… …… …………… ………… …………………………… …… ………… …………………………………… …………………………………………………………………………………………………………………………",
    },
  },
  contentRows: [
    {
      greeting: "……………… ………… ……… ………… ………………………… …………",
      text: "………………… ……………… ………………… …… …………… ………………………… ………………… ……………………………………………………… ……………… …… …………… ……………… …………… …………… …………… ………………… …………………",
      type: "ConfirmationAndContact",
    },
    {
      heading: "………………………… …………………………",
      text: "……… ……… ………………………………… ………………………… …………………… ……… ……………… …………………… …………… ……… ………………………… ……… …………………………",
      datenschutz: {
        label:
          "……… ……………… ……… ………………………… ……………………… ……… ………………………………………… ………… ……… ……… …………………… ……………………… ……… …………",
      },
      email: {
        label: "",
      },
      subscribeButton: {
        label: "…………… …………………………",
      },
      type: "NewsletterManagement",
    },
  ],
};
