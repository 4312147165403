import {
  begin as testabilityBegin,
  end as testabilityEnd,
} from "shared/testability/actions";
import * as action from "../../../pages/constants";

export default function searchActions(opts = {}) {
  const defaults = {
    suggestDelay: 200,
    productTypeSuggestDelay: 600,
    productSwitchSuggestDelay: 200,
  };

  // higher delay for IE11
  if (
    typeof document !== "undefined" &&
    document.documentElement.classList &&
    document.documentElement.classList.contains("mf-ie11")
  ) {
    defaults.suggestDelay = 1000;
    defaults.productTypeSuggestDelay = 1100;
    defaults.productSwitchSuggestDelay = 600;
  }

  const { suggestDelay, productTypeSuggestDelay, productSwitchSuggestDelay } =
    Object.assign(defaults, opts);
  const timeout = {
    words: null,
    products: null,
  };

  function loadSearchSuggest(apiClient, query) {
    const encodedQuery = encodeURIComponent(query.replace(/\/|\\/g, ""));

    return [
      {
        type: action.LOAD_SEARCH_SUGGEST_STARTED_IN_BACKGROUND,
        payload: { query },
      },
      apiClient.get(
        `/search/suggest/${encodedQuery}`,
        {
          ok: (response) => ({
            type: action.LOAD_SEARCH_SUGGEST_SUCCESS,
            payload: { jsonData: response.data, query },
          }),
          noContent: () => ({
            type: action.LOAD_SEARCH_SUGGEST_FAILED,
            payload: { query },
          }),
          badRequest: () => ({
            type: action.LOAD_SEARCH_SUGGEST_FAILED,
            payload: { query },
          }),
        },
        { apiBase: "/api/sell" }
      ),
    ];
  }

  function loadSearchSuggestProducts(apiClient, query) {
    const encodedQuery = encodeURIComponent(query.replace(/\/|\\/g, ""));

    return [
      {
        type: action.LOAD_SEARCH_SUGGEST_PRODUCTS_STARTED_IN_BACKGROUND,
        payload: { query },
      },
      apiClient.get(
        `/search/suggest/products/${encodedQuery}`,
        {
          ok: (response) => ({
            type: action.LOAD_SEARCH_SUGGEST_PRODUCTS_SUCCESS,
            payload: { jsonData: response.data, query },
          }),
          noContent: () => ({
            type: action.LOAD_SEARCH_SUGGEST_PRODUCTS_FAILED,
            payload: { query },
          }),
          badRequest: () => ({
            type: action.LOAD_SEARCH_SUGGEST_PRODUCTS_FAILED,
            payload: { query },
          }),
        },
        { apiBase: "/api/sell" }
      ),
    ];
  }

  function loadSearchSuggestDelayed(apiClient, query) {
    return function (dispatch) {
      if (timeout.words) {
        dispatch(testabilityEnd());
        clearTimeout(timeout.words);
      }
      dispatch(testabilityBegin());
      timeout.words = setTimeout(() => {
        dispatch(testabilityEnd());
        dispatch(loadSearchSuggest(apiClient, query));
        timeout.words = null;
      }, suggestDelay);
      return null;
    };
  }

  function loadSearchSuggestProductsDelayed(apiClient, query, delay) {
    return function (dispatch) {
      if (timeout.products) {
        dispatch(testabilityEnd());
        clearTimeout(timeout.products);
      }
      dispatch(testabilityBegin());
      timeout.products = setTimeout(() => {
        dispatch(testabilityEnd());
        dispatch(loadSearchSuggestProducts(apiClient, query));
        timeout.products = null;
      }, delay);
      return null;
    };
  }

  function loadSearchSuggestWithLongDelay(apiClient, query) {
    return loadSearchSuggestProductsDelayed(
      apiClient,
      query,
      productTypeSuggestDelay
    );
  }

  function loadSearchSuggestWithShortDelay(apiClient, query) {
    return loadSearchSuggestProductsDelayed(
      apiClient,
      query,
      productSwitchSuggestDelay
    );
  }

  function loadSuggestData(apiClient, query) {
    return (dispatch) => {
      loadSearchSuggestDelayed(apiClient, query)(dispatch);
      loadSearchSuggestWithLongDelay(apiClient, query)(dispatch);
    };
  }

  function clearSearchSuggests() {
    return {
      type: action.CLEAR_SEARCH_SUGGESTS,
    };
  }

  function changeSearchString(searchString) {
    return {
      type: action.CHANGE_SEARCH_STRING,
      payload: searchString,
    };
  }

  function clearSearchString() {
    return { type: action.CLEAR_SEARCH_STRING };
  }

  let hoverTimeout = null;

  function hoverSuggestion(suggestionIndex) {
    return (dispatch) => {
      if (hoverTimeout) clearTimeout(hoverTimeout);
      hoverTimeout = window.setTimeout(
        () =>
          dispatch({
            type: action.HOVER_SEARCH_SUGGEST,
            payload: suggestionIndex,
          }),
        200
      );
    };
  }

  let leaveTimeout = null;
  function leaveSuggestion() {
    clearTimeout(leaveTimeout);
    return (dispatch) => {
      if (leaveTimeout) clearTimeout(leaveTimeout);
      leaveTimeout = window.setTimeout(
        () =>
          dispatch({
            type: action.LEAVE_SEARCH_SUGGEST,
          }),
        200
      );
    };
  }

  function mouseEnterSubmitButton() {
    window.clearTimeout(hoverTimeout);
    return {
      type: action.MOUSE_ENTER_SEARCH_SUBMIT,
    };
  }

  function arrowNavigation(key) {
    switch (key) {
      case "ArrowDown":
        return { type: action.SUGGEST_ARROW_DOWN };

      case "ArrowUp":
        return { type: action.SUGGEST_ARROW_UP };

      case "ArrowRight":
        return { type: action.SUGGEST_ARROW_RIGHT };

      case "ArrowLeft":
        return { type: action.SUGGEST_ARROW_LEFT };

      default:
        return null;
    }
  }

  return {
    loadSearchSuggest: loadSuggestData,
    loadProductSuggests: loadSearchSuggestWithShortDelay,
    clearSearchSuggests,
    changeSearchString,
    clearSearchString,
    arrowNavigation,
    hoverSuggestion,
    leaveSuggestion,
    mouseEnterSubmitButton,
  };
}
