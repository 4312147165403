import React from "react";
import classNames from "classnames/bind";

import s from "./List.module.css";

const cx = classNames.bind(s);

type Props = {
  children?: React.ReactNode;
  bullets?: boolean;
  ordered?: boolean;
};

function List({ children, bullets, ordered }: Props) {
  const bulletOverride = bullets || (!bullets && !ordered) || false;
  const tag = ordered ? "ol" : "ul";
  const classNamesString = cx("root", {
    ordered,
    bullets: bulletOverride,
  });
  const items = children
    ? React.Children.map(
        children,
        (item: React.ReactElement) =>
          item &&
          React.cloneElement(
            item,
            { margin: true },
            item.props && item.props.children
          )
      )
    : null;
  return React.createElement(tag, { className: classNamesString }, items);
}

export default List;
