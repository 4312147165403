import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Text from "stelar/components/Text";
import s from "./DhlGoGreen.module.css";

function DhlGoGreen({ label, dhlGoGreenEntry }) {
  return (
    <div className={s.root}>
      {label && (
        <Text normal className={s.label}>
          {label}
        </Text>
      )}
      {dhlGoGreenEntry && (
        <div className={s.dhlGoGreenImage}>
          <img
            src={dhlGoGreenEntry.image?.src}
            alt={dhlGoGreenEntry.image?.alt}
            height="58px"
          />
        </div>
      )}
    </div>
  );
}

DhlGoGreen.propTypes = {
  label: PropTypes.string,
  dhlGoGreenEntry: PropTypes.object,
};

export default DhlGoGreen;
