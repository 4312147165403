// ase
import track from "shared/tracking";
import backendUrl from "../../pages/utils/AseBackendUrls";
import { triggerLineItemEditFragmentSubmitEvent } from "../../pages/utils/BrowserEvents";
import {
  LINE_ITEM_EDIT_FRAGMENT__CHANGE_INPUT,
  LINE_ITEM_EDIT_FRAGMENT__GET,
  LINE_ITEM_EDIT_FRAGMENT__POST,
  LINE_ITEM_EDIT_FRAGMENT__POST_FAILED,
  LINE_ITEM_EDIT_FRAGMENT__VALIDATE,
} from "../../pages/constants";

export function getFragment(apiClient, url) {
  return apiClient.get(url || backendUrl.LINE_ITEM_EDIT, {
    ok: (content) => ({
      type: LINE_ITEM_EDIT_FRAGMENT__GET,
      payload: content.body,
    }),
  });
}

export function reGetFragment(sku, apiClient) {
  return (dispatch, getState) => {
    const { lineItemEditFragment } = getState();
    const { lineItemId } = lineItemEditFragment.data;
    const freeText =
      lineItemEditFragment.data.article.freeText &&
      lineItemEditFragment.data.article.freeText.value;
    const quantity = lineItemEditFragment.data.article.quantity.value;
    let queryStringParameters = sku
      ? `?lineItemId=${lineItemId}&quantity=${quantity}&sku=${sku}`
      : "";
    queryStringParameters += freeText ? `&freeText=${freeText}` : "";
    const url = `${backendUrl.LINE_ITEM_EDIT}${queryStringParameters}`;
    dispatch(
      apiClient.get(url, {
        ok: (content) => ({
          type: LINE_ITEM_EDIT_FRAGMENT__GET,
          payload: content.body,
        }),
        badRequest: (content) => ({
          type: LINE_ITEM_EDIT_FRAGMENT__POST_FAILED,
          payload: content.body,
        }),
      })
    );

    return true;
  };
}

export function updateInput(event) {
  event.preventDefault();
  const { name, value } = event.target;
  return {
    type: LINE_ITEM_EDIT_FRAGMENT__CHANGE_INPUT,
    payload: { name, value },
  };
}

export function submitForm({ event, url, values }, apiClient) {
  event.preventDefault();
  return (dispatch, getState) => {
    dispatch({
      type: LINE_ITEM_EDIT_FRAGMENT__VALIDATE,
    });
    const state = getState();
    if (state.lineItemEditFragment.submitable) {
      dispatch(
        apiClient.post(url, values, {
          ok: (content) => [
            {
              type: LINE_ITEM_EDIT_FRAGMENT__POST,
              payload: content.body,
            },
            triggerLineItemEditFragmentSubmitEvent(),
            track(content.body),
          ],
          badRequest: (content) => ({
            type: LINE_ITEM_EDIT_FRAGMENT__POST_FAILED,
            payload: content.body,
          }),
        })
      );
    }
    return true;
  };
}
