import classnames from "classnames/bind";
import PropTypes from "prop-types";
import React, { useState } from "react"; // eslint-disable-line import/no-extraneous-dependencies
import IconDown from "stelar/components/Icon/IconDown";

import s from "./AccordionItem.module.css";

const cx = classnames.bind(s);

function AccordionItem({
  id,
  header,
  content,
  order,
  preview,
  initiallyExpanded,
  noBottomBorder,
}) {
  const [expanded, setExpanded] = useState(initiallyExpanded || false);

  return (
    <div
      className={cx("items", { "border-b-0": noBottomBorder })}
      style={{
        order: order || "initial",
      }}
    >
      <div className={cx("item")}>
        <h2>
          <button
            type="button"
            className={cx("trigger")}
            id={`accordion-header-${id}`}
            onClick={() => setExpanded((value) => !value)}
            aria-controls={`accordion-content-${id}`}
            aria-expanded={expanded}
          >
            <span className={cx("header")}>{header}</span>
            <IconDown className={s.icon} />
          </button>
        </h2>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
        <div
          className={cx("content", { preview })}
          id={`accordion-content-${id}`}
          role="region"
          aria-labelledby={`accordion-header-${id}`}
          hidden={!expanded}
          onClick={() => preview && !expanded && setExpanded(true)}
        >
          {content}
        </div>
      </div>
    </div>
  );
}

AccordionItem.propTypes = {
  id: PropTypes.string,
  header: PropTypes.object,
  content: PropTypes.object,
  order: PropTypes.object,
  preview: PropTypes.bool,
  initiallyExpanded: PropTypes.bool,
  noBottomBorder: PropTypes.bool,
};

export default AccordionItem;
