// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import FormRow from "stelar/components/FormRow";
import FormRowList from "stelar/components/FormRowList";
import Select from "stelar/components/Select";
import Text from "stelar/components/Text";
import { Input } from "stelar/components/Input";
// component
import s from "./FormRows.module.css";

function FormRows({
  dimensionSelections,
  doReGetFragment,
  doUpdateInput,
  freeText,
  quantity,
  skuCode,
}) {
  const quantityRegex = quantity?.regex && new RegExp(quantity?.regex);

  return (
    <div className={s.root}>
      <FormRowList layout="fluid">
        {dimensionSelections &&
          dimensionSelections.map((dimension, index) => (
            <FormRow
              key={dimension.name}
              label={{
                error: dimension.error,
                for: `${dimension.name}-${index}`,
                text: dimension.label,
              }}
            >
              <Select
                data-test-ase-field-input="dimension"
                id={`${dimension.name}-${index}`}
                name={dimension.name}
                onChange={(event) => doReGetFragment(event.target.value)}
                selectOptions={dimension.selectOptions}
                value={skuCode}
              />
            </FormRow>
          ))}
        <FormRow
          label={{
            error: quantity.error,
            for: "lineItemQuantity",
            text: quantity.label,
          }}
        >
          <Input
            data-test-ase-field-input="lineItemQuantity"
            id="lineItemQuantity"
            name="quantity"
            input={{
              type: "text",
              inputmode: "decimal",
            }}
            onChange={(e) => {
              const newValue = e.target.value;
              if (
                quantityRegex &&
                newValue?.length !== 0 &&
                new RegExp(quantityRegex).test(newValue) === false
              ) {
                return;
              }
              doUpdateInput(e);
            }}
            onBlur={(e) => {
              let newValue = e.target.value;
              if (newValue?.length === 0) {
                newValue = quantity?.minValue || 1;
              }
              if (newValue > quantity?.maxValue) {
                newValue = quantity?.maxValue;
              }
              doUpdateInput(e);
            }}
            value={quantity.value}
          />
        </FormRow>
      </FormRowList>
      {freeText && freeText.text && (
        <div className={s.freeTextFormRow} data-test-sell-product-free-input>
          <label htmlFor="freeText">
            <Text small>{freeText.text.description}</Text>
          </label>
          <Input
            className={s.freeText}
            id="freeText"
            name="freeText"
            onChange={(event) => doUpdateInput(event)}
            value={freeText.value}
          />
        </div>
      )}
    </div>
  );
}

FormRows.propTypes = {
  dimensionSelections: PropTypes.array,
  doReGetFragment: PropTypes.func.isRequired,
  doUpdateInput: PropTypes.func.isRequired,
  freeText: PropTypes.object,
  quantity: PropTypes.object,
  skuCode: PropTypes.string,
};

export default FormRows;
