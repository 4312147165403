import classnames from "classnames/bind";
import media from "stelar/components/media";
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import {
  buildImageSrcDefinitions,
  buildPreviewImageUrl,
  buildSrcSetWithDefault,
} from "shared/utils/imageUrlBuilder";
import s from "./RatioImageInner.module.css";

const cx = classnames.bind(s);

const trans =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

const mediaMap = {
  small: media.up_to_M,
  medium: media.M_to_L,
};

function RatioImageInner({
  isInformative = false,
  image,
  breakpointImages = [],
  sizes,
  className,
  hasLoaded,
  onImageLoad = () => {},
  skeleton,
  imageProfileDefault,
  ...props
}) {
  const { srcSet, previewSrc, fallBackSrc } = buildImageSrcDefinitions(
    image,
    imageProfileDefault
  );

  return (
    <div
      className={classnames(
        cx(
          "wrap",
          {
            withRatio: image?.ratio,
            artDirected: breakpointImages[0],
            hasLoaded,
            skeleton,
          },
          className
        )
      )}
      {...props}
    >
      {breakpointImages.map((source) => {
        if (!source.ratio) {
          return null;
        }
        return (
          <div
            style={{ paddingTop: `${(1 / source.ratio) * 100}%` }}
            className={cx("ratio", source.breakpointSize)}
            key={`spacer_${source.pattern}_${source.breakpointSize}`}
          />
        );
      })}
      {image?.ratio && (
        <div
          style={{ paddingTop: `${(1 / image.ratio) * 100}%` }}
          className={cx("ratio", "large")}
        />
      )}
      <picture className={s.previewPicture}>
        {breakpointImages.map((source) => (
          <source
            media={mediaMap[source.breakpointSize]}
            sizes="100vw"
            srcSet={buildPreviewImageUrl(source)}
            key={`preview_${source.pattern}_${source.breakpointSize}`}
          />
        ))}
        <img
          className={s.preview}
          src={previewSrc}
          alt={image?.altText || ""}
          aria-hidden
        />
      </picture>
      <picture className={s.mainPicture}>
        {breakpointImages.map((source) => (
          <source
            media={mediaMap[source.breakpointSize]}
            sizes={sizes}
            srcSet={buildSrcSetWithDefault(source, imageProfileDefault)}
            key={`main_${source.pattern}_${source.breakpointSize}`}
          />
        ))}
        <img
          className={s.image}
          src={fallBackSrc || trans}
          srcSet={srcSet}
          alt={image?.altText || ""}
          sizes={sizes}
          onLoad={
            image?.fallbackPreset ? () => onImageLoad(image?.pattern) : null
          }
          aria-hidden={!isInformative}
        />
      </picture>
    </div>
  );
}

RatioImageInner.propTypes = {
  isInformative: PropTypes.bool,
  image: PropTypes.shape({
    pattern: PropTypes.string,
    presets: PropTypes.array,
    previewPreset: PropTypes.string,
    fallbackPreset: PropTypes.string,
    ratio: PropTypes.number,
    altText: PropTypes.string,
  }),
  breakpointImages: PropTypes.arrayOf(
    PropTypes.shape({
      pattern: PropTypes.string,
      presets: PropTypes.array,
      previewPreset: PropTypes.string,
      fallbackPreset: PropTypes.string,
      ratio: PropTypes.number,
      altText: PropTypes.string,
    })
  ),
  sizes: PropTypes.string,
  skeleton: PropTypes.bool,
  className: PropTypes.string,
  hasLoaded: PropTypes.bool,
  onImageLoad: PropTypes.func,
  imageProfileDefault: PropTypes.object,
};

export default RatioImageInner;
