import {
  ADD_OVERLAY_ACTION,
  REMOVE_OVERLAY_ACTION,
  UPDATE_OVERLAY_ACTION,
} from "./constants";

const initialState = {
  contents: [],
  scrollPosition: 0,
};

export default function overlay(state = initialState, action) {
  if (action.type === ADD_OVERLAY_ACTION) {
    const { scrollPosition, content } = action.payload;
    const newState = Object.assign({}, state); // eslint-disable-line prefer-object-spread
    if (state.contents.length === 0) {
      newState.scrollPosition = scrollPosition;
    }
    newState.contents.unshift(
      !content.title && content.body ? content.body : content
    );

    return newState;
  }
  if (action.type === REMOVE_OVERLAY_ACTION) {
    const newState = Object.assign({}, state); // eslint-disable-line prefer-object-spread
    newState.contents.shift();
    return newState;
  }
  if (action.type === UPDATE_OVERLAY_ACTION) {
    const { content } = action.payload;
    const newState = Object.assign({}, state); // eslint-disable-line prefer-object-spread
    if (state.contents.length === 0) {
      newState.scrollPosition = 0;
    }
    newState.contents.shift();

    newState.contents.unshift(
      !content.title && content.body ? content.body : content
    );

    return newState;
  }
  return state;
}
