import React from "react";
import classnames from "classnames/bind";
import s from "./TextHighlight.module.css";
import HtmlContent from "../HtmlContent";
import Paragraph from "../Paragraph";
import Text from "../Text";

const cx = classnames.bind(s);

/**
 * TextHighlight
 */
const HEADING = "heading";
const TEXT = "text";

type Props = {
  children: React.ReactNode;
  variant?: unknown | unknown;
  skeleton?: boolean;
};

function TextHighlight({ children, variant, skeleton, ...attrs }: Props) {
  switch (variant) {
    case HEADING:
      return (
        <div className={cx("root", "heading", { skeleton })}>
          <Text em {...attrs} skeleton={skeleton}>
            {children}
          </Text>
        </div>
      );

    case TEXT:
    default:
      return (
        <div className={cx("root", "text", { skeleton })}>
          {!skeleton ? (
            <HtmlContent
              dangerouslySetInnerHTML={{ __html: children }}
              {...attrs}
            />
          ) : (
            <Paragraph skeleton={skeleton}>{children}</Paragraph>
          )}
        </div>
      );
  }
}

export default TextHighlight;
