import { setPageTitle } from "shared/webbridge/actions";
import backendUrl from "../utils/AseBackendUrls";
import { CREDIT_CARD_PAYMENT_PAGE__LOAD_PAGE } from "../constants";

export function loadCreditCardPaymentPage(apiClient, paymentId, query) {
  const appendix = paymentId ? `/${paymentId}` : "";
  return apiClient.get(
    `${backendUrl.CREDIT_CARD_PAYMENT_V3}${appendix}`,
    {
      ok: (content) => [
        {
          type: CREDIT_CARD_PAYMENT_PAGE__LOAD_PAGE,
          payload: content.body,
        },
        setPageTitle(content.body?.page?.headData?.title),
      ],
    },
    {
      httpClientOptions: {
        params: query,
      },
    }
  );
}
