import { SSR_PREFETCHED } from "shared/ssrPrefetch/constants";
import {
  LOAD_RECOMMENDATIONS_SUCCESS,
  LOAD_RECOMMENDATIONS_STARTED,
  LOAD_RECOMMENDATIONS_FAILED,
  SHOW_SKELETON_RECOMMENDATIONS,
  LOAD_RECOMMENDATIONS_TIMEOUT,
  RESET_CURRENT_ARTICLE_RECOMMENDATIONS,
} from "../../pages/constants";
import { buildCacheKey } from "./helper";

export function loadRecommendations(apiClient, location, params, isClient) {
  return [
    {
      type: LOAD_RECOMMENDATIONS_STARTED,
      payload: { location, articleNumber: params.articleNumber },
    },
    apiClient.get(
      `/recommendations/${encodeURIComponent(location)}`,
      {
        ok: (response) => [
          ...(!isClient && {
            type: SSR_PREFETCHED,
            payload: buildCacheKey({
              location,
              article: params.articleNumber,
            }),
          }),
          {
            type: LOAD_RECOMMENDATIONS_SUCCESS,
            payload: {
              ...response.data,
              location,
              articleNumber: params.articleNumber,
            },
          },
        ],
        notFound: () => ({
          type: LOAD_RECOMMENDATIONS_FAILED,
          payload: { location, articleNumber: params.articleNumber },
        }),
        noContent: () => ({
          type: LOAD_RECOMMENDATIONS_FAILED,
          payload: { location, articleNumber: params.articleNumber },
        }),
        internalServerError: () => ({
          type: LOAD_RECOMMENDATIONS_FAILED,
          payload: { location, articleNumber: params.articleNumber },
        }),
        gatewayTimeout: () => ({
          type: LOAD_RECOMMENDATIONS_TIMEOUT,
          payload: { location, articleNumber: params.articleNumber },
        }),
      },
      {
        apiBase: "/api/sell",
        httpClientOptions: { params, ...(!isClient && { timeout: 1000 }) },
        handleUnexpected: () => ({
          type: LOAD_RECOMMENDATIONS_FAILED,
          payload: { location, articleNumber: params.articleNumber },
        }),
      }
    ),
  ];
}

export function showSkeleton(location, params, skeletonData) {
  return {
    type: SHOW_SKELETON_RECOMMENDATIONS,
    payload: { location, articleNumber: params.articleNumber, skeletonData },
  };
}

export function resetCurrentArticle() {
  return { type: RESET_CURRENT_ARTICLE_RECOMMENDATIONS };
}
