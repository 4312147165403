import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies

import classnames from "classnames/bind";
import { Button } from "stelar/components/Button";
import IconX from "stelar/components/Icon/IconX";

import { useTrackWhenViewed } from "shared/utils/useTrackWhenViewed";
import s from "./AppInstallBanner.module.css";
import {
  appInstallBannerVisible,
  closeAppInstallBanner,
} from "../../utils/bannerHelper";

const cx = classnames.bind(s);

function AppInstallBanner({
  appIcon,
  texts,
  cta,
  handleClickAndTracking,
  tracking,
}) {
  const [showBanner, setShowBanner] = React.useState(true);
  const { ref } = useTrackWhenViewed(tracking);

  React.useEffect(() => {
    const isBannerVisible = appInstallBannerVisible();
    setShowBanner(isBannerVisible);
  }, []);

  function onClickClose(e) {
    const data = {
      googleTagManager: {
        containerId: window.gtmId,
        payload: { event: "appinstallbanner-close-click" },
      },
    };
    closeAppInstallBanner();
    setShowBanner(false);
    handleClickAndTracking(e, data);
  }

  return showBanner ? (
    <div
      ref={ref}
      className={cx("root", { hide: !showBanner })}
      data-test-sell-appinstallbanner-container
    >
      <div className={s.leftContainer}>
        {/* TODO a11y */}
        {/* eslint-disable jsx-a11y/control-has-associated-label */}
        <button
          className={s.iconButton}
          data-test-sell-topbanner-button
          type="button"
          onClick={onClickClose}
        >
          <IconX small />
        </button>
        {/* eslint-enable jsx-a11y/control-has-associated-label */}
        {appIcon && (
          <div className={s.appIcon}>
            <img src={appIcon.src} width="50" alt={appIcon.alt} />
          </div>
        )}
        {texts && (
          <div className={s.texts}>
            {texts.title && <span className={s.title}>{texts.title}</span>}
            {texts.usps?.map((usp) => (
              <span className={s.usp}>{usp}</span>
            ))}
          </div>
        )}
      </div>
      {cta && (
        <Button
          className={s.cta}
          prio1
          compact
          text={cta.launch?.text}
          href={cta.launch?.link}
        />
      )}
    </div>
  ) : null;
}

AppInstallBanner.propTypes = {
  appIcon: PropTypes.object,
  texts: PropTypes.object,
  cta: PropTypes.object,
  handleClickAndTracking: PropTypes.func,
  tracking: PropTypes.object,
};

export default AppInstallBanner;
