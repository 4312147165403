const warehouse = (id) => ({
  warehouse: {
    warehouseId: `MANUFACTUM_BERLIN_HAUS_HADENBERG${id}`,
    name: "Manufactum Berlin",
    url: "https://www.manufactum.de/manufactum-berlin-c199347/",
    addressLines: [
      "Hardenbergstraße 4-5",
      "10623 Berlin",
      "Telefon 030 24033844",
      "berlin@manufactum.de",
    ],
    openingTimeLabel: "opening time",
    openingTimeValue: [
      "Montag bis Freitag: 10:00 – 19:00 Uhr",
      "Samstag: 10:00 – 18:00 Uhr",
    ],
  },
  stock: 5,
  availabilityStatus: "SCARCE",
  availabilityText: "Bestand knapp",
});

export default {
  heading: "Verfügbarkeit im Warenhaus",
  introduction:
    "Hier finden Sie eine Übersicht über die Verfügbarkeit des gewünschten Artikels in unseren Warenhäusern. Wir bitten um Verständnis, dass wir die Verfügbarkeiten im Haus nicht garantieren können, da sich unsere Bestände kurzfristig ändern.",
  product: {
    image: {},
    manufacturer: "Invicta",
    displayedName: "Invicta Bräter rund",
    articleNumber: "10010",
    labelArticleNumber: "Artikelnummer",
    price: {
      amount: "109,00 €",
    },
  },
  availableText: "Verfügbar",
  scarceAvailableText: "Bestand knapp",
  notAvailableText: "momentan nicht verfügbar",
  warehouseAvailabilities: [
    warehouse(1),
    warehouse(2),
    warehouse(3),
    warehouse(4),
    warehouse(5),
    warehouse(6),
    warehouse(7),
    warehouse(8),
    warehouse(9),
    warehouse(10),
  ],
};
