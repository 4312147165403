import { v4 as uuidv4 } from "uuid";
import {
  EPOQ_CUSTOMER_COOKIE,
  EPOQ_ID_COOKIE,
  TMP_CUSTOMER_ID_ENABLED,
} from "./constants";
import { ONE_YEAR, setCookie, getCookieValue } from "./cookie";

const path = (tenant) => `https://cdn.epoq.de/flow/${tenant}.js`;
let loaded = false;
let hashIdPushed = false;
let pushedHashId = "";

// eslint-disable-next-line import/prefer-default-export
export function init(tenant, hashId) {
  const epoqPushCustomerIdOnce = () => {
    if (!hashId) return;

    const customerHasChangedItsHashId = hashId !== pushedHashId;
    if (!hashIdPushed || customerHasChangedItsHashId) {
      window.epoqPushCustomerId(hashId);
      hashIdPushed = true;
      pushedHashId = hashId;
    }
  };

  if (loaded) {
    epoqPushCustomerIdOnce();
    return;
  }
  window.loadjs(path(tenant), {
    success: () => {
      loaded = true;
      epoqPushCustomerIdOnce();
    },
  });
}

export function checkEpoqCookies() {
  const epoqCustomerId = getCookieValue(EPOQ_CUSTOMER_COOKIE);
  const epoqId = getCookieValue(EPOQ_ID_COOKIE);

  if (!epoqCustomerId) {
    let epoqIdCookieValue = null;
    if (epoqId) {
      epoqIdCookieValue = epoqId;
      setCookie(EPOQ_CUSTOMER_COOKIE, epoqIdCookieValue, ONE_YEAR);
    } else if (TMP_CUSTOMER_ID_ENABLED) {
      epoqIdCookieValue = `tmp_${uuidv4()}`;
      setCookie(EPOQ_CUSTOMER_COOKIE, epoqIdCookieValue, ONE_YEAR);
    }
  }
}
