import classnames from "classnames/bind";
import s from "./Icon.module.css";

export type IconType = {
  large?: boolean;
  small?: boolean;
  tiny?: boolean;
  alt?: string;
};

export function classNames({ large, small, tiny }: IconType) {
  const cx = classnames.bind(s);
  return cx("root", {
    large,
    medium: !(large || small || tiny),
    small,
    tiny,
  });
}
