import update from "immutability-helper";

import {
  MINI_BASKET_FRAGMENT__LOAD_MINI_BASKET,
  ADS_PAGE__LOAD_ADS,
  ADS_PAGE__SELECT_ARTICLE,
  ADS_PAGE__ADD_TO_BASKET_FAILED,
  ADS_PAGE__ADD_TO_WISHLIST_FAILED,
  ADS_PAGE__ADD_TO_BASKET_STARTED,
  ADS_PAGE__ADD_TO_WISHLIST_STARTED,
} from "../constants";

const initialState = {
  data: {
    miniBasket: {},
    articles: [
      {
        articleNumber: "88084",
        variant: "200",
        articleName: "88084 - Schär Kokosläufer Fischgrat natur-schwarz 200cm",
      },
      {
        articleNumber: "80328",
        variant: "65",
        articleName:
          "80328 - Kokosläufer Fischgrat Charlottenburger Muster 65cm",
      },
      {
        articleNumber: "80328",
        variant: "90",
        articleName:
          "80328 - Kokosläufer Fischgrat Charlottenburger Muster 90cm",
      },
      {
        articleNumber: "80328",
        variant: "120",
        articleName:
          "80328 - Kokosläufer Fischgrat Charlottenburger Muster 120cm",
      },
      {
        articleNumber: "41514",
        articleName: "41514 - Vakuumdeckel Edelstahl",
      },
      {
        articleNumber: "15001",
        articleName: "15001 - Filion Süße Mandelcreme",
      },
      {
        articleNumber: "43809",
        articleName: "43809 - Rakete",
      },
      {
        articleNumber: "62775",
        articleName: "62775 - LED-Filament-Glühlampe E27",
      },
      {
        articleNumber: "14610",
        variant: "36",
        articleName: "14610 - Hanfturnschuh flach",
      },
      {
        articleNumber: "10850",
        variant: "42",
        articleName: "10850 - Zeha Ledersportschuh Trainer",
      },
      { articleNumber: "80018", articleName: "80018 - Manufactum Stehpult" },
      {
        articleNumber: "55326",
        articleName: "55326 - Manufactum Stehpult (Freitext)",
      },
      {
        articleNumber: "45771",
        articleName: "45771 - Bücherregal komplett",
      },
      {
        articleNumber: "49331",
        articleName: "49331 - Rostradierer",
      },
      {
        articleNumber: "78794",
        articleName: "78794 - Säulenapfel Black McIntosh",
      },
      {
        articleNumber: "26174",
        articleName: "26174 - Vereinsdechantbirne (nur CH)",
      },
      {
        articleNumber: "64351",
        articleName: "64351 - Jasper Stuhl 980 (Speditionsartikel)",
      },
      {
        articleNumber: "11438",
        articleName: "11438 - Standleuchte Messing zweifach (Sperrgut)",
      },
      {
        articleNumber: "75037",
        articleName: "75037 - Besenstiel Eschenholz mit Gewinde (Sperrgut)",
      },
      {
        articleNumber: "88623",
        articleName: "88623 - Fentimans Curiosity Cola (Pfand + Grundpreis)",
      },
      {
        articleNumber: "41012",
        articleName:
          "41012 - Zubehör zu Container DS (spezielle Artikel-/Variantenauswahl)",
      },
      {
        articleNumber: "76771",
        articleName: "76771 - Brunnen Notizbuch (rabattfähig)",
      },
      {
        articleNumber: "13069",
        articleName: "13069 - Güde Filetiermesser (nicht nachbestellbar)",
      },
      {
        articleNumber: "G2107",
        articleName: "G2107 - PDF Warengutschein bunt",
      },
      {
        articleNumber: "G1107",
        articleName: "G1107 - Warengutschein Kinder",
      },
      {
        articleNumber: "G2900",
        articleName: "G2900 - Warengutschein (COM und NL)",
      },
      {
        articleNumber: "12064",
        articleName:
          "12064 - Bratwurst-Senf (nicht nachbestellbar, Stock 105, Simulate-Order Mindermenge 1 Lieferaussage)",
      },
      {
        articleNumber: "12117",
        articleName:
          "12117 - Haselnussplätzchen (Stock 104, Simulate-Order Mindermenge 2 Lieferaussagen)",
      },
      {
        articleNumber: "11104",
        articleName: "11104 - Säckli Gianduja (Stock 156, Simulate-Order ok)",
      },
      {
        articleNumber: "10917",
        articleName:
          "10917 - Französische Quittenwürfel (Simulate-Order Timeout)",
      },
      {
        articleNumber: "17408",
        articleName: "17408 - Sebo Staubsauger (EEG)",
      },
      {
        articleNumber: "44119",
        articleName: "44119 - Milantoast Kontaktgrill (EEG)",
      },
      {
        articleNumber: "80329",
        variant: "65",
        articleName: "80329-65 - Kokosteppich Fischgrat cm Artikel (DE)",
      },
      {
        articleNumber: "69672",
        variant: "S",
        articleName: "69672-S - Herren-Pullover Donegal (DE)",
      },
      {
        articleNumber: "205869",
        articleName: "205869 - Herrenstrickschal Kamelhaar (fraud article)",
      },
    ],
  },
  virtual: {
    selectedArticle: {
      articleName: "Rostradierer",
      articleNumber: { value: "49331" },
      variant: { value: "36" },
      freeInput: { value: "" },
      quantity: { value: "1" },
      voucherAmount: { value: "" },
      target: { value: "" },
      jsonInput: { value: "" },
    },
  },
};

function findName(articles, action) {
  const matchedArticles = articles.filter(
    (o) => o.articleNumber === action.articleNumber
  );
  if (matchedArticles.length === 0) {
    return "";
  }
  const variants = matchedArticles.filter(
    (o) => !action.variant || o.variant === action.variant
  );
  if (variants.length === 0) {
    return matchedArticles[0].articleName;
  }
  return variants[0].articleName;
}

function currentUrl(action) {
  const variant = action.variant ? `/${action.variant}` : "";
  return `/ads/${action.articleNumber}${variant}`;
}

const createBasketJson = (action, quantity) => {
  const requestObj = {
    articleNumber: action.articleNumber,
  };
  if (action.variant) {
    requestObj.sizeCode = action.variant;
  }
  requestObj.quantity = quantity;

  return JSON.stringify([requestObj], null, 2);
};

const ads = (state = initialState, action) => {
  switch (action.type) {
    case MINI_BASKET_FRAGMENT__LOAD_MINI_BASKET:
      return update(state, {
        miniBasket: { $set: action.payload },
      });
    case ADS_PAGE__LOAD_ADS:
    case ADS_PAGE__SELECT_ARTICLE:
      return update(state, {
        virtual: {
          selectedArticle: {
            articleNumber: { value: { $set: action.articleNumber } },
            variant: { value: { $set: action.variant } },
            articleName: { $set: findName(state.data.articles, action) },
            target: { value: { $set: currentUrl(action) } },
            jsonInput: {
              value: {
                $set: createBasketJson(
                  action,
                  state.virtual.selectedArticle.quantity.value
                ),
              },
            },
          },
        },
      });
    case ADS_PAGE__ADD_TO_BASKET_STARTED:
    case ADS_PAGE__ADD_TO_WISHLIST_STARTED:
      return update(state, {
        virtual: { globalMessage: { $set: null } },
      });
    case ADS_PAGE__ADD_TO_BASKET_FAILED:
    case ADS_PAGE__ADD_TO_WISHLIST_FAILED:
      return update(state, {
        virtual: { globalMessage: { $set: action.payload } },
      });
    default:
      return state;
  }
};

export default ads;
