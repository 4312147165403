import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames/bind";
import LoadingSpinnerAddon from "./LoadingSpinnerAddon";
import IconRight from "../Icon/IconRight";
import s from "./Button.module.css";
import s2 from "./ButtonLoading.module.css";

type Props = {
  disabled?: boolean;
  href?: string;
  large?: boolean;
  onClick?: (...args: unknown[]) => unknown;
  prio1?: boolean;
  prio2?: boolean;
  prio3?: boolean;
  prio4?: boolean;
  prio5?: boolean;
  inverted?: boolean;
  text: string;
  className?: string;
  skeleton?: boolean;
  loading?: boolean;
  spinnerPosition?: "start" | "end";
  delay?: number;
  next?: boolean;
};

function ButtonLoading({
  disabled = false,
  href = null,
  large = false,
  onClick,
  prio1 = false,
  prio2 = false,
  prio3 = false,
  prio4 = false,
  prio5 = false,
  inverted,
  text,
  className,
  skeleton,
  loading,
  spinnerPosition,
  delay,
  next,
  ...attrs
}: Props) {
  const loadingVisibleDelay = delay ?? 500;
  const animationStopTime = 300;

  const loadingRef = useRef(loading);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    loadingRef.current = loading;

    let timerShow;
    let timerHide;

    if (loadingRef.current) {
      timerShow = setTimeout(() => {
        if (loadingRef.current) {
          setAnimate(true);
          setLoadingVisible(true);
        }
      }, loadingVisibleDelay);
    } else {
      setLoadingVisible(false);
      timerHide = setTimeout(() => {
        setAnimate(false);
      }, animationStopTime);
    }
    return () => {
      clearTimeout(timerShow);
      clearTimeout(timerHide);
    };
  }, [loading]);

  const cx = classNames.bind(s);
  const rootClasses = cx("root", {
    disabled: disabled || loading || animate,
    large,
    prio1: prio1 || !(prio2 || prio3 || prio4 || prio5),
    prio2,
    prio3,
    prio4,
    prio5,
    inverted,
    skeleton,
    regular: !inverted,
  });
  const cx2 = classNames.bind(s2);
  const additionalClasses = cx2("loading", "noPadding");
  const classnames = cx(rootClasses, additionalClasses);

  const htmlHref = disabled ? null : href;

  const spinner = !skeleton && (
    <LoadingSpinnerAddon
      visible={loadingVisible}
      animate={animate}
      size={large ? "large" : "normal"}
    />
  );

  const pad = <div className={large ? s2.padLarge : s2.padNormal} />;

  const startBlock = spinnerPosition === "end" ? pad : spinner;

  let endBlock;
  if (next) {
    endBlock = (
      <span className={cx2("icon", large ? "large" : "normal")}>
        <IconRight small />
      </span>
    );
  } else {
    endBlock = spinnerPosition === "end" ? spinner : pad;
  }

  let buttoncontent = (
    <span className={s.content}>
      {startBlock}
      <span className={s.center}>{text}</span>
      {endBlock}
    </span>
  );

  buttoncontent = !skeleton ? (
    buttoncontent
  ) : (
    <span className={s.skeleton}>{buttoncontent}</span>
  );

  let htmlTag = "button";
  if (href) {
    htmlTag = disabled ? "span" : "a";
  }

  return React.createElement(
    htmlTag,
    {
      ...attrs,
      className: classnames,
      disabled: disabled || loading,
      href: htmlHref,
      onClick,
      role: href ? "button" : null,
    },
    buttoncontent
  );
}

export default ButtonLoading;
