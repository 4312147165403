// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { useFormContext } from "react-hook-form";
// mf-stelar
import FormRow from "stelar/components/FormRow";
import { Button } from "stelar/components/Button";
import { Input } from "stelar/components/Input";
// ase
import AddressFormRow from "../../../components/AddressForm/AddressFormRow";
import { buildFormRow } from "../../../util/reduxFormUtils/reduxFormRowFieldBuilder";

const EmailChangeFormContent = ({
  emailCurrent,
  formDefinition,
  submitting,
  formName,
}) => {
  const context = useFormContext();

  const layout = "standard";
  const { structure, values, submit } = formDefinition;

  const valueMap = values.reduce((map, valueObject) => {
    // eslint-disable-next-line no-param-reassign
    map[valueObject.name] = valueObject;
    return map;
  }, {});

  const submitLabel = submit && submit.label;

  return (
    <>
      <FormRow label={{ text: emailCurrent && emailCurrent.label }}>
        <Input
          data-test-ase-field-input="currentEmail"
          id="currentEmail"
          readOnly
          value={emailCurrent && emailCurrent.value}
        />
      </FormRow>

      {structure.map((element) => {
        const row = [element];

        return (
          <AddressFormRow
            key={element.name}
            row={row}
            valueMap={valueMap}
            formName={formName}
            layout={layout}
          >
            {buildFormRow({
              formName,
              row,
              valueMap,
              context,
            })}
          </AddressFormRow>
        );
      })}

      <FormRow>
        <Button
          disabled={submitting}
          text={submitLabel}
          data-test-ase-fragment-email-change-submit
        />
      </FormRow>
    </>
  );
};

export default EmailChangeFormContent;

EmailChangeFormContent.propTypes = {
  emailCurrent: PropTypes.object.isRequired,
  formDefinition: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  submitting: PropTypes.bool,
};
