/* eslint-disable camelcase */

import SMILEY_LIGHT_1 from "./img/light/smiley_light_1.svg";
import SMILEY_LIGHT_2 from "./img/light/smiley_light_2.svg";
import SMILEY_LIGHT_3 from "./img/light/smiley_light_3.svg";
import SMILEY_LIGHT_4 from "./img/light/smiley_light_4.svg";
import SMILEY_LIGHT_5 from "./img/light/smiley_light_5.svg";
import SMILEY_LIGHT_1_HOVER from "./img/light/smiley_light_1_hover.svg";
import SMILEY_LIGHT_2_HOVER from "./img/light/smiley_light_2_hover.svg";
import SMILEY_LIGHT_3_HOVER from "./img/light/smiley_light_3_hover.svg";
import SMILEY_LIGHT_4_HOVER from "./img/light/smiley_light_4_hover.svg";
import SMILEY_LIGHT_5_HOVER from "./img/light/smiley_light_5_hover.svg";

import SMILEY_BOLD_1 from "./img/bold/smiley_bold_1.svg";
import SMILEY_BOLD_2 from "./img/bold/smiley_bold_2.svg";
import SMILEY_BOLD_3 from "./img/bold/smiley_bold_3.svg";
import SMILEY_BOLD_4 from "./img/bold/smiley_bold_4.svg";
import SMILEY_BOLD_5 from "./img/bold/smiley_bold_5.svg";
import SMILEY_BOLD_1_HOVER from "./img/bold/smiley_bold_1_hover.svg";
import SMILEY_BOLD_2_HOVER from "./img/bold/smiley_bold_2_hover.svg";
import SMILEY_BOLD_3_HOVER from "./img/bold/smiley_bold_3_hover.svg";
import SMILEY_BOLD_4_HOVER from "./img/bold/smiley_bold_4_hover.svg";
import SMILEY_BOLD_5_HOVER from "./img/bold/smiley_bold_5_hover.svg";

export const SMILEY_RATING_LIGHT_ICONS = [
  SMILEY_LIGHT_1,
  SMILEY_LIGHT_2,
  SMILEY_LIGHT_3,
  SMILEY_LIGHT_4,
  SMILEY_LIGHT_5,
];

export const SMILEY_RATING_LIGHT_HOVER_ICONS = [
  SMILEY_LIGHT_1_HOVER,
  SMILEY_LIGHT_2_HOVER,
  SMILEY_LIGHT_3_HOVER,
  SMILEY_LIGHT_4_HOVER,
  SMILEY_LIGHT_5_HOVER,
];

export const SMILEY_RATING_BOLD_ICONS = [
  SMILEY_BOLD_1,
  SMILEY_BOLD_2,
  SMILEY_BOLD_3,
  SMILEY_BOLD_4,
  SMILEY_BOLD_5,
];

export const SMILEY_RATING_BOLD_HOVER_ICONS = [
  SMILEY_BOLD_1_HOVER,
  SMILEY_BOLD_2_HOVER,
  SMILEY_BOLD_3_HOVER,
  SMILEY_BOLD_4_HOVER,
  SMILEY_BOLD_5_HOVER,
];
