export default {
  page: {
    back: {
      label: "………………",
    },
    headline: "…………… ……………… ……… ………… …………………………………",
    submitButton: {
      label: "…………………………",
    },
  },
  billingAddress: {
    address: {
      addressLines: ["…………………………………………… ……", "…………… ………………", "……………………………"],
      nameLines: ["…………", "……………………… ……………"],
    },
    changeLink: {
      label: "………………",
    },
    heading: "……………………… …… …………………………………………",
    submitButton: {
      label: "………………………",
    },
  },
  clickAndCollect: {
    addresses: [],
    heading: "……………………… …… ……………………… …………………………………………………",
    submitButton: {},
  },
  standardDeliveryAddress: {
    heading: "…… ……………………… ……………………………",
    submitButton: {
      label: "………………………",
      link: null,
    },
    address: {
      heading: "…… ……………………… ……………………………",
      addressLines: [],
      chooseDeliveryAddressLink: {
        label: "………………………",
        link: null,
      },
    },
  },
  deliveryAddresses: {
    heading: "……………………… …… ……………………… ……………………………",
    confirmation: {
      description: "……………………… …… ……………………… …………………………… …………………",
      heading: "…………………………… …………………",
      cancelLabel: "…………………",
      submitLabel: "…………………",
    },
    submitButton: {
      label: "………………………",
      link: null,
    },
    storedAddresses: [],
  },
  newDeliveryAddress: {
    deliveryAddress: {
      updateFormURL: "",
      values: [],
      structure: [],
    },
    heading: "………… …………………………… …………………",
    submitButton: {},
  },
  packstation: {
    deliveryAddress: {
      updateFormURL: "",
      values: [],
      structure: [],
    },
    heading: "……………………… …… ………… ……………………………",
    submitButton: {},
  },
  selectedOption: "billingAddress",
  submitStarted: [],
};
