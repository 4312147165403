/* eslint-disable import/prefer-default-export */
import {
  API_HOMEPAGE,
  LOAD_HOMEPAGE_FAILED,
  LOAD_HOMEPAGE_STARTED,
  LOAD_HOMEPAGE_SUCCESS,
} from "./constants";

export function loadHomepage(apiClient) {
  return [
    { type: LOAD_HOMEPAGE_STARTED },
    apiClient.get(
      API_HOMEPAGE,
      {
        ok: (response) => [
          {
            type: LOAD_HOMEPAGE_SUCCESS,
            payload: response.data,
          },
        ],
        notFound: () => ({ type: LOAD_HOMEPAGE_FAILED }),
        noContent: () => ({ type: LOAD_HOMEPAGE_FAILED }),
        internalServerError: () => ({ type: LOAD_HOMEPAGE_FAILED }),
      },
      { apiBase: "/api/sell" }
    ),
  ];
}
