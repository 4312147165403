import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import HtmlContent from "stelar/components/HtmlContent";
import s from "./ElementTripleText.module.css";
import ContentSpacing from "../../ContentSpacing";

function ElementTripleText({
  firstText,
  secondText,
  thirdText,
  fullWidth,
  onLinkClick,
}) {
  if (!firstText || !secondText || !thirdText) {
    return null;
  }
  function renderText(text) {
    return (
      <div className={s.text} data-test-sell-element-triple-text-text>
        <HtmlContent
          dangerouslySetInnerHTML={{ __html: text }}
          onClick={onLinkClick}
        />
      </div>
    );
  }

  return (
    <ContentSpacing
      alignment="columns"
      flex
      fullWidth={fullWidth}
      data-test-sell-element-triple-text
    >
      {renderText(firstText)}
      {renderText(secondText)}
      {renderText(thirdText)}
    </ContentSpacing>
  );
}

ElementTripleText.propTypes = {
  firstText: PropTypes.string,
  secondText: PropTypes.string,
  thirdText: PropTypes.string,
  fullWidth: PropTypes.bool,
  onLinkClick: PropTypes.func,
};

export default ElementTripleText;
