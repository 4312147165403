import { browserHistory } from "mf-react-router"; // eslint-disable-line import/no-extraneous-dependencies
// @ts-expect-error
import favicon from "stelar/components/Favicon";
import React, { useEffect } from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Helmet from "react-helmet";

import InteractiveScrollUp from "sell/components/InteractiveScrollUp";
import AsyncSearchFormWithProductSuggests from "sell/components/AsyncSearchFormWithProductSuggests";
import ErrorMessage from "sell/components/ErrorMessage";
import Footer from "sell/components/Footer";
import AppFooter from "sell/components/Footer/AppFooter";
import Header, { HeaderBanner } from "sell/components/Header";
import Flyout from "sell/components/Navigation/Flyout";
import RenderTarget from "sell/components/RenderTarget";
import TopBanner from "sell/components/TopBanner";

import MiniAccountFragment from "ase/fragments/MiniAccountFragment/MiniAccountFragment";
import MiniBasketFragment from "ase/fragments/MiniBasketFragment/components/MiniBasketFragment";
import MiniWishListFragment from "ase/fragments/MiniWishListFragment/MiniWishListFragment";
import OffCanvasItemsFragment from "ase/fragments/OffCanvasItemsFragment/OffCanvasItemsFragment";
import CookieBannerFragment from "sell/fragments/sell-cookie-banner/CookieBannerFragment";
import OffCanvas from "sell/fragments/sell-off-canvas/OffCanvas";
import { Tenants } from "stelar/components/CountryFlag/types";
import Testability from "../../testability/component/Testability";
import track from "../../tracking";
import { createHandleClick, createHandleNavigateTo } from "../../utils/routing";
import WebBridgeConnector from "../../webbridge/component/WebBridgeConnector";
import {
  AseNewsletterSubscriptionFragment,
  SellLanguageSuggestionFragment,
} from "../registry";
import shouldSkipNormalLayout from "./shouldSkipNormalLayout";
import { getCookieValue } from "../../utils/cookie";
import DeviceIdent from "../../utils/httpContext/DeviceIdent";
import { getClientsideFetchLocals } from "../../ssrPrefetch/useFetchLocalsStore";

import s from "./NormalLayout.module.css";

// TODO fix when sell package is ts
type Props = {
  apiClient?: object;
  bannerId?: string;
  children?: React.ReactNode;
  location?: { pathname: string; query: string };
  countrySelectionOpen?: boolean;
  error?: boolean;
  eventTracking?: object;
  footer?: {
    scrollUp?: string;
    dhlGoGreen?: {
      label?: string;
      dhlGoGreenEntry?: {
        image?: {
          src?: string;
          alt?: string;
        };
        link?: string;
      };
    };
  };
  header?: {
    appInstallBanner?: {
      appIcon?: {
        src?: string;
      };
      texts?: {
        title?: string;
        usps?: string[];
      };
      cta?: {
        install?: {
          text?: string;
          link?: string;
        };
        launch?: {
          text?: string;
          link?: string;
        };
      };
    };
    banner?: {
      id?: string;
      type?: string;
      elementType?: string;
      label?: string;
      link?: string;
      tracking?: unknown;
    };
    country?: {
      label?: string;
      iconName?: Tenants;
      selection: {
        heading: "Choisir une langue";
        options: [
          {
            label: "Français";
            link: "https://fr-be-test-shop.mfdp.io";
          },
          {
            label: "Néerlandais";
            link: "https://nl-be-test-shop.mfdp.io";
          },
        ];
      };
    };
    links?: Array<{ name?: string; link?: string }>;
    service?: {
      label?: string;
      phone?: string;
      link?: string;
    };
    searchbox?: {
      placeholder?: string;
      link?: string;
      buttonText?: string;
      shouldSuggestProducts?: boolean;
      wordsSectionHeading?: string;
      productsSectionHeading?: string;
    };
    navigation?: {
      items?: Array<{ id?: string; name?: string; link?: string }>;
      offCanvasTitle?: string;
      menuIconHref?: string;
      flyoutClose?: string;
    };
  };
  miniAccount?: object;
  miniAccountActions?: {
    refresh(): void;
  };
  miniWishlist?: object;
  miniWishlistActions?: {
    refresh(): void;
  };
  miniBasket?: object;
  miniBasketActions?: {
    refresh(): void;
  };
  cookieBanner?: object;
  cookieBannerActions?: object;
  offCanvas?: object;
  offCanvasActions?: {
    onOpenOffCanvas(): void;
  };
  navigationActions?: object;
  navigationState?: object;
  noRouting?: boolean;
  onCloseCountrySelection?: (...args: unknown[]) => unknown;
  onToggleCountrySelection?: (...args: unknown[]) => unknown;
  overlay?: object;
  overlayActions?: object;
  routes?: unknown[];
  searchActions?: object;
  searchState?: object;
  loadBanner?: (...args: unknown[]) => unknown;
};

function NormalLayout(props: Props) {
  const {
    children,
    apiClient,
    location,
    countrySelectionOpen,
    error,
    footer,
    header = {},
    miniAccount,
    miniAccountActions,
    miniWishlist,
    miniWishlistActions,
    miniBasket,
    miniBasketActions,
    cookieBanner,
    cookieBannerActions,
    offCanvas,
    offCanvasActions,
    navigationActions,
    navigationState,
    noRouting,
    onCloseCountrySelection,
    onToggleCountrySelection,
    routes,
    searchActions,
    searchState,
    eventTracking,
    loadBanner,
  } = props;

  const isReducedLayoutRoute = shouldSkipNormalLayout(
    location.pathname,
    location.query
  );

  const [countryToggleHeaderBanner, setCountryToggleHeaderBanner] =
    React.useState(false);

  const featureCookieSet = getCookieValue("mf-feature") !== null;

  useEffect(() => {
    if (isReducedLayoutRoute) {
      return;
    }
    const miniBasketRefresh = () => miniBasketActions.refresh();
    const miniWishlistRefresh = () => miniWishlistActions.refresh();
    const miniAccountRefresh = () => miniAccountActions.refresh();

    window.addEventListener("ase:basket:changed", miniBasketRefresh);
    window.addEventListener("ase:wishlist:changed", miniWishlistRefresh);
    window.addEventListener("ase:login:changed", miniAccountRefresh);

    const onVisibilityChange = () => {
      const { params, path } = getClientsideFetchLocals();
      if (document.visibilityState === "visible") {
        loadBanner(params, path);
      }
    };
    window.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
      window.removeEventListener("ase:basket:changed", miniBasketRefresh);
      window.removeEventListener("ase:wishlist:changed", miniWishlistRefresh);
      window.removeEventListener("ase:login:changed", miniAccountRefresh);
      window.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, []);

  const handleClick = createHandleClick(routes);
  const handleNavigateTo = createHandleNavigateTo(routes);
  function onNavigate(path, hasChildren) {
    if (noRouting) {
      if (!hasChildren) {
        window.location = path;
      }
    } else {
      browserHistory.push(path);
    }
    return null;
  }

  function handleClickAndTracking(e, trackingData = {}) {
    handleClick(e);
    // ToDo: we have to change tracking structure | SELL-4957
    if (Array.isArray(trackingData)) {
      trackingData.forEach((data) => {
        track(data);
      });
    } else {
      track(trackingData);
    }
  }

  function onClickLogo(e) {
    if (e.metaKey || e.ctrlKey) {
      return null;
    }
    if (!noRouting) {
      e.preventDefault();
      browserHistory.push("/");
    }
    return null;
  }

  function submitSearch(value) {
    const encodedQuery = encodeURIComponent(value.replace(/\/|\\/g, ""));
    const path = `/suche/?q=${encodedQuery}`;
    browserHistory.push(path);
  }

  function handleSearchSubmit(e) {
    if (!noRouting) {
      e.preventDefault();
      const searchInput = e.target.q;
      const { value } = searchInput;
      if (value.length > 0) {
        searchInput.blur();
        submitSearch(value);
      }
    }
  }

  const flyoutNav = header?.navigation && (
    /* TODO fix when sell package is ts */
    /* @ts-ignore */
    <Flyout
      structure={header.navigation.items || []}
      closeLabel={header.navigation.flyoutClose || ""}
      {...navigationActions}
      {...navigationState}
      onNavigate={onNavigate}
    />
  );

  const searchBox = header?.searchbox && (
    <AsyncSearchFormWithProductSuggests
      {...header.searchbox}
      {...searchState}
      {...searchActions}
      linkUpdate={[]}
      eventTracking={eventTracking}
      onSubmit={handleSearchSubmit}
      onLinkClick={handleClick}
      onNavigateTo={handleNavigateTo}
      onClickSuggestion={submitSearch}
    />
  );

  const main = <main className={s.main}>{children}</main>;

  const content = !isReducedLayoutRoute && (
    <div className={s.root}>
      <RenderTarget web>
        <Header
          banner={
            <HeaderBanner
              country={header?.country}
              countryToggle={countryToggleHeaderBanner}
              setCountryToggle={setCountryToggleHeaderBanner}
              links={header?.links}
              service={header?.service}
              handleClick={handleClick}
            />
          }
          appInstallBanner={header?.appInstallBanner}
          topBanner={header?.banner}
          searchBox={searchBox}
          myAccount={
            <MiniAccountFragment {...miniAccount} onClick={handleClick} />
          }
          miniNotepad={
            <MiniWishListFragment {...miniWishlist} onClick={handleClick} />
          }
          miniCart={
            /* TODO fix when sell package is ts */
            /* @ts-ignore */
            <MiniBasketFragment
              {...miniBasket}
              {...miniBasketActions}
              onClick={handleClick}
            />
          }
          navigation={flyoutNav}
          offCanvasTitle={header?.navigation?.offCanvasTitle || ""}
          menuIconHref={header?.navigation?.menuIconHref || ""}
          {...navigationState}
          {...searchState}
          {...searchActions}
          onClickLogo={onClickLogo}
          handleClickAndTracking={handleClickAndTracking}
          openOffCanvas={offCanvasActions.onOpenOffCanvas}
        />
      </RenderTarget>
      {header?.banner ? (
        <RenderTarget app>
          <TopBanner
            {...header?.banner}
            handleClickAndTracking={handleClickAndTracking}
          />
        </RenderTarget>
      ) : null}
      {error ? (
        <div className={s.error}>
          <ErrorMessage />
        </div>
      ) : null}
      {main}
      {footer && (
        <>
          <RenderTarget app>
            <AppFooter {...footer} onLinkClick={handleClick} />
          </RenderTarget>
          <RenderTarget web>
            {/* TODO fix when sell package is ts */}
            {/* @ts-ignore */}
            <Footer
              {...footer}
              newsletter={<AseNewsletterSubscriptionFragment lazy />}
              countrySelectionOpen={countrySelectionOpen}
              onToggleCountrySelection={onToggleCountrySelection}
              onCloseCountrySelection={onCloseCountrySelection}
              onLinkClick={handleClick}
            />
          </RenderTarget>
        </>
      )}
    </div>
  );

  const reducedContent = isReducedLayoutRoute && (
    <div className={s.root}>
      {main}
      {footer && (
        <RenderTarget app>
          <AppFooter {...footer} onLinkClick={handleClick} />
        </RenderTarget>
      )}
    </div>
  );

  return (
    <>
      <Helmet meta={favicon.meta} link={favicon.link} />
      <RenderTarget web>
        <OffCanvas
          offCanvas={offCanvas}
          offCanvasActions={offCanvasActions}
          header={header}
          aseOffCanvasItems={
            <OffCanvasItemsFragment
              miniAccount={miniAccount}
              miniWishlist={miniWishlist}
              miniBasket={miniBasket}
            />
          }
          navigationState={navigationState}
          navigationActions={navigationActions}
        />
      </RenderTarget>
      {isReducedLayoutRoute ? reducedContent : content}
      <CookieBannerFragment
        cookieBannerActions={cookieBannerActions}
        {...cookieBanner}
      />
      {featureCookieSet && (
        <RenderTarget web>
          {/* TODO fix when sell package is ts */}
          {/* @ts-ignore */}
          <SellLanguageSuggestionFragment lazy />
        </RenderTarget>
      )}
      <Testability />
      <DeviceIdent />
      <RenderTarget app>
        <WebBridgeConnector apiClient={apiClient} />
      </RenderTarget>
      {footer && footer.scrollUp ? (
        <InteractiveScrollUp text={footer.scrollUp} />
      ) : null}
    </>
  );
}

export default NormalLayout;
