import loadable from "@loadable/component";
import { connect } from "react-redux";
import { provideHooks } from "redial";

import Loading from "shared/components/Loading";

import frontendUrls from "../utils/AseFrontendUrls";
import { loadNewsletterPage } from "./actions";

const redial = {
  fetch: ({ apiClient, dispatch, query }) =>
    dispatch(loadNewsletterPage(apiClient, query)),
};

const mapStateToProps = (state) => ({
  contentRows: state.newsletterPage.contentRows,
  attentionMessage: state.newsletterPage.attentionMessage,
  headline: state.newsletterPage.headline,
  page: state.newsletterPage.page,
  successMessage: state.newsletterPage.successMessage,
});

const LoadableNewsletterPage = loadable(
  () =>
    import(
      /* webpackChunkName: "newsletter-page" */ "./components/NewsletterPage"
    ),
  {
    fallback: Loading,
  }
);

export default {
  component: provideHooks(redial)(
    connect(mapStateToProps)(LoadableNewsletterPage)
  ),
  path: frontendUrls.NEWSLETTER_SUBSCRIPTION,
};
