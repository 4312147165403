/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconFaq(props: IconType) {
  const { alt } = props;
  return (
    <svg
      className={classNames(props)}
      viewBox="-0.5 -0.5 28.3 28.3"
      aria-label={alt}
    >
      {alt ? <title>{alt}</title> : null}
      <path d="M13.89 27.77a13.89 13.89 0 1 1 13.89-13.88 13.9 13.9 0 0 1-13.89 13.88zm0-26.51a12.63 12.63 0 1 0 12.63 12.63A12.65 12.65 0 0 0 13.89 1.26z" />
      <path d="M12.65 15.57a3.74 3.74 0 0 1 .24-1.35 4.87 4.87 0 0 1 .57-1.07 8.05 8.05 0 0 1 .73-.89q.39-.41.73-.83a4.49 4.49 0 0 0 .57-.89 2.47 2.47 0 0 0 .22-1.08 2.22 2.22 0 0 0-.11-.57 1.45 1.45 0 0 0-.3-.56 1.73 1.73 0 0 0-.54-.44 1.71 1.71 0 0 0-.77-.16 2 2 0 0 0-1.2.36 3.46 3.46 0 0 0-.9 1.19l-1.25-.59A3.91 3.91 0 0 1 12 7.03a3.43 3.43 0 0 1 1.95-.57 3.8 3.8 0 0 1 1.23.2 3.13 3.13 0 0 1 1.06.6 3 3 0 0 1 .73 1 3 3 0 0 1 .27 1.32 3 3 0 0 1-.23 1.21 5.09 5.09 0 0 1-.6 1 11.46 11.46 0 0 1-.77.92q-.41.44-.77.88a4.65 4.65 0 0 0-.6.93 2.36 2.36 0 0 0-.23 1v1.17h-1.39z" />
      <circle cx="13.42" cy="20.2" r="1.12" />
    </svg>
  );
}

export default IconFaq;
