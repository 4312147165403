import update from "immutability-helper";
import {
  LOAD_HOMEPAGE_FAILED,
  LOAD_HOMEPAGE_STARTED,
  LOAD_HOMEPAGE_SUCCESS,
} from "./constants";

const initialState = {
  loading: false,
};

export default function homepage(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    case LOAD_HOMEPAGE_STARTED:
      return update(state, {
        loading: { $set: true },
      });
    case LOAD_HOMEPAGE_SUCCESS:
      return update(state, {
        $merge: payload,
        loading: { $set: false },
      });
    case LOAD_HOMEPAGE_FAILED: {
      return update(state, {
        $set: payload,
        loading: { $set: false },
      });
    }
    default:
      return state;
  }
}
