import AppRoot from "stelar/components/AppRoot";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import LoadingBar from "react-redux-loading-bar";

import useIsClient from "../../utils/useIsClient";
import GlobalModal from "../GlobalModal/GlobalModal";

import loadingBarStyles from "./LoadingBar.module.css";

type Props = {
  children?: React.ReactNode;
};

function Root({ ...props }: Props) {
  const { children } = props;
  const isClient = useIsClient();

  return (
    <>
      {isClient && <LoadingBar className={loadingBarStyles.root} />}
      <AppRoot>
        {children}
        <GlobalModal />
      </AppRoot>
    </>
  );
}

export default Root;
