// vendor
import _flatten from "lodash.flatten";
import _flatMap from "lodash.flatmap";
import { useEffect, useRef, useMemo } from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { useForm } from "react-hook-form";
// ase
import { resolveInputType } from "../inputTypes";
import { extractFormErrors, scrollToFirstError } from "./scrollToFirstError";
import { filterEmptyOrNeedlessFormValues } from "./filterEmptyOrNeedlessFormValues";

const PLEASE_SELECT_PLACEHOLDER_VALUE = "0";

const getFields = (structure) => {
  const fieldSet = _flatten(structure);
  let fields;
  if (fieldSet.length > 0 && fieldSet[0].fieldRows) {
    fields = _flatten(_flatMap(fieldSet, (fieldRows) => fieldRows.fieldRows));
  } else {
    fields = fieldSet;
  }
  return fields;
};

const getDefaultValuesByStructure = (structure, passedValues) => {
  const values = [];
  getFields(structure).forEach((field) => {
    const inputType = resolveInputType(field.type);
    if (inputType) {
      if (inputType.select) {
        if (field.options.length > 1) {
          values[field.name] = PLEASE_SELECT_PLACEHOLDER_VALUE;
        } else if (field.options.length === 1) {
          values[field.name] = field.options[0].value;
        }
      } else if (inputType.bool) {
        values[field.name] = passedValues[field.name] || false;
      } else if (inputType.radio) {
        values[field.name] = field.defaultValue.value;
      } else if (
        passedValues[field.name] === undefined ||
        passedValues[field.name] === null
      ) {
        values[field.name] = "";
      }
    }
  });
  return values;
};

export const initialValuesByFormDefinition = (formDefinition) => {
  const { values, structure } = formDefinition;

  const initialInputValues = Object.values(values || {}).reduce(
    (result, { name, value }) => {
      if (value !== "") {
        // eslint-disable-next-line no-param-reassign
        result[name] = value;
      }
      return result;
    },
    {}
  );

  const initialSelectAndEmptyValues = getDefaultValuesByStructure(
    structure,
    initialInputValues
  );

  return { ...initialSelectAndEmptyValues, ...initialInputValues };
};

export const fieldsToInitializeOnStructureUpdate = (formDefinition, fields) => {
  const defaultValues = getDefaultValuesByStructure(
    formDefinition.structure,
    fields
  );
  return Object.keys(defaultValues).reduce((result, key) => {
    if (
      fields[key] === undefined ||
      fields[key]?.length === 0 ||
      fields.federalState?.length === 0
    ) {
      result.push({
        name: key,
        value: defaultValues[key],
      });
    }
    return result;
  }, []);
};

export const buildBackendGeneratedForm = ({
  formDefinition,
  onSubmit,
  additionalFieldNames,
  cachedValues,
  otrToken,
  scrollToError = true,
}) => {
  if (!formDefinition || !onSubmit) {
    throw new Error("Invalid parameters for generating the form!");
  }
  const isMounted = useRef(false);

  const defaultValues = useMemo(() => {
    const values = initialValuesByFormDefinition(formDefinition);
    return cachedValues && Object.keys(cachedValues)?.length > 0
      ? { ...values, ...cachedValues }
      : values;
  }, [formDefinition, cachedValues]);

  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
    getValues,
    setValue,
    reset,
  } = useForm({
    mode: "onTouched",
    defaultValues,
  });

  useEffect(() => {
    if (isMounted.current) {
      reset(defaultValues);
      if (scrollToError) {
        scrollToFirstError(extractFormErrors(formDefinition, errors));
      }
    }
  }, [formDefinition.values]);

  useEffect(() => {
    if (isMounted.current) {
      fieldsToInitializeOnStructureUpdate(formDefinition, getValues()).forEach(
        (field) => setValue(field.name, field.value)
      );
    }
  }, [formDefinition.structure]);

  useEffect(() => {
    isMounted.current = true;
  });

  const onHandleSubmit = (data) => {
    onSubmit({
      ...filterEmptyOrNeedlessFormValues({
        values: data,
        structureFields: getFields(formDefinition.structure),
        additionalFieldNames,
      }),
      ...(otrToken && { otrToken }),
    });
  };

  const onErrors = (data) => {
    if (scrollToError) {
      scrollToFirstError(Object.keys(data));
    }
  };

  const formProviderValue = {
    handleSubmit,
    control,
    errors,
    getValues,
    dirtyFields,
  };

  const onSubmitHandler = handleSubmit(onHandleSubmit, onErrors);

  return { formProviderValue, onSubmitHandler, control, getValues };
};
