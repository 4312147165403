import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import ScrollTopButton from "stelar/components/ScrollTopButton";
import { scrollDocument } from "shared/utils/scrollTo";
import propTypes from "./propTypes";
import s from "./InteractiveScrollUp.module.css";

const cx = classnames.bind(s);

function InteractiveScrollUp({ text }) {
  const [show, setShow] = React.useState(false);
  // eslint-disable-next-line no-use-before-define
  const boundHandleScroll = () => handleScroll();
  let lastPosition = null;
  let timeout = null;

  React.useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    attachEvents();

    return () => {
      clearTimeout(timeout);
      // eslint-disable-next-line no-use-before-define
      detatchEvents();
    };
  }, []);

  function attachEvents() {
    if (typeof window === "object") {
      window.addEventListener("scroll", boundHandleScroll);
    }
  }

  function detatchEvents() {
    if (typeof window === "object") {
      window.removeEventListener("scroll", boundHandleScroll);
    }
  }

  function hideAfterDelay() {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setShow(false);
    }, 3500);
  }

  function handleScroll() {
    const oldShow = show;
    const topLimit = window.innerHeight;
    const currentPosition = window.scrollY || window.pageYOffset;
    const scrollDiff = lastPosition - currentPosition;
    const scrollUp = scrollDiff > 0;
    const thresholdPassed = Math.abs(scrollDiff) > 5;
    const currentShow = currentPosition > topLimit && scrollUp;
    if (oldShow !== currentShow && thresholdPassed) {
      setShow(currentShow);
    }
    if (currentShow) {
      hideAfterDelay();
    }
    lastPosition = currentPosition;
  }

  const scrollToTop = () => {
    setShow(false);
    detatchEvents();
    scrollDocument(0, 750, () => {
      attachEvents();
    });
  };

  return (
    <div className={cx("root", { show, hide: !show })}>
      <ScrollTopButton text={text} onClick={scrollToTop} />
    </div>
  );
}

InteractiveScrollUp.propTypes = propTypes;

export default InteractiveScrollUp;
