import React from "react";
import classNames from "classnames/bind";

import s from "./LoadingSpinnerAddon.module.css";

const cx = classNames.bind(s);

type Props = {
  size?: "normal" | "large";
  visible?: boolean;
  animate?: boolean;
};

function LoadingSpinnerAddon({ size, visible, animate }: Props) {
  const contentSize = size || "normal";
  return (
    <div className={cx("container", contentSize)}>
      <svg
        className={cx("throbber", { visible, animate })}
        viewBox="25 25 50 50"
      >
        <circle
          className={cx("throbber--circle", { visible, animate })}
          cx="50"
          cy="50"
          fill="none"
          r="20"
          strokeMiterlimit="10"
          strokeWidth="3"
        />
      </svg>
    </div>
  );
}

export default LoadingSpinnerAddon;
