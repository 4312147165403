/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconDown(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 15 15" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path d="M14.7,3.7c-0.3-0.3-0.7-0.3-1,0L7.5,9.9L1.2,3.7c-0.3-0.3-0.7-0.3-1,0C0,4,0,4.4,0.3,4.7L7,11.3c0.1,0.1,0.3,0.2,0.5,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0,0.3-0.1,0.5-0.2l6.7-6.6C15,4.4,15,4,14.7,3.7z" />
    </svg>
  );
}

export default IconDown;
