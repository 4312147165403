import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import s from "./Cookie.module.css";

export default function Cookie() {
  return (
    <svg
      className={s.root}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24"
    >
      <symbol id="bg_4">
        <path fill="#fff" d="M.1-23.9h23.8V-.1H.1z" />
        <path className="st1" d="M23.8-.2v-23.6H.2V-.2h23.6m.2.2H0v-24h24V0z" />
        <path
          className="st1"
          d="M21.795-2.205v-19.6H2.205v19.6h19.59m.2.2H2.006v-20h19.99v20z"
          opacity="0.4"
        />
        <defs>
          <path id="SVGID_1_" d="M0-24h24V0H0z" />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlinkHref="#SVGID_1_" overflow="visible" />
        </clipPath>
        <g opacity="0.4" clipPath="url(#SVGID_2_)">
          <path className="st4" d="M0-12h24M12-24V0" />
        </g>
      </symbol>
      <path fill="none" d="M0 0h24v24H0z" id="Ebene_2" />
      <g id="test6">
        <circle cx="19.5" cy="11.5" r="0.5" />
        <circle cx="10.5" cy="11.5" r="0.5" />
        <circle cx="14.5" cy="16.5" r="0.5" />
        <circle cx="10.5" cy="17.5" r="0.5" />
        <circle cx="18.5" cy="6.5" r="0.5" />
        <circle cx="19.25" cy="4.75" r="0.75" />
        <circle cx="6.25" cy="9.75" r="0.75" />
        <circle cx="8.25" cy="11.75" r="0.75" />
        <circle cx="13.25" cy="17.75" r="0.75" />
        <path d="M9.5 7C8.673 7 8 7.673 8 8.5S8.673 10 9.5 10 11 9.327 11 8.5 10.327 7 9.5 7zm0 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1z" />
        <circle cx="18.5" cy="8.5" r="0.75" />
        <circle cx="20.25" cy="6.5" r="0.75" />
        <path d="M19.791 14.099c-.006.002-.599.154-1.038.154-1.103 0-2-.898-2-2 0-.287.258-1.045.441-1.386.094-.16.107-.451.008-.609-.188-.299-.525-.272-.702-.258-.827 0-1.5-.673-1.5-1.5 0-.852.59-1.583 1.12-1.706a.5.5 0 0 0 .1-.938c-.006-.003-.72-.365-.72-.856 0-.254.073-.567.164-.698a.498.498 0 0 0-.218-.745c-1.196-.502-2.48-.566-3.466-.566-4.962 0-9 4.037-9 9s4.038 9 9 9c3.298 0 6.334-1.767 7.734-4.503.339-.662.667-1.713.68-1.757a.5.5 0 1 0-.604-.633zm-.968 1.934c-1.23 2.405-3.916 3.958-6.843 3.958-4.41 0-8-3.589-8-8s3.59-8 8-8c1.093 0 1.907.096 2.612.317A2.8 2.8 0 0 0 14.5 5c0 .53.271.943.565 1.236A2.9 2.9 0 0 0 14 8.5c0 1.23.893 2.255 2.064 2.462-.162.425-.311.94-.311 1.291 0 1.789 1.584 3.184 3.413 2.975a7.96 7.96 0 0 1-.343.805z" />
      </g>
    </svg>
  );
}

Cookie.propTypes = {};
