import track from "shared/tracking";
import { setPageTitle } from "shared/webbridge/actions";
import backendUrl from "../utils/AseBackendUrls";
import {
  WISHLIST_PAGE__RENDER_WISHLIST_PAGE,
  WISHLIST_PAGE__SHOW_MESSAGE,
  WISHLIST_PAGE__TOGGLE_AGE_RELATED_ADVICE,
} from "../constants";
import {
  triggerBasketChangeEvent,
  triggerWishlistChangeEvent,
} from "../utils/BrowserEvents";

export function loadWishListPage(apiClient) {
  function ok({ body }) {
    return [
      { type: WISHLIST_PAGE__RENDER_WISHLIST_PAGE, payload: body },
      setPageTitle(body?.page?.title),
    ];
  }
  return apiClient.get(backendUrl.WISHLIST, { ok });
}

export function removeFromWishList({ apiClient, removeHref }) {
  return apiClient.delete(removeHref, {
    noContent: () => [
      loadWishListPage(apiClient),
      triggerWishlistChangeEvent(),
    ],
  });
}

function showMessage(message) {
  return {
    type: WISHLIST_PAGE__SHOW_MESSAGE,
    message,
  };
}

export function addToBasket({ apiClient, href }) {
  return apiClient.post(
    href,
    {},
    {
      ok: (content) => {
        track(content?.body?.trackingInfo);
        triggerBasketChangeEvent();
        triggerWishlistChangeEvent();

        return apiClient.get(backendUrl.WISHLIST, {
          ok: (result) => {
            track(result?.body?.page?.trackingInfo);
            return [
              {
                type: WISHLIST_PAGE__RENDER_WISHLIST_PAGE,
                payload: result.body,
              },
              showMessage(content.body.globalMessage || content.body),
            ];
          },
        });
      },
    }
  );
}

export function toggleAgeRelatedAdvice(key) {
  return {
    type: WISHLIST_PAGE__TOGGLE_AGE_RELATED_ADVICE,
    key,
  };
}
