// vendor
import { connect } from "react-redux";
import prefetch from "shared/ssrPrefetch/prefetch";
import { getClientsideFetchLocals } from "shared/ssrPrefetch/useFetchLocalsStore";
// ase
import { updateFormStructure } from "../../util/reduxFormUtils/updateFormStructure";
import { CATALOG_ORDER_FRAGMENT__UPDATE_FORM } from "../../pages/constants";
// component
import CatalogOrderFragment from "./components/CatalogOrderFragment";
import {
  cacheFormValues,
  confirmAddressAlternative,
  editAddressAlternative,
  loadCatalogOrderFragmentData,
  submitCatalogOrder,
} from "./actions";

const redial = {
  fetch: ({ dispatch, apiClient, props: { catalogId } }) =>
    dispatch(loadCatalogOrderFragmentData(apiClient, catalogId)),
};

const mapDispatchToProps = (dispatch) => {
  const { apiClient } = getClientsideFetchLocals();
  return {
    confirmAddressAlternative: (args) =>
      dispatch(confirmAddressAlternative(args, apiClient)),
    editAddressAlternative: (args) =>
      dispatch(editAddressAlternative(args, apiClient)),
    cacheFormValues: (args) => dispatch(cacheFormValues(args)),
    submitCatalogOrder: (args) => dispatch(submitCatalogOrder(args, apiClient)),
    updateForm:
      ({ addressStatePath, formName }) =>
      ({ updateFormURL, formValues, newValues }) => {
        dispatch(
          updateFormStructure(
            {
              addressStatePath,
              formName,
              formValues,
              newValues,
              type: CATALOG_ORDER_FRAGMENT__UPDATE_FORM,
              updateFormURL,
            },
            apiClient
          )
        );
      },
  };
};

const mapStateToProps = (state) => state.catalogOrderFragment;

export { attrs, stateKey } from "./components/CatalogOrderFragment";

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(prefetch(CatalogOrderFragment, redial));
