import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import s from "./ContentSpacing.module.css";

const cx = classnames.bind(s);

function ContentSpacing({
  children,
  alignment = "text",
  fullWidth,
  flex,
  gutters,
  className,
  ...attrs
}) {
  return (
    <div
      className={cx({
        [alignment]: true,
        indented: !fullWidth,
        fullWidth,
        gutters,
        flex,
        [className]: true,
      })}
      {...attrs}
    >
      {children}
    </div>
  );
}

ContentSpacing.propTypes = {
  children: PropTypes.node,
  alignment: PropTypes.oneOf([
    "text",
    "columns",
    "narrowcolumns",
    "imagetext",
    "none",
  ]),
  fullWidth: PropTypes.bool,
  flex: PropTypes.bool,
  gutters: PropTypes.bool,
  className: PropTypes.string,
};

export default ContentSpacing;
