import React from "react";
import HeaderIcon from "../HeaderIcon";

const icon = (
  <svg viewBox="0 0 36 36">
    <g>
      <path d="M18 29.2c5.6 0 11-2.7 10.2-11.7-.3-3.3-2.1-6.8-2.4-7.4-.8.3-1.8.5-2.8.7-.3-3.3-1.6-5.7-5-5.7-3.3 0-4.6 2.4-5 5.7-1-.1-1.9-.3-2.7-.6-.5.8-1.9 4.2-2.4 7.4C6.6 26 12.4 29.2 18 29.2zm0-22.9c2.6 0 3.6 2 3.9 4.7-1.2.2-2.5.3-3.9.3-1.3 0-2.7-.1-3.9-.3.3-2.7 1.4-4.7 3.9-4.7zM9 18.1c.4-2.8 1.7-5.7 2.1-6.4.6.1 1.2.3 1.8.4v.7c0 .7 0 1.4.1 2.1h1.2c-.1-.7-.2-1.4-.2-2.2v-.4c1.2.2 2.6.3 4 .3s2.7-.1 3.9-.3v.4c0 .8 0 1.5-.1 2.2H23c.1-.7.1-1.4.1-2.1v-.7c.7-.2 1.3-.3 1.9-.5.3.5 1.8 3.5 2.1 6.5.4 3.9-.7 6.4-2.5 7.9-.6-.9-3.4-1.6-6.6-1.6-3.1 0-5.8.6-6.5 1.5-1.9-1.5-3.1-4.1-2.5-7.8z" />
    </g>
  </svg>
);

type Props = {
  active?: boolean;
  text?: string;
  link?: string;
};

function HeaderBasket(props: Props) {
  return (
    <HeaderIcon
      {...props}
      icon={icon}
      strongText
      testSelector="data-test-stelar-header-basket"
    />
  );
}

export default HeaderBasket;
