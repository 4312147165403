import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { triggerLocationChange } from "shared/utils/customEvents";
import Compact from "../../components/Navigation/Compact";
import { scrollInOffCanvas } from "./helper";

function OffCanvasNavigation({
  header,
  offCanvasActions,
  isLanguageSelectionOpen,
  aseOffCanvasItems,
  navigationState,
  navigationActions,
}) {
  function onNavigate(path, hasChildren) {
    if (path) {
      if (!hasChildren) {
        offCanvasActions.onCloseOffCanvas();
        triggerLocationChange(path, true);
      } else {
        triggerLocationChange(path, false);
      }
    }
  }
  scrollInOffCanvas(navigationState.offCanvasPath);
  return (
    header && (
      <Compact
        structure={header.navigation.items || header.navigation || []}
        onNavigate={onNavigate}
        country={header?.country}
        countryToggle={isLanguageSelectionOpen}
        aseOffCanvasItems={aseOffCanvasItems}
        {...navigationState}
        {...navigationActions}
      />
    )
  );
}

OffCanvasNavigation.propTypes = {
  header: PropTypes.object,
  offCanvasActions: PropTypes.object,
  isLanguageSelectionOpen: PropTypes.bool,
  aseOffCanvasItems: PropTypes.object,
  navigationState: PropTypes.object,
  navigationActions: PropTypes.object,
};

export default OffCanvasNavigation;
