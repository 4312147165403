import React from "react";

import s from "./HtmlContent.module.css";

type Props = {
  children?: React.ReactNode;
} & React.ComponentPropsWithoutRef<"div">;

/**
 * HtmlContent can be used if text needs to be displayed which also contains html elements such as `h2, h3, h4, p, strong, b, a, ul>li, ol>li`.
 * It should be noted that the container itself has no spacing and only the elements itself have a styleguide-compliant `margin` (usually `margin-bottom`).
 * Pure text would therefore have no spacing.
 */
function HtmlContent({ children, ...attrs }: Props) {
  return (
    <div {...attrs} className={s.root}>
      {children}
    </div>
  );
}

export default HtmlContent;
