import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import ReactDOM from "react-dom"; // eslint-disable-line import/no-extraneous-dependencies
import { trigger } from "redial";
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Router,
  applyRouterMiddleware,
  browserHistory,
  match,
} from "mf-react-router";
import { loadableReady } from "@loadable/component";
import { Provider } from "react-redux";
import { useScroll } from "react-router-scroll"; // eslint-disable-line import/no-extraneous-dependencies
import { client as clientSideApiClient } from "shared/apiClient/clientSide";
import clientErrorLogger from "shared/logger/clientErrorLogger";
import setupWebVitals from "shared/webvitals";

import { ready as uiStateReady } from "sell/utils/uiState/actions";

import testability from "shared/testability/client";
import debugPanel from "shared/utils/debugPanel";
import { HttpContextProviders } from "shared/utils/httpContext";
import { getCookieString } from "shared/utils/httpContext/CookieProvider";
import { mark } from "shared/utils/performance";
import shouldScroll from "shared/utils/shouldScroll";
import { clearStatus } from "shared/utils/status/actions";

import { API_BASE } from "shared/apiClient/constants";
import { setClientsideFetchLocals } from "shared/ssrPrefetch/useFetchLocalsStore";
import { registerRouteChangeEventListener } from "./routes";
import { configureStore } from "./store";

window.MF_CORE_ENV_TEAM = "SELL";
window.MF_CORE_ENV_BUILD_NUMBER = process.env.BUILD_NUMBER;

clientErrorLogger();
setupWebVitals();

window.addEventListener("load", () => {
  const stateElement = document.getElementById("SELL_INITIAL_STATE");
  const initialState =
    stateElement && JSON ? JSON.parse(stateElement.innerHTML) : {};

  // Set up Redux (note: this API requires redux@>=3.1.0):
  const store = configureStore(initialState);
  const { dispatch, getState, subscribe } = store;

  const renderTarget = getState()?.httpContext?.renderTarget;
  const deviceOs = getState()?.httpContext?.deviceOs;

  const container = document.getElementById("sell-root");

  const { pathname, search, hash } = window.location;
  let location = `${pathname}${search}${hash}`;

  // the not found page gets delivered all kind of urls
  // faking the initial location to not confuse react-router
  if (store.getState().notFound) {
    location = "/404";
    browserHistory.getCurrentLocation = () => ({ pathname: location });
  }

  // watch store status.code for error logging
  subscribe(() => {
    const { status } = getState();
    if (!status || !status.code) {
      return;
    }
    dispatch(clearStatus());
    const { code, location } = status; // eslint-disable-line no-shadow
    if (location?.length > 0 && /500.*.html$/.test(location)) {
      window.location.href = "/500.html";
    }
    if (code === 404) {
      window.location.reload();
    }
    if (location) {
      if (code === 401) {
        browserHistory.replace(location);
      } else {
        browserHistory.push(location);
      }
    } else {
      console.error(message); // eslint-disable-line
    }
  });

  function createFetchLocals(renderProps) {
    return {
      path: renderProps.location.pathname,
      query: renderProps.location.query,
      queryParams: renderProps.location.query,
      locationState: renderProps.location.state,
      params: renderProps.params,
      apiClient: clientSideApiClient.pages({
        apiBase: API_BASE.ASE,
      }),
      cookies: getCookieString(),
      // Allow lifecycle hooks to dispatch Redux actions:
      dispatch,
      getState,
      isClient: true,
      renderTarget,
    };
  }

  const render = () => {
    // We need to have a root route for HMR to work.
    // eslint-disable-next-line global-require
    const createRoutes = require("./routes").default;
    const routes = createRoutes();

    match({ routes, location }, (error, redirectLocation, renderProps) => {
      if (error) {
        // eslint-disable-next-line no-console
        return console.error(error);
      }
      if (!renderProps) {
        // eslint-disable-next-line no-console
        return console.warn("no renderProps, unable to render app");
      }

      const fetchLocals = createFetchLocals(renderProps);
      setClientsideFetchLocals({ fetchLocals });

      const app = (
        <HttpContextProviders renderTarget={renderTarget} deviceOs={deviceOs}>
          <Provider store={store}>
            <Router
              createElement={(Component, props) => (
                <Component apiClient={fetchLocals.apiClient} {...props} />
              )}
              routes={routes}
              history={browserHistory}
              render={applyRouterMiddleware(useScroll(shouldScroll))}
            />
          </Provider>
        </HttpContextProviders>
      );

      loadableReady(() => {
        // eslint-disable-next-line react/no-deprecated
        ReactDOM.hydrate(app, container);
      });

      // redial: load additional data
      const { components } = renderProps;

      trigger("defer", components, fetchLocals);
      trigger("done", components, fetchLocals);
      return null;
    });

    dispatch(uiStateReady());

    registerRouteChangeEventListener(routes);

    // redial: load data on route change
    return browserHistory.listen((loc) => {
      match(
        { routes, location: loc },
        (error, redirectLocation, renderProps) => {
          // No matching route found
          if (!renderProps) {
            console.error("unable to match a route", loc); // eslint-disable-line no-console
            return;
          }
          // Don't fetch on changing URL fragment part.
          if (loc.hash?.length > 0) {
            return;
          }

          const { components } = renderProps;
          const fetchLocals = createFetchLocals(renderProps);
          setClientsideFetchLocals({ fetchLocals });

          // Don't fetch data for initial route, server has already done the work:
          trigger("fetch", components, fetchLocals)
            .then(() => trigger("done", components, fetchLocals))
            .catch((e) => console.error(e)); // eslint-disable-line no-console
          // Fetch deferred, client-only data dependencies:
          trigger("defer", components, fetchLocals);
        }
      );
    });
  };

  const unsubscribeHistory = render();

  if (module.hot) {
    module.hot.accept("./routes", () => {
      unsubscribeHistory();
      setTimeout(render);
    });
  }

  const docClassList = document.documentElement.classList;
  if (docClassList) {
    docClassList.remove("mf-noscript");
    docClassList.add("mf-script");
  }

  mark("sell_page_interactive");

  debugPanel();
  testability();
});
