import queryString from "query-string";
import track from "shared/tracking";
import { statusRedirect, statusNotFound } from "shared/utils/status/actions";
import { createActionKey } from "shared/utils/actionKey";
import {
  triggerAseBasketUpdatedEvent,
  triggerAseNotepadUpdatedEvent,
} from "shared/utils/customEvents";
import { scrollDocument } from "shared/utils/scrollTo";
import { setPageTitle, setSharingInformation } from "shared/webbridge/actions";
import { EXCLUDE_OUTFIT } from "./constants";
import {
  LOAD_DETAIL_STARTED,
  LOAD_DETAIL_SUCCESS,
  ADD_TO_BASKET_STARTED,
  ADD_TO_BASKET_SUCCESS,
  ADD_TO_BASKET_BAD_REQUEST,
  ADD_TO_NOTEPAD_STARTED,
  ADD_TO_NOTEPAD_SUCCESS,
  ADD_TO_NOTEPAD_BAD_REQUEST,
  CHANGE_DETAIL_SLIDE,
  CHANGE_ORDER_FORM_VOUCHER_AMOUNT,
  CHANGE_ORDER_FORM_FREE_INPUT,
  LOAD_RECOMMENDATIONS_DETAILPAGE_SUCCESS,
  LOAD_RECOMMENDATIONS_DETAILPAGE_FAILED,
  LOAD_RECOMMENDATIONS_DETAILPAGE_STARTED,
  LOAD_OUTFIT_INFO_SUCCESS,
  LOAD_OUTFIT_INFO_FAILED,
  LOAD_OUTFIT_INFO_STARTED,
  IS_CLIENT_SIDE,
  RESET_RECOMMENDATIONS_DETAILPAGE,
  ADD_TO_NOTEPAD_CONFLICT,
  ADD_TO_BASKET_CLOSE_MODAL,
} from "../constants";

/* eslint-disable camelcase */
export function loadDetail(
  apiClient,
  productId,
  urlName,
  articleId,
  categoryId,
  searchHits,
  searchString,
  variantName,
  voucherAmount,
  status,
  sizeId,
  adword,
  gclid,
  newsletter,
  utm_source,
  utm_medium,
  utm_campaign,
  ecmUid,
  bmrecipientid,
  trackingListType,
  globalError
) {
  const params = {
    a: articleId,
    c: categoryId,
    h: searchHits,
    s: searchString,
    v: variantName,
    voucher_amount: voucherAmount,
    status,
    sizeId,
    adword,
    gclid,
    newsletter,
    utm_source,
    utm_medium,
    utm_campaign,
    ecmUid,
    bmrecipientid,
    tlt: trackingListType,
    globalError,
  };

  const key = createActionKey(productId || urlName, params, true);
  const handler = {
    ok: (response) => {
      const {
        redirectUrl,
        redirectStatus,
        detailUrlName,
        productId: redirectProductID,
        articleNumber: redirectArticleNumber,
      } = response.data;
      if (redirectUrl) {
        return statusRedirect(redirectUrl, redirectStatus);
      }

      if (detailUrlName && detailUrlName !== urlName) {
        const location = redirectProductID
          ? `/${detailUrlName}-p${redirectProductID}/`
          : `/${detailUrlName}-a${redirectArticleNumber}/`;
        return statusRedirect(location, 301);
      }

      return [
        {
          type: LOAD_DETAIL_SUCCESS,
          payload: { jsonData: { ...response.data }, key },
        },
        setPageTitle(response.data?.head?.title),
        setSharingInformation({
          title: response.data?.name,
          link: response.data?.absoluteUrl,
        }),
      ];
    },
    notFound: () =>
      statusNotFound(
        productId
          ? `product ${productId} does not exist`
          : `article ${articleId} does not exist`
      ),
  };

  const apiCall = productId
    ? apiClient.get(`/detail/${encodeURIComponent(productId)}`, handler, {
        apiBase: "/api/sell",
        httpClientOptions: {
          params,
        },
      })
    : apiClient.get(
        `/detail/article/${encodeURIComponent(articleId)}`,
        handler,
        {
          apiBase: "/api/sell",
          httpClientOptions: { params: { ...params, a: undefined } },
        }
      );

  return [{ type: LOAD_DETAIL_STARTED, payload: { key } }, apiCall];
}

export function loadOutfitInfo(apiClient, articleId) {
  if (EXCLUDE_OUTFIT.includes(articleId)) {
    return null;
  }
  return [
    { type: LOAD_OUTFIT_INFO_STARTED },
    apiClient.get(
      `/detail/article/isoutfit/${encodeURIComponent(articleId)}`,
      {
        ok: (response) => ({
          type: LOAD_OUTFIT_INFO_SUCCESS,
          payload: response.data,
        }),
        notFound: (response) => ({
          type: LOAD_OUTFIT_INFO_FAILED,
          payload: response.data,
        }),
        noContent: (response) => ({
          type: LOAD_OUTFIT_INFO_FAILED,
          payload: response.data,
        }),
        internalServerError: (response) => ({
          type: LOAD_OUTFIT_INFO_FAILED,
          payload: response.data,
        }),
      },
      { apiBase: "/api/sell" }
    ),
  ];
}

export function afterChangeCallback(event) {
  return { type: CHANGE_DETAIL_SLIDE, payload: { event } };
}

export function addToBasket(
  apiClient,
  url,
  params,
  quantity,
  overlay,
  clickedElement,
  googleTrackingData
) {
  const addQuantityToTrackingData = (googleData) => {
    const modifiedData = { ...googleData };
    if (
      modifiedData.payload &&
      modifiedData.payload.ecommerce &&
      modifiedData.payload.ecommerce.add &&
      modifiedData.payload.ecommerce.add.products &&
      modifiedData.payload.ecommerce.add.products[0]
    ) {
      modifiedData.payload.ecommerce.add.products[0].quantity = quantity;
    }
    return modifiedData;
  };

  return [
    { type: ADD_TO_BASKET_STARTED },
    apiClient.post(
      url,
      queryString.stringify(params, { sort: false }),
      {
        created: (response) => {
          triggerAseBasketUpdatedEvent();
          if (googleTrackingData) {
            track({
              googleEnhancedEcommerce:
                addQuantityToTrackingData(googleTrackingData),
            });
            track({
              googleTagManager: {
                containerId: window.gtmId,
                payload: { event: "pageview" },
              },
            });
          }
          return {
            type: ADD_TO_BASKET_SUCCESS,
            payload: response.data,
          };
        },
        notFound: (response) => ({
          type: ADD_TO_BASKET_BAD_REQUEST,
          payload: response.data,
        }),
        badRequest: (response) => ({
          type: ADD_TO_BASKET_BAD_REQUEST,
          payload: response.data,
        }),
        noContent: (response) => {
          if (response.headers.location) {
            window.location.href = response.headers.location;
          } else {
            window.location.reload();
          }
        },
      },
      {
        apiBase: "",
        httpClientOptions: {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        },
      }
    ),
  ];
}

export function closeAddToCartModal() {
  return { type: ADD_TO_BASKET_CLOSE_MODAL };
}

export function addToNotepad(apiClient, url, params) {
  return [
    { type: ADD_TO_NOTEPAD_STARTED },
    apiClient.post(
      url,
      queryString.stringify(params, { sort: false }),
      {
        created: (response) => {
          triggerAseNotepadUpdatedEvent();
          scrollDocument();
          return {
            type: ADD_TO_NOTEPAD_SUCCESS,
            payload: response.data,
          };
        },
        conflict: (response) => {
          triggerAseNotepadUpdatedEvent();
          scrollDocument();
          return {
            type: ADD_TO_NOTEPAD_CONFLICT,
            payload: response.data,
          };
        },
        badRequest: (response) => {
          scrollDocument();
          return {
            type: ADD_TO_NOTEPAD_BAD_REQUEST,
            payload: response.data,
          };
        },
        notFound: (response) => {
          scrollDocument();
          return {
            type: ADD_TO_NOTEPAD_BAD_REQUEST,
            payload: response.data,
          };
        },
      },
      {
        apiBase: "",
        httpClientOptions: {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        },
      }
    ),
  ];
}

export function changeVoucherAmount(voucherAmount) {
  return {
    type: CHANGE_ORDER_FORM_VOUCHER_AMOUNT,
    payload: voucherAmount,
  };
}

export function changeFreeInput(freeInput) {
  return {
    type: CHANGE_ORDER_FORM_FREE_INPUT,
    payload: freeInput,
  };
}

export function loadRecommendations(apiClient, params) {
  return [
    { type: LOAD_RECOMMENDATIONS_DETAILPAGE_STARTED },
    apiClient.get(
      `/recommendations/pds2`,
      {
        ok: (response) => ({
          type: LOAD_RECOMMENDATIONS_DETAILPAGE_SUCCESS,
          payload: response.data,
        }),
        notFound: () => ({ type: LOAD_RECOMMENDATIONS_DETAILPAGE_FAILED }),
        noContent: () => ({ type: LOAD_RECOMMENDATIONS_DETAILPAGE_FAILED }),
        internalServerError: () => ({
          type: LOAD_RECOMMENDATIONS_DETAILPAGE_FAILED,
        }),
      },
      {
        apiBase: "/api/sell",
        httpClientOptions: {
          params,
        },
      }
    ),
  ];
}

export function resetRecoDetailpageState() {
  return { type: RESET_RECOMMENDATIONS_DETAILPAGE };
}

export function resetIsClientSideLoaded(value) {
  return { type: IS_CLIENT_SIDE, payload: value };
}

export function isClientSideLoaded() {
  return { type: IS_CLIENT_SIDE, payload: true };
}
