import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import mq from "stelar/components/media";
import Arrow from "./Arrow";

// eslint-disable-next-line import/prefer-default-export
export function createSlickSettings({ lazy, interval = 3000 }) {
  return {
    arrows: false,
    accessibility: true,
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: interval,
    pauseOnHover: true,
    dots: true,
    draggable: false,
    infinite: true,
    lazyLoad: lazy ? true : "progressive",
    initialSlide: 0,
    slidesToScroll: 1,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: mq.breakpoint.l.max + 1,
        settings: {
          arrows: false,
          draggable: false,
          fade: false,
          speed: 200,
          swipe: true,
          touchThreshold: 150,
        },
      },
      {
        breakpoint: 10000,
        settings: {
          arrows: true,
          draggable: false,
          fade: true,
          nextArrow: <Arrow next />,
          prevArrow: <Arrow prev />,
          swipe: false,
        },
      },
    ],
  };
}

export function fadeAnimationHandler(props, state) {
  const transitionTime = `${props.transitionTime}ms`;
  const transitionTimingFunction = "ease-in-out";

  let slideStyle = {
    position: "absolute",
    display: "block",
    zIndex: 0,
    minHeight: "100%",
    opacity: 0,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    transitionTimingFunction,
    msTransitionTimingFunction: transitionTimingFunction,
    MozTransitionTimingFunction: transitionTimingFunction,
    WebkitTransitionTimingFunction: transitionTimingFunction,
    OTransitionTimingFunction: transitionTimingFunction,
  };

  if (!state.swiping) {
    slideStyle = {
      ...slideStyle,
      WebkitTransitionDuration: transitionTime,
      MozTransitionDuration: transitionTime,
      OTransitionDuration: transitionTime,
      transitionDuration: transitionTime,
      msTransitionDuration: transitionTime,
    };
  }

  return {
    slideStyle,
    selectedStyle: {
      ...slideStyle,
      opacity: 1,
      zIndex: 1,
      position: "relative",
    },
    prevStyle: { ...slideStyle },
  };
}
