import track from "shared/tracking";
import loadable from "@loadable/component";
import { connect } from "react-redux";
import { provideHooks } from "redial";
import { categoryIdsInArray } from "shared/utils/helper";
import { initScrollPositionInFacetFilter } from "shared/utils/scrollTo";
import Loading from "shared/components/Loading/Loading";
import { increaseStickyBannerShownCount } from "../../utils/bannerHelper";
import {
  closeOrOpenContentAccordionItem,
  closeOrOpenFacetFilter,
  imageLoaded,
  resetFacetFilter,
  resetFacetFilterDisplay,
  setTrackingListType,
  toggleFacetFilterDisplay,
} from "../../utils/uiState/actions";
import { isQueryAttributesEmpty } from "../../utils/uiState/facetFilter/helper";
import navigationActions from "../../utils/uiState/navigation/actions";
import searchActions from "../../utils/uiState/search/actions";
import { loadCategory } from "./actions";
import {
  containsFacets,
  handleCommercialTracking,
  isCategoryLoaded,
} from "./helper";
import { getSliderActions } from "../../utils/uiState/sliders/actions";

const { updateCurrent, compactTogglePath } = navigationActions();
const { clearSearchString } = searchActions();

function trackContent({
  kuratorContentsTop,
  kuratorContentsBottom,
  noCommercialTracking,
}) {
  const trackDeeper = (contentData) => {
    contentData.forEach((elem) => {
      if (!elem) {
        return;
      }
      if (elem.productsTracking) {
        track(elem.productsTracking, {
          noCommercialTracking,
        });
      }
      if (elem.elements) {
        elem.elements.forEach((elemElem) => {
          if (elemElem.productsTracking) {
            track(elemElem.productsTracking, {
              noCommercialTracking,
            });
          }
        });
      }
    });
  };
  if (kuratorContentsTop) {
    trackDeeper(kuratorContentsTop);
  }
  if (kuratorContentsBottom) {
    trackDeeper(kuratorContentsBottom);
  }
}

const redial = {
  fetch: ({
    dispatch,
    params: { categoryId, categoryUrlName },
    apiClient,
    getState,
    query,
  }) => {
    const { keys, data } = getState().category;
    if (isQueryAttributesEmpty(query)) {
      dispatch(resetFacetFilterDisplay()); // reset ui checkboxes for facet filters
      dispatch(resetFacetFilter()); // reset ui accordion for clicked filters
    }
    if (
      isCategoryLoaded(keys, categoryId) &&
      containsFacets(data, categoryId)
    ) {
      return null;
    }
    return dispatch(
      loadCategory(apiClient, categoryId, categoryUrlName, query)
    );
  },
  done: ({ dispatch, apiClient, query, getState }) => {
    const { keys, data } = getState().category;
    const category = data[keys[0]];
    const categoryIds = categoryIdsInArray(category?.breadcrumb?.crumbs);
    categoryIds.push(category?.categoryId);

    if (category?.banner) {
      increaseStickyBannerShownCount();
    }
    if (isQueryAttributesEmpty(query)) {
      initScrollPositionInFacetFilter(category);
    }
    handleCommercialTracking(
      category.noCommercialTracking,
      apiClient.getCookies()
    );
    track(category.tracking, {
      noCommercialTracking: category.noCommercialTracking,
    });
    track(category.productsTracking, {
      noCommercialTracking: category.noCommercialTracking,
    });
    trackContent(category);
    dispatch(updateCurrent(category.navigationPath));
    dispatch(clearSearchString());
    dispatch(compactTogglePath(categoryIds));
  },
};

function mapDispatchToProps(dispatch) {
  return {
    onImageLoad: (src) => dispatch(imageLoaded(src)),
    closeOrOpenContentAccordionItem: (contentAccordionItem) =>
      dispatch(closeOrOpenContentAccordionItem(contentAccordionItem)),
    toggleListDisplay: (currentState) =>
      dispatch(toggleFacetFilterDisplay(currentState)),
    closeOrOpenFacetFilter: (facetName) =>
      dispatch(closeOrOpenFacetFilter(facetName)),
    setTrackingListType: (trackingData) =>
      dispatch(setTrackingListType(trackingData)),
    sliderActions: getSliderActions(dispatch),
  };
}

function mapStateToProps(state) {
  const { data, currentLocation } = state.category;
  const {
    loadedImages,
    openedContentAccordionItems,
    initialFacetFilterDisplay,
    closedFacetFilters,
  } = state.uiState;
  const { loadedSliders, sliderPositions } = state.uiState.sliders;

  const sliderState = {
    loadedSliders,
    sliderPositions,
  };

  const currentKey = state.category.keys[0];
  const prevKey = state.category.keys[1];

  // use current data if exists
  if (data[currentKey]) {
    return {
      ...data[currentKey],
      currentLocation,
      loadedImages,
      openedContentAccordionItems,
      initialFacetFilterDisplay,
      closedFacetFilters,
      sliderState,
    };
  }

  // use breadcrumb & localnav from previouse page -> partial skeleton
  if (prevKey && data[prevKey]) {
    const { localNavis, breadcrumb, facets, sortOrder, pager } = data[prevKey];
    return {
      localNavis,
      breadcrumb,
      facets,
      initialFacetFilterDisplay,
      closedFacetFilters,
      sliderState,
      sortOrder,
      pager,
      loading: true,
    };
  }

  // no data available -> full skeleton
  return { loading: true };
}

const LoadableCategory = loadable(
  () => import(/* webpackChunkName: "category-page" */ "./components/Category"),
  {
    fallback: Loading,
  }
);

export default provideHooks(redial)(
  connect(mapStateToProps, mapDispatchToProps)(LoadableCategory)
);
