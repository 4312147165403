const initialState = {
  data: {
    title: "······················",
    heading: "······················",
    number: {
      value: "",
      label: "······",
      required: true,
      error: "",
      errorEmpty: "····· ····· ··· ···· ······ ·················· ···.",
      errorInvalid: "····· ······ ··· ··· ··········· ··················.",
      validation: "^\\d{16}(?:\\d{4})?$",
    },
    pin: {
      value: "",
      label: "···",
      required: true,
      error: "",
      errorEmpty: "····· ····· ··· ···· ······ ··· ···.",
      errorInvalid: "····· ······ ··· ··· ··········· ···.",
      validation: "^\\d+$",
    },
    submitButton: {
      label: "·········",
      link: "/api/ase/checkout/public/gutschein/wertabfrage",
    },
  },
  uiState: { renderAsSkeleton: true },
  submitStarted: false,
};

export default initialState;
