// vendor
import merge from "lodash.merge";
import track from "shared/tracking";
import loadable from "@loadable/component";
import { connect } from "react-redux";
import { provideHooks } from "redial";
import Loading from "shared/components/Loading";
// ase
import {
  redeemVoucher as _redeemVoucher,
  loadGutscheinFormData,
} from "../../components/GutscheinForm/actions";
import frontendUrls from "../utils/AseFrontendUrls";
// component
import {
  addCatalogToBasket,
  deleteGutscheinItem,
  deleteLineItem,
  loadBasketPage,
  moveToWishList,
  toggleAgeRelatedAdvice,
  navigateWhenUpdated,
  updateEegReturn,
  updateLineItem,
  updateQuantityOfLineItem,
} from "./actions";

const redial = {
  fetch: ({ apiClient, dispatch, query }) =>
    Promise.all([
      dispatch(loadBasketPage(apiClient, query)),
      dispatch(loadGutscheinFormData(apiClient)),
    ]),
  defer: ({ getState }) => {
    track(getState()?.basketPage?.data?.page?.trackingInfo);
  },
};

const mapDispatchToProps = (dispatch, { apiClient, routes }) => {
  const deleteGutscheinItemProp = (gutscheinItemId) => {
    dispatch(deleteGutscheinItem(apiClient, gutscheinItemId));
  };
  return {
    loadBasketPage: () => dispatch(loadBasketPage(apiClient)),
    shopCatalogSection: {
      changeCatalogSelection: (values, submitLink) => {
        dispatch(addCatalogToBasket({ apiClient, values, submitLink }));
      },
    },
    basket: {
      lineItems: {
        deleteLineItem: (lineItemId) => {
          dispatch(deleteLineItem(apiClient, lineItemId));
        },
        updateLineItem: (lineItemId, quantity, isBlur) => {
          dispatch(
            updateLineItem(apiClient, lineItemId, quantity, routes, isBlur)
          );
        },
        updateQuantityOfLineItem: (newQuantity, type, lineItemId) =>
          dispatch(updateQuantityOfLineItem(newQuantity, type, lineItemId)),
        updateEegReturn: (changeUrl) =>
          dispatch(updateEegReturn(apiClient, changeUrl)),
        toggleAgeRelatedAdvice: (lineItemId) =>
          dispatch(toggleAgeRelatedAdvice(lineItemId)),
        moveToWishList: (link) => dispatch(moveToWishList(apiClient, link)),
      },
      einloeseItems: {
        deleteGutscheinItem: deleteGutscheinItemProp,
      },
      gutscheinItems: {
        deleteGutscheinItem: deleteGutscheinItemProp,
      },
    },
    redeemVoucher: ({ link, context, values }) =>
      dispatch(_redeemVoucher({ link, values, context, apiClient })),
    navigateWhenUpdated: () => dispatch(navigateWhenUpdated()),
  };
};

function mapStateToProps(state) {
  return {
    ...state.basketPage.data,
    hasInvalidQuantities: state.basketPage.data?.basket?.lineItems?.some(
      (lineItem) => lineItem.quantity?.hasChanged || !lineItem.quantity?.valid
    ),
    isLoading: state.basketPage.data.page.title === "",
  };
}

const LoadableBasketPage = loadable(
  () => import(/* webpackChunkName: "basket-page" */ "./components/BasketPage"),
  {
    fallback: Loading,
  }
);

export default {
  path: frontendUrls.BASKET,
  component: provideHooks(redial)(
    connect(mapStateToProps, mapDispatchToProps, merge)(LoadableBasketPage)
  ),
};
