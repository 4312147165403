import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import s from "./SkeletonContentKurator.module.css";

function SkeletonContentKurator() {
  return (
    <div className={s.root}>
      <div className={s.element} />
      <div className={s.element} />
      <div className={s.element} />
    </div>
  );
}

export default SkeletonContentKurator;
