import update from "immutability-helper";
import { parseActionKey } from "shared/utils/actionKey";
import { scrollDocument } from "shared/utils/scrollTo";
import * as TYPES from "../constants";
import { initialState } from "./constants";

const detail = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.LOAD_DETAIL_STARTED: {
      const { key } = action.payload;
      const $splice = [];

      let tempCurrentSliderPosition = state.currentSliderPosition;
      let tempQuantity = state.orderFormInput.quantity;
      let tempFreeInput = state.orderFormInput.freeInput;
      if (key && state.keys[0]) {
        const newParsedKey = parseActionKey(key);
        const currentParsedKey = parseActionKey(state.keys[0]);
        if (newParsedKey.slug !== currentParsedKey.slug) {
          tempCurrentSliderPosition = 0;
          tempQuantity = "1";
          tempFreeInput = "";
        }
      }
      const tempLoadedSliderImages = [
        tempCurrentSliderPosition,
        tempCurrentSliderPosition + 1,
      ];

      // remove key if it exists
      if (state.keys.includes(key)) {
        $splice.push([state.keys.indexOf(key), 1]);
      }

      // add new key to the front
      $splice.push([0, 0, key]);

      // evtl. remove over limit key
      $splice.push([state.maxKeys, 1]);

      return update(state, {
        keys: { $splice },
        loading: { $set: true },
        loadedSliderImages: { $set: tempLoadedSliderImages },
        currentSliderPosition: { $set: tempCurrentSliderPosition },
        orderFormInput: {
          quantity: { $set: tempQuantity },
          freeInput: { $set: tempFreeInput },
          addToBasketDisabled: { $set: false },
        },
        openedAddToCartModal: { $set: false },
      });
    }
    case TYPES.LOAD_DETAIL_SUCCESS: {
      const data = {};

      // write data if key is present
      const { key, jsonData } = action.payload;
      if (state.keys.includes(key)) {
        data[key] = { $set: jsonData };
      }

      // Workaround for voucher error message | SELL-4984
      if (state.data[key]?.message) {
        data[key] = { message: { $set: state.data[key].message } };
      }

      // cleanup: remove data from keys that don't exist any more
      Object.keys(state.data).forEach((k) => {
        if (!state.keys.includes(k)) {
          data[k] = { $set: null };
        }
      });

      return update(state, { data, loading: { $set: false } });
    }

    case TYPES.IS_CLIENT_SIDE:
      return update(state, {
        isClientSideLoaded: { $set: action.payload },
      });
    case TYPES.CHANGE_DETAIL_SLIDE: {
      const { loadedSliderImages } = state;
      // add current image key
      if (!state.loadedSliderImages.includes(action.payload.event)) {
        loadedSliderImages.push(action.payload.event);
      }
      // add next image key for preloading
      const nextImageKey = action.payload.event + 1;
      if (!state.loadedSliderImages.includes(nextImageKey)) {
        loadedSliderImages.push(nextImageKey);
      }
      return update(state, {
        loadedSliderImages: { $set: loadedSliderImages },
        currentSliderPosition: { $set: action.payload.event },
      });
    }

    case TYPES.CHANGE_ORDER_FORM_VOUCHER_AMOUNT:
      return update(state, {
        data: {
          [state.keys[0]]: {
            orderForm: {
              voucherAmount: {
                $set: action.payload,
              },
            },
          },
        },
      });

    case TYPES.CHANGE_ORDER_FORM_FREE_INPUT:
      return update(state, {
        orderFormInput: {
          freeInput: {
            $set: action.payload,
          },
        },
      });

    case TYPES.ADD_TO_NOTEPAD_STARTED:
      return update(state, {
        orderFormInput: {
          addToNotepadDisabled: {
            $set: true,
          },
        },
      });

    case TYPES.ADD_TO_NOTEPAD_SUCCESS: {
      const key = state.keys[0];
      const message = action.payload?.globalMessage || null;

      return update(state, {
        orderFormInput: {
          addToNotepadDisabled: { $set: false },
        },
        data: {
          [key]: {
            message: {
              $set: message,
            },
          },
        },
      });
    }

    case TYPES.ADD_TO_NOTEPAD_CONFLICT: {
      const key = state.keys[0];
      const message = action.payload?.globalMessage || null;

      return update(state, {
        orderFormInput: {
          addToNotepadDisabled: { $set: false },
        },
        data: {
          [key]: {
            message: {
              $set: message,
            },
          },
        },
      });
    }

    case TYPES.ADD_TO_NOTEPAD_BAD_REQUEST: {
      const key = state.keys[0];
      const message = action.payload?.globalMessage || null;

      return update(state, {
        orderFormInput: {
          addToBasketDisabled: { $set: false },
        },
        data: {
          [key]: {
            message: {
              $set: message,
            },
          },
        },
      });
    }

    case TYPES.ADD_TO_BASKET_STARTED: {
      const key = state.keys[0];

      return update(state, {
        orderFormInput: {
          addToBasketDisabled: {
            $set: true,
          },
        },
        data: { [key]: { errors: { $set: null } } },
        openedAddToCartModal: { $set: false },
      });
    }

    case TYPES.ADD_TO_BASKET_SUCCESS: {
      const key = state.keys[0];
      const message = action.payload?.globalMessage || null;

      return update(state, {
        openedAddToCartModal: { $set: true },
        orderFormInput: { addToBasketDisabled: { $set: false } },
        data: { [key]: { message: { $set: message } } },
      });
    }

    case TYPES.ADD_TO_BASKET_BAD_REQUEST: {
      const key = state.keys[0];

      let message = null;
      // don't show globalmessage, if quantity field has specific error message
      if (!action.payload?.errors?.quantity?.message) {
        message = action.payload?.globalMessage;
      }
      const errors = action.payload?.errors || null;

      if (message) {
        scrollDocument();
      }

      return update(state, {
        orderFormInput: {
          addToBasketDisabled: { $set: false },
        },
        data: {
          [key]: {
            message: { $set: message },
            errors: { $set: errors },
          },
        },
      });
    }

    case TYPES.ADD_TO_BASKET_CLOSE_MODAL: {
      return update(state, {
        openedAddToCartModal: { $set: false },
      });
    }

    case TYPES.LOAD_RECOMMENDATIONS_DETAILPAGE_STARTED:
    case TYPES.LOAD_RECOMMENDATIONS_DETAILPAGE_FAILED:
    case TYPES.RESET_RECOMMENDATIONS_DETAILPAGE:
      return update(state, {
        isRecoLoaded: { $set: false },
      });

    case TYPES.LOAD_RECOMMENDATIONS_DETAILPAGE_SUCCESS:
      return update(state, {
        isRecoLoaded: { $set: true },
      });

    case TYPES.LOAD_OUTFIT_INFO_FAILED:
    case TYPES.LOAD_OUTFIT_INFO_SUCCESS:
      return update(state, {
        isOutfitInfoLoaded: { $set: action.payload },
      });

    case TYPES.LOAD_OUTFIT_INFO_STARTED:
      return update(state, {
        isOutfitInfoLoaded: { $set: false },
      });

    default:
      return state;
  }
};

export default detail;
