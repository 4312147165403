// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import ContentHeading from "stelar/components/ContentHeading";
import Paragraph from "stelar/components/Paragraph";
// component
import s from "./PrepaymentInfo.module.css";

const PrepaymentInfo = ({ correspondentBank, heading, text, text2 }) => {
  // TODO: prepare data in model/api
  function textToHTML(transtext) {
    const regex = /(\n)/g;
    return (
      transtext
        .split(regex)
        // eslint-disable-next-line react/no-array-index-key
        .map((line, i) => (line.match(regex) ? <br key={i} /> : line))
    );
  }

  const bankInfo = correspondentBank ? (
    <Paragraph className={s.correspondentBank}>
      {textToHTML(correspondentBank)}
    </Paragraph>
  ) : null;

  return (
    <section className={s.root}>
      {heading && <ContentHeading prio2>{heading}</ContentHeading>}
      <Paragraph className={s.text}>{textToHTML(text)}</Paragraph>
      {bankInfo}
      <Paragraph className={s.text2}>{textToHTML(text2)}</Paragraph>
    </section>
  );
};

PrepaymentInfo.propTypes = {
  correspondentBank: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
  text2: PropTypes.string,
};

export default PrepaymentInfo;
