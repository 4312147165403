import update from "immutability-helper";
import {
  KEYBOARD_ARROW_UP,
  KEYBOARD_ARROW_DOWN,
} from "shared/utils/keyboardNavigation";
import * as types from "../../../pages/constants";
import getFocus from "./focusNavigation";

const initialState = {
  queries: [],
  data: {
    wordsData: {},
    productsData: {},
  },
  productSkeleton: false,
  maxQueries: 10,
  searchString: "",
  typedSearchString: "",
  focusedSuggestion: null,
  focusedProduct: null,
};

function getFocusedWord(state, keyboardDirection) {
  const currentSuggestion = state.focusedSuggestion;
  const { wordsData } = state.data;

  const { typedSearchString } = state;
  const listLength = wordsData[typedSearchString]
    ? wordsData[typedSearchString].length
    : 0;

  return getFocus(keyboardDirection, listLength, currentSuggestion);
}

function getFocusedProduct(state, keyboardDirection) {
  const currentProduct = state.focusedProduct;
  const { productsData } = state.data;

  const { focusedProduct, typedSearchString, searchString } = state;
  const isProductFocused = focusedProduct !== null;
  const query = isProductFocused ? searchString : typedSearchString;

  const listLength = productsData[query]
    ? productsData[query].productSuggests.length
    : 0;

  return getFocus(keyboardDirection, listLength, currentProduct);
}

function getCurrentQuery(index, { data, typedSearchString }) {
  const words = data.wordsData[typedSearchString];
  return words && words[index] && words[index].word;
}

export default function searchReducer(state = initialState, action) {
  function isSkeleton(currentQuery) {
    return !state.data.productsData[currentQuery];
  }

  function getQuerySplice(query) {
    const $splice = [];

    // remove query if it exists
    if (state.queries.includes(query)) {
      $splice.push([state.queries.indexOf(query), 1]);
    }

    // add new query to the front
    $splice.push([0, 0, query]);

    // evtl. remove over limit query
    $splice.push([state.maxQueries, 1]);
    return $splice;
  }

  if (action.type === types.SUGGEST_ARROW_DOWN) {
    const focusedWord = getFocusedWord(state, KEYBOARD_ARROW_DOWN);
    const currentQuery = getCurrentQuery(focusedWord, state);
    return update(
      state,
      state.focusedProduct !== null
        ? {
            focusedProduct: {
              $set: getFocusedProduct(state, KEYBOARD_ARROW_DOWN),
            },
          }
        : {
            focusedSuggestion: { $set: focusedWord },
            queries: {
              $splice: getQuerySplice(currentQuery),
            },
            searchString: { $set: currentQuery },
            productSkeleton: { $set: isSkeleton(currentQuery) },
          }
    );
  }

  if (action.type === types.SUGGEST_ARROW_UP) {
    const focusedWord = getFocusedWord(state, KEYBOARD_ARROW_UP);
    const currentQuery =
      focusedWord === null
        ? state.typedSearchString
        : getCurrentQuery(focusedWord, state);
    return update(
      state,
      state.focusedProduct !== null
        ? {
            focusedProduct: {
              $set: getFocusedProduct(state, KEYBOARD_ARROW_UP),
            },
          }
        : {
            focusedSuggestion: { $set: focusedWord },
            queries: {
              $splice: getQuerySplice(currentQuery),
            },
            searchString: { $set: currentQuery },
            productSkeleton: { $set: isSkeleton(currentQuery) },
          }
    );
  }

  if (action.type === types.SUGGEST_ARROW_RIGHT) {
    return update(state, {
      focusedProduct: {
        $set: state.data?.productsData?.[state.searchString]?.productSuggests
          ?.length
          ? 0
          : null,
      },
    });
  }

  if (action.type === types.SUGGEST_ARROW_LEFT) {
    return update(state, {
      focusedProduct: { $set: null },
    });
  }

  if (action.type === types.HOVER_SEARCH_SUGGEST) {
    const currentQuery = getCurrentQuery(action.payload, state);
    return update(state, {
      focusedSuggestion: { $set: action.payload },
      queries: {
        $splice: getQuerySplice(currentQuery),
      },
      searchString: {
        $set: currentQuery,
      },
      productSkeleton: {
        $set: isSkeleton(currentQuery),
      },
    });
  }

  if (action.type === types.LEAVE_SEARCH_SUGGEST) {
    const currentQuery = state.typedSearchString;
    return update(state, {
      focusedSuggestion: { $set: null },
      focusedProduct: { $set: null },
      queries: {
        $splice: getQuerySplice(currentQuery),
      },
      searchString: {
        $set: currentQuery,
      },
      productSkeleton: {
        $set: isSkeleton(currentQuery),
      },
    });
  }

  switch (action.type) {
    case types.LOAD_SEARCH_SUGGEST_STARTED_IN_BACKGROUND: {
      const { query } = action.payload;

      return update(state, {
        queries: { $splice: getQuerySplice(query) },
        focusedSuggestion: { $set: null },
        productSkeleton: {
          $set: isSkeleton(state.searchString),
        },
      });
    }

    case types.LOAD_SEARCH_SUGGEST_SUCCESS: {
      const wordsData = {};

      // abort if searchString is empty
      if (state.searchString !== undefined && state.searchString.length < 2)
        return state;

      // write data if query is present
      const { query, jsonData } = action.payload;
      if (state.queries.includes(query)) {
        const data = jsonData;
        const shouldUseSearchString =
          data.words && data.words.length === 0 && state.searchString;
        if (shouldUseSearchString) {
          data.words.unshift({ word: state.searchString, userInputWord: true });
        }
        wordsData[query] = { $set: data.words };
      }

      // cleanup: remove data from queries that don't exist any more
      Object.keys(state.data.wordsData).forEach((q) => {
        if (!state.queries.includes(q)) {
          wordsData[q] = { $set: undefined };
        }
      });

      return update(state, {
        data: { wordsData },
        productSkeleton: {
          $set: isSkeleton(state.searchString),
        },
      });
    }
    case types.LOAD_SEARCH_SUGGEST_FAILED: {
      const productsData = {};
      const { query } = action.payload;
      if (state.queries.includes(query)) {
        productsData[query] = { $set: [] };
      }

      return update(state, {
        data: { productsData },
        productSkeleton: { $set: false },
      });
    }

    case types.LOAD_SEARCH_SUGGEST_PRODUCTS_STARTED_IN_BACKGROUND: {
      return update(state, {
        productSkeleton: {
          $set: isSkeleton(state.searchString),
        },
      });
    }

    case types.LOAD_SEARCH_SUGGEST_PRODUCTS_SUCCESS: {
      const productsData = {};

      // abort if searchString is empty
      if (state.searchString !== undefined && state.searchString.length < 2)
        return state;

      const { query, jsonData } = action.payload;
      if (state.queries.includes(query)) {
        productsData[query] = { $set: jsonData };
      }

      return update(state, {
        data: { productsData },
        productSkeleton: { $set: false },
      });
    }

    case types.LOAD_SEARCH_SUGGEST_PRODUCTS_FAILED: {
      const productsData = {};

      const { query } = action.payload;
      if (state.queries.includes(query)) {
        productsData[query] = { $set: [] };
      }

      return update(state, {
        data: { productsData },
        productSkeleton: { $set: false },
      });
    }

    case types.CLEAR_SEARCH_SUGGESTS: {
      const resetValue = "EMPTY_SEARCH_STRING";
      const $splice = [];
      if (state.queries.includes(resetValue)) {
        $splice.push([state.queries.indexOf(resetValue), 1]);
      }
      $splice.push([0, 0, resetValue]);

      const data = {
        wordsData: {},
        productsData: {},
      };
      data.wordsData[resetValue] = { $set: [] };
      data.productsData[resetValue] = { $set: {} };

      return update(state, {
        queries: { $splice },
        focusedSuggestion: { $set: null },
        focusedProduct: { $set: null },
        data,
      });
    }

    case types.CHANGE_SEARCH_STRING:
      return update(state, {
        searchString: {
          $set: action.payload,
        },
        typedSearchString: {
          $set: action.payload,
        },
        productSkeleton: {
          $set: isSkeleton(state.searchString),
        },
        focusedProduct: { $set: null },
      });

    case types.CLEAR_SEARCH_STRING:
      return update(state, {
        searchString: {
          $set: "",
        },
        typedSearchString: {
          $set: action.payload,
        },
      });

    case types.MOUSE_ENTER_SEARCH_SUBMIT:
      return update(state, {
        focusedProduct: {
          $set: null,
        },
      });

    default:
      return state;
  }
}
