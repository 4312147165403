import React from "react";
import classnames from "classnames/bind";

import Text from "../Text";

import s from "./ListItem.module.css";

const cx = classnames.bind(s);

type Props = {
  children?: React.ReactNode;
  margin?: boolean;
  skeleton?: boolean;
};

/**
 * ListItem
 */
function ListItem({ children, margin, skeleton, ...args }: Props) {
  return (
    <li {...args} className={cx("item", { margin })}>
      <Text normal skeleton={skeleton}>
        {children}
      </Text>
    </li>
  );
}

export default ListItem;
