import classNames from "classnames/bind";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { buildSrcSet, buildUrl } from "../imageUrlBuilder";
import { FALLBACK_PRESET, IMAGE_PRESETS, IMAGESIZES } from "./constants";
import { EnergyEfficiencyClass } from "./types";

import s from "./EnergyEfficiencyLabel.module.css";

const cx = classNames.bind(s);

type Props = {
  pattern?: string;
  presets?: unknown[];
  fallbackPreset?: string;
  altText?: string;
  eeClass?: EnergyEfficiencyClass;
  orientation?: "left" | "right";
  size?: "large" | "normal";
  blackAndWhite?: boolean;
};

function EnergyEfficiencyLabel({
  pattern = null,
  presets = null,
  fallbackPreset = null,
  altText,
  eeClass,
  orientation = "right",
  size = "normal",
  blackAndWhite = false,
}: Props) {
  if (!eeClass) return null;

  let srcSet;
  let fallBackSrc;
  let handleClick;
  if (pattern) {
    srcSet = buildSrcSet(pattern, presets || IMAGE_PRESETS);
    fallBackSrc = buildUrl(pattern, fallbackPreset || FALLBACK_PRESET);

    handleClick = (e) => {
      e.preventDefault();
      e.currentTarget.dispatchEvent(
        new window.CustomEvent("GLOBAL_EVENTS__MODAL_OPEN", {
          detail: {
            type: "PLAIN_TEXT",
            payload: `<div class="${s.overlay}">
          <img
            class="${s.overlayImage}"
            src="${fallBackSrc}"
            srcSet="${srcSet}"
            sizes="${IMAGESIZES}"
            alt="${altText}"
          />
        </div>`,
          },
          bubbles: true,
        })
      );
    };
  }

  let labelDefinition;
  switch (eeClass) {
    case "A":
    case "A+":
    case "A++":
      labelDefinition = { letter: "A", backgroundColor: "#00a651" };
      break;
    case "B":
      labelDefinition = { letter: "B", backgroundColor: "#51b848" };
      break;
    case "C":
      labelDefinition = { letter: "C", backgroundColor: "#bed731" };
      break;
    case "D":
      labelDefinition = { letter: "D", backgroundColor: "#fcee23" };
      break;
    case "E":
      labelDefinition = { letter: "E", backgroundColor: "#fcb918" };
      break;
    case "F":
      labelDefinition = { letter: "F", backgroundColor: "#f37124" };
      break;
    case "G":
      labelDefinition = { letter: "G", backgroundColor: "#ed1c24" };
      break;
    default:
      break;
  }

  // eslint-disable-next-line react/prop-types
  function pointingRight({ backgroundColor, letter }) {
    return (
      <g>
        <polygon
          fill={blackAndWhite ? "#fff" : backgroundColor}
          points="78.84 0.81 22.43 0.81 22.43 56.74 78.84 56.74 104.55 28.77 78.84 0.81"
        />
        <path
          className={cx("cls-2")}
          d="M78.28.4H.85A.41.41,0,0,0,.44.81V56.74a.4.4,0,0,0,.36.44H78.28a.43.43,0,0,0,.3-.13l25.71-28a.43.43,0,0,0,0-.56L78.58.5A.38.38,0,0,0,78.28.4Z"
        />
        <text
          className={cx("cls-3", { blackAndWhite })}
          transform="translate(38.24 43.14) scale(0.93 1)"
        >
          {letter}
        </text>
        <text className={cx("cls-5")} transform="translate(5.38 18.45)">
          A
        </text>
        <line
          className={cx("cls-6")}
          x1="12.11"
          y1="34.89"
          x2="12.11"
          y2="22.96"
        />
        <polyline
          className={cx("cls-6")}
          points="15.27 26.07 12.11 22.91 8.94 26.07"
        />
        <text
          className={cx("cls-7")}
          transform="translate(5.92 52.99) scale(0.93 1)"
        >
          G
        </text>
        {blackAndWhite && (
          <line className={cx("cls-8")} x1="23.2" y1="0" x2="23.2" y2="57" />
        )}
      </g>
    );
  }

  // eslint-disable-next-line react/prop-types
  function pointingLeft({ backgroundColor, letter }) {
    return (
      <g>
        <polygon
          fill={blackAndWhite ? "#fff" : backgroundColor}
          points="25.98 0.83 82.39 0.83 82.39 56.75 25.98 56.75 0.27 28.79 25.98 0.83"
        />
        <path
          className={cx("cls-2")}
          d="M26.54.42H104a.41.41,0,0,1,.41.41h0V56.75a.41.41,0,0,1-.41.41H26.54a.4.4,0,0,1-.3-.13L.53,29a.4.4,0,0,1,0-.56L26.24.47A.44.44,0,0,1,26.54.42Z"
        />
        <text
          className={cx("cls-3", { blackAndWhite })}
          transform="translate(38.79 43.16) scale(0.93 1)"
        >
          {letter}
        </text>
        <text className={cx("cls-5")} transform="translate(86.84 18.47)">
          A
        </text>
        <line
          className={cx("cls-6")}
          x1="93.56"
          y1="34.9"
          x2="93.56"
          y2="22.98"
        />
        <polyline
          className={cx("cls-6")}
          points="96.72 26.08 93.56 22.92 90.4 26.08"
        />
        <text
          className={cx("cls-7")}
          transform="translate(87.37 53) scale(0.93 1)"
        >
          G
        </text>
        {blackAndWhite && (
          <line className={cx("cls-8")} x1="82" y1="0" x2="82" y2="57" />
        )}
      </g>
    );
  }

  return (
    <button
      data-test-stelar-energy-efficiency-label
      className={cx("button", { noOverlay: !pattern })}
      onClick={handleClick}
      type="button"
    >
      <div className={cx("energyContainer", { [size]: true })}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104.8 63">
          {orientation === "right"
            ? pointingRight(labelDefinition)
            : pointingLeft(labelDefinition)}
        </svg>
      </div>
    </button>
  );
}

export default EnergyEfficiencyLabel;
