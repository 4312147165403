// ase
import { setPageTitle } from "shared/webbridge/actions";
import { setOtrToken } from "shared/utils/httpContext/actions";
import {
  LOGIN_PAGE__LOAD_LOGIN_PAGE,
  LOGIN_PAGE__LOGIN_FAILED,
  LOGIN_PAGE__LOGIN,
  LOGIN_PAGE__REGISTER_FAILED,
  LOGIN_PAGE__REGISTER,
  LOGIN_PAGE__TOGGLE_ACCORDION,
} from "../constants";
import backendUrl from "../utils/AseBackendUrls";
import { triggerEvent } from "../utils/BrowserEvents";
import { openPage, redirectToTarget } from "../utils/Routing";

function triggerLoginChangeEvent() {
  triggerEvent("ase:login:changed");
  triggerEvent("ase:basket:changed");
  triggerEvent("ase:wishlist:changed");
}

export function loadLoginPage(apiClient, query) {
  return apiClient.get(
    backendUrl.LOGIN,
    {
      ok: (content) => [
        {
          type: LOGIN_PAGE__LOAD_LOGIN_PAGE,
          payload: content.body,
        },
        setOtrToken(content?.headers?.["x-mf-otr"]),
        setPageTitle(content.body?.page?.headData?.title),
      ],
    },
    {
      httpClientOptions: {
        params: query,
      },
    }
  );
}

export function login(
  { routes, email, password, target, otrToken },
  apiClient
) {
  return (dispatch) => {
    const postData = { email, password, target };
    dispatch([
      {
        type: LOGIN_PAGE__LOGIN,
      },
      apiClient.post(
        backendUrl.LOGIN,
        postData,
        {
          ok: (response) => {
            if (response?.headers?.location) {
              return [
                triggerLoginChangeEvent(),
                redirectToTarget(routes, response),
              ];
            }
            return [
              setOtrToken(response?.headers?.["x-mf-otr"]),
              {
                type: LOGIN_PAGE__LOGIN_FAILED,
                payload: response.body,
              },
            ];
          },
          // TODO remove after deployment
          accepted: (content) => [
            triggerLoginChangeEvent(),
            redirectToTarget(routes, content),
          ],
          // TODO remove after deployment
          unauthorized: (content) => [
            {
              type: LOGIN_PAGE__LOGIN_FAILED,
              payload: content.body,
            },
          ],
        },
        {
          httpClientOptions: {
            params: { target: target || "/kundenkonto" },
            headers: {
              "Content-Type": "application/json",
              "x-mf-otr": otrToken,
            },
          },
        }
      ),
    ]);
  };
}

export function register({ routes, newemail, target }, apiClient) {
  return (dispatch) => {
    const postData = { newemail, target };
    dispatch([
      {
        type: LOGIN_PAGE__REGISTER,
      },
      apiClient.post(
        backendUrl.REGISTER_LOGIN,
        postData,
        {
          noContent: (content) => [openPage(routes, content.headers.location)],
          conflict: (content) => [
            setOtrToken(content?.headers?.["x-mf-otr"]),
            {
              type: LOGIN_PAGE__REGISTER_FAILED,
              payload: content.body,
            },
          ],
        },
        {
          httpClientOptions: {
            headers: { "Content-Type": "application/json" },
          },
        }
      ),
    ]);
  };
}

export function toggleAccordion(itemToOpen) {
  return {
    type: LOGIN_PAGE__TOGGLE_ACCORDION,
    itemToOpen,
  };
}
