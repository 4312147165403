import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { ErrorBoundary } from "react-error-boundary";

import Category from "sell/pages/Category";
import Detail from "sell/pages/Detail";
import Homepage from "sell/pages/Homepage";
import NotFound from "sell/pages/NotFound";
import Search from "sell/pages/Search";

import AccountDeletionPage from "ase/pages/AccountDeletionPage";
import Ads from "ase/pages/Ads";
import BasketPage from "ase/pages/BasketPage";
import CatalogOrderPage from "ase/pages/CatalogOrderPage";
import ChangeBillingAddressPage from "ase/pages/ChangeBillingAddressPage";
import CreditCardPaymentPageV3 from "ase/pages/CreditCardPaymentPageV3";
import CustomerAccountPage from "ase/pages/CustomerAccountPage";
import DebitorOverviewPage from "ase/pages/DebitorOverviewPage";
import DeliveryAddressPage from "ase/pages/DeliveryAddressPage";
import ErrorTestPage from "ase/pages/ErrorTestPage";
import KuzCreditCardPaymentPageV2 from "ase/pages/KuzCreditCardPaymentPageV2";
import LastCheckPage from "ase/pages/LastCheckPage";
import LoginPage from "ase/pages/LoginPage";
import NewsletterPage from "ase/pages/NewsletterPage";
import NewsletterUnsubscribePage from "ase/pages/NewsletterUnsubscribePage";
import NoScript from "ase/pages/NoScriptPage";
import OrderConfirmationPage from "ase/pages/OrderConfirmationPage";
import OrderHistoryPage from "ase/pages/OrderHistoryPage";
import OrderSheetPage from "ase/pages/OrderSheetPage";
import PasswordPage from "ase/pages/PasswordPage";
import PaymentPage from "ase/pages/PaymentPage";
import RegisterPage from "ase/pages/RegisterPage";
import Uwg7MailUnsubscribePage from "ase/pages/Uwg7MailUnsubscribePage";
import WishListPage from "ase/pages/WishListPage";

import NormalLayout from "shared/components/NormalLayout";
import Root from "shared/components/Root";
import { navigateTo } from "shared/utils/routing/index";
import Loading from "shared/components/Loading";
import { sendClientSideError } from "shared/logger/clientLog";

export default function createRoutes(additionalProps = {}) {
  function wrap(Component) {
    function WrappedComponent(ownProps) {
      const props = { ...ownProps, ...additionalProps };
      return (
        <ErrorBoundary
          fallback={<Loading error />}
          onError={(boundaryError, info) => {
            sendClientSideError(
              `[error-boundary] Message: ${boundaryError || "-"}\n\nErrorStack: ${boundaryError?.stack || "-"}\n\nComponentStack: ${info?.componentStack || "-"}`
            );
          }}
        >
          <Component {...props} />
        </ErrorBoundary>
      );
    }
    WrappedComponent["@@redial-hooks"] = Component["@@redial-hooks"];
    return WrappedComponent;
  }

  const mapLanguageSpecificPaths = (pageObjects) =>
    pageObjects?.reduce((acc, pageObject) => {
      if (
        typeof pageObject.path?.base === "object" &&
        pageObject.path?.appendix
      ) {
        Object.values(pageObject.path.base).forEach((pathEntry) => {
          acc.push({
            ...pageObject,
            component: wrap(pageObject.component),
            path: `${pathEntry}${pageObject.path.appendix}`,
          });
        });
      } else if (typeof pageObject.path === "object") {
        Object.values(pageObject.path).forEach((pathEntry) => {
          acc.push({
            ...pageObject,
            component: wrap(pageObject.component),
            path: pathEntry,
          });
        });
      } else {
        acc.push({
          ...pageObject,
          component: wrap(pageObject.component),
        });
      }
      return acc;
    }, []);

  const sellRoutes = [
    {
      path: "/suche/",
      component: wrap(Search),
    },
    {
      path: "/:categoryUrlName-c:categoryId$numeric/",
      component: wrap(Category),
    },
    {
      path: "/:urlName-p:productId$numeric/",
      component: wrap(Detail),
      skipSsrPrefetch: true,
    },
    {
      path: "/:urlName-a:articleId([gG]?\\d+)/",
      component: wrap(Detail),
      skipSsrPrefetch: true,
    },
    {
      path: "/404",
      component: wrap(NotFound),
    },
  ];

  const aseRoutes = mapLanguageSpecificPaths([
    Ads,
    BasketPage,
    CatalogOrderPage,
    ChangeBillingAddressPage,
    CreditCardPaymentPageV3,
    CustomerAccountPage,
    AccountDeletionPage,
    DebitorOverviewPage,
    DeliveryAddressPage,
    KuzCreditCardPaymentPageV2,
    LastCheckPage,
    LoginPage,
    NewsletterPage,
    NewsletterUnsubscribePage,
    Uwg7MailUnsubscribePage,
    NoScript,
    OrderConfirmationPage,
    OrderHistoryPage,
    OrderSheetPage,
    PasswordPage,
    PaymentPage,
    RegisterPage,
    WishListPage,
    ErrorTestPage,
  ]);

  const root = [
    {
      component: wrap(Root),
      childRoutes: [
        {
          component: wrap(NormalLayout),
          childRoutes: [
            {
              path: "/",
              indexRoute: {
                component: wrap(Homepage),
              },
            },
            ...sellRoutes,
            ...aseRoutes,
          ],
        },
      ],
    },
  ];

  return root;
}

export function registerRouteChangeEventListener(routes) {
  window.addEventListener("mf:location:change", ({ detail }) => {
    navigateTo(detail?.url, routes);
  });
}
