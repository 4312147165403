import update from "immutability-helper";
import * as types from "../../../pages/constants";

const initialState = {
  // hidden hides component completly / hiddenByUser binds css animation
  hidden: true,
  hiddenByUser: false,
};

export default function cookieBannerState(state = initialState, action) {
  switch (action.type) {
    case types.HIDE_COOKIE_BANNER:
      return update(state, {
        hiddenByUser: { $set: true },
      });
    case types.SHOW_COOKIE_BANNER:
      return update(state, {
        hidden: { $set: false },
      });
    default:
      return state;
  }
}
