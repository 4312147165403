import update from "immutability-helper";
import {
  ORDER_SHEET_PAGE__ADD_ORDER_SHEET_ROW,
  ORDER_SHEET_PAGE__ADD_TO_BASKET_FAILED,
  ORDER_SHEET_PAGE__DEL_ORDER_SHEET_ROW,
  ORDER_SHEET_PAGE__GET_ARTICLE_DATA_FAILED,
  ORDER_SHEET_PAGE__GET_ARTICLE_DATA,
  ORDER_SHEET_PAGE__LOAD_ORDER_SHEET_PAGE,
  ORDER_SHEET_PAGE__RERENDER_STATE,
  ORDER_SHEET_PAGE__ADD_TO_BASKET_STARTED,
} from "../constants";

const initialState = {
  page: {
    headline: "",
    submit: {
      label: "",
      link: "",
    },
  },
  pageDescription: "",
  orderSheet: {
    labelArticleDescription: "",
    labelArticleNumber: "",
    labelVariant: "",
    labelDelete: "",
    labelSearch: "",
    labelAddRow: "",
    searchLink: "",
    items: [],
    submitable: true,
    loading: false,
  },
};

const orderSheetPage = (state = initialState, action) => {
  let updateObject = {};
  let orderSubmitable;
  switch (action.type) {
    case ORDER_SHEET_PAGE__ADD_TO_BASKET_STARTED:
      return update(state, { orderSheet: { loading: { $set: true } } });
    case ORDER_SHEET_PAGE__ADD_TO_BASKET_FAILED:
      orderSubmitable = state.orderSheet.submitable;
      updateObject = action.payload;
      updateObject.orderSheet.loading = false;
      updateObject.orderSheet.submitable = orderSubmitable;
      return update(state, { $set: updateObject });
    case ORDER_SHEET_PAGE__LOAD_ORDER_SHEET_PAGE:
      orderSubmitable = state.orderSheet.submitable;
      updateObject = action.payload;
      updateObject.orderSheet.loading = false;
      updateObject.orderSheet.submitable = orderSubmitable;
      return update(state, { $set: updateObject });
    case ORDER_SHEET_PAGE__ADD_ORDER_SHEET_ROW:
      updateObject[action.name] = action.inputScheme;
      return update(state, {
        orderSheet: { items: { $push: [updateObject] } },
      });
    case ORDER_SHEET_PAGE__DEL_ORDER_SHEET_ROW:
      return update(state, {
        orderSheet: { items: { $splice: [[action.rowIndex, 1]] } },
      });
    case ORDER_SHEET_PAGE__GET_ARTICLE_DATA:
      updateObject = state.orderSheet.items;
      updateObject[action.meta.rowIndex] = action.payload;
      return update(state, {
        orderSheet: {
          items: { $set: updateObject },
        },
      });
    case ORDER_SHEET_PAGE__GET_ARTICLE_DATA_FAILED:
      updateObject = state.orderSheet.items;
      updateObject[action.meta.rowIndex] = action.payload;
      return update(state, {
        orderSheet: {
          items: { $set: updateObject },
        },
      });
    case ORDER_SHEET_PAGE__RERENDER_STATE:
      updateObject.orderSheet = action.payload;
      return update(state, {
        orderSheet: {
          submitable: { $set: updateObject },
        },
      });
    default:
      return state;
  }
};

export default orderSheetPage;
