/* eslint-disable import/prefer-default-export */

export function mark(name) {
  if (window.performance && window.performance.mark) {
    performance.mark(name);
  }
}

export function cleanupInitialState(storeKey) {
  delete window[`STORE_${storeKey}`];
  let initialStateDomElement = document.getElementById(storeKey); // eslint-disable-line
  if (initialStateDomElement && initialStateDomElement.parentNode) {
    initialStateDomElement.parentNode.removeChild(initialStateDomElement);
  }
}
