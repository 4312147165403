// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import HeaderAccount from "stelar/components/HeaderIcon/HeaderAccount";

function isActive(object) {
  return !!object;
}

const MiniAccountFragment = ({ label, target, user, onClick }) => (
  <HeaderAccount
    active={isActive(user)}
    link={target}
    text={label}
    onClick={onClick}
  />
);
MiniAccountFragment.propTypes = {
  label: PropTypes.string,
  target: PropTypes.string.isRequired,
  user: PropTypes.object,
  onClick: PropTypes.func,
};

export default MiniAccountFragment;
