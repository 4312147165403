import PropTypes from "prop-types";
import { RenderTargets } from "shared/utils/appUtils";
import { useDeviceInfo } from "shared/utils/httpContext/DeviceInfoProvider";

function RenderTarget({ children, app, web }) {
  const { renderTarget } = useDeviceInfo();
  if (
    (app && renderTarget === RenderTargets.APP) ||
    (web && renderTarget === RenderTargets.WEB)
  ) {
    return children || null;
  }
  return null;
}

RenderTarget.propTypes = {
  children: PropTypes.element,
  web: PropTypes.bool,
  app: PropTypes.bool,
};

export default RenderTarget;
