import React from "react";
import Input from "./Input";

type Props = {
  autoComplete?: string;
  error?: boolean | string;
} & React.ComponentPropsWithoutRef<typeof Input>;

/**
 * InputStreet
 */
function InputStreet({
  autoComplete = "address-line1",
  error = "",
  ...attrs
}: Props) {
  return (
    <Input
      autoComplete={autoComplete}
      className="street"
      error={error}
      {...attrs}
    />
  );
}

export default InputStreet;
