import skeletonProductData from "../../components/ProductTile/skeletonData";

export default {
  heading: "---------- --------------",
  products: [
    skeletonProductData(1),
    skeletonProductData(2, 20),
    skeletonProductData(3),
    skeletonProductData(4),
    skeletonProductData(5, 35),
    skeletonProductData(6),
  ],
};
