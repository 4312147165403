import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classNames from "classnames/bind";
import SliderButton from "../SliderButton";

import s from "./SliderButtonWrapper.module.css";

const cx = classNames.bind(s);

function SliderButtonWrapper({
  prev,
  next,
  position,
  itemsAmount,
  onClick,
  className,
  testSelector,
}) {
  const SliderButtonWrapperClasses = cx("buttonWrapper", position, {
    prev,
    next,
    itemsAmount,
  });
  return (
    <div className={classNames(SliderButtonWrapperClasses, className)}>
      <SliderButton
        prev={prev}
        next={next}
        onClick={onClick}
        testSelector={testSelector}
      />
    </div>
  );
}

SliderButtonWrapper.propTypes = {
  prev: PropTypes.bool,
  next: PropTypes.bool,
  position: PropTypes.oneOf([
    "centerAbsolute",
    "centerForSquareItems",
    "centerForSquareItemsOverlay",
  ]),
  itemsAmount: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
  testSelector: PropTypes.string,
};

export default SliderButtonWrapper;
