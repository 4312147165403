import backendUrl from "../utils/AseBackendUrls";
import { NO_SCRIPT_PAGE__LOAD_PAGE } from "../constants";

export function loadNoScriptPage({ apiClient }) {
  return apiClient.get(backendUrl.NO_SCRIPT, {
    ok: (content) => ({
      type: NO_SCRIPT_PAGE__LOAD_PAGE,
      payload: content.body,
    }),
  });
}
