import { REDUCED_LAYOUT_COMPONTENT__LOAD_REDUCED_LAYOUT } from "../../pages/constants";

import initialState from "./initialState";

const reducedLayout = (state = initialState, action) => {
  switch (action.type) {
    case REDUCED_LAYOUT_COMPONTENT__LOAD_REDUCED_LAYOUT:
      return action.payload;
    default:
      return state;
  }
};

export default reducedLayout;
