// vendor
import classnames from "classnames/bind";
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import {
  GLOBAL_MODAL_TYPES,
  openModal,
} from "shared/components/GlobalModal/GlobalModal";
// mf-stelar
import Heading from "stelar/components/Heading";
import LinkButton from "stelar/components/LinkButton";
import Price from "stelar/components/Price";
import Text from "stelar/components/Text";
// ase
import s from "./SummaryTable.module.css";

const cx = classnames.bind(s);

const SummaryTable = ({
  flexible,
  isLoading,
  items,
  mini,
  shippingChargeAdvice,
  basketDiscountAdvice,
  totalLabel,
  totalValue,
  vatLabel,
}) => {
  const rootClasses = cx("root", {
    flexible,
    mini,
    normal: !mini,
  });

  const showGoGreenLayer = (kuratorTag) => () => {
    openModal(GLOBAL_MODAL_TYPES.KURATOR_FRAGMENT, kuratorTag);
  };

  /* Leider ist die items && (jsx output) Schreibweise bei <table> nicht
     anwendbar, da es 'Whitespace'-Errors in der Console gibt. Es muss
     explizit null zurückgegeben werden.
  */
  const itemRows = items
    ? items.map((itemRow) =>
        itemRow.label || itemRow.value ? (
          <tr key={`${itemRow.label}${itemRow.value}`}>
            <th data-test-ase-pair-label>
              <Text skeleton={isLoading} normal>
                {itemRow.label}
              </Text>
              {itemRow.goGreenInfo && (
                <div>
                  <Text skeleton={isLoading} small>
                    {`${itemRow.goGreenInfo.precedingText} `}
                    <LinkButton
                      prio1
                      small
                      onClick={showGoGreenLayer(itemRow.goGreenInfo.kuratorTag)}
                    >
                      {itemRow.goGreenInfo.linkText}
                    </LinkButton>
                  </Text>
                </div>
              )}
            </th>
            {/* TODO a11y */}
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <td data-test-ase-pair-value>
              <Price skeleton={isLoading} inline price={itemRow.value} />
            </td>
          </tr>
        ) : null
      )
    : null;

  const basketDiscountAdviceRow = basketDiscountAdvice ? (
    <tr>
      <td colSpan="2" className={s.basketDiscountAdvice}>
        <Text skeleton={isLoading} light small>
          {basketDiscountAdvice}
        </Text>
      </td>
    </tr>
  ) : null;

  const shippingChargeAdviceRow = shippingChargeAdvice ? (
    <tr>
      <td colSpan="2" className={s.shippingChargeAdvice}>
        <Text skeleton={isLoading} light small>
          {shippingChargeAdvice}
        </Text>
      </td>
    </tr>
  ) : null;

  return (
    <table className={rootClasses}>
      <tbody>
        {itemRows}
        <tr className={s.grandTotalRow}>
          <th
            className={s.grandTotalHeader}
            data-test-ase-pair-label
            id="grandTotalHeader"
          >
            <Heading
              inline
              prio3={!mini}
              prio4={mini}
              skeleton={isLoading}
              tag="span"
            >
              {totalLabel}
              <br />
              <Text light skeleton={isLoading} small highContrast>
                {vatLabel}
              </Text>
            </Heading>
          </th>
          <td
            className={s.grandTotalData}
            data-test-ase-pair-value
            headers="grandTotalHeader"
          >
            {mini ? (
              <Heading skeleton={isLoading} inline prio2 tag="span">
                {totalValue}
              </Heading>
            ) : (
              <Price inline large price={totalValue} skeleton={isLoading} />
            )}
          </td>
        </tr>
        {basketDiscountAdviceRow}
        {shippingChargeAdviceRow}
      </tbody>
    </table>
  );
};

SummaryTable.propTypes = {
  flexible: PropTypes.bool,
  isLoading: PropTypes.bool,
  items: PropTypes.array,
  mini: PropTypes.bool,
  shippingChargeAdvice: PropTypes.string,
  basketDiscountAdvice: PropTypes.string,
  totalLabel: PropTypes.string,
  totalValue: PropTypes.string,
  vatLabel: PropTypes.string,
};

export default SummaryTable;
