import { setPageTitle } from "shared/webbridge/actions";
import backendUrl from "../utils/AseBackendUrls";
import { NEWSLETTER_PAGE__LOAD_NEWSLETTER_PAGE } from "../constants";

export function loadNewsletterPage(apiClient, query) {
  return apiClient.get(
    backendUrl.NEWSLETTER_SUBSCRIBE,
    {
      ok: (content) => [
        {
          type: NEWSLETTER_PAGE__LOAD_NEWSLETTER_PAGE,
          payload: content.body,
        },
        setPageTitle(content.body?.page?.headData?.title),
      ],
    },
    {
      httpClientOptions: {
        params: query,
      },
    }
  );
}
