// Helper to determine the content attribute of controlled components
import { createChangeInput } from "../constants";

const getContentAttributeName = (event) => {
  switch (event.target.type) {
    case "checkbox":
      return "checked";
    default:
      return "value";
  }
};

export default (dispatch) => (page, path, field) => (event) => {
  const contentAttributeName = getContentAttributeName(event);
  const value = event.target[contentAttributeName];

  return dispatch({
    type: createChangeInput(page, field),
    page,
    value,
    contentAttributeName,
    path,
    name: field,
  });
};
