function getNearestLink(root, target) {
  if (!target) {
    return null;
  }
  if (target.tagName === "A") {
    return target;
  }
  if (target === root) {
    return null;
  }
  return getNearestLink(root, target.parentElement);
}

export function getAnchorFromEvent(e) {
  // allow open in new tab
  if (e.metaKey || e.ctrlKey) {
    return null;
  }

  return getNearestLink(e.currentTarget, e.target);
}

export function getPathFromAnchor(a) {
  // no href
  const path = a.getAttribute("href");
  if (!path) {
    return null;
  }

  // anchor link
  if (path.startsWith("#")) {
    return null;
  }
  return path;
}

export function getPathFromEvent(e) {
  const a = getAnchorFromEvent(e);
  if (!a) return null;

  return getPathFromAnchor(a);
}
