import { getVersion, round } from "../utils";

export default function transformInpMetric(metric) {
  return {
    name: metric.name,
    value: round(metric.value),
    rating: metric.rating,
    entries: metric.entries?.map((entry) => ({
      name: entry.name,
      entryType: entry.entryType,
      duration: entry.duration,
    })),
    version: getVersion(metric),
    navigationType: metric.navigationType,
  };
}
