function scrollToFirstError(errors) {
  errors?.some((fieldName) => {
    const globalMessageAttribute = "data-global-message";
    const globalMessage = document.querySelector(`[${globalMessageAttribute}]`);
    const formElement = document.querySelector(`[name="${fieldName}"]`);
    const fieldset = formElement !== null && formElement.closest("fieldset");
    const labelElement = document.querySelector(`label[for="${fieldName}"]`);

    if (formElement) {
      let scrollTarget = formElement;
      if (globalMessage) {
        scrollTarget = globalMessage;
      } else if (fieldset) {
        scrollTarget = fieldset;
      } else if (labelElement) {
        scrollTarget = labelElement.parentElement;
      }
      scrollTarget.scrollIntoView({
        behavior: "smooth",
      });
      return true;
    }
    return false;
  });
}

function extractFormErrors(formDefinition, errors) {
  return [
    ...Object.values(formDefinition.values || {})
      .filter((value) => value.error?.length > 0)
      .map((value) => value.name),
    ...Object.keys(errors),
  ];
}

export { scrollToFirstError, extractFormErrors };
