import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import HtmlContent from "stelar/components/HtmlContent";
import ContentHeading from "stelar/components/ContentHeading";
import Paragraph from "stelar/components/Paragraph";
import imageSizes from "shared/utils/imageSizes";
import { IMAGE_PROFILE_DEFAULTS } from "shared/utils/imageUrlBuilder";
import s from "./ElementTripleImageText.module.css";
import RatioImage from "../../RatioImage";
import ContentSpacing from "../../ContentSpacing";

function ElementTripleImageText({
  firstImageText,
  secondImageText,
  thirdImageText,
  onImageLoad,
  loadedImages = {},
  onLinkClick,
  fullWidth,
  disableSpacings,
  lazy,
}) {
  const imgSizes = disableSpacings
    ? imageSizes({
        xxxl: "500px",
        m: "33vw",
        default: "100vw",
      })
    : imageSizes({
        xxxl: "310px",
        xxl: "21vw",
        xl: fullWidth ? "21vw" : "19vw",
        l: fullWidth ? "28vw" : "25vw",
        m: "33vw",
        default: "100vw",
      });

  function renderImageWithText(column = {}) {
    const { image, caption, heading, text } = column;
    if (!image || !text) {
      return null;
    }
    return (
      <div className={s.column} data-test-sell-element-tripleimagetext-image>
        <div className={s.imageSection}>
          <div className={s.image}>
            <RatioImage
              image={image}
              lazy={lazy}
              imageProfileDefault={IMAGE_PROFILE_DEFAULTS.kurator}
              sizes={imgSizes}
              onImageLoad={onImageLoad}
              hasLoaded={loadedImages[image && image.pattern]}
            />
          </div>
          {caption && <Paragraph small>{caption}</Paragraph>}
        </div>
        <div className={s.textSection}>
          {heading && (
            <div className={s.heading}>
              <ContentHeading prio3>{heading}</ContentHeading>
            </div>
          )}
          <div className={s.text}>
            <HtmlContent
              dangerouslySetInnerHTML={{ __html: text }}
              onClick={onLinkClick}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <ContentSpacing
      alignment={disableSpacings ? "none" : "columns"}
      flex
      fullWidth={fullWidth}
      data-test-sell-element-tripleimagetext
    >
      {renderImageWithText(firstImageText)}
      {renderImageWithText(secondImageText)}
      {renderImageWithText(thirdImageText)}
    </ContentSpacing>
  );
}

ElementTripleImageText.propTypes = {
  firstImageText: PropTypes.object,
  secondImageText: PropTypes.object,
  thirdImageText: PropTypes.object,
  loadedImages: PropTypes.object,
  onImageLoad: PropTypes.func,
  onLinkClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  disableSpacings: PropTypes.bool,
  lazy: PropTypes.bool,
};

export default ElementTripleImageText;
