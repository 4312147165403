import { connect } from "react-redux";
import { provideHooks } from "redial";
import merge from "lodash.merge";
import loadable from "@loadable/component";

import track from "shared/tracking";
import Loading from "shared/components/Loading";

import frontendUrls from "../utils/AseFrontendUrls";
import loadReducedLayout from "../../components/ReducedLayout/actions";
import { CHANGE_BILLING_ADDRESS_PAGE__UPDATE_FORM } from "../constants";
import {
  confirmAddressAlternative,
  editAddressAlternative,
  loadChangeBillingAddressPage,
  submitChangeBillingAddress,
} from "./actions";
import { updateFormStructure } from "../../util/reduxFormUtils/updateFormStructure";

const redial = {
  fetch: ({ dispatch, apiClient, getState, query }) =>
    Promise.all([
      dispatch(loadChangeBillingAddressPage(apiClient, query)),
      dispatch(loadReducedLayout(apiClient, getState)),
    ]),
  defer: ({ getState }) =>
    track(getState()?.changeBillingAddressPage?.page?.trackingInfo),
};

const mapDispatchToProps = (dispatch, props) => ({
  confirmAddressAlternative: (args) =>
    dispatch(confirmAddressAlternative(args, props.apiClient)),
  editAddressAlternative: (args) =>
    dispatch(editAddressAlternative(args, props.apiClient)),
  submitForm: (args) =>
    dispatch(submitChangeBillingAddress(args, props.apiClient)),
  updateForm:
    ({ addressStatePath, formName }) =>
    ({ updateFormURL, formValues, newValues }) =>
      dispatch(
        updateFormStructure(
          {
            addressStatePath,
            formName,
            formValues,
            newValues,
            type: CHANGE_BILLING_ADDRESS_PAGE__UPDATE_FORM,
            updateFormURL,
          },
          props.apiClient
        )
      ),
});

const mapStateToProps = (state) => ({
  page: state.changeBillingAddressPage.page,
  reducedLayout: state.reducedLayout,
  addressForm: state.changeBillingAddressPage.addressForm,
  target: state.changeBillingAddressPage.target,
  submitStarted: state.changeBillingAddressPage.submitStarted,
});

const LoadableChangeBillingAddressPage = loadable(
  () =>
    import(
      /* webpackChunkName: "changebillingaddress-page" */ "./components/ChangeBillingAddressPage"
    ),
  {
    fallback: Loading,
  }
);

const props = connect(mapStateToProps, mapDispatchToProps, merge);
export default {
  component: provideHooks(redial)(props(LoadableChangeBillingAddressPage)),
  path: frontendUrls.CHANGE_BILLING_ADDRESS,
};
