/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconPinterest(props: IconType) {
  return (
    <svg
      className={classNames(props)}
      viewBox="0 0 243 243"
      aria-label="Pinterest"
      role="img"
    >
      <g>
        <title>Pinterest</title>
        <g>
          <path
            d="M121.5,0C54.4,0,0,54.4,0,121.5C0,173,32,217,77.2,234.7c-1.1-9.6-2-24.4,0.4-34.9
            c2.2-9.5,14.2-60.4,14.2-60.4s-3.6-7.3-3.6-18c0-16.9,9.8-29.5,22-29.5c10.4,0,15.4,7.8,15.4,17.1c0,10.4-6.6,26-10.1,40.5
            c-2.9,12.1,6.1,22,18,22c21.6,0,38.2-22.8,38.2-55.6c0-29.1-20.9-49.4-50.8-49.4c-34.6,0-54.9,25.9-54.9,52.7
            c0,10.4,4,21.6,9,27.7c1,1.2,1.1,2.3,0.8,3.5c-0.9,3.8-3,12.1-3.4,13.8c-0.5,2.2-1.8,2.7-4.1,1.6c-15.2-7.1-24.7-29.2-24.7-47.1
            c0-38.3,27.8-73.5,80.3-73.5c42.1,0,74.9,30,74.9,70.2c0,41.9-26.4,75.6-63,75.6c-12.3,0-23.9-6.4-27.8-14c0,0-6.1,23.2-7.6,28.9
            c-2.7,10.6-10.1,23.8-15.1,31.9c11.4,3.5,23.4,5.4,36,5.4c67.1,0,121.5-54.4,121.5-121.5C243,54.4,188.6,0,121.5,0z"
          />
        </g>
      </g>
    </svg>
  );
}

export default IconPinterest;
