import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import track from "shared/tracking";
import { connect } from "react-redux";
import LazyLoad from "react-lazyload";
import ContentHeading from "stelar/components/ContentHeading";
import { navigateTo } from "shared/utils/routing";
import { getPathFromEvent } from "shared/utils/routing/helper";
import { triggerLocationChange } from "shared/utils/customEvents";
import prefetch from "shared/ssrPrefetch/prefetch";
import AsyncProductSlider from "../../components/AsyncProductSlider";
import { loadRecommendations, loadTeaserFromProductServcie } from "./actions";
import { getSliderActions } from "../../utils/uiState/sliders/actions";
import skeletonData from "./skeletonData";
import StripeColumnWrapper from "../../components/StripeColumnWrapper";
import Teaser from "./component/Teaser";
import { buildCacheKey } from "../sell-recommendations/helper";
import { EPOQ_SESSION_ID_COOKIE_NAME } from "../../pages/constants";

const redial = {
  // eslint-disable-next-line no-shadow
  fetch: ({ dispatch, apiClient, props, cookies, isClient }) => {
    const {
      location,
      article,
      [EPOQ_SESSION_ID_COOKIE_NAME]: sessionId,
      category,
    } = props;

    const params = {
      sessionId,
      ...(article ? { articleNumber: article } : {}),
      ...(category ? { categoryContext: category } : {}),
    };

    return dispatch(
      loadRecommendations(apiClient, location, params, cookies, isClient)
    );
  },
  // eslint-disable-next-line no-shadow
  defer: ({ dispatch, apiClient, props, cookies }) => {
    const { teaserType } = props;

    return dispatch(
      loadTeaserFromProductServcie(apiClient, teaserType, cookies)
    );
  },
};

function mapStateToProps(state, props) {
  const { serverskeleton: serverSkeletonString, heading } = props;

  const { data } = state.recoTeaserHybridFragment.recommendations;
  const { teaser } = state.recoTeaserHybridFragment.loadedTeaser;
  const { loadedSliders, sliderPositions } =
    state.recoTeaserHybridFragment.uiState.sliders;
  const sliderState = {
    loadedSliders,
    sliderPositions,
  };
  const ssrPrefetched = state.ssrPrefetched[buildCacheKey(props)];

  const loading = false;
  return {
    loading,
    sliderState,
    serverSkeleton: serverSkeletonString === "true",
    ...data,
    teaser,
    ...(heading ? { heading } : {}),
    ssrPrefetched,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sliderActions: getSliderActions(dispatch),
    changeLocation: (path, isOwnContext) => {
      if (!isOwnContext) {
        navigateTo(path, {});
      } else {
        triggerLocationChange(path);
      }
    },
  };
}

function RecoTeaserHybridFragment({
  title,
  heading,
  products,
  recommendationId,
  sliderActions,
  sliderState,
  serverSkeleton,
  changeLocation,
  teaser,
  hideTeaser,
  isMobile,
}) {
  if (!products) {
    return null;
  }

  function trackRecommendation(recoId, articleNumber) {
    if (window.epoqPushClick) {
      window.epoqPushClick(recoId, articleNumber);
    }
  }

  function handleClick(e, trackingData) {
    const articleNumber = trackingData.articleNumber || null;
    const path = getPathFromEvent(e);
    if (path) {
      e.preventDefault();
      changeLocation(path, document.getElementById("sell-root"));
    }
    track(trackingData);
    trackRecommendation(recommendationId, articleNumber);
  }

  const fullSlider = (
    <AsyncProductSlider
      products={products}
      onLinkClick={handleClick}
      sliderActions={sliderActions}
      sliderState={sliderState}
      sliderId={`RECOMMENDATIONS_${recommendationId}`}
      size="half"
      lazy
    />
  );

  const serverSkeletonSlider = (
    <AsyncProductSlider
      products={skeletonData.products}
      onLinkClick={(e) => {
        e.preventDefault();
      }}
      sliderActions={sliderActions}
      sliderState={sliderState}
      sliderId={`RECOMMENDATIONS_${recommendationId}`}
      skeleton
      size="half"
      lazy
    />
  );

  const componentWithTeaser = (headline, slider, teaser1, skeleton = false) => (
    <StripeColumnWrapper lastNoBorders={isMobile}>
      <div data-test-sell-reco-teaser-hybrid-fragment>
        <ContentHeading prio2 tag="span" skeleton={skeleton}>
          {headline}
        </ContentHeading>
        {slider}
      </div>
      {!hideTeaser ? <Teaser {...teaser1} /> : null}
    </StripeColumnWrapper>
  );

  if (serverSkeleton) {
    return (
      <LazyLoad
        placeholder={componentWithTeaser(
          skeletonData.heading || title,
          serverSkeletonSlider,
          teaser,
          true
        )}
        once
        offset={1000}
      >
        {componentWithTeaser(heading || title, fullSlider, teaser)}
      </LazyLoad>
    );
  }

  return !products
    ? componentWithTeaser(
        skeletonData.heading || title,
        serverSkeletonSlider,
        teaser,
        true
      )
    : componentWithTeaser(heading || title, fullSlider, teaser);
}

RecoTeaserHybridFragment.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  sliderActions: PropTypes.object,
  sliderState: PropTypes.object,
  products: PropTypes.array,
  recommendationId: PropTypes.string,
  serverSkeleton: PropTypes.bool,
  changeLocation: PropTypes.func,
  teaser: PropTypes.object,
  hideTeaser: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  prefetch(RecoTeaserHybridFragment, redial, {
    cookies: [EPOQ_SESSION_ID_COOKIE_NAME],
    buildCacheKey,
  })
);
