// vendor
import classNames from "classnames/bind";
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { Controller, FormProvider } from "react-hook-form";
// mf-stelar
import Form from "stelar/components/Form";
import FormRow from "stelar/components/FormRow";
import Heading from "stelar/components/Heading";
import Message from "stelar/components/Message";
// ase
import AddressFormContent from "../../../components/AddressForm/AddressFormContent";
import AddressFormSuggestionsWrap from "../../../components/AddressFormSuggestionsWrap";
import frontendUrl from "../../../pages/utils/AseFrontendUrls";
import CatalogList from "../../../components/Catalog/CatalogList";
import CatalogTile from "../../../components/Catalog/CatalogTile";
import { buildBackendGeneratedForm } from "../../../util/reduxFormUtils/reduxFormBuilder";
import { getPropertyOrString } from "../../../util/jsonUtils";
// component
import s from "./CatalogOrderForm.module.css";

const cx = classNames.bind(s);

function AwarenessRow({ children }) {
  return <div className={s.awarenessRow}>{children}</div>;
}

AwarenessRow.propTypes = {
  children: PropTypes.object,
};

const CatalogOrderForm = ({
  additionalCatalogs,
  awarenessHint,
  awarenessId,
  confirmAddressAlternative,
  editAddressAlternative,
  errorMessage,
  formDefinition,
  formName,
  globalMessage,
  onSubmit,
  cacheFormValues,
  formCache,
  heading,
  highlightedCatalog,
  infoMessage,
  layout,
  message,
  skeleton,
  submitButton,
  ...props
}) => {
  if (formDefinition.structure?.length === 0) {
    return null;
  }

  const { formProviderValue, onSubmitHandler, control, getValues } =
    buildBackendGeneratedForm({
      formDefinition,
      onSubmit,
      additionalFieldNames: [
        "awarenessHint",
        "awarenessId",
        "selectedCatalogs",
      ],
      cachedValues: formCache,
    });

  const onBlurHandler = () => {
    const {
      awarenessHint: ignore1,
      awarenessId: ignore2,
      ...finalValues
    } = getValues();
    cacheFormValues({
      values: finalValues,
    });
  };

  const rootClassNames = classNames(cx("root"), cx(layout));
  const isCatalog = true;

  const additionalCatalogsDiv = additionalCatalogs &&
    additionalCatalogs.catalogs?.length > 0 && (
      <div className={s.additionalCatalogs}>
        <div className={s.subHeading}>
          <Heading prio2 skeleton={skeleton}>
            {getPropertyOrString(additionalCatalogs, "heading")}
          </Heading>
        </div>
        <CatalogList
          isLoading={skeleton}
          name="selectedCatalogs"
          shopCatalogs={additionalCatalogs.catalogs}
        />
      </div>
    );

  const addressForm = formDefinition.heading ? (
    <div>
      <div className={s.subHeading}>
        <Heading className={s.subHeading} prio2>
          {formDefinition.heading}
        </Heading>
      </div>
      <AddressFormContent
        isCatalog={isCatalog}
        buttonNextTestId="catalogOrderCTA"
        formDefinition={formDefinition}
        formName={formName}
        layout={layout}
        submitButton={submitButton}
        {...props}
      />
    </div>
  ) : null;

  const catalogTile = highlightedCatalog ? (
    <CatalogTile {...highlightedCatalog} />
  ) : null;

  // TODO: GlobalMessageDto nutzen
  let messageElement;
  if (errorMessage) {
    messageElement = <Message type="error" text={errorMessage} />;
  } else if (infoMessage) {
    messageElement = <Message type="attention" text={infoMessage} />;
  } else {
    messageElement = null;
  }

  const awarenessHintRow = awarenessHint ? (
    <AwarenessRow>
      <FormRow
        label={{
          text: awarenessHint.label,
          for: "awarenessHint",
        }}
        layout="fluid"
      >
        <Controller
          render={({ field }) => (
            <input {...field} placeholder={awarenessHint.label} />
          )}
          control={control}
          name="awarenessHint"
        />
        <div>Hint</div>
      </FormRow>
    </AwarenessRow>
  ) : null;

  const awarenessIdRow = awarenessId ? (
    <AwarenessRow>
      <FormRow
        label={{
          text: awarenessId.label,
          for: "awarenessId",
        }}
        layout="fluid"
      >
        <Controller
          render={({ field }) => <input {...field} readOnly />}
          control={control}
          name="awarenessId"
          defaultValue={awarenessId.value}
        />
        <div>ID</div>
      </FormRow>
    </AwarenessRow>
  ) : null;

  return (
    <div className={s.catalogContainer}>
      <Heading className={s.heading} prio1 skeleton={skeleton}>
        {heading}
      </Heading>
      {globalMessage && <Message {...globalMessage} />}
      {message && <Message {...message} />}
      {messageElement}
      {catalogTile}
      <AddressFormSuggestionsWrap
        editAddressAlternative={editAddressAlternative}
        confirmAddressAlternative={confirmAddressAlternative}
        formDefinition={formDefinition}
      >
        <FormProvider {...formProviderValue}>
          <Form
            action={frontendUrl.NO_SCRIPT.DE}
            className={rootClassNames}
            layout={layout}
            method="POST"
            onSubmit={onSubmitHandler}
            onBlur={onBlurHandler}
          >
            {additionalCatalogsDiv}
            {awarenessIdRow}
            {awarenessHintRow}
            {addressForm}
          </Form>
        </FormProvider>
      </AddressFormSuggestionsWrap>
    </div>
  );
};

CatalogOrderForm.propTypes = {
  additionalCatalogs: PropTypes.object.isRequired,
  awarenessHint: PropTypes.object,
  awarenessId: PropTypes.object,
  confirmAddressAlternative: PropTypes.func.isRequired,
  editAddressAlternative: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  formDefinition: PropTypes.object,
  cacheFormValues: PropTypes.func,
  formCache: PropTypes.object,
  formName: PropTypes.string,
  globalMessage: PropTypes.object,
  onSubmit: PropTypes.func,
  heading: PropTypes.string.isRequired,
  highlightedCatalog: PropTypes.object,
  infoMessage: PropTypes.string,
  layout: PropTypes.oneOf(["compact", "fluid", "standard", "fixed"]),
  message: PropTypes.object,
  skeleton: PropTypes.bool,
  submitButton: PropTypes.object.isRequired,
};

CatalogOrderForm.defaultPropTypes = {
  errorMessage: "",
  heading: "",
  infoMessage: "",
};

export default CatalogOrderForm;
