import update from "immutability-helper";
import {
  PASSWORD_FORGOT_PAGE__LOAD_PASSWORD_FORGOT_PAGE,
  PASSWORD_FORGOT_PAGE__REQUEST_PASSWORD_CHANGE,
  PASSWORD_FORGOT_PAGE__REQUEST_PASSWORD_RECOVERY_EMAIL_FAILED,
  PASSWORD_FORGOT_PAGE__REQUEST_PASSWORD_RECOVERY_EMAIL,
  PASSWORD_FORGOT_PAGE__VALIDATE_EMAIL,
  PASSWORD_FORGOT_PAGE__VALIDATE_INPUT,
  PASSWORD_FORGOT_PAGE__VALIDATE_PASSWORDREPETITION_FIELD,
  PASSWORD_PAGE__VALIDATE_ALL,
} from "../constants";
import {
  validateAll,
  validateEmail,
  validatePasswordRepetition,
} from "./validation";
import { validateInput } from "../utils/Validators";

const initialState = {
  pageDescription: "…………… …………… ……… ……… …………………………………… ……… ……… …………… …………",
  headline: "…………………… ………………………",
  buttonNextLabel: "……………… …………………",
  email: {
    value: "",
    label: "…-…………-………………",
    required: true,
    error: "",
    errorEmpty: "",
    errorInvalid: "",
  },
  password: {},
  passwordRepetition: {},
  submitable: true,
  page: {
    headData: {},
  },
};

const passwordPage = (state = initialState, action) => {
  switch (action.type) {
    case PASSWORD_PAGE__VALIDATE_ALL:
      return validateAll(state);
    // case PASSWORD_FORGOT_PAGE__LOAD_PASSWORD_FORGOT_PAGE:
    //   if (action.payload.response.status) {
    //     return update(state, { $set: action.payload.response.data });
    //   }
    //   return state;
    case PASSWORD_FORGOT_PAGE__LOAD_PASSWORD_FORGOT_PAGE:
      return update(state, { $set: action.payload });
    case PASSWORD_FORGOT_PAGE__REQUEST_PASSWORD_RECOVERY_EMAIL:
      return update(state, { $set: action.payload });
    case PASSWORD_FORGOT_PAGE__REQUEST_PASSWORD_RECOVERY_EMAIL_FAILED:
      return update(state, { $set: action.payload });
    case PASSWORD_FORGOT_PAGE__VALIDATE_EMAIL:
      return validateEmail(state);
    case PASSWORD_FORGOT_PAGE__VALIDATE_INPUT:
      return validateInput(state, action);
    case PASSWORD_FORGOT_PAGE__VALIDATE_PASSWORDREPETITION_FIELD: {
      return validatePasswordRepetition(state);
    }
    case PASSWORD_FORGOT_PAGE__REQUEST_PASSWORD_CHANGE:
      return update(state, { $set: action.payload });
    default:
      return state;
  }
};

export default passwordPage;
