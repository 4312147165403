import update from "immutability-helper";
import {
  LOAD_LAYER_AVAILABILITIES_SUCCESS,
  LOAD_LAYER_AVAILABILITIES_STARTED,
} from "../../pages/constants";
import skeletonData from "./skeletonData";

const initialState = {
  data: skeletonData,
};

const layerAvailabilityFragment = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LAYER_AVAILABILITIES_STARTED: {
      return update(state, {
        loading: { $set: true },
      });
    }
    case LOAD_LAYER_AVAILABILITIES_SUCCESS: {
      return update(state, {
        loading: { $set: false },
        data: { $set: action.payload },
      });
    }

    default:
      return state;
  }
};

export default layerAvailabilityFragment;
