const PRESET_STRING = "{preset}";
const QUERY_PART = "?profile=";

export const IMAGE_PROFILE_DEFAULTS = {
  // eel => defined in stelar
  produktliste: {
    sizes: {
      "150w": "produktliste_150",
      "200w": "produktliste_200",
      "300w": "produktliste_300",
      "400w": "produktliste_400",
      "500w": "produktliste_500",
      "750w": "produktliste_750",
      "1000w": "produktliste_1000",
    },
    previewProfile: "produktliste_32",
    fallbackProfile: "produktliste_750",
  },
  pdsmain: {
    sizes: {
      "150w": "pdsmain_150",
      "200w": "pdsmain_200",
      "300w": "pdsmain_300",
      "400w": "pdsmain_400",
      "500w": "pdsmain_500",
      "750w": "pdsmain_750",
      "1000w": "pdsmain_1000",
    },
    previewProfile: "pdsmain_32",
    fallbackProfile: "pdsmain_750",
  },
  categoryteaser: {
    sizes: {
      "150w": "categoryteaser_150",
      "200w": "categoryteaser_200",
      "300w": "categoryteaser_300",
      "400w": "categoryteaser_400",
      "500w": "categoryteaser_500",
      "750w": "categoryteaser_750",
      "1000w": "categoryteaser_1000",
    },
    previewProfile: "categoryteaser_32",
    fallbackProfile: "categoryteaser_750",
  },
  kurator: {
    sizes: {
      "150w": "kurator_150",
      "200w": "kurator_200",
      "300w": "kurator_300",
      "400w": "kurator_400",
      "500w": "kurator_500",
      "750w": "kurator_750",
      "1000w": "kurator_1000",
      "1250w": "kurator_1250",
      "1500w": "kurator_1500",
      "2000w": "kurator_2000",
      "2500w": "kurator_2500",
      "3000w": "kurator_3000",
    },
    previewProfile: "kurator_32",
    fallbackProfile: "kurator_1250",
  },
  kuratorteaser: {
    sizes: {
      "150w": "kuratorteaser_150",
      "200w": "kuratorteaser_200",
      "300w": "kuratorteaser_300",
      "400w": "kuratorteaser_400",
      "500w": "kuratorteaser_500",
      "750w": "kuratorteaser_750",
      "1000w": "kuratorteaser_1000",
      "1250w": "kuratorteaser_1250",
      "1500w": "kuratorteaser_1500",
      "2000w": "kuratorteaser_2000",
      "2500w": "kuratorteaser_2500",
      "3000w": "kuratorteaser_3000",
    },
    previewProfile: "kuratorteaser_32",
    fallbackProfile: "kuratorteaser_1250",
  },
  categoryicons: {
    sizes: { "1x": "categoryicons_50", "2x": "categoryicons_100" },
    previewProfile: "categoryicons_32",
    fallbackProfile: "categoryicons_750",
  },
};

export function buildUrl(pattern, preset) {
  if (!preset) return null;
  return pattern.replace(PRESET_STRING, preset);
}

function buildSrcString(pattern, preset, width) {
  return `${buildUrl(pattern, preset)} ${width}`;
}

function buildSrcSet(pattern, presets) {
  if (!presets) return null;
  return presets
    .map(({ sizeKey, preset }) => buildSrcString(pattern, preset, sizeKey))
    .join(", ");
}

export function buildSrcSetWithDefault(image, profileDefault) {
  if (profileDefault && !image?.presets?.length) {
    return Array.from(
      Object.entries(profileDefault?.sizes).map(([sizeKey, profile]) =>
        buildSrcString(image?.pattern, `${QUERY_PART}${profile}`, sizeKey)
      )
    ).join(", ");
  }
  return buildSrcSet(image?.pattern, image?.presets);
}

export function buildPreviewImageUrl(image, profileDefault) {
  if (profileDefault && !image?.previewPreset) {
    return buildUrl(
      image?.pattern,
      `${QUERY_PART}${profileDefault.previewProfile}`
    );
  }

  return buildUrl(image?.pattern, image?.previewPreset);
}

export function buildFallbackImageUrl(image, profileDefault) {
  if (profileDefault && !image?.fallbackPreset) {
    return buildUrl(
      image?.pattern,
      `${QUERY_PART}${profileDefault.fallbackProfile}`
    );
  }
  return buildUrl(image?.pattern, image?.fallbackPreset);
}

export function buildImageSrcDefinitions(image, imageProfileDefault) {
  if (!image || !image.pattern) {
    return {};
  }
  if (
    imageProfileDefault &&
    !image.presets?.length &&
    !image.previewPreset &&
    !image.fallbackPreset
  ) {
    return {
      srcSet: buildSrcSetWithDefault(image, imageProfileDefault),
      previewSrc: buildPreviewImageUrl(image, imageProfileDefault),
      fallBackSrc: buildFallbackImageUrl(image, imageProfileDefault),
    };
  }

  return {
    srcSet: buildSrcSet(image.pattern, image.presets),
    previewSrc: buildUrl(image.pattern, image.previewPreset),
    fallBackSrc: buildUrl(image.pattern, image.fallbackPreset),
  };
}

export const noPattern = () => `NO_PATTERN_${PRESET_STRING}`;
