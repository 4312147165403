// vendor
import { connect } from "react-redux";
import { provideHooks } from "redial";
import track from "shared/tracking";
import loadable from "@loadable/component";
import Loading from "shared/components/Loading";
// ase
import changeInputAction from "shared/utils/actionReducer/changeInput/ChangeInputAction";
import frontendUrls from "../utils/AseFrontendUrls";
import {
  addOrderSheetRow,
  delOrderSheetRow,
  getArticleData,
  loadOrderSheetPage,
  submitForm,
} from "./actions";
import { ORDER_SHEET_PAGE__PAGE_NAME } from "../constants";

const redial = {
  fetch: ({ apiClient, dispatch, query }) =>
    dispatch(loadOrderSheetPage(apiClient, query)),
  defer: ({ getState }) =>
    track(getState()?.orderSheetPage?.page?.trackingInfo),
};

const page = ORDER_SHEET_PAGE__PAGE_NAME;

const mapDispatchToProps = (dispatch, props) => ({
  addOrderSheetRow: (inputScheme) => dispatch(addOrderSheetRow(inputScheme)),
  changeInput: (path, field) => changeInputAction(dispatch)(page, path, field),
  delOrderSheetRow: (rowIndex) => dispatch(delOrderSheetRow(rowIndex)),
  getArticleData: (args) => {
    dispatch(getArticleData(args, props.apiClient));
  },
  submitFunc: (args) => dispatch(submitForm(args, props.apiClient)),
});

const mapStateToProps = (state) => ({
  noScriptHint: state.orderSheetPage.noScriptHint,
  orderSheet: state.orderSheetPage.orderSheet,
  page: state.orderSheetPage.page,
  pageDescription: state.orderSheetPage.pageDescription,
  submitDisabled: !state.orderSheetPage.orderSheet.submitable,
});

const LoadableOrderSheetPage = loadable(
  () =>
    import(
      /* webpackChunkName: "ordersheet-page" */ "./components/OrderSheetPage"
    ),
  {
    fallback: Loading,
  }
);

export default {
  path: frontendUrls.ORDER_SHEET,
  component: provideHooks(redial)(
    connect(mapStateToProps, mapDispatchToProps)(LoadableOrderSheetPage)
  ),
};
