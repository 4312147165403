import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import style from "./Price.module.css";
import h from "../helpers";

const Price = ({ unitPrice }) => {
  let unitPriceHtml = "";
  let unitOldPriceHtml = null;
  let unitOldPriceSpacer = null;
  const oldUnitPrice = unitPrice.oldValue;
  if (oldUnitPrice) {
    unitOldPriceHtml = (
      <span className={style.strike} data-test-ase-article-oldprice>
        {h.stabilize(oldUnitPrice)}
      </span>
    );
    unitOldPriceSpacer = " ";
    unitPriceHtml = <ins>{h.stabilize(unitPrice.value)}</ins>;
  } else {
    unitPriceHtml = h.stabilize(unitPrice.value);
  }

  return (
    <div className={style.root} data-test-ase-article-price>
      {unitPrice.label} {unitOldPriceHtml}
      {unitOldPriceSpacer}
      {unitPriceHtml}
    </div>
  );
};

Price.propTypes = {
  unitPrice: PropTypes.object.isRequired,
};

export default Price;
