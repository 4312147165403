// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { useFormContext } from "react-hook-form";
// mf-stelar
import { ButtonBack, ButtonLoading } from "stelar/components/Button";
// ase
import ButtonGroup from "stelar/components/ButtonGroup";
import AddressFormRow from "../../../components/AddressForm/AddressFormRow";
import { buildFormRow } from "../../../util/reduxFormUtils/reduxFormRowFieldBuilder";
import useIsClient from "../../../util/useIsClient";

function AccountDeletionForm({
  navigate,
  formDefinition,
  submitButton,
  back,
  submitStarted,
}) {
  const isClient = useIsClient();
  const context = useFormContext();

  const formName = "accountdeletion";
  const { structure, values } = formDefinition;

  if (!structure) {
    return null;
  }

  const layout = "compact";

  const valueMap =
    values?.reduce((map, valueObject) => {
      // eslint-disable-next-line no-param-reassign
      map[valueObject.name] = valueObject;
      return map;
    }, {}) || {};

  return (
    <>
      {structure.map((element) => {
        const row = [element];

        return (
          <AddressFormRow
            key={element.name}
            row={row}
            valueMap={valueMap}
            formName={formName}
            layout={layout}
          >
            {buildFormRow({
              formName,
              row,
              valueMap,
              context,
            })}
          </AddressFormRow>
        );
      })}

      <ButtonGroup
        buttonLeft={
          <ButtonBack
            data-test-ase-button
            href={back?.link}
            onClick={navigate}
            prio2
            text={back?.label}
          />
        }
        buttonRight={
          <ButtonLoading
            data-test-ase-delete-account-submit-button
            disabled={
              isClient &&
              (submitButton?.disabled || !context?.getValues("deletionConsent"))
            }
            prio1
            text={submitButton?.label}
            type="submit"
            loading={submitStarted}
          />
        }
      />
    </>
  );
}

AccountDeletionForm.propTypes = {
  formDefinition: PropTypes.object,
  submitButton: PropTypes.object,
  back: PropTypes.object,
  navigate: PropTypes.func,
  submitStarted: PropTypes.bool,
};

export default AccountDeletionForm;
