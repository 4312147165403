const PRESET_STRING = "{preset}";

export function buildUrl(pattern, preset) {
  if (!preset) return null;
  return pattern.replace(PRESET_STRING, preset);
}

function buildSrcString(pattern, preset, width) {
  return `${buildUrl(pattern, preset)} ${width}`;
}

export function buildSrcSet(pattern, presets) {
  if (!presets) return null;
  return presets
    .map(({ sizeKey, preset }) => buildSrcString(pattern, preset, sizeKey))
    .join(", ");
}

export const noPattern = () => `NO_PATTERN_${PRESET_STRING}`;
