// vendor
import update from "immutability-helper";
// ase
import {
  DEBITOR_OVERVIEW_PAGE__LOAD_PAGE,
  // DEBITOR_OVERVIEW_PAGE__LOAD_REFUND_OVERLAY,
  // DEBITOR_OVERVIEW_PAGE__LOAD_REFUND_OVERLAY_SUCCESS,
  // DEBITOR_OVERVIEW_PAGE__REQUEST_REFUND_SUCCESS,
} from "../constants";
// component
import initialState from "./initialState";

const debitorOverviewPage = (state = initialState, action) => {
  switch (action.type) {
    case DEBITOR_OVERVIEW_PAGE__LOAD_PAGE:
      return update(state, {
        $set: {
          ...action.payload,
        },
      });
    // case DEBITOR_OVERVIEW_PAGE__LOAD_REFUND_OVERLAY:
    //   return update(state, {
    //     isRefundOverlayLoaded: { $set: false },
    //     refundOverlay: { isLoading: { $set: true } },
    //   });
    // case DEBITOR_OVERVIEW_PAGE__LOAD_REFUND_OVERLAY_SUCCESS:
    //   return update(state, {
    //     isRefundOverlayLoaded: { $set: true },
    //     refundOverlay: {
    //       isLoading: { $set: false },
    //       $merge: { ...action.payload },
    //     },
    //   });
    // case DEBITOR_OVERVIEW_PAGE__REQUEST_REFUND_SUCCESS:
    //   return update(state, {
    //     refundOverlay: {
    //       $set: { ...action.payload },
    //     },
    //   });
    default:
      return state;
  }
};

export default debitorOverviewPage;
