import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import classnames from "classnames";
import classNamesBind from "classnames/bind";
import LinkButton from "stelar/components/LinkButton";
import { renderWithProps } from "./util";
import s from "./Navigation.module.css";

const cx = classNamesBind.bind(s);
function Navigation({
  compact,
  className,
  children,
  country,
  countryToggle,
  aseOffCanvasItems,
  ...attrs
}) {
  const pathname =
    typeof document !== "undefined" && document.location
      ? document.location.pathname
      : "/";
  return (
    <nav
      {...attrs}
      className={classnames(s.root, compact ? s.compact : null, className)}
    >
      {country && (
        <div className={cx("countryContainer")}>
          {countryToggle &&
            country.selection.options.map((element) => {
              const isLabelEqual = country.label === element.label;
              return (
                <div
                  key={`country-selection-${element.label}`}
                  className={cx("selection")}
                >
                  <LinkButton
                    href={`${element.link}${pathname}`}
                    em={isLabelEqual}
                    prio2
                  >
                    {element.label}
                  </LinkButton>
                </div>
              );
            })}
        </div>
      )}
      {renderWithProps(children, { compact })}
      {compact && aseOffCanvasItems}
    </nav>
  );
}

Navigation.propTypes = {
  compact: PropTypes.bool,
  className: PropTypes.string,
  country: PropTypes.object,
  children: PropTypes.node,
  countryToggle: PropTypes.bool,
  aseOffCanvasItems: PropTypes.object,
};

Navigation.defaultProps = {};

export default Navigation;
