const initialState = {
  login: {
    description: "………………………………………………………………………………………………………",
    email: {
      label: "………………",
    },
    headline: "……………………",
    password: {
      label: "……………………",
    },
    passwordRecovery: {
      label: "………………………………",
    },
    submitLink: {
      label: "……………………",
    },
  },
  loginStarted: false,
  registerStarted: false,
  openedItem: "login_box",
  page: {
    back: {
      label: "………………",
    },
    headData: {
      title: "",
    },
    headline: "…………………………………………………………………………………………",
  },
  register: {
    description: "……………………………………………………………………………………………………………………………",
    headline: "…………………………………………",
    newemail: {
      label: "………………",
    },
    submitLink: {
      label: "………………………………",
    },
  },
};

export default initialState;
