import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";

import FormRow from "stelar/components/FormRow";

import { useFormContext } from "react-hook-form";
import { errorByValueMapOrForm } from "../../util/reduxFormUtils/reduxFormRowFieldBuilder";

function makeLabels({ row, valueMap, errors, dirtyFields }) {
  const firstElementInRow = row[0];
  if (firstElementInRow.superLabel) {
    return [
      {
        for: firstElementInRow.name,
        error:
          errors &&
          row
            .map((element) =>
              errorByValueMapOrForm(element, valueMap, errors, dirtyFields)
            )
            .find((e) => e),
        text: firstElementInRow.superLabel,
      },
    ];
  }

  return row.map((element) => ({
    for: element.name,
    error: errorByValueMapOrForm(element, valueMap, errors, dirtyFields),
    text: element.type !== "CHECKBOX" ? element.label : "",
  }));
}

const AddressFormRow = ({ row, valueMap, ...props }) => {
  const { errors, dirtyFields } = useFormContext();

  return (
    <FormRow
      labels={makeLabels({
        row,
        valueMap,
        errors,
        dirtyFields,
      })}
      {...props}
    />
  );
};

AddressFormRow.propTypes = {
  row: PropTypes.array.isRequired,
  valueMap: PropTypes.object.isRequired,
};

export default AddressFormRow;
