import React from "react";
import classNames from "classnames/bind";
import s from "./ListInline.module.css";

type Props = {
  children?: React.ReactNode;
  inverted?: boolean;
};

function ListInline({ children, inverted }: Props) {
  const cx = classNames.bind(s);
  const classNamesString = cx("root", {
    inverted,
  });
  return (
    <ul className={classNamesString}>
      {React.Children.map(children, (item: React.ReactElement, key) =>
        // eslint-disable-next-line react/no-array-index-key
        React.cloneElement(item, { inverted, key })
      )}
    </ul>
  );
}

export default ListInline;
