import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classNames from "classnames/bind";
import Text from "../Text";
import EnergyEfficiencyLabel from "../EnergyEfficiencyLabel";
import s from "./EnergyEfficiency.module.css";
import { EnergyEfficiencyClass } from "../EnergyEfficiencyLabel/types";

const cx = classNames.bind(s);

type Props = {
  variant?: "orderform" | "compact";
  labelText?: string;
  eeClass?: EnergyEfficiencyClass;
  image?: {
    pattern?: string;
    presets?: unknown[];
  };
  fallbackPreset?: string;
  altText?: string;
  orientation?: "left" | "right";
  blackAndWhite?: boolean;
  pdfLabelText?: string;
  pdfUrl?: string;
  iconSize?: "large" | "normal";
};

function EnergyEfficiency({
  variant = "orderform",
  labelText,
  eeClass,
  pdfLabelText,
  pdfUrl,
  image,
  fallbackPreset,
  altText,
  orientation,
  iconSize,
  blackAndWhite,
}: Props) {
  const { pattern, presets } = image;

  return (
    <div
      className={cx(s.root, { [variant]: true })}
      data-test-sell-energy-efficiency
    >
      <div className={cx(s.image, { [variant]: true })}>
        {variant === "orderform" && labelText && (
          <div className={s.label}>
            <Text small>{labelText}</Text>
          </div>
        )}
        <EnergyEfficiencyLabel
          pattern={pattern}
          presets={presets}
          fallbackPreset={fallbackPreset}
          altText={altText || labelText}
          eeClass={eeClass}
          orientation={orientation}
          size={iconSize || (variant === "orderform" && "large") || "normal"}
          blackAndWhite={blackAndWhite}
        />
      </div>
      {pdfUrl ? (
        <div className={cx(s.pdfLink, { [variant]: true })}>
          <a
            className={s.pdfHref}
            rel="noopener noreferrer"
            target="_blank"
            href={pdfUrl}
            data-test-sell-eel-pdf-link
          >
            <Text small>{pdfLabelText}</Text>
          </a>
        </div>
      ) : null}
    </div>
  );
}

export default EnergyEfficiency;
