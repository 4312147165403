import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { Button } from "stelar/components/Button";
import ElementSectionHeading from "../ElementSectionHeading/ElementSectionHeading";
import s from "./ElementProductSlider.module.css";
import AsyncProductSlider from "../../AsyncProductSlider";

function ElementProductSlider({
  headline,
  heading,
  products,
  articles,
  cta,
  onLinkClick,
  sliderState,
  sliderId,
  sliderActions,
  lazy,
  fullWidth,
  disableSpacings,
}) {
  if (
    (!products || products.length === 0) &&
    (!articles || articles.length === 0)
  ) {
    return null;
  }

  const testAttributes = {
    ...(products ? { "data-test-sell-element-productslider": true } : {}),
    ...(articles ? { "data-test-sell-element-articleslider": true } : {}),
  };

  return (
    <div className={s.root} {...testAttributes}>
      {(headline || heading) && (
        <ElementSectionHeading
          heading={headline || heading}
          fullWidth={fullWidth}
          disableSpacings={disableSpacings}
        />
      )}
      <div className={s.products}>
        <AsyncProductSlider
          products={products || articles}
          onLinkClick={onLinkClick}
          sliderActions={sliderActions}
          sliderState={sliderState}
          sliderId={sliderId}
          lazy={lazy}
        />
      </div>
      {cta && (
        <div className={s.cta}>
          <Button prio3 href={cta.link} title={cta.altText} text={cta.text} />
        </div>
      )}
    </div>
  );
}

ElementProductSlider.propTypes = {
  headline: PropTypes.string,
  heading: PropTypes.string,
  products: PropTypes.array,
  articles: PropTypes.array,
  cta: PropTypes.object,
  onLinkClick: PropTypes.func,
  sliderState: PropTypes.object,
  sliderId: PropTypes.string,
  sliderActions: PropTypes.object,
  lazy: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disableSpacings: PropTypes.bool,
};

export default ElementProductSlider;
