import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import ContentHeading from "stelar/components/ContentHeading";
import s from "./ElementSectionHeading.module.css";
import ContentSpacing from "../../ContentSpacing/ContentSpacing";

function ElementSectionHeading({ heading, fullWidth, disableSpacings }) {
  return (
    <ContentSpacing
      alignment={disableSpacings ? "none" : "text"}
      gutters
      fullWidth={fullWidth}
      className={s.spacing}
      data-test-sell-element-section-heading
    >
      <ContentHeading prio2>{heading}</ContentHeading>
    </ContentSpacing>
  );
}

ElementSectionHeading.propTypes = {
  heading: PropTypes.string,
  fullWidth: PropTypes.bool,
  disableSpacings: PropTypes.bool,
};

ElementSectionHeading.defaultProps = {};

export default ElementSectionHeading;
