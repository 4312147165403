import MediaPlayer from "stelar/components/MediaPlayer";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import ContentSpacing from "../../ContentSpacing";
import s from "./ElementAudio.module.css";

function ElementAudio({ audioSrc, audioType, fullWidth }) {
  return (
    <ContentSpacing
      alignment="columns"
      className={s.spacing}
      fullWidth={fullWidth}
      gutters
      data-test-sell-element-audio
    >
      <MediaPlayer
        type={audioType}
        audio={audioSrc}
        data-test-sell-media-player
      />
    </ContentSpacing>
  );
}

ElementAudio.propTypes = {
  audioSrc: PropTypes.string,
  audioType: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default ElementAudio;
