import { combineReducers } from "redux";
import update from "immutability-helper";
import {
  LOAD_PRODUCT_TEASER_SUCCESS,
  LOAD_PRODUCT_TEASER_STARTED,
  LOAD_PRODUCT_TEASER_FAILED,
  LOAD_RECOMMENDATIONS_TIMEOUT,
  LOAD_RECO_TEASER_STARTED,
  LOAD_RECO_TEASER_SUCCESS,
  SHOW_RECO_TEASER_SKELETON,
  LOAD_RECO_TEASER_FAILED,
} from "../../pages/constants";
import uiState from "../../utils/uiState/reducer";

const initialState = {
  loading: false,
  data: null,
};

const recommendations = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_RECO_TEASER_STARTED: {
      return update(state, {
        loading: { $set: true },
        data: { $set: null },
      });
    }

    case LOAD_RECO_TEASER_SUCCESS: {
      return update(state, {
        loading: { $set: false },
        data: { $set: action.payload },
      });
    }

    case LOAD_RECO_TEASER_FAILED: {
      return update(state, {
        loading: { $set: false },
        data: { $set: {} },
      });
    }

    case SHOW_RECO_TEASER_SKELETON: {
      return update(state, {
        loading: { $set: false },
        data: { $set: action.payload },
      });
    }

    default:
      return state;
  }
};

const loadedTeaser = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PRODUCT_TEASER_STARTED: {
      return update(state, {
        loading: { $set: true },
        teaser: { $set: null },
      });
    }

    case LOAD_PRODUCT_TEASER_SUCCESS: {
      return update(state, {
        loading: { $set: false },
        teaser: { $set: action.payload },
      });
    }

    case LOAD_PRODUCT_TEASER_FAILED: {
      return update(state, {
        loading: { $set: false },
        teaser: { $set: {} },
      });
    }

    case LOAD_RECOMMENDATIONS_TIMEOUT: {
      return update(state, {
        error: { $set: false },
      });
    }

    default:
      return state;
  }
};
export default combineReducers({
  recommendations,
  loadedTeaser,
  uiState,
});
