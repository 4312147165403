import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import Paragraph from "stelar/components/Paragraph";
import IconCheck from "stelar/components/Icon/IconCheck";
import IconX from "stelar/components/Icon/IconX";
import Text from "stelar/components/Text";
import {
  triggerLocationChange,
  triggerOverlayCloseEvent,
} from "shared/utils/customEvents";
import { getPathFromEvent } from "shared/utils/routing/helper";
import s from "./LayerAvailability.module.css";
import OverlayProductTile from "../../../components/OverlayProductTile";

const cx = classnames.bind(s);

function LayerAvailability({
  introduction,
  reservationHeading,
  reservationText,
  collectHeading,
  collectText,
  product,
  availableText,
  scarceAvailableText,
  notAvailableText,
  warehouseAvailabilities = [],
  note,
  phoneText,
  emailSubjectText,
  loading,
}) {
  const statusIcons = {
    AVAILABLE: (alt) => (
      <div className={cx("icon", "available")}>
        <IconCheck large alt={alt} />
      </div>
    ),
    SCARCE: (alt) => (
      <div className={cx("icon", "scarce")}>
        <IconCheck large alt={alt} />
      </div>
    ),
    OUT_OF_STOCK: (alt) => (
      <div className={cx("icon", "notAvailable")}>
        <IconX small alt={alt} />
      </div>
    ),
  };

  function handleClick(e) {
    const path = getPathFromEvent(e);
    triggerOverlayCloseEvent(e.target);
    if (path) {
      e.preventDefault();
      triggerLocationChange(path);
    }
  }
  return (
    <div className={s.root} data-test-sell-overlay-warehouse-availabilities>
      <Paragraph>{introduction}</Paragraph>
      {reservationHeading && (
        <Text className={s.heading}>{reservationHeading}</Text>
      )}
      {reservationText && (
        <div>
          <Text>{reservationText}</Text>
        </div>
      )}
      {collectHeading && (
        <div className={s.collectheading}>
          <Text className={s.heading}>{collectHeading}</Text>
        </div>
      )}
      {collectText && (
        <div>
          <Text>{collectText}</Text>
        </div>
      )}
      {product && (
        <OverlayProductTile
          image={product.image}
          manufacturer={product.manufacturer}
          name={product.displayedName}
          skeleton={loading}
          additionalTexts={[
            `${product.labelArticleNumber}: ${product.articleNumber}`,
          ]}
        />
      )}
      {note ? (
        <Paragraph data-test-sell-availability-note>{note}</Paragraph>
      ) : null}

      <ul className={s.legend} data-test-sell-availability-legend>
        <li className={s.legendItem}>
          {statusIcons.AVAILABLE()}{" "}
          <Text small skeleton={loading}>
            {availableText}
          </Text>
        </li>
        <li className={s.legendItem}>
          {statusIcons.SCARCE()}{" "}
          <Text small skeleton={loading}>
            {scarceAvailableText}
          </Text>
        </li>
        <li className={s.legendItem}>
          {statusIcons.OUT_OF_STOCK()}{" "}
          <Text small skeleton={loading}>
            {notAvailableText}
          </Text>
        </li>
      </ul>
      <div
        className={s.availabilities}
        data-test-sell-availability-warehouse-list
      >
        {warehouseAvailabilities.map(
          ({ warehouse, availabilityStatus, availabilityText }) => (
            <div key={warehouse.warehouseId} className={s.row}>
              <div className={s.warehouseName}>
                <Text normal skeleton={loading}>
                  <a
                    href={warehouse.url}
                    onClick={handleClick}
                    data-test-sell-overlay-warehouse-link
                  >
                    {warehouse.name}
                  </a>
                </Text>
              </div>
              <div className={s.status}>
                {statusIcons[availabilityStatus](availabilityText)}
              </div>
              <div className={s.address}>
                <Paragraph skeleton={loading}>
                  {warehouse.addressLines.map((addressLine) => (
                    <React.Fragment key={addressLine}>
                      {addressLine}
                      <br />
                    </React.Fragment>
                  ))}
                  {warehouse.phone ? (
                    <div>
                      {`${phoneText} `}
                      <a
                        href={`tel:${warehouse.phone}`}
                        data-test-sell-overlay-warehouse-phone
                      >
                        {warehouse.phone}
                      </a>
                    </div>
                  ) : null}
                  {warehouse.email ? (
                    <a
                      href={`mailto:${warehouse.email}?subject=(${warehouse.name}) - ${emailSubjectText} ${product.displayedName}, ${product.articleNumber}`}
                      data-test-sell-overlay-warehouse-email
                    >
                      {warehouse.email}
                    </a>
                  ) : null}
                  {warehouse.openingTimeValue ? (
                    <div className={s.openingtime}>
                      {warehouse.openingTimeLabel}
                      <br />
                      {warehouse.openingTimeValue.map((openingTime) => (
                        <React.Fragment key={openingTime}>
                          {openingTime}
                          <br />
                        </React.Fragment>
                      ))}
                    </div>
                  ) : null}
                </Paragraph>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

LayerAvailability.propTypes = {
  introduction: PropTypes.string,
  reservationHeading: PropTypes.string,
  reservationText: PropTypes.string,
  collectHeading: PropTypes.string,
  collectText: PropTypes.string,
  product: PropTypes.object,
  availableText: PropTypes.string,
  scarceAvailableText: PropTypes.string,
  notAvailableText: PropTypes.string,
  note: PropTypes.string,
  phoneText: PropTypes.string,
  emailSubjectText: PropTypes.string,
  warehouseAvailabilities: PropTypes.array,
  loading: PropTypes.bool,
};

export default LayerAvailability;
