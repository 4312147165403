import {
  LOAD_FULL_NAVIGATION_STARTED,
  LOAD_FULL_NAVIGATION_SUCCESS,
  LOAD_HEADER_FOOTER_BANNER_SUCCESS,
  SET_QUERY_PARAMETER,
  SET_DETAIL_PARAMETER,
  SET_BANNER_FOOTNOTES,
  LOAD_HEADER_STARTED,
  LOAD_HEADER_SUCCESS,
  LOAD_FOOTER_STARTED,
  LOAD_FOOTER_SUCCESS,
} from "sell/pages/constants";

import AseBackendUrls from "ase/pages/utils/AseBackendUrls";
import {
  MINI_ACCOUNT_FRAGMENT__GET,
  MINI_BASKET_FRAGMENT__LOAD_MINI_BASKET,
  MINI_BASKET_FRAGMENT__LOAD_MINI_BASKET_DATA,
  MINI_WISH_LIST_FRAGMENT__GET,
} from "ase/pages/constants";

import { setApiPath } from "./helper";

export function setFootnotesToStore(response) {
  if (response.data.bannerFootnotes) {
    return {
      type: SET_BANNER_FOOTNOTES,
      payload: response.data.bannerFootnotes,
    };
  }
}

export function loadHeader(apiClient, params = {}, _path = "") {
  const { articleId, categoryId, path } = params;
  const options = { url: "header", articleId, categoryId, path: _path || path };

  const headerFooterApi = setApiPath(options);
  return [
    { type: LOAD_HEADER_STARTED },
    apiClient.get(
      headerFooterApi,
      {
        ok: (response) => ({
          type: LOAD_HEADER_SUCCESS,
          payload: response.data,
        }),
      },
      {
        apiBase: "/api/sell",
      }
    ),
  ];
}

export function loadFooter(apiClient, params = {}, _path = "") {
  const { articleId, categoryId, path } = params;
  const options = { url: "footer", articleId, categoryId, path: _path || path };

  const headerFooterApi = setApiPath(options);
  return [
    { type: LOAD_FOOTER_STARTED },
    apiClient.get(
      headerFooterApi,
      {
        ok: (response) => ({
          type: LOAD_FOOTER_SUCCESS,
          payload: response.data,
        }),
      },
      {
        apiBase: "/api/sell",
      }
    ),
  ];
}

export function loadFullNavigation(apiClient) {
  return [
    { type: LOAD_FULL_NAVIGATION_STARTED },
    apiClient.get(
      "/navigation/tree",
      {
        ok: (response) => ({
          type: LOAD_FULL_NAVIGATION_SUCCESS,
          payload: response.data,
        }),
      },
      {
        apiBase: "/api/sell",
      }
    ),
  ];
}

export function loadBanner(apiClient, params, path) {
  const { articleId, categoryId, _path } = params;
  const options = { url: "header", articleId, categoryId, path: _path || path };

  const headerFooterApi = setApiPath(options);

  return [
    { type: LOAD_HEADER_STARTED },
    apiClient.get(
      headerFooterApi,
      {
        ok: (response) => [
          {
            type: LOAD_HEADER_FOOTER_BANNER_SUCCESS,
            payload: {
              banner: response.data.banner,
              appInstallBanner: response.data.appInstallBanner,
            },
          },
          () => setFootnotesToStore(response),
        ],
      },
      {
        apiBase: "/api/sell",
      }
    ),
  ];
}

export function loadMiniAccount(apiClient) {
  return apiClient.get(AseBackendUrls.MINI_ACCOUNT, {
    ok: (content) => ({
      payload: content.body,
      type: MINI_ACCOUNT_FRAGMENT__GET,
    }),
  });
}

export function loadMiniWishlist(apiClient) {
  return apiClient.get(AseBackendUrls.MINI_WISHLIST, {
    ok: (content) => ({
      payload: content.body,
      type: MINI_WISH_LIST_FRAGMENT__GET,
    }),
  });
}

export function loadMiniBasketBase(apiClient) {
  return apiClient.get(AseBackendUrls.MINI_BASKET, {
    ok: (content) => ({
      payload: content.body,
      type: MINI_BASKET_FRAGMENT__LOAD_MINI_BASKET,
    }),
  });
}

export function loadMiniBasketData(apiClient) {
  return apiClient.get(AseBackendUrls.MINI_BASKET_DATA, {
    ok: (content) => ({
      payload: content.body,
      type: MINI_BASKET_FRAGMENT__LOAD_MINI_BASKET_DATA,
    }),
  });
}

export function setQueryToStore(query) {
  return { type: SET_QUERY_PARAMETER, payload: query };
}

export function setParamsToStore(params, path) {
  return { type: SET_DETAIL_PARAMETER, payload: { ...params, path } };
}

export function retrieveCsrfToken(apiClient) {
  apiClient.get(AseBackendUrls.CSRF_TOKEN, {});
}
