import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import s from "./SkeletonBox.module.css";

const cx = classnames.bind(s);

type Props = {
  children?: React.ReactNode;
  type?: "page";
};

export default function SkeletonBox({ children, type }: Props) {
  return <div className={cx("root", { [type]: true })}>{children}</div>;
}
