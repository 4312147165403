// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// component
import Benefits from "./Benefits";
import BottomLine from "./BottomLine";
import Footnotes from "./Footnotes";
import LegalLinks from "./LegalLinks";
import s from "./Footer.module.css";

const Footer = ({ benefits, bottomLine, footnotes, isLoading, legal }) => (
  <footer className={s.root}>
    <LegalLinks isLoading={isLoading} legal={legal} />
    {benefits && (
      <Benefits
        benefits={benefits.labels}
        isLoading={isLoading}
        link={benefits.link}
      />
    )}
    {bottomLine && <BottomLine content={bottomLine} isLoading={isLoading} />}
    {footnotes && <Footnotes footnotes={footnotes} isLoading={isLoading} />}
  </footer>
);

Footer.propTypes = {
  benefits: PropTypes.object,
  bottomLine: PropTypes.string,
  footnotes: PropTypes.array,
  isLoading: PropTypes.bool,
  legal: PropTypes.object,
};

export default Footer;
