import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Navigation from "./Navigation";
import TopLevel from "./TopLevel";
import TopLevelItem from "./TopLevelItem";
import MiddleLevel from "./MiddleLevel";
import MiddleLevelItem from "./MiddleLevelItem";
import BottomLevel from "./BottomLevel";
import BottomLevelItem from "./BottomLevelItem";
import { getFlyoutAlignment } from "./util";

function Flyout({
  structure,
  closeLabel,
  currentPath,
  clickedPath,
  activeId,
  flyoutAlign,
  onOpen,
  onClose,
  onCloseDelayed,
  onToggle,
  onNavigate,
}) {
  function handleOnClick(e) {
    if (e.metaKey || e.ctrlKey) {
      return null;
    }
    onClose();
    e.preventDefault();
    onNavigate(e.currentTarget.getAttribute("href"));
    return null;
  }

  function isWide(secondLevelItems = [], thirdLevelItems = []) {
    return secondLevelItems.length <= 3 && thirdLevelItems.length >= 8;
  }

  function numberOfFlyoutColumns(secondLevelItems = []) {
    return secondLevelItems.reduce((sum, item) => {
      const cols = isWide(secondLevelItems, item.children) ? 2 : 1;
      return sum + cols;
    }, 0);
  }

  function thirdLevel(items = []) {
    return (
      <BottomLevel>
        {items.map(({ id, name, link }) => (
          <BottomLevelItem
            key={id}
            name={name}
            link={link}
            active={id === currentPath[2]}
            onClick={handleOnClick}
            data-test-sell-flyout-link-level-3
          />
        ))}
      </BottomLevel>
    );
  }

  function secondLevel(items = []) {
    return items.length === 0 ? null : (
      <MiddleLevel onClose={onClose} closeLabel={closeLabel} level={2}>
        {items.map(
          ({ id, name, image, imageUrl, srcSet, altTag, link, children }) => {
            const layout = isWide(items, children) ? "wide" : "normal";
            return (
              <MiddleLevelItem
                key={id}
                name={name}
                image={image}
                imageUrl={imageUrl}
                srcSet={srcSet}
                altTag={altTag}
                link={link}
                level={2}
                active={id === currentPath[1]}
                onClick={handleOnClick}
                layout={layout}
                data-test-sell-flyout-link-level-2
              >
                {thirdLevel(children, onNavigate)}
              </MiddleLevelItem>
            );
          }
        )}
      </MiddleLevel>
    );
  }

  return (
    <Navigation data-test-sell-flyout-navigation>
      <TopLevel>
        {structure &&
          structure
            .map(({ id, name, link, children, navigationGroup }) => {
              if (navigationGroup === "SERVICE_PAGES") {
                return null;
              }
              const numberOfColumns = numberOfFlyoutColumns(children);
              const hasChildren = children && children.length > 0;
              return (
                <TopLevelItem
                  key={id}
                  name={name}
                  link={link}
                  open={id === clickedPath[0]}
                  active={id === activeId}
                  flyoutAlign={flyoutAlign}
                  flyoutColumns={numberOfColumns}
                  onTouchEnd={(e) => {
                    if (hasChildren) {
                      e.preventDefault();
                      const align = getFlyoutAlignment(e.currentTarget);
                      onToggle(id, align);
                    }
                  }}
                  onMouseEnter={(e) => {
                    const align = getFlyoutAlignment(e.currentTarget);
                    onOpen(id, align);
                  }}
                  onMouseLeave={() => onCloseDelayed()}
                  onClick={handleOnClick}
                  data-test-sell-flyout-toplevellink
                >
                  {secondLevel(children)}
                </TopLevelItem>
              );
            })
            .filter(Boolean)}
      </TopLevel>
    </Navigation>
  );
}

Flyout.propTypes = {
  structure: PropTypes.array,
  closeLabel: PropTypes.string,
  currentPath: PropTypes.array,
  clickedPath: PropTypes.array,
  activeId: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onCloseDelayed: PropTypes.func,
  onToggle: PropTypes.func,
  onNavigate: PropTypes.func,
  flyoutAlign: PropTypes.oneOf(["left", "midleft", "midright", "right"]),
};

Flyout.defaultProps = {
  onNavigate: () => {},
  clickedPath: [],
};

export default React.memo(Flyout, (props, nextProps) => {
  if (props.prop1 === nextProps.prop1) {
    // return true if you don't need re-render
  }
});
