/* eslint-disable */

// vendor
import axiosRetry from "axios-retry";
import deepmerge from "deepmerge";
import { Base64 } from "js-base64";
// ase
import logger from "../logger";
import { OPTIN_TRACKING } from "../tracking/constants";
import { getServerCookieValue } from "../tracking/cookie";
import { allowedTrackingInArray } from "../tracking/optin";
import { API_BASE } from "./constants";
import { Headers } from "./headers";
import instanceCreator from "./instanceCreator";
import { enableVerboseLogging, useCsrfToken } from "./interceptors";
import serializer from "./server/paramsSerializer";
import { getEnv } from "../utils/envVariables";
const log = logger(module);

function getUrl(path, apiHost = "", apiBase = "") {
  if (path.startsWith(API_BASE.SELL) || path.startsWith(API_BASE.ASE)) {
    return `${apiHost}${path}`;
  }
  return `${apiHost}${apiBase}${path}`;
}

function setExtInfo(data) {
  let trackingList = ["min"];

  // log.info(`setExtInfo(${data && typeof data || "missing data param"})`)

  // cookies can be stored as a string by document.cookie call then you handle it like following
  if (typeof data === "string") {
    const mfTrackingCookieValue = getServerCookieValue(OPTIN_TRACKING, data);
    if (mfTrackingCookieValue) {
      try {
        trackingList = allowedTrackingInArray(
          Base64.decode(mfTrackingCookieValue)
        );
      } catch (e) {
        log.error("error decoding optin-cookie A1");
      }
    }
  } else {
    const cookies = data || [];
    // we need this operation, because cookies are stored as an array
    cookies.map((item) => {
      if (item.includes(OPTIN_TRACKING)) {
        // get length of mf-tracking-optin string
        let value = item.substr(OPTIN_TRACKING.length + 1, item.length);
        // there are charset problems, we have to replace ALL of the chars; use regex for replace in string
        const mfTrackingCookieValue = value.replace(/%3D/g, "=");
        if (mfTrackingCookieValue) {
          try {
            trackingList = allowedTrackingInArray(
              Base64.decode(mfTrackingCookieValue)
            );
          } catch (e) {
            log.error("error decoding optin-cookie A2");
          }
        }
      }
      return trackingList;
    });
  }
  return trackingList.join(",");
}

/* 
  creates a configuration for axios right before the actual call (e.g. for sending current url)
  * config - current configuration created by createConfig
 */
function getAdHocConfig(config) {
  const origUri =
    (config && config.headers && config.headers[Headers.X_MF_ORIG_URI]) ||
    (typeof window !== "undefined" && window.location.href);

  if (!origUri) {
    return {};
  }

  let obj = { headers: {} };
  obj.headers[Headers.X_MF_ORIG_URI] =
    origUri || (typeof window !== "undefined" && window.location.href);
  return obj;
}

/* creates a configuration for axios when createHttpClient is called, currently at React boot-up */
function createConfig({
  apiHost,
  cookies,
  queryParams = {},
  isClientCall = false,
  passthroughHeaders = {},
}) {
  const headers = {
    "x-requested-with": "XMLHttpRequest",
    "x-mf-ext-infos": setExtInfo(cookies),
    "content-type": "application/json",
    "x-ssr-request": `${!isClientCall}`,
    [Headers.X_MF_FE_VERSION]: getEnv("BUILD_NUMBER"),
    accept: "application/json",
    ...passthroughHeaders,
  };

  // refused to set unsafe header "Cookie" on client side
  if (!isClientCall && cookies) {
    headers.Cookie = cookies;
  }

  return {
    apiHost,
    headers,
    timeout: 60000,
    params: queryParams,
    withCredentials: true,
    // validateStatus: () => {
    //   return true; // resolve all status codes and handle within ApiResponseHandler
    // },
  };
}

export function createHttpClient({
  cookies,
  apiHost,
  queryParams,
  isClientCall,
  headerContentType,
  passthroughHeaders = {},
  apiBase,
  verbose,
}) {
  const config = createConfig({
    apiHost,
    cookies,
    queryParams,
    isClientCall,
    headerContentType,
    passthroughHeaders,
  });

  let instance = instanceCreator(config);

  function executeRequest(requestExecutor, path, configuration) {
    let latestClientSideConfig = {};
    if (isClientCall) {
      latestClientSideConfig = createConfig({
        apiHost: window.location.origin,
        cookies: document.cookie,
        queryParams,
        isClientCall,
        headerContentType,
        passthroughHeaders,
      });
    }

    let resultingPath;
    let fallbackAuthRedirectTarget = {};
    if (typeof path === "object") {
      fallbackAuthRedirectTarget = {
        authRedirectTarget: path.authRedirectTarget,
      };
      resultingPath = path.path;
    } else {
      resultingPath = path;
    }

    const url = getUrl(
      resultingPath,
      apiHost,
      configuration?.apiBase ?? apiBase
    );
    const adHocConf = getAdHocConfig(config);
    const latestConfig = isClientCall ? latestClientSideConfig : config;
    const conf = deepmerge.all([
      { paramsSerializer: serializer },
      fallbackAuthRedirectTarget,
      latestConfig,
      configuration?.httpClientOptions || {},
      adHocConf,
    ]);

    function mapResult(res) {
      return {
        ...res,
        body: res.data,
        location: res.headers?.location,
        url,
      };
    }

    function handleAxiosError(e) {
      if (verbose || conf.verbose) {
        console.log(e);
      }
      if (e instanceof Error) {
        let configUrl = e.response?.config?.url || "N/A";
        if (e.response?.status > 0) {
          return mapResult(e.response);
        }
        throw `Error while requesting: ${url} / configUrl: ${configUrl}, message: ${e.message}`;
      }
      throw e;
    }

    // attach interceptors
    if (verbose || conf.verbose) {
      enableVerboseLogging(instance);
    }

    useCsrfToken(instance);

    axiosRetry(instance, {
      retryCondition: (error) =>
        axiosRetry.isNetworkOrIdempotentRequestError(error) ||
        error.code === "ECONNABORTED",
      shouldResetTimeout: true,
      retries: 0,
    });

    return requestExecutor(url, conf).then(mapResult).catch(handleAxiosError);
  }

  return {
    get: (path, configuration) =>
      executeRequest(
        (url, conf) => instance.get(url, conf),
        path,
        configuration
      ),
    delete: (path, configuration) =>
      executeRequest(
        (url, conf) => instance.delete(url, conf),
        path,
        configuration
      ),
    post: (path, payload, configuration) =>
      executeRequest(
        (url, conf) => instance.post(url, payload, conf),
        path,
        configuration
      ),
    put: (path, payload, configuration) =>
      executeRequest(
        (url, conf) => instance.put(url, payload, conf),
        path,
        configuration
      ),
    getCookies: () => cookies,
    defaultApiBase: apiBase,
    instance,
  };
}

/* eslint-enable */
