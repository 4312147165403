import loadable from "@loadable/component";
import merge from "lodash.merge";
import { connect } from "react-redux";
import { provideHooks } from "redial";

import Loading from "shared/components/Loading";
import track from "shared/tracking";
import changeInputAction from "shared/utils/actionReducer/changeInput/ChangeInputAction";

import loadReducedLayout from "../../components/ReducedLayout/actions";
import { updateFormStructure } from "../../util/reduxFormUtils/updateFormStructure";
import { DELIVERY_ADDRESS_PAGE__UPDATE_FORM } from "../constants";
import frontendUrls from "../utils/AseFrontendUrls";
import {
  confirmAddressAlternative,
  deleteAddress,
  doChooseAddress,
  editAddressAlternative,
  loadDeliveryAddressPage,
  openItem,
  setOverlayOpen,
  submitChangeDeliveryAddress,
  submitNewDeliveryAddress,
  submitPackstationAddress,
  submitSelectedAddress,
} from "./actions";

const page = "DELIVERY_ADDRESS";

const redial = {
  fetch: ({ apiClient, dispatch, getState, params: { addressId }, query }) =>
    Promise.all([
      dispatch(loadDeliveryAddressPage(apiClient, addressId, query)),
      dispatch(loadReducedLayout(apiClient, getState)),
    ]),
  defer: ({ getState }) =>
    track(getState()?.deliveryAddressPage?.page?.trackingInfo),
};

const mapDispatchToProps = (dispatch, props) => ({
  confirmAddressAlternative: (args) =>
    dispatch(confirmAddressAlternative(args, props.apiClient)),
  deleteAddress: (args) => dispatch(deleteAddress(args, props.apiClient)),
  editAddressAlternative: (args) =>
    dispatch(editAddressAlternative(args, props.apiClient)),
  openItem: (args) => dispatch(openItem(args)),
  doChooseAddress: (args) => dispatch(doChooseAddress(args, props.apiClient)),
  submitNewDeliveryAddress: (args) =>
    dispatch(submitNewDeliveryAddress(args, props.apiClient)),
  submitPackstationAddress: (args) =>
    dispatch(submitPackstationAddress(args, props.apiClient)),
  submitSelectedAddress: (args) =>
    dispatch(submitSelectedAddress(args, props.apiClient)),
  submitChangeDeliveryAddress: (args) =>
    dispatch(submitChangeDeliveryAddress(args, props.apiClient)),
  updateForm:
    ({ addressStatePath, formName }) =>
    ({ updateFormURL, formValues, newValues }) =>
      dispatch(
        updateFormStructure(
          {
            addressStatePath,
            formName,
            formValues,
            newValues,
            type: DELIVERY_ADDRESS_PAGE__UPDATE_FORM,
            updateFormURL,
          },
          props.apiClient
        )
      ),
  storeValue: (path, field) => changeInputAction(dispatch)(page, path, field),
  setOverlayOpen: (args) => dispatch(setOverlayOpen(args)),
});

const mapStateToProps = (state) => ({
  ...state.deliveryAddressPage,
  isLoading: !state.deliveryAddressPage.page.headData,
  reducedLayout: state.reducedLayout,
});

const LoadableDeliveryAddressPage = loadable(
  () =>
    import(
      /* webpackChunkName: "deliveryaddress-page" */ "./components/DeliveryAddressPage"
    ),
  {
    fallback: Loading,
  }
);

export default {
  component: provideHooks(redial)(
    connect(
      mapStateToProps,
      mapDispatchToProps,
      merge
    )(LoadableDeliveryAddressPage)
  ),
  path: { base: frontendUrls.DELIVERY_ADDRESS, appendix: "(/:addressId)" },
};
