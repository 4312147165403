import React from "react";
import classNames from "classnames/bind";
import s from "./Form.module.css";

const cx = classNames.bind(s);

type Props = {
  children?: React.ReactNode;
  layout?: "compact" | "fluid" | "standard" | "fixed";
} & React.ComponentPropsWithoutRef<"form">;

function Form({ children, layout = "standard", ...attrs }: Props) {
  const classnames = cx("root", layout);

  const result: React.ReactNode[] = [];
  React.Children.forEach(children, (item: React.ReactElement, key) => {
    if (item) {
      // eslint-disable-next-line react/no-array-index-key
      result.push(React.cloneElement(item, { layout, key }));
    }
  });

  return (
    <form className={classnames} noValidate {...attrs}>
      {result}
    </form>
  );
}

export default Form;
