// vendor
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
// mf-stelar
import Heading from "stelar/components/Heading";
import Button from "stelar/components/Button/Button";
import Paragraph from "stelar/components/Paragraph";
// ase
import s from "./LoginPrompt.module.css";

// TODO Textee übersetzen
const LoginPrompt = ({
  callToAction = "Bitte melden Sie sich erneut an, um fortfahren zu können.",
  hint = "Aus Sicherheitsgründen läuft Ihre Sitzung nach einiger Zeit aus.",
  heading = "Ihre Sitzung ist abgelaufen!",
  login: { link = "/login?target=/kundenkonto", label = "Anmelden" },
}) => (
  <div className={s.root}>
    <Heading prio1>{heading}</Heading>
    <Paragraph>
      {hint}
      {callToAction}
    </Paragraph>
    <Button className={s.button} href={link} text={label} />
  </div>
);

LoginPrompt.propTypes = {
  callToAction: PropTypes.string,
  heading: PropTypes.string,
  hint: PropTypes.string,
  login: PropTypes.objectOf(PropTypes.string),
};

export default LoginPrompt;
