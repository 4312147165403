const QUERY_PARAMETER = [
  "color",
  "category",
  "manufacturer",
  "availability",
  "size",
  "price",
  "material",
  "targetgroup",
];

export function isQueryAttributesEmpty(query) {
  let isAttributeEmpty = true;
  QUERY_PARAMETER.forEach((element) => {
    if (query[element] || query[element]?.length >= 1) {
      isAttributeEmpty = false;
    }
  });

  return isAttributeEmpty;
}
