import React from "react";
import IconArrowUp from "../Icon/IconArrowUp";
import s from "./ScrollTopButton.module.css";

type Props = {
  text?: string;
  onClick?: (...args: unknown[]) => unknown;
  onMouseEnter?: (...args: unknown[]) => unknown;
  onTouchStart?: (...args: unknown[]) => unknown;
  onMouseLeave?: (...args: unknown[]) => unknown;
  onTouchEnd?: (...args: unknown[]) => unknown;
};

function ScrollTopButton({
  onClick,
  onMouseEnter,
  onTouchStart,
  onMouseLeave,
  onTouchEnd,
  text,
}: Props) {
  return (
    <button
      className={s.root}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onTouchStart={onTouchStart}
      onMouseLeave={onMouseLeave}
      onTouchEnd={onTouchEnd}
      type="button"
    >
      <span className={s.content}>
        <span className={s.text}>{text}</span>{" "}
        <span className={s.icon}>
          <IconArrowUp small />
        </span>
      </span>
    </button>
  );
}

export default ScrollTopButton;
