import { validateCheckbox, validateInput } from "../../pages/utils/Validators";

export function validateEmail(state) {
  const newState = validateInput(state, {
    inputPath: "newsletterSubscription.email",
  });
  return newState;
}

export function validateDatenschutz(state) {
  const newState = validateCheckbox(state, {
    checkboxPath: "newsletterSubscription.datenschutz",
  });
  return newState;
}
