import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classNames from "classnames/bind";
import { renderWithProps } from "../util";
import s from "./BottomLevel.module.css";

const cx = classNames.bind(s);

function BottomLevel({ compact, children }) {
  return (
    <div className={cx("root", { compact, flyout: !compact })}>
      {renderWithProps(children, { compact })}
    </div>
  );
}

BottomLevel.propTypes = {
  compact: PropTypes.bool,
  children: PropTypes.node,
};

export default BottomLevel;
