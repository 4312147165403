import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import StaticSlider from "../SliderCore/StaticSlider";
import styles from "./LoadingProductSlider.module.css";

import { SIZE_FOUR, SIZE_HALF, SIZE_OVERLAY } from "./constants";

function LoadingProductSlider({ children, size }) {
  function sizeClassName(sizeName) {
    switch (sizeName) {
      case SIZE_HALF:
        return styles.half;
      case SIZE_FOUR:
        return styles.four;
      case SIZE_OVERLAY:
        return styles.overlay;
      default:
        return styles.full;
    }
  }

  return (
    <div className={sizeClassName(size)}>
      <StaticSlider styles={styles}>{children}</StaticSlider>
    </div>
  );
}

LoadingProductSlider.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf([SIZE_FOUR, SIZE_HALF, SIZE_OVERLAY]),
};

export default LoadingProductSlider;
