/* eslint-disable jsx-a11y/label-has-associated-control */
// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import Button from "stelar/components/Button/Button";
import Heading from "stelar/components/Heading";
// ase
import { useForm } from "react-hook-form";
import Address from "../Address";
// component
import s from "./AddressAlternative.module.css";
import { filterEmptyOrNeedlessFormValues } from "../../util/reduxFormUtils/filterEmptyOrNeedlessFormValues";

const AddressAlternative = ({
  alternative = {},
  editAddressAlternative,
  ...props
}) => {
  const { address, actionUrl, confirmation, edit, heading, values } =
    alternative;
  const { onSubmit, submitting } = props;

  const { handleSubmit } = useForm();

  const onHandleSubmit = (data) => {
    onSubmit(filterEmptyOrNeedlessFormValues({ values: data }));
  };

  const hiddenInputFields = values.map((value, index) => (
    <input
      // eslint-disable-next-line react/no-array-index-key
      key={`${value.name}${index}`}
      name={value.name}
      value={value.value}
      type="hidden"
    />
  ));

  return (
    <form
      action={actionUrl}
      className={s.form}
      method="POST"
      onSubmit={handleSubmit(onHandleSubmit)}
    >
      <div>
        {heading && <Heading prio3>{heading}</Heading>}
        {address && <Address address={address} />}
        {hiddenInputFields}
      </div>
      <div className={s.buttons}>
        {edit && (
          <Button
            className={s.button}
            disabled={submitting}
            name={edit.name}
            onClick={(event) => {
              event.preventDefault();
              editAddressAlternative({ actionUrl, values });
            }}
            prio2
            text={edit.label}
            type="submit"
            value={edit.value}
          />
        )}
        <br />
        {confirmation && (
          <Button
            className={s.button}
            disabled={submitting}
            name={confirmation.name}
            prio1={confirmation.callToAction}
            prio2={!confirmation.callToAction}
            text={confirmation.label}
            type="submit"
            value={confirmation.value}
          />
        )}
      </div>
    </form>
  );
};

AddressAlternative.propTypes = {
  alternative: PropTypes.object.isRequired,
  editAddressAlternative: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default AddressAlternative;
