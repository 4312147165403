function removeFootnotesInDOM() {
  (
    document.querySelector(
      "[data-test-sell-footer-footnotes-banner]"
    ) as HTMLElement
  ).style.display = "none";
}

function isFootnotesInDOM() {
  return (
    typeof document !== "undefined" &&
    document.querySelector("[data-test-sell-footer-footnotes-banner]")
  );
}

export function removeFootnotes() {
  if (isFootnotesInDOM()) {
    removeFootnotesInDOM();
  }
}
