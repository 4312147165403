import React from "react";

const helpers = {
  linebreaksToHtmlBrs(stringWithLinebreaks) {
    const linebreak = "\n";
    if (stringWithLinebreaks.indexOf(linebreak) < 0)
      return stringWithLinebreaks;

    return stringWithLinebreaks.split(linebreak).map((item, key) => (
      <span key={`span_${key + 1}`}>
        {item}
        <br />
      </span>
    ));
  },
};

export default helpers;
