/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "../common";

function IconGiftCard(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 28 28" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path d="M11.2 1.2c-2.4 0-4.4 2-4.4 4.4 0 2.4 2 4.4 4.4 4.4 1.3 0 4.4 0 4.4 0s0-3.2 0-4.4C15.6 3.2 13.6 1.2 11.2 1.2zM14.5 8.9c0 0-2.4 0-3.3 0 -1.8 0-3.3-1.5-3.3-3.3 0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3C14.5 6.5 14.5 8.9 14.5 8.9z" />
      <path d="M25.3 11.6h-6.1l4 3c0.3 0.3 0.3 0.8 0 1.1 -0.3 0.3-0.8 0.3-1.1 0l-4.8-3.9v15.1h8c1.1 0 2-0.9 2-2V13.6C27.3 12.5 26.4 11.6 25.3 11.6zM10.9 15.6c-0.3 0.3-0.8 0.3-1.1 0s-0.3-0.8 0-1.1l3.9-2.9h-11c-1.1 0-2 0.9-2 2v11.2c0 1.1 0.9 2 2 2h13v-15L10.9 15.6z" />
      <path d="M17.2 5.6c0 1.2 0 4.4 0 4.4s3.1 0 4.4 0C24 10 26 8 26 5.6c0-2.4-2-4.4-4.4-4.4S17.2 3.2 17.2 5.6zM18.3 5.6c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3c0 1.8-1.5 3.3-3.3 3.3 -0.9 0-3.3 0-3.3 0S18.3 6.5 18.3 5.6z" />
    </svg>
  );
}

export default IconGiftCard;
