import React from "react";
import Input from "./Input";

type Props = {
  autoComplete?: string;
  error?: boolean | string;
} & React.ComponentPropsWithoutRef<typeof Input>;

/**
 * InputHouseNumber
 */
function InputHouseNumber({
  autoComplete = "address-line2",
  error = "",
  ...attrs
}: Props) {
  return (
    <Input
      autoComplete={autoComplete}
      className="houseNumber"
      error={error}
      size={5}
      {...attrs}
    />
  );
}

export default InputHouseNumber;
