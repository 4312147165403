import { animateScrollTo } from "shared/utils/scrollTo";

const CONTAINER_NODE = "[data-test-sell-offcanvas-navigation]";
const ELEMENT_NODE = (element) => `[data-sell-navigation-id='${element}']`;
const DISTANCE_PIXEL = 35;

export function scrollInOffCanvas(pathArray) {
  if (typeof document === "undefined") {
    return;
  }

  const containerNode = document.querySelector(CONTAINER_NODE);

  if (pathArray?.length !== 0) {
    const element =
      pathArray.length >= 4 ? pathArray[2] : pathArray[pathArray.length - 1];
    const node =
      document.querySelector(ELEMENT_NODE(element))?.offsetTop || 100;

    const parentNode =
      document.querySelector(ELEMENT_NODE(element))?.parentElement.offsetTop ||
      0;

    const difference = node - parentNode >= 450 ? 200 : node - parentNode;
    const optionTo = node - difference - DISTANCE_PIXEL;

    if (containerNode && node) {
      animateScrollTo(containerNode, optionTo, 500);
    }
  } else {
    containerNode?.scrollTo(0, 0);
  }
}
