import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Text from "stelar/components/Text";
import s from "./ListElement.module.css";

const ListElement = ({ children }) => (
  <li className={s.element}>
    <Text normal className={s.text}>
      {children}
    </Text>
  </li>
);

ListElement.propTypes = {
  children: PropTypes.node,
};

ListElement.defaultProps = {};

export default ListElement;
