const initialState = {
  data: {
    heading: "",
    additionalCatalogs: {
      heading: "",
      shopCatalogs: [],
    },
    deliveryAddress: {
      updateFormURL: "",
    },
    submit: {},
  },
  virtual: {
    step: "form",
    skeleton: false,
  },
  formCache: {},
};

export default initialState;
