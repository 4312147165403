/* eslint-disable global-require */

/**
 * Add to your module:
 *
 * import logger from './../../util/log/logger';
 * const log = logger(module);
 *
 * There are four log levels:
 * log.debug(...);
 * log.info(...);
 * log.warn(...);
 * log.error(...);
 *
 * log[level](message:string, {additional fields})
 *   e.g. log.info('Login successful')
 *   e.g. log.debug('Changed page', { pageId, oldPageId })
 *
 * log[level](message:string, {request:Express Request})
 *   e.g. log.error('Unexpected error', { request })
 *
 * log[level](error:Error)
 *  e.g. log.error(new Error("this should not happen"))
 *
 */
if (process.env.APP_ENV === "browser") {
  module.exports = require("./clientLog");
} else {
  module.exports = require("./serverLog");
}
