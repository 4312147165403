const validateDecimal = (value) => value >= 0;

const validateInteger = (value) => {
  const intValue = parseInt(value, 10);
  return intValue >= 0 && Number(value) === intValue;
};

const validateQuantityField = (quantity, type) => {
  if (type === "decimal") {
    return validateDecimal(parseFloat(quantity));
  }
  if (type === "integer") {
    return validateInteger(quantity);
  }
  return false;
};

export default validateQuantityField;
