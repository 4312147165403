import { useEffect, useState } from "react";
import track from "../tracking";
import useIsInViewport from "./useIsInViewport";

export const useTrackWhenViewed = (tracking) => {
  const [promoViewTriggered, setPromoViewTriggered] = useState(false);
  const { ref, inViewport } = useIsInViewport();

  useEffect(() => {
    if (inViewport && !promoViewTriggered) {
      setPromoViewTriggered(true);
      track(tracking, { isPromoView: true });
    }
  }, [inViewport]);

  return { ref };
};
