import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import IconAccount from "stelar/components/Icon/IconAccount";
import IconEvent from "stelar/components/Icon/IconEvent";
import IconWarehouse from "stelar/components/Icon/IconWarehouse";
import IconFaq from "stelar/components/Icon/IconFaq";
import IconWishList from "stelar/components/Icon/IconWishList";
import IconGiftCard from "stelar/components/Icon/IconGiftCard";
import IconCatalog from "stelar/components/Icon/IconCatalog";
import IconDirectOrder from "stelar/components/Icon/IconDirectOrder";
import IconInfo from "stelar/components/Icon/IconInfo";
import IconNewsletter from "stelar/components/Icon/IconNewsletter";
import classNames from "classnames/bind";
import ListElement from "../ListElement";
import Headline from "../Headline";
import List from "../List";
import IconLink from "../IconLink";
import s from "./ServiceIconLinks.module.css";

const cx = classNames.bind(s);

function createListElement(element, icon, identifier) {
  if (element) {
    return (
      <ListElement>
        <span className={cx(s.identifier, identifier)}>
          <IconLink
            link={element.link}
            name={element.name}
            icon={icon}
            identifier={identifier}
          />
        </span>
      </ListElement>
    );
  }
  return null;
}

function ServiceIconLinks({
  headline,
  myAccount,
  wishlist,
  giftcards,
  catalog,
  directorder,
  warehouses,
  faq,
  events,
  disposal,
  newsletter,
}) {
  return (
    <div>
      <Headline>{headline}</Headline>
      <List>
        {createListElement(myAccount, <IconAccount />, "myAccount")}
        {createListElement(wishlist, <IconWishList />, "wishlist")}
        {createListElement(giftcards, <IconGiftCard />)}
        {createListElement(catalog, <IconCatalog />)}
        {createListElement(directorder, <IconDirectOrder />)}
        {createListElement(newsletter, <IconNewsletter />, "newsletter")}
        {createListElement(warehouses, <IconWarehouse />)}
        {createListElement(faq, <IconFaq />)}
        {createListElement(events, <IconEvent />)}
        {createListElement(disposal, <IconInfo />)}
      </List>
    </div>
  );
}

ServiceIconLinks.propTypes = {
  headline: PropTypes.string,
  myAccount: PropTypes.object,
  wishlist: PropTypes.object,
  giftcards: PropTypes.object,
  catalog: PropTypes.object,
  directorder: PropTypes.object,
  warehouses: PropTypes.object,
  faq: PropTypes.object,
  events: PropTypes.object,
  disposal: PropTypes.object,
};

export default ServiceIconLinks;
