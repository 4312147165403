import React from "react";
import classNames from "classnames/bind";
import s from "./Button.module.css";

type Props = {
  disabled?: boolean;
  href?: string;
  large?: boolean;
  onClick?: (...args: unknown[]) => unknown;
  prio1?: boolean;
  prio2?: boolean;
  prio3?: boolean;
  prio4?: boolean;
  prio5?: boolean;
  inverted?: boolean;
  text: string;
  className?: string;
  skeleton?: boolean;
};

function Button({
  disabled,
  href,
  large,
  onClick,
  prio1,
  prio2,
  prio3,
  prio4,
  prio5,
  inverted,
  text,
  className,
  skeleton,
  ...attrs
}: Props) {
  const cx = classNames.bind(s);
  let rootClass = cx("root", {
    disabled,
    large,
    prio1: prio1 || !(prio2 || prio3 || prio4 || prio5),
    prio2,
    prio3,
    prio4,
    prio5,
    inverted,
    regular: !inverted,
    skeleton,
  });
  rootClass = cx(rootClass, className);

  const htmlHref = disabled ? null : href;

  let buttoncontent = (
    <span className={s.content}>
      <span className={s.center}>{text}</span>
    </span>
  );

  buttoncontent = !skeleton ? (
    buttoncontent
  ) : (
    <span className={s.skeleton}>{buttoncontent}</span>
  );

  let htmlTag = "button";
  if (href) {
    htmlTag = disabled ? "span" : "a";
  }

  return React.createElement(
    htmlTag,
    {
      ...attrs,
      className: rootClass,
      disabled,
      href: htmlHref,
      onClick,
      role: href ? "button" : null,
    },
    buttoncontent
  );
}

Button.defaultProps = {
  disabled: false,
  href: null,
  large: false,
  prio1: false,
  prio2: false,
  prio3: false,
  prio4: false,
  prio5: false,
};

export default Button;
