import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export const CLIENTSIDE_LOG_SERVICE_PATH = "/el/";

function deleteColorCodes(s) {
  // eslint-disable-next-line no-control-regex
  return s.replace(/\u001b\[.*?m/g, "");
}

function fixMessage(message) {
  if (typeof message === "string") {
    return deleteColorCodes(message).trim();
  }
  return message;
}

const sentMessages = [];

export default async function sendLogEntry({ url, payload, config = {} }) {
  try {
    const rawPayload = { ...payload };
    if (payload?.message?.length !== 0) {
      rawPayload.message = fixMessage(payload.message);
    }
    const serializedRawPayload = JSON.stringify(rawPayload);
    if (sentMessages.includes(serializedRawPayload)) {
      return;
    }
    if (sentMessages.length > 10) {
      sentMessages.shift();
    }
    sentMessages.push(serializedRawPayload);

    const finalPayload = { ...rawPayload, id: uuidv4(), timestamp: Date.now() };
    await axios.post(url, JSON.stringify(finalPayload), {
      maxRedirects: 0,
      headers: {
        ...config.headers,
        "Content-Type": "application/json",
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      `ERROR: Sending log entry to '${url}' has failed!`,
      e.message
    );
  }
}
