import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import { Button } from "stelar/components/Button";
import ContentHeading from "stelar/components/ContentHeading";
import CategoryHeading from "stelar/components/CategoryHeading";
import Paragraph from "stelar/components/Paragraph";
import { IMAGE_PROFILE_DEFAULTS } from "shared/utils/imageUrlBuilder";
import { useTrackWhenViewed } from "shared/utils/useTrackWhenViewed";
import RatioImage from "../../RatioImage";
import s from "./ContentTeaser.module.css";

const cx = classnames.bind(s);

function ContentTeaser({
  customStyle = "",
  heading,
  topic,
  text,
  image = {},
  link,
  lazy,
  imgSizes,
  variant = "stacked",
  onLinkClick,
  onImageLoad,
  loadedImages = {},
  tracking,
}) {
  const { ref } = useTrackWhenViewed(tracking);

  const imageNode = (
    <RatioImage
      image={image}
      imageProfileDefault={IMAGE_PROFILE_DEFAULTS.kuratorteaser}
      lazy={lazy}
      sizes={imgSizes}
      onImageLoad={onImageLoad}
      hasLoaded={loadedImages[image.pattern]}
      data-test-sell-teaser-image
    />
  );

  function onClick(e) {
    onLinkClick(e, tracking);
  }

  return (
    <div
      ref={ref}
      className={cx("root", { [variant]: true })}
      data-test-sell-teaser
    >
      {link ? (
        <a
          href={link.link}
          onClick={onClick}
          className={s.image}
          tabIndex={-1}
          aria-hidden
        >
          {imageNode}
        </a>
      ) : (
        <div className={s.image}>{imageNode}</div>
      )}
      <div className={s.text}>
        {heading && (
          <div className={s.title}>
            {topic && (
              <CategoryHeading prio3 data-test-sell-teaser-title>
                {topic}
              </CategoryHeading>
            )}
            <div
              className={cx("hyphens", {
                clamp: customStyle !== "quadTeaser",
              })}
            >
              <ContentHeading
                prio3
                tag="h3"
                data-test-sell-teaser-name
                {...(customStyle === "quadTeaser" && { compact: true })}
              >
                {link ? (
                  <a href={link.link} data-prio2>
                    {heading}
                  </a>
                ) : (
                  heading
                )}
              </ContentHeading>
            </div>
          </div>
        )}
        {text && <Paragraph className={s.paragraph}>{text}</Paragraph>}
        {link && link.text && (
          <div className={cx("button", customStyle)}>
            <span>
              <Button
                prio3
                text={link.text}
                href={link.link}
                onClick={onClick}
                tabIndex={-1}
                data-test-sell-teaser-cta
              />
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

ContentTeaser.propTypes = {
  customStyle: PropTypes.string,
  heading: PropTypes.string,
  topic: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.object,
  link: PropTypes.object,
  imgSizes: PropTypes.string,
  lazy: PropTypes.bool,
  variant: PropTypes.oneOf(["stacked", "side-by-side"]),
  onLinkClick: PropTypes.func,
  onImageLoad: PropTypes.func,
  loadedImages: PropTypes.object,
  tracking: PropTypes.object,
};

export default ContentTeaser;
