import merge from "lodash.merge";
import { connect } from "react-redux";
import { provideHooks } from "redial";
import track from "shared/tracking";
import loadable from "@loadable/component";
import Loading from "shared/components/Loading";
// ase
import frontendUrls from "../utils/AseFrontendUrls";
import {
  loadOrderHistoryPage,
  loadShipmentTracking,
  requestReturnVoucherByEmail,
  requestShipmentLabelByEmail,
} from "./actions";

const redial = {
  fetch: ({ apiClient, dispatch, query }) =>
    dispatch(loadOrderHistoryPage(apiClient, query)),
  defer: ({ getState }) =>
    track(getState()?.orderHistoryPage?.data?.page?.trackingData),
};

const mapDispatchToProps = (dispatch, { apiClient }) => ({
  orderHistory: {
    loadShipmentTracking: (open, parcelNumbers) =>
      dispatch(loadShipmentTracking(apiClient, open, parcelNumbers)),
    requestReturnVoucherByEmail: (deliveryNumber) =>
      dispatch(requestReturnVoucherByEmail(apiClient, deliveryNumber)),
    requestShipmentLabelByEmail: (deliveryNumber) =>
      dispatch(requestShipmentLabelByEmail(apiClient, deliveryNumber)),
  },
});

const mapStateToProps = (state) =>
  merge({}, state.orderHistoryPage.data, state.orderHistoryPage.virtual, {
    isLoading: state.orderHistoryPage.data.pageTitle === "",
  });

const connector = connect(mapStateToProps, mapDispatchToProps, merge);

const LoadableOrderHistoryPage = loadable(
  () =>
    import(
      /* webpackChunkName: "orderhistory-page" */ "./components/OrderHistoryPage"
    ),
  {
    fallback: Loading,
  }
);

export default {
  path: frontendUrls.ORDER_HISTORY,
  component: provideHooks(redial)(connector(LoadableOrderHistoryPage)),
};
