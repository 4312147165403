import track from "shared/tracking";
import { connect } from "react-redux";
import { provideHooks } from "redial";
import loadable from "@loadable/component";
import Loading from "shared/components/Loading";
import { loadNotFound } from "./actions";
import navigationActions from "../../utils/uiState/navigation/actions";
import { getSliderActions } from "../../utils/uiState/sliders/actions";
import {
  closeOrOpenContentAccordionItem,
  imageLoaded,
  setTrackingListType,
} from "../../utils/uiState/actions";
import { openCookieBanner } from "../../fragments/sell-cookie-banner/actions";

const { updateCurrent, compactTogglePath } = navigationActions();

const redial = {
  fetch: ({ dispatch, apiClient, cookies }) =>
    dispatch(loadNotFound(apiClient, cookies)),
  done: ({ dispatch, getState }) => {
    dispatch(updateCurrent());
    track(getState().notFound.tracking);
    dispatch(openCookieBanner());
    dispatch(compactTogglePath());
  },
};

function mapDispatchToProps(dispatch) {
  return {
    onImageLoad: (src) => dispatch(imageLoaded(src)),
    sliderActions: getSliderActions(dispatch),
    closeOrOpenContentAccordionItem: (contentAccordionItem) =>
      dispatch(closeOrOpenContentAccordionItem(contentAccordionItem)),
    setTrackingListType: (trackingData) =>
      dispatch(setTrackingListType(trackingData)),
  };
}

function mapStateToProps(state) {
  const { loadedImages, sliders, openedContentAccordionItems } = state.uiState;
  const { loadedSliders, sliderPositions } = sliders;
  const { notFound } = state;

  return {
    ...notFound,
    loadedImages,
    sliderState: {
      loadedSliders,
      sliderPositions,
    },
    openedContentAccordionItems,
  };
}

const LoadableNotFound = loadable(
  () =>
    import(/* webpackChunkName: "not-found-page" */ "./components/NotFound"),
  {
    fallback: Loading,
  }
);

export default provideHooks(redial)(
  connect(mapStateToProps, mapDispatchToProps)(LoadableNotFound)
);
