/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconDirectOrder(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 28 28" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <polygon points="17.8 3.1 17.8 8.6 23.3 8.6 " />
      <path d="M16.5 9.9V2.6H6.3c-1.1 0-2.1 0.9-2.1 2.1v18.6c0 1.1 0.9 2.1 2.1 2.1h15.5c1.1 0 2.1-0.9 2.1-2.1V9.9H16.5zM20.2 18.8H7.7v-1.7h12.5V18.8zM20.2 15.2H7.7v-1.7h12.5V15.2z" />
    </svg>
  );
}

export default IconDirectOrder;
