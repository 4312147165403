import React from "react";

import s from "./ProductImage.module.css";

const trans =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

type Props = {
  src?: string;
  srcSet?: string;
  previewSrc?: string;
  sizes?: string;
  alt?: string;
};

function ProductImage({ src, srcSet, previewSrc, sizes, alt, ...args }: Props) {
  return (
    <div className={s.root}>
      <div className={s.wrap}>
        <div
          className={
            alt && alt.includes("videoPlayButton")
              ? s.videoPlayButton
              : s.imageSkeleton
          }
        />
        <img
          className={s.imagePreview}
          src={previewSrc || trans}
          role="presentation"
          alt=""
        />
        <img
          {...args}
          className={s.image}
          src={src || trans}
          srcSet={srcSet}
          alt={alt || ""}
          sizes={sizes}
          role={src ? null : "presentation"}
        />
      </div>
    </div>
  );
}

export default ProductImage;
