import { setPageTitle } from "shared/webbridge/actions";
import { GLOBAL_STATUS_REDIRECT } from "shared/utils/status/constants";
import backendUrl from "../utils/AseBackendUrls";
import {
  BASKET_PAGE__ADD_SHOP_CATALOG,
  BASKET_PAGE__DELETE_GUTSCHEIN_ITEM,
  BASKET_PAGE__DELETE_LINE_ITEM,
  BASKET_PAGE__LOAD_BASKET,
  BASKET_PAGE__NAVIGATE_WHEN_UPDATED,
  BASKET_PAGE__SHOW_MESSAGE,
  BASKET_PAGE__TOGGLE_AGE_RELATED_ADVICE,
  BASKET_PAGE__UPDATE_LINE_ITEM,
  BASKET_PAGE__UPDATE_LINE_ITEM_EEG_RETURN,
  BASKET_PAGE__UPDATE_LINE_ITEM_FAILED,
  BASKET_PAGE__UPDATE_LINE_ITEM_QUANTITY,
} from "../constants";
import {
  triggerBasketChangeEvent,
  triggerWishlistChangeEvent,
} from "../utils/BrowserEvents";
import { openPage } from "../utils/Routing";

export function loadBasketPage(apiClient, query) {
  return apiClient.get(
    backendUrl.BASKET,
    {
      ok: (content) => [
        {
          type: BASKET_PAGE__LOAD_BASKET,
          payload: content.body,
        },
        setPageTitle(content.body?.page?.title),
      ],
    },
    {
      httpClientOptions: {
        params: query,
      },
      handleUnexpected: () => ({
        type: GLOBAL_STATUS_REDIRECT,
        payload: {
          code: 303,
          location: "/500.html",
        },
      }),
    }
  );
}

export function deleteLineItem(apiClient, deleteUrl) {
  return apiClient.post(deleteUrl, null, {
    noContent: () => [
      {
        type: BASKET_PAGE__DELETE_LINE_ITEM,
      },
      loadBasketPage(apiClient),
      triggerBasketChangeEvent(),
    ],
    ok: () => [
      {
        type: BASKET_PAGE__DELETE_LINE_ITEM,
      },
      loadBasketPage(apiClient),
      triggerBasketChangeEvent(),
    ],
  });
}

export function deleteGutscheinItem(apiClient, deleteUrl) {
  return apiClient.post(deleteUrl, null, {
    noContent: () => [
      {
        type: BASKET_PAGE__DELETE_GUTSCHEIN_ITEM,
      },
      loadBasketPage(apiClient),
      triggerBasketChangeEvent(),
    ],
    ok: () => [
      {
        type: BASKET_PAGE__DELETE_GUTSCHEIN_ITEM,
      },
      loadBasketPage(apiClient),
      triggerBasketChangeEvent(),
    ],
  });
}

export function addCatalogToBasket({ apiClient, values, submitLink }) {
  return apiClient.post(submitLink, values, {
    created: () => [
      {
        type: BASKET_PAGE__ADD_SHOP_CATALOG,
      },
      triggerBasketChangeEvent(),
    ],
  });
}

export function toggleAgeRelatedAdvice(lineItemId) {
  return {
    type: BASKET_PAGE__TOGGLE_AGE_RELATED_ADVICE,
    lineItemId,
  };
}

export function updateLineItem(
  apiClient,
  lineItemId,
  quantity,
  routes,
  isBlur
) {
  return (dispatch, getState) => {
    const optionalNavigate = () => {
      const state = getState();
      const link = state.basketPage.data?.page?.submitButton?.link;
      const validQuantities = state.basketPage.data?.basket?.lineItems?.some(
        (lineItem) => !lineItem.quantity?.hasChanged || lineItem.quantity?.valid
      );
      return isBlur &&
        link &&
        state.basketPage.data?.navigateWhenUpdated &&
        validQuantities
        ? openPage(routes, link)
        : null;
    };

    const putData = { quantity };

    return dispatch(
      apiClient.put(backendUrl.lineItem(lineItemId), putData, {
        ok: () => [
          {
            type: BASKET_PAGE__UPDATE_LINE_ITEM,
          },
          loadBasketPage(apiClient),
          triggerBasketChangeEvent(),
          optionalNavigate(),
        ],
        badRequest: (response) => ({
          type: BASKET_PAGE__UPDATE_LINE_ITEM_FAILED,
          payload: { lineItemId, quantity, ...response.body },
        }),
      })
    );
  };
}

export function updateQuantityOfLineItem(quantity, fieldType, lineItemId) {
  return {
    type: BASKET_PAGE__UPDATE_LINE_ITEM_QUANTITY,
    quantity,
    lineItemId,
    fieldType,
  };
}

export function updateEegReturn(apiClient, changeUrl) {
  return apiClient.put(changeUrl, null, {
    noContent: () => [
      {
        type: BASKET_PAGE__UPDATE_LINE_ITEM_EEG_RETURN,
      },
      loadBasketPage(apiClient),
      triggerBasketChangeEvent(),
    ],
  });
}

function showMessage(message) {
  return {
    type: BASKET_PAGE__SHOW_MESSAGE,
    message,
  };
}

export function moveToWishList(apiClient, link) {
  const postData = {};
  return apiClient.post(link, postData, {
    ok: (content) => [
      apiClient.get(backendUrl.BASKET, {
        ok: (result) => [
          {
            type: BASKET_PAGE__LOAD_BASKET,
            payload: result.body,
          },
          showMessage(content.body.globalMessage || content.body),
        ],
      }),
      triggerBasketChangeEvent(),
      triggerWishlistChangeEvent(),
    ],
  });
}

export function navigateWhenUpdated() {
  return {
    type: BASKET_PAGE__NAVIGATE_WHEN_UPDATED,
  };
}
