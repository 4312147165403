import { Base64 } from "js-base64";
import { getCookieValue } from "./cookie";

const OPTIN_TRACKING = "mf-tracking-optin";
const isOptinVersion5 = false;

export function isOptinBannerRequired(cookies) {
  if (cookies) {
    const data = getCookieValue(OPTIN_TRACKING, cookies);

    try {
      if (data) {
        const cookieContent = Base64.decode(data);
        if (cookieContent) {
          const cookieJson = JSON.parse(cookieContent);
          if (cookieJson.version) {
            if (isOptinVersion5) {
              return cookieJson.version < 5;
            }
            return cookieJson.version < 4;
          }
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("error evaluating optin cookie ", e);
    }
  }

  return true;
}
