/* eslint-disable no-param-reassign */
import backendUrl from "../../pages/utils/AseBackendUrls";
import {
  CATALOG_ORDER_FRAGMENT__CACHE_FORM_CLEAR,
  CATALOG_ORDER_FRAGMENT__CACHE_FORM_VALUE,
  CATALOG_ORDER_FRAGMENT__LOAD_DATA,
  CATALOG_ORDER_FRAGMENT__ORDER_CATALOGS,
  CATALOG_ORDER_FRAGMENT__SHOW_CONFIRMATION,
} from "../../pages/constants";

export function loadCatalogOrderFragmentData(apiClient, catalogId) {
  const catalogIdAppendix = catalogId ? `/${catalogId}` : "";
  return apiClient.get(`${backendUrl.CATALOG_ORDER}${catalogIdAppendix}`, {
    ok: (content) => [
      {
        type: CATALOG_ORDER_FRAGMENT__LOAD_DATA,
        payload: content.body,
      },
    ],
  });
}

export function cacheFormValues({ values }) {
  return {
    type: CATALOG_ORDER_FRAGMENT__CACHE_FORM_VALUE,
    values,
  };
}

export function submitCatalogOrder(
  { link, values, defaultCatalog },
  apiClient
) {
  values.selectedCatalogs = values.selectedCatalogs || [];
  if (defaultCatalog) {
    values.selectedCatalogs.push(defaultCatalog);
  }

  values.selectedCatalogs = values.selectedCatalogs.filter(
    (item) => item !== ""
  );

  return apiClient.post(link, values, {
    ok: (content) => [
      {
        type: CATALOG_ORDER_FRAGMENT__SHOW_CONFIRMATION,
        payload: content.body,
      },
      {
        type: CATALOG_ORDER_FRAGMENT__CACHE_FORM_CLEAR,
      },
    ],
    badRequest: (content) => ({
      type: CATALOG_ORDER_FRAGMENT__ORDER_CATALOGS,
      payload: content.body,
    }),
  });
}

export function confirmAddressAlternative({ link, values }, apiClient) {
  const valuesArray = values.slice();
  valuesArray.push({ name: "action", value: "CONFIRMATION" });
  const valuesDict = valuesArray.reduce((result, item) => {
    const key = item.name;
    // eslint-disable-next-line no-param-reassign
    result[key] = item.value;
    return result;
  }, {});
  return apiClient.post(link, valuesDict, {
    ok: (content) => [
      {
        type: CATALOG_ORDER_FRAGMENT__SHOW_CONFIRMATION,
        payload: content.body,
      },
      {
        type: CATALOG_ORDER_FRAGMENT__CACHE_FORM_CLEAR,
      },
    ],
    badRequest: (content) => [
      {
        type: CATALOG_ORDER_FRAGMENT__ORDER_CATALOGS,
        payload: content.body,
      },
    ],
  });
}

export function editAddressAlternative({ link, values }, apiClient) {
  const valuesArray = values.slice();
  valuesArray.push({ name: "action", value: "EDIT" });
  const valuesDict = valuesArray.reduce((result, item) => {
    const key = item.name;
    // eslint-disable-next-line no-param-reassign
    result[key] = item.value;
    return result;
  }, {});
  return apiClient.post(link, valuesDict, {
    ok: (content) => [
      {
        type: CATALOG_ORDER_FRAGMENT__SHOW_CONFIRMATION,
        payload: content.body,
      },
      {
        type: CATALOG_ORDER_FRAGMENT__CACHE_FORM_CLEAR,
      },
    ],
    badRequest: (content) => [
      {
        type: CATALOG_ORDER_FRAGMENT__ORDER_CATALOGS,
        payload: content.body,
      },
    ],
  });
}
