import PropTypes from "prop-types";
// vendor
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import Heading from "stelar/components/Heading";
import IconPhone from "stelar/components/Icon/IconPhone";
import Logo from "stelar/components/Logo";
import Text from "stelar/components/Text";
// ase
import s from "./Header.module.css";

function Header({ isLoading, serviceLabel, serviceLink, servicePhoneNumber }) {
  const serviceLinkContainer = serviceLink ? (
    <a href={serviceLink}>
      <Heading
        data-test-ase-service-phone-number
        inline
        prio2
        skeleton={isLoading}
        tag="span"
      >
        {servicePhoneNumber}
      </Heading>
    </a>
  ) : (
    <Heading
      data-test-ase-service-phone-number
      inline
      prio2
      skeleton={isLoading}
      tag="span"
    >
      {servicePhoneNumber}
    </Heading>
  );

  return (
    <header className={s.root} data-test-ase-header-reduced>
      <div className={s.limiter}>
        <div className={s.logoContainer}>
          {/* TODO a11y */}
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <a href="/">
            <Logo />
          </a>
        </div>
        <div className={s.telNumberContainer}>
          <span className={s.telNumberIcon}>
            <IconPhone small />
          </span>{" "}
          <Text className={s.telNumberKey} normal skeleton={isLoading}>
            {serviceLabel}:{" "}
          </Text>
          {serviceLinkContainer}
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  isLoading: PropTypes.bool,
  serviceLabel: PropTypes.string,
  serviceLink: PropTypes.string,
  servicePhoneNumber: PropTypes.string,
};

export default Header;
