import MurmurHash3 from "imurmurhash";
import update from "immutability-helper";
import {
  getParsedObjectFromCookie,
  updateCookieValue,
} from "shared/utils/cookie";

const BANNER_CAPPING_AMOUNT = 4;

const BANNER_COOKIE = "sell-banner";
const PERMA_BANNER_COOKIE = "sell-banner-perm";

const BANNER_TYPE = {
  STICKY: "sticky",
  TOP: "top",
  APP_INSTALL: "app_install",
};

const config = {
  [BANNER_TYPE.STICKY]: {
    initialValue: { count: 0, visible: true },
    containedInCookie: BANNER_COOKIE,
  },
  [BANNER_TYPE.TOP]: {
    initialValue: { count: 0, visible: true, labelHash: null },
    containedInCookie: BANNER_COOKIE,
  },
  [BANNER_TYPE.APP_INSTALL]: {
    initialValue: { closedTimestamp: null, closedCount: 0 },
    containedInCookie: PERMA_BANNER_COOKIE,
    recurringAfterDays: [1, 2, 5],
  },
};

function getInitialValues(cookieName) {
  return Object.fromEntries(
    Object.entries(config).reduce((acc, [bannerType, value]) => {
      if (value.containedInCookie === cookieName) {
        return [...acc, [bannerType, value.initialValue]];
      }
      return acc;
    }, [])
  );
}

function isAppearanceOverdue(closedDate, closedCount) {
  const appearanceOverdue =
    new Date() >
    closedDate.setDate(
      closedDate.getDate() +
        config[BANNER_TYPE.APP_INSTALL].recurringAfterDays[closedCount - 1]
    );
  return appearanceOverdue;
}

function updateBannerRelatedCookieValue(cookieName, newValue) {
  let expirationDate;
  if (cookieName === PERMA_BANNER_COOKIE) {
    expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 90 * 24 * 60 * 60 * 1000); // 90 days
  }
  updateCookieValue(cookieName, newValue, expirationDate);
}

function setBannerProperties(bannerType, properties) {
  const cookieName = config[bannerType].containedInCookie;
  const cookieValue = getParsedObjectFromCookie(cookieName);
  let newCookieValue;
  try {
    if (!cookieValue?.[bannerType]) {
      throw new Error();
    }
    const newProperties = Object.entries(properties).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: { $set: value } }),
      []
    );
    newCookieValue = update(newCookieValue || cookieValue, {
      [bannerType]: newProperties,
    });
  } catch (e) {
    newCookieValue = getInitialValues(cookieName);
  }
  updateBannerRelatedCookieValue(cookieName, newCookieValue);
}

function bannerCookieExisting(cookieValue) {
  return cookieValue?.[BANNER_TYPE.TOP]?.count >= 0;
}

export function stickyBannerVisible(cookies) {
  const cookieValue = getParsedObjectFromCookie(BANNER_COOKIE, cookies);
  const bannerType = BANNER_TYPE.STICKY;
  if (bannerCookieExisting(cookieValue)) {
    return cookieValue[bannerType]?.visible;
  }
  const initialValues = getInitialValues(config[bannerType].containedInCookie);
  updateBannerRelatedCookieValue(BANNER_COOKIE, initialValues);
  return initialValues[bannerType].visible;
}

export function setStickyBannerVisible(visible) {
  setBannerProperties(BANNER_TYPE.STICKY, { visible });
}

export function increaseStickyBannerShownCount() {
  const cookieValue = getParsedObjectFromCookie(BANNER_COOKIE);
  if (!cookieValue) {
    return;
  }
  const count = cookieValue[BANNER_TYPE.STICKY]?.count;
  if (count === BANNER_CAPPING_AMOUNT - 2) {
    setBannerProperties(BANNER_TYPE.STICKY, {
      count: count + 1,
      visible: false,
    });
  } else {
    setBannerProperties(BANNER_TYPE.STICKY, { count: count + 1 });
  }
}

export function topBannerVisible(cookies, bannerLabel) {
  const cookieValue = getParsedObjectFromCookie(BANNER_COOKIE, cookies);
  const bannerType = BANNER_TYPE.TOP;
  const newLabelHash = MurmurHash3(bannerLabel)?.result();

  if (!bannerCookieExisting(cookieValue)) {
    const initialValues = getInitialValues(
      config[bannerType].containedInCookie
    );
    initialValues.labelHash = newLabelHash;
    updateBannerRelatedCookieValue(BANNER_COOKIE, initialValues);
    return initialValues[bannerType].visible;
  }

  const labelHashesEqual = newLabelHash === cookieValue[bannerType].labelHash;
  if (cookieValue[bannerType]?.visible) {
    setBannerProperties(BANNER_TYPE.TOP, {
      labelHash: newLabelHash,
    });
    return true;
  }

  setBannerProperties(BANNER_TYPE.TOP, {
    visible: !labelHashesEqual,
    labelHash: newLabelHash,
  });
  return !labelHashesEqual;
}

export function setTopBannerVisible(visible) {
  setBannerProperties(BANNER_TYPE.TOP, { visible });
}

export function appInstallBannerVisible(cookies) {
  const cookieValue = getParsedObjectFromCookie(PERMA_BANNER_COOKIE, cookies);
  const closedTimestamp =
    cookieValue?.[BANNER_TYPE.APP_INSTALL]?.closedTimestamp;
  const closedCount = cookieValue?.[BANNER_TYPE.APP_INSTALL]?.closedCount;

  if (closedTimestamp === null) {
    return true;
  }
  const closedDate = new Date(closedTimestamp);
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(closedDate) && closedCount >= 0) {
    return isAppearanceOverdue(closedDate, closedCount);
  }

  const initialValues = getInitialValues(
    config[BANNER_TYPE.APP_INSTALL].containedInCookie
  );
  updateBannerRelatedCookieValue(PERMA_BANNER_COOKIE, initialValues);
  return true;
}

export function closeAppInstallBanner(cookies) {
  const cookieValue = getParsedObjectFromCookie(PERMA_BANNER_COOKIE, cookies);
  if (!cookieValue) {
    return;
  }

  const closedCount = cookieValue[BANNER_TYPE.APP_INSTALL]?.closedCount;
  const isCycleReset =
    closedCount + 1 > config[BANNER_TYPE.APP_INSTALL].recurringAfterDays.length;

  let newClosedCount;
  if (isCycleReset) {
    newClosedCount = 1;
  } else {
    newClosedCount = closedCount + 1;
  }

  const newState = {
    closedTimestamp: new Date().toUTCString(),
    closedCount: newClosedCount,
  };
  setBannerProperties(BANNER_TYPE.APP_INSTALL, newState);
  return newState;
}
