import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { findDOMNode } from "react-dom"; // eslint-disable-line import/no-extraneous-dependencies
import classNames from "classnames/bind";
import { animateScrollTo } from "shared/utils/scrollTo";
import Navigation from "./Navigation";
import TopLevel from "./TopLevel";
import TopLevelItem from "./TopLevelItem";
import MiddleLevel from "./MiddleLevel";
import MiddleLevelItem from "./MiddleLevelItem";

import s from "./Compact.module.css";
import BottomLevel from "./BottomLevel";
import BottomLevelItem from "./BottomLevelItem";

const cx = classNames.bind(s);

class Compact extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { structure, offCanvasPath, countryToggle } = this.props;
    const structureChanged = structure !== nextProps.structure;
    const clickedPathChanged = offCanvasPath !== nextProps.offCanvasPath;
    const toggleChanged = countryToggle !== nextProps.countryToggle;
    return structureChanged || clickedPathChanged || toggleChanged;
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  onHandleClick(e, level, id, children) {
    if (e.metaKey || e.ctrlKey) {
      return null;
    }
    e.preventDefault();
    const hasChildren = children && children.length > 0;
    const path = e.currentTarget.getAttribute("href");
    const { onNavigate, onCloseOffCanvas, onToggleLevelOffCanvas } = this.props;

    function handleScroll(_id) {
      const i = document.querySelector(
        `[data-sell-navigation-id='${_id}']`
      ).offsetTop;
      document
        .querySelector(`[data-test-sell-offcanvas-navigation]`)
        .scrollTo(0, i);
    }

    if (window.location.pathname !== path) {
      if (hasChildren) {
        onToggleLevelOffCanvas(level, id);
        onNavigate(path, hasChildren);
        this.scrollToElement(id, null, () => handleScroll(id));
      } else {
        onToggleLevelOffCanvas(level, id);
        onCloseOffCanvas();
        onNavigate(path, hasChildren);
      }
    } else {
      onToggleLevelOffCanvas(level, id);
    }
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  scrollToElement(id, callback, handleScroll) {
    const scrollOffset = 100;
    // eslint-disable-next-line react/no-find-dom-node
    const node = findDOMNode(this);
    if (!node || typeof node.getBoundingClientRect !== "function") {
      return;
    }
    const nodeTop = node.getBoundingClientRect().top;
    const target = node.querySelector(`[data-sell-navigation-id='${id}']`);

    if (!target) {
      return;
    }
    const to =
      node.scrollTop +
      target.getBoundingClientRect().top -
      nodeTop -
      scrollOffset;

    let scrollToValue;

    if (to < 450) {
      scrollToValue = to;
    } else if (to >= 450 && to < 600) {
      scrollToValue = 350;
    } else {
      scrollToValue = to;
    }

    animateScrollTo(node, scrollToValue, 200, callback, handleScroll);
  }

  render() {
    const {
      structure,
      offCanvasPath,
      country,
      countryToggle,
      aseOffCanvasItems,
    } = this.props;
    const self = this;

    function createMiddleLevel(items, level, nextComponent) {
      return items.length === 0 ? null : (
        <MiddleLevel level={level}>
          {items.map(({ id, name, link, children }) => {
            if (children?.length > 0) {
              return (
                <MiddleLevelItem
                  key={id}
                  data-sell-navigation-id={id}
                  {...{
                    [`data-test-sell-offcanvas-link-level-${level}`]: true,
                  }}
                  name={name}
                  link={link}
                  level={level}
                  open={id === offCanvasPath[level - 1]}
                  onClick={(e) =>
                    self.onHandleClick(e, level - 1, id, children)
                  }
                >
                  {nextComponent(children)}
                </MiddleLevelItem>
              );
            }
            return (
              <BottomLevelItem
                key={id}
                data-sell-navigation-id={id}
                {...{
                  [`data-test-sell-offcanvas-link-level-${level}`]: true,
                }}
                name={name}
                link={link}
                open={id === offCanvasPath[level - 1]}
                onClick={(e) => self.onHandleClick(e, level - 1, id)}
              />
            );
          })}
        </MiddleLevel>
      );
    }

    function fourthLevel(items = []) {
      return items.length === 0 ? null : (
        <BottomLevel>
          {items.map(({ id, name, link }) => (
            <BottomLevelItem
              key={id}
              data-sell-navigation-id={id}
              data-test-sell-offcanvas-link-level-4
              name={name}
              link={link}
              open={id === offCanvasPath[3]}
              onClick={(e) => self.onHandleClick(e, 3, id)}
            />
          ))}
        </BottomLevel>
      );
    }

    function thirdLevel(items = []) {
      return createMiddleLevel(items, 3, fourthLevel);
    }

    function secondLevel(items = []) {
      return createMiddleLevel(items, 2, thirdLevel);
    }

    return (
      <Navigation
        compact
        className={cx("root")}
        data-test-sell-offcanvas-navigation
        countryToggle={countryToggle}
        country={country}
        aseOffCanvasItems={aseOffCanvasItems}
      >
        <TopLevel>
          {structure.map(({ id, name, link, children, navigationGroup }) => (
            <TopLevelItem
              key={id}
              data-sell-navigation-id={id}
              data-test-sell-offcanvas-toplevellink
              name={name}
              link={link}
              open={id === offCanvasPath[0]}
              onClick={(e) => self.onHandleClick(e, 0, id, children)}
              isServicePage={navigationGroup === "SERVICE_PAGES"}
            >
              {secondLevel(children)}
            </TopLevelItem>
          ))}
        </TopLevel>
      </Navigation>
    );
  }
}

Compact.propTypes = {
  structure: PropTypes.array,
  offCanvasPath: PropTypes.array,
  onToggleLevelOffCanvas: PropTypes.func,
  onNavigate: PropTypes.func,
  onCloseOffCanvas: PropTypes.func,
  country: PropTypes.object,
  countryToggle: PropTypes.bool,
  aseOffCanvasItems: PropTypes.object,
};

Compact.defaultProps = {
  onNavigate: () => {},
  onCloseOffCanvas: () => {},
  offCanvasPath: [],
  aseOffCanvasItems: null,
};

export default Compact;
