import React, { useEffect, useState } from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import SlickSlider from "mf-react-slick";
import classNames from "classnames/bind";
import s from "./Slider.module.css";

// import loadable from "@loadable/component";
// const SlickSlider = loadable.lib(() => import("mf-react-slick"));

const Slider = ({
  afterChangeCallback = () => {},
  children,
  settings,
  styles = {},
  slickGoTo,
  testSelector,
  slickWillGoTo,
}) => {
  const [initialSlide, setInitialSlide] = useState();
  useEffect(() => {
    setInitialSlide(slickWillGoTo);
  }, []);

  const defaultSettings = {
    cssEase: "cubic-bezier(0.165, 0.840, 0.440, 1.000)",
    initialSlide: initialSlide || slickGoTo, // eslint-disable-line
    afterChange(event) {
      afterChangeCallback(event);
    },
  };

  const mergedSettings = { ...defaultSettings, ...settings };
  const additionalProps = testSelector ? { [testSelector]: true } : {};
  return (
    <div className={classNames(s.root, styles.root)} {...additionalProps}>
      <SlickSlider {...mergedSettings}>{children}</SlickSlider>
    </div>
  );
};

Slider.propTypes = {
  afterChangeCallback: PropTypes.func,
  children: PropTypes.node,
  settings: PropTypes.object,
  slickGoTo: PropTypes.number,
  slickWillGoTo: PropTypes.number,
  styles: PropTypes.object,
  testSelector: PropTypes.string,
};

export default Slider;
