import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classNames from "classnames/bind";
import IconBoldRight from "stelar/components/Icon/IconBoldRight";
import IconBoldDown from "stelar/components/Icon/IconBoldDown";
import { renderWithProps } from "../util";
import s from "./TopLevelItem.theme"; // eslint-disable-line import/no-unresolved

const cx = classNames.bind(s);

function TopLevelItem({
  name,
  link,
  active,
  open,
  children,
  compact,
  flyoutAlign,
  flyoutColumns,
  onMouseEnter,
  onMouseLeave,
  onTouchStart,
  onTouchEnd,
  onClick,
  isServicePage,
  ...attrs
}) {
  const hasChildren = !!children;
  const showChildren = hasChildren && (open || !compact);
  const linkClass = cx(s.link, {
    active: active || (open && !children),
    open: open && children,
  });
  const arrowClass = cx("arrow", {
    down: showChildren,
  });
  const cols = Math.min(Math.max(4, flyoutColumns), 6);
  const childClass = cx("child", flyoutAlign, `cols${cols}`);
  return (
    <div
      className={cx("root", {
        compact,
        flyout: !compact,
        servicePageHighlight: isServicePage,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <a
        {...attrs}
        href={link}
        className={linkClass}
        onTouchStart={onTouchStart}
        onClick={onClick}
        onTouchEnd={onTouchEnd}
      >
        <span>{name}</span>
        {hasChildren ? (
          <span className={arrowClass}>
            {showChildren ? <IconBoldDown tiny /> : <IconBoldRight tiny />}
          </span>
        ) : null}
      </a>
      {open ? (
        <div className={childClass}>
          {showChildren ? renderWithProps(children, { compact }) : null}
        </div>
      ) : null}
    </div>
  );
}

TopLevelItem.propTypes = {
  name: PropTypes.node,
  link: PropTypes.node,
  active: PropTypes.bool,
  open: PropTypes.bool,
  compact: PropTypes.bool,
  children: PropTypes.node,
  flyoutAlign: PropTypes.oneOf(["left", "midleft", "midright", "right"]),
  flyoutColumns: PropTypes.number,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onTouchStart: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onClick: PropTypes.func,
  isServicePage: PropTypes.bool,
};

TopLevelItem.defaultProps = {
  flyoutAlign: "left",
};

export default TopLevelItem;
