/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconBoldRight(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 8 12" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path d="M0.8,0.8c-0.4,0.4-0.4,1,0,1.4l4,3.8l-4,3.8c-0.4,0.4-0.4,1.1,0,1.4c0.4,0.4,1.1,0.4,1.6,0l4.8-4.5 C7.4,6.5,7.5,6.3,7.5,6c0-0.3-0.1-0.5-0.3-0.7L2.4,0.8C2,0.4,1.3,0.4,0.8,0.8z" />
    </svg>
  );
}

export default IconBoldRight;
