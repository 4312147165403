/* helper function */

export function setApiPath({ articleId, categoryId, path, url }) {
  if (path === "/") {
    return `/${url}?page=homepage`;
  }
  if (articleId) {
    return `/${url}?articleId=${articleId}`;
  }
  if (categoryId) {
    return `/${url}?categoryId=${categoryId}`;
  }
  return `/${url}`;
}

export function setNavigationItems(state, payload) {
  if (state.header) {
    return { header: { navigation: { items: { $merge: payload } } } };
  }
  return { $set: { header: { navigation: { items: payload } } } };
}
