import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import LazyLoad from "react-lazyload";
import imgSizes from "shared/utils/imageSizes";
import ProductImage from "../../ProductImage";

const defaultImgSizes = imgSizes({
  xxxl: "300px",
  xxl: "20vw",
  l: "25vw",
  default: "50vw",
});

function ProductTileImage({
  image,
  imageProfileDefault,
  imageSizes = defaultImgSizes,
  lazy: reactLazy,
  nativeLazy,
  minimal,
}) {
  const normalProductImage = (
    <ProductImage
      image={image}
      imageProfileDefault={imageProfileDefault}
      sizes={imageSizes}
      data-test-sell-product-image
      {...(!reactLazy && nativeLazy && { loading: "lazy" })}
    />
  );

  const minimalProductImage = (
    <ProductImage
      image={image}
      imageProfileDefault={imageProfileDefault}
      preview
      data-test-sell-product-image
    />
  );

  const lazyProductImage = (
    <LazyLoad placeholder={minimalProductImage} once offset={1000}>
      {normalProductImage}
    </LazyLoad>
  );

  if (minimal) return minimalProductImage;
  if (reactLazy) return lazyProductImage;
  return normalProductImage;
}

ProductTileImage.propTypes = {
  image: PropTypes.shape({
    pattern: PropTypes.string,
    presets: PropTypes.array,
    previewPreset: PropTypes.string,
    fallbackPreset: PropTypes.string,
    ratio: PropTypes.number,
    altText: PropTypes.string,
  }),
  imageProfileDefault: PropTypes.object,
  imagePreview: PropTypes.string,
  imageUrl: PropTypes.string,
  imageSrcSet: PropTypes.string,
  imageSizes: PropTypes.string,
  name: PropTypes.string,
  lazy: PropTypes.bool,
  nativeLazy: PropTypes.bool,
  minimal: PropTypes.bool,
};

export default ProductTileImage;
