import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import IconUp from "stelar/components/Icon/IconUp";
import IconDown from "stelar/components/Icon/IconDown";
import CountryFlag from "stelar/components/CountryFlag";
import Text from "stelar/components/Text";
import classnames from "classnames/bind";
import s from "./CurrentCountry.module.css";

const cx = classnames.bind(s);

const CurrentCountry = ({
  current,
  isCountrySelectionOpen,
  toggleCountrySelection,
}) => {
  const arrowIcon = isCountrySelectionOpen ? <IconUp /> : <IconDown />;
  const selectedClass = cx("selected", { open: isCountrySelectionOpen });
  return (
    <button
      className={selectedClass}
      onClick={toggleCountrySelection}
      type="button"
    >
      <Text normal className={s.text}>
        {current.icon !== "mag" ? (
          <span className={`${s.flagIconContainer} ${s.currentCountryIcon}`}>
            <CountryFlag country={current.icon} />
          </span>
        ) : (
          <div />
        )}
        <span className={s.selectedLink}>{current.name}</span>
        <span className={s.arrowIconContainer}>{arrowIcon}</span>
      </Text>
    </button>
  );
};

CurrentCountry.propTypes = {
  current: PropTypes.object,
  isCountrySelectionOpen: PropTypes.bool,
  toggleCountrySelection: PropTypes.func,
};

CurrentCountry.defaultProps = {};

export default CurrentCountry;
