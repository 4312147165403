import { useRef } from "react"; // eslint-disable-line import/no-extraneous-dependencies

function useIsFirstRender() {
  const isFirst = useRef(true);
  if (isFirst.current) {
    isFirst.current = false;
    return true;
  }
  return isFirst.current;
}

export default useIsFirstRender;
