import React from "react";
import classnames from "classnames/bind";
import Text from "../Text";

import s from "./LinkButton.module.css";

const cx = classnames.bind(s);

const ICON_LEFT = "left";
const ICON_RIGHT = "right";

type Props = {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  href?: string;
  onClick?: (...args: unknown[]) => unknown;
  prio1?: boolean;
  prio2?: boolean;
  strong?: boolean;
  em?: boolean;
  normal?: boolean;
  light?: boolean;
  small?: boolean;
  inverted?: boolean;
  skeleton?: boolean;
  disabled?: boolean;
  iconPosition?: unknown | unknown;
};

/**
 * The LinkButton is used in places where there is no specific user guidance.
 * It inherits its appearance from the text component and is to be used in its environment.
 * Use cases are secondary actions such as deleting shopping cart items or opening hidden content.
 */
function LinkButton({
  children,
  icon,
  href,
  onClick,
  prio1,
  prio2,
  strong,
  em,
  normal,
  light,
  small,
  inverted,
  skeleton,
  disabled,
  iconPosition,
  ...props
}: Props) {
  const htmlHref = disabled ? null : href;
  let htmlTag = "button";
  if (href) {
    htmlTag = disabled ? "span" : "a";
  }
  htmlTag = skeleton ? "span" : htmlTag;

  return (
    <Text
      strong={strong}
      em={em}
      normal={normal || !(strong || em || light || small)}
      light={light}
      small={small}
      inverted={inverted}
      skeleton={skeleton}
    >
      {React.createElement(
        htmlTag,
        {
          ...props,
          className: cx("button", { disabled }),
          href: htmlHref,
          onClick,
          disabled,
          role: href ? "button" : null,
          "data-pure-button": true,
          "data-prio1": prio1,
        },
        <span className={s.buttonContent}>
          {icon && iconPosition === ICON_LEFT && (
            <span className={cx("icon", iconPosition)}>{icon}</span>
          )}
          <span className={s.text}>{children}</span>
          {icon && iconPosition === ICON_RIGHT && (
            <span className={cx("icon", iconPosition)}>{icon}</span>
          )}
        </span>
      )}
    </Text>
  );
}

LinkButton.defaultProps = {
  iconPosition: ICON_LEFT,
};

export default LinkButton;
