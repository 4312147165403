// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Helmet from "react-helmet";
// mf-stelar
import Accordion, { AccordionItem } from "stelar/components/Accordion";
import ButtonBack from "stelar/components/Button/ButtonBack";
import ButtonGroup from "stelar/components/ButtonGroup";
import Heading from "stelar/components/Heading";
// ase
import Message from "stelar/components/Message";
import ContentGutter from "../../../components/ContentGutter";
import ReducedLayout from "../../../components/ReducedLayout";
import { createHandleClick } from "../../utils/Routing";
import reducedLayoutCondition from "../reducedLayoutCondition";
import LoginContainer from "./LoginContainer";
import RegisterContainer from "./RegisterContainer";

import s from "./LoginPage.module.css";

const LoginPage = ({
  doLogin,
  doRegister,
  isLoading,
  login,
  openedItem,
  loginStarted,
  registerStarted,
  page,
  reducedLayout,
  location,
  register,
  routes,
  toggleAccordion,
  otrToken,
}) => {
  const target = location?.query?.target;
  const navigate = createHandleClick(routes);
  const head = page ? page.headData || {} : {};

  const checkoutHeadline = page.headline ? (
    <Heading prio1 skeleton={isLoading}>
      {page.headline}
    </Heading>
  ) : null;

  const pageContent = (
    <div className={s.gutter}>
      {checkoutHeadline}
      {page.globalMessage && <Message {...page.globalMessage} />}
      <Accordion
        currentValue={openedItem}
        onChange={(itemToOpen) => toggleAccordion(itemToOpen)}
        skeleton={isLoading}
        smallAccordion
        testStelarAccordionId="login-register"
      >
        <AccordionItem
          skeleton={isLoading}
          subTitle={login.description}
          testStelarAccordionItemId="login"
          title={login.headline}
          value="login_box"
        >
          <LoginContainer
            doLogin={doLogin}
            isLoading={isLoading}
            login={login}
            routes={routes}
            target={target}
            loginStarted={loginStarted}
            otrToken={otrToken}
          />
        </AccordionItem>
        <AccordionItem
          subTitle={register.description}
          testStelarAccordionItemId="register"
          title={register.headline}
          value="register_box"
        >
          <RegisterContainer
            doRegister={doRegister}
            isLoading={isLoading}
            register={register}
            routes={routes}
            target={target}
            registerStarted={registerStarted}
          />
        </AccordionItem>
      </Accordion>
      {page?.back ? (
        <ButtonGroup
          buttonLeft={
            <ButtonBack
              data-test-ase-prev-page
              href={page.back.link}
              onClick={navigate}
              prio5
              skeleton={isLoading}
              text={page.back.label}
            />
          }
        />
      ) : null}
    </div>
  );

  const showReducedLayout = reducedLayoutCondition(location?.query);

  const pageWrap = showReducedLayout ? (
    <ReducedLayout
      isLoading={isLoading}
      navigate={navigate}
      orderProgressStep="1"
      {...reducedLayout}
    >
      <Helmet
        meta={[{ name: "robots", content: "none" }]}
        noscript={head.noscript}
        title={head.title}
      />
      {pageContent}
    </ReducedLayout>
  ) : (
    <ContentGutter>
      <Helmet
        meta={[{ name: "robots", content: "none" }]}
        noscript={head.noscript}
        title={head.title}
      />
      {pageContent}
    </ContentGutter>
  );
  return pageWrap;
};

LoginPage.propTypes = {
  doLogin: PropTypes.func.isRequired,
  doRegister: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  login: PropTypes.object.isRequired,
  openedItem: PropTypes.string,
  loginStarted: PropTypes.bool,
  registerStarted: PropTypes.bool,
  page: PropTypes.object,
  reducedLayout: PropTypes.object,
  register: PropTypes.object.isRequired,
  routes: PropTypes.array,
  location: PropTypes.object,
  toggleAccordion: PropTypes.func.isRequired,
  otrToken: PropTypes.string,
};

export default LoginPage;
