import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ButtonGroup from "stelar/components/ButtonGroup";
import { ButtonBack, ButtonWithIcon } from "stelar/components/Button";
import prefetch from "shared/ssrPrefetch/prefetch";
import { SellRecommendationsFragment } from "shared/components/registry";
import IconBasket from "stelar/components/Icon/IconBasket";
import OverlayProductTile from "../../components/OverlayProductTile";
import loadLayerContent from "./actions";
import s from "./LayerAddToCartFragment.module.css";

const redial = {
  fetch: ({ dispatch, apiClient, props, cookies }) => {
    const { sku } = props;
    return Promise.all([dispatch(loadLayerContent(apiClient, sku, cookies))]);
  },
};

function mapStateToProps(state, { sku, recommendationsEnabled }) {
  const { data, loading } = state.addToCartFragment;
  // set isLaoding is required, cause of consuming in first render the wrong/old state; you have to use the fetch sku of fragment call
  if (state.addToCartFragment.sku !== sku) {
    return { loading: true, sku, recommendationsEnabled };
  }
  return { ...data, loading, sku, recommendationsEnabled };
}

function LayerAddToCartFragment({
  currentArticle,
  manufacturers,
  backText,
  image,
  loading,
  productName,
  proceedText,
  recommendationsEnabled,
  categoryContext,
  basketUrl,
  onCloseModal,
}) {
  const basketButtonPath = basketUrl ? `${basketUrl}#top` : "/warenkorb#top";

  return (
    <div className={s.root} data-test-sell-addtocart-overlay>
      <OverlayProductTile
        image={image}
        name={productName}
        manufacturer={manufacturers && manufacturers.join(", ")}
        skeleton={loading}
      />
      <ButtonGroup
        buttonLeft={
          <ButtonBack
            prio2
            text={backText}
            skeleton={loading}
            onClick={() => onCloseModal()}
            data-test-sell-addtocart-overlay-back
          />
        }
        buttonRight={
          <ButtonWithIcon
            text={proceedText}
            href={basketButtonPath}
            skeleton={loading}
            icon={<IconBasket />}
            iconClass={s.basketButtonIcon}
            next
            data-test-sell-addtocart-overlay-proceed
          />
        }
        narrow
      />
      {recommendationsEnabled === "true" ? (
        <div className={s.recommendations}>
          <SellRecommendationsFragment
            lazy
            location="addToBasket"
            article={currentArticle}
            skeleton={loading}
            category={categoryContext}
          />
        </div>
      ) : null}
    </div>
  );
}

LayerAddToCartFragment.propTypes = {
  image: PropTypes.object,
  productName: PropTypes.string,
  proceedText: PropTypes.string,
  backText: PropTypes.string,
  currentArticle: PropTypes.string,
  manufacturers: PropTypes.array,
  loading: PropTypes.bool,
  recommendationsEnabled: PropTypes.string,
  categoryContext: PropTypes.string,
  basketUrl: PropTypes.string,
  onCloseModal: PropTypes.func,
};

export default connect(mapStateToProps)(
  prefetch(LayerAddToCartFragment, redial)
);
