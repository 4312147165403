import track from "shared/tracking";
import { connect } from "react-redux";
import { provideHooks } from "redial";
import loadable from "@loadable/component";
import Loading from "shared/components/Loading";
import { loadHomepage } from "./actions";
import navigationActions from "../../utils/uiState/navigation/actions";
import { getSliderActions } from "../../utils/uiState/sliders/actions";
import {
  imageLoaded,
  closeOrOpenContentAccordionItem,
  setTrackingListType,
} from "../../utils/uiState/actions";
import { increaseStickyBannerShownCount } from "../../utils/bannerHelper";
import searchActions from "../../utils/uiState/search/actions";
import { openCookieBanner } from "../../fragments/sell-cookie-banner/actions";

const { updateCurrent, compactTogglePath } = navigationActions();
const { clearSearchString } = searchActions();

const redial = {
  fetch: ({ dispatch, apiClient }) => dispatch(loadHomepage(apiClient)),
  done: ({ dispatch, getState }) => {
    track(getState().homepage.tracking);
    if (getState().homepage?.banner) {
      increaseStickyBannerShownCount();
    }
    dispatch(updateCurrent());
    dispatch(clearSearchString());
    dispatch(openCookieBanner());
    dispatch(compactTogglePath());
  },
};

function mapDispatchToProps(dispatch, { apiClient }) {
  return {
    loadHomepage: () => dispatch(loadHomepage(apiClient)),
    onImageLoad: (src) => dispatch(imageLoaded(src)),
    sliderActions: getSliderActions(dispatch),
    closeOrOpenContentAccordionItem: (contentAccordionItem) =>
      dispatch(closeOrOpenContentAccordionItem(contentAccordionItem)),
    setTrackingListType: (trackingData) =>
      dispatch(setTrackingListType(trackingData)),
  };
}

function mapStateToProps(state) {
  const { loadedImages, sliders, openedContentAccordionItems } = state.uiState;
  const { homepage } = state;

  return {
    ...homepage,
    loadedImages,
    sliderState: sliders,
    openedContentAccordionItems,
  };
}

const LoadableHomepage = loadable(
  () => import(/* webpackChunkName: "home-page" */ "./components/Homepage"),
  {
    fallback: Loading,
  }
);

export default provideHooks(redial)(
  connect(mapStateToProps, mapDispatchToProps)(LoadableHomepage)
);
