import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Text from "stelar/components/Text";
import { IMAGE_PROFILE_DEFAULTS } from "shared/utils/imageUrlBuilder";
import ProductImage from "../ProductImage";
import ProductTileText from "../ProductTile/ProductTileText";
import s from "./OverlayProductTile.module.css";

function OverlayProductTile({
  image,
  manufacturer,
  name,
  additionalTexts = [],
  skeleton,
}) {
  return (
    <div className={s.root} data-test-sell-overlay-product-tile>
      {image && (
        <div className={s.image}>
          <ProductImage
            image={image}
            imageProfileDefault={IMAGE_PROFILE_DEFAULTS.pdsmain}
          />
        </div>
      )}
      <div className={s.text}>
        <ProductTileText
          manufacturer={manufacturer}
          name={name}
          skeleton={skeleton}
        />
        {additionalTexts.map((additionalText) => (
          <Text normal skeleton={skeleton} key={additionalText}>
            {additionalText}
          </Text>
        ))}
      </div>
    </div>
  );
}

OverlayProductTile.propTypes = {
  image: PropTypes.object,
  manufacturer: PropTypes.string,
  name: PropTypes.string,
  additionalTexts: PropTypes.arrayOf(PropTypes.string),
  skeleton: PropTypes.bool,
};

export default OverlayProductTile;
