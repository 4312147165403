import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import { Button } from "stelar/components/Button";
import Heading from "stelar/components/Heading";
import Paragraph from "stelar/components/Paragraph";
import markdownLinkBuilder from "shared/utils/markdownLinkBuilder";
import Cookie from "../Cookie";
import s from "./CookieBanner.module.css";

function CookieBanner({
  consent,
  general,
  text,
  link,
  buttonText,
  cookieAndTrackingBanner = true,
  information,
  onConfirm,
  onClick,
  onOptOut,
  onOptIn,
  reject,
  utilization,
}) {
  if (typeof document === "undefined") {
    return <div className={s.root} data-test-sell-cookie-banner />;
  }

  return (
    <div
      className={s.root}
      data-test-sell-cookie-banner
      data-cookie-banner-area
    >
      <div className={s.cookie}>
        <Cookie />
      </div>
      {general && utilization ? (
        <div className={s.text} data-test-sell-cookie-banner-scrollbox>
          <Heading
            className={s.headline}
            id={`${general.heading.replace(/\s/g, "-")}`}
            prio1
            tag="h2"
          >
            {general.heading}
          </Heading>
          <Paragraph
            className={s.paragraph}
            small
            data-test-sell-cookie-banner-text
          >
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: `${markdownLinkBuilder(general.text)} `,
              }}
            />
          </Paragraph>
          <Heading
            className={s.headline}
            prio1
            id={`${utilization.heading.replace(/\s/g, "-")}`}
            tag="h2"
          >
            {utilization.heading}
          </Heading>
          <Paragraph
            className={s.paragraph}
            small
            data-test-sell-cookie-banner-text
          >
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: `${markdownLinkBuilder(utilization.text)} `,
              }}
            />
          </Paragraph>
        </div>
      ) : (
        <div className={s.text}>
          <Paragraph
            className={s.paragraph}
            small
            data-test-sell-cookie-banner-text
          >
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: `${markdownLinkBuilder(text)} `,
              }}
            />
            <a
              href={link.link}
              onClick={!cookieAndTrackingBanner ? onClick : onOptOut}
              data-cookie-banner-area
              data-tracking-sell-cookie-banner-opt-out
            >
              {link.name}
            </a>
            .
          </Paragraph>
        </div>
      )}

      <div className={s.cookieFooter}>
        <div className={s.confirmButtonContainer}>
          {consent ? (
            <Button
              className={s.confirmButton}
              prio1
              large
              text={consent.label}
              onClick={!cookieAndTrackingBanner ? onConfirm : onOptIn}
              data-test-sell-cookie-banner-button
              data-tracking-sell-cookie-banner-opt-in
              data-cookie-banner-area
            />
          ) : (
            <Button
              className={s.confirmButton}
              prio1
              large
              text={buttonText}
              onClick={!cookieAndTrackingBanner ? onConfirm : onOptIn}
              data-test-sell-cookie-banner-button
              data-tracking-sell-cookie-banner-opt-in
              data-cookie-banner-area
            />
          )}
        </div>
        {reject && information ? (
          <div className={s.buttonLinkContainer}>
            <button
              type="button"
              className={s.link}
              onClick={!cookieAndTrackingBanner ? onClick : onOptOut}
              data-cookie-banner-area
              data-tracking-sell-cookie-banner-opt-out
            >
              {reject.label}
            </button>
            <a className={s.link} href={`${information.link}`}>
              {information.label}
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
}

CookieBanner.propTypes = {
  consent: PropTypes.object,
  general: PropTypes.object,
  text: PropTypes.string,
  link: PropTypes.object,
  buttonText: PropTypes.string,
  cookieAndTrackingBanner: PropTypes.bool,
  information: PropTypes.object,
  onConfirm: PropTypes.func,
  onClick: PropTypes.func,
  onOptOut: PropTypes.func,
  onOptIn: PropTypes.func,
  reject: PropTypes.object,
  utilization: PropTypes.object,
};

export default CookieBanner;
