import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import isEqual from "lodash.isequal";
import { getSirvLoaded, getViewer } from "./helper"; // eslint-disable-line import/named
import style from "./MainImageSlider.module.css";
import "./SirvInternalStyle.module.css";

class MainVideo extends React.Component {
  constructor(props) {
    super(props);

    this.state = { sirvReady: false, renderEnabled: true };
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.imgRef = React.createRef();
    this.sirvTimerCounter = 0;
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.sirvViewer = null;
  }

  componentDidMount() {
    if (getSirvLoaded()) {
      const viewer = getViewer();
      if (viewer && viewer.isReady()) {
        this.setState((state) => ({
          ...state,
          sirvReady: true,
          renderEnabled: false,
        }));
      } else {
        window.Sirv.on("viewer:ready", (vw) => {
          this.checkViewer(vw);
        });
        this.sirvTimer = window.setInterval(this.checkViewer.bind(this), 500);
      }
    } else {
      this.sirvTimer = window.setInterval(this.checkViewer.bind(this), 500);
    }
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.asset, nextProps.asset);
  }

  componentWillUnmount() {
    if (this.imageHideTimer) {
      window.clearTimeout(this.imageHideTimer);
      this.imageHideTimer = null;
    }
    if (getSirvLoaded()) {
      window.Sirv.off("viewer:ready");
    }
    this.clearSirvTimer();
  }

  checkViewer(viewer) {
    const sirvViewer = viewer || getViewer();
    if (sirvViewer) {
      // eslint-disable-next-line react/no-unused-class-component-methods
      this.sirvViewer = sirvViewer;

      if (sirvViewer && sirvViewer.isReady()) {
        this.clearSirvTimer();
        this.setState((state) => ({
          ...state,
          sirvReady: true,
          renderEnabled: false,
        }));
      } else {
        this.sirvTimerCounter += 1;
        if (this.sirvTimerCounter > 10) {
          this.clearSirvTimer();
          this.setState((state) => ({
            ...state,
            sirvReady: false,
            renderEnabled: true,
          }));
        }
      }
    } else {
      this.sirvTimerCounter += 1;
      if (this.sirvTimerCounter > 10) {
        this.clearSirvTimer();
        this.setState((state) => ({
          ...state,
          sirvReady: false,
          renderEnabled: true,
        }));
      }
    }
  }

  clearSirvTimer() {
    if (this.sirvTimer) {
      window.clearInterval(this.sirvTimer);
      this.sirvTimer = null;
    }
  }

  render() {
    const { asset, options } = this.props;

    const sirvViewer = typeof window !== "undefined" && (
      <div className="Sirv">
        <div data-src={asset} data-options={options} />
      </div>
    );
    return <div className={style.videoContainer}>{sirvViewer}</div>;
  }
}

MainVideo.propTypes = {
  asset: PropTypes.string,
  options: PropTypes.string,
};

export default MainVideo;
