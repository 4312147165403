/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconNewsletter(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 28 28" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path d="M0.3,9v11l4.6-3.8c0.4-0.2,0.8-0.2,1,0.1l0,0c0.2,0.3,0.1,0.7-0.3,1l-5.3,4.3V22c0,0.8,0.6,1.4,1.4,1.4h24.6c0.8,0,1.4-0.6,1.4-1.4v-0.5l-5.3-4.3c-0.4-0.2-0.5-0.7-0.3-1l0,0c0.2-0.3,0.7-0.3,1-0.1l4.6,3.8V9L14,18.3L0.3,9z" />
      <path d="M26.3,4.6H1.7C0.9,4.6,0.3,5.2,0.3,6v1.5L14,16.8l13.7-9.3V6C27.7,5.2,27.1,4.6,26.3,4.6z" />
    </svg>
  );
}

export default IconNewsletter;
