export function getSlidesPerPage(settings, screenWidth) {
  const currentWidth =
    typeof window !== "undefined" && typeof window.innerWidth !== "undefined"
      ? window.innerWidth
      : screenWidth || 0;
  const breakpoints = {};
  let currentBreakpoint = 0;

  if (settings.responsive) {
    // set slides per page for each breakpoint
    settings.responsive.forEach((obj) => {
      breakpoints[obj.breakpoint] = obj.settings.slidesToScroll;
    });

    // sort breakboints ascending and find first larger than current screenWidth
    currentBreakpoint = Object.keys(breakpoints)
      .sort((a, b) => a - b)
      .find((breakpoint) => breakpoint > currentWidth);
  }

  return breakpoints[currentBreakpoint] || settings.slidesToScroll || 1;
}

export function getCurrentSliderPagePosition(
  settings,
  activeSlide,
  screenWidth
) {
  const slidesPerPage = getSlidesPerPage(settings, screenWidth);

  // wack-a-mole
  return Math.floor(activeSlide / slidesPerPage) * slidesPerPage;
}
