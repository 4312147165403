import {
  GLOBAL_STATUS_REDIRECT,
  GLOBAL_STATUS_NOTFOUND,
  GLOBAL_STATUS_FAILED,
  CLEAR_GLOBAL_STATUS,
} from "./constants";

export function statusRedirect(location, code = 301) {
  return {
    type: GLOBAL_STATUS_REDIRECT,
    payload: { location, code },
  };
}

export function statusNotFound(message) {
  return {
    type: GLOBAL_STATUS_NOTFOUND,
    payload: { message },
  };
}

export function statusFailed(message) {
  return {
    type: GLOBAL_STATUS_FAILED,
    payload: { message: `${message}` },
  };
}

export function clearStatus() {
  return { type: CLEAR_GLOBAL_STATUS };
}
