// vendor
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import { useController, useFormContext } from "react-hook-form";
// mf-stelar
import Checkbox from "stelar/components/Checkbox";

const CheckboxGroup = ({ name, options, isLoading }) => {
  const { control } = useFormContext();
  const { field } = useController({
    control,
    name,
  });

  const [groupValue, setGroupValue] = React.useState(field.value || []);

  return options.map(({ label, value }, index) => (
    <li key={`${name}[${value}]`}>
      <Checkbox
        // eslint-disable-next-line react/jsx-no-bind
        onChange={(e) => {
          const valueCopy = new Set(groupValue);
          if (e.target.checked) {
            valueCopy.add(e.target.value);
          } else {
            valueCopy.delete(e.target.value);
          }
          const valueCopyArr = Array.from(valueCopy);
          field.onChange(valueCopyArr);
          setGroupValue(valueCopyArr);
        }}
        checked={groupValue?.some(
          (groupValueOption) => groupValueOption === value
        )}
        name={name}
        value={value}
        id={`${name}[${index}]`}
        skeleton={isLoading}
        label={label}
      />
    </li>
  ));
};

CheckboxGroup.propTypes = {
  isLoading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CheckboxGroup;
