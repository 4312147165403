function onlyDataTestPredicate(key) {
  return key.startsWith("data-test-");
}

function filter(obj) {
  return Object.keys(obj)
    .filter((key) => onlyDataTestPredicate(key))
    .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {});
}

export default function (obj) {
  return filter(obj);
}
