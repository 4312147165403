import { RenderTargets } from "../../utils/appUtils";
import { useDeviceInfo } from "../../utils/httpContext/DeviceInfoProvider";

type Props = {
  children?: React.ReactElement;
  web?: boolean;
  app?: boolean;
};

function RenderTarget({ children, app, web }: Props) {
  const { renderTarget } = useDeviceInfo();
  if (
    (app && renderTarget === RenderTargets.APP) ||
    (web && renderTarget === RenderTargets.WEB)
  ) {
    return children || null;
  }
  return null;
}

export default RenderTarget;
