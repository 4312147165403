export function addOrRemoveClassInContent(bool) {
  if (typeof document !== "undefined") {
    if (document.querySelector("#appRoot")) {
      if (bool) {
        document.querySelector("#appRoot").classList.add("offCanvasActive");
      } else {
        document.querySelector("#appRoot").classList.remove("offCanvasActive");
      }
    }
  }
}
