import PropTypes from "prop-types";
// vendor
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classNames from "classnames/bind";
// mf-stelar
import ProductImage from "stelar/components/ProductImage";
// ase
import MiniProductDescription from "./MiniProductDescription/MiniProductDescription";
import s from "./MiniProduct.module.css";

const cx = classNames.bind(s);

const MiniProduct = ({
  articleName,
  className,
  imgSrc,
  name,
  pdsLink,
  ...attrs
}) => {
  const displayName = name || articleName;

  const productImage = imgSrc ? (
    <div className={s.colImage}>
      <ProductImage
        alt={displayName}
        data-test-ase-product-image
        src={imgSrc}
        className="img"
      />
    </div>
  ) : null;

  const productDescription = displayName ? (
    <MiniProductDescription {...attrs} imgSrc={imgSrc} name={displayName} />
  ) : null;

  return pdsLink ? (
    <a
      className={classNames(cx("root"), cx(className))}
      data-test-ase-product
      href={pdsLink}
    >
      {productImage}
      {productDescription}
    </a>
  ) : (
    <div
      className={classNames(cx("root"), cx(className))}
      data-test-ase-product
    >
      {productImage}
      {productDescription}
    </div>
  );
};

MiniProduct.propTypes = {
  articleName: PropTypes.string, // for legacy backend
  className: PropTypes.string,
  imgSrc: PropTypes.string,
  name: PropTypes.string,
  pdsLink: PropTypes.string,
};

export default MiniProduct;
