/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "../common";

function IconBasket(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 36 36" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <g>
        <path d="M18,35.6c8.2,0,16-3.9,14.9-17.1c-0.4-4.8-3.1-9.9-3.5-10.8c-1.2,0.4-2.6,0.7-4.1,1C24.9,3.9,23,0.4,18,0.4 c-4.8,0-6.7,3.5-7.3,8.3C9.3,8.6,7.9,8.3,6.8,7.9C6.1,9,4,14,3.3,18.7C1.4,30.9,9.8,35.6,18,35.6z M18,2.2c3.8,0,5.2,2.9,5.7,6.9 C21.9,9.3,20,9.5,18,9.5c-1.9,0-3.9-0.1-5.7-0.4C12.8,5.1,14.4,2.2,18,2.2z M4.9,19.4c0.6-4.1,2.5-8.3,3.1-9.3 c0.9,0.1,1.7,0.4,2.6,0.6v1c0,1,0,2,0.1,3.1h1.7c-0.1-1-0.3-2-0.3-3.2v-0.6c1.7,0.3,3.8,0.4,5.8,0.4s3.9-0.1,5.7-0.4v0.6 c0,1.2,0,2.2-0.1,3.2h1.7c0.1-1,0.1-2,0.1-3.1v-1c1-0.3,1.9-0.4,2.8-0.7c0.4,0.7,2.6,5.1,3.1,9.5c0.6,5.7-1,9.3-3.6,11.5 c-0.9-1.3-5-2.3-9.6-2.3c-4.5,0-8.5,0.9-9.5,2.2C5.8,28.6,4,24.8,4.9,19.4z" />
      </g>
    </svg>
  );
}

export default IconBasket;
