import React from "react";
import classnames from "classnames/bind";
import s from "./Headline.module.css";
import CategoryHeading from "../CategoryHeading";

const cx = classnames.bind(s);

type Props = {
  headline: string;
  overline?: string;
  margin?: boolean;
};

/**
 * The headline serves as the page title and should only be used once on a page.
 * It has an h2-tag and is therefore SEO-relevant.
 */
function Headline({ headline, overline, margin, ...rest }: Props) {
  // TODO: Remove deprecation notice. SELL-3092
  Object.keys(rest).map(
    (propName) =>
      propName.startsWith("prio") &&
      console.warn(
        "Using Headline as Heading (or with Prios) is deprecated. Please use <Heading /> instead."
      )
  );

  return (
    <header className={cx("root", { margin })} {...rest}>
      {overline && (
        <CategoryHeading prio0 data-test-stelar-overline>
          {overline}
        </CategoryHeading>
      )}
      <h2 className={s.headline} data-test-stelar-headline>
        {headline}
      </h2>
    </header>
  );
}

export default Headline;
