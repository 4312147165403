import ContentHeading from "stelar/components/ContentHeading";
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { AseNewsletterSubscriptionFragment } from "shared/components/registry";
import ContentSpacing from "../../ContentSpacing";
import s from "./ElementNewsletterSignOn.module.css";

function ElementNewsletterSignOn({ heading, categoryId }) {
  return (
    <ContentSpacing alignment="text" className={s.spacing} fullWidth gutters>
      <div className={s.root}>
        <ContentHeading prio0 data-test-sell-title>
          {heading}
        </ContentHeading>
        <AseNewsletterSubscriptionFragment
          layout="kurator"
          point="content"
          categoryId={categoryId}
        />
      </div>
    </ContentSpacing>
  );
}

ElementNewsletterSignOn.propTypes = {
  heading: PropTypes.string,
  categoryId: PropTypes.string,
};

ElementNewsletterSignOn.defaultProps = {};

export default ElementNewsletterSignOn;
