// vendor
import update from "immutability-helper";
// ase
import { validateQuantity, validateFreeText } from "./validator";
import {
  LINE_ITEM_EDIT_FRAGMENT__CHANGE_INPUT,
  LINE_ITEM_EDIT_FRAGMENT__GET,
  LINE_ITEM_EDIT_FRAGMENT__POST,
  LINE_ITEM_EDIT_FRAGMENT__POST_FAILED,
  LINE_ITEM_EDIT_FRAGMENT__VALIDATE,
} from "../../pages/constants";
// component
import initialState from "./initialState";

function lineItemEditFragment(state = initialState, action) {
  let newState;
  switch (action.type) {
    case LINE_ITEM_EDIT_FRAGMENT__GET:
      return update(state, {
        data: { $set: action.payload },
      });
    case LINE_ITEM_EDIT_FRAGMENT__CHANGE_INPUT:
      return update(state, {
        data: {
          article: {
            [action.payload.name]: {
              value: { $set: action.payload.value },
            },
          },
        },
      });
    case LINE_ITEM_EDIT_FRAGMENT__POST:
      return state;
    case LINE_ITEM_EDIT_FRAGMENT__POST_FAILED:
      return update(state, {
        data: { $merge: action.payload },
      });
    case LINE_ITEM_EDIT_FRAGMENT__VALIDATE:
      newState = update(state, { submitable: { $set: true } });
      newState = validateFreeText(newState);
      return validateQuantity(newState);
    default:
      return state;
  }
}

export default lineItemEditFragment;
