export default {
  page: {
    back: {
      label: "………………",
    },
    headline: "……… …………………………",
    submit: {
      label: "………………",
    },
    submitButton: {
      label: "………………",
    },
  },
  heading: "………………………………………………",
  hint: "…………………… ………………………… …… …………… ……………………………… ………………………………… ………… ……………… ……… …………… ……… ………… ……………………………… …………… ………………………… ………………… ………… ………… ………………… …… ………………… ……………………… ……………………………… ………………………",
  attention: "",
  logout: {
    label: "……………………",
    link: "",
  },
  logOutUrl: "",
  customer: {
    heading: "………………………… …………………",
    id: {
      label: "",
      value: "",
    },
    customerNumber: {
      label: "………………………………",
      value: "………………………",
    },
  },
  billingAddress: {
    heading: "…………………………………………",
    address: {
      addressLines: ["…………………………………………… ……", "…………… ………………", "……………………………"],
      nameLines: ["…………", "……………………… ……………"],
    },
    change: "",
    changeLink: {
      label: "………………………………………… ………………",
      link: "",
    },
  },
  loginCredentials: {
    heading: "…………………………",
    changePassword: "………………… ………………",
    changeEmail: "………………………………… ………………",
  },
  orders: {
    heading: "………… …………………………………",
    allOrders: "",
    allOrdersLink: {},
  },
  newsletter: {
    heading: "…………………………",
  },
  newDeliveryAddress: {
    cancelButton: {},
    submitButton: {},
    deliveryAddress: {
      heading: "………… …………………………………",
      addressSuggest: "",
      message: "",
      values: [],
    },
  },
  openedItem: ["personalinformation_box"],
  deliveryMode: "delivery_overview",
  submitStarted: [],
};
