// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import Heading from "stelar/components/Heading";
import Text from "stelar/components/Text";
// ase
import s from "./Address.module.css";

const Address = ({ address, skeleton }) => {
  const headingLines = address.headingLines ? (
    <div data-test-ase-customer-name>
      {address.headingLines.map(
        (line) =>
          line && (
            <div key={line}>
              <Text skeleton={skeleton} normal>
                {line}
              </Text>
            </div>
          )
      )}
    </div>
  ) : null;

  const nameLines = address.nameLines ? (
    <div data-test-ase-customer-name>
      {address.nameLines.map(
        (line) =>
          line && (
            <div key={line}>
              <Text skeleton={skeleton} normal>
                {line}
              </Text>
            </div>
          )
      )}
    </div>
  ) : (
    headingLines
  );

  const addressLines = address.addressLines ? (
    <div data-test-ase-customer-address>
      {address.addressLines.map(
        (line) =>
          line && (
            <div key={line}>
              <Text skeleton={skeleton} normal>
                {line}
              </Text>
            </div>
          )
      )}
    </div>
  ) : null;

  const openingHours = address.openingHours ? (
    <div data-test-ase-customer-opening-hours>
      {address.openingHours.map(
        (line) =>
          line && (
            <div key={line}>
              <Text skeleton={skeleton} small>
                {line}
              </Text>
            </div>
          )
      )}
    </div>
  ) : null;

  const phoneNumber = address.phoneNumber && (
    <div className={s.tel} data-test-ase-customer-phone>
      <Text skeleton={skeleton} normal>
        {address.phoneNumber}
      </Text>
    </div>
  );

  return (
    <div className={s.root}>
      {address.heading && <Heading prio3>{address.heading}</Heading>}
      <div className={s.nameLines}>{nameLines}</div>
      <div className={s.otherLines}>
        {addressLines}
        {phoneNumber}
        {openingHours}
      </div>
    </div>
  );
};

Address.propTypes = {
  address: PropTypes.object.isRequired,
  skeleton: PropTypes.bool,
};

export default Address;
