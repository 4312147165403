// mf-stelar
import Checkbox from "stelar/components/Checkbox";
import {
  Input,
  InputCity,
  InputDateDay,
  InputDateMonth,
  InputDateYear,
  InputEmail,
  InputHouseNumber,
  InputPassword,
  InputStreet,
  InputTel,
  InputZip,
} from "stelar/components/Input";
import Select from "stelar/components/Select";
import InputDateMonthTxt from "stelar/components/Input/InputDateMonthTxt";
import RadioButtons from "stelar/components/RadioButtons";

const inputTypes = {
  CHECKBOX: {
    type: Checkbox,
    select: false,
    bool: true,
    styleOverrideAllowed: true,
  },
  CITY: { type: InputCity, select: false, bool: false },
  DATE_DAY: { type: InputDateDay, select: false, bool: false },
  DATE_MONTH: { type: InputDateMonth, select: true, bool: false },
  DATE_MONTH_TXT: { type: InputDateMonthTxt, select: false, bool: false },
  DATE_YEAR: { type: InputDateYear, select: false, bool: false },
  DEFAULT: {
    type: Input,
    select: false,
    bool: false,
    styleOverrideAllowed: true,
  },
  EMAIL: { type: InputEmail, select: false, bool: false },
  HIDDEN: { type: Input, select: false, bool: false, hidden: true },
  HOUSE_NUMBER: { type: InputHouseNumber, select: false, bool: false },
  PASSWORD: { type: InputPassword, select: false, bool: false },
  POSTAL_CODE: { type: InputZip, select: false, bool: false },
  DHL_POSTNUMBER: { type: Input, numeric: true },
  RADIO: { type: RadioButtons, select: false, bool: false, radio: true },
  SELECT: { type: Select, select: true, bool: false },
  STREET_NAME: { type: InputStreet, select: false, bool: false },
  TEL: { type: InputTel, select: false, bool: false },
  INFO_TEXT: { textonly: true, select: false, bool: false },
};

export function resolveInputType(type) {
  try {
    return inputTypes[type];
  } catch (error) {
    console.warn("unknown inputType", type);
    return null;
  }
}
