const initialState = {
  navigation: {
    heading: "………… ……………",
    settings: {
      label: "…………………………………………",
      link: "/kundenkonto",
    },
  },
  pageTitle: "",
  info: "Hier können Sie Ihre offenen und erledigten Rechnungen der letzten 24 Monate einsehen.",
  importantInfo: "Retouren werden erst nach einigen Werktagen sichtbar.",
  balance: {
    label: "Ihr aktueller Kontostand beträgt:",
    value: "+ ---,-- €",
  },
  historyTable: {
    heading: "Verlauf",
    emptyText: "Keine Einträge vorhanden.",
    columns: [
      { field: "date", headerName: "Datum" },
      { field: "type", headerName: "Art" },
      { field: "referenceNumber", headerName: "Beleg Nr." },
      { field: "amount", headerName: "Betrag" },
      { field: "notice", headerName: "Bemerkung" },
    ],
    rows: [
      {
        id: 1,
        date: "11.11.1111",
        type: "Rechnung",
        referenceNumber: 12345678,
        amount: { text: "00,00 €" },
        notice: {
          label: "PDF anzeigen",
          link: "/api/ase/control/secure/invoice/12345678",
        },
      },
      {
        id: 2,
        date: "11.11.1111",
        type: "Rechnung",
        referenceNumber: 12345678,
        amount: { text: "00,00 €" },
        notice: {
          label: "PDF anzeigen",
          link: "/api/ase/control/secure/invoice/12345678",
        },
      },
      {
        id: 3,
        date: "11.11.1111",
        type: "Rechnung",
        referenceNumber: 12345678,
        amount: { text: "00,00 €" },
        notice: {
          label: "PDF anzeigen",
          link: "/api/ase/control/secure/invoice/12345678",
        },
      },
    ],
  },
};

export default initialState;
