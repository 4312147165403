import React from "react";
import classnames from "classnames/bind";

import s from "./GoodThingsFromMoasteriesLogo.module.css";

const cx = classnames.bind(s);

type Props = {
  subtitle?: string;
  size?: "small" | "medium" | "large" | "teaser";
};

function GoodThingsFromMoasteriesLogo({ subtitle, size, ...attrs }: Props) {
  return (
    <div className={s.root} {...attrs}>
      <svg
        className={cx("root", { [size]: true })}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
        aria-label="Good Things From Moasteries Logo"
      >
        <rect
          x="0"
          y="0"
          width="256"
          height="256"
          style={{
            fill: "#00477A",
          }}
        />
        <path
          className={s.innerpath}
          {...attrs}
          d="M167.01,223.43v-54.08c0-32.15-40.87-54.2-40.87-54.2s-40.87,21.72-40.87,54.2v54.08"
        />
        <path
          className={s.outerpath}
          {...attrs}
          d="M188.42,217.76V115.37c0-47.44-62.29-80-62.29-80s-62.27,32.07-62.27,80v102.39H188.42z"
        />
        <path
          className={s.innerpath}
          {...attrs}
          d="M145.91,52.48c0,0-60.62,53.89-60.62,108.99v18.3"
        />
        <path
          className={s.innerpath}
          {...attrs}
          d="M105.82,52.75c0,0,61.39,52.77,61.39,107.87v19.15"
        />
      </svg>
    </div>
  );
}

export default GoodThingsFromMoasteriesLogo;
