// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { FormProvider } from "react-hook-form";
// mf-stelar
import Form from "stelar/components/Form";
import Heading from "stelar/components/Heading";
import List from "stelar/components/List";
import ListItem from "stelar/components/ListItem";
import Message from "stelar/components/Message";
import Paragraph from "stelar/components/Paragraph";
// ase
import { createHandleClick } from "shared/utils/routing";
import { buildBackendGeneratedForm } from "../../../util/reduxFormUtils/reduxFormBuilder";
import AccountDeletionForm from "./AccountDeletionForm";

import s from "./AccountDeletionFragment.module.css";

const AccountDeletionFragment = ({
  routes,
  headline,
  globalMessage,
  formDefinition,
  submitDeletion,
  submitButton,
  back,
  submitStarted,
  paragraph,
  blockBenefits,
  blockRequirements,
}) => {
  const navigate = createHandleClick(routes);

  const onSubmit = (formValues) => {
    submitDeletion({
      link: submitButton?.link,
      formValues,
    });
  };

  const { formProviderValue, onSubmitHandler } = buildBackendGeneratedForm({
    formDefinition,
    onSubmit,
    scrollToError: false,
  });

  return (
    <div className={s.content}>
      {headline && (
        <Heading className={s.center} prio2>
          {headline}
        </Heading>
      )}
      {globalMessage && <Message {...globalMessage} />}
      {paragraph && <Paragraph>{paragraph}</Paragraph>}
      {blockBenefits && (
        <div className={s.blockBenefits}>
          <Paragraph className={s.headlineOverride}>
            {blockBenefits.headline}
          </Paragraph>
          <List>
            {blockBenefits.list?.map((entry) => (
              <ListItem>{entry}</ListItem>
            ))}
          </List>
        </div>
      )}
      {blockRequirements && (
        <div className={s.blockRequirements}>
          <Paragraph className={s.headlineOverride}>
            {blockRequirements.headline}
          </Paragraph>
          <List>
            {blockRequirements.list?.map((entry) => (
              <ListItem>{entry}</ListItem>
            ))}
          </List>
        </div>
      )}
      <FormProvider {...formProviderValue}>
        <Form
          action={submitButton?.link}
          layout="compact"
          method="post"
          onSubmit={onSubmitHandler}
        >
          <AccountDeletionForm
            submitButton={submitButton}
            back={back}
            navigate={navigate}
            formDefinition={formDefinition}
            onSubmitHandler={onSubmitHandler}
            submitStarted={submitStarted}
          />
        </Form>
      </FormProvider>
    </div>
  );
};

AccountDeletionFragment.propTypes = {
  routes: PropTypes.object,
  headline: PropTypes.string,
  globalMessage: PropTypes.object,
  formDefinition: PropTypes.object,
  submitDeletion: PropTypes.func,
  submitButton: PropTypes.object,
  back: PropTypes.object,
  submitStarted: PropTypes.bool,
  paragraph: PropTypes.string,
  blockBenefits: PropTypes.object,
  blockRequirements: PropTypes.object,
};

export default AccountDeletionFragment;
