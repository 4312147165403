import update from "immutability-helper";
import {
  NAVIGATION_FLYOUT_PREPARE_OPEN,
  NAVIGATION_FLYOUT_OPEN,
  NAVIGATION_FLYOUT_CLOSE,
  NAVIGATION_FLYOUT_TOGGLE,
  NAVIGATION_COMPACT_TOGGLE_LEVEL,
  NAVIGATION_COMPACT_TOGGLE_PATH,
  NAVIGATION_COMPACT_TOGGLE_PATH_LEVEL,
  NAVIGATION_SET_CURRENT,
} from "../../../pages/constants";

const initialState = {
  currentPath: [], // the id-path of the page the user is currently on
  clickedPath: [], // tree path the user has opened through clicking or hovering
  highlightId: null, // flyout: top level item the user is currently hovering over
  flyoutAlign: null,
  offCanvasPath: [],
};

export default function navigationReducer(state = initialState, action) {
  switch (action.type) {
    case NAVIGATION_FLYOUT_PREPARE_OPEN: {
      const id = action.payload;
      return update(state, {
        highlightId: { $set: id },
      });
    }
    case NAVIGATION_FLYOUT_OPEN: {
      const { id, align } = action.payload;
      return update(state, {
        highlightId: { $set: id },
        clickedPath: { $set: [id] },
        flyoutAlign: { $set: align },
      });
    }
    case NAVIGATION_FLYOUT_TOGGLE: {
      const { id, align } = action.payload;
      const { highlightId, clickedPath } = state;
      return update(state, {
        highlightId: { $set: highlightId && highlightId === id ? null : id },
        clickedPath: {
          $set: clickedPath.length && clickedPath[0] === id ? [] : [id],
        },
        flyoutAlign: { $set: align },
      });
    }
    case NAVIGATION_FLYOUT_CLOSE: {
      return update(state, {
        clickedPath: { $set: [] },
        highlightId: { $set: null },
        flyoutAlign: { $set: null },
      });
    }
    case NAVIGATION_COMPACT_TOGGLE_LEVEL: {
      const { level, id } = action.payload;
      const newPath = state.clickedPath.slice();
      if (newPath[level] === id) {
        newPath.splice(level);
      } else {
        newPath.splice(level, 3, id);
      }

      return update(state, {
        clickedPath: { $set: newPath },
      });
    }
    case NAVIGATION_COMPACT_TOGGLE_PATH: {
      window.COMPACT_PATH = action.payload.id;
      return update(state, {
        offCanvasPath: { $set: action.payload.id },
      });
    }
    case NAVIGATION_COMPACT_TOGGLE_PATH_LEVEL: {
      const { level, id } = action.payload;

      const newPath = state.offCanvasPath.slice();
      if (newPath[level] === id) {
        newPath.splice(level);
      } else {
        newPath.splice(level, 3, id);
      }
      window.COMPACT_PATH = newPath;
      return update(state, {
        offCanvasPath: { $set: newPath },
      });
    }
    case NAVIGATION_SET_CURRENT: {
      return update(state, {
        currentPath: { $set: action.payload },
      });
    }
    default:
      return state;
  }
}
