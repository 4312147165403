import React, { useEffect } from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { sendClientSideError } from "../../logger/clientLog";

export function sendDeviceIdentData() {
  if (window?.mf_di) {
    window.mf_di();
  }
}

type Props = {
  deviceIdent?: {
    cssUrl: string;
    jsUrl: string;
    config?: unknown;
  };
};

function DeviceIdent({ deviceIdent }: Props) {
  if (!deviceIdent) {
    return null;
  }

  useEffect(() => {
    function loadSnippetCode(onLoad, onError) {
      // eslint-disable-next-line one-var, no-var
      var s, r, t;

      r = false;
      s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = deviceIdent.jsUrl;
      s.onerror = onError;
      // eslint-disable-next-line no-multi-assign
      s.onload = s.onreadystatechange = function () {
        // eslint-disable-next-line react/no-this-in-sfc
        if (!r && (!this.readyState || this.readyState === "complete")) {
          r = true;
          onLoad();
        }
      };

      // eslint-disable-next-line prefer-destructuring
      t = document.getElementsByTagName("script")[0];
      t.parentNode.insertBefore(s, t);
    }

    function scriptLoaded() {
      window.mf_di = function () {
        // @ts-expect-error
        // eslint-disable-next-line no-undef
        const promise = ri.send(deviceIdent.config);

        function collectionDone() {
          // collection is complete
        }

        promise.then(collectionDone);
      };
      sendDeviceIdentData();
    }

    function loadError(e) {
      sendClientSideError(`Couldn't load di script: ${e?.target?.src}`);
    }

    loadSnippetCode(scriptLoaded, loadError);
  }, []);

  return (
    <Helmet>
      <noscript>{`<link rel="stylesheet" type="text/css" href="${deviceIdent.cssUrl}">`}</noscript>
    </Helmet>
  );
}

const mapStateToProps = (state) => ({
  deviceIdent: state.httpContext.deviceIdent,
});

export default connect(mapStateToProps)(DeviceIdent);
