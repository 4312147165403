/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "../common";

function IconWarehouse(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 28 28" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path
        fill="#EDEDED"
        d="M20.8,9L14,4L7.2,9l-6.8,5h3v10h21.1V14h3L20.8,9z M18.6,14h4.2v5.7h-4.2V14z M5.2,13.9h4.2v5.8H5.2V13.9z M9.6,21.6c0,0.5-0.4,0.9-0.9,0.9H5.9c-0.5,0-0.9-0.4-0.9-0.9v0c0-0.5,0.4-0.9,0.9-0.9h2.9C9.2,20.7,9.6,21.1,9.6,21.6L9.6,21.6z M17,22.5L17,22.5h-5.8v-8c0-1.6,1.3-2.9,2.9-2.9c1.6,0,2.8,1.3,2.9,2.9V22.5z M23.1,21.6c0,0.5-0.4,0.9-0.9,0.9h-2.9 c-0.5,0-0.9-0.4-0.9-0.9v0c0-0.5,0.4-0.9,0.9-0.9h2.9C22.7,20.7,23.1,21.1,23.1,21.6L23.1,21.6z"
      />
    </svg>
  );
}

export default IconWarehouse;
