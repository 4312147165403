import { browserHistory, match } from "mf-react-router"; // eslint-disable-line import/no-extraneous-dependencies
import { getAnchorFromEvent, getPathFromAnchor } from "./helper";
import "core-js/actual/array";
import "core-js/actual/set";
import "url-search-params-polyfill";
import {
  GLOBAL_MODAL_TYPES,
  openModal,
} from "../../components/GlobalModal/GlobalModal";

function isAbsolute(url) {
  return url.match(/https?:\/\//);
}

export function isKnownRoute(routes, location, callback) {
  if (isAbsolute(location)) {
    callback(false);
  } else {
    match({ routes, location }, (error, redirectLocation, renderProps) => {
      const known = (error || redirectLocation || renderProps) !== undefined;
      callback(known);
    });
  }
}

function preventNavigation(a, e) {
  // catalog order overlay was found
  const catalogId = a.getAttribute("data-sell-catalog-overlay");
  if (catalogId) {
    e.preventDefault();
    openModal(GLOBAL_MODAL_TYPES.CATALOG_ORDER_FRAGMENT, catalogId);
    return true;
  }
  return false;
}

export function navigateTo(
  path,
  routes,
  stateObject = null,
  localBrowserHistory = browserHistory,
  localWindow = typeof window === "undefined" || window
) {
  isKnownRoute(routes, path, (isKnown) => {
    if (isKnown) {
      if (stateObject !== null) {
        localBrowserHistory.push(stateObject);
      } else {
        localBrowserHistory.push(path);
      }
    } else {
      localWindow.location.href = path; // eslint-disable-line
    }
  });
}

export function createHandleClick(
  routes,
  localBrowserHistory = browserHistory,
  localWindow = typeof window === "undefined" || window
) {
  // eslint-disable-next-line func-names
  return function handleClick(e) {
    const a = getAnchorFromEvent(e);
    if (!a) return null;

    const path = getPathFromAnchor(a);
    if (!path) return null;

    if (preventNavigation(a, e, localWindow)) return null;

    // navigiere
    e.preventDefault();
    navigateTo(path, routes, null, localBrowserHistory, localWindow);
    return null;
  };
}

export function createStatefulHandleClick(
  state,
  routes,
  localBrowserHistory = browserHistory,
  localWindow = typeof window === "undefined" || window
) {
  // eslint-disable-next-line func-names
  return function handleClick(e) {
    const a = getAnchorFromEvent(e);
    if (!a) return null;

    const path = getPathFromAnchor(a);
    if (!path) return null;

    const { pathname, search } = a;

    let query = {};
    if (search?.length > 0) {
      query = JSON.parse(
        `{"${decodeURI(search.substring(1))
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`
      );
    }

    if (preventNavigation(a, e, localWindow)) return null;

    // navigiere
    e.preventDefault();
    navigateTo(
      path,
      routes,
      { pathname, search, query, state },
      localBrowserHistory,
      localWindow
    );
    return null;
  };
}

export function createHandleNavigateTo(
  routes,
  localBrowserHistory = browserHistory,
  localWindow = typeof window === "undefined" || window
) {
  // eslint-disable-next-line func-names
  return function handleNavigateTo(path) {
    navigateTo(path, routes, null, localBrowserHistory, localWindow);
  };
}

export function handleSelect(e) {
  browserHistory.push(e.target.value);
}

export function buildUrl(linkElement) {
  if (linkElement) {
    const seperator =
      linkElement && linkElement.link && linkElement.link.indexOf("?") <= 0
        ? "?"
        : "&";
    const parameters =
      linkElement && linkElement.parameters
        ? seperator + linkElement.parameters.join("&")
        : "";
    return linkElement.link + parameters;
  }
  return "";
}

export function buildUrlWithParams(linkElement, queryParams) {
  if (linkElement) {
    const seperator =
      linkElement && linkElement.link && linkElement.link.indexOf("?") <= 0
        ? "?"
        : "&";
    const parameters =
      linkElement && queryParams ? seperator + queryParams.join("&") : "";
    return linkElement.link + parameters;
  }
  return "";
}

export function getDistinctParameters(searchParams) {
  return Array.from(new Set(searchParams)).filter((val) => val !== "");
}

export function getParameters(linkElement) {
  const queryParams = linkElement.parameters;

  if (typeof window === "undefined") {
    return getDistinctParameters(queryParams);
  }

  const queryManufacturerConcat = [];
  const queryColorConcat = [];
  const querySizeConcat = [];
  const queryAvailabilityConcat = [];
  const queryMaterialConcat = [];
  const queryTargetgroupConcat = [];
  const queryPriceConcat = [];
  const queryCategoryConcat = [];

  getDistinctParameters(
    new URLSearchParams(window.location.search).getAll("manufacturer")
  ).map((search) =>
    queryManufacturerConcat.push(`manufacturer=${encodeURIComponent(search)}`)
  );
  getDistinctParameters(
    new URLSearchParams(window.location.search).getAll("color")
  ).map((search) =>
    queryColorConcat.push(`color=${encodeURIComponent(search)}`)
  );
  getDistinctParameters(
    new URLSearchParams(window.location.search).getAll("size")
  ).map((search) => querySizeConcat.push(`size=${encodeURIComponent(search)}`));
  getDistinctParameters(
    new URLSearchParams(window.location.search).getAll("availability")
  ).map((search) =>
    queryAvailabilityConcat.push(`availability=${encodeURIComponent(search)}`)
  );
  getDistinctParameters(
    new URLSearchParams(window.location.search).getAll("material")
  ).map((search) =>
    queryMaterialConcat.push(`material=${encodeURIComponent(search)}`)
  );
  getDistinctParameters(
    new URLSearchParams(window.location.search).getAll("price")
  ).map((search) =>
    queryPriceConcat.push(`price=${encodeURIComponent(search)}`)
  );
  getDistinctParameters(
    new URLSearchParams(window.location.search).getAll("targetgroup")
  ).map((search) =>
    queryTargetgroupConcat.push(`targetgroup=${encodeURIComponent(search)}`)
  );
  getDistinctParameters(
    new URLSearchParams(window.location.search).getAll("category")
  ).map((search) =>
    queryCategoryConcat.push(`category=${encodeURIComponent(search)}`)
  );

  queryParams.push(queryManufacturerConcat.join("&"));
  queryParams.push(queryColorConcat.join("&"));
  queryParams.push(querySizeConcat.join("&"));
  queryParams.push(queryAvailabilityConcat.join("&"));
  queryParams.push(queryMaterialConcat.join("&"));
  queryParams.push(queryPriceConcat.join("&"));
  queryParams.push(queryTargetgroupConcat.join("&"));
  queryParams.push(queryCategoryConcat.join("&"));

  return getDistinctParameters(queryParams);
}

export function handleSelectWithLinklist(element, links) {
  const linkElement = links.find((link) => link.name === element.target.value);

  browserHistory.replace(
    buildUrlWithParams(linkElement, getParameters(linkElement))
  );
}

export function handleSelectWithOptionslist(element, links) {
  const linkElement = links.find((link) => link.value === element.target.value);
  browserHistory.replace(buildUrl(linkElement));
}

export function handleLink(linkElement) {
  browserHistory.push(
    buildUrlWithParams(
      linkElement,
      getDistinctParameters(linkElement.parameters)
    )
  );
}

export function handlePageLink(linkElement) {
  browserHistory.replace(
    buildUrlWithParams(linkElement, getParameters(linkElement))
  );
}

export function removeUrlFragment() {
  browserHistory.replace(".");
}
