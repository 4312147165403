import logger from "../logger";
import { sendClientSideError } from "../logger/clientLog";
import apiResponseHandler from "./ApiResponseHandler";

const log = logger(module);

function handle({
  response,
  handleResult,
  globalResponseHandlers,
  path,
  configuration,
}) {
  return [
    {
      type: "REQUEST_STARTED",
      path,
    },
    () =>
      response
        .then((res) => [
          globalResponseHandlers?.map((handler) =>
            apiResponseHandler(handler)(res, configuration)
          ),
          apiResponseHandler(handleResult)(res, configuration),
        ])
        .catch((error) => {
          log.error(error);
          sendClientSideError(error);
          if (
            configuration?.handleUnexpected &&
            typeof configuration.handleUnexpected === "function"
          ) {
            if (error.response) {
              return configuration.handleUnexpected({
                ...error,
                message: error.message,
                response: {
                  ...error.response,
                  statusCode: error.response.status,
                },
              });
            }
            return configuration.handleUnexpected(error);
          }
          return [
            null,
            {
              type: "REQUEST_ERROR",
              message: error?.message || error,
            },
          ];
        }),
    {
      type: "REQUEST_FINISHED",
      path,
    },
  ];
}

export function createApiClient(httpClient, globalResponseHandlers = null) {
  return {
    get: (path, handleResult, configuration) =>
      handle({
        response: httpClient.get(path, configuration),
        handleResult,
        globalResponseHandlers,
        path,
        configuration,
      }),
    delete: (path, handleResult, configuration) =>
      handle({
        response: httpClient.delete(path, configuration),
        handleResult,
        globalResponseHandlers,
        path,
        configuration,
      }),
    post: (path, payload, handleResult, configuration) =>
      handle({
        response: httpClient.post(path, payload, configuration),
        handleResult,
        globalResponseHandlers,
        path,
        configuration,
      }),
    put: (path, payload, handleResult, configuration) =>
      handle({
        response: httpClient.put(path, payload, configuration),
        handleResult,
        globalResponseHandlers,
        path,
        configuration,
      }),
    httpClient,
    getCookies: () => httpClient.getCookies(),
  };
}
