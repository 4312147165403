import React from "react";
import classNames from "classnames/bind";
import s from "./FormRowList.module.css";

const cx = classNames.bind(s);

type Props = {
  children?: React.ReactNode;
  inverted?: boolean;
  layout?: "compact" | "fluid" | "standard" | "fixed";
};

/**
 * Same as 'Form', but doesn't include a form-tag
 * It must only contain 'FormRow' components as children
 */
function FormRowList({ children, inverted, layout }: Props) {
  const classnames = cx("root", layout);

  const result = [];
  React.Children.forEach(children, (item: React.ReactElement, key) => {
    if (item) {
      // eslint-disable-next-line react/no-array-index-key
      result.push(React.cloneElement(item, { inverted, layout, key }));
    }
  });

  return <div className={classnames}>{result}</div>;
}

FormRowList.defaultProps = {
  layout: "standard",
  inverted: false,
};

export default FormRowList;
