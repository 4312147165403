import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import ContentHeading from "stelar/components/ContentHeading";
import s from "./ElementHeading.module.css";
import ContentSpacing from "../../ContentSpacing/ContentSpacing";

function ElementHeading({ heading, fullWidth }) {
  return (
    <ContentSpacing
      fullWidth={fullWidth}
      alignment="text"
      className={s.spacing}
      data-test-sell-element-heading
    >
      <ContentHeading prio3>{heading}</ContentHeading>
    </ContentSpacing>
  );
}

ElementHeading.propTypes = {
  heading: PropTypes.string,
  fullWidth: PropTypes.bool,
};

ElementHeading.defaultProps = {};

export default ElementHeading;
