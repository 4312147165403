/* eslint-disable */

const LAST_LOG_ATTRIBUTE = "lastLog";
const TIMESTAMP_ATTRIBUTE = "timestamp";

export const ONE_DAY = 60 * 60 * 24;
export const ONE_YEAR = ONE_DAY * 365;

export const isCookieSet = (cookieName) => document.cookie.includes(cookieName);

export function setCookie(name, value = true, maxAge = ONE_YEAR) {
  document.cookie = `${name}=${value}; max-age=${maxAge}; path=/; secure`;
}

export function setSessionCookie(name, value = true) {
  document.cookie = `${name}=${value}; path=/; secure`;
}

export function getCookieValue(name, document = window.document) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(";").shift();
  }
  return null;
}

export function getServerCookieValue(name, data) {
  if (data) {
    const parts = `; ${data}`.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(";").shift();
    }
  }

  return null;
}

export function getCookieAttribute(json, attribute) {
  try {
    const data = JSON.parse(json);
    for (const [key, value] of Object.entries(data)) {
      if (key === attribute) {
        return value;
      }
    }
  } catch (e) {}
  return null;
}

export function isLoggedToday(data) {
  // incoming ISO format (ISO 8601)
  const cookieLoggedValue = getCookieAttribute(data, LAST_LOG_ATTRIBUTE);
  const timestamp = getCookieAttribute(data, TIMESTAMP_ATTRIBUTE);
  return isDateEqual(cookieLoggedValue, timestamp);
}

function isDateEqual(firstDate, secondDate) {
  let firstDateObject = {
    year: new Date(firstDate).getFullYear(),
    month: new Date(firstDate).getMonth() + 1,
    day: new Date(firstDate).getDate(),
  };
  let secondDateObject = {
    year: new Date(secondDate).getFullYear(),
    month: new Date(secondDate).getMonth() + 1,
    day: new Date(secondDate).getDate(),
  };

  return (
    firstDateObject.year === secondDateObject.year &&
    firstDateObject.month === secondDateObject.month &&
    firstDateObject.day === secondDateObject.day
  );
}

/* eslint-enable */
