import {
  LOAD_LANGUAGE_SUGGESTIONS_STARTED,
  LOAD_LANGUAGE_SUGGESTIONS_SUCCESS,
} from "../../pages/constants";

export default function loadLanguageSuggestion(apiClient) {
  return [
    { type: LOAD_LANGUAGE_SUGGESTIONS_STARTED },
    apiClient.get(
      `/language-suggestions`,
      {
        ok: (response) => ({
          type: LOAD_LANGUAGE_SUGGESTIONS_SUCCESS,
          payload: { ...response.data },
        }),
        noContent: () => null,
      },
      {
        apiBase: "/api/sell",
      }
    ),
  ];
}
