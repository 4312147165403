import loadable from "@loadable/component";
import { connect } from "react-redux";
import { provideHooks } from "redial";

import Loading from "shared/components/Loading";

import {
  submit,
  validateNewsletterFragment,
} from "../../components/NewsletterSubscription/actions";
import loadReducedLayout from "../../components/ReducedLayout/actions";
import frontendUrls from "../utils/AseFrontendUrls";
import { loadKuzCreditCardPaymentPage } from "./actions";

const redial = {
  fetch: ({ apiClient, dispatch, getState, params: { orderId }, query }) =>
    Promise.all([
      dispatch(loadKuzCreditCardPaymentPage(apiClient, orderId, query)),
      dispatch(loadReducedLayout(apiClient, getState)),
    ]),
};

const mapDispatchToProps = (dispatch, { apiClient }) => ({
  submitNewsletter: (event, link, data) =>
    dispatch([
      validateNewsletterFragment(event),
      submit(event, link, data, apiClient),
    ]),
});

const mapStateToProps = (state) => ({
  reducedLayout: state.reducedLayout,
  page: state.kuzCreditCardPaymentPageV2.page,
  contentRows: state.kuzCreditCardPaymentPageV2.contentRows,
  description: state.kuzCreditCardPaymentPageV2.description,
  paymentData: state.kuzCreditCardPaymentPageV2.paymentData,
  computopForm: state.kuzCreditCardPaymentPageV2.computopForm,
});

const LoadableKuzCreditCardPaymentPageV2 = loadable(
  () =>
    import(
      /* webpackChunkName: "kuzccv2-page" */ "./components/KuzCreditCardPaymentPageV2"
    ),
  {
    fallback: Loading,
  }
);

export default {
  component: provideHooks(redial)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(LoadableKuzCreditCardPaymentPageV2)
  ),
  path: {
    base: frontendUrls.KUZ_CREDIT_CARD_PAYMENT_V2,
    appendix: "(/:orderId)",
  },
};
