import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { provideHooks } from "redial";
import { resetSsrPrefetch } from "./actions";
import { getClientsideFetchLocals } from "./useFetchLocalsStore";

type Props = {
  ssrPrefetched?: boolean;
};

export default function prefetch(Component, redial, config) {
  function WrappedComponent(props: Props) {
    const fetchLocals = getClientsideFetchLocals();

    React.useEffect(() => {
      const { ssrPrefetched } = props;
      if (!ssrPrefetched) {
        redial.fetch({ ...fetchLocals, props });
      } else {
        fetchLocals?.dispatch(resetSsrPrefetch(config?.buildCacheKey(props)));
      }
      if (redial.defer) {
        redial.defer({ ...fetchLocals, props });
      }
    }, []);

    return <Component {...props} />;
  }

  WrappedComponent["@@prefetch"] = { cookies: config?.cookies };
  return provideHooks(redial)(WrappedComponent);
}
