import { SSR_PREFETCHED } from "shared/ssrPrefetch/constants";
import {
  LOAD_KURATOR_CONTENT_SUCCESS,
  LOAD_KURATOR_CONTENT_STARTED,
  LOAD_KURATOR_CONTENT_FAILED,
  SHOW_SKELETON_KURATOR_CONTENT,
} from "../../pages/constants";
import { buildCacheKey } from "./helper";

export function loadKuratorContent(apiClient, tag, params, isClient) {
  return [
    { type: LOAD_KURATOR_CONTENT_STARTED, payload: { tag } },
    apiClient.get(
      `/kurator/service/content/tag/${encodeURIComponent(tag)}`,
      {
        ok: (response) => [
          ...(!isClient && {
            type: SSR_PREFETCHED,
            payload: buildCacheKey(tag),
          }),
          {
            type: LOAD_KURATOR_CONTENT_SUCCESS,
            payload: { ...response.data, tag },
          },
        ],
        notFound: () => ({
          type: LOAD_KURATOR_CONTENT_FAILED,
          payload: { tag },
        }),
        noContent: () => ({
          type: LOAD_KURATOR_CONTENT_FAILED,
          payload: { tag },
        }),
        serviceUnavailable: () => ({
          type: LOAD_KURATOR_CONTENT_FAILED,
          payload: { tag },
        }),
      },
      {
        apiBase: "/api/sell",
        httpClientOptions: { params },
      }
    ),
  ];
}

export function showSkeleton() {
  return { type: SHOW_SKELETON_KURATOR_CONTENT };
}
