import {
  UI_STATE_SLIDER_SETUP,
  UI_STATE_SLIDER_POSITION_CHANGED,
  UI_STATE_SET_SLIDER_POSITION,
} from "../../../pages/constants";

export function productSliderSetup(sliderId, slidesPerPage, amountSlides) {
  return {
    type: UI_STATE_SLIDER_SETUP,
    payload: { sliderId, slidesPerPage, amountSlides },
  };
}

export function changeSliderPosition(sliderId, currentPosition, slidesPerPage) {
  return {
    type: UI_STATE_SLIDER_POSITION_CHANGED,
    payload: { sliderId, currentPosition, slidesPerPage },
  };
}

export function setSliderPosition(position) {
  return {
    type: UI_STATE_SET_SLIDER_POSITION,
    payload: { position },
  };
}

export function getSliderActions(dispatch) {
  return {
    onSliderSetup: (sliderId, slidesPerPage, amountSlides) =>
      dispatch(productSliderSetup(sliderId, slidesPerPage, amountSlides)),
    onChangeSliderPosition: (sliderId, currentSliderPosition, slides) =>
      dispatch(changeSliderPosition(sliderId, currentSliderPosition, slides)),
  };
}
