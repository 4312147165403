import { combineReducers } from "redux";
import {
  LOAD_LAYER_CONTENT_SUCCESS,
  LOAD_LAYER_CONTENT_STARTED,
} from "../../pages/constants";
import uiState from "../../utils/uiState/reducer";

const initialState = null;

const contents = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LAYER_CONTENT_STARTED: {
      return initialState;
    }
    case LOAD_LAYER_CONTENT_SUCCESS: {
      return action.payload;
    }

    default:
      return state;
  }
};

export default combineReducers({
  contents,
  uiState,
});
