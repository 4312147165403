import { setPageTitle } from "shared/webbridge/actions";
import backendUrl from "../utils/AseBackendUrls";
import {
  ORDER_HISTORY_PAGE__LOAD_ORDER_HISTORY_PAGE,
  ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING_FAILED,
  ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING,
  ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING_SUCCESS,
  ORDER_HISTORY_PAGE__OPEN_ORDER,
  ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL_FAILED,
  ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL,
  ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL_SUCCESS,
  ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL_FAILED,
  ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL,
  ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL_SUCCESS,
} from "../constants";

export function loadOrderHistoryPage(apiClient, query) {
  return apiClient.get(
    backendUrl.ORDER_HISTORY,
    {
      ok: (content) => [
        {
          type: ORDER_HISTORY_PAGE__LOAD_ORDER_HISTORY_PAGE,
          payload: content.body,
        },
        setPageTitle(content.body?.page?.headData?.title),
      ],
    },
    {
      httpClientOptions: {
        params: query,
      },
    }
  );
}

export function loadShipmentTracking(apiClient, open, parcelNumbers) {
  if (open || parcelNumbers?.length === 0) {
    return null;
  }
  const parcelNumbersAppendix = `?parcelNumbers=${parcelNumbers.join(",")}`;
  return [
    {
      type: ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING,
    },
    apiClient.get(`${backendUrl.SHIPMENT_TRACKING}${parcelNumbersAppendix}`, {
      ok: (content) => ({
        type: ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING_SUCCESS,
        payload: content.body,
      }),
      noContent: () => ({
        type: ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING_FAILED,
      }),
      badRequest: () => ({
        type: ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING_FAILED,
      }),
      notFound: () => ({
        type: ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING_FAILED,
      }),
      unauthorized: () => ({
        type: ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING_FAILED,
      }),
      forbidden: () => ({
        type: ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING_FAILED,
      }),
    }),
  ];
}

export function requestReturnVoucherByEmail(apiClient, deliveryNumber) {
  return [
    {
      type: ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL,
    },
    apiClient.post(
      `${backendUrl.RETURNVOUCHER_BY_EMAIL}/${deliveryNumber}`,
      null,
      {
        noContent: () => ({
          type: ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL_SUCCESS,
          payload: { deliveryNumber, mailSent: true },
        }),
        badRequest: () => ({
          type: ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL_FAILED,
          payload: { deliveryNumber, requestFailedMessage: true },
        }),
        notFound: () => ({
          type: ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL_FAILED,
          payload: { deliveryNumber, requestFailedMessage: true },
        }),
        unauthorized: () => ({
          type: ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL_FAILED,
          payload: { deliveryNumber, requestFailedMessage: true },
        }),
        forbidden: () => ({
          type: ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL_FAILED,
          payload: { deliveryNumber, requestFailedMessage: true },
        }),
      }
    ),
  ];
}

export function requestShipmentLabelByEmail(apiClient, deliveryNumber) {
  return [
    {
      type: ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL,
    },
    apiClient.post(
      `${backendUrl.SHIPMENT_LABEL_BY_EMAIL}/${deliveryNumber}`,
      null,
      {
        noContent: () => ({
          type: ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL_SUCCESS,
          payload: { deliveryNumber, mailSent: true },
        }),
        badRequest: () => ({
          type: ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL_FAILED,
          payload: { deliveryNumber, requestFailedMessage: true },
        }),
        notFound: () => ({
          type: ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL_FAILED,
          payload: { deliveryNumber, requestFailedMessage: true },
        }),
        unauthorized: () => ({
          type: ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL_FAILED,
          payload: { deliveryNumber, requestFailedMessage: true },
        }),
        forbidden: () => ({
          type: ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL_FAILED,
          payload: { deliveryNumber, requestFailedMessage: true },
        }),
      }
    ),
  ];
}

export function openOrder(orderId) {
  return {
    type: ORDER_HISTORY_PAGE__OPEN_ORDER,
    orderId,
  };
}
