import {
  NAVIGATION_FLYOUT_PREPARE_OPEN,
  NAVIGATION_FLYOUT_OPEN,
  NAVIGATION_FLYOUT_CLOSE,
  NAVIGATION_FLYOUT_TOGGLE,
  NAVIGATION_COMPACT_TOGGLE_LEVEL,
  NAVIGATION_COMPACT_TOGGLE_PATH,
  NAVIGATION_COMPACT_TOGGLE_PATH_LEVEL,
  NAVIGATION_SET_CURRENT,
} from "../../../pages/constants";

export default function navigationActions(opts = {}) {
  const defaults = { openDelay: 300, closeDelay: 250 };
  const { openDelay, closeDelay } = Object.assign(defaults, opts);
  let timeout = null;

  function flyoutPrepareOpen(id) {
    return { type: NAVIGATION_FLYOUT_PREPARE_OPEN, payload: id };
  }

  function flyoutOpen(id, align) {
    return { type: NAVIGATION_FLYOUT_OPEN, payload: { id, align } };
  }

  function flyoutClose(id) {
    return { type: NAVIGATION_FLYOUT_CLOSE, payload: id };
  }

  return {
    flyoutClose,

    flyoutCloseDelayed(id) {
      return (dispatch) => {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => dispatch(flyoutClose(id)), closeDelay);
        return null;
      };
    },

    flyoutToggle(id, align) {
      return { type: NAVIGATION_FLYOUT_TOGGLE, payload: { id, align } };
    },

    flyoutOpenDelayed(id, align) {
      return (dispatch) => {
        if (timeout) clearTimeout(timeout);
        /* disabled: instant navigation on two rows makes flyout hard to reach
        // navigation between sibling topics should be instant
        if (getState().uiState.navigation.clickedPath.length !== 0) {
          return dispatch(flyoutOpen(id, align));
        }
        */
        // opening a topic should have a delay, so the user gets the change
        // to mouseover the navigation without opening it
        dispatch(flyoutPrepareOpen(id));
        timeout = setTimeout(() => dispatch(flyoutOpen(id, align)), openDelay);
        return null;
      };
    },

    compactToggleLevel(level, id) {
      return {
        type: NAVIGATION_COMPACT_TOGGLE_LEVEL,
        payload: { level, id },
      };
    },

    compactTogglePathLevel(level, id) {
      return {
        type: NAVIGATION_COMPACT_TOGGLE_PATH_LEVEL,
        payload: { level, id },
      };
    },

    compactTogglePath(id = []) {
      return {
        type: NAVIGATION_COMPACT_TOGGLE_PATH,
        payload: { id },
      };
    },

    updateCurrent(path = []) {
      return { type: NAVIGATION_SET_CURRENT, payload: path };
    },
  };
}
