import ContentHeading from "stelar/components/ContentHeading";
import track from "shared/tracking";
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import LazyLoad from "react-lazyload";
import { connect } from "react-redux";
import prefetch from "shared/ssrPrefetch/prefetch";
import { triggerOverlayCloseEvent } from "shared/utils/customEvents";
import { getPathFromEvent } from "shared/utils/routing/helper";
import AsyncProductSlider from "../../components/AsyncProductSlider";
import { SIZE_OVERLAY } from "../../components/AsyncProductSlider/constants";
import { loadRecommendations, resetCurrentArticle } from "./actions";
import { mapKeyString, buildCacheKey } from "./helper";
import mapDispatchToProps from "./mapDispatchToProps";
import mapStateToProps from "./mapStateToProps";
import skeletonData from "./skeletonData";
import { EPOQ_SESSION_ID_COOKIE_NAME } from "../../pages/constants";

export const redial = {
  // eslint-disable-next-line no-shadow
  fetch: ({ dispatch, getState, apiClient, props, isClient }) => {
    const {
      article,
      blacklist,
      location,
      [EPOQ_SESSION_ID_COOKIE_NAME]: sessionId,
      size,
      category,
    } = props;

    const categoryContext =
      typeof category === "string"
        ? category?.replace(/&gt;/gi, ">")
        : category;

    const params = {
      sessionId,
      ...(article ? { articleNumber: article } : {}),
      ...(blacklist ? { blacklistedArticleNumbers: blacklist } : {}),
      ...(size ? { fragmentSize: size } : {}),
      ...(category ? { categoryContext } : {}),
    };

    if (!article && location === "addToBasket") {
      // reset currentArticle
      if (getState().recommendationsFragment?.currentArticle) {
        dispatch(resetCurrentArticle());
      }

      return null;
    }

    if (article) {
      const key = mapKeyString(location, article);
      if (getState().recommendationsFragment?.recommendations?.[key]?.loading) {
        return null;
      }
    }

    return dispatch(loadRecommendations(apiClient, location, params, isClient));
  },
};

export function RecommendationsFragment({
  title,
  recommendations,
  article,
  sliderActions,
  sliderState,
  serverSkeleton,
  location,
  changeLocation,
  skeleton,
  size,
}) {
  const key = mapKeyString(location, recommendations.currentArticle || article);
  let products = null;
  let heading = null;
  let recommendationId = null;
  let loading = null;
  if (recommendations[key] !== undefined) {
    const { data } = recommendations[key];
    loading = recommendations[key].loading;
    if (data) {
      products = data.products;
      heading = data.heading;
      recommendationId = data.recommendationId;
    }
  }

  function trackRecommendation(recoId, articleNo) {
    if (window.epoqPushClick) {
      window.epoqPushClick(recoId, articleNo);
    }
  }

  function handleClick(e, trackingData) {
    const articleNo = trackingData.articleNumber || null;
    const path = getPathFromEvent(e);

    if (path) {
      e.preventDefault();
      changeLocation(path, document.getElementById("sell-root"));
    }
    if (location === "addToBasket") {
      triggerOverlayCloseEvent(e.target);
    }
    track(trackingData);
    trackRecommendation(recommendationId, articleNo);
  }

  const fullSlider = (
    <AsyncProductSlider
      products={products}
      onLinkClick={handleClick}
      sliderActions={sliderActions}
      sliderState={sliderState}
      sliderId={`RECOMMENDATIONS_${recommendationId}`}
      size={location === "addToBasket" ? SIZE_OVERLAY : size}
      lazy
    />
  );

  const serverSkeletonSlider = (
    <AsyncProductSlider
      products={skeletonData.products}
      onLinkClick={(e) => {
        e.preventDefault();
      }}
      sliderActions={sliderActions}
      sliderState={sliderState}
      sliderId={`RECOMMENDATIONS_${recommendationId}`}
      skeleton
      size={location === "addToBasket" ? SIZE_OVERLAY : size}
      lazy
    />
  );

  const component = (headline, slider) => (
    <div data-test-sell-recommendations-fragment>
      <ContentHeading prio2 tag="span">
        {headline}
      </ContentHeading>
      {slider}
    </div>
  );

  const componentSkeleton = (headline, slider) => (
    <div data-test-sell-recommendations-fragment>
      <ContentHeading prio2 tag="span" skeleton>
        {headline}
      </ContentHeading>
      {slider}
    </div>
  );

  if (!loading && !products) {
    return null;
  }

  if (serverSkeleton) {
    return (
      <LazyLoad
        placeholder={componentSkeleton(
          skeletonData.heading || title,
          serverSkeletonSlider
        )}
        once
        offset={1000}
      >
        {component(heading || title, fullSlider)}
      </LazyLoad>
    );
  }

  return (skeleton || loading) && !products
    ? componentSkeleton(skeletonData.heading || title, serverSkeletonSlider)
    : component(heading || title, fullSlider);
}

RecommendationsFragment.propTypes = {
  article: PropTypes.string,
  changeLocation: PropTypes.func,
  currentArticle: PropTypes.string,
  heading: PropTypes.string,
  products: PropTypes.array,
  recommendationId: PropTypes.string,
  location: PropTypes.oneOf([
    "homepage",
    "detail",
    "addToBasket",
    "pds2",
    "detailPds2",
    "pds2Detail",
    "byOutfit",
  ]),
  recommendations: PropTypes.object,
  serverSkeleton: PropTypes.bool,
  skeleton: PropTypes.string,
  size: PropTypes.string,
  sliderActions: PropTypes.object,
  sliderState: PropTypes.object,
  title: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  prefetch(RecommendationsFragment, redial, {
    cookies: [EPOQ_SESSION_ID_COOKIE_NAME],
    buildCacheKey,
  })
);
