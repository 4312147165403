import { combineReducers } from "redux";
import update from "immutability-helper";
import { RenderTargets } from "../appUtils";
import {
  HTTP_CONTEXT__DEVICE_IDENT,
  HTTP_CONTEXT__SET_OTR_TOKEN,
} from "./constants";

export default combineReducers({
  renderTarget: (state) => state || RenderTargets.WEB,
  deviceOs: (state) => state || null,
  otrToken: (state = null, action) => {
    switch (action.type) {
      case HTTP_CONTEXT__SET_OTR_TOKEN:
        if (action.payload) {
          return update(state, { $set: action.payload });
        }
        return state;
      default:
        return state;
    }
  },
  deviceIdent: (state = null, action) => {
    switch (action.type) {
      case HTTP_CONTEXT__DEVICE_IDENT:
        if (action.payload) {
          return update(state, { $set: action.payload });
        }
        return state;
      default:
        return state;
    }
  },
});
