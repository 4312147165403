/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconPhone(props: IconType) {
  const { alt } = props;
  return (
    <svg
      className={classNames(props)}
      viewBox="0 0 348.1 348.1"
      aria-label={alt}
    >
      {alt ? <title>{alt}</title> : null}
      <path d="M275 7.8l-53.7 53.8C210.7 72.3 211 90 222 101l27 27.2-2.8 5.3c-9.5 17-22.5 40.5-47 65-24.8 24.8-48.3 37.8-65.4 47.4-1.8 1-3.6 1.8-5.2 2.8l-18.2-18.2-9-9c-11-11-28.7-11.4-39.4-.7L8.2 274.6C-2.5 285.4-2.2 303 9 314.2L24 329.4l.4-.5c6.5 5 14 9.3 22 12.4 7.7 3 15 4.7 22.3 5.6 58.8 7 112.6-19.8 185.5-92.8 101-101 93.3-182.2 93-185.7-1-7.6-2.8-15-5.7-22.4-3-8-7.4-15.3-12.4-21.8l.3-.3-15-15.5c-11.2-11-29-11.4-39.6-.7z" />
    </svg>
  );
}

export default IconPhone;
