import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import IconBoldDown from "stelar/components/Icon/IconBoldDown";
import IconBoldRight from "stelar/components/Icon/IconBoldRight";
import classNames from "classnames/bind";
import { IMAGE_PROFILE_DEFAULTS } from "shared/utils/imageUrlBuilder";
import { renderWithProps } from "../util";
import s from "./MiddleLevelItem.module.css";
import ProductImage from "../../ProductImage";

const cx = classNames.bind(s);

function MiddleLevelItem({
  name,
  link,
  image,
  imageUrl,
  srcSet,
  altTag,
  active,
  open,
  compact,
  children,
  onClick,
  layout,
  level,
  ...attrs
}) {
  const hasChildren = !!children;
  const showChildren = hasChildren && (open || !compact);
  const flyout = !compact;
  const showImagesOld = imageUrl && flyout;
  const showImagesNew = image && flyout;
  const arrowClass = cx("arrow", {
    down: showChildren,
  });
  // TODO SELL-2386 showImagesOld entfernen wenn neues Bildformat im Kurator komplett live ist
  return (
    <div className={cx("root", layout, { open, compact, flyout, hasChildren })}>
      {link ? (
        <a
          {...attrs}
          className={cx(s.name, {
            active,
            flyout,
            "name-level-2": level === 2,
          })}
          href={link}
          onClick={onClick}
        >
          {showImagesOld ? (
            <img
              className={s.image}
              src={imageUrl}
              srcSet={srcSet}
              alt={altTag}
            />
          ) : null}
          {showImagesNew ? (
            <div className={s.image}>
              <ProductImage
                image={image}
                imageProfileDefault={IMAGE_PROFILE_DEFAULTS.categoryicons}
              />
            </div>
          ) : null}
          <span>{name}</span>
          {hasChildren ? (
            <span className={arrowClass}>
              {showChildren ? <IconBoldDown tiny /> : <IconBoldRight tiny />}
            </span>
          ) : null}
        </a>
      ) : (
        <div className={s.name}>{name}</div>
      )}
      <div className={s.child}>
        {showChildren ? renderWithProps(children, { compact }) : null}
      </div>
    </div>
  );
}

MiddleLevelItem.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.object,
  imageUrl: PropTypes.string,
  srcSet: PropTypes.string,
  altTag: PropTypes.string,
  active: PropTypes.bool,
  open: PropTypes.bool,
  compact: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  layout: PropTypes.oneOf(["normal", "wide"]),
  level: PropTypes.number,
};

MiddleLevelItem.defaultProps = {
  layout: "normal",
};

export default MiddleLevelItem;
