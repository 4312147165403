import { getVersion, round } from "../utils";

export default function transformLcpMetric(metric) {
  return {
    name: metric.name,
    value: metric.value,
    rating: metric.rating,
    entries: metric.entries?.map((entry) => ({
      entryType: entry.entryType,
      size: round(entry.size),
      loadTime: round(entry.loadTime),
      startTime: round(entry.startTime),
      renderTime: round(entry.renderTime),
      url: entry.url,
    })),
    version: getVersion(metric),
    navigationType: metric.navigationType,
  };
}
