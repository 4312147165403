// vendor
import update from "immutability-helper";
// ase
import {
  WISHLIST_PAGE__RENDER_WISHLIST_PAGE,
  WISHLIST_PAGE__SHOW_MESSAGE,
  WISHLIST_PAGE__TOGGLE_AGE_RELATED_ADVICE,
} from "../constants";
// component
import initialState from "./initialState";

const wishListPage = (state = initialState, action) => {
  let index;
  switch (action.type) {
    case WISHLIST_PAGE__RENDER_WISHLIST_PAGE:
      return update(state, {
        data: { $set: action.payload },
      });
    case WISHLIST_PAGE__SHOW_MESSAGE:
      return update(state, {
        data: {
          page: {
            globalMessage: { $set: action.message },
          },
        },
      });
    case WISHLIST_PAGE__TOGGLE_AGE_RELATED_ADVICE:
      index = state.data.wishList.products.findIndex(
        (li) => li.key === action.key
      );
      return update(state, {
        data: {
          wishList: {
            products: {
              [index]: {
                ageRelatedAdviceVisible: {
                  $apply: (visible) => !visible,
                },
              },
            },
          },
        },
      });
    default:
      return state;
  }
};

export default wishListPage;
