import React from "react";
import classNames from "classnames/bind";

import s from "./OffCanvasContent.module.css";

const cx = classNames.bind(s);

type Props = {
  children?: React.ReactNode;
  active?: boolean;
};

function OffCanvasContent({ active, children }: Props) {
  return <div className={cx("root", { active })}>{children}</div>;
}

export default OffCanvasContent;
