// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// ase
import s from "./Footnotes.module.css";

const Footnote = (footnote, index) => {
  let stars = "";
  for (let i = 0; i < index + 1; i += 1) {
    stars += "*";
  }

  return (
    <li className={s.footnote} key={index}>
      <span className={s.stars}>{stars}</span>
      {" "}
      <span>{footnote}</span>
    </li>
  );
};

const Footnotes = ({ footnotes }) =>
  footnotes ? <ol className={s.root}>{footnotes.map(Footnote)}</ol> : null;

Footnotes.propTypes = {
  footnotes: PropTypes.array,
};

export default Footnotes;
