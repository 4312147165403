// eslint-disable-next-line import/prefer-default-export
export function mapKeyString(location, articleNumber) {
  let key = location;
  if (articleNumber) {
    if (articleNumber !== location) {
      key += `|${articleNumber}`;
    }
  }
  return key;
}

export const buildCacheKey = ({ location, article }) =>
  `recommendations|${mapKeyString(location, article)}`;
