// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// ase
import MiniProduct from "../../../components/MiniProduct";
// component
import s from "./LineItemList.module.css";

const LineItemList = ({ einloeseItems, gutscheinItems, lineItems }) => {
  function createLineItems(items) {
    return items && items.length
      ? items.map((item) => (
          <li key={item.lineItemId || item.gutscheinItemId}>
            <MiniProduct {...item} />
          </li>
        ))
      : [];
  }

  const einloeseItemList = createLineItems(einloeseItems);
  const gutscheinItemList = createLineItems(gutscheinItems);
  const lineItemList = createLineItems(lineItems);

  const itemList = lineItemList.concat(
    gutscheinItemList.concat(einloeseItemList)
  );
  return itemList.length ? <ul className={s.root}>{itemList}</ul> : null;
};

LineItemList.propTypes = {
  einloeseItems: PropTypes.array,
  gutscheinItems: PropTypes.array,
  lineItems: PropTypes.array,
};

export default LineItemList;
