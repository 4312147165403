import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import { SellRecommendationsFragment } from "shared/components/registry";

import s from "./ElementRecommendations.module.css";

const cx = classnames.bind(s);

function ElementRecommendations({
  heading,
  location,
  sliderId,
  isTmpCustomerIdEnabled,
}) {
  return (
    <div className={cx("root")} data-test-sell-element-recommendations>
      <SellRecommendationsFragment
        location={location}
        heading={heading}
        sessionId="0000"
        sliderId={sliderId}
        tmpCustomerIdEnabled={isTmpCustomerIdEnabled}
      />
    </div>
  );
}

ElementRecommendations.propTypes = {
  location: PropTypes.string.isRequired,
  heading: PropTypes.string,
  isTmpCustomerIdEnabled: PropTypes.bool,
  sliderId: PropTypes.string,
};

ElementRecommendations.defaultProps = {};

export default ElementRecommendations;
