export function wrapHandler(handler) {
  const isClickable = (elem) =>
    elem.tagName === "A" || elem.tagName === "BUTTON";

  const hasClickableParent = (elem) => {
    const parent = elem.parentElement;
    if (!parent || parent.tagName === "body") {
      return false;
    }
    if (isClickable(parent)) {
      return true;
    }
    return hasClickableParent(parent);
  };

  const isCookieBannerArea = (elem) => {
    if (!elem || elem.tagName === "body") {
      return false;
    }
    if (elem.dataset.cookieBannerArea) {
      return true;
    }
    return isCookieBannerArea(elem.parentElement);
  };

  const wrappedHandler = (event) => {
    if (
      !isCookieBannerArea(event.target) &&
      (isClickable(event.target) || hasClickableParent(event.target))
    ) {
      handler();
      return true;
    }
    return false;
  };

  return wrappedHandler;
}
