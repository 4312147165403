import { setPageTitle } from "shared/webbridge/actions";
import backendUrl from "../utils/AseBackendUrls";
import { ORDER_CONFIRMATION_PAGE__LOAD_ORDER_CONFIRMATION_PAGE } from "../constants";

export default function loadOrderConfirmationPage(
  apiClient,
  paymentMethod,
  query
) {
  const appendix = paymentMethod ? `/${paymentMethod}` : "";
  return apiClient.get(
    backendUrl.ORDER_CONFIRMATION + appendix,
    {
      ok: (content) => [
        {
          type: ORDER_CONFIRMATION_PAGE__LOAD_ORDER_CONFIRMATION_PAGE,
          payload: content.body,
        },
        setPageTitle(content.body?.page?.headData?.title),
      ],
    },
    { httpClientOptions: { params: query } }
  );
}
