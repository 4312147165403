// vendor
import update from "immutability-helper";
// ase
import {
  GUTSCHEIN_WERTABFRAGE_FRAGMENT__CHANGE_INPUT,
  GUTSCHEIN_WERTABFRAGE_FRAGMENT__GET,
  GUTSCHEIN_WERTABFRAGE_FRAGMENT__POST,
  GUTSCHEIN_WERTABFRAGE_FRAGMENT__POST_FAILED,
  GUTSCHEIN_WERTABFRAGE_FRAGMENT__POST_SUCCESS,
  GUTSCHEIN_WERTABFRAGE_FRAGMENT__VALIDATE,
} from "../../pages/constants";
import { validateNumber, validatePin } from "./validator";
// component
import initialState from "./initialState";

function gutscheinWertabfrageFragment(state = initialState, action) {
  let newState;
  switch (action.type) {
    case GUTSCHEIN_WERTABFRAGE_FRAGMENT__POST:
      return update(state, {
        submitStarted: { $set: true },
      });
    case GUTSCHEIN_WERTABFRAGE_FRAGMENT__GET:
    case GUTSCHEIN_WERTABFRAGE_FRAGMENT__POST_SUCCESS:
    case GUTSCHEIN_WERTABFRAGE_FRAGMENT__POST_FAILED:
      return update(state, {
        data: { $set: action.payload },
        submitStarted: { $set: false },
      });
    case GUTSCHEIN_WERTABFRAGE_FRAGMENT__CHANGE_INPUT:
      return update(state, {
        data: {
          [action.payload.name]: {
            value: { $set: action.payload.value },
          },
        },
      });
    case GUTSCHEIN_WERTABFRAGE_FRAGMENT__VALIDATE:
      newState = update(state, { submitable: { $set: true } });
      newState = validatePin(newState);
      return validateNumber(newState);
    default:
      return state;
  }
}

export default gutscheinWertabfrageFragment;
