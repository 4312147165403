export const LOAD_NATIVE_APP_CONTENT_STARTED =
  "LOAD_NATIVE_APP_CONTENT_STARTED";
export const LOAD_NATIVE_APP_CONTENT_SUCCESS =
  "LOAD_NATIVE_APP_CONTENT_SUCCESS";
export const LOAD_DETAIL_STARTED = "LOAD_DETAIL_STARTED";
export const LOAD_DETAIL_SUCCESS = "LOAD_DETAIL_SUCCESS";
export const LOAD_HOMEPAGE_RECOMMENDATIONS_SUCCESS =
  "LOAD_HOMEPAGE_RECOMMENDATIONS_SUCCESS";
export const IS_CLIENT_SIDE = "IS_CLIENT_SIDE";
export const SET_TRACKING_ID = "SET_TRACKING_ID";
export const SET_QUERY_PARAMETER = "SET_QUERY_PARAMETER";
export const SET_DETAIL_PARAMETER = "SET_DETAIL_PARAMETER";

export const LOAD_OUTFIT_INFO_SUCCESS = "LOAD_OUTFIT_INFO_SUCCESS";
export const LOAD_OUTFIT_INFO_FAILED = "LOAD_OUTFIT_INFO_FAILED";
export const LOAD_OUTFIT_INFO_STARTED = "LOAD_OUTFIT_INFO_STARTED";

export const ADD_TO_BASKET_STARTED = "ADD_TO_BASKET_STARTED";
export const ADD_TO_BASKET_SUCCESS = "ADD_TO_BASKET_SUCCESS";
export const ADD_TO_BASKET_BAD_REQUEST = "ADD_TO_BASKET_BAD_REQUEST";
export const ADD_TO_BASKET_CLOSE_MODAL = "ADD_TO_BASKET_CLOSE_MODAL";

export const ADD_TO_NOTEPAD_STARTED = "ADD_TO_NOTEPAD_STARTED";
export const ADD_TO_NOTEPAD_SUCCESS = "ADD_TO_NOTEPAD_SUCCESS";
export const ADD_TO_NOTEPAD_CONFLICT = "ADD_TO_NOTEPAD_CONFLICT";
export const ADD_TO_NOTEPAD_BAD_REQUEST = "ADD_TO_NOTEPAD_BAD_REQUEST";
export const LOAD_FULL_NAVIGATION_STARTED = "LOAD_FULL_NAVIGATION_STARTED";
export const LOAD_FULL_NAVIGATION_SUCCESS = "LOAD_FULL_NAVIGATION_SUCCESS";
export const LOAD_HEADER_FOOTER_BANNER_SUCCESS =
  "LOAD_HEADER_FOOTER_BANNER_SUCCESS";
export const SET_BANNER_FOOTNOTES = "SET_BANNER_FOOTNOTES";

export const LOAD_LANGUAGE_SUGGESTIONS_STARTED =
  "LOAD_LANGUAGE_SUGGESTIONS_STARTED";
export const LOAD_LANGUAGE_SUGGESTIONS_SUCCESS =
  "LOAD_LANGUAGE_SUGGESTIONS_SUCCESS";

export const LOAD_HEADER_STARTED = "LOAD_HEADER_STARTED";
export const LOAD_HEADER_SUCCESS = "LOAD_HEADER_SUCCESS";
export const LOAD_HEADER_FAILED = "LOAD_HEADER_FAILED";

export const LOAD_FOOTER_STARTED = "LOAD_FOOTER_STARTED";
export const LOAD_FOOTER_SUCCESS = "LOAD_FOOTER_SUCCESS";
export const LOAD_FOOTER_FAILED = "LOAD_FOOTER_FAILED";

export const LOAD_COOKIE_BANNER_SUCCESS = "LOAD_COOKIE_BANNER_SUCCESS";
export const LOAD_COOKIE_BANNER_STARTED = "LOAD_COOKIE_BANNER_STARTED";
export const LOAD_COOKIE_BANNER_FAILED = "LOAD_COOKIE_BANNER_FAILED";
export const HIDE_COOKIE_BANNER = "HIDE_COOKIE_BANNER";
export const SHOW_COOKIE_BANNER = "SHOW_COOKIE_BANNER";
export const CLOSE_COOKIE_BANNER = "CLOSE_COOKIE_BANNER";
export const OPEN_COOKIE_BANNER = "OPEN_COOKIE_BANNER";

export const CHANGE_SEARCH_STRING = "CHANGE_SEARCH_STRING";
export const CLEAR_SEARCH_STRING = "CLEAR_SEARCH_STRING";
export const LOAD_SEARCH_SUGGEST_STARTED_IN_BACKGROUND =
  "LOAD_SEARCH_SUGGEST_STARTED_IN_BACKGROUND";
export const LOAD_SEARCH_SUGGEST_SUCCESS = "LOAD_SEARCH_SUGGEST_SUCCESS";
export const LOAD_SEARCH_SUGGEST_FAILED = "LOAD_SEARCH_SUGGEST_FAILED";
export const LOAD_SEARCH_SUGGEST_PRODUCTS_STARTED_IN_BACKGROUND =
  "LOAD_SEARCH_SUGGEST_PRODUCTS_STARTED_IN_BACKGROUND";
export const LOAD_SEARCH_SUGGEST_PRODUCTS_SUCCESS =
  "LOAD_SEARCH_SUGGEST_PRODUCTS_SUCCESS";
export const LOAD_SEARCH_SUGGEST_PRODUCTS_FAILED =
  "LOAD_SEARCH_SUGGEST_PRODUCTS_FAILED";
export const CLEAR_SEARCH_SUGGESTS = "CLEAR_SEARCH_SUGGESTS";
export const NAVIGATION_COMPACT_TOGGLE_LEVEL =
  "NAVIGATION_COMPACT_TOGGLE_LEVEL";
export const NAVIGATION_FLYOUT_CLOSE = "NAVIGATION_FLYOUT_CLOSE";
export const NAVIGATION_FLYOUT_OPEN = "NAVIGATION_FLYOUT_OPEN";
export const NAVIGATION_FLYOUT_PREPARE_OPEN = "NAVIGATION_FLYOUT_PREPARE_OPEN";
export const NAVIGATION_FLYOUT_TOGGLE = "NAVIGATION_FLYOUT_TOGGLE";
export const NAVIGATION_SET_CURRENT = "NAVIGATION_SET_CURRENT";
export const NAVIGATION_COMPACT_TOGGLE_PATH = "NAVIGATION_COMPACT_TOGGLE_PATH";
export const NAVIGATION_COMPACT_TOGGLE_PATH_LEVEL =
  "NAVIGATION_COMPACT_TOGGLE_PATH_LEVEL";
export const UI_STATE_CLOSE_COUNTRY_SELECTION =
  "UI_STATE_CLOSE_COUNTRY_SELECTION";
export const UI_STATE_CLOSE_OFFCANVAS = "UI_STATE_CLOSE_OFFCANVAS";
export const UI_STATE_OPEN_OFFCANVAS = "UI_STATE_OPEN_OFFCANVAS";
export const UI_STATE_OPEN_LANGUAGE_SELECTION_OFFCANVAS =
  "UI_STATE_OPEN_LANGUAGE_SELECTION_OFFCANVAS";
export const UI_STATE_CLOSE_LANGUAGE_SELECTION_OFFCANVAS =
  "UI_STATE_CLOSE_LANGUAGE_SELECTION_OFFCANVAS";
export const UI_STATE_TOGGLE_COUNTRY_SELECTION =
  "UI_STATE_TOGGLE_COUNTRY_SELECTION";
export const UI_STATE_IMAGE_LOADED = "UI_STATE_IMAGE_LOADED";
export const UI_STATE_SLIDER_SETUP = "UI_STATE_SLIDER_SETUP";
export const UI_STATE_SLIDER_POSITION_CHANGED =
  "UI_STATE_SLIDER_POSITION_CHANGED";
export const UI_STATE_SET_SLIDER_POSITION = "UI_STATE_SET_SLIDER_POSITION";

export const UI_STATE_SCROLL_CHANGE = "UI_STATE_SCROLL_CHANGE";
export const UI_STATE_SCROLL_HIDE = "UI_STATE_SCROLL_HIDE";
export const UI_STATE_READY = "UI_STATE_READY";
export const UI_STATE_SHOW_TEASER = "UI_STATE_SHOW_TEASER";
export const CHANGE_DETAIL_SLIDE = "CHANGE_DETAIL_SLIDE";

export const GLOBAL_STATUS_FAILED = "GLOBAL_STATUS_FAILED";
export const GLOBAL_STATUS_NOTFOUND = "GLOBAL_STATUS_NOTFOUND";
export const GLOBAL_STATUS_REDIRECT = "GLOBAL_STATUS_REDIRECT";
export const CLEAR_GLOBAL_STATUS = "CLEAR_GLOBAL_STATUS";

export const CHANGE_ORDER_FORM_VOUCHER_AMOUNT =
  "CHANGE_ORDER_FORM_VOUCHER_AMOUNT";
export const CHANGE_ORDER_FORM_FREE_INPUT = "CHANGE_ORDER_FORM_FREE_INPUT";

export const LOAD_LAYER_CONTENT_SUCCESS = "LOAD_LAYER_CONTENT_SUCCESS";
export const LOAD_LAYER_CONTENT_STARTED = "LOAD_LAYER_CONTENT_STARTED";

export const LOAD_LAYER_ADDTOCART_SUCCESS = "LOAD_LAYER_ADDTOCART_SUCCESS";
export const LOAD_LAYER_ADDTOCART_STARTED = "LOAD_LAYER_ADDTOCART_STARTED";

export const LOAD_LAYER_RATINGS_SUCCESS = "LOAD_LAYER_RATINGS_SUCCESS";
export const LOAD_LAYER_RATINGS_STARTED = "LOAD_LAYER_RATINGS_STARTED";
export const SUBMIT_RATING_FORM_STARTED = "SUBMIT_RATING_FORM_STARTED";
export const SUBMIT_RATING_FORM_SUCCESS = "SUBMIT_RATING_FORM_SUCCESS";
export const SUBMIT_RATING_FORM_FAILED = "SUBMIT_RATING_FORM_FAILED";
export const LOAD_CHECK_USER_LOGGIN_STARTED = "LOAD_CHECK_USER_LOGGIN_STARTED";
export const LOAD_CHECK_USER_LOGGIN_SUCCESS = "LOAD_CHECK_USER_LOGGIN_SUCCESS";
export const LOAD_CHECK_USER_LOGGIN_FAILED = "LOAD_CHECK_USER_LOGGIN_FAILED";

export const LOAD_LAYER_AVAILABILITIES_SUCCESS =
  "LOAD_LAYER_AVAILABILITIES_SUCCESS";
export const LOAD_LAYER_AVAILABILITIES_STARTED =
  "LOAD_LAYER_AVAILABILITIES_STARTED";

export const LOAD_RECOMMENDATIONS_SUCCESS = "LOAD_RECOMMENDATIONS_SUCCESS";
export const LOAD_RECOMMENDATIONS_STARTED = "LOAD_RECOMMENDATIONS_STARTED";
export const LOAD_RECOMMENDATIONS_FAILED = "LOAD_RECOMMENDATIONS_FAILED";
export const LOAD_RECOMMENDATIONS_TIMEOUT = "LOAD_RECOMMENDATIONS_TIMEOUT";
export const SHOW_SKELETON_RECOMMENDATIONS = "SHOW_SKELETON_RECOMMENDATIONS";

export const LOAD_RECO_TEASER_SUCCESS = "LOAD_RECO_TEASER_SUCCESS";
export const LOAD_RECO_TEASER_STARTED = "LOAD_RECO_TEASER_STARTED";
export const LOAD_RECO_TEASER_FAILED = "LOAD_RECO_TEASER_FAILED";
export const SHOW_RECO_TEASER_SKELETON = "SHOW_RECO_TEASER_SKELETON";

export const RESET_CURRENT_ARTICLE_RECOMMENDATIONS =
  "RESET_CURRENT_ARTICLE_RECOMMENDATIONS";

export const LOAD_RECOMMENDATIONS_DETAILPAGE_SUCCESS =
  "LOAD_RECOMMENDATIONS_DETAILPAGE_SUCCESS";
export const LOAD_RECOMMENDATIONS_DETAILPAGE_FAILED =
  "LOAD_RECOMMENDATIONS_DETAILPAGE_FAILED";
export const LOAD_RECOMMENDATIONS_DETAILPAGE_STARTED =
  "LOAD_RECOMMENDATIONS_DETAILPAGE_STARTED";
export const RESET_RECOMMENDATIONS_DETAILPAGE =
  "RESET_RECOMMENDATIONS_DETAILPAGE";

export const LOAD_PRODUCT_TEASER_SUCCESS = "LOAD_PRODUCT_TEASER_SUCCESS";
export const LOAD_PRODUCT_TEASER_STARTED = "LOAD_PRODUCT_TEASER_STARTED";
export const LOAD_PRODUCT_TEASER_FAILED = "LOAD_PRODUCT_TEASER_FAILED";

export const LOAD_PRODUCT_CAMPAIGN_SUCCESS = "LOAD_PRODUCT_CAMPAIGN_SUCCESS";
export const LOAD_PRODUCT_CAMPAIGN_STARTED = "LOAD_PRODUCT_CAMPAIGN_STARTED";
export const LOAD_PRODUCT_CAMPAIGN_FAILED = "LOAD_PRODUCT_CAMPAIGN_FAILED";
export const SHOW_SKELETON_PRODUCT_CAMPAIGN = "SHOW_SKELETON_PRODUCT_CAMPAIGN";

export const LOAD_RATINGS_STARTED = "LOAD_RATINGS_STARTED";
export const LOAD_RATINGS_SUCCESS = "LOAD_RATINGS_SUCCESS";
export const LOAD_RATINGS_FAILED = "LOAD_RATINGS_FAILED";
export const LOAD_MORE_RATINGS_STARTED = "LOAD_MORE_RATINGS_STARTED";
export const LOAD_MORE_RATINGS_SUCCESS = "LOAD_MORE_RATINGS_SUCCESS";
export const LOAD_MORE_RATINGS_FAILED = "LOAD_MORE_RATINGS_FAILED";
export const RATINGS_SHOW_COMMENTS = "RATINGS_SHOW_COMMENTS";
export const RATINGS_SHOW_WHOLE_TEXT = "RATINGS_WHOLE_COMMENTS";
export const RATINGS_SHOW_WHOLE_COMMENT_TEXT =
  "RATINGS_SHOW_WHOLE_COMMENT_TEXT";
export const RATINGS_TOGGLE_OVERLAY = "RATINGS_TOGGLE_OVERLAY";
export const LOAD_RATINGS_PREVIEW_STARTED = "LOAD_RATINGS_PREVIEW_STARTED";
export const LOAD_RATINGS_PREVIEW_SUCCESS = "LOAD_RATINGS_PREVIEW_SUCCESS";
export const LOAD_RATINGS_PREVIEW_FAILED = "LOAD_RATINGS_FAILED";
export const SHOW_SKELETON_RATINGS_PREVIEW = "SHOW_SKELETON_RATINGS_PREVIEW";
export const FETCH_RATINGS_USER_STARTED = "FETCH_RATINGS_USER_STARTED";
export const LOAD_RATINGS_USER_SUCCESS = "LOAD_RATINGS_USER_SUCCESS";
export const LOAD_RATINGS_USER_FAILED = "LOAD_RATINGS_USER_FAILED";

export const LOAD_RATINGS_BUTTON_STARTED = "LOAD_RATINGS_BUTTON_STARTED";
export const LOAD_RATINGS_BUTTON_SUCCESS = "LOAD_RATINGS_BUTTON_SUCCESS";
export const LOAD_RATINGS_BUTTON_FAILED = "LOAD_RATINGS_BUTTON_FAILED";

export const SHOW_RATING_FORM_LOGIN_VIEW = "SHOW_RATING_FORM_LOGIN_VIEW";
export const SHOW_RATING_FORM_SUCCESS_VIEW = "SHOW_RATING_FORM_SUCCESS_VIEW";
export const SHOW_RATING_FORM_FORM_VIEW = "SHOW_RATING_FORM_FORM_VIEW";

export const SUGGEST_ARROW_DOWN = "SUGGEST_ARROW_DOWN";
export const SUGGEST_ARROW_UP = "SUGGEST_ARROW_UP";
export const SUGGEST_ARROW_RIGHT = "SUGGEST_ARROW_RIGHT";
export const SUGGEST_ARROW_LEFT = "SUGGEST_ARROW_LEFT";
export const HOVER_SEARCH_SUGGEST = "HOVER_SEARCH_SUGGEST";
export const LEAVE_SEARCH_SUGGEST = "LEAVE_SEARCH_SUGGEST";
export const MOUSE_ENTER_SEARCH_SUBMIT = "MOUSE_ENTER_SEARCH_SUBMIT";

export const PRODUCT_LIST_SHOW_PRODUCT_TILE_SLIDER =
  "PRODUCT_LIST_SHOW_PRODUCT_TILE_SLIDER";
export const PRODUCT_LIST_HIDE_PRODUCT_TILE_SLIDER =
  "  PRODUCT_LIST_HIDE_PRODUCT_TILE_SLIDER";

export const TOGGLE_FACET_FILTER_DISPLAY = "TOGGLE_FACET_FILTER_DISPLAY";
export const RESET_FACET_FILTER_DISPLAY = "RESET_FACT_FILTER_DISPLAY";
export const UI_STATE_CLOSE_OR_OPEN_FACET_FILTER =
  "UI_STATE_CLOSE_OR_OPEN_FACET_FILTER";
export const UI_STATE_RESET_FACET_FILTER = "UI_STATE_RESET_FACET_FILTER";

export const UI_STATE_CLOSE_OR_OPEN_CONTENT_ACCORDION_ITEM =
  "UI_STATE_CLOSE_OR_OPEN_CONTENT_ACCORDION_ITEM";

export const LOAD_KURATOR_CONTENT_SUCCESS = "LOAD_KURATOR_CONTENT_SUCCESS";
export const LOAD_KURATOR_CONTENT_STARTED = "LOAD_KURATOR_CONTENT_STARTED";
export const LOAD_KURATOR_CONTENT_FAILED = "LOAD_KURATOR_CONTENT_FAILED";
export const SHOW_SKELETON_KURATOR_CONTENT = "SHOW_SKELETON_KURATOR_CONTENT";

export const CATEGORY_PRODUCT_NAVIGATION = "CATEGORY_PRODUCT_NAVIGATION";
export const CATEGORY_CONTENT_NAVIGATION = "CATEGORY_CONTENT_NAVIGATION";
export const CATEGORY_ALL_NAVIGATION = "CATEGORY_ALL_NAVIGATION";

export const UI_STATE_SET_TRACKING_LIST_TYPE =
  "UI_STATE_SET_TRACKING_LIST_TYPE";
export const UI_STATE_SET_TRACKING_LIST_TYPE_UNDEFINED =
  "UI_STATE_SET_TRACKING_LIST_TYPE_UNDEFINED";
export const UI_STATE_REMOVE_TRACKING_LIST_TYPE =
  "UI_STATE_REMOVE_TRACKING_LIST_TYPE";

export const UI_STATE_CLOSE_COOKIEBANNER = "UI_STATE_CLOSE_COOKIEBANNER";

export const EPOQ_SESSION_ID_COOKIE_NAME = "7cb54b_session_id";
