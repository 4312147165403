import PropTypes from "prop-types";
// vendor
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classNames from "classnames/bind";
// mf-stelar
import ProductImage from "stelar/components/ProductImage";
// ase
import ProductDescription from "./ProductDescription/ProductDescription";
import s from "./Product.module.css";

const cx = classNames.bind(s);

const Product = ({ className, imgSrc, isLoading, pdsLink, name, ...attrs }) => {
  const productImage = imgSrc ? (
    <div className={s.colImage}>
      <ProductImage
        alt={name}
        data-test-ase-article-image
        data-test-ase-product-image
        src={imgSrc}
        className="img"
      />
    </div>
  ) : null;

  const productDescription = name ? (
    <ProductDescription
      {...attrs}
      name={name}
      pdsLink={pdsLink}
      isLoading={isLoading}
    />
  ) : null;

  return pdsLink ? (
    <div
      className={classNames(cx("root"), cx(className))}
      data-test-ase-product
    >
      <a href={pdsLink}>{productImage}</a>
      {productDescription}
    </div>
  ) : (
    <div
      className={classNames(cx("root"), cx(className))}
      data-test-ase-product
    >
      {productImage}
      {productDescription}
    </div>
  );
};

Product.propTypes = {
  className: PropTypes.string,
  imgSrc: PropTypes.string,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  pdsLink: PropTypes.string,
};

export default Product;
