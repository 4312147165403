import {
  IMAGE_PROFILE_DEFAULTS,
  buildSrcSetWithDefault,
  buildUrl,
} from "shared/utils/imageUrlBuilder";
import { PATH } from "./constants";

export function getIdFromPattern(img) {
  // eslint-disable-next-line prefer-regex-literals
  const rx = RegExp(".*?/([^/]+?).(?:pdf|jpg|mp4|mp3|png).*", "i");
  const match = rx.exec(img);
  return match && match[1];
}

function getImgDivElement(image) {
  return `<div key="${image.id}" data-src="${image.url}" data-type="${image.type}" data-thumbnail-image="${image.thumbnailImage}" data-alt="${image.text}" data-id="${image.id}"/>`;
}

function ensureOneImagePresent(images) {
  return images && typeof images.main !== "undefined" && images.main.length > 0
    ? images.main
    : [
        {
          defaultPattern: "https://assets.manufactum.de/c/empty.png",
          altText: "empty image",
          fallbackPreset: "?profile=pdsmain_750",
          id: "0000_00",
          pattern: "https://assets.manufactum.de/c/empty.png{preset}",
          previewPreset: "?profile=pdsmain_32",
          type: "image",
          presets: [{ sizeKey: "150w", preset: "?profile=pdsmain_150" }],
        },
      ];
}

export function normalizeImages(images, imageSettings, idSuffix = null) {
  const imageArray = ensureOneImagePresent(images);
  return imageArray.map((img) => {
    const imgUrl = img.defaultPattern || buildUrl(img.pattern, "");
    const isImage = img.type === "image";
    return {
      url: isImage ? `${imgUrl}${imageSettings}` : `${imgUrl}?thumbnail=700`,
      id: getIdFromPattern(imgUrl) + (idSuffix || ""),
      thumbnailImage: isImage
        ? `${imgUrl}?profile=pdsthumb`
        : `${imgUrl}?thumbnail=93&video.thumbPos=0.5`,
      text: img.altText,
      srcSet: buildSrcSetWithDefault(img, IMAGE_PROFILE_DEFAULTS.pdsmain),
      type: isImage ? "zoom" : "", // add this for video
    };
  });
}

function addImages(viewer, images, ignoreIndexNumber) {
  images.forEach(
    (image, index) =>
      ignoreIndexNumber !== index && viewer.insertItem(getImgDivElement(image))
  );
}

export function addOrReplaceSirvItems(viewer, currentImages, nextImages) {
  const ids = currentImages.map((o) => o.id);
  const filteredImages = nextImages.filter(({ id }) => !ids.includes(id));

  if (currentImages[0]?.id === nextImages[0]?.id) {
    addImages(viewer, filteredImages, 0);
  } else {
    viewer.removeAllItems();
    addImages(viewer, nextImages);
  }
}

let loaded = false;

export function initSirv() {
  if (loaded) {
    return;
  }
  window.loadjs(PATH, {
    success: () => {
      loaded = true;
      window.Sirv.start();
    },
  });
}

export function getSirvLoaded() {
  return typeof window !== "undefined" && typeof window.Sirv !== "undefined";
}

export function getViewer() {
  return getSirvLoaded() && window.Sirv.getInstance(".Sirv .mainimage");
}

export function isWindowDefined() {
  return typeof window !== "undefined";
}

export function loadSirv(components) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://scripts.sirv.com/sirvjs/v3/sirv.js";
    script.type = "text/javascript";
    script.async = true;
    if (components) {
      script.dataset.components = components;
    }
    script.onload = resolve;
    document.body.appendChild(script);
  });
}

export function createMobileOptions(baseImageLength, sirvThumbnails) {
  let mobileOptions = "thumbnails.enable:false; zoom:hint.enable:false;";
  let isDisabled = true;

  if (sirvThumbnails) {
    const enableThumbnail =
      baseImageLength > 1
        ? "thumbnails.enable:true; thumbnails.position:bottom; thumbnails.always:true;"
        : "thumbnails.enable:false; thumbnails.always:false;";
    isDisabled = false;
    mobileOptions = `${enableThumbnail} ${sirvThumbnails} zoom:hint.enable:false;`;
    return { mobileOptions, isDisabled };
  }

  return { mobileOptions, isDisabled };
}

export function waitForInterval() {
  const intervalId = setInterval(fname, 500); // eslint-disable-line no-unused-vars,no-use-before-define
  let counter = 0;

  function fname() {
    counter++; // eslint-disable-line no-plusplus
    if (counter >= 3) {
      document.querySelector("#pdp").style.display = "none";
      clearInterval(intervalId); // eslint-disable-line no-use-before-define
    }
  }
}
