/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconUp(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 15 15" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path d="M14.7,11.3c-0.3,0.3-0.7,0.3-1,0L7.5,5.1l-6.3,6.2c-0.3,0.3-0.7,0.3-1,0c-0.3-0.3-0.3-0.7,0-1L7,3.7c0.1-0.1,0.3-0.2,0.5-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0,0.3,0.1,0.5,0.2l6.7,6.6C15,10.6,15,11,14.7,11.3z" />
    </svg>
  );
}

export default IconUp;
