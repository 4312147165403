import React from "react";
import Select from "../Select";

type Props = {
  disabled?: boolean;
  error?: boolean | string;
  id: string;
  name?: string;
} & React.ComponentPropsWithoutRef<typeof Select>;

/**
 * InputDateMonth
 * the month of a date as drop-down
 */
function InputDateMonth({ disabled, error, id, name, ...attrs }: Props) {
  const nameAttr = name || id;

  return (
    <Select
      dateMonth
      disabled={disabled}
      error={error}
      id={id}
      name={nameAttr}
      {...attrs}
    />
  );
}

InputDateMonth.defaultProps = {
  error: "",
};

export default InputDateMonth;
