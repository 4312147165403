import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Message from "stelar/components/Message";

export default function ErrorMessage() {
  return (
    <Message
      type="error"
      title="Fehler:"
      text="Diese Seite konnte leider nicht geladen werden."
    />
  );
}
