import { Base64 } from "js-base64";

export function getCookieValue(cookieName, providedCookieString) {
  let cookieString = "";
  if (providedCookieString) {
    cookieString = providedCookieString || "";
  } else if (typeof document !== "undefined") {
    cookieString = document?.cookie || "";
  }
  return (
    cookieString.match(`(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`)?.pop() || null
  );
}

export function decodeBase64CookieValue(cookieName, providedCookieString) {
  const encodedValue = getCookieValue(cookieName, providedCookieString);
  if (encodedValue) {
    return Base64.decode(encodedValue);
  }
  return null;
}

export function getParsedObjectFromCookie(cookieName, providedCookieString) {
  const objectAsString = decodeBase64CookieValue(
    cookieName,
    providedCookieString
  );
  try {
    if (objectAsString) {
      return JSON.parse(objectAsString);
    }
  } catch (e) {
    return null;
  }
  return null;
}

function setDomain(domain) {
  switch (domain) {
    case "fr.manufactum.be":
      return "Domain=manufactum.be;";
    case "nl.manufactum.be":
      return "Domain=manufactum.be;";
    case "fr.manufactum-shop.lu":
      return "Domain=manufactum-shop.lu;";
    case "de.manufactum-shop.lu":
      return "Domain=manufactum-shop.lu;";
    case "manufactum.dk":
      return "Domain=manufactum.dk;";
    default:
      return "";
  }
}

export function updateCookieValue(cookieName, newValue, expirationDate) {
  if (typeof document === "undefined") {
    return;
  }

  let expiresAddition = "";
  if (expirationDate) {
    expiresAddition = ` Expires=${expirationDate.toUTCString()};`;
  }

  const domain = setDomain(document.location.host);
  document.cookie = `${cookieName}=${Base64.encode(
    JSON.stringify(newValue)
  )}; SameSite=None; Secure;${expiresAddition} ${domain} Path=/;`;
}
