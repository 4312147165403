/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconEvent(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 28 28" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path
        fill="#EBE9E7"
        d="M21.9,4.6h-1.4l0-1.1c0-0.7-0.6-1.3-1.3-1.3h-0.2c-0.7,0-1.3,0.6-1.3,1.3l0,1.1h2.9v1.7
        c0,0.7-0.6,1.3-1.3,1.3h-0.2c-0.7,0-1.3-0.6-1.3-1.3V4.6h-2.1l0-1.1c0-0.7-0.6-1.3-1.3-1.3h-0.2c-0.7,0-1.3,0.6-1.3,1.3l0,1.1h2.9
        v1.8c0,0.7-0.6,1.3-1.3,1.3h-0.2c-0.7,0-1.3-0.6-1.3-1.3V4.6h-2.1l0-1.1c0-0.7-0.6-1.3-1.3-1.3H8.9c-0.7,0-1.3,0.6-1.3,1.3l0,1.1
        h2.9v1.8c0,0.7-0.6,1.3-1.3,1.3H8.9c-0.7,0-1.3-0.6-1.3-1.3V4.6H6.1C5,4.6,4.2,5.5,4.2,6.5v16.4c0,1.1,0.9,1.9,1.9,1.9h15.8
        c1.1,0,1.9-0.9,1.9-1.9V6.5C23.8,5.5,23,4.6,21.9,4.6z M17.7,11.4c-0.4,0.6-0.8,1.2-1.3,2c-0.4,0.8-0.8,1.7-1.2,2.6
        c-0.4,0.9-0.7,1.9-0.9,2.9c-0.2,1-0.3,1.9-0.3,2.9h-2.1c0-0.6,0.1-1.2,0.2-1.8c0.2-0.7,0.4-1.3,0.6-2c0.3-0.7,0.6-1.3,0.9-2
        c0.3-0.6,0.7-1.3,1-1.8c0.4-0.6,0.7-1.1,1.1-1.6c0.4-0.5,0.7-0.8,1-1.1h-4.2c-0.3,0-0.6,0-0.8,0.1c-0.2,0.1-0.4,0.2-0.5,0.3
        c-0.1,0.1-0.3,0.3-0.4,0.5c-0.1,0.2-0.2,0.5-0.3,0.7h-0.3V10h7.4V11.4z"
      />
    </svg>
  );
}

export default IconEvent;
