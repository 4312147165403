const initialState = {
  data: {
    basket: {
      einloeseItems: [],
      emptyBasketMessage: "Es befinden sich keine Artikel in Ihrem Warenkorb.",
      gutscheinItems: [],
      headerLabelArticle: "",
      headerLabelArticleImage: "",
      headerLabelArticleQuantityAndShape: "",
      headerLabelArticleSum: "",
      shippingCharge: { label: "Versandkosten", value: "…,…… …" },
      shippingChargeAdvice: "",
      basketDiscountAdvice: "",
      subTotal: { label: "Zwischensumme", value: "……,…… …" },
      total: { label: "Gesamtsumme", value: "……,…… …" },
      vatLabel: "inkl. MwSt.",
      summaryItems: [
        { label: "Zwischensumme", value: "……,…… …" },
        { label: "Versandkosten", value: "…,…… …" },
      ],
      lineItems: [
        {
          moveToWishListLink: {
            label: "Zur Merkliste",
            link: "",
          },
          availability: {
            text: "vorrätig",
            info: "Lieferung in 3-4 Tagen",
            styling: "available",
            additionalInfos: [],
          },
          articleNumber: { label: "Art.Nr.", value: "……………" },
          dimensions: [],
          unitPrice: { label: "Einzelpreis", value: "……,…… …" },
          ageRelated: false,
          imgSrc: "",
          key: "",
          name: "",
          quantity: {
            valid: false,
            error: "",
            type: "integer",
            label: "Menge",
            value: 0,
            targetUrl: "",
            hasChanged: false,
          },
          lineItemId: "",
          pdsLink: "",
          changeAmount: "ändern",
          changeQuantity: "ändern",
          removeLink: {
            label: "entfernen",
            link: "",
          },
          totalPrice: "……,…… €",
          gravurTextLabel: "Individualisierung",
        },
      ],
    },
    page: {
      globalMessage: null,
      headline: "Warenkorb",
      back: { label: "Einkauf fortsetzen", link: "" },
      submitButton: {
        label: "Zur Kasse",
        link: "",
      },
      headData: {
        title: "Warenkorb | Manufactum",
        noscript: [],
      },
      title: "",
    },
    redirectTarget: "",
    shopCatalogSection: {
      catalogsHeader: "Kostenlose Kataloge",
      shopCatalogs: [
        {
          name: "……………………",
          label: "Warenkatalog Nr. …… (…………/…………) ",
          checked: false,
        },
      ],
      submit: {
        label: "",
        link: "",
      },
    },
  },
};

export default initialState;
