import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import IconMenuDE from "stelar/components/Icon/IconMenuDE";
import IconMenuEN from "stelar/components/Icon/IconMenuEN";
import Logo from "stelar/components/Logo";
import LogoCompact from "stelar/components/LogoCompact";
import TopBanner from "../TopBanner";
import s from "./Header.module.css";
import AppInstallBanner from "../AppInstallBanner";

function Header({
  banner,
  searchBox,
  myAccount,
  miniNotepad,
  miniCart,
  navigation,
  menuIconHref,
  offCanvasTitle,
  onClickLogo,
  topBanner,
  handleClickAndTracking,
  appInstallBanner,
  openOffCanvas,
}) {
  function handleMenuIconClick(e) {
    e.preventDefault();
    openOffCanvas();
  }

  return (
    <div className={s.root} data-test-sell-header>
      {appInstallBanner ? (
        <AppInstallBanner
          {...appInstallBanner}
          handleClickAndTracking={handleClickAndTracking}
        />
      ) : null}
      {topBanner ? (
        <TopBanner
          {...topBanner}
          handleClickAndTracking={handleClickAndTracking}
        />
      ) : null}
      <div className={s.top} data-test-sell-banner>
        {banner}
      </div>
      <div className={s.main} data-test-sell-head-main>
        <a
          className={s.navigationIcon}
          href={menuIconHref}
          onClick={handleMenuIconClick}
          data-test-sell-menu-icon
        >
          {offCanvasTitle === "Menu" ? (
            <IconMenuEN alt={offCanvasTitle} large />
          ) : (
            <IconMenuDE alt={offCanvasTitle} large />
          )}
        </a>

        {/* TODO a11y */}
        {/* eslint-disable jsx-a11y/control-has-associated-label */}
        <a
          href="/"
          onClick={onClickLogo}
          className={s.logoContainer}
          data-test-sell-logo
        >
          <div className={s.logoInner}>
            <Logo />
          </div>
          <div className={s.logoInnerCompact}>
            <LogoCompact />
          </div>
        </a>
        {/* eslint-enable jsx-a11y/control-has-associated-label */}
        <div className={s.searchContainer}>
          <div className={s.searchBox} data-test-sell-search-box>
            {searchBox}
          </div>
        </div>
        <div className={s.myAccount} data-test-sell-my-account>
          {myAccount}
        </div>
        <div className={s.miniNotepad} data-test-sell-mini-notepad>
          {miniNotepad}
        </div>
        <div className={s.miniCart} data-test-sell-mini-cart>
          {miniCart}
        </div>
        <div className={s.navigation} data-test-sell-navigation>
          {navigation}
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {
  banner: PropTypes.node,
  searchBox: PropTypes.node,
  myAccount: PropTypes.node,
  miniNotepad: PropTypes.node,
  miniCart: PropTypes.node,
  navigation: PropTypes.node,
  menuIconHref: PropTypes.string,
  offCanvasTitle: PropTypes.string,
  onClickLogo: PropTypes.func,
  topBanner: PropTypes.object,
  handleClickAndTracking: PropTypes.func,
  appInstallBanner: PropTypes.object,
  openOffCanvas: PropTypes.func,
};

export default Header;
