import loginPageCondition from "ase/pages/LoginPage/reducedLayoutCondition";
import changeBillingAddressPageCondition from "ase/pages/ChangeBillingAddressPage/reducedLayoutCondition";
import registerPageCondition from "ase/pages/RegisterPage/reducedLayoutCondition";
import AseFrontendUrls from "ase/pages/utils/AseFrontendUrls";

const shouldSkipNormalLayout = (path, query) => {
  const pageObjectsWithDynamicLayout = [
    [AseFrontendUrls.LOGIN, loginPageCondition],
    [AseFrontendUrls.CHANGE_BILLING_ADDRESS, changeBillingAddressPageCondition],
    [AseFrontendUrls.REGISTER, registerPageCondition],

    [AseFrontendUrls.CREDIT_CARD_PAYMENT_V3, true],
    [AseFrontendUrls.DELIVERY_ADDRESS, true],
    [AseFrontendUrls.KUZ_CREDIT_CARD_PAYMENT_V2, true],
    [AseFrontendUrls.LAST_CHECK, true],
    [AseFrontendUrls.PAYMENT, true],
  ];

  return pageObjectsWithDynamicLayout.find((conditionPair) => {
    const foundPathAtBeginning = Object.values(conditionPair[0]).some(
      (definedPath) => path.startsWith(definedPath)
    );
    return (
      foundPathAtBeginning &&
      (conditionPair[1] === true || conditionPair[1](query))
    );
  });
};

export default shouldSkipNormalLayout;
