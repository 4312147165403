import React, { useEffect, useLayoutEffect, useRef } from "react";
import s from "./Modal.module.css";
import ModalContent from "./ModalContent";

type Props = {
  children?: React.ReactNode;
  opened: boolean;
  onClose?: (...args: unknown[]) => unknown;
  title?: string;
  iconName?: "check";
  closeButtonLabel?: string;
  withoutHeader?: boolean;
};

function Modal({
  children,
  opened,
  onClose,
  title,
  iconName,
  closeButtonLabel,
  withoutHeader,
}: Props) {
  const wrapperRef = useRef(null);

  const handleEscKey = (e) => {
    if (e.keyCode === 27) {
      onClose();
    }
  };

  useEffect(() => {
    window.document.addEventListener("keydown", handleEscKey);
    return () => window.document.removeEventListener("keydown", handleEscKey);
  }, []);

  useLayoutEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      if (opened) {
        const scrollBarWidth = window.innerWidth - document.body.offsetWidth;
        document.body.style.margin = `0px ${scrollBarWidth}px 0px 0px`;
        document.body.style.overflow = "hidden";
        if (wrapperRef.current) {
          wrapperRef.current.style.paddingRight = `${scrollBarWidth + 15}px`;
          wrapperRef.current.style.overflow = "hidden";
          setTimeout(() => {
            if (wrapperRef.current) {
              wrapperRef.current.style.paddingRight = "";
              wrapperRef.current.style.overflow = "";
            }
          }, 450);
        }
      } else {
        document.body.style.margin = "";
        document.body.style.overflow = "";
      }
    }
  }, [opened]);

  const overlayContent = children ? (
    <ModalContent
      wrapperRef={wrapperRef}
      opened={opened}
      title={title}
      iconName={iconName}
      closeButtonLabel={closeButtonLabel}
      withoutHeader={withoutHeader}
      hideOverlay={onClose}
    >
      {children}
    </ModalContent>
  ) : null;

  return (
    <div className={s.root}>
      {/* TODO a11y */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        aria-hidden={!opened}
        className={s.smokedGlass}
        onClick={onClose}
        data-test-stelar-overlay-background
      />
      {/* TODO a11y */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div aria-hidden={!opened} className={s.overlayContent} onClick={onClose}>
        <div
          ref={wrapperRef}
          aria-hidden={!opened}
          className={s.overlayWrapper}
        >
          {overlayContent}
        </div>
      </div>
    </div>
  );
}

export default Modal;
