import {
  LOAD_LAYER_ADDTOCART_SUCCESS,
  LOAD_LAYER_ADDTOCART_STARTED,
} from "../../pages/constants";

export default function loadLayerContent(apiClient, sku) {
  return [
    { type: LOAD_LAYER_ADDTOCART_STARTED, payload: { sku } },
    apiClient.get(
      `/layer/addtocart/success`,
      {
        ok: (response) => ({
          type: LOAD_LAYER_ADDTOCART_SUCCESS,
          payload: { ...response.data },
        }),
      },
      {
        apiBase: "/api/sell",
        httpClientOptions: { params: { sku: encodeURIComponent(sku) } },
      }
    ),
  ];
}
