import React from "react";
import classNames from "classnames/bind";
import h from "../helpers";
import IconBubbleInfo from "../Icon/IconBubbleInfo";
import IconBubbleBang from "../Icon/IconBubbleBang";
import IconBubbleCheck from "../Icon/IconBubbleCheck";
import s from "./Message.module.css";

const cx = classNames.bind(s);

const icons = {
  attention: <IconBubbleBang />,
  error: <IconBubbleBang />,
  success: <IconBubbleCheck />,
  hint: <IconBubbleInfo />,
};

type Props = {
  title?: string;
  text?: string;
  type?: "attention" | "error" | "success" | "hint";
  linkText?: string;
  linkUrl?: string;
  onLinkClick?: (...args: unknown[]) => unknown;
  plain?: boolean;
};

/**
 * Global message which is usually displayed at the top of the page.
 */
function Message({
  title,
  text,
  type,
  linkUrl,
  linkText,
  onLinkClick,
  plain,
  ...attrs
}: Props) {
  const messageClasses = cx("root", type, plain ? "plain" : null);
  return (
    <div
      className={messageClasses}
      {...attrs}
      data-test-stelar-message-type-attention={type === "attention" || null}
      data-test-stelar-message-type-error={type === "error" || null}
      data-test-stelar-message-type-success={type === "success" || null}
      data-test-stelar-message-type-hint={type === "hint" || null}
      data-global-message
    >
      <span className={s.iconContainer}>{icons[type]}</span>
      <span className={s.messageText}>
        {title && (
          <span className={s.title} data-test-stelar-message-title>
            {title}{" "}
          </span>
        )}
        {text && (
          <span className={s.text} data-test-stelar-message-text>
            {h.linebreaksToHtmlBrs(text)}
          </span>
        )}
        {linkUrl && linkText ? (
          <a href={linkUrl} onClick={onLinkClick} className={s.link}>
            {linkText}
          </a>
        ) : null}
      </span>
    </div>
  );
}

export default Message;
