/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconRight(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 12 22" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path d="M11.3,10.3L11.3,10.3L1.7,0.7c-0.3-0.3-0.7-0.3-1,0L0.7,0.8c-0.3,0.3-0.3,0.7,0,1L9.9,11l-9.2,9.2c-0.3,0.3-0.3,0.7,0,1l0.1,0.1c0.3,0.3,0.7,0.3,1,0l9.6-9.6v0c0.2-0.2,0.3-0.4,0.3-0.7l0-0.1C11.6,10.7,11.5,10.5,11.3,10.3z" />
    </svg>
  );
}

export default IconRight;
