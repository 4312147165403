import update from "immutability-helper";
import {
  ACCOUNT_DELETION_PAGE__LOAD_PAGE,
  ACCOUNT_DELETION_PAGE__SUBMIT_FAILED,
  ACCOUNT_DELETION_PAGE__SUBMIT,
} from "../constants";

const initialState = {
  formDefinition: {},
  page: {},
  submitStarted: false,
};

const accountDeletionPage = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_DELETION_PAGE__LOAD_PAGE:
      return update(state, { $set: action.payload });
    case ACCOUNT_DELETION_PAGE__SUBMIT:
      return update(state, {
        submitStarted: { $set: true },
      });
    case ACCOUNT_DELETION_PAGE__SUBMIT_FAILED:
      return update(state, {
        formDefinition: { $set: action.payload.formDefinition },
        page: { $set: action.payload?.page },
        submitStarted: { $set: false },
      });

    default:
      return state;
  }
};

export default accountDeletionPage;
