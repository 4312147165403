import React from "react"; // eslint-disable-line import/no-extraneous-dependencies

export function renderWithProps(children, props) {
  return React.Children.map(children, (child) =>
    React.cloneElement(child, props)
  );
}

function isLeftOfScreen(el) {
  return el.offsetLeft + el.offsetWidth / 2 < el.offsetParent.offsetWidth / 2;
}

function isMiddleOfScreen(el) {
  return (
    el.offsetLeft + el.offsetWidth / 2 > el.offsetParent.offsetWidth / 4 &&
    el.offsetLeft + el.offsetWidth / 2 < (el.offsetParent.offsetWidth / 4) * 3
  );
}

export function getFlyoutAlignment(el) {
  const align = isLeftOfScreen(el) ? "left" : "right";
  const middle = isMiddleOfScreen(el) ? "mid" : "";
  return middle + align;
}

export default null;
