import { initialState, LOAD_NOT_FOUND_SUCCESS } from "./constants";

export default function notFound(state = initialState, action) {
  switch (action.type) {
    case LOAD_NOT_FOUND_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
