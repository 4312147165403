import {
  LOAD_FEEDBACK_STRUCTURE_FAILED,
  LOAD_FEEDBACK_STRUCTURE_STARTED,
  LOAD_FEEDBACK_STRUCTURE_SUCCESS,
  SEND_FEEDBACK_RATING_FAILED,
  SEND_FEEDBACK_RATING_STARTED,
  SEND_FEEDBACK_RATING_SUCCESS,
  SEND_FEEDBACK_TEXT_FAILED,
  SEND_FEEDBACK_TEXT_STARTED,
  SEND_FEEDBACK_TEXT_SUCCESS,
} from "./constants";

export function loadFeedbackStructure(apiClient, feedbackType, isClient) {
  return [
    { type: LOAD_FEEDBACK_STRUCTURE_STARTED },
    apiClient.get(
      `/feedback/config/${feedbackType}`,
      {
        ok: (response) => ({
          type: LOAD_FEEDBACK_STRUCTURE_SUCCESS,
          payload: { ...response.data },
        }),
        noContent: () => ({
          type: LOAD_FEEDBACK_STRUCTURE_FAILED,
        }),
      },
      {
        apiBase: "/api/sell",
        ...(!isClient && { httpClientOptions: { timeout: 1000 } }),
        handleUnexpected: () => ({
          type: LOAD_FEEDBACK_STRUCTURE_FAILED,
        }),
      }
    ),
  ];
}

export function submitRatingFeedback(apiClient, payload, metadata) {
  return [
    { type: SEND_FEEDBACK_RATING_STARTED },
    apiClient.post(
      "/feedback/add",
      { metadata, ...payload },
      {
        created: () => ({
          type: SEND_FEEDBACK_RATING_SUCCESS,
        }),
      },
      {
        apiBase: "/api/sell",
        handleUnexpected: () => ({
          type: SEND_FEEDBACK_RATING_FAILED,
        }),
      }
    ),
  ];
}

export function submitTextFeedback(apiClient, payload) {
  return [
    { type: SEND_FEEDBACK_TEXT_STARTED },
    apiClient.put(
      "/feedback/text",
      payload,
      {
        created: () => ({
          type: SEND_FEEDBACK_TEXT_SUCCESS,
        }),
      },
      {
        apiBase: "/api/sell",
        handleUnexpected: () => ({
          type: SEND_FEEDBACK_TEXT_FAILED,
        }),
      }
    ),
  ];
}
