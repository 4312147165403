import classnames from "classnames/bind";
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import EnergyEfficiency from "stelar/components/EnergyEfficiency";

import Flags from "../Flags";
import ProductTileImage from "./ProductTileImage";
import ProductTileText from "./ProductTileText";

import s from "./ProductTile.module.css";

const cx = classnames.bind(s);
function ProductTile({
  image,
  images = [],
  imagePreview,
  imageSrcSet,
  imageUrl,
  imageSizes, // Optional: Nur setzen wenn wirklich gebraucht
  imageProfileDefault,
  energyEfficiency,
  lazy,
  nativeLazy,
  link,
  manufacturer,
  additionalVariationsText,
  minimal,
  name,
  nameAdditional,
  netWeight,
  flags,
  onLinkClick,
  price,
  tracking,
  skeleton,
  type,
}) {
  function onClick(e) {
    onLinkClick(e, tracking);
  }

  const textbox = (
    <ProductTileText
      name={name}
      manufacturer={manufacturer}
      nameAdditional={nameAdditional}
      additionalVariationsText={additionalVariationsText}
      price={price}
      netWeight={netWeight}
      skeleton={skeleton}
      type={type}
    />
  );

  return (
    <div
      className={cx("root", { separated: type === "recommendation" })}
      data-test-sell-product-tile
    >
      <div className={s.wrapper}>
        <a
          href={link}
          className={s.link}
          onClick={onClick}
          data-test-sell-product-link
        >
          <div className={s.box}>
            <div className={s.imagebox}>
              <ProductTileImage
                image={images[0] || image}
                imageProfileDefault={imageProfileDefault}
                imageUrl={imageUrl}
                imagePreview={imagePreview}
                imageSrcSet={imageSrcSet}
                imageSizes={imageSizes}
                name={name}
                lazy={lazy}
                nativeLazy={nativeLazy}
                minimal={minimal}
              />
              <Flags
                topStyle="productTile"
                data-test-sell-producttile-flags
                {...flags}
              />
            </div>
            <div className={s.textbox}>{textbox}</div>
          </div>
        </a>
        {energyEfficiency ? (
          <div className={s.eelContainer}>
            <EnergyEfficiency variant="compact" {...energyEfficiency} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

ProductTile.propTypes = {
  energyEfficiency: PropTypes.object,
  image: PropTypes.object,
  images: PropTypes.array,
  imagePreview: PropTypes.string,
  imageSrcSet: PropTypes.string,
  imageSizes: PropTypes.string,
  imageUrl: PropTypes.string,
  imageProfileDefault: PropTypes.object,
  lazy: PropTypes.bool,
  nativeLazy: PropTypes.bool,
  link: PropTypes.string,
  manufacturer: PropTypes.string,
  minimal: PropTypes.bool,
  name: PropTypes.string,
  nameAdditional: PropTypes.string,
  additionalVariationsText: PropTypes.string,
  netWeight: PropTypes.string,
  flags: PropTypes.object,
  onLinkClick: PropTypes.func,
  price: PropTypes.object,
  tracking: PropTypes.array,
  skeleton: PropTypes.bool,
  type: PropTypes.oneOf(["normal", "recommendation"]),
};

export default ProductTile;
