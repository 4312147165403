// vendor
import update from "immutability-helper";
// ase
import { validateInput } from "../utils/Validators";
import {
  LAST_CHECK_PAGE__CREATE_ORDER_FAILED,
  LAST_CHECK_PAGE__CREATE_ORDER_STARTED,
  LAST_CHECK_PAGE__LOAD_LAST_CHECK_PAGE,
  LAST_CHECK_PAGE__TOGGLE_AGE_RELATED_ADVICE,
  LAST_CHECK_PAGE__UPDATE_DELIVERY_METHOD,
  LAST_CHECK_PAGE__UPDATE_LINE_ITEM_QUANTITY,
  LAST_CHECK_PAGE__VALIDATE_FOR_ORDER_SUBMISSION,
  LAST_CHECK_PAGE__VALIDATE_INPUT,
} from "../constants";
// component
import initialState from "./initialState";
import { validateForOrderSubmission } from "./validation";
import validateQuantityField from "../../components/validation/numerics";

const lastCheck = (state = initialState, action) => {
  const items = {};
  let updateObject;
  let index;
  switch (action.type) {
    case LAST_CHECK_PAGE__UPDATE_DELIVERY_METHOD:
      return update(state, {
        customer: {
          deliveryMethod: {
            selected: {
              $set: action.value,
            },
          },
        },
      });
    case LAST_CHECK_PAGE__VALIDATE_INPUT:
      return validateInput(state, action);
    case LAST_CHECK_PAGE__VALIDATE_FOR_ORDER_SUBMISSION:
      return validateForOrderSubmission(state);
    case LAST_CHECK_PAGE__CREATE_ORDER_STARTED:
      return update(state, {
        createOrderStarted: {
          $set: true,
        },
      });
    case LAST_CHECK_PAGE__LOAD_LAST_CHECK_PAGE:
      if (action.payload) {
        updateObject = action.payload;
        updateObject.submitable = true;
        updateObject.submitButtonDisabled = false;
        updateObject.createOrderStarted = false;
        return update(state, {
          $set: updateObject,
        });
      }
      return state;
    case LAST_CHECK_PAGE__TOGGLE_AGE_RELATED_ADVICE:
      index = state.basket.lineItems.findIndex(
        (li) => li.lineItemId === action.lineItemId
      );
      return update(state, {
        basket: {
          lineItems: {
            [index]: {
              ageRelatedAdviceVisible: {
                $apply: (visible) => !visible,
              },
            },
          },
        },
      });
    case LAST_CHECK_PAGE__UPDATE_LINE_ITEM_QUANTITY:
      index = state.basket.lineItems.findIndex(
        (li) => li.lineItemId === action.lineItemId
      );
      items[index] = {
        quantity: {
          value: { $set: action.quantity },
          valid: {
            $set: validateQuantityField(action.quantity, action.fieldType),
          },
          hasChanged: { $set: true },
        },
      };
      return update(state, {
        basket: { lineItems: items },
      });
    case LAST_CHECK_PAGE__CREATE_ORDER_FAILED:
      if (action.payload?.page?.globalMessage) {
        return update(state, {
          page: {
            globalMessage: { $set: action.payload.page.globalMessage },
          },
          createOrderStarted: {
            $set: false,
          },
        });
      }
      return state;
    default:
      return state;
  }
};

export default lastCheck;
