/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "../common";

function IconCatalog(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 28 28" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path d="M23.2 5.4c0 0-3.6-0.6-5.5-0.2 -1.3 0.3-2.5 1.6-3.1 2v11.9c1-0.2 3.4-2.4 5.8-2.4 3-0.1 7.3 1.5 7.3 1.5L23.2 5.4z" />
      <path d="M4.8 5.4c0 0 3.6-0.6 5.5-0.2 1.3 0.3 2.3 1.6 3 2v11.9c-1-0.2-3.3-2.4-5.7-2.4 -3-0.1-7.3 1.5-7.3 1.5L4.8 5.4z" />
      <path d="M27 19.6h-9.2l-1.2 0c0 0-0.7 2-2.6 2 -2 0-2.7-2-2.7-2l-1.1 0H1c-0.4 0-0.8 0.3-0.8 0.6 0 0.3 0.4 0.6 0.8 0.6h9.3c0.5 1 1.9 2.1 3.7 2.1 1.8 0 3.2-1 3.6-2.1H27c0.4 0 0.8-0.3 0.8-0.6C27.8 19.9 27.4 19.6 27 19.6z" />
    </svg>
  );
}

export default IconCatalog;
