// vendor
import { connect } from "react-redux";
import { provideHooks } from "redial";
import track from "shared/tracking";
import loadable from "@loadable/component";
import Loading from "shared/components/Loading";
// ase
import changeInputAction from "shared/utils/actionReducer/changeInput/ChangeInputAction";
import loadReducedLayout from "../../components/ReducedLayout/actions";
import frontendUrls from "../utils/AseFrontendUrls";
import { PAYMENT_PAGE__PAGE_NAME } from "../constants";
// component
import {
  changePayment,
  loadPaymentPage,
  setPayment,
  validateInput,
} from "./actions";

const page = PAYMENT_PAGE__PAGE_NAME;

const redial = {
  fetch: ({ apiClient, dispatch, getState, query }) =>
    Promise.all([
      dispatch(loadPaymentPage(apiClient, query)),
      dispatch(loadReducedLayout(apiClient, getState)),
    ]),
  defer: ({ getState }) => track(getState()?.paymentPage?.page?.trackingInfo),
};

const mapDispatchToProps = (dispatch, props) => ({
  changePayment: (paymentMethod) => dispatch(changePayment(paymentMethod)),
  ops: {
    changeAccountHolder: changeInputAction(dispatch)(
      page,
      "directDebit",
      "accountHolder"
    ),
    changeBic: changeInputAction(dispatch)(page, "directDebit", "bic"),
    changeIban: changeInputAction(dispatch)(page, "directDebit", "iban"),
  },
  setPayment: (args) => dispatch(setPayment(args, props.apiClient)),
  validateInput: (inputPath, errorPath) =>
    dispatch(validateInput(inputPath, errorPath)),
});

const mapStateToProps = (state) => ({
  ...state.paymentPage,
  isLoading: !state.paymentPage.page.headData,
  reducedLayout: state.reducedLayout,
});

const LoadablePaymentPage = loadable(
  () =>
    import(/* webpackChunkName: "payment-page" */ "./components/PaymentPage"),
  {
    fallback: Loading,
  }
);

export default {
  component: provideHooks(redial)(
    connect(mapStateToProps, mapDispatchToProps)(LoadablePaymentPage)
  ),
  path: frontendUrls.PAYMENT,
};
