import React from "react"; // eslint-disable-line import/no-extraneous-dependencies

const helpers = {
  linebreaksToHtmlBrs(stringWithLinebreaks) {
    const linebreak = "\n";
    if (stringWithLinebreaks.indexOf(linebreak) < 0)
      return stringWithLinebreaks;

    return stringWithLinebreaks.split(linebreak).map((item, key) => (
      <span key={`span_${key + 1}`}>
        {item}
        <br />
      </span>
    ));
  },
  stabilize(valueAndUnit) {
    return valueAndUnit && valueAndUnit.toString().replace(/\s+/g, "\u00a0"); // \u00a0 = &nbsp;
  },
  selectAll(event) {
    event.target.select();
    return true;
  },
};

export default helpers;
