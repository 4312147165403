import {
  LOAD_LAYER_CONTENT_SUCCESS,
  LOAD_LAYER_CONTENT_STARTED,
} from "../../pages/constants";

export default function loadLayerContent(apiClient, content) {
  return [
    { type: LOAD_LAYER_CONTENT_STARTED },
    apiClient.get(
      `/overlay/content/${encodeURIComponent(content)}`,
      {
        ok: (response) => ({
          type: LOAD_LAYER_CONTENT_SUCCESS,
          payload: { ...response.data },
        }),
      },
      {
        apiBase: "/api/sell",
      }
    ),
  ];
}
