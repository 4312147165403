// import queryString from "query-string";
import { statusRedirect } from "shared/utils/status/actions";
import { setPageTitle } from "shared/webbridge/actions";
import {
  LOAD_SEARCH_FAILED_REQUEST,
  LOAD_SEARCH_STARTED_REQUEST,
  LOAD_SEARCH_SUCCESS_REQUEST,
} from "./constants";

export function loadSearch(
  apiClient,
  {
    q = "",
    p = "",
    sortby,
    targetgroup,
    manufacturer,
    color,
    material,
    size,
    availability,
    price,
    category,
    stp,
  }
) {
  const parameters = {
    p,
    sortby,
    targetgroup,
    manufacturer,
    color,
    material,
    size,
    availability,
    price,
    category,
    stp,
  };
  const encodedQuery = encodeURIComponent(q.replace(/\/|\\/g, ""));

  return [
    { type: LOAD_SEARCH_STARTED_REQUEST },
    apiClient.get(
      `/search/${encodedQuery}`,
      {
        ok: (response) => {
          const location = response?.data?.redirectUrl;
          if (location) {
            return statusRedirect(location, 302);
          }
          return [
            {
              type: LOAD_SEARCH_SUCCESS_REQUEST,
              payload: { ...response.data },
            },
            setPageTitle(response.data?.head?.title),
          ];
        },
        badRequest: () => ({ type: LOAD_SEARCH_FAILED_REQUEST }),
      },
      { apiBase: "/api/sell", httpClientOptions: { params: parameters } }
    ),
  ];
}
