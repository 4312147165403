import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import SmileyRatingIcon from "stelar/components/SmileyRatingIcon";
import s from "./FeedbackRatingInput.module.css";

export default function FeedbackRatingInput({ name, value, onChange }) {
  return (
    <div className={s.root}>
      <div className={s.inputs}>
        {[1, 2, 3, 4, 5].map((val) => (
          <div className={s.inputContainer} key={val}>
            {/* eslint-disable jsx-a11y/label-has-associated-control */}
            <label htmlFor={`${name}_${val}`} className={s.label}>
              <input
                id={`${name}_${val}`}
                type="radio"
                name={name}
                value={val}
                checked={val === value}
                className={s.input}
                onChange={(e) => {
                  onChange(e.currentTarget.value);
                }}
              />
              <SmileyRatingIcon rating={val} />
            </label>
            {/* eslint-enable jsx-a11y/label-has-associated-control */}
          </div>
        ))}
      </div>
    </div>
  );
}

FeedbackRatingInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};
