// redux actions
export const LOAD_CATEGORY_STARTED = "LOAD_CATEGORY_STARTED";
export const LOAD_CATEGORY_SUCCESS = "LOAD_CATEGORY_SUCCESS";

// redux initial state for reducer
export const initialState = {
  data: {}, // server responses grouped by key-identifier
  keys: [], // list of the last opened keys, recent ones first
  maxKeys: 20,
};
