import React from "react";
import s from "./AdvantageList.module.css";

type Props = {
  children?: React.ReactNode;
};

function AdvantageList({ children, ...args }: Props) {
  const items = children
    ? React.Children.map(
        children,
        (item: React.ReactElement) =>
          item &&
          React.cloneElement(
            item,
            { margin: false },
            item.props && item.props.children
          )
      )
    : null;
  return React.createElement("ul", { className: s.root, ...args }, items);
}

export default AdvantageList;
