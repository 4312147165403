// vendor
import classnames from "classnames/bind";
import PropTypes from "prop-types";
import React, { useState } from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import HeaderBasket from "stelar/components/HeaderIcon/HeaderBasket";
import media from "stelar/components/media";
// component
import MiniBasket from "./MiniBasket";
import s from "./MiniBasketFragment.module.css";

const cx = classnames.bind(s);

const MiniBasketFragment = ({
  basketData,
  baseData,
  uiState: { overlayLoaded },
  onClick,
}) => {
  const [overlayVisible, setOverlayVisible] = useState();
  const { active, link, summary, overlayEnabled } = baseData;

  const showOverlay = () => {
    const windowTooSmall = window.innerWidth < media.breakpoint.xl.min;
    if (windowTooSmall) return;
    setOverlayVisible(true);
  };

  const hideOverlay = () => {
    setOverlayVisible(false);
  };

  const closeOverlayOnClick = (clickFn) => (e) => {
    hideOverlay();
    clickFn(e);
  };

  const rootClasses = cx("root", {
    overlayVisible,
  });

  const handleTouchEnd = (e) => {
    if (!overlayVisible && overlayEnabled) {
      const windowTooSmall = window.innerWidth < media.breakpoint.xl.min;
      if (!windowTooSmall) {
        e.preventDefault();
      }
      showOverlay(e);
    } else {
      setTimeout(() => {
        setOverlayVisible(false);
      }, 1);
    }
  };

  function withOverlay() {
    return (
      <div
        className={rootClasses}
        onMouseEnter={showOverlay}
        onMouseLeave={hideOverlay}
        data-test-ase-mini-basket
      >
        <div className={s.a11yHelper}>{summary?.label}</div>
        <HeaderBasket
          active={active}
          link={link}
          onTouchEnd={handleTouchEnd}
          role="button"
          text={summary?.value}
          onClick={closeOverlayOnClick(onClick)}
        />
        <MiniBasket
          hideOverlay={hideOverlay}
          visible={overlayVisible}
          handleClick={closeOverlayOnClick(onClick)}
          {...basketData}
        />
      </div>
    );
  }

  function withoutOverlay() {
    return (
      <HeaderBasket
        active={active}
        link={link}
        text={summary?.value}
        onClick={onClick}
      />
    );
  }

  return overlayEnabled && overlayLoaded ? withOverlay() : withoutOverlay();
};

MiniBasketFragment.propTypes = {
  active: PropTypes.bool,
  data: PropTypes.object,
  hideOverlay: PropTypes.func,
  showOverlay: PropTypes.func,
  total: PropTypes.string,
  uiState: PropTypes.object,
};

export default MiniBasketFragment;
