import Heading from "stelar/components/Heading";
import Paragraph from "stelar/components/Paragraph";
import { breakpoint } from "stelar/components/media";
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies

import { AB_TEST_NAMES, isTestgroupB } from "shared/utils/abTest";
import useMediaQuery from "shared/utils/useMediaQuery";
import Accordion from "../Accordion/Accordion";
import AccordionItem from "../Accordion/AccordionItem";

import s from "./UpperBenefits.module.css";

function UpperBenefits({ headline, columns }) {
  const isMobileBreakpoint = useMediaQuery(
    `(max-width: ${breakpoint.s.max}px)`,
    false
  );

  const abTest = isTestgroupB(AB_TEST_NAMES.PDS_ACCORDION);

  const regularLayout = (
    <div className={s.root}>
      <Heading prio2 className={s.headline}>
        {headline}
      </Heading>
      <div className={s.columns}>
        {columns.map((column) => (
          <div className={s.column} key={column.headline}>
            <Heading prio3>{column.headline}</Heading>
            <Paragraph>{column.text}</Paragraph>
          </div>
        ))}
      </div>
    </div>
  );

  if (isMobileBreakpoint && abTest) {
    return (
      <Accordion noMarginBottom>
        <AccordionItem
          id="upperBenefits"
          header={headline}
          content={columns.map((column) => (
            <div className={s.column} key={column.headline}>
              <Heading prio3>{column.headline}</Heading>
              <Paragraph>{column.text}</Paragraph>
            </div>
          ))}
        />
      </Accordion>
    );
  }
  return regularLayout;
}

UpperBenefits.propTypes = {
  headline: PropTypes.string,
  columns: PropTypes.array,
};

export default UpperBenefits;
