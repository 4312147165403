import React from "react";
import Text from "../Text";

import s from "./ListInlineItem.module.css";

type Props = {
  children?: React.ReactNode;
  inverted?: boolean;
};

function ListInlineItem({ children, inverted }: Props) {
  return (
    <li className={s.inlineItem}>
      <Text normal inverted={inverted}>
        {children}
      </Text>
    </li>
  );
}

export default ListInlineItem;
