// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import ContentHeading from "stelar/components/ContentHeading";
import Heading from "stelar/components/Heading";
import Logo from "stelar/components/Logo";
import Paragraph from "stelar/components/Paragraph";
// ase
import markdown from "../../../util/markdownLinkBuilder";
// component
import s from "./ConfirmationAndContact.module.css";

const ConfirmationAndContact = ({ contact, greeting, isLoading, text }) => {
  // TODO: prepare data in model/api
  function textToHTML(transtext) {
    const regex = /(\n)/g;
    return (
      transtext
        .split(regex)
        // eslint-disable-next-line react/no-array-index-key
        .map((line, i) => (line.match(regex) ? <br key={i} /> : line))
    );
  }

  const addressElement = (address) =>
    address ? (
      <div>
        <ContentHeading prio3 skeleton={isLoading}>
          {address.label}
        </ContentHeading>
        <Paragraph skeleton={isLoading}>{textToHTML(address.value)}</Paragraph>
      </div>
    ) : null;

  return (
    <section className={s.root}>
      <div className={s.summary}>
        {greeting && (
          <Heading prio1 skeleton={isLoading}>
            {greeting}
          </Heading>
        )}
        {text && (
          <Paragraph skeleton={isLoading}>
            <span
              // omitting this span causes console warning
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: markdown(text),
              }}
            />
          </Paragraph>
        )}
      </div>
      {contact && (
        <aside className={s.addresses}>
          <div className={s.logo}>
            <Logo />
          </div>
          <div>
            {addressElement(contact.postalAddress)}
            {addressElement(contact.address)}
            <div>
              <Paragraph skeleton={isLoading}>{contact.phone}</Paragraph>
            </div>
          </div>
        </aside>
      )}
    </section>
  );
};

ConfirmationAndContact.propTypes = {
  contact: PropTypes.object,
  greeting: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

export default ConfirmationAndContact;
