import classNames from "classnames/bind";
import React from "react";
import s from "./SmileyRatingIcon.module.css";
import {
  SMILEY_RATING_BOLD_HOVER_ICONS,
  SMILEY_RATING_BOLD_ICONS,
  SMILEY_RATING_LIGHT_HOVER_ICONS,
  SMILEY_RATING_LIGHT_ICONS,
} from "./iconCollection";

const cx = classNames.bind(s);

function iconsBy(size, rating) {
  switch (size) {
    case "light":
      return {
        icon: SMILEY_RATING_LIGHT_ICONS[rating - 1],
        hoverIcon: SMILEY_RATING_LIGHT_HOVER_ICONS[rating - 1],
      };
    case "bold":
      return {
        icon: SMILEY_RATING_BOLD_ICONS[rating - 1],
        hoverIcon: SMILEY_RATING_BOLD_HOVER_ICONS[rating - 1],
      };
    default:
      return {
        icon: SMILEY_RATING_BOLD_ICONS[rating - 1],
        hoverIcon: SMILEY_RATING_BOLD_HOVER_ICONS[rating - 1],
      };
  }
}

type Props = {
  iconStyle?: "bold" | "light";
  size?: "large" | "medium" | "small" | "tiny";
  rating?: 1 | 2 | 3 | 4 | 5;
  label?: string;
};

function SmileyRatingIcon({
  rating,
  size = "large",
  iconStyle = "bold",
  label,
}: Props) {
  const { icon, hoverIcon } = iconsBy(iconStyle, rating);
  return (
    <figure className={s.wrapper} aria-label={label}>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img
        className={cx("root", "hoverIcon", size)}
        src={hoverIcon}
        aria-hidden
      />
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img className={cx("root", "defaultIcon", size)} src={icon} aria-hidden />
    </figure>
  );
}

export default SmileyRatingIcon;
