import StelarOffCanvas from "stelar/components/OffCanvas";
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies

import OffCanvasNavigationFragment from "../sell-off-canvas-navigation/OffCanvasNavigationFragment";
import { addOrRemoveClassInContent } from "./helper";

function OffCanvas({
  header,
  offCanvas,
  offCanvasActions,
  aseOffCanvasItems,
  navigationState,
  navigationActions,
}) {
  if (!header) {
    return null;
  }

  const isOpen = offCanvas?.isLanguageSelectionOpen || false;
  const toggle = () => {
    if (isOpen) {
      offCanvasActions.closeLanguageSelectionOffCanvas();
    } else {
      offCanvasActions.openLanguageSelectionOffCanvas();
    }
  };

  addOrRemoveClassInContent(offCanvas.isDrawerOpen);

  return (
    <StelarOffCanvas
      data-test-offcanvas
      data-test-offcanvas-state={offCanvas.isDrawerOpen}
      active={offCanvas.isDrawerOpen}
      headerText={header?.navigation?.offCanvasTitle || ""}
      navigation={
        <OffCanvasNavigationFragment
          header={header}
          navigationState={navigationState}
          navigationActions={navigationActions}
          isLanguageSelectionOpen={offCanvas?.isLanguageSelectionOpen}
          aseOffCanvasItems={aseOffCanvasItems}
          offCanvasActions={offCanvasActions}
        />
      }
      onClose={offCanvasActions.onCloseOffCanvas}
      country={header?.country}
      countryToggle={isOpen}
      handleCountryToggle={toggle}
    />
  );
}

OffCanvas.propTypes = {
  header: PropTypes.object,
  offCanvas: PropTypes.object,
  offCanvasActions: PropTypes.object,
  aseOffCanvasItems: PropTypes.object,
  navigationState: PropTypes.object,
  navigationActions: PropTypes.object,
};

export default OffCanvas;
