// vendor
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
// mf-stelar
import ListInline from "stelar/components/ListInline";
import ListInlineItem from "stelar/components/ListInlineItem";
// ase
import s from "./LegalLinks.module.css";

import overlayTrigger from "../../../util/overlayTrigger";

function LegalLinks({ legal }) {
  const { agb, datenschutz, impressum } = legal;

  return (
    <div className={s.root}>
      <ListInline>
        <ListInlineItem>
          <a
            href={agb.link}
            data-overlay-content-type="agb"
            onClick={overlayTrigger}
          >
            {agb.label}
          </a>
        </ListInlineItem>
        <ListInlineItem>
          <a
            href={datenschutz.link}
            data-overlay-content-type="privacy"
            onClick={overlayTrigger}
          >
            {datenschutz.label}
          </a>
        </ListInlineItem>
        <ListInlineItem>
          <a
            href={impressum.link}
            data-overlay-content-type="imprint"
            onClick={overlayTrigger}
          >
            {impressum.label}
          </a>
        </ListInlineItem>
      </ListInline>
    </div>
  );
}

LegalLinks.propTypes = {
  legal: PropTypes.shape({
    agb: PropTypes.object.isRequired,
    datenschutz: PropTypes.object.isRequired,
    impressum: PropTypes.object.isRequired,
  }),
};

export default LegalLinks;
