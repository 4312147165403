import backendUrl from "../../pages/utils/AseBackendUrls";
import {
  GUTSCHEIN_WERTABFRAGE_FRAGMENT__CHANGE_INPUT,
  GUTSCHEIN_WERTABFRAGE_FRAGMENT__GET,
  GUTSCHEIN_WERTABFRAGE_FRAGMENT__POST,
  GUTSCHEIN_WERTABFRAGE_FRAGMENT__POST_FAILED,
  GUTSCHEIN_WERTABFRAGE_FRAGMENT__POST_SUCCESS,
  GUTSCHEIN_WERTABFRAGE_FRAGMENT__VALIDATE,
} from "../../pages/constants";

export function loadGutscheinWertabfrageFragmentData(apiClient, layout) {
  const layoutAttr = layout === undefined ? "" : `?layout=${layout}`;
  const url = `${backendUrl.GUTSCHEIN_WERTABFRAGE}${layoutAttr}`;
  return apiClient.get(url, {
    ok: (content) => ({
      type: GUTSCHEIN_WERTABFRAGE_FRAGMENT__GET,
      payload: content.body,
    }),
  });
}

export function submitForm({ event, url, values }, apiClient) {
  event.preventDefault();
  return (dispatch, getState) => {
    dispatch({
      type: GUTSCHEIN_WERTABFRAGE_FRAGMENT__VALIDATE,
    });
    const state = getState();
    if (state.gutscheinWertabfrageFragment.submitable) {
      dispatch([
        { type: GUTSCHEIN_WERTABFRAGE_FRAGMENT__POST },
        apiClient.post(url, values, {
          ok: (content) => ({
            type: GUTSCHEIN_WERTABFRAGE_FRAGMENT__POST_SUCCESS,
            payload: content.body,
          }),
          badRequest: (content) => ({
            type: GUTSCHEIN_WERTABFRAGE_FRAGMENT__POST_FAILED,
            payload: content.body,
          }),
        }),
      ]);
    }
    return true;
  };
}

export function updateInput(event) {
  const { name, value } = event.target;
  return {
    type: GUTSCHEIN_WERTABFRAGE_FRAGMENT__CHANGE_INPUT,
    payload: { name, value },
  };
}
