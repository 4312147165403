import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classNames from "classnames/bind";
import { ButtonX } from "stelar/components/Button";
import s from "./MiddleLevel.module.css";
import { renderWithProps } from "../util";

const cx = classNames.bind(s);

const MiddleLevel = ({ compact, children, closeLabel, onClose, level }) => (
  <div
    className={cx("root", { compact, flyout: !compact })}
    {...{
      [`data-test-sell-flyout-level-${level}`]: true,
    }}
  >
    <div className={s.content}>{renderWithProps(children, { compact })}</div>
    {closeLabel ? (
      <div className={s.footer}>
        <div className={s.footerInner}>
          <ButtonX
            highContrast
            text={closeLabel}
            onClick={onClose}
            {...{
              [`data-test-sell-flyout-close-button-level-${level}`]: true,
            }}
          />
        </div>
      </div>
    ) : null}
  </div>
);

MiddleLevel.propTypes = {
  children: PropTypes.node,
  closeLabel: PropTypes.string,
  onClose: PropTypes.func,
  compact: PropTypes.bool,
  level: PropTypes.number,
};

export default MiddleLevel;
