import React from "react";
import Input from "./Input";

type Props = {
  autoComplete?: string;
  error?: boolean | string;
} & React.ComponentPropsWithoutRef<typeof Input>;

/**
 * InputEmail
 */
function InputEmail({
  autoComplete = "email",
  error = "",
  innerRef,
  ...attrs
}: Props) {
  return (
    <Input
      autoComplete={autoComplete}
      className="email"
      error={error}
      type="email"
      innerRef={innerRef}
      {...attrs}
    />
  );
}

export default InputEmail;
