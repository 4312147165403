import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import { Button } from "stelar/components/Button";
import ContentHeading from "stelar/components/ContentHeading";
import Paragraph from "stelar/components/Paragraph";
import { IMAGE_PROFILE_DEFAULTS } from "shared/utils/imageUrlBuilder";
import RatioImage from "../../RatioImage";
import s from "./WarehouseItem.module.css";

const cx = classnames.bind(s);

function WarehouseItem({
  heading,
  image = {},
  openHours,
  address,
  phone,
  link,
  lazy,
  imgSizes,
  variant = "stacked",
  onLinkClick,
  onImageLoad,
  loadedImages = {},
}) {
  const imageNode = (
    <RatioImage
      image={image}
      imageProfileDefault={IMAGE_PROFILE_DEFAULTS.kurator}
      lazy={lazy}
      sizes={imgSizes}
      onImageLoad={onImageLoad}
      hasLoaded={loadedImages[image.pattern]}
      data-test-sell-warehouse-image
    />
  );
  return (
    <div className={cx("root", { [variant]: true })} data-test-sell-warehouse>
      {link ? (
        <a
          href={link.link}
          onClick={onLinkClick}
          className={s.image}
          tabIndex={-1}
          aria-hidden
        >
          {imageNode}
        </a>
      ) : (
        <div className={s.image}>{imageNode}</div>
      )}
      <div className={s.text}>
        {heading && (
          <div className={s.title}>
            <div className={s.clamp}>
              <ContentHeading prio2 tag="h3" data-test-sell-warehouse-name>
                {link ? (
                  <a href={link.link} data-prio2>
                    {heading}
                  </a>
                ) : (
                  heading
                )}
              </ContentHeading>
            </div>
          </div>
        )}
        {openHours && (
          <div className={s.block}>
            {openHours.line1 && (
              <div data-test-sell-warehouse-openhours-line1 className={s.line}>
                {openHours.line1}
              </div>
            )}
            {openHours.line2 && (
              <div data-test-sell-warehouse-openhours-line2 className={s.line}>
                {openHours.line2}
              </div>
            )}
          </div>
        )}
        {address && (
          <div className={s.block}>
            {address.street && (
              <div data-test-sell-warehouse-address-street className={s.line}>
                {address.street}
              </div>
            )}
            {address.city && (
              <div data-test-sell-warehouse-address-city className={s.line}>
                {address.city}
              </div>
            )}
          </div>
        )}
        {phone && (
          <Paragraph data-test-sell-warehouse-phone className={s.line}>
            <a href={`tel:${phone}`}>{phone}</a>
          </Paragraph>
        )}
        {link && link.text && (
          <div className={s.button}>
            <Button
              prio3
              text={link.text}
              href={link.link}
              onClick={onLinkClick}
              tabIndex={-1}
              data-test-sell-warehouse-cta
            />
          </div>
        )}
      </div>
    </div>
  );
}

WarehouseItem.propTypes = {
  heading: PropTypes.string,
  phone: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.object,
  link: PropTypes.object,
  openHours: PropTypes.object,
  address: PropTypes.object,
  imgSizes: PropTypes.string,
  lazy: PropTypes.bool,
  variant: PropTypes.oneOf(["stacked", "side-by-side"]),
  onLinkClick: PropTypes.func,
  onImageLoad: PropTypes.func,
  loadedImages: PropTypes.object,
};

export default WarehouseItem;
