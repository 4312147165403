const initialState = {
  addressForm: {
    submitButton: {
      label: "",
      link: "",
    },
    values: [],
    structure: [],
  },
  page: {
    back: {},
    headline: "",
    submitButton: {
      label: "",
      link: "",
    },
    submit: {
      label: "",
      link: "",
    },
  },
  submitStarted: false,
};

export default initialState;
