import update from "immutability-helper";
import * as types from "./constants";

const initialState = {};

const webbridge = (state = initialState, action) => {
  switch (action.type) {
    case types.WEB_BRIDGE__UPDATE_BASKET_COUNT:
      return update(state, { basketCount: { $set: action.payload } });
    case types.WEB_BRIDGE__UPDATE_WISHLIST_COUNT:
      return update(state, { wishlistCount: { $set: action.payload } });
    case types.WEB_BRIDGE__UPDATE_INFO:
      return update(state, { info: { $set: action.payload } });
    case types.WEB_BRIDGE__UPDATE_TITLE:
      return update(state, { title: { $set: action.payload } });
    case types.WEB_BRIDGE__UPDATE_SHARING:
      return update(state, { sharing: { $set: action.payload } });
    default:
      return state;
  }
};

export default webbridge;
