import { setPageTitle } from "shared/webbridge/actions";
import { GLOBAL_STATUS_REDIRECT } from "shared/utils/status/constants";
import {
  DEBITOR_OVERVIEW_PAGE__LOAD_PAGE,
  // DEBITOR_OVERVIEW_PAGE__LOAD_REFUND_OVERLAY,
  // DEBITOR_OVERVIEW_PAGE__LOAD_REFUND_OVERLAY_SUCCESS,
  // DEBITOR_OVERVIEW_PAGE__REQUEST_REFUND,
  // DEBITOR_OVERVIEW_PAGE__REQUEST_REFUND_SUCCESS,
} from "../constants";
import backendUrl from "../utils/AseBackendUrls";

export function loadPage(apiClient, query) {
  return apiClient.get(
    backendUrl.DEBITOR_OVERVIEW.path,
    {
      ok: (content) => [
        {
          type: DEBITOR_OVERVIEW_PAGE__LOAD_PAGE,
          payload: content.body,
        },
        setPageTitle(content.body?.page?.headData?.title),
      ],
    },
    {
      httpClientOptions: {
        params: query,
      },
      authRedirectTarget: backendUrl.DEBITOR_OVERVIEW.authRedirectTarget,
      handleUnexpected: () => ({
        type: GLOBAL_STATUS_REDIRECT,
        payload: {
          code: 303,
          location: "/500.html",
        },
      }),
    }
  );
}

// export function requestRefund({ link, values }, apiClient) {
//   return [
//     {
//       type: DEBITOR_OVERVIEW_PAGE__REQUEST_REFUND,
//     },
//     apiClient.post(
//       link,
//       values,
//       {
//         ok: (content) => ({
//           type: DEBITOR_OVERVIEW_PAGE__REQUEST_REFUND_SUCCESS,
//           payload: content.body,
//         }),
//       },
//       {
//         authRedirectTarget: backendUrl.DEBITOR_OVERVIEW.authRedirectTarget,
//       }
//     ),
//   ];
// }

// export function loadRefundOverlay(apiClient) {
//   return [
//     {
//       type: DEBITOR_OVERVIEW_PAGE__LOAD_REFUND_OVERLAY,
//     },
//     apiClient.get(
//       backendUrl.DEBITOR_REFUND_OVERLAY.path,
//       {
//         ok: (content) => ({
//           type: DEBITOR_OVERVIEW_PAGE__LOAD_REFUND_OVERLAY_SUCCESS,
//           payload: content.body,
//         }),
//       },
//       {
//         authRedirectTarget: backendUrl.DEBITOR_OVERVIEW.authRedirectTarget,
//       }
//     ),
//   ];
// }
