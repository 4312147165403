import { connect } from "react-redux";
import { provideHooks } from "redial";
import * as overlayActions from "stelar/components/Overlay/actions";

import {
  toggleCountrySelection,
  closeCountrySelection,
  openOffCanvas,
  closeOffCanvas,
  openLanguageSelectionOffCanvas,
  closeLanguageSelectionOffCanvas,
} from "sell/utils/uiState/actions";
import navigationActions from "sell/utils/uiState/navigation/actions";
import searchActions from "sell/utils/uiState/search/actions";
import { isNavigationLoaded } from "sell/utils/uiState/navigation/check";
import {
  clearCookieBanner,
  cookieBannerConsent,
  cookieBannerReject,
  loadCookieBanner,
  openCookieBanner,
} from "sell/fragments/sell-cookie-banner/actions";
import { isRestictedContent } from "sell/fragments/sell-cookie-banner/helper";
import getCookieBannerActions from "sell/utils/uiState/cookieBanner/actions";
import {
  loadFullNavigation,
  setQueryToStore,
  setParamsToStore,
  loadHeader,
  loadFooter,
  loadBanner,
  loadMiniAccount,
  loadMiniBasketData,
  loadMiniWishlist,
  loadMiniBasketBase,
  retrieveCsrfToken,
} from "./actions";
import NormalLayout from "./NormalLayout";
import { RenderTargets } from "../../utils/appUtils";
import { triggerWebBridgeCalls } from "../../webbridge/actions";
import { isOptinBannerRequired } from "../../utils/trackingHelper";
import { wrapHandler } from "../../utils/ClickEventRegisterer";
import shouldSkipNormalLayout from "./shouldSkipNormalLayout";

const redial = {
  fetch: ({
    dispatch,
    getState,
    params,
    apiClient,
    path,
    query,
    cookies,
    renderTarget,
  }) => {
    dispatch(setQueryToStore(query?.q));
    dispatch(setParamsToStore(params, path));

    let webBridgeCalls = null;
    if (renderTarget === RenderTargets.APP) {
      webBridgeCalls = triggerWebBridgeCalls(apiClient, dispatch, getState());
    }

    let cookieBannerDispatch = null;
    if (isOptinBannerRequired(cookies)) {
      cookieBannerDispatch = dispatch(loadCookieBanner(apiClient));
    }
    if (
      getState().normalLayout &&
      getState().normalLayout.header &&
      getState().normalLayout.footer
    ) {
      return Promise.all([...webBridgeCalls, cookieBannerDispatch]);
    }

    if (shouldSkipNormalLayout(path, query)) {
      if (renderTarget === RenderTargets.APP) {
        return Promise.all([
          ...webBridgeCalls,
          cookieBannerDispatch,
          dispatch(loadFooter(apiClient, params, path)),
        ]);
      }
      return;
    }

    return Promise.all([
      ...webBridgeCalls,
      cookieBannerDispatch,
      dispatch(loadMiniWishlist(apiClient)),
      dispatch(loadMiniAccount(apiClient)),
      dispatch(loadMiniBasketBase(apiClient)),
      dispatch(loadHeader(apiClient, params, path)),
      dispatch(loadFooter(apiClient, params, path)),
    ]);
  },
  defer: ({
    dispatch,
    getState,
    apiClient,
    params,
    query,
    path,
    renderTarget,
  }) => {
    if (shouldSkipNormalLayout(path, query)) {
      return;
    }
    dispatch(loadBanner(apiClient, params, path));
    if (renderTarget === RenderTargets.APP) {
      retrieveCsrfToken(apiClient);
      return null;
    }
    if (!getState().normalLayout.miniBasket.uiState.overlayLoaded) {
      dispatch(loadMiniBasketData(apiClient));
    }
    if (isNavigationLoaded(getState())) {
      return null;
    }
    return dispatch([loadFullNavigation(apiClient)]);
  },
  done: ({ dispatch, cookies }) => {
    dispatch(searchActions().clearSearchString());
    if (isOptinBannerRequired(cookies)) {
      const location = window?.location?.pathname;
      if (isRestictedContent(location)) {
        clearCookieBanner();
        return null;
      }
      return Promise.all([dispatch(openCookieBanner())]);
    }
  },
};

function mapDispatchToProps(dispatch, { apiClient }) {
  const navigation = navigationActions();
  const search = searchActions();
  const cookieBannerActions = getCookieBannerActions();
  return {
    loadBanner: (params, path) => dispatch(loadBanner(apiClient, params, path)),
    onToggleCountrySelection: () => dispatch(toggleCountrySelection()),
    onCloseCountrySelection: () => dispatch(closeCountrySelection()),
    offCanvasActions: {
      onOpenOffCanvas: () => dispatch(openOffCanvas()),
      onCloseOffCanvas: () => dispatch(closeOffCanvas()),
      openLanguageSelectionOffCanvas: () =>
        dispatch(openLanguageSelectionOffCanvas()),
      closeLanguageSelectionOffCanvas: () =>
        dispatch(closeLanguageSelectionOffCanvas()),
    },
    searchActions: {
      onChange: (searchString) =>
        dispatch(search.changeSearchString(searchString)),
      onSuggest: (query) =>
        dispatch(search.loadSearchSuggest(apiClient, query)),
      onProductSuggest: (query) =>
        dispatch(search.loadProductSuggests(apiClient, query)),
      onClearSuggests: () => dispatch(search.clearSearchSuggests()),
      onClearInput: () => dispatch(search.clearSearchString()),
      onArrowNavigation: (key) => dispatch(search.arrowNavigation(key)),
      onHoverSuggestion: (suggestionIndex) =>
        dispatch(search.hoverSuggestion(suggestionIndex)),
      onLeaveSuggestion: () => dispatch(search.leaveSuggestion()),
      onMouseEnterSubmitButton: () => dispatch(search.mouseEnterSubmitButton()),
    },
    navigationActions: {
      onOpen: (id, align) => dispatch(navigation.flyoutOpenDelayed(id, align)),
      onClose: (id) => dispatch(navigation.flyoutClose(id)),
      onCloseDelayed: (id) => dispatch(navigation.flyoutCloseDelayed(id)),
      onToggle: (id, align) => dispatch(navigation.flyoutToggle(id, align)),
      onToggleLevelOffCanvas: (level, id) =>
        dispatch(navigation.compactTogglePathLevel(level, id)),
    },
    overlayActions: {
      addOverlay: (content, y) =>
        dispatch(overlayActions.addOverlay(content, y)),
      removeOverlay: () => dispatch(overlayActions.removeOverlay()),
    },
    miniBasketActions: {
      refresh: () =>
        dispatch([
          loadMiniBasketBase(apiClient),
          loadMiniBasketData(apiClient),
        ]),
    },
    miniWishlistActions: {
      refresh: () => dispatch(loadMiniWishlist(apiClient)),
    },
    miniAccountActions: {
      refresh: () => dispatch(loadMiniAccount(apiClient)),
    },
    cookieBannerActions: {
      onConfirm: () => {
        dispatch(cookieBannerConsent(apiClient));
        dispatch(cookieBannerActions.hideCookieBanner());
      },
      onNeedToShow: () => {
        dispatch(cookieBannerActions.showCookieBanner());
      },
      onOptOut: () => {
        dispatch(cookieBannerReject(apiClient));
        dispatch(cookieBannerActions.hideCookieBanner());
      },
      onOptIn: () => {
        dispatch(cookieBannerConsent(apiClient));
        dispatch(cookieBannerActions.hideCookieBanner());
      },
      onOptInByClick: wrapHandler(() => {
        dispatch(cookieBannerConsent(apiClient));
        dispatch(cookieBannerActions.hideCookieBanner());
      }),
    },
  };
}

function mapStateToProps(state) {
  const { offCanvas, search, navigation, countrySelectionOpen, overlay } =
    state.uiState;
  const { highlightId, currentPath, clickedPath, flyoutAlign, offCanvasPath } =
    navigation;
  const {
    data,
    queries,
    searchString,
    typedSearchString,
    focusedSuggestion,
    focusedProduct,
    productSkeleton,
  } = search;

  const firstQueryWithData =
    focusedSuggestion === null
      ? queries.find((q) => data.wordsData && data.wordsData[q])
      : typedSearchString;

  const words = firstQueryWithData ? data.wordsData[firstQueryWithData] : [];
  const products =
    data.productsData[queries[0]] &&
    data.productsData[queries[0]].productSuggests;
  const productsLink =
    data.productsData[queries[0]] && data.productsData[queries[0]].link;

  return {
    footer: state?.normalLayout?.footer,
    header: state?.normalLayout?.header,
    miniAccount: state?.normalLayout?.miniAccount,
    miniWishlist: state?.normalLayout?.miniWishlist,
    miniBasket: state?.normalLayout?.miniBasket,
    offCanvas,
    countrySelectionOpen,
    searchState: {
      words,
      products,
      productsLink,
      searchString,
      typedSearchString,
      focusedSuggestion,
      focusedProduct,
      productSkeleton,
    },
    navigationState: {
      activeId: highlightId || currentPath[0],
      clickedPath,
      currentPath,
      flyoutAlign,
      offCanvasPath,
    },
    cookieBanner: {
      data: state?.normalLayout?.cookieBanner?.data,
      open: state?.normalLayout?.cookieBanner?.open,
      uiState: state?.uiState?.cookieBannerState,
    },
    error: state?.error,
    eventTracking: state?.normalLayout?.header?.eventTracking,
    overlay,
    storeQuery: state?.normalLayout?.query,
    params: state?.normalLayout?.params,
  };
}

export default provideHooks(redial)(
  connect(mapStateToProps, mapDispatchToProps)(NormalLayout)
);
