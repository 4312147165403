import { combineReducers } from "redux";
import { loadingBarReducer as loadingBar } from "react-redux-loading-bar";

import category from "sell/pages/Category/reducer";
import detail from "sell/pages/Detail/reducer";
import search from "sell/pages/Search/reducer";
import homepage from "sell/pages/Homepage/reducer";
import notFound from "sell/pages/NotFound/reducer";
import uiState from "sell/utils/uiState/reducer";

import aseReducers from "ase/pages/reducers";

import newsletterSubscriptionFragment from "ase/fragments/NewsletterSubscriptionFragment/reducer";
import catalogOrderFragment from "ase/fragments/CatalogOrderFragment/reducer";
import lineItemEditFragment from "ase/fragments/LineItemEditFragment/reducer";
import emailChangeFragment from "ase/fragments/EmailChangeFragment/reducer";
import gutscheinWertabfrageFragment from "ase/fragments/GutscheinWertabfrageFragment/reducer";
import passwordChangeFragment from "ase/fragments/PasswordChangeFragment/reducer";
import orderHistoryDetailsFragment from "ase/fragments/OrderHistoryDetailsFragment/reducer";
import accountDeletionFragment from "ase/fragments/AccountDeletionFragment/reducer";

import recommendationsFragment from "sell/fragments/sell-recommendations/reducer";
import recoTeaserHybridFragment from "sell/fragments/sell-reco-teaser-hybrid/reducer";
import feedbackFragment from "sell/fragments/sell-feedback/reducer";
import availabilityFragment from "sell/fragments/sell-layer-availability/reducer";
import addToCartFragment from "sell/fragments/sell-layer-addtocart/reducer";
import layerContentFragment from "sell/fragments/sell-layer-content/reducer";
import kuratorContentFragment from "sell/fragments/sell-kurator-content/reducer";
import languageSuggestionFragment from "sell/fragments/LanguageSuggestionFragment/reducer";

import normalLayout from "shared/components/NormalLayout/reducer";
import httpContext from "shared/utils/httpContext/reducer";
import ssrPrefetched from "shared/ssrPrefetch/reducer";
import webbridge from "shared/webbridge/reducer";
import testability from "shared/testability/reducer";
import status from "shared/utils/status/reducer";
import { error } from "shared/utils/errorReducer";

export default combineReducers({
  normalLayout,
  category,
  detail,
  search,
  homepage,
  notFound,
  loadingBar,
  httpContext,
  ssrPrefetched,
  webbridge,
  testability,
  uiState,
  status,
  error,
  ...aseReducers,

  newsletterSubscriptionFragment,
  catalogOrderFragment,
  lineItemEditFragment,
  emailChangeFragment,
  gutscheinWertabfrageFragment,
  passwordChangeFragment,
  orderHistoryDetailsFragment,
  accountDeletionFragment,

  recommendationsFragment,
  recoTeaserHybridFragment,
  feedbackFragment,
  availabilityFragment,
  addToCartFragment,
  layerContentFragment,
  kuratorContentFragment,
  languageSuggestionFragment,
});
