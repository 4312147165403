/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "../common";

function IconBubbleCheck(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 36 36" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path d="M30.8,8.2c-0.3,0.4-0.3,0.3-0.6,0.8c-3.2,4.1-6.3,8.2-9.5,12.2c-2.1,2.6-4.1,5.3-6.2,7.9c-0.2,0.3-0.6,0.6-1,0.6 c-0.4,0-0.9-0.4-1.2-0.8c-2.3-2.7-4.6-5.5-7-8.2c-0.4-0.5-0.5-1-0.2-1.5c0.4-0.5,0.9-0.7,1.5-0.5c2.1,0.8,4.2,1.5,6.3,2.3 c0.3,0.1,0.4,0,0.6-0.1c3.5-3.3,7.1-6.5,10.6-9.8c1.6-1.5,3.3-3,4.9-4.6c0.7-0.7,1.5-0.5,1.9-0.2C31.2,6.9,31.3,7.5,30.8,8.2z" />
    </svg>
  );
}

export default IconBubbleCheck;
