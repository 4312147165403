/* eslint-disable react/no-danger, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions  */
import classnames from "classnames/bind";
import FocusTrap from "focus-trap-react";
import React, { useEffect, useRef } from "react";

import { ButtonX } from "../Button";
import ContentHeading from "../ContentHeading";
import IconCheck from "../Icon/IconCheck";
import s from "./ModalContent.module.css";

const cx = classnames.bind(s);

type Props = {
  wrapperRef?: React.RefObject<HTMLDivElement>;
  opened?: boolean;
  title?: string;
  children?: React.ReactNode;
  iconName?: "check";
  hideOverlay?: (...args: unknown[]) => unknown;
  content?: object;
  closeButtonLabel?: string;
  withoutHeader?: boolean;
};

function ModalContent({
  wrapperRef,
  opened,
  title,
  children,
  iconName,
  hideOverlay,
  content,
  closeButtonLabel,
  withoutHeader,
}: Props) {
  const [stickyCloseButton, setStickyCloseButton] = React.useState(false);
  const closeButtonWrapperRef = useRef(null);

  function preventClick(e) {
    e.stopPropagation();
  }

  useEffect(() => {
    const wrapper = wrapperRef?.current || wrapperRef;
    const handleScroll = () => {
      const rect = closeButtonWrapperRef?.current?.getBoundingClientRect();
      const minHeightReached = rect?.y < (withoutHeader ? 15 : 20);
      setStickyCloseButton(minHeightReached);
    };
    (wrapper as any)?.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      (wrapper as any)?.removeEventListener("scroll", handleScroll, {
        passive: true,
      });
    };
  }, []);

  const contentElement = content ? (
    <div
      className={cx("content", { withoutHeader })}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  ) : (
    <div className={cx("content", { withoutHeader })}>{children}</div>
  );

  const closeButton = (
    <div ref={closeButtonWrapperRef} className={s.closeButtonWrapper}>
      <ButtonX
        onClick={hideOverlay}
        className={cx("closeButton", {
          withoutHeader,
          stickyCloseButton,
        })}
        aria-label={closeButtonLabel}
        data-test-stelar-overlay-close-button
      />
    </div>
  );

  const header = (
    <header className={cx("header", { titled: title, untitled: !title })}>
      {title && (
        <ContentHeading
          prio2
          className={cx("headline", { stickyCloseButton })}
          data-test-stelar-overlay-title
        >
          {iconName ? (
            <span className={s.icon}>
              <IconCheck className={s.icon} />
            </span>
          ) : null}
          {title}
        </ContentHeading>
      )}
      {closeButton}
    </header>
  );

  return (
    <FocusTrap
      active={opened}
      focusTrapOptions={{
        initialFocus: false,
        allowOutsideClick: true,
      }}
    >
      <div className={s.root} data-test-stelar-overlay-content>
        <section className={s.overlay} onClick={preventClick}>
          {withoutHeader ? closeButton : header}
          {contentElement}
        </section>
      </div>
    </FocusTrap>
  );
}

export default ModalContent;
