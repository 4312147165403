import React from "react";
import classnames from "classnames/bind";
import s from "./OffCanvasExtensionItem.module.css";

const cx = classnames.bind(s);

type Props = {
  highlighted?: boolean;
  icon?: React.ReactElement;
  link?: string;
  onClick?: (...args: unknown[]) => unknown;
  selected?: boolean;
  text?: string;
} & React.ComponentPropsWithoutRef<"a">;

function OffCanvasExtensionItem({
  highlighted,
  icon,
  link,
  onClick,
  selected,
  text,
  ...attrs
}: Props) {
  return (
    <li className={cx("root", { highlighted, selected })}>
      <a {...attrs} className={s.link} href={link} onClick={onClick}>
        {icon && (
          <span className={s.iconWrap}>
            {React.cloneElement(icon, { small: true })}
          </span>
        )}
        {text}
      </a>
    </li>
  );
}

export default OffCanvasExtensionItem;
