import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import track from "shared/tracking";
import { connect } from "react-redux";
import LazyLoad from "react-lazyload";
import { triggerLocationChange } from "shared/utils/customEvents";
import { getPathFromEvent } from "shared/utils/routing/helper";
import { navigateTo } from "shared/utils/routing";
import prefetch from "shared/ssrPrefetch/prefetch";
import { loadKuratorContent, showSkeleton } from "./actions";
import { getSliderActions } from "../../utils/uiState/sliders/actions";
import ContentKurator, {
  SkeletonContentKurator,
} from "../../components/ContentKurator";
import {
  imageLoaded,
  closeOrOpenContentAccordionItem,
} from "../../utils/uiState/actions";
import { buildCacheKey } from "./helper";

const redial = {
  fetch: ({ dispatch, apiClient, props, cookies, isClient }) => {
    const { tag, skeleton } = props;

    if (skeleton === "true" || !tag) {
      return dispatch(showSkeleton());
    }

    const params = {};

    return dispatch(
      loadKuratorContent(apiClient, tag, params, cookies, isClient)
    );
  },
};

function mapStateToProps({ kuratorContentFragment }, ownProps) {
  const { tag } = ownProps;
  const { kuratorContents, uiState } = kuratorContentFragment;
  const { loading, data } = tag in kuratorContents ? kuratorContents[tag] : {};

  const {
    loadedImages,
    sliders: sliderState,
    openedContentAccordionItems,
  } = uiState;

  return {
    loading,
    loadedImages,
    sliderState,
    openedContentAccordionItems,
    serverSkeleton: true,
    ...data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sliderActions: getSliderActions(dispatch),
    onImageLoad: (src) => dispatch(imageLoaded(src)),
    closeOrOpenContentAccordionItem: (contentAccordionItem) =>
      dispatch(closeOrOpenContentAccordionItem(contentAccordionItem)),
    changeLocation: (path, isOwnContext) => {
      if (!isOwnContext) {
        navigateTo(path, {});
      } else {
        triggerLocationChange(path);
      }
    },
  };
}

function KuratorContentFragment({
  serverSkeleton,
  kuratorContent = [],
  changeLocation,
  sliderState,
  sliderActions,
  openedContentAccordionItems,
  closeOrOpenContentAccordionItem: toggleAccordionItem,
  onImageLoad,
  loadedImages,
}) {
  function handleClickAndTracking(e, trackingData) {
    const path = getPathFromEvent(e);
    if (path) {
      e.preventDefault();
      changeLocation(path, document.getElementById("sell-root"));
    }
    // ToDo: we have to change tracking structure | SELL-4957
    if (Array.isArray(trackingData)) {
      trackingData.forEach((data) => {
        track(data);
      });
    } else {
      track(trackingData);
    }
  }

  const fullContent = (
    <ContentKurator
      content={kuratorContent.elements}
      onLinkClick={handleClickAndTracking}
      sliderState={sliderState}
      sliderActions={sliderActions}
      onImageLoad={onImageLoad}
      loadedImages={loadedImages}
      closeOrOpenContentAccordionItem={toggleAccordionItem}
      openedContentAccordionItems={openedContentAccordionItems}
      fullWidth
    />
  );

  const serverSkeletonContent = <SkeletonContentKurator />;

  const component = (contentKurator) => (
    <div data-test-sell-kurator-content-fragment>{contentKurator}</div>
  );

  return serverSkeleton ? (
    <LazyLoad
      placeholder={component(serverSkeletonContent, true)}
      once
      offset={1000}
    >
      {component(fullContent)}
    </LazyLoad>
  ) : (
    component(fullContent)
  );
}

KuratorContentFragment.propTypes = {
  kuratorContent: PropTypes.object,
  serverSkeleton: PropTypes.bool,
  changeLocation: PropTypes.func,
  sliderState: PropTypes.object,
  sliderActions: PropTypes.object,
  onImageLoad: PropTypes.func,
  openedContentAccordionItems: PropTypes.object,
  closeOrOpenContentAccordionItem: PropTypes.func,
  loadedImages: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(prefetch(KuratorContentFragment, redial, { buildCacheKey }));
