/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconFacebook(props: IconType) {
  return (
    <svg
      className={classNames(props)}
      viewBox="0 0 18 18"
      aria-label="Facebook"
      role="img"
    >
      <g>
        <title>Facebook</title>
        <path
          d="M16.6,0.5H1.4c-0.5,0-0.9,0.4-0.9,0.9v15.1c0,0.5,0.4,0.9,0.9,0.9h8.1v-6.6H7.4V8.4h2.2V6.5
            c0-2.2,1.3-3.4,3.3-3.4c0.9,0,1.7,0.1,2,0.1v2.3l-1.4,0c-1.1,0-1.3,0.5-1.3,1.2v1.6h2.5l-0.3,2.6h-2.2v6.6h4.3
            c0.5,0,0.9-0.4,0.9-0.9V1.4C17.5,0.9,17.1,0.5,16.6,0.5z"
        />
      </g>
    </svg>
  );
}

export default IconFacebook;
