// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import Text from "stelar/components/Text";
// ase
import s from "./BottomLine.module.css";

function BottomLine({ content }) {
  return (
    <address className={s.root} data-test-ase-bottom-line>
      <Text small>{content}</Text>
    </address>
  );
}

BottomLine.propTypes = {
  content: PropTypes.string,
};

export default BottomLine;
