/* eslint-disable react/no-danger */
import PropTypes from "prop-types";
// vendor
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import EnergyEfficiency from "stelar/components/EnergyEfficiency";
import Text from "stelar/components/Text";
// ase
import h from "../../helpers";
import Price from "../../Price";
import s from "./ProductDescription.module.css";

const ProductDescription = ({
  pdsLink,
  articleNumber,
  basePrice,
  deposit,
  dimensions,
  energyEfficiency,
  energyEfficiencyLabel,
  engraving,
  engravingLabel,
  isLoading,
  itemStatus,
  manufacturer,
  name,
  nameAdditional,
  netWeight,
  shippingDate,
  unitPrice,
  deleteItem,
}) => {
  function buildDimension(dimension) {
    return (
      <div key={dimension.label} className={s.dimension}>
        <Text skeleton={isLoading} light className={s.key}>
          {dimension.label}
        </Text>{" "}
        <Text skeleton={isLoading} light className={s.val}>
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: dimension.value }}
          />
        </Text>
      </div>
    );
  }

  const productDimensions =
    dimensions && dimensions.length > 0
      ? dimensions.map((dimension) => buildDimension(dimension))
      : null;

  const productBasePrice = basePrice && (
    <div className={s.todo}>
      <Text skeleton={isLoading} light>
        {basePrice.label} {h.stabilize(basePrice.value)}
      </Text>
    </div>
  );

  const productDeposit = deposit && (
    <div className={s.foobar} data-test-ase-article-price>
      <Text skeleton={isLoading} light>
        {deposit.labelBefore} {h.stabilize(deposit.value)} {deposit.labelAfter}
      </Text>
    </div>
  );

  const productEnergyEfficiency = energyEfficiency ? (
    <Text skeleton={isLoading} light>
      {energyEfficiency.label} {energyEfficiency.value}
    </Text>
  ) : null;

  const productEnergyEfficiencyLabel = energyEfficiencyLabel ? (
    <div className={s.eelContainer}>
      <EnergyEfficiency variant="compact" {...energyEfficiencyLabel} />
    </div>
  ) : null;

  const productEngraving = engraving && (
    <div className={s.productEngraving}>
      <Text skeleton={isLoading} light>
        {engravingLabel} {`„${engraving}“`}
      </Text>
    </div>
  );

  const productManufacturer = manufacturer && (
    <div className={s.manufacturer}>
      <a href={pdsLink}>
        <Text skeleton={isLoading} light>
          {manufacturer}
        </Text>
      </a>
    </div>
  );

  const productNetWeight = netWeight && (
    <div className={s.todo}>
      <Text skeleton={isLoading} light>
        {netWeight.label} {h.stabilize(netWeight.value)}
      </Text>
    </div>
  );

  const productName = name && (
    <Text data-test-ase-article-name em skeleton={isLoading}>
      <span dangerouslySetInnerHTML={{ __html: name }} />
    </Text>
  );

  const productNameAdditional = nameAdditional && (
    <Text em skeleton={isLoading}>
      <span dangerouslySetInnerHTML={{ __html: ` ${nameAdditional}` }} />{" "}
    </Text>
  );

  const productArticleNumber = articleNumber && (
    <Text skeleton={isLoading} light data-test-ase-article-number>
      {`${articleNumber.label} ${articleNumber.value}`}
    </Text>
  );

  const productItemStatus = itemStatus && (
    <div data-test-ase-article-number>
      <Text skeleton={isLoading} light>
        {itemStatus}
      </Text>
    </div>
  );

  const productShippingDate = shippingDate && (
    <div data-test-ase-article-number>
      <Text skeleton={isLoading} light>
        {shippingDate}
      </Text>
    </div>
  );

  const productUnitPrice = unitPrice && (
    <Text skeleton={isLoading} light>
      <Price skeleton={isLoading} unitPrice={unitPrice} />
    </Text>
  );

  return (
    <div className={s.root}>
      <div>
        {deleteItem}
        {productManufacturer}
        <a href={pdsLink}>
          <div className={s.name} data-test-ase-article-name>
            {productName}
            {productNameAdditional}
          </div>
        </a>
      </div>
      {productEnergyEfficiency}
      {productEnergyEfficiencyLabel}
      <a href={pdsLink}>
        {productUnitPrice}
        {productDeposit}
        {productBasePrice}
        {productNetWeight}
        {productArticleNumber}
        {productItemStatus}
        {productShippingDate}
        {productDimensions}
        {productEngraving}
      </a>
    </div>
  );
};

ProductDescription.propTypes = {
  pdsLink: PropTypes.string,
  articleNumber: PropTypes.object,
  basePrice: PropTypes.object,
  deposit: PropTypes.object,
  dimensions: PropTypes.array,
  energyEfficiency: PropTypes.object,
  energyEfficiencyLabel: PropTypes.object,
  engraving: PropTypes.string,
  engravingLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  itemStatus: PropTypes.string,
  manufacturer: PropTypes.string,
  name: PropTypes.string,
  nameAdditional: PropTypes.string,
  netWeight: PropTypes.object,
  shippingDate: PropTypes.string,
  unitPrice: PropTypes.object,
  deleteItem: PropTypes.object,
};

export default ProductDescription;
