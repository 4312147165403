import isEqual from "lodash.isequal";
import update from "immutability-helper";
import * as types from "../../../pages/constants";

const initialState = {
  loadedSliders: {},
  sliderPositions: {},
};

export default function slidersReducer(state = initialState, action) {
  switch (action.type) {
    case types.UI_STATE_SLIDER_SETUP: {
      const { sliderId, slidesPerPage, amountSlides } = action.payload;

      const loadedSlides = {};
      for (let i = 0; i < amountSlides; i += 1) {
        loadedSlides[i] = i < slidesPerPage;
      }
      if (isEqual(loadedSlides, state.loadedSliders[sliderId])) return state;

      return update(state, {
        loadedSliders: {
          [sliderId]: {
            [state.loadedSliders[sliderId] ? "$merge" : "$set"]: loadedSlides,
          },
        },
        sliderPositions: {
          [sliderId]: {
            $set: 0,
          },
        },
      });
    }

    case types.UI_STATE_SLIDER_POSITION_CHANGED: {
      const { sliderId, currentPosition, slidesPerPage } = action.payload;

      const loadedSlides = {};
      for (
        let i = currentPosition;
        i < currentPosition + slidesPerPage;
        i += 1
      ) {
        loadedSlides[i] = true;
      }
      return update(state, {
        loadedSliders: {
          [sliderId]: {
            $merge: loadedSlides,
          },
        },
        sliderPositions: {
          [sliderId]: { $set: currentPosition },
        },
      });
    }

    case types.UI_STATE_SET_SLIDER_POSITION: {
      let data = state.sliderPositions; // eslint-disable-line
      if (state.sliderPositions) {
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const values in data) {
          data[values] = action.payload.position; // eslint-disable-line no-param-reassign
        }
      }
      return update(state, { $set: data });
    }

    default:
      return state;
  }
}
