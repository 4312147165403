import { create } from "zustand";

const useFetchLocalsStore = create(() => ({
  fetchLocals: null,
}));

export const setClientsideFetchLocals = (state) => {
  useFetchLocalsStore.setState(state);
};

export function getClientsideFetchLocals() {
  return useFetchLocalsStore?.getState()?.fetchLocals || {};
}

export default useFetchLocalsStore;
