import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classNames from "classnames/bind";
import Text from "stelar/components/Text";
import s from "./IconElement.module.css";

const cx = classNames.bind(s);
const iconUnit = (icon) => <span className={s.iconContainer}>{icon}</span>;

const IconElement = ({ name, icon }) => (
  <li className={cx("iconElement")}>
    <Text normal className={cx("text")}>
      {name}
    </Text>
    {iconUnit(icon)}
  </li>
);

IconElement.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.node,
};

export default IconElement;
