/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "../common";

function IconCheck(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 36 36" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path d="M30.8 8.2c-.3.4-.3.3-.6.8-3.2 4.1-6.3 8.2-9.5 12.2-2.1 2.6-4.1 5.3-6.2 7.9-.2.3-.6.6-1 .6s-.9-.4-1.2-.8c-2.3-2.7-4.6-5.5-7-8.2-.4-.5-.5-1-.2-1.5.4-.5.9-.7 1.5-.5 2.1.8 4.2 1.5 6.3 2.3.3.1.4 0 .6-.1 3.5-3.3 7.1-6.5 10.6-9.8 1.6-1.5 3.3-3 4.9-4.6.7-.7 1.5-.5 1.9-.2.3.6.4 1.2-.1 1.9z" />
    </svg>
  );
}

export default IconCheck;
