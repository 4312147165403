// vendor
import { connect } from "react-redux";
import { provideHooks } from "redial";
import merge from "lodash.merge";
import track from "shared/tracking";
import loadable from "@loadable/component";
import Loading from "shared/components/Loading";
// ase
import {
  addToBasket,
  loadWishListPage,
  removeFromWishList,
  toggleAgeRelatedAdvice,
} from "./actions";

import frontendUrls from "../utils/AseFrontendUrls";

const redial = {
  fetch: ({ dispatch, apiClient }) => dispatch(loadWishListPage(apiClient)),
  done: ({ getState }) =>
    track(getState()?.wishListPage?.data?.page?.trackingInfo),
};

const mapDispatchToProps = (dispatch, { apiClient }) => ({
  wishList: {
    products: {
      actions: {
        removeFromWishlist: (removeHref) => {
          const newVar = removeFromWishList({ apiClient, removeHref });
          return dispatch(newVar);
        },
        addToBasket: (href, skuCode) => {
          const newVar = addToBasket({ apiClient, href, skuCode });
          return dispatch(newVar);
        },
      },
    },
  },
  toggleAgeRelatedAdvice: (key) => dispatch(toggleAgeRelatedAdvice(key)),
});

const mapStateToProps = (state) => ({
  ...state.wishListPage.data,
  ...state.wishListPage.uiState,
});

const LoadableWishListPage = loadable(
  () =>
    import(/* webpackChunkName: "wishlist-page" */ "./components/WishListPage"),
  {
    fallback: Loading,
  }
);

export default {
  component: provideHooks(redial)(
    connect(mapStateToProps, mapDispatchToProps, merge)(LoadableWishListPage)
  ),
  path: frontendUrls.WISHLIST,
};
