import { connect } from "react-redux";
import { provideHooks } from "redial";
import track from "shared/tracking";
import loadable from "@loadable/component";
import Loading from "shared/components/Loading";
import frontendUrls from "../utils/AseFrontendUrls";
import loadReducedLayout from "../../components/ReducedLayout/actions";
import { loadCreditCardPaymentPage } from "./actions";

const redial = {
  fetch: ({ apiClient, dispatch, getState, params: { paymentId }, query }) =>
    Promise.all([
      dispatch(loadCreditCardPaymentPage(apiClient, paymentId, query)),
      dispatch(loadReducedLayout(apiClient, getState)),
    ]),
  defer: ({ getState }) =>
    track(getState()?.creditCardPaymentPageV3?.page?.trackingInfo),
};

const mapStateToProps = (state) => ({
  computopForm: state.creditCardPaymentPageV3.computopForm,
  description: state.creditCardPaymentPageV3.description,
  heading: state.creditCardPaymentPageV3.heading,
  iframeSrc: state.creditCardPaymentPageV3.iframeSrc,
  iframesNotSupportedText:
    state.creditCardPaymentPageV3.iframesNotSupportedText,
  page: state.creditCardPaymentPageV3.page,
  reducedLayout: state.reducedLayout,
});

const LoadableCreditCardPaymentPage = loadable(
  () =>
    import(
      /* webpackChunkName: "creditcardpayment-page" */ "./components/CreditCardPaymentPageV3"
    ),
  {
    fallback: Loading,
  }
);

export default {
  component: provideHooks(redial)(
    connect(mapStateToProps)(LoadableCreditCardPaymentPage)
  ),
  path: {
    base: frontendUrls.CREDIT_CARD_PAYMENT_V3,
    appendix: "(/:paymentId)",
  },
};
