// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import Text from "stelar/components/Text";
// ase
import s from "./InfoBox.module.css";

const InfoBox = ({ children, heading, isLoading }) => (
  <div className={s.root}>
    {heading && (
      <Text em skeleton={isLoading}>
        {heading}
        <br />
      </Text>
    )}
    {children}
  </div>
);

InfoBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  heading: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default InfoBox;
