import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";

import s from "./MagazinProductCircleLogo.module.css";

const cx = classnames.bind(s);

type Props = {
  subtitle?: string;
  size?: "small" | "medium" | "large";
};

function MagazinProductCircleLogo({ subtitle, size, ...attrs }: Props) {
  return (
    <div className={s.root} {...attrs}>
      <svg
        className={cx("root", { [size]: true })}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 34 34"
        enableBackground="new 0 0 34 34"
        xmlSpace="preserve"
      >
        <rect
          style={{
            fill: "#FFFFFF",
            stroke: "#FFFFFF",
            strokeWidth: "5px",
          }}
          width="34"
          height="34"
        />
        <g>
          <path
            d="M9.1,24.3V9.4c0-0.8,0.7-1.2,1.3-1.2h1.7c1.1,0,1.4,0.9,1.6,1.3l1.6,2.7c0.5,0.9,0.7,0.6,1.2,0L18.5,9
            c0.5-0.7,1.3-0.8,1.6-0.8l4.1,0c0.8,0,0.9,1.1,0.9,2.2v13.6c0,1.7-1.1,1.5-1.7,1.5h-1.6c-0.8,0-1.1-0.6-1.1-1.1V10.5
            c0-1.1-0.4-0.6-1,0l-3.3,5.1c-0.5,0.7-1.4,0.2-1.8-0.4l-2.6-5c-0.2-0.4-1-0.7-0.9,0.4v13.7C11,25.6,9.2,25.9,9.1,24.3"
          />
          <path
            d="M17,3.2C9.4,3.2,3.2,9.4,3.2,17c0,7.6,6.2,13.8,13.8,13.8S30.8,24.6,30.8,17C30.8,9.4,24.6,3.2,17,3.2 M17,32.7
            c-8.7,0-15.7-7-15.7-15.7S8.4,1.3,17,1.3s15.7,7,15.7,15.7C32.7,25.7,25.7,32.7,17,32.7"
          />
        </g>
      </svg>
    </div>
  );
}

export default MagazinProductCircleLogo;
