import classnames from "classnames/bind";
import React, { useRef } from "react";
import IconSearch from "../Icon/IconSearch";
import IconX from "../Icon/IconX";
import s from "./SearchBar.module.css";

const cx = classnames.bind(s);

type Props = {
  placeholder?: string;
  buttonText?: string;
  clearButtonText?: string;
  isCloseButtonActive?: boolean;
  onChange?: (...args: unknown[]) => unknown;
  onClickSubmit?: (...args: unknown[]) => unknown;
  onClickClear?: (...args: unknown[]) => unknown;
  value?: string;
  autoFocus?: boolean;
} & React.ComponentPropsWithoutRef<"input">;

function SearchBar({
  placeholder,
  buttonText,
  clearButtonText,
  onChange = () => {},
  onClickSubmit = () => {},
  onClickClear = () => {},
  autoFocus,
  value,

  // ToDo: SELL-4644 - only for AB Testing
  isCloseButtonActive = false,

  ...attrs
}: Props) {
  const inputRef = useRef(null);

  // useEffect(() => {
  //   if (!inputRef.current) {
  //     return;
  //   }
  //   const event = {
  //     preventDefault() {
  //       return null;
  //     },

  //     target: {
  //       value: inputRef.current.value,
  //     },
  //   };

  //   onChange(event);
  // }, []);

  const inputClassNames = cx(s.input, attrs.className);

  const closeButton = isCloseButtonActive && value && (
    <button className={s.clearButton} onClick={onClickClear} type="button">
      <span className={s.icon}>
        <IconX small alt={clearButtonText} />
      </span>
    </button>
  );

  return (
    <div className={s.root}>
      <label className={s.label} htmlFor="q">
        {placeholder}
      </label>
      <input
        {...attrs}
        ref={inputRef}
        id="q"
        autoComplete="off"
        className={inputClassNames}
        name="q"
        placeholder={placeholder}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={
          // keeping focus when focused even after changing underlying component
          (typeof window !== "undefined" &&
            document.activeElement &&
            document.activeElement.id === "q") ||
          autoFocus
        }
        type="text"
        onChange={onChange}
        value={value}
      />
      <button
        className={cx("submitButton", {
          calltoaction: value && value.length > 1,
        })}
        onClick={onClickSubmit}
        type="submit"
      >
        <span className={s.icon}>
          <IconSearch small alt={buttonText} />
        </span>
      </button>
      {closeButton}
    </div>
  );
}

export default SearchBar;
