/* eslint-disable camelcase */
import track from "shared/tracking";
import { provideHooks } from "redial";
import { connect } from "react-redux";
import loadable from "@loadable/component";
import { categoryIdsInArray } from "shared/utils/helper";
import Loading from "shared/components/Loading";
import {
  loadDetail,
  resetRecoDetailpageState,
  loadOutfitInfo,
  loadRecommendations,
  isClientSideLoaded,
} from "./actions";

import mapStateToProps from "./mapStateToProps";
import mapDispatchToProps from "./mapDispatchToProps";
import navigationActions from "../../utils/uiState/navigation/actions";
import { resetTrackingListType } from "../../utils/uiState/actions";
import { EPOQ_SESSION_ID_COOKIE_NAME } from "../constants";
import searchActions from "../../utils/uiState/search/actions";
import { setCategoryContext, trackMicroConversion } from "./helper";
import { openCookieBanner } from "../../fragments/sell-cookie-banner/actions";

const { updateCurrent, compactTogglePath } = navigationActions();
const { clearSearchString } = searchActions();

function getCookie(name) {
  /* eslint-disable-next-line */
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : "";
}

const redial = {
  fetch: ({
    dispatch,
    params: { productId, articleId, urlName },
    query: {
      a,
      c,
      h,
      s,
      v,
      voucher_amount,
      status,
      sizeId,
      adword,
      gclid,
      newsletter,
      utm_source,
      utm_medium,
      utm_campaign,
      ecmUid,
      bmrecipientid,
      globalError,
    },
    locationState,
    apiClient,
    getState,
  }) =>
    dispatch(
      loadDetail(
        apiClient,
        productId,
        urlName,
        articleId || a,
        c || locationState?.categoryId,
        h,
        s,
        v,
        voucher_amount,
        status,
        sizeId,
        adword,
        gclid,
        newsletter,
        utm_source,
        utm_medium,
        utm_campaign,
        ecmUid,
        bmrecipientid,
        getState().uiState.trackingListType.listType,
        globalError
      )
    ),
  done: ({ apiClient, dispatch, getState }) => {
    const { keys, data } = getState().detail;
    const detail = data[keys[0]];

    if (!detail) {
      return console.error("no detail information available"); // eslint-disable-line no-console
    }
    dispatch(isClientSideLoaded()); // for client side loading components
    dispatch(loadOutfitInfo(apiClient, detail.articleNumber));
    dispatch(clearSearchString());

    const context = setCategoryContext(detail?.stripes);
    const categoryIds = categoryIdsInArray(detail?.breadcrumb?.crumbs);
    const recoParams = {
      articleNumber: detail.articleNumber,
      sessionId: getCookie(EPOQ_SESSION_ID_COOKIE_NAME),
      categoryContext: context,
    };

    dispatch(resetRecoDetailpageState());
    dispatch(loadRecommendations(apiClient, recoParams));
    dispatch(updateCurrent(detail.navigationPath));
    dispatch(resetTrackingListType());

    track(detail.tracking);
    trackMicroConversion();

    if (detail.stripes) {
      detail.stripes.forEach((stripe) => track(stripe.tracking));
    }

    openCookieBanner();
    dispatch(compactTogglePath(categoryIds));
    return null;
  },
};

const LoadableDetail = loadable(
  () => import(/* webpackChunkName: "detail-page" */ "./components/Detail"),
  {
    fallback: Loading,
  }
);

export default provideHooks(redial)(
  connect(mapStateToProps, mapDispatchToProps)(LoadableDetail)
);
