import remoteLogger from "./remoteLogger";

// see: import media from "stelar/components/media";
const breakpoints = {
  S: 300,
  M: 500,
  L: 750,
  XL: 1000,
  XXL: 1250,
  XXXL: 1500,
};

const result = {};
result.breakpoint = {};

let lastName = null;
Object.keys(breakpoints).forEach((name) => {
  const point = breakpoints[name];

  result.breakpoint[name.toLowerCase()] = { min: point };
  if (lastName) {
    result.breakpoint[lastName.toLowerCase()].max = point - 1;
  }
  lastName = name;
});

function getBreakpoint(width) {
  return Object.keys(result.breakpoint).find((x) => {
    const { min, max = Infinity } = result.breakpoint[x];
    return min <= width && width <= max;
  });
}

export default () => {
  remoteLogger({
    browserWidth: window.innerWidth,
    browserHeight: window.innerHeight,
    host: window.location.host,
    url: window.location.href,
    breakpoint: getBreakpoint(window.innerWidth),
  });
};
