/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import UpperBenefits from "../UpperBenefits";
import CompanyLinks from "./CompanyLinks";
import BottomLine from "./BottomLine";
import CountrySelection from "./CountrySelection";
import ServiceContact from "./ServiceContact";
import ServiceIconLinks from "./ServiceIconLinks";
import ServiceLinks from "./ServiceLinks";
import LegalLinks from "./LegalLinks";
import SocialLinks from "./SocialLinks";
import BenefitCommunication from "./BenefitCommunication";
import Footnotes from "./Footnotes";

import s from "./Footer.module.css";
import AppPromotion from "./AppPromotion/AppPromotion";
import DhlGoGreen from "./DhlGoGreen/DhlGoGreen";

function Footer({
  upperBenefits,
  country,
  countrySelectionOpen,
  onToggleCountrySelection,
  onCloseCountrySelection,
  contact,
  service,
  payment,
  company,
  legal,
  communicationBanner,
  social,
  onLinkClick,
  footnotes,
  bannerFootnotes,
  appPromotion,
  dhlGoGreen,
  newsletter,
}) {
  return (
    <div data-test-sell-footer className="footer">
      {upperBenefits ? <UpperBenefits {...upperBenefits} /> : null}
      <div className={s.background} onClick={onLinkClick}>
        <div className={s.sections} data-test-sell-footer-sections>
          {country ? (
            <div className={s.country} data-test-sell-footer-country>
              <CountrySelection
                data-test-sell-footer-country-selection
                country={country}
                countrySelectionOpen={countrySelectionOpen}
                onToggleCountrySelection={onToggleCountrySelection}
                onCloseCountrySelection={onCloseCountrySelection}
              />
            </div>
          ) : (
            <div className={s.country} />
          )}
          <div className={s.service} data-test-sell-footer-service>
            <ServiceIconLinks {...service} />
          </div>
          <div className={s.contact}>
            <ServiceContact contact={contact} />
          </div>
          <div className={s.newsletter} data-test-sell-footer-newsletter>
            {newsletter}
          </div>
          <div className={s.payment} data-test-sell-footer-payment>
            <ServiceLinks payment={payment} />
          </div>
          <div className={s.company} data-test-sell-footer-company>
            <div className={s.companyLinks}>
              <CompanyLinks {...company} data-test-sell-footer-company-links />
            </div>
            <div className={s.legalLinks} data-test-sell-footer-legal-links>
              <LegalLinks country={country} {...legal} />
            </div>
          </div>
        </div>
        {communicationBanner && (
          <BenefitCommunication
            link={communicationBanner.link}
            benefits={[
              communicationBanner.specialOrder,
              communicationBanner.sparePartsService,
              communicationBanner.personalProductGuide,
            ]}
          />
        )}
        {appPromotion && <AppPromotion {...appPromotion} />}
        {dhlGoGreen && <DhlGoGreen {...dhlGoGreen} />}
        {social && <SocialLinks {...social} />}
        <div>
          <BottomLine line={company.bottomLine} />
        </div>
      </div>
      {footnotes && (
        <Footnotes
          data-test-sell-footer-footnotes
          styleElement="element"
          {...footnotes}
        />
      )}
      {bannerFootnotes && (
        <Footnotes
          data-test-sell-footer-footnotes-banner
          styleElement="bannerElement"
          {...bannerFootnotes}
        />
      )}
    </div>
  );
}

Footer.propTypes = {
  upperBenefits: PropTypes.object,
  country: PropTypes.object,
  countrySelectionOpen: PropTypes.bool,
  onToggleCountrySelection: PropTypes.func,
  onCloseCountrySelection: PropTypes.func,
  contact: PropTypes.object,
  service: PropTypes.object,
  payment: PropTypes.object,
  company: PropTypes.object,
  legal: PropTypes.object,
  communicationBanner: PropTypes.object,
  social: PropTypes.object,
  onLinkClick: PropTypes.func,
  footnotes: PropTypes.object,
  bannerFootnotes: PropTypes.object,
  appPromotion: PropTypes.object,
};

export default Footer;
