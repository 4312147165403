import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Text from "stelar/components/Text";
import Heading from "stelar/components/Heading";
import s from "./LargePhoneNumber.module.css";

function LargePhoneNumber({ number, label, note }) {
  const numberWithoutWhiteSpace = number
    ? number
        .replace(/ /g, "")
        .replace("-", "")
        .replace(".", "")
        .replace("+", "00")
        .replace("(0)", "")
    : "";
  return (
    <div className={s.root}>
      {label ? (
        <Text normal className={s.label}>
          {label}{" "}
        </Text>
      ) : null}
      {number ? (
        <a href={`tel:${numberWithoutWhiteSpace}`} className={s.number}>
          <Heading inline prio2 tag="span" className={s.numberText}>
            {number}
          </Heading>
        </a>
      ) : null}
      {note ? (
        <Text small className={s.text}>
          {note}
        </Text>
      ) : null}
    </div>
  );
}

LargePhoneNumber.propTypes = {
  number: PropTypes.string,
  label: PropTypes.node,
  note: PropTypes.string,
};

LargePhoneNumber.defaultProps = {};

export default LargePhoneNumber;
