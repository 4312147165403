import withInputs from "shared/utils/actionReducer/changeInput/ChangeInputReducer";
import ads from "./Ads/reducer";
import basketPage from "./BasketPage/reducer";
import changeBillingAddressPage from "./ChangeBillingAddressPage/reducer";
import creditCardPaymentPageV3 from "./CreditCardPaymentPageV3/reducer";
import customerAccountPage from "./CustomerAccountPage/reducer";
import accountDeletionPage from "./AccountDeletionPage/reducer";
import debitorOverviewPage from "./DebitorOverviewPage/reducer";
import deliveryAddressPage from "./DeliveryAddressPage/reducer";
import gutscheinForm from "../components/GutscheinForm/reducer";
import kuzCreditCardPaymentPageV2 from "./KuzCreditCardPaymentPageV2/reducer";
import lastCheckPage from "./LastCheckPage/reducer";
import loginPage from "./LoginPage/reducer";
import newsletterPage from "./NewsletterPage/reducer";
import newsletterSubscription from "../components/NewsletterSubscription/reducer";
import newsletterUnsubscribePage from "./NewsletterUnsubscribePage/reducer";
import uwg7MailUnsubscribePage from "./Uwg7MailUnsubscribePage/reducer";
import noScriptPage from "./NoScriptPage/reducer";
import orderConfirmationPage from "./OrderConfirmationPage/reducer";
import orderHistoryPage from "./OrderHistoryPage/reducer";
import orderSheetPage from "./OrderSheetPage/reducer";
import passwordPage from "./PasswordPage/reducer";
import paymentPage from "./PaymentPage/reducer";
import reducedLayout from "../components/ReducedLayout/reducer";
import registerPage from "./RegisterPage/reducer";
import wishListPage from "./WishListPage/reducer";
import {
  ADS_PAGE__PAGE_NAME,
  BASKET_PAGE__PAGE_NAME,
  LAST_CHECK_PAGE__PAGE_NAME,
  LOGIN_PAGE__PAGE_NAME,
  NEWSLETTER_SUBSCRIPTION_COMPONENT__COMPONENT_NAME,
  NEWSLETTER_UNSUBSCRIBE_PAGE__PAGE_NAME,
  ORDER_CONFIRMATION_PAGE__PAGE_NAME,
  ORDER_SHEET_PAGE__PAGE_NAME,
  PASSWORD_PAGE__PAGE_NAME,
  PAYMENT_PAGE__PAGE_NAME,
} from "./constants";

const AseReducers = {
  ads: withInputs(ads, ADS_PAGE__PAGE_NAME),
  basketPage: withInputs(basketPage, BASKET_PAGE__PAGE_NAME),
  changeBillingAddressPage,
  creditCardPaymentPageV3,
  customerAccountPage,
  accountDeletionPage,
  debitorOverviewPage,
  deliveryAddressPage,
  gutscheinForm,
  kuzCreditCardPaymentPageV2,
  lastCheckPage: withInputs(lastCheckPage, LAST_CHECK_PAGE__PAGE_NAME),
  loginPage: withInputs(loginPage, LOGIN_PAGE__PAGE_NAME),
  newsletterSubscription: withInputs(
    newsletterSubscription,
    NEWSLETTER_SUBSCRIPTION_COMPONENT__COMPONENT_NAME
  ),
  newsletterPage,
  newsletterUnsubscribePage: withInputs(
    newsletterUnsubscribePage,
    NEWSLETTER_UNSUBSCRIBE_PAGE__PAGE_NAME
  ),
  uwg7MailUnsubscribePage,
  noScriptPage,
  orderConfirmationPage: withInputs(
    orderConfirmationPage,
    ORDER_CONFIRMATION_PAGE__PAGE_NAME
  ),
  orderHistoryPage,
  orderSheetPage: withInputs(orderSheetPage, ORDER_SHEET_PAGE__PAGE_NAME),
  passwordPage: withInputs(passwordPage, PASSWORD_PAGE__PAGE_NAME),
  paymentPage: withInputs(paymentPage, PAYMENT_PAGE__PAGE_NAME),
  reducedLayout,
  registerPage,
  wishListPage,
};

export default AseReducers;
