import React from "react";
import Input from "./Input";

type Props = {
  autoComplete?: string;
  error?: boolean | string;
} & React.ComponentPropsWithoutRef<typeof Input>;

/**
 * InputDateYear
 */
function InputDateYear({
  autoComplete = "bday-year",
  error = "",
  ...attrs
}: Props) {
  return (
    <Input
      autoComplete={autoComplete}
      className="dateYear"
      error={error}
      maxLength={4}
      size={4}
      type="tel"
      {...attrs}
    />
  );
}

export default InputDateYear;
