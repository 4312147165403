import imgSizes from "shared/utils/imageSizes";

export const SIZE_HALF = "half";
export const SIZE_FOUR = "four";
export const SIZE_OVERLAY = "overlay";

export const IMAGE_SIZES = imgSizes({
  xxxl: "240px",
  xxl: "16.6vw",
  xl: "20vw",
  l: "25vw",
  m: "33vw",
  default: "50vw",
});
