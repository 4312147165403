export default {
  // HOME: "/", is owned by SELL
  BASKET: {
    DE: "/warenkorb",
    EN: "/basket",
    NL: "/winkelmand",
    FR: "/panier",
    DK: "/indkobskurv ",
  },
  CATALOG_ORDER: { DE: "/katalogbestellung" },
  CHANGE_BILLING_ADDRESS: {
    DE: "/rechnungsadresse",
    EN: "/billingaddress",
    NL: "/factuuradres",
    FR: "/adressedefacturation",
    DK: "/faktureringsadresse",
  },
  CREDIT_CARD_PAYMENT_V3: {
    DE: "/kreditkartenzahlung",
    EN: "/creditcardpayment",
    NL: "/kredietkaartbetaling",
    FR: "/cartedecredit",
    DK: "/kreditkortbetaling",
  },
  CUSTOMER_ACCOUNT: {
    DE: "/kundenkonto",
    EN: "/customeraccount",
    NL: "/klantaccount",
    FR: "/compteclient",
    DK: "/kundekonto",
  },
  ACCOUNT_DELETION: {
    DE: "/kontoloeschung",
    EN: "/accountdeletion",
    NL: "/accountverwijdering",
    FR: "/suppressiondecompte",
    DK: "/sletningafkonto",
  },
  DEBITOR_OVERVIEW: {
    DE: "/kontouebersicht",
    EN: "/debitoroverview",
    NL: "/overzichtdebiteuren",
    FR: "/relevedecompte",
    DK: "/kontooversigt",
  },
  DELIVERY_ADDRESS: {
    DE: "/lieferadresse",
    EN: "/deliveryaddress",
    NL: "/afleveradres",
    FR: "/adressedelivraison",
    DK: "/leveringsadresse",
  },
  KUZ_CREDIT_CARD_PAYMENT_V2: {
    DE: "/zahlungsservice/v2",
    EN: "/paymentservice/v2",
    NL: "/betalingservice/v2",
    FR: "/servicedepaiement/v2",
    DK: "/betalingsservice/v2",
  },
  LAST_CHECK: {
    DE: "/bestelluebersicht",
    EN: "/basketsummary",
    NL: "/besteloverzicht",
    FR: "/recapitulatif",
    DK: "/bestillingsoversigt",
  },
  LOGIN: {
    DE: "/login",
    // EN: "/login",
    // NL: "/login",
    // FR: "/login",
    // DK: "/login",
  },
  NEWSLETTER_SUBSCRIPTION: {
    DE: "/ase/newsletterbestellung",
    EN: "/ase/newsletterunsubscribe",
    NL: "/ase/nieuwsbriefabonneren",

    DE_NEW: "/newsletter/bestellung",
    EN_NEW: "/newsletter/subscribe",
    NL_NEW: "/nieuwsbrief/abonneren",
    FR: "/newsletter/inscription",
    DK: "/nyhedsbrev/tilmeld",
  },
  NEWSLETTER_CANCEL_SUBSCRIPTION: {
    DE: "/newsletter/abbestellen",
    EN: "/newsletter/unsubscribe",
    NL: "/nieuwsbrief/opzeggen",
    FR: "/newsletter/desabonner",
    DK: "/nyhedsbrev/afmelding",
  },
  UWG7_MAIL_CANCEL_SUBSCRIPTION: {
    DE: "/produktempfehlungen/abbestellen",
    EN: "/recommendation/unsubscribe",
    NL: "/aanbevelingen/opzeggen",
    FR: "/recommandations/desabonner",
    DK: "/anbefalinger/afmelding",
  },
  NO_SCRIPT: { DE: "/noscript", EN: "/noscript", NL: "/noscript" },
  ORDER_CONFIRMATION: {
    DE: "/bestellbestaetigung",
    EN: "/orderconfirmation",
    NL: "/orderbevestiging",
    FR: "/confirmationdecommande",
    DK: "/ordrebekraftelse",
  },
  ORDER_HISTORY: {
    DE: "/bestellhistorie",
    EN: "/orderhistory",
    NL: "/bestelgeschiedenis",
    FR: "/historiquedescommandes",
    DK: "/ordrehistorik",
  },
  ORDER_SHEET: {
    DE: "/bestellschein",
    EN: "/orderform",
    NL: "/bestelbon",
    FR: "/formulairedecommande",
    DK: "/bestillingsformular",
  },
  PASSWORD: {
    DE: "/passwort/vergessen",
    EN: "/password/forgotten",
    NL: "/wachtwoord/vergeten",
    FR: "/motdepasse/oublie",
    DK: "/adgangskodeg/glemt",
  },
  PAYMENT: {
    DE: "/zahlung",
    EN: "/pay",
    NL: "/betaling",
    FR: "/paiement",
    DK: "/betaling",
  },
  REGISTER: {
    DE: "/register",
    EN: "/register",
    NL: "/registreren",
    FR: "/s-enregistrer",
    DK: "/registrering",
  },
  WISHLIST: {
    DE: "/merkliste",
    EN: "/wishlist",
    NL: "/verlanglijst",
    FR: "/aide-memoire",
    DK: "/huskeliste ",
  },
  ERROR_TEST: { DE: "/error-test", EN: "/error-test", NL: "/error-test" },
};
