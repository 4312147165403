export const NEWSLETTER_SKELETON = {
  loading: false,
  kuratorContentsTop: [{ id: "...", elements: [{}] }],
};

export const EXAMPLE_DATA = {
  id: "2509_9",
  type: "newsletterSignOnPage",
  headline: "Newsletter abonnieren",
  image: {
    imagePreview:
      "https://assets.manufactum.de/c/manufactum/newsletter/Newsletter-incentivierung-10-Euro-v2.jpg?profile=newsletter_32",
    imageUrl:
      "https://assets.manufactum.de/c/manufactum/newsletter/Newsletter-incentivierung-10-Euro-v2.jpg?profile=newsletter_750",
    imageSrcSet:
      "https://assets.manufactum.de/c/manufactum/newsletter/Newsletter-incentivierung-10-Euro-v2.jpg?profile=newsletter_750 1x,  https://assets.manufactum.de/c/manufactum/newsletter/Newsletter-incentivierung-10-Euro-v2.jpg?profile=newsletter_1250 2x",
    altText: "Newsletter abonnieren",
    highResImageUrl:
      "https://assets.manufactum.de/c/manufactum/newsletter/Newsletter-incentivierung-10-Euro-v2.jpg?profile=newsletter_1250",
  },
  advantages: {
    headline: "... und einen Gutschein über 10 € sichern*",
    items: [
      "Neuheiten im Sortiment - bereits vor Erscheinen der Kataloge",
      "Sonderangebote",
      "Veranstaltungen und Aktionen in den Warenhäusern",
      "Hilfreiche Ratgeberthemen",
    ],
  },
};
