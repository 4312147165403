import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import s from "./WarehouseItems.module.css";
import WarehouseItem from "../WarehouseItem";

const cx = classnames.bind(s);

const WarehouseItems = ({
  items,
  type,
  fullWidth,
  imgSizes,
  onLinkClick,
  onImageLoad,
  loadedImages = {},
  lazy,
}) => (
  <div
    className={cx("root", {
      fullWidth,
      indented: !fullWidth,
      [`teasers-${type}`]: true,
    })}
    data-test-sell-element-warehouses
  >
    {items &&
      items.map((warehouse) =>
        warehouse.empty ? (
          <div className={s.teaser} key="" />
        ) : (
          <div className={s.teaser} key={warehouse.id}>
            <WarehouseItem
              variant={type === "one" ? "side-by-side" : "stacked"}
              {...warehouse}
              imgSizes={imgSizes}
              onLinkClick={onLinkClick}
              onImageLoad={onImageLoad}
              loadedImages={loadedImages}
              lazy={lazy}
            />
          </div>
        )
      )}
  </div>
);

WarehouseItems.propTypes = {
  items: PropTypes.array,
  type: PropTypes.oneOf(["one", "two", "three"]),
  fullWidth: PropTypes.bool,
  lazy: PropTypes.bool,
  imgSizes: PropTypes.string,
  onLinkClick: PropTypes.func,
  onImageLoad: PropTypes.func,
  loadedImages: PropTypes.object,
};

export default WarehouseItems;
