import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Text from "stelar/components/Text";
import s from "./AppPromotion.module.css";

function AppPromotion({ label, ios, android }) {
  return (
    <div className={s.root}>
      {label && (
        <Text normal className={s.label}>
          {label}
        </Text>
      )}
      {(ios || android) && (
        <div className={s.appStoreImages}>
          {ios && (
            <a href={ios.link}>
              <img src={ios.image?.src} alt={ios.image?.alt} height="44px" />
            </a>
          )}
          {android && (
            <a href={android.link}>
              <img
                src={android.image?.src}
                alt={android.image?.alt}
                height="44px"
              />
            </a>
          )}
        </div>
      )}
    </div>
  );
}

AppPromotion.propTypes = {
  label: PropTypes.string,
  ios: PropTypes.object,
  android: PropTypes.object,
};

export default AppPromotion;
