import classnames from "classnames/bind";
import IconX from "stelar/components/Icon/IconX";
import React, { useEffect } from "react";
import { useCookies } from "shared/utils/httpContext/CookieProvider";
import { useTrackWhenViewed } from "shared/utils/useTrackWhenViewed";
import {
  setTopBannerVisible,
  topBannerVisible,
} from "../../utils/bannerHelper";
import TopBannerElement from "./components/TopBannerElement";
import { removeFootnotes } from "./helper";
import type { TopBannerProps } from "./types";

import styles from "./TopBanner.module.css";

const cx = classnames.bind(styles);

function TopBanner(props: TopBannerProps) {
  const { elementType, handleClickAndTracking, tracking, label } = props;

  const { serverSideCookies } = useCookies();
  const cookies =
    typeof document !== "undefined" ? document.cookie : serverSideCookies;
  const [showBanner, setShowBanner] = React.useState(
    topBannerVisible(cookies, label)
  );

  const { ref } = useTrackWhenViewed(tracking);

  useEffect(() => {
    const isBannerVisible = topBannerVisible(cookies, label);
    setTopBannerVisible(isBannerVisible);
    setShowBanner(isBannerVisible);
    if (!isBannerVisible) {
      removeFootnotes();
    }
  }, []);

  function onClickClose(e: any) {
    const data = {
      googleTagManager: {
        containerId: window.gtmId,
        payload: { event: "topbanner-close-click" },
      },
    };
    setTopBannerVisible(false);
    setShowBanner(false);
    removeFootnotes();
    handleClickAndTracking(e, data);
  }

  return showBanner ? (
    <div
      id="topBannerRoot"
      className={cx(
        "topbannerRoot",
        showBanner ? "open" : "close",
        elementType
      )}
      data-test-sell-topbanner-container
    >
      <div ref={ref} className={cx("topbannerContainer")}>
        {/* TODO a11y */}
        {/* eslint-disable jsx-a11y/control-has-associated-label */}
        <button
          className={cx("tbButton")}
          data-test-sell-topbanner-button
          type="button"
          onClick={onClickClose}
        >
          <IconX small />
        </button>
        {/* eslint-enable jsx-a11y/control-has-associated-label */}
        <div className={cx("tbListContainer")}>
          <TopBannerElement {...props} />
        </div>
      </div>
    </div>
  ) : null;
}

export default TopBanner;
