import StelarProductImage from "stelar/components/ProductImage";
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { buildImageSrcDefinitions } from "shared/utils/imageUrlBuilder";
import { oneline } from "shared/utils/strings";

function ProductImage({ image, preview, sizes, imageProfileDefault, ...args }) {
  const { srcSet, previewSrc, fallBackSrc } = buildImageSrcDefinitions(
    image,
    imageProfileDefault
  );

  return preview ? (
    <StelarProductImage
      previewSrc={previewSrc}
      alt={image?.altText}
      {...args}
    />
  ) : (
    <StelarProductImage
      previewSrc={previewSrc}
      src={fallBackSrc}
      srcSet={srcSet}
      alt={image?.altText}
      sizes={oneline(sizes)}
      {...args}
    />
  );
}

ProductImage.propTypes = {
  image: PropTypes.object,
  altText: PropTypes.string,
  pattern: PropTypes.string,
  presets: PropTypes.array,
  previewPreset: PropTypes.string,
  fallbackPreset: PropTypes.string,
  sizes: PropTypes.string,
  preview: PropTypes.bool,
  imageProfileDefault: PropTypes.object,
};

export default ProductImage;
