// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import OffCanvasExtension from "stelar/components/OffCanvasExtension";
import OffCanvasExtensionItem from "stelar/components/OffCanvasExtension/OffCanvasExtensionItem";
import IconAccount from "stelar/components/Icon/IconAccount";
import IconWishList from "stelar/components/Icon/IconWishList";
import IconBasket from "stelar/components/Icon/IconBasket";

const OffCanvasItemsFragment = ({ miniAccount, miniWishlist, miniBasket }) => {
  function isActive(object) {
    return !!object;
  }

  const miniAccountNavigationItem = miniAccount && (
    <OffCanvasExtensionItem
      highlighted={isActive(miniAccount.user)}
      icon={<IconAccount alt={miniAccount.label} />}
      link={miniAccount.target}
      text={miniAccount.label}
    />
  );

  const miniWishlistNavigationItem = miniWishlist && (
    <OffCanvasExtensionItem
      highlighted={miniWishlist.active}
      icon={<IconWishList alt={miniWishlist.label} />}
      link={miniWishlist.link}
      text={miniWishlist.label}
    />
  );

  const miniBasketNavigationItem = miniBasket?.baseData?.label && (
    <OffCanvasExtensionItem
      highlighted={miniBasket.baseData.active}
      icon={<IconBasket alt={miniBasket.baseData.label} />}
      link={miniBasket.baseData.link}
      text={miniBasket.baseData.label}
    />
  );

  return (
    <OffCanvasExtension>
      {miniAccountNavigationItem}
      {miniWishlistNavigationItem}
      {miniBasketNavigationItem}
    </OffCanvasExtension>
  );
};

OffCanvasItemsFragment.propTypes = {
  miniAccount: PropTypes.object,
  miniWishlist: PropTypes.object,
  miniBasket: PropTypes.object,
};

export default OffCanvasItemsFragment;
