const initialState = {
  data: {
    navigation: {
      heading: "………… ……………",
      settings: {
        label: "…………………………………………",
        link: "/kundenkonto",
      },
    },
    pageTitle: "",
    info: "Hier können Sie Ihre Bestellungen der letzten 12 Monate einsehen.",
    orderHistory: {
      detailsLabel: "Bestelldetails",
      orderCreationDateLabel: "Bestelldatum",
      shippingAddressLabel: "Lieferadresse",
      showArticlesLabel: "Artikel anzeigen",
      totalPriceLabel: "Gesamtsumme",
      orders: [
        {
          auftragsnummer: "…………………………",
          inProgressLabel: "…… ………………………",
          invoicesLabel: "…………………………",
          orderCreationDate: "…….…….…………",
          shippingAddressShortName: "……………………………",
          summary: {},
          totalPrice: "……,…… €",
          shippingAddress: [
            "…………",
            "…………… ………………",
            "………………. ……",
            "…………… …………………",
          ],
          detail: {
            products: [
              {
                name: "……………………………… ……………………………………",
                imgSrc:
                  "https://assets.manufactum.de/p/000/000000/000_.jpg?profile=basket",
                manufacturer: null,
                unitPrice: {
                  label: "Einzelpreis",
                  value: "……,…… €",
                },
                variant: null,
                size: null,
                articleNumber: {
                  label: "Art.Nr.",
                  value: "……………",
                },
                pdsLink: "/sku-p1/?a=……………",
                netWeight: null,
                engraving: null,
                ageRelatedDetails: null,
                basePrice: null,
                deposit: null,
                quantity: {
                  label: "Menge",
                  value: "1",
                },
                totalPrice: "……,…… €",
                energyEfficiency: null,
                itemStatus: "",
                shippingDate: null,
                parcelTracking: null,
                parcelTrackingNumber: null,
              },
            ],
          },
          invoices: [],
          returnsVoucher: [],
          carrierInfo: "warenhaus",
        },
      ],
    },
  },
  virtual: {
    orderHistory: {
      openedOrder: "",
    },
    isShipmentTrackingLoading: true,
  },
};

export default initialState;
