import { LOAD_NOT_FOUND_STARTED, LOAD_NOT_FOUND_SUCCESS } from "./constants";

export function loadNotFound(apiClient) {
  return [
    { type: LOAD_NOT_FOUND_STARTED },
    apiClient.get(
      "/not-found",
      {
        ok: (response) => ({
          type: LOAD_NOT_FOUND_SUCCESS,
          payload: { ...response.data },
        }),
      },
      { apiBase: "/api/sell" }
    ),
  ];
}
