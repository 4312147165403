import { setPageTitle } from "shared/webbridge/actions";
import { UWG7_MAIL_UNSUBSCRIBE_PAGE__LOAD_PAGE } from "../constants";
import backendUrl from "../utils/AseBackendUrls";

export default (apiClient) =>
  apiClient.get(backendUrl.UWG7_UNSUBSCRIBE, {
    ok: (content) => [
      {
        type: UWG7_MAIL_UNSUBSCRIBE_PAGE__LOAD_PAGE,
        payload: content.body,
      },
      setPageTitle(content.body?.page?.headData?.title),
    ],
  });
