import loadable from "@loadable/component";

import Loading from "shared/components/Loading";

import frontendUrls from "../utils/AseFrontendUrls";

const LoadableCatalogOrderPage = loadable(
  () =>
    import(
      /* webpackChunkName: "catalogorder-page" */ "./components/CatalogOrderPage"
    ),
  {
    fallback: Loading,
  }
);

export default {
  path: frontendUrls.CATALOG_ORDER,
  component: LoadableCatalogOrderPage,
};
