// Entfernt value-items, falls deren korresponierende structure-items nicht mehr existieren.
// Sinnvoll z.B. wenn zu Ländern ohne PLZ gewechselt wird

export default function removeItemsByNamesFromValues(names, values) {
  for (let j = 0; j < values.length; j += 1) {
    for (let i = 0; i < values.length; i += 1) {
      if (values[i].name === names[j]) {
        values.splice(i, 1);
      }
    }
  }
  return true;
}
