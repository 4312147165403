import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import ElementHeadline from "./ElementHeadline";
import ElementResponsiveTeasers from "./ElementResponsiveTeasers";
import ElementText from "./ElementText";
import ElementImage from "./ElementImage";
import ElementVideoSirv from "./ElementVideoSirv";
import ElementAudio from "./ElementAudio";
import ElementProductList from "./ElementProductList";
import ElementProductSlider from "./ElementProductSlider";
import ElementSectionHeading from "./ElementSectionHeading";
import ElementHeading from "./ElementHeading";
import ElementTripleImage from "./ElementTripleImage";
import ElementHeroList from "./ElementHeroList/ElementHeroList";
import ElementDoubleTeaser from "./ElementDoubleTeaser";
import ElementTripleTeaser from "./ElementTripleTeaser";
import ElementQuadTeaser from "./ElementQuadTeaser";
import ElementTextImage from "./ElementTextImage";
import ElementTripleText from "./ElementTripleText";
import ElementTripleImageText from "./ElementTripleImageText";
import ElementLocation from "./ElementLocation";
import ElementInfoBox from "./ElementInfoBox";
import ElementAccordion from "./ElementAccordion";
import ElementSingleHeroTile from "./ElementHeroSingleTile";
import ElementRecommendations from "./ElementRecommendations";
import ElementSingleTeaser from "./ElementSingleTeaser";
import ElementWarehouseOverview from "./ElementWarehouseOverview";
import ElementCategoryBanner from "./ElementCategoryBanner";
import ElementNewsletterSignOn from "./ElementNewsletterSignOn";
import ElementNewsletterSignOnPage from "./ElementNewsletterSignOnPage/ElementNewsletterSignOnPage";
import ElementFeedbackModule from "./ElementFeedbackModule/ElementFeedbackModule";

// const isDeleteApproved = true;

function ContentKurator({
  categoryId,
  content = {},
  fullWidth,
  disableSpacings,
  onLinkClick = () => {},
  onImageLoad = () => {},
  loadedImages = {},
  sliderState,
  sliderActions,
  // eslint-disable-next-line react/prop-types
  loading,
  isLazy,
  openedContentAccordionItems = {},
  closeOrOpenContentAccordionItem = () => {},
}) {
  if (!content) {
    return null;
  }

  function getProductSliderIdName(id) {
    return `content-element-${id}`;
  }

  function getContentContainer(contentElement, index) {
    const { id, type } = contentElement;
    const lazy = isLazy && index >= 4;
    const commonParameters = {
      categoryId,
      key: `${id}_${type}`,
      lazy,
      fullWidth,
      disableSpacings,
      onLinkClick,
      onImageLoad,
      loadedImages,
      loading,
    };

    const props = { ...contentElement, ...commonParameters };

    switch (type) {
      case "headline":
        return <ElementHeadline {...props} />;

      case "stage":
      case "responsiveTeasers":
        return <ElementResponsiveTeasers {...props} />;

      case "sectionHeading":
        return <ElementSectionHeading {...props} />;

      case "heading":
        return <ElementHeading {...props} />;

      case "text":
        return <ElementText {...props} />;

      case "tripleText":
        return <ElementTripleText {...props} />;

      case "image":
        return <ElementImage {...props} />;

      case "tripleColumnImageText":
        return <ElementTripleImageText {...props} />;

      case "video":
        return <ElementVideoSirv {...props} />;

      case "recommendations":
        return (
          <ElementRecommendations
            {...props}
            sliderId={getProductSliderIdName(id)}
          />
        );

      case "manualArticleList":
      case "productList":
        switch (contentElement.productListType || contentElement.listType) {
          case "slider":
            return (
              <ElementProductSlider
                {...props}
                sliderState={sliderState}
                sliderActions={sliderActions}
                sliderId={getProductSliderIdName(id)}
              />
            );

          case "list":
          default:
            return <ElementProductList {...props} />;
        }
      case "tripleImage":
        return <ElementTripleImage {...props} />;

      case "singleTeaser":
        return <ElementSingleTeaser {...props} />;

      case "doubleTeaser":
        return <ElementDoubleTeaser {...props} />;

      case "tripleTeaser":
        return <ElementTripleTeaser {...props} />;

      case "warehouseOverview":
        return <ElementWarehouseOverview {...props} />;

      case "categoryBanner":
        return <ElementCategoryBanner {...props} />;

      case "quadTeaser":
        return <ElementQuadTeaser {...props} />;

      case "heroArticleList":
        return <ElementHeroList {...props} />;

      case "singleHeroArticle":
        return <ElementSingleHeroTile {...props} />;

      case "textImage":
        return <ElementTextImage {...props} />;

      case "location":
        return <ElementLocation {...props} />;

      case "feedbackModule":
        return <ElementFeedbackModule {...props} />;

      case "info":
        return <ElementInfoBox {...props} />;

      case "accordion":
        return (
          <ElementAccordion
            {...props}
            closeOrOpenContentAccordionItem={closeOrOpenContentAccordionItem}
            openedContentAccordionItems={openedContentAccordionItems}
          />
        );

      case "audio":
        return <ElementAudio {...props} />;

      case "newsletterSignOn":
        return <ElementNewsletterSignOn {...props} />;

      case "newsletterSignOnPage":
        return <ElementNewsletterSignOnPage {...props} />;
      default:
        return null;
    }
  }

  const containerProps = {
    id: content.id && `content-${content.id}`,
  };

  const elements = content.elements || content;

  return elements && elements.length > 0 ? (
    <div data-test-sell-content-container {...containerProps}>
      {elements.map(getContentContainer)}
    </div>
  ) : null;
}

ContentKurator.propTypes = {
  content: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  fullWidth: PropTypes.bool,
  disableSpacings: PropTypes.bool,
  onLinkClick: PropTypes.func,
  loadedImages: PropTypes.object,
  onImageLoad: PropTypes.func,
  isLazy: PropTypes.bool,
  sliderState: PropTypes.object,
  sliderActions: PropTypes.object,
  openedContentAccordionItems: PropTypes.object,
  closeOrOpenContentAccordionItem: PropTypes.func,
};

export default ContentKurator;
