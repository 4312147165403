import { combineReducers } from "redux";
import overlay from "stelar/components/Overlay/reducer";
import update from "immutability-helper";
import * as types from "../../pages/constants";
import navigation from "./navigation/reducer";
import search from "./search/reducer";
import sliders from "./sliders/reducer";
import cookieBannerState from "./cookieBanner/reducer";
// import ratingOverlayState from "./ratingOverlay/reducer";

const offCanvasInitState = {
  isDrawerOpen: false,
  isLanguageSelectionOpen: false,
};
function offCanvas(state = offCanvasInitState, action) {
  const { type } = action;
  switch (type) {
    case types.UI_STATE_OPEN_OFFCANVAS: {
      return update(state, {
        isDrawerOpen: { $set: true },
      });
    }
    case types.UI_STATE_CLOSE_OFFCANVAS: {
      return update(state, {
        isDrawerOpen: { $set: false },
      });
    }
    case types.UI_STATE_OPEN_LANGUAGE_SELECTION_OFFCANVAS: {
      return update(state, {
        isLanguageSelectionOpen: { $set: true },
      });
    }
    case types.UI_STATE_CLOSE_LANGUAGE_SELECTION_OFFCANVAS: {
      return update(state, {
        isLanguageSelectionOpen: { $set: false },
      });
    }
    default:
      return state;
  }
}

function loadedImages(state = {}, action) {
  if (action.type === types.UI_STATE_IMAGE_LOADED) {
    return update(state, {
      $merge: { [action.src]: true },
    });
  }
  return state;
}

function ready(state = false, action) {
  if (action.type === types.UI_STATE_READY) {
    return true;
  }
  return state;
}

function uiDetailPage(state = false, action) {
  if (action.type === types.UI_STATE_SHOW_TEASER) {
    return update(state, { $set: true });
  }
  return state;
}

const countrySelectionOpen = (state = false, action) => {
  switch (action.type) {
    case types.UI_STATE_TOGGLE_COUNTRY_SELECTION:
      return !state;
    case types.UI_STATE_CLOSE_COUNTRY_SELECTION:
      return false;
    default:
      return state;
  }
};

const initialFacetFilterDisplay = (state = true, action) => {
  switch (action.type) {
    case types.TOGGLE_FACET_FILTER_DISPLAY:
      return !state;
    case types.RESET_FACET_FILTER_DISPLAY:
      return true;
    default:
      return state;
  }
};

const closedFacetFilters = (state = {}, { type, facetName }) => {
  switch (type) {
    case types.UI_STATE_CLOSE_OR_OPEN_FACET_FILTER: {
      const updateData = {};
      updateData[facetName] = !state[facetName];
      return update(state, {
        $merge: updateData,
      });
    }
    case types.UI_STATE_RESET_FACET_FILTER: {
      return update(state, { $set: {} });
    }
    default:
      return state;
  }
};

const openedContentAccordionItems = (
  state = {},
  { type, contentAccordionItem }
) => {
  switch (type) {
    case types.UI_STATE_CLOSE_OR_OPEN_CONTENT_ACCORDION_ITEM: {
      const updateData = {};
      updateData[contentAccordionItem] = !state[contentAccordionItem];
      return update(state, {
        $merge: updateData,
      });
    }
    default:
      return state;
  }
};

const trackingListType = (state = {}, { type, listType }) => {
  switch (type) {
    case types.UI_STATE_SET_TRACKING_LIST_TYPE: {
      return update(state, {
        $set: { listType },
      });
    }
    case types.UI_STATE_REMOVE_TRACKING_LIST_TYPE: {
      return update(state, {
        $set: {},
      });
    }
    default:
      return state;
  }
};

export default combineReducers({
  uiDetailPage,
  offCanvas,
  loadedImages,
  sliders,
  ready,
  search,
  cookieBannerState,
  countrySelectionOpen,
  navigation,
  overlay,
  // ratingOverlayState,
  initialFacetFilterDisplay,
  closedFacetFilters,
  openedContentAccordionItems,
  trackingListType,
});
