import loadable from "@loadable/component";
import LoadingSearchForm from "./LoadingSearchForm";
import propTypes from "./propTypes";

const AsyncSearchFormWithProductSuggests = loadable(
  () => import(/* webpackChunkName: "search" */ "./InteractiveSearchForm"),
  {
    fallback: LoadingSearchForm,
  }
);

// const AsyncSearchFormWithProductSuggests = asyncComponent({
//   resolve: () =>
//     import(/* webpackChunkName: "search" */ "./InteractiveSearchForm"),
//   LoadingComponent: LoadingSearchForm,
//   serverMode: "defer",
// });

AsyncSearchFormWithProductSuggests.propTypes = propTypes;

export default AsyncSearchFormWithProductSuggests;
