import classnames from "classnames/bind";
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies

import s from "./Accordion.module.css";

const cx = classnames.bind(s);

/**
 * Used for the mobile view of the product detail page
 */
function Accordion({ children, noMarginBottom }) {
  return <div className={cx("main", noMarginBottom && "mb-0")}>{children}</div>;
}

Accordion.propTypes = {
  children: PropTypes.object,
  noMarginBottom: PropTypes.bool,
};
export default Accordion;
