function parse(query) {
  if (!query) {
    return {};
  }
  return (/^[?#]/.test(query) ? query.slice(1) : query)
    .split("&")
    .reduce((params, param) => {
      const [key, value] = param.split("=");
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : ""; // eslint-disable-line no-param-reassign
      return params;
    }, {});
}

function hasDifferentPagingParam(prevLocation, currLocation) {
  const prevPage = parse(prevLocation.search).p;
  const currPage = parse(currLocation.search).p;
  return currPage !== prevPage;
}

export default function shouldScroll(prev, curr) {
  const prevLocation = prev ? prev.location : {};
  const currLocation = curr ? curr.location : {};

  if (prev === null) {
    // first visit
    return false;
  }

  if (currLocation.hash && currLocation.hash !== "") {
    // has a hash
    return false; // url has hash, internal jumps
  }
  const isSamePath = currLocation.pathname === prevLocation.pathname;
  if (isSamePath) {
    if (hasDifferentPagingParam(currLocation, prevLocation)) {
      return true; // navigating between similar pages, but p-param changed
    }
    return false; // navigating between similar pages
  }
  return true; // scroll top
}
