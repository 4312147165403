import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import styles from "../TopBanner.module.css";
import type { TopBannerProps } from "../types";

const cx = classnames.bind(styles);

function TopBannerElement({
  link,
  elementType,
  label,
  tracking,
  handleClickAndTracking,
}: TopBannerProps) {
  function onLinkClick(e: React.MouseEvent) {
    handleClickAndTracking(e, tracking);
  }
  return link ? (
    <a
      className={cx("tbListItemLink", elementType)}
      href={link}
      onClick={onLinkClick}
    >
      <p className={cx("element0", "elementOnly")}>{label}</p>
    </a>
  ) : (
    <p className={cx("element0", "elementOnly")}>
      <span className={cx("tbListItem", elementType)}>{label}</span>
    </p>
  );
}

export default TopBannerElement;
