import queryString from "query-string";
import backendUrl from "../../pages/utils/AseBackendUrls";

import {
  ACCOUNT_DELETION_FRAGMENT__LOAD_DATA,
  ACCOUNT_DELETION_FRAGMENT__SUBMIT,
  ACCOUNT_DELETION_FRAGMENT__SUBMIT_FAILED,
} from "../../pages/constants";
import { redirectToTarget } from "../../pages/utils/Routing";

export function loadAccountDeletionFragmentData(apiClient, query) {
  return apiClient.get(
    backendUrl.ACCOUNT_DELETION_FRAGMENT,
    {
      ok: (content) => [
        {
          type: ACCOUNT_DELETION_FRAGMENT__LOAD_DATA,
          payload: content.body,
        },
      ],
    },
    {
      httpClientOptions: {
        params: query,
      },
    }
  );
}

export function submitDeletion({ routes, link, formValues }, apiClient) {
  return [
    {
      type: ACCOUNT_DELETION_FRAGMENT__SUBMIT,
    },
    apiClient.post(
      link,
      queryString.stringify(formValues, { sort: false }),
      {
        noContent: (content) => [redirectToTarget(routes, content)],
        badRequest: (content) => [
          {
            type: ACCOUNT_DELETION_FRAGMENT__SUBMIT_FAILED,
            payload: content.body,
          },
        ],
        forbidden: (content) => [
          redirectToTarget(routes, content.headers.location),
        ],
        unauthorized: (content) => [
          redirectToTarget(routes, content.headers.location),
        ],
      },
      {
        httpClientOptions: {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        },
      }
    ),
  ];
}
