import AseFrontendUrls from "../utils/AseFrontendUrls";
import { isIncludedInPathObject } from "../utils/Routing";

const queryParameterKey = "target";

const reducedLayoutCondition = (queryParameterObject) => {
  const paramValue = queryParameterObject[queryParameterKey];
  return isIncludedInPathObject(paramValue, AseFrontendUrls.CUSTOMER_ACCOUNT);
};

export default reducedLayoutCondition;
