import React from "react";
import Input from "./Input";

type Props = {
  autoComplete?: string;
  error?: boolean | string;
} & React.ComponentPropsWithoutRef<typeof Input>;

/**
 * InputTel
 */
function InputTel({ autoComplete = "tel", error = "", ...attrs }: Props) {
  return (
    <Input autoComplete={autoComplete} error={error} type="tel" {...attrs} />
  );
}

export default InputTel;
