/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconLeft(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 12 22" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path d="m 0.875,10.3 0,0 9.6,-9.6 c 0.3,-0.3 0.7,-0.3 1,0 l 0,0.1 c 0.3,0.3 0.3,0.7 0,1 l -9.2,9.2 9.2,9.2 c 0.3,0.3 0.3,0.7 0,1 l -0.1,0.1 c -0.3,0.3 -0.7,0.3 -1,0 l -9.6,-9.6 0,0 c -0.2,-0.2 -0.3,-0.4 -0.3,-0.7 l 0,-0.1 c 0.1,-0.2 0.2,-0.4 0.4,-0.6 z" />
    </svg>
  );
}

export default IconLeft;
