/* eslint-disable */

import {
  E2E_TEST_COOKIE,
  OPTIN_TRACKING,
  THIS_NOTRACK_COOKIE,
} from "./constants";
import { Base64 } from "js-base64";
import { getCookieValue, isCookieSet } from "./cookie";
import { allowedTrackingInArray } from "./optin";
import { checkEpoqCookies } from "./epoq";

function google(adwordsData) {
  const data = {
    gtmId: adwordsData.gtmId,
    dataLayer: adwordsData.dataLayer,
  };
  import(/* webpackChunkName: "tracking" */ "./googleTagManager").then(
    ({ send }) => send(data)
  );
}

function googleTagManager(data) {
  if (!data) {
    return;
  }
  import(/* webpackChunkName: "tracking" */ "./googleTagManager").then(
    ({ send }) => send(data)
  );
}

function gtag(data) {
  if (!data) {
    return;
  }
  import(/* webpackChunkName: "tracking" */ "./googleTagManager").then(
    ({ sendGtag }) => sendGtag(data)
  );
}

function epoq(data) {
  if (!data) {
    return;
  }
  import(/* webpackChunkName: "tracking" */ "./epoq").then(({ init }) =>
    init(data.tenant, data.hashId)
  );
}

function facebookPixel(data) {
  if (!data) {
    return;
  }
  import(/* webpackChunkName: "tracking" */ "./facebookPixel").then(
    ({ init }) => init(data)
  );
}

function pinterestPixel(data) {
  if (!data) {
    return;
  }
  import(/* webpackChunkName: "tracking" */ "./pinterestPixel").then(
    ({ init }) => init(data)
  );
}

function webbridgeTracking(data) {
  if (!data) {
    return;
  }
  import(/* webpackChunkName: "tracking" */ "./webbridgeTracking").then(
    ({ triggerCalls }) => triggerCalls(data)
  );
}

function trackAllowedTypes(data, options = {}) {
  const cookieValue = getCookieValue(OPTIN_TRACKING);

  try {
    const decodeTrackingData = cookieValue && Base64.decode(cookieValue);
    const allowedTrackings = allowedTrackingInArray(decodeTrackingData);

    allowedTrackings.forEach((item) => {
      switch (item) {
        case "mat":
          // matomo(data.adwordsData);
          break;
        case "gg":
          if (data.adwordsData) {
            google(data.adwordsData);
          }

          if (data.googleTagManager) {
            googleTagManager(data.googleTagManager);
          }

          if (data.gtag) {
            data.gtag.forEach((entry) => gtag(entry));
          }

          const handleEnhancedEcommerceEvent = (eventObj) => {
            if (!eventObj?.containerId) {
              return;
            }
            const isPromoViewEvent = !!eventObj.payload?.ecommerce?.promoView;
            if (
              (isPromoViewEvent && options.isPromoView) ||
              (!isPromoViewEvent && !options.isPromoView)
            ) {
              googleTagManager({
                containerId: eventObj.containerId,
                payload: { ecommerce: null }, // see https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce?hl=de#clear-ecommerce
              });
              googleTagManager(eventObj); // see https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce?hl=de#promo
            }
          };

          Array.isArray(data.googleEnhancedEcommerce)
            ? data.googleEnhancedEcommerce.forEach((obj) =>
                handleEnhancedEcommerceEvent(obj)
              )
            : handleEnhancedEcommerceEvent(data.googleEnhancedEcommerce);
          break;
        case "epq":
          checkEpoqCookies();
          epoq(data.epoqData || data.epoq);
          break;
        case "fb":
          // eslint-disable-next-line no-unused-expressions
          Array.isArray(data.fbPixel)
            ? data.fbPixel.forEach((item) => facebookPixel(item)) // eslint-disable-line no-shadow
            : facebookPixel(data.fbPixel);
          break;
        case "pin":
          // eslint-disable-next-line no-unused-expressions
          Array.isArray(data.pinPixel)
            ? data.pinPixel.forEach((item) => pinterestPixel(item)) // eslint-disable-line no-shadow
            : pinterestPixel(data.pinPixel);
          break;
        default:
          break; // track nothing
      }
    });

    const webbridgeCalls = data.webbridge;
    const purchaseCompletedName = "purchaseCompleted";
    const purchaseCompletedEvent = webbridgeCalls?.find(
      (call) => call.action === purchaseCompletedName
    );
    if (purchaseCompletedEvent) {
      webbridgeTracking([purchaseCompletedEvent]);
    }

    if (
      ["adj", "fbase", "air"].every((item) => allowedTrackings.includes(item))
    ) {
      webbridgeTracking(
        webbridgeCalls?.filter((call) => call.action !== purchaseCompletedName)
      );
    }
  } catch (e) {
    throw `ERROR: decoding optin-cookie in tracking/index ${e}`;
  }
}

export default function track(data, options = {}) {
  if (document.cookie.includes(E2E_TEST_COOKIE)) {
    return;
  }

  const isTrackingAllowed = !document.cookie.includes(THIS_NOTRACK_COOKIE);
  const trackingCookieExists = isCookieSet(OPTIN_TRACKING);

  if (!options?.noCommercialTracking) {
    if (isTrackingAllowed && trackingCookieExists) {
      if (data) {
        if (data.trackingInfo) trackAllowedTypes(data.trackingInfo);
        else trackAllowedTypes(data, options);
      }
    }
  }
}

/* eslint-enable */
