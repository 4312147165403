import classnames from "classnames/bind";
import React from "react";
import Text from "../Text";
import s from "./Price.module.css";

const cx = classnames.bind(s);

type Props = {
  additionalInfos?: string[];
  alignment?: "left" | "center" | "right";
  inline?: boolean;
  large?: boolean;
  oldPrice?: string;
  price?: string;
  skeleton?: boolean;
  unit?: string;
  vatAndShippingNote?: React.ReactNode;
  unitPriceText?: string;
};

function Price({
  additionalInfos,
  alignment = "center",
  inline,
  large,
  oldPrice,
  price,
  skeleton,
  unit,
  vatAndShippingNote,
  unitPriceText,
  ...args
}: Props) {
  const oldPriceNode = oldPrice ? (
    <div className={s.oldpriceContainer}>
      <Text
        className={s.oldPriceText}
        light={!large}
        normal={large}
        skeleton={skeleton}
        data-test-stelar-price-oldprice
      >
        <s>{oldPrice}</s>
      </Text>
    </div>
  ) : null;

  const vatNode = vatAndShippingNote ? (
    <span className={s.vatAndShippingNote}>
      <Text small skeleton={skeleton} data-test-stelar-price-vatandshippingnote>
        {vatAndShippingNote}
      </Text>
    </span>
  ) : null;

  const unitTexts = [unit, unitPriceText].filter(Boolean);

  const unitNode =
    unitTexts?.length > 0 ? (
      <span className={s.unit}>
        <Text small skeleton={skeleton} data-test-stelar-price-unit>
          {unitTexts.join(", ")}
        </Text>
      </span>
    ) : null;

  const additionalInfosNode = additionalInfos
    ? additionalInfos.map((additionalInfo, key) => {
        let additionalinfoMarkup = null;
        if (additionalInfo) {
          additionalinfoMarkup = (
            <span className={s.additionalinfo} key={additionalInfo}>
              <Text
                small
                skeleton={skeleton}
                data-test-stelar-price-additionalinfo={key}
              >
                {additionalInfo}
              </Text>
            </span>
          );
        }
        return additionalinfoMarkup;
      })
    : null;

  const priceTag = oldPrice && oldPrice !== "" ? "ins" : "span";

  const priceNode = React.createElement(
    priceTag,
    {
      "data-test-stelar-price-price": true,
      className: cx("price", { large, skeleton, normal: !large }),
    },
    price
  );

  return inline ? (
    priceNode
  ) : (
    <div {...args} className={cx("root", alignment, { large, normal: !large })}>
      {oldPriceNode}
      {priceNode}
      {additionalInfosNode}
      {unitNode}
      {vatNode}
    </div>
  );
}

export default Price;
