import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies

import SmileyFeedback from "./SmileyFeedback";
import TextFeedback from "./TextFeedback";

export default function FeedbackBox(props) {
  const { structure, uiState } = props;
  if (!structure) {
    return null;
  }

  if (!uiState.ratingSent) {
    return <SmileyFeedback {...props} />;
  }
  return <TextFeedback {...props} />;
}

FeedbackBox.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  structure: PropTypes.object,
  uiState: PropTypes.object,
  submitRating: PropTypes.func,
  submitText: PropTypes.func,
};
