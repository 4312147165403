// ase
import { setPageTitle } from "shared/webbridge/actions";
import backendUrl from "../utils/AseBackendUrls";
import { triggerBasketChangeEvent } from "../utils/BrowserEvents";
import { openPage } from "../utils/Routing";
import {
  LAST_CHECK_PAGE__CHANGE_DELIVERY_METHOD,
  LAST_CHECK_PAGE__CREATE_ORDER_SUCCESS,
  LAST_CHECK_PAGE__CREATE_ORDER_FAILED,
  LAST_CHECK_PAGE__CREATE_ORDER_STARTED,
  LAST_CHECK_PAGE__DELETE_GUTSCHEIN_ITEM,
  LAST_CHECK_PAGE__DELETE_LINE_ITEM,
  LAST_CHECK_PAGE__LOAD_LAST_CHECK_PAGE,
  LAST_CHECK_PAGE__TOGGLE_AGE_RELATED_ADVICE,
  LAST_CHECK_PAGE__UPDATE_DELIVERY_METHOD,
  LAST_CHECK_PAGE__UPDATE_LINE_ITEM,
  LAST_CHECK_PAGE__UPDATE_LINE_ITEM_EEG_RETURN,
  LAST_CHECK_PAGE__UPDATE_LINE_ITEM_QUANTITY,
  LAST_CHECK_PAGE__VALIDATE_FOR_ORDER_SUBMISSION,
  LAST_CHECK_PAGE__VALIDATE_INPUT,
} from "../constants";

export function loadLastCheckPage(apiClient, query) {
  return apiClient.get(
    backendUrl.LAST_CHECK,
    {
      ok: (content) => [
        {
          type: LAST_CHECK_PAGE__LOAD_LAST_CHECK_PAGE,
          payload: content.body,
        },
        setPageTitle(content.body?.page?.headData?.title),
      ],
    },
    {
      httpClientOptions: {
        params: query,
      },
    }
  );
}

export function toggleAgeRelatedAdvice(lineItemId) {
  return {
    type: LAST_CHECK_PAGE__TOGGLE_AGE_RELATED_ADVICE,
    lineItemId,
  };
}

export function changeDeliveryMethod({ event, link }, apiClient) {
  const { value } = event.target;
  const postData = {
    deliveryMethod: value,
  };
  return apiClient.post(link, postData, {
    noContent: (content) => [
      {
        type: LAST_CHECK_PAGE__CHANGE_DELIVERY_METHOD,
        payload: content.body,
      },
      {
        type: LAST_CHECK_PAGE__UPDATE_DELIVERY_METHOD,
        value,
      },
      loadLastCheckPage(apiClient),
    ],
  });
}

export function validateInput(inputPath) {
  return {
    inputPath,
    type: LAST_CHECK_PAGE__VALIDATE_INPUT,
  };
}

export function validateForOrderSubmission() {
  return { type: LAST_CHECK_PAGE__VALIDATE_FOR_ORDER_SUBMISSION };
}

export function deleteLineItem(deleteUrl, apiClient) {
  return apiClient.post(deleteUrl, null, {
    ok: () => [loadLastCheckPage(apiClient)],
    noContent: () => [loadLastCheckPage(apiClient)],
  });
}

export function deleteGutscheinItem(deleteUrl, apiClient) {
  return apiClient.post(deleteUrl, null, {
    noContent: () => [
      { type: LAST_CHECK_PAGE__DELETE_GUTSCHEIN_ITEM },
      loadLastCheckPage(apiClient),
    ],
    ok: () => [
      { type: LAST_CHECK_PAGE__DELETE_LINE_ITEM },
      loadLastCheckPage(apiClient),
      triggerBasketChangeEvent(),
    ],
  });
}

export function updateLineItem(apiClient, lineItemId, quantity) {
  const putData = { quantity };
  return apiClient.put(backendUrl.lineItem(lineItemId), putData, {
    ok: () => [
      {
        type: LAST_CHECK_PAGE__UPDATE_LINE_ITEM,
      },
      loadLastCheckPage(apiClient),
      triggerBasketChangeEvent(),
    ],
  });
}

export function updateQuantityOfLineItem(quantity, fieldType, lineItemId) {
  return {
    type: LAST_CHECK_PAGE__UPDATE_LINE_ITEM_QUANTITY,
    quantity,
    lineItemId,
    fieldType,
  };
}

export function updateEegReturn(apiClient, changeUrl) {
  return apiClient.put(changeUrl, null, {
    noContent: () => [
      {
        type: LAST_CHECK_PAGE__UPDATE_LINE_ITEM_EEG_RETURN,
      },
      loadLastCheckPage(apiClient),
      triggerBasketChangeEvent(),
    ],
  });
}

export function createOrder({ event, routes, link, ...data }, apiClient) {
  return (dispatch, getState) => {
    event.preventDefault();
    dispatch(validateForOrderSubmission());
    const state = getState();
    if (state.lastCheckPage.submitable) {
      dispatch([
        { type: LAST_CHECK_PAGE__CREATE_ORDER_STARTED },
        apiClient.post(link, data, {
          ok: (content) => [
            {
              type: LAST_CHECK_PAGE__CREATE_ORDER_SUCCESS,
              payload: content.body,
            },
            openPage(routes, content.headers.location),
          ],
          noContent: (content) => [
            { type: LAST_CHECK_PAGE__CREATE_ORDER_SUCCESS },
            openPage(routes, content.headers.location),
          ],
          forbidden: (content) => [openPage(routes, content.headers.location)],
          badRequest: (content) => [
            {
              type: LAST_CHECK_PAGE__CREATE_ORDER_FAILED,
              payload: content.body,
            },
            setPageTitle(content.body?.page?.headData?.title),
          ],
        }),
      ]);
    }
  };
}
