// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import Availability from "stelar/components/Availability";
import Button from "stelar/components/Button/Button";
import ContentHeading from "stelar/components/ContentHeading";
import Heading from "stelar/components/Heading";
import Message from "stelar/components/Message";
import Price from "stelar/components/Price";
import ProductImage from "stelar/components/ProductImage";
import EnergyEfficiency from "stelar/components/EnergyEfficiency";
import HtmlContent from "stelar/components/HtmlContent";
// component
import FormRows from "./FormRows";
import s from "./LineItemEditFragment.module.css";

function LineItemEditFragment({
  article,
  // cancelButton,
  doReGetFragment,
  doSubmitForm,
  doUpdateInput,
  lineItemId,
  message,
  submitButton,
}) {
  const {
    availability,
    basePrice,
    deposit,
    dimensionSelections,
    energyEfficiencyLabel,
    freeText,
    imgSrc,
    info,
    name,
    quantity,
    skuCode,
    unitPrice,
    vatAndShippingNote,
  } = article;

  const additionalInfos =
    deposit && `${deposit.labelBefore} ${deposit.value} ${deposit.labelAfter}`;

  const unit = basePrice && `${basePrice.label} ${basePrice.value}`;

  function submitAndCloseOverlay(event) {
    doSubmitForm({
      event,
      url: submitButton.link,
      values: {
        freeText: freeText ? freeText.value : undefined,
        lineItemId,
        quantity: quantity.value,
        sku: skuCode,
      },
    });
  }

  return (
    <aside className={s.root}>
      {message && <Message {...message} />}
      <ContentHeading data-test-ase-fragment-heading prio1>
        {name}
      </ContentHeading>
      <div className={s.imageAndForm}>
        <div>
          <ProductImage alt={name} data-test-ase-article-image src={imgSrc} />
        </div>
        <div>
          <form
            action={submitButton.link}
            method="post"
            onSubmit={(event) => submitAndCloseOverlay(event)}
          >
            <FormRows
              dimensionSelections={dimensionSelections}
              doReGetFragment={doReGetFragment}
              doUpdateInput={doUpdateInput}
              freeText={freeText}
              quantity={quantity}
              skuCode={skuCode}
            />
            {energyEfficiencyLabel && (
              <EnergyEfficiency
                variant="orderform"
                {...energyEfficiencyLabel}
              />
            )}
            <div className={s.gutter}>
              {unitPrice && (
                <Price
                  additionalInfos={[additionalInfos]}
                  alignment="center"
                  large
                  // oldPrice={unitPrice.old}
                  price={unitPrice.value}
                  unit={unit}
                  vatAndShippingNote={vatAndShippingNote}
                />
              )}
            </div>
            <div className={s.gutter}>
              <Button
                data-test-ase-edit-line-item-submit
                text={submitButton.label || ""}
                type="submit"
              />
            </div>
            {/* {cancelButton && (
              <div className={s.gutter}>
                <Button prio2 text={cancelButton.label || ""} type="submit" />
              </div>
            )} */}
            <div className={s.center}>
              {availability && (
                <Availability
                  additionalInfos={availability.additionalInfos}
                  info={availability.info}
                  styling={availability.styling}
                  text={availability.text}
                />
              )}
            </div>
          </form>
        </div>
      </div>
      {info && info.descriptions && (
        <div className={s.info}>
          <Heading prio4>{info.heading}</Heading>
          {info.descriptions &&
            info.descriptions.map((description) => (
              <div key={description}>
                <HtmlContent
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            ))}
        </div>
      )}
    </aside>
  );
}

LineItemEditFragment.propTypes = {
  article: PropTypes.object,
  cancelButton: PropTypes.object,
  doReGetFragment: PropTypes.func.isRequired,
  doSubmitForm: PropTypes.func.isRequired,
  doUpdateInput: PropTypes.func.isRequired,
  energyEfficiency: PropTypes.object,
  lineItemId: PropTypes.string.isRequired,
  message: PropTypes.object,
  submitButton: PropTypes.object.isRequired,
};

export const attrs = ["link"];
export const stateKey = "ASE_INITIAL_STATE_LINE_ITEM_EDIT_FRAGMENT";
export default LineItemEditFragment;
