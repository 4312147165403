import ButtonLoading from "stelar/components/Button/ButtonLoading";
import Form from "stelar/components/Form";
import FormRow from "stelar/components/FormRow";
import InputEmail from "stelar/components/Input/InputEmail";
import InputPassword from "stelar/components/Input/InputPassword";
import Text from "stelar/components/Text";
import PropTypes from "prop-types";
import React, { useRef, useEffect } from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { Controller, useForm } from "react-hook-form";
import { ruleValidation } from "../../../util/form/ruleGenerator";
import errorFromServerOrForm from "../../../util/reduxFormUtils/errorFromServerOrForm";
import { createHandleClick } from "../../utils/Routing";

const LoginContainer = ({
  doLogin,
  isLoading,
  login,
  routes,
  target,
  loginStarted,
  otrToken,
}) => {
  const isMounted = useRef(false);
  const emailRef = useRef(null);
  const emailTimerRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordTimerRef = useRef(null);

  const navigate = createHandleClick(routes);

  const defaultValues = {
    email: login?.email?.value,
    password: login?.password?.value,
  };

  const { handleSubmit, control, setValue, reset } = useForm({
    mode: "onTouched",
    defaultValues,
  });

  useEffect(() => {
    if (isMounted.current) {
      reset(defaultValues);
    }
  }, [login]);

  useEffect(() => {
    isMounted.current = true;
    const emailTimerFunc = () => {
      if (emailRef.current?.value?.length > 0) {
        setValue("email", emailRef.current?.value);
      } else {
        emailTimerRef.current = setTimeout(emailTimerFunc, 100);
      }
    };

    const passwordTimerFunc = () => {
      if (passwordRef.current?.value?.length > 0) {
        setValue("password", passwordRef.current?.value);
      } else {
        passwordTimerRef.current = setTimeout(passwordTimerFunc, 100);
      }
    };

    emailTimerFunc();
    passwordTimerFunc();

    return () => {
      clearTimeout(emailTimerRef.current);
      clearTimeout(passwordTimerRef.current);
    };
  }, []);

  const onSubmit = (data) => {
    doLogin({
      email: data.email,
      password: data.password,
      routes,
      target,
      otrToken,
    });
  };

  const recoverPassword = (
    <Text normal skeleton={isLoading}>
      <a
        href={login.passwordRecovery.link}
        data-test-ase-link-passwordforgotten
        onClick={navigate}
      >
        {login.passwordRecovery.label}
      </a>
    </Text>
  );

  return (
    <Form
      action={login.submitLink.link}
      layout="compact"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="email"
        control={control}
        rules={ruleValidation(login.email)}
        // eslint-disable-next-line react/jsx-no-bind
        render={({ field, fieldState: { isDirty, error } }) => {
          const errorMessage = errorFromServerOrForm({
            field: login.email,
            error,
            isDirty,
          });
          return (
            <FormRow
              layout="compact"
              label={{
                error: errorMessage,
                for: "login-email",
                text: login.email.label,
              }}
              skeleton={isLoading}
            >
              <input name="_otr" type="hidden" value={otrToken} />
              <input name="target" type="hidden" value={target || "/"} />
              <InputEmail
                data-test-ase-field-input="login-email"
                id="login-email"
                error={errorMessage}
                innerRef={emailRef}
                {...field}
              />
            </FormRow>
          );
        }}
      />
      <Controller
        name="password"
        control={control}
        rules={ruleValidation(login.password)}
        // eslint-disable-next-line react/jsx-no-bind
        render={({ field, fieldState: { isDirty, error } }) => {
          const errorMessage = errorFromServerOrForm({
            field: login.password,
            error,
            isDirty,
          });
          return (
            <FormRow
              layout="compact"
              label={{
                error: errorMessage,
                for: "password",
                text: login.password.label,
              }}
              labelSuffix={recoverPassword}
              skeleton={isLoading}
            >
              <InputPassword
                data-test-ase-field-input="password"
                id="password"
                error={errorMessage}
                innerRef={passwordRef}
                {...field}
              />
            </FormRow>
          );
        }}
      />
      <FormRow skeleton={isLoading}>
        <ButtonLoading
          data-test-ase-button
          data-test-ase-login-action
          prio2
          skeleton={isLoading}
          text={login.submitLink.label}
          type="submit"
          loading={loginStarted}
        />
      </FormRow>
    </Form>
  );
};

LoginContainer.propTypes = {
  doLogin: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  login: PropTypes.object.isRequired,
  routes: PropTypes.array,
  target: PropTypes.string,
  loginStarted: PropTypes.bool,
  otrToken: PropTypes.string,
};

export default LoginContainer;
