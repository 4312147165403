import AseFrontendUrls from "./AseFrontendUrls";

export default {
  APP_BASKET: "/app/basket/count",
  APP_WISHLIST: "/app/wishlist/count",
  APP_INFO: "/app/info",
  ADD_TO_BASKET: "/checkout/shared/public/basket/lineitem",
  ADD_TO_WISHLIST: "/checkout/shared/public/wishlist/add",
  ADWORDS_OPT_OUT: "/optin/revoke",
  BASKET: "/checkout/public/basket",
  CATALOG_ORDER: "/checkout/public/catalogorder",
  CATALOG_ORDER_PAGE: "/checkout/public/catalogorder/page",
  CHANGE_BILLING_ADDRESS: "/checkout/secure/changebillingaddress",
  CREATE_SHARED_BASKET: "/checkout/shared/public/basket",
  CREDIT_CARD_PAYMENT_V3: "/checkout/secure/v3/creditcard",
  CSRF_TOKEN: "/checkout/public/csrf",
  CUSTOMER_ACCOUNT: "/checkout/secure/customeraccount",
  ACCOUNT_DELETION: "/checkout/secure/customer/accountdeletion",
  ACCOUNT_DELETION_FRAGMENT:
    "/checkout/secure/customer/accountdeletion/fragment",
  DEBITOR_OVERVIEW: {
    path: "/debitor/secure/overview",
    authRedirectTarget: AseFrontendUrls.DEBITOR_OVERVIEW,
  },
  DEBITOR_REFUND_OVERLAY: {
    path: "/debitor/secure/refund",
    authRedirectTarget: AseFrontendUrls.DEBITOR_OVERVIEW,
  },
  DELIVERY: "/checkout/secure/deliveryaddress",
  EMAIL_CHANGE: "/checkout/secure/email/change",
  GUTSCHEIN_WERTABFRAGE: "/checkout/public/gutschein/wertabfrage",
  KUZ_CREDIT_CARD_PAYMENT_V2: "/payment/csOrder",
  LAST_CHECK: "/checkout/secure/ordersummary",
  LINE_ITEM_EDIT: "/checkout/public/basket/lineitem/edit",
  LOGIN: "/checkout/public/login",
  MINI_ACCOUNT: "/checkout/public/customeraccount/fragment",
  MINI_BASKET: "/checkout/public/minibasket/icon",
  MINI_BASKET_DATA: "/checkout/public/minibasket/data",
  MINI_WISHLIST: "/checkout/public/miniwishlist",
  NEWSLETTER_SUBSCRIBE: "/checkout/public/newsletter/subscribe",
  NEWSLETTER_UNSUBSCRIBE: "/checkout/public/newsletter/unsubscribe",
  NEWSLETTER_UNSUBSCRIBE_SHOP_ADDR: "/checkout/public/newsletter/dounsubscribe",
  POSTALADVERTISE_UNSUBSCRIBE: "/checkout/public/postaladvertise/reject",
  UWG7_UNSUBSCRIBE: "/checkout/public/newsletter/uwg7/unsubscribe",
  NO_SCRIPT: "/checkout/public/noscript",
  ORDER_CONFIRMATION: "/checkout/secure/orderconfirmation",
  ORDER_HISTORY: "/control/secure/orderhistory",
  SHIPMENT_TRACKING: "/shipping/secure/shipmentTracking",
  RETURNVOUCHER_BY_EMAIL: "/purchaseinfo/secure/return/byemail",
  SHIPMENT_LABEL_BY_EMAIL: "/purchaseinfo/secure/shipmentlabel/byemail",
  ORDER_SHEET: "/checkout/public/directorder",
  PASSWORD_CHANGE: "/checkout/secure/password/change",
  PASSWORD_FORGOT: "/checkout/public/customer/password/forgotten",
  PASSWORD_PAGE_CHANGE: "/checkout/public/password/forgotten/change",
  PAYMENT: "/checkout/secure/payment",
  REDUCED_LAYOUT: "/checkout/public/layout/reduced",
  REGISTER: "/checkout/public/register",
  REGISTER_LOGIN: "/checkout/public/register/login",
  SESSION: "/checkout/public/session",
  SESSION_EXPIRED: "/checkout/public/session/expired",
  UPDATE_FORM: "/checkout/public/catalogorder/address/updateform", // TODO this path should be read from be
  VOUCHER: "/checkout/public/voucher/redeem",
  VOUCHER_REDEEM: "/checkout/public/voucher/redeem",
  WISHLIST: "/checkout/public/wishlist",
  // generator-marker
  gutscheinItem: (gutscheinItemId) =>
    `/basket/gutscheinitem/${gutscheinItemId}`,
  lineItem: (lineItemId) => `/checkout/public/basket/lineitem/${lineItemId}`,
  orderHistoryDetails: (orderKey) => `/control/secure/orderdetail/${orderKey}`,
};
