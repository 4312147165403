/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "../common";

function IconAccount(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 36 36" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path d="M24.1,17.4c1.6-1.7,2.5-4.1,2.5-6.7c0-5.4-3.8-9.7-8.4-9.7c-4.7-0.1-8.4,4.2-8.4,9.6c0,2.8,0.9,5.1,2.3,6.8 C5.8,19.3,1.4,24.9,1.4,30c0,5.2,7.4,5.7,16.6,5.7c9.2,0.1,16.6-0.7,16.6-5.7C34.6,24.9,30.4,19.3,24.1,17.4z M11.6,11.1 c0-3.9,2.8-8,6.4-8c3.5,0,6.5,4.1,6.5,8c0,2.5-1.2,4.5-2.9,5.8c-0.7,0.3-1.6,0.7-1.6,1.6c0,0.3,0.4,0.7,0.6,1l1,3.6L18,24.9 l-3.3-1.7l0.9-3.8c0-0.1,0.4-0.6,0.4-0.9c0-0.7-0.9-1.3-1.5-1.6C12.8,15.6,11.6,13.5,11.6,11.1z M29,32.8c-0.3,0.1-1.2,0.3-1.9,0.4 L27,31c0-0.3-0.3-0.6-0.7-0.6H26c-0.3,0-0.6,0.3-0.6,0.7l0.1,2.3c-2.2,0.1-4.7,0.3-7.4,0.3s-6-0.1-8.4-0.3l0.1-2.2 c0.1-0.4-0.1-0.7-0.6-0.7H9c-0.4-0.1-0.7,0.1-0.7,0.6l-0.1,2.2c-0.4-0.1-1-0.3-1.2-0.3c-2.2-0.6-3.8-1.3-3.8-2.9 c0-4.7,3.9-9.2,9.2-10.8c0,0,1.3-0.3,1.5,0c0.1,0.3-1.2,5.1-1.2,5.1L17,27v3.2c0,0.7,0.3,1.2,1,1.2h0.1c0.7-0.1,1-0.4,1-1.3L18.9,27 l4.5-2.8c0,0-1.2-3.9-1.2-4.8c0-0.3,1.3,0,1.3,0c5.2,1.6,9,6.1,9.2,10.5C32.7,31,31.2,32.2,29,32.8z" />
    </svg>
  );
}

export default IconAccount;
