/* eslint-disable jsx-a11y/label-has-associated-control */
// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// component
import s from "./AddressAlternatives.module.css";
import AddressAlternative from "./AddressAlternative";

const AddressAlternatives = ({
  alternatives,
  editAddressAlternative,
  onSubmit,
}) => (
  <ul className={s.root}>
    {alternatives.map((alternative, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <li className={s.li} key={index}>
        <AddressAlternative
          alternative={alternative}
          editAddressAlternative={editAddressAlternative}
          onSubmit={() =>
            onSubmit({
              actionUrl: alternative.actionUrl,
              values: alternative.values,
            })
          }
        />
      </li>
    ))}
  </ul>
);

AddressAlternatives.propTypes = {
  alternatives: PropTypes.array.isRequired,
  editAddressAlternative: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddressAlternatives;
