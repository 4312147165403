import logger from "../logger";

const log = logger(module);

export default (store) => (next) => (action) => {
  if (!action) {
    return undefined;
  }

  if (!Array.isArray(action)) {
    return next(action);
  }

  const { dispatch } = store;

  function dispatchIfNotEmpty(tail) {
    return (r) => {
      if (tail.length > 0) {
        return dispatch(tail);
      }
      return r;
    };
  }

  if (action.length === 0) {
    throw new Error("sideEffectMiddleware expects an non empty array");
  }
  const [head, ...tail] = action;

  if (typeof head === "function") {
    return Promise.resolve(head())
      .then(dispatch, dispatch)
      .then(dispatchIfNotEmpty(tail), (e) => {
        log.info(e);
        return dispatchIfNotEmpty(tail)(e);
      });
  }
  const headResult = store.dispatch(head);
  return dispatchIfNotEmpty(tail)(headResult);
};
