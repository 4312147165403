// vendor
import React, { useEffect } from "react"; // eslint-disable-line import/no-extraneous-dependencies
import logger from "shared/logger";

const log = logger(module);

const ErrorTestPage = ({ location }) => {
  if (Object.keys(location?.query).includes("csr")) {
    useEffect(() => {
      csrfehler[0].test = 0; // eslint-disable-line no-undef
    });
    return (
      <div>
        Hier sollte eine clientseitige implizite Fehlermeldung geloggt werden.
      </div>
    );
  }

  log.info("Eine SSR Info Meldung mit zusätzlichen Daten", {
    weitereDaten: 123,
  });
  ssrfehler[0].test = 0; // eslint-disable-line no-undef
  return (
    <div>
      Hier sollte eigentlich eine SSR Info Meldung gesendet werden und ein SSR
      Fehler auftreten.
    </div>
  );
};

export default ErrorTestPage;
