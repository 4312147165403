import React from "react";
import classNames from "classnames/bind";
import s from "./Paragraph.module.css";

type Props = {
  children?: React.ReactNode;
  inverted?: boolean;
  light?: boolean;
  small?: boolean;
  skeleton?: boolean;
  className?: string;
  collapseId?: string;
  collapseText?: string;
  expandText?: string;
  expanded?: boolean;
  convertNewline?: boolean;
};

/**
 * In text design, a paragraph, passus in Latin, describes a section of a continuous text consisting of one or more sentences.
 * In a paragraph, the written text usually has its own context or even its own small topic.
 * Once this idea has been developed, a new paragraph follows.
 */
function Paragraph({
  children,
  inverted,
  light,
  small,
  className,
  skeleton,
  collapseId,
  collapseText,
  expandText,
  expanded,
  convertNewline,
  ...args
}: Props) {
  const cx = classNames.bind(s);

  const collapsibleText = collapseId && collapseText && expandText;

  let classNamesString = cx("root", {
    inverted,
    light,
    small,
    skeleton,
    collapsibleText,
  });
  classNamesString = cx(classNamesString, className);

  const collapseControlInput = collapsibleText && (
    <input
      id={collapseId}
      type="checkbox"
      defaultChecked={!(expanded || false)}
      className={cx("root", "collapseCheckbox")}
    />
  );

  const collapseControlLabel = collapsibleText && (
    <label htmlFor={collapseId}>
      <span className={cx("root", "collapseText")}>{collapseText}</span>
      <span className={cx("root", "expandText")}>{expandText}</span>
    </label>
  );

  let resultingChildren;
  if (convertNewline && typeof children === "string") {
    resultingChildren = React.createElement(small ? "small" : "span", {
      dangerouslySetInnerHTML: { __html: children.replace(/\n/g, "<br />") },
    });
  } else {
    resultingChildren = React.createElement(
      small ? "small" : "span",
      null,
      children
    );
  }

  return (
    <>
      {collapseControlInput}
      <p {...args} className={classNamesString}>
        {resultingChildren}
      </p>
      {collapseControlLabel}
    </>
  );
}

export default Paragraph;
