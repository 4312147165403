// vendor
import { connect } from "react-redux";
import prefetch from "shared/ssrPrefetch/prefetch";
import { getClientsideFetchLocals } from "shared/ssrPrefetch/useFetchLocalsStore";
// ase
import {
  loadPasswordChangeFragmentData,
  submitChangePassword,
} from "./actions";
import PasswordChangeFragment from "./components/PasswordChangeFragment";

const redial = {
  fetch: ({ apiClient, dispatch }) =>
    dispatch(loadPasswordChangeFragmentData(apiClient)),
};

const mapDispatchToProps = (dispatch) => {
  const { apiClient } = getClientsideFetchLocals();
  return {
    submitFormData: (formValues) => {
      dispatch(submitChangePassword(formValues, apiClient));
    },
  };
};

function mapStateToProps(state) {
  return {
    ...state.passwordChangeFragment.data,
    ...state.passwordChangeFragment.uiState,
  };
}

export const stateKey = "ASE_INITIAL_STATE_PASSWORDCHANGE_FRAGMENT";

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(prefetch(PasswordChangeFragment, redial));
