import PropTypes from "prop-types";
import React, { useEffect } from "react"; // eslint-disable-line import/no-extraneous-dependencies

import { IMAGE_PROFILE_DEFAULTS } from "shared/utils/imageUrlBuilder";
import useIsClient from "shared/utils/useIsClient";
import ProductTile from "../ProductTile";
import { IMAGE_SIZES, SIZE_FOUR, SIZE_HALF, SIZE_OVERLAY } from "./constants";
import InteractiveProductSlider from "./InteractiveProductSlider";
import LoadingProductSlider from "./LoadingProductSlider";

const AsyncProductSlider = ({
  products,
  onLinkClick,
  size,
  lazy,
  sliderState,
  sliderId = "0",
  sliderActions,
  skeleton,
}) => {
  const isClient = useIsClient();

  const loadedSlides = sliderState ? sliderState.loadedSliders[sliderId] : null;
  const currentSlide = sliderState
    ? sliderState.sliderPositions[sliderId]
    : null;

  function getInitialItems(sizeName) {
    switch (sizeName) {
      case SIZE_HALF:
        return 3;
      case SIZE_FOUR:
        return 4;
      case SIZE_OVERLAY:
        return 4;
      default:
        return 6;
    }
  }

  useEffect(() => {
    if (!loadedSlides && sliderActions) {
      sliderActions.onSliderSetup(
        sliderId,
        getInitialItems(size),
        products.length
      );
    }
  }, [loadedSlides]);

  if (!products || products.length === 0) {
    return null;
  }

  const initialItems = getInitialItems(size);

  const SliderComponent = isClient
    ? InteractiveProductSlider
    : LoadingProductSlider;

  const renderedProducts = products.map((product, key) => {
    const full =
      key < initialItems || (loadedSlides ? loadedSlides[key] : false);
    return (
      <div key={product}>
        <ProductTile
          {...product}
          imageProfileDefault={IMAGE_PROFILE_DEFAULTS.produktliste}
          imageSizes={IMAGE_SIZES}
          lazy={lazy}
          minimal={!full}
          type="recommendation"
          onLinkClick={onLinkClick}
          skeleton={skeleton}
        />
      </div>
    );
  });

  return (
    <SliderComponent
      size={size}
      sliderActions={sliderActions}
      loadedSlides={loadedSlides}
      currentSlide={currentSlide}
      products={products}
      onLinkClick={onLinkClick}
      sliderId={sliderId}
      sliderState={sliderState}
    >
      {renderedProducts}
    </SliderComponent>
  );
};

AsyncProductSlider.propTypes = {
  products: PropTypes.array,
  onLinkClick: PropTypes.func,
  size: PropTypes.oneOf([SIZE_FOUR, SIZE_HALF, SIZE_OVERLAY]),
  lazy: PropTypes.bool,
  skeleton: PropTypes.bool,
  sliderState: PropTypes.object,
  sliderId: PropTypes.string,
  sliderActions: PropTypes.object,
};

export default AsyncProductSlider;
