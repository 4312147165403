import { connect } from "react-redux";
import { provideHooks } from "redial";
import loadable from "@loadable/component";
import Loading from "shared/components/Loading";
import frontendUrls from "../utils/AseFrontendUrls";
import loadUwg7MailUnsubscribePage from "./actions";

const redial = {
  fetch: ({ apiClient, dispatch }) =>
    dispatch(loadUwg7MailUnsubscribePage(apiClient)),
};

const mapStateToProps = (state) => ({
  attentionMessage: state.uwg7MailUnsubscribePage.attentionMessage,
  headline: state.uwg7MailUnsubscribePage.headline,
  page: state.uwg7MailUnsubscribePage.page,
  successMessage: state.uwg7MailUnsubscribePage.successMessage,
  contentRows: state.uwg7MailUnsubscribePage.contentRows,
});

const LoadableUwg7MailUnsubscribePage = loadable(
  () =>
    import(
      /* webpackChunkName: "uwg7unsubscribe-page" */ "./components/Uwg7MailUnsubscribePage"
    ),
  {
    fallback: Loading,
  }
);

export default {
  component: provideHooks(redial)(
    connect(mapStateToProps)(LoadableUwg7MailUnsubscribePage)
  ),
  path: frontendUrls.UWG7_MAIL_CANCEL_SUBSCRIPTION,
};
