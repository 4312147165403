import update from "immutability-helper";
import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { parseActionKey } from "shared/utils/actionKey";
import { findExistingProduct, sameProductId } from "./helper";

function chooseVariantOptions(ready, data) {
  const variantSelection = get(
    data,
    "orderForm.variantSelection.variantSelection"
  );
  if (!variantSelection) {
    return data;
  }

  const { currentVariants, allVariants } = variantSelection;
  const variants = ready ? currentVariants : allVariants;
  return update(data, {
    orderForm: {
      variantSelection: {
        variantSelection: {
          allVariants: { $set: variants },
        },
      },
    },
  });
}

function keepVariantFromPreviousState(data, prevData) {
  const variantSelection = get(
    data,
    "orderForm.variantSelection.variantSelection"
  );
  const prevVariantSelection = get(
    prevData,
    "orderForm.variantSelection.variantSelection"
  );
  if (!variantSelection || !prevVariantSelection) {
    return data;
  }

  return update(data, {
    orderForm: {
      variantSelection: {
        variantSelection: { $set: prevVariantSelection },
      },
    },
  });
}

function sameArticleId(keyA, keyB) {
  const a = parseActionKey(keyA);
  const b = parseActionKey(keyB);
  return a.slug === b.slug && a.params.a === b.params.a;
}

function findProductTileInState(productKey, reducedState) {
  if (!productKey) {
    return null;
  }

  const { slug, params } = parseActionKey(productKey);
  const articleIdentifier = `${slug}-a${params.a}` || slug;

  const serachProducts = !isEmpty(reducedState.search.data)
    ? reducedState.search.data.products
    : null;

  const categoryProducts =
    !isEmpty(reducedState.category.data) && params.c
      ? !isEmpty(reducedState.category.data[params.c].products)
      : null;

  const serachBreadcrumbs = !isEmpty(reducedState.search.data)
    ? reducedState.search.data.breadcrumb
    : null;

  const allProducts = [...serachProducts, ...categoryProducts];
  const result = findExistingProduct(allProducts, articleIdentifier);
  return result ? { breadcrumb: serachBreadcrumbs, product: result } : null;
}

function mapProductTileToDetail({ breadcrumb, product }) {
  const images = {
    // TODO SELL-2386 old ImageFormat
    ...(product.name ? { altText: product.name } : {}),
    ...(product.imagePreview ? { imagePreview: product.imagePreview } : {}),
    ...(product.imageSrcSet ? { srcSet: product.imageSrcSet } : {}),
    ...(product.imageUrl ? { url: product.imageUrl } : {}),

    // TODO SELL-2386 new ImageFormat
    ...product.image,
  };

  return {
    head: {
      title: product.name,
    },
    breadcrumb,
    name: product.name,
    images: {
      main: [images],
      thumbnails: [images],
    },
  };
}

export default function mapStateToProps(state) {
  const {
    keys,
    data,
    isRecoLoaded,
    isOutfitInfoLoaded,
    isClientSideLoaded,
    loading,
  } = state.detail;
  const [currentKey, ...prevKeys] = keys;
  const { ready, sliders } = state.uiState;
  const { loadedSliders, sliderPositions } = sliders;
  const sliderState = {
    loadedSliders,
    sliderPositions,
    detail: { sliderContext: currentKey },
  };

  const localState = {
    loadedImages: state.uiState.loadedImages,
    uiDetailPage: state.uiState.uiDetailPage,
    sliderState,
    isRecoLoaded,
    isOutfitInfoLoaded,
    isClientSideLoaded,
    loadedSliderImages: state.detail.loadedSliderImages,
    currentSliderPosition: state.detail.currentSliderPosition,
    orderFormInput: state.detail.orderFormInput,
    loading,
    openedAddToCartModal: state.detail.openedAddToCartModal,
    dataForDebugging: data,
  };

  let currentData = data[currentKey];

  // use matching data if exists
  if (currentData) {
    currentData = chooseVariantOptions(ready, currentData);
    return {
      ...currentData,
      ...localState,
    };
  }

  /* LOADING STATES */

  // use data from same article if exists
  const sameArticleKey = prevKeys.find(
    (key) => sameArticleId(currentKey, key) && data[key]
  );
  if (sameArticleKey) {
    let sameArticleData = keepVariantFromPreviousState(
      data[sameArticleKey],
      data[prevKeys[0]]
    );
    sameArticleData = chooseVariantOptions(ready, sameArticleData);
    return {
      ...sameArticleData,
      ...localState,
    };
  }

  // use data from same product if exists
  const sameProductKey = prevKeys.find(
    (key) => sameProductId(currentKey, key) && data[key]
  );
  if (sameProductKey) {
    const sameProductData = chooseVariantOptions(ready, data[sameProductKey]);
    return {
      ...sameProductData,
      ...localState,
    };
  }

  // search for a product-tile object in the state and convert it to detail format
  const { category, detail, search } = state;
  const reducedState = {
    category,
    detail,
    search,
  };
  const productTile = findProductTileInState(currentKey, reducedState);
  if (productTile) {
    const productData = mapProductTileToDetail(productTile);
    return { ...productData, loading: true };
  }

  // no data available -> skeleton
  return { loading: true };
}
