import {
  LOGIN_DEFAULT_PATH,
  GLOBAL_STATUS_UNAUTHORIZED,
  GLOBAL_STATUS_REDIRECT,
} from "./constants";
import { Headers } from "./headers";

export class UnhandledStatusCodeError extends Error {
  constructor(message = "", response, ...args) {
    super(message, ...args);
    this.message = message;
    this.response = response;
  }
}

const statusCodes = {
  200: "ok",
  201: "created",
  202: "accepted",
  204: "noContent",
  302: "found",
  400: "badRequest",
  401: "unauthorized",
  403: "forbidden",
  404: "notFound",
  409: "conflict",
  410: "gone",
  500: "internalServerError",
  503: "serviceUnavailable",
  504: "gatewayTimeout",
};

function unhandled(response, configuration) {
  const { headers, status, url } = response;
  const { location } = headers;

  if (status === 204 || status === 403 || status === 406 || status === 409) {
    if (
      location &&
      typeof window !== "undefined" &&
      headers?.[Headers.X_MF_FE_HARD_NAV]
    ) {
      window.location.href = location;
      return;
    }

    if (typeof window !== "undefined" && headers?.[Headers.X_MF_FE_UPGRADE]) {
      const responseMethod = response?.config?.method?.toLocaleLowerCase();
      if (responseMethod === "get") {
        // refresh the just navigated react router path
        window.location.reload();
        return;
      }
    }

    if (!location) {
      return;
    }

    return {
      type: GLOBAL_STATUS_REDIRECT,
      payload: { code: 303, location },
    };
  }

  if (status === 401) {
    const lang = headers?.[Headers.X_MF_CONTENT_LANGUAGE]?.toLocaleUpperCase();
    const passedTarget = configuration?.authRedirectTarget;
    let targetUrl = LOGIN_DEFAULT_PATH;

    if (passedTarget) {
      if (typeof passedTarget === "object") {
        if (lang?.length > 0 && passedTarget[lang]?.length > 0) {
          targetUrl = `${LOGIN_DEFAULT_PATH}?target=${passedTarget[lang]}`;
        } else {
          targetUrl = `${LOGIN_DEFAULT_PATH}?target=${passedTarget.DE}`;
        }
      } else {
        targetUrl = `${LOGIN_DEFAULT_PATH}?target=${passedTarget}`;
      }
    } else if (location) {
      targetUrl = location;
    }
    return {
      type: GLOBAL_STATUS_UNAUTHORIZED,
      payload: targetUrl,
    };
  }

  const errorMessage = `Could not handle response from backend. ${url} statusCode: ${status}`;
  throw new UnhandledStatusCodeError(errorMessage, response);
}

export default (cases = {}) =>
  (response, configuration) => {
    const { status } = response;
    const statusName = statusCodes[status];
    const matchingCase = cases[statusName] || cases.all;
    if (matchingCase) {
      return matchingCase(response);
    }
    return unhandled(response, configuration);
  };
