// vendor
import { animated, easings, useTransition } from "@react-spring/web";
import classnames from "classnames/bind";
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import { Button } from "stelar/components/Button";
import Paragraph from "stelar/components/Paragraph";
// ase
import SummaryTable from "../../../components/SummaryTable";
import TitleBar from "../../../components/TitleBar";
import LineItemList from "./LineItemList";

import s from "./MiniBasket.module.css";

const cx = classnames.bind(s);

const MiniBasket = ({
  callToAction,
  closeText,
  einloeseItems,
  emptyBasketText,
  gutscheinItems,
  heading,
  hideOverlay,
  lineItems,
  summary,
  visible,
  handleClick,
}) => {
  const basketIsFilled = lineItems.length + gutscheinItems.length > 0;
  const rootClasses = cx("root");

  const emptyBasket = (
    <section key="emptyBasket">
      <TitleBar closeFunc={hideOverlay} closeText={closeText} title={heading} />
      <div className={s.content}>
        <Paragraph>{emptyBasketText}</Paragraph>
      </div>
    </section>
  );

  const summaryLabel = summary ? summary.label : "";
  const summaryValue = summary ? summary.value : "";
  const summaryVatLabel = summary ? summary.vatLabel : "";

  const filledBasket = (
    <section key="filledBasket">
      <TitleBar closeFunc={hideOverlay} closeText={closeText} title={heading} />
      <LineItemList
        einloeseItems={einloeseItems}
        gutscheinItems={gutscheinItems}
        lineItems={lineItems}
      />
      <div className={s.SummaryTableContainer}>
        <SummaryTable
          items={[]}
          mini
          totalLabel={summaryLabel}
          totalValue={summaryValue}
          vatLabel={summaryVatLabel}
        />
      </div>
      <div className={s.cta}>
        <Button
          href={callToAction.link}
          onClick={handleClick}
          prio1
          text={callToAction.label}
        />
      </div>
    </section>
  );

  const basketToShow = basketIsFilled ? filledBasket : emptyBasket;

  const transitions = useTransition(visible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { easing: easings.easeInQuad, duration: 200 },
  });

  return transitions((style, itemVisible) => (
    <animated.div style={style} className={rootClasses}>
      {itemVisible && basketToShow}
    </animated.div>
  ));
};

MiniBasket.propTypes = {
  callToAction: PropTypes.object,
  closeText: PropTypes.string,
  einloeseItems: PropTypes.array,
  emptyBasketText: PropTypes.string,
  gutscheinItems: PropTypes.array,
  heading: PropTypes.string,
  hideOverlay: PropTypes.func,
  lineItems: PropTypes.array,
  summary: PropTypes.object,
  visible: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default MiniBasket;
