import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import HtmlContent from "stelar/components/HtmlContent";
import classnames from "classnames/bind";
import imageSizes from "shared/utils/imageSizes";
import { IMAGE_PROFILE_DEFAULTS } from "shared/utils/imageUrlBuilder";
import s from "./ElementTextImage.module.css";
import RatioImage from "../../RatioImage";
import ContentSpacing from "../../ContentSpacing";

function ElementTextImage({
  textImage,
  imagePosition = "left",
  onImageLoad,
  loadedImages = {},
  fullWidth,
  lazy,
  onLinkClick,
}) {
  const cx = classnames.bind(s);

  const imgSizes = imageSizes({
    xxxl: "475px",
    xxl: "33vw",
    l: fullWidth ? "33vw" : "29vw",
    m: "41vw",
    default: "100vw",
  });

  const renderImage = () => (
    <div
      className={cx("image", {
        [imagePosition]: true,
      })}
      data-test-sell-element-text-image-image
    >
      <RatioImage
        image={textImage.image}
        imageProfileDefault={IMAGE_PROFILE_DEFAULTS.kurator}
        sizes={imgSizes}
        lazy={lazy}
        onImageLoad={onImageLoad}
        hasLoaded={loadedImages[textImage.pattern]}
      />
    </div>
  );

  const renderText = () => (
    <div className={s.text} data-test-sell-element-text-image-text>
      <HtmlContent
        dangerouslySetInnerHTML={{ __html: textImage.text }}
        onClick={onLinkClick}
      />
    </div>
  );

  return (
    <ContentSpacing
      alignment="imagetext"
      className={s.root}
      fullWidth={fullWidth}
      data-test-sell-element-text-image
    >
      {renderImage()}
      {renderText()}
    </ContentSpacing>
  );
}

ElementTextImage.propTypes = {
  textImage: PropTypes.object,
  imagePosition: PropTypes.oneOf(["left", "right"]),
  onImageLoad: PropTypes.func,
  loadedImages: PropTypes.object,
  fullWidth: PropTypes.bool,
  onLinkClick: PropTypes.func,
  lazy: PropTypes.bool,
};

export default ElementTextImage;
