import update from "immutability-helper";
import {
  NEWSLETTER_UNSUBSCRIBE_PAGE__CHANGE_EMAIL,
  NEWSLETTER_UNSUBSCRIBE_PAGE__LOAD_PAGE,
  NEWSLETTER_UNSUBSCRIBE_PAGE__REQUEST_NEWSLETTER_FAILED,
  NEWSLETTER_UNSUBSCRIBE_PAGE__REQUEST_NEWSLETTER,
  NEWSLETTER_UNSUBSCRIBE_PAGE__VALIDATE_ALL,
  NEWSLETTER_UNSUBSCRIBE_PAGE__VALIDATE_EMAIL,
} from "../constants";
import validateAll from "./validation";
import { validateInput } from "../utils/Validators";

const initialState = {
  email: {},
  headline: "",
  submitable: true,
  page: {
    back: {},
    submit: {},
  },
  contentRows: [],
};

const newsletterPage = (state = initialState, action) => {
  switch (action.type) {
    case NEWSLETTER_UNSUBSCRIBE_PAGE__VALIDATE_ALL:
      return validateAll(state);
    case NEWSLETTER_UNSUBSCRIBE_PAGE__LOAD_PAGE:
      return update(state, { $set: action.payload });
    case NEWSLETTER_UNSUBSCRIBE_PAGE__CHANGE_EMAIL:
      return update(state, {
        email: { value: { $set: action.value } },
      });
    case NEWSLETTER_UNSUBSCRIBE_PAGE__VALIDATE_EMAIL:
      return validateInput(state, action);
    case NEWSLETTER_UNSUBSCRIBE_PAGE__REQUEST_NEWSLETTER:
      return update(state, { $set: action.payload });
    case NEWSLETTER_UNSUBSCRIBE_PAGE__REQUEST_NEWSLETTER_FAILED: {
      if (action.payload.response.status) {
        return update(state, { $set: action.payload.response.data });
      }
      return state;
    }
    default:
      return state;
  }
};

export default newsletterPage;
