// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// component
import s from "./CatalogList.module.css";
import CheckboxGroup from "./CheckboxGroup";

const CatalogList = ({ isLoading, name, shopCatalogs }) =>
  shopCatalogs && shopCatalogs.length > 0 ? (
    <ul className={s.catalogSelectionList}>
      <CheckboxGroup
        name={name}
        options={shopCatalogs.map((shopCatalog) => ({
          label: shopCatalog.label,
          value: shopCatalog.name,
        }))}
        isLoading={isLoading}
      />
    </ul>
  ) : null;

CatalogList.propTypes = {
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  shopCatalogs: PropTypes.array,
};

export default CatalogList;
