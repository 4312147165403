import update from "immutability-helper";
import { UWG7_MAIL_UNSUBSCRIBE_PAGE__LOAD_PAGE } from "../constants";

const initialState = {
  headline: "",
  page: {
    back: {},
    submit: {},
  },
  contentRows: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UWG7_MAIL_UNSUBSCRIBE_PAGE__LOAD_PAGE:
      return update(state, { $set: action.payload });
    default:
      return state;
  }
};
