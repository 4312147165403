import { useEffect, useState } from "react"; // eslint-disable-line import/no-extraneous-dependencies

function useIsClient() {
  const [isClient, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  return isClient;
}

export default useIsClient;
