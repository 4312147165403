/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconInfo(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 28 28" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1,4.1c-6.4,0-11.6,4.4-11.6,9.9c0,1.9,0.6,3.6,1.7,5.1c0,0,0,0.1,0,0.1l-2,4.9L7,21.7c0,0,0.1,0.1,0.1,0.1c2,1.3,4.4,2,7,2c6.4,0,11.6-4.4,11.6-9.9S20.6,4.1,14.1,4.1z M14.4,19.2h-2.1l1.6-7.6H16L14.4,19.2z M16.2,10.2c-0.2,0.2-0.5,0.3-0.9,0.3c-0.7,0-1-0.3-1-0.8c0-0.4,0.1-0.7,0.3-0.9s0.5-0.3,0.9-0.3c0.3,0,0.6,0.1,0.8,0.2c0.2,0.1,0.3,0.3,0.3,0.6C16.5,9.6,16.4,9.9,16.2,10.2z"
      />
    </svg>
  );
}

export default IconInfo;
