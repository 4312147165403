// ase
import { setPageTitle } from "shared/webbridge/actions";
import { GLOBAL_STATUS_REDIRECT } from "shared/utils/status/constants";
import frontendUrl from "../utils/AseFrontendUrls";
import { openPage } from "../utils/Routing";
import backendUrl from "../utils/AseBackendUrls";
import {
  CUSTOMER_ACCOUNT_PAGE__CHANGE_ADDRESS_SUBMIT_FAILED,
  CUSTOMER_ACCOUNT_PAGE__CHANGE_SELECTION,
  CUSTOMER_ACCOUNT_PAGE__CHANGE_STANDARD,
  CUSTOMER_ACCOUNT_PAGE__CHOOSE_ADDRESS,
  CUSTOMER_ACCOUNT_PAGE__DELETE_ADDRESS,
  CUSTOMER_ACCOUNT_PAGE__DO_CHANGE_REQUEST,
  CUSTOMER_ACCOUNT_PAGE__LOAD_CUSTOMER_ACCOUNT,
  CUSTOMER_ACCOUNT_PAGE__NEW_ADDRESS_SUBMIT_FAILED,
  CUSTOMER_ACCOUNT_PAGE__NEWSLETTER_UNSUBSCRIBE_SHOP_ADDR_FAILED,
  CUSTOMER_ACCOUNT_PAGE__NEWSLETTER_UNSUBSCRIBE_SHOP_ADDR_SUCCEEDED,
  CUSTOMER_ACCOUNT_PAGE__SET_OVERLAY,
  CUSTOMER_ACCOUNT_PAGE__TOGGLE_ACCORDION,
  CUSTOMER_ACCOUNT_PAGE__TOGGLE_DELIVERY_MODE,
  CUSTOMER_ACCOUNT_PAGE__NEW_ADDRESS_SUBMIT,
  CUSTOMER_ACCOUNT_PAGE__CHANGE_ADDRESS_SUBMIT,
  CUSTOMER_ACCOUNT_PAGE__POSTALADVERTISE_UNSUBSCRIBE_FAILED,
  CUSTOMER_ACCOUNT_PAGE__POSTALADVERTISE_UNSUBSCRIBE_SUCCEEDED,
} from "../constants";

export function toggleAccordion(itemToOpen) {
  return {
    type: CUSTOMER_ACCOUNT_PAGE__TOGGLE_ACCORDION,
    itemToOpen,
  };
}
export function toggleDeliveryMode(deliveryMode) {
  // ToDO: reset/initialize form after click to overview, pls change structure/logic next time; move out of action
  if (deliveryMode === "delivery_overview") {
    return [
      { type: CUSTOMER_ACCOUNT_PAGE__TOGGLE_DELIVERY_MODE, deliveryMode },
    ];
  }
  return {
    type: CUSTOMER_ACCOUNT_PAGE__TOGGLE_DELIVERY_MODE,
    deliveryMode,
  };
}

export function loadCustomerAccount(apiClient, query) {
  return apiClient.get(
    backendUrl.CUSTOMER_ACCOUNT,
    {
      ok: (content) => [
        {
          type: CUSTOMER_ACCOUNT_PAGE__LOAD_CUSTOMER_ACCOUNT,
          payload: content.body,
        },
        setPageTitle(content.body?.page?.headData?.title),
        toggleDeliveryMode("delivery_overview"),
      ],
    },
    {
      httpClientOptions: {
        params: query,
      },
      handleUnexpected: () => ({
        type: GLOBAL_STATUS_REDIRECT,
        payload: {
          code: 303,
          location: "/500.html",
        },
      }),
    }
  );
}

export function unsubscribe(event, apiClient) {
  event.preventDefault();

  return apiClient.get(backendUrl.NEWSLETTER_UNSUBSCRIBE_SHOP_ADDR, {
    ok: (content) => ({
      type: CUSTOMER_ACCOUNT_PAGE__NEWSLETTER_UNSUBSCRIBE_SHOP_ADDR_SUCCEEDED,
      payload: content.body,
    }),
    badRequest: (content) => ({
      type: CUSTOMER_ACCOUNT_PAGE__NEWSLETTER_UNSUBSCRIBE_SHOP_ADDR_FAILED,
      payload: content.body,
    }),
  });
}

export function submitSelectedAddress({ routes, link, values }, apiClient) {
  return apiClient.post(link, values, {
    noContent: (content) => openPage(routes, content.headers.location),
    badRequest: () => openPage(routes, frontendUrl.CUSTOMER_ACCOUNT.DE), // FIXME
  });
}

export function submitChangeDeliveryAddress(
  { routes, link, values },
  apiClient
) {
  return [
    { type: CUSTOMER_ACCOUNT_PAGE__CHANGE_ADDRESS_SUBMIT },
    apiClient.post(link, values, {
      noContent: (content) => [openPage(routes, content.headers.location)],
      badRequest: (content) => [
        {
          type: CUSTOMER_ACCOUNT_PAGE__CHANGE_ADDRESS_SUBMIT_FAILED,
          payload: content.body,
        },
      ],
    }),
  ];
}

export function submitNewDeliveryAddress({ routes, link, values }, apiClient) {
  return [
    { type: CUSTOMER_ACCOUNT_PAGE__NEW_ADDRESS_SUBMIT },
    apiClient.post(link, values, {
      noContent: (content) => [
        openPage(routes, content.headers.location),
        toggleDeliveryMode("delivery_overview"),
      ],
      badRequest: (content) => [
        {
          type: CUSTOMER_ACCOUNT_PAGE__NEW_ADDRESS_SUBMIT_FAILED,
          payload: content.body,
        },
      ],
    }),
  ];
}

export function confirmAddressAlternative({ routes, link, values }, apiClient) {
  const valuesArray = values.slice();
  valuesArray.push({ name: "action", value: "CONFIRMATION" });
  const valuesDict = valuesArray.reduce((result, item) => {
    const key = item.name;
    // eslint-disable-next-line no-param-reassign
    result[key] = item.value;
    return result;
  }, {});
  return apiClient.post(link, valuesDict, {
    noContent: (content) => [
      openPage(routes, content.headers.location),
      toggleDeliveryMode("delivery_overview"),
    ],
    badRequest: (content) => [
      {
        type: CUSTOMER_ACCOUNT_PAGE__NEW_ADDRESS_SUBMIT_FAILED,
        payload: content.body,
      },
    ],
  });
}

export function confirmChangeAddressAlternative(
  { routes, link, values },
  apiClient
) {
  const valuesArray = values.slice();
  valuesArray.push({ name: "action", value: "CONFIRMATION" });
  const valuesDict = valuesArray.reduce((result, item) => {
    const key = item.name;
    // eslint-disable-next-line no-param-reassign
    result[key] = item.value;
    return result;
  }, {});
  return apiClient.post(link, valuesDict, {
    noContent: (content) => [
      openPage(routes, content.headers.location),
      toggleDeliveryMode("delivery_overview"),
    ],
    badRequest: (content) => [
      {
        type: CUSTOMER_ACCOUNT_PAGE__CHANGE_ADDRESS_SUBMIT_FAILED,
        payload: content.body,
      },
    ],
  });
}

export function editAddressAlternative(
  { routes, link, values, badRequestActionType },
  apiClient
) {
  const valuesArray = values.slice();
  valuesArray.push({ name: "action", value: "EDIT" });
  const valuesDict = valuesArray.reduce((result, item) => {
    const key = item.name;
    // eslint-disable-next-line no-param-reassign
    result[key] = item.value;
    return result;
  }, {});
  return apiClient.post(link, valuesDict, {
    noContent: (content) => [
      openPage(routes, content.headers.location),
      toggleDeliveryMode("delivery_overview"),
    ],
    badRequest: (content) => [
      {
        type: badRequestActionType,
        payload: content.body,
      },
    ],
  });
}

export function openItem(key) {
  return {
    type: CUSTOMER_ACCOUNT_PAGE__CHANGE_SELECTION,
    value: key,
  };
}
export function setOverlayOpen(value) {
  return {
    type: CUSTOMER_ACCOUNT_PAGE__SET_OVERLAY,
    payload: value,
  };
}

export function doChooseAddress({ routes, link }, apiClient) {
  return apiClient.post(link, {
    ok: (content) => [openPage(routes, content.headers.location)],
    badRequest: (content) => [
      {
        type: CUSTOMER_ACCOUNT_PAGE__CHOOSE_ADDRESS,
        payload: content.body,
      },
    ],
  });
}

export function deleteAddress(link, apiClient) {
  return apiClient.delete(link, {
    ok: (content) => ({
      type: CUSTOMER_ACCOUNT_PAGE__DELETE_ADDRESS,
      payload: content.body,
    }),
  });
}

export function doChangeRequest({ link }, apiClient) {
  return apiClient.get(link, {
    ok: (content) => [
      {
        type: CUSTOMER_ACCOUNT_PAGE__DO_CHANGE_REQUEST,
        payload: content.body,
      },
    ],
  });
}

export function changeStandard({ link, label }, apiClient) {
  if (link) {
    if (label === "Standard entfernen") {
      return apiClient.delete(link, {
        ok: (content) => [
          {
            type: CUSTOMER_ACCOUNT_PAGE__CHANGE_STANDARD,
            payload: content.body,
          },
        ],
      });
    }

    return apiClient.post(
      link,
      {},
      {
        ok: (content) => [
          {
            type: CUSTOMER_ACCOUNT_PAGE__CHANGE_STANDARD,
            payload: content.body,
          },
        ],
      }
    );
  }
}

export function unsubscribePostalAdvertise(event, apiClient) {
  event.preventDefault();

  return apiClient.post(
    backendUrl.POSTALADVERTISE_UNSUBSCRIBE,
    {},
    {
      ok: (content) => ({
        type: CUSTOMER_ACCOUNT_PAGE__POSTALADVERTISE_UNSUBSCRIBE_SUCCEEDED,
        payload: content.body,
      }),
      badRequest: (content) => ({
        type: CUSTOMER_ACCOUNT_PAGE__POSTALADVERTISE_UNSUBSCRIBE_FAILED,
        payload: content.body,
      }),
    }
  );
}
