import {
  GUTSCHEIN_FORM_COMPONENT__DISABLE_FORM,
  GUTSCHEIN_FORM_COMPONENT__ENABLE_FORM,
  GUTSCHEIN_FORM_COMPONENT__LOAD_DATA,
  GUTSCHEIN_FORM_COMPONENT__TOGGLE_DISPLAY,
} from "../../pages/constants";
import backendUrl from "../../pages/utils/AseBackendUrls";
import { loadBasketPage } from "../../pages/BasketPage/actions";
import { loadLastCheckPage } from "../../pages/LastCheckPage/actions";

export function loadGutscheinFormData(apiClient, query) {
  return apiClient.get(
    backendUrl.VOUCHER,
    {
      ok: (content) => ({
        type: GUTSCHEIN_FORM_COMPONENT__LOAD_DATA,
        payload: content.body,
      }),
    },
    {
      httpClientOptions: {
        params: query,
      },
    }
  );
}

export function toggleGutscheinFormDisplay() {
  return {
    type: GUTSCHEIN_FORM_COMPONENT__TOGGLE_DISPLAY,
  };
}

export function redeemVoucher({ link, values, context, apiClient }) {
  return [
    {
      type: GUTSCHEIN_FORM_COMPONENT__DISABLE_FORM,
    },
    () =>
      apiClient.post(link, values, {
        created: (content) => ({
          type: GUTSCHEIN_FORM_COMPONENT__LOAD_DATA,
          payload: content.body,
        }),
        badRequest: (content) => ({
          type: GUTSCHEIN_FORM_COMPONENT__LOAD_DATA,
          payload: content.body,
        }),
      }),
    () => ({
      type: GUTSCHEIN_FORM_COMPONENT__ENABLE_FORM,
    }),
    () =>
      context === "basket"
        ? loadBasketPage(apiClient)
        : loadLastCheckPage(apiClient),
  ];
}
