// vendor
import update from "immutability-helper";
// ase
import {
  ORDER_HISTORY_DETAILS_FRAGMENT__LOAD_DATA_STARTED,
  ORDER_HISTORY_DETAILS_FRAGMENT__LOAD_DATA_SUCCESS,
} from "../../pages/constants";
// component
import initialState from "./initialState";

function orderHistoryDetailsFragment(state = initialState, action) {
  switch (action.type) {
    case ORDER_HISTORY_DETAILS_FRAGMENT__LOAD_DATA_STARTED: {
      return update(state, { $set: initialState });
    }
    case ORDER_HISTORY_DETAILS_FRAGMENT__LOAD_DATA_SUCCESS: {
      return update(state, { $set: action.payload });
    }
    default:
      return state;
  }
}

export default orderHistoryDetailsFragment;
