import { navigateTo } from "shared/utils/routing";
import { triggerLocationChange } from "shared/utils/customEvents";
import { getSliderActions } from "../../utils/uiState/sliders/actions";

export default function mapDispatchToProps(dispatch) {
  return {
    sliderActions: getSliderActions(dispatch),
    changeLocation: (path, isOwnContext) => {
      if (!isOwnContext) {
        navigateTo(path, {});
      } else {
        triggerLocationChange(path);
      }
    },
  };
}
