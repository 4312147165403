// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { useFormContext } from "react-hook-form";
// mf-stelar
import ButtonBack from "stelar/components/Button/ButtonBack";
import ButtonGroup from "stelar/components/ButtonGroup";
import Message from "stelar/components/Message";
// ase
import { ButtonLoading } from "stelar/components/Button";
import Text from "stelar/components/Text";
import { buildFormRow } from "../../util/reduxFormUtils/reduxFormRowFieldBuilder";
// component
import AddressFormRow from "./AddressFormRow";
import s from "./AddressFormContent.module.css";

const AddressFormContent = ({
  buttonNextTestId,
  formDefinition,
  formName,
  layout,
  cancelButton,
  submitButton,
  toggleDeliveryMode,
  isCatalog = false,
  updateForm,
  updateFormURL,
  message,
  submitStarted,
  hint,
}) => {
  const context = useFormContext();
  const formData = formDefinition.values || [
    { name: "country", value: "DE", error: "" },
  ];
  const valueMap = formData.reduce((map, valueObject) => {
    // eslint-disable-next-line no-param-reassign
    map[valueObject.name] = valueObject;
    return map;
  }, {});

  const leftButton = cancelButton && toggleDeliveryMode && (
    <ButtonBack
      onClick={() => toggleDeliveryMode("delivery_overview")}
      prio3
      text={cancelButton.label}
      data-test-ase-button
    />
  );

  return (
    <div>
      {message && (
        <Message
          text={message.text}
          title={message.title}
          type={message.type}
        />
      )}
      {formDefinition.structure.map((row) => (
        <AddressFormRow
          formName={formName}
          key={row[0].name}
          layout={layout}
          row={row}
          valueMap={valueMap}
        >
          {buildFormRow({
            formName,
            row,
            updateForm,
            updateFormURL,
            valueMap,
            context,
          })}
        </AddressFormRow>
      ))}
      {hint && (
        <div className={s.hintContainer}>
          {hint.headline && <Text em>{hint.headline}</Text>}
          {hint.text && <Text normal>{hint.text}</Text>}
        </div>
      )}
      {!isCatalog ? (
        <ButtonGroup
          className={s.noScriptButtons}
          buttonLeft={leftButton}
          buttonRight={
            <ButtonLoading
              data-test-ase-button
              data-test-ase-id={buttonNextTestId}
              text={submitButton.label}
              type="submit"
              loading={submitStarted}
              next
            />
          }
        />
      ) : (
        <div className={s.buttonStyle}>
          <ButtonLoading
            data-test-ase-button
            data-test-ase-id={buttonNextTestId}
            text={submitButton.label}
            type="submit"
            loading={submitStarted}
            next
          />
        </div>
      )}
    </div>
  );
};

AddressFormContent.propTypes = {
  buttonNextTestId: PropTypes.string,
  cancelButton: PropTypes.object,
  formDefinition: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  isCatalog: PropTypes.bool,
  layout: PropTypes.oneOf(["compact", "fluid", "standard", "fixed"]),
  submitButton: PropTypes.object.isRequired,
  toggleDeliveryMode: PropTypes.func,
  updateForm: PropTypes.func.isRequired,
  updateFormURL: PropTypes.string.isRequired,
  message: PropTypes.object,
  submitStarted: PropTypes.bool,
  hint: PropTypes.object,
};

export default AddressFormContent;
