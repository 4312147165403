const initialState = {
  header: {
    serviceLabel: "…………………………, ………………… & ……………………",
    servicePhoneNumber: "…………… ………-………",
  },
  checkoutProgressBar: {
    activeStepLabel: "……………………… …………………",
    basketLabel: "………………………",
    steps: [
      {
        label: "…………… & …………………",
      },
      {
        label: "…………………",
      },
      {
        label: "…………………",
      },
      {
        label: "………………",
        link: "",
      },
    ],
  },
  footer: {
    benefits: {
      labels: [
        "………………………………………………",
        "……………………………………………",
        "…………………………… ………………………………………",
      ],
      link: "/Kontakt.html",
    },
    bottomLine:
      "………………………… …………………………… - …………………………………… … - …-…………… ………………… - ………………… +…… ………… ……… ……… - ……… +…… ………… ……… ………",
    legal: {
      agb: {
        label: "………",
        link: "/agb",
      },
      datenschutz: {
        label: "……………………………",
        link: "/datenschutz",
      },
      impressum: {
        label: "………………………",
        link: "/impressum",
      },
    },
  },
};

export default initialState;
