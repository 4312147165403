// vendor
import classNames from "classnames/bind";
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import Button from "stelar/components/Button/Button";
import Checkbox from "stelar/components/Checkbox";
import FormRow from "stelar/components/FormRow";
import Message from "stelar/components/Message";
import Text from "stelar/components/Text";
import { Input, InputEmail } from "stelar/components/Input";
// ase
import markdown from "../../../util/markdownLinkBuilder";
import overlayTrigger from "../../../util/overlayTrigger";
import { hasNonWhitespaceValue } from "../../../util/propertyUtils";
// component
import header from "./NewsletterSubscriptionHeader";
import s from "./NewsletterSubscription.module.css";

const cx = classNames.bind(s);

function AwarenessRow({ children }) {
  return <div className={s.awarenessRow}>{children}</div>;
}

AwarenessRow.propTypes = {
  children: PropTypes.object,
};

function NewsletterSubscriptionFragment({
  doSubmitForm,
  doUpdateCheckbox,
  doUpdateInput,
  layout,
  point,
  newsletterSubscription,
  successMessage,
  categoryId,
}) {
  layout = layout || "footer"; // eslint-disable-line no-param-reassign
  let layoutIsFooter = layout === "footer";
  const isBannerLayout = layout === "banner";
  const isKuratorLayout = layout === "kurator";
  if (isKuratorLayout || isBannerLayout) {
    layoutIsFooter = true;
  }

  const successMessageText =
    successMessage && successMessage.text ? successMessage.text : "";
  const successMessageText2 =
    successMessage && successMessage.text2 ? successMessage.text2 : "";

  const fullSuccessMessageText = `${successMessageText} \n \n ${successMessageText2}`;

  const submitButton =
    typeof newsletterSubscription.page.submitButton !== "undefined"
      ? newsletterSubscription.page.submitButton
      : newsletterSubscription.page.submit;

  if (!submitButton?.link) {
    return null;
  }

  const nlHeader =
    layoutIsFooter && !isKuratorLayout && !isBannerLayout
      ? header({
          heading: newsletterSubscription.page.headline,
          shortDesc: newsletterSubscription.pageDescription,
        })
      : null;

  const button =
    newsletterSubscription.page && !successMessage ? (
      <Button
        className={s.button}
        data-test-ase-prev-page
        inverted={layoutIsFooter}
        prio1={!layoutIsFooter}
        prio2={layoutIsFooter}
        text={submitButton?.label || ""}
        type="submit"
      />
    ) : null;

  const emailField = newsletterSubscription.email ? (
    <div className={s.emailField}>
      <FormRow
        inverted={layoutIsFooter}
        label={{
          error: newsletterSubscription.email.error,
          for: "newsletterSubscriptionEmail",
        }}
        layout="compact"
      >
        <InputEmail
          data-test-ase-field-input="email"
          defaultValue={newsletterSubscription.email.value}
          id="newsletterSubscriptionEmail"
          name="email"
          onChange={doUpdateInput}
          placeholder={newsletterSubscription.email.label}
        />
      </FormRow>
    </div>
  ) : null;

  const success = successMessage ? (
    <Message
      text={fullSuccessMessageText}
      data-test-ase-newsletter-subscription-successful
      type="success"
    />
  ) : null;

  const datenschutzLabel = newsletterSubscription.datenschutz?.label ? (
    <Text
      className={s.privacyLabel}
      onClick={overlayTrigger}
      small
      highContrast={!layoutIsFooter}
    >
      <span
        // omitting this span causes console warning
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: markdown(newsletterSubscription.datenschutz.label),
        }}
      />
    </Text>
  ) : null;

  const emailInputExists =
    newsletterSubscription &&
    newsletterSubscription.email &&
    hasNonWhitespaceValue(newsletterSubscription.email.value);

  const datenschutz =
    newsletterSubscription.datenschutz &&
    newsletterSubscription.datenschutz.label ? (
      <FormRow
        inverted={layoutIsFooter}
        label={{
          error: newsletterSubscription.datenschutz.error,
          for: `datenschutz-${layout}`,
        }}
        layout="compact"
      >
        <Checkbox
          data-test-ase-field-input="datenschutz"
          id={`datenschutz-${layout}`}
          name="datenschutz"
          label={datenschutzLabel}
          onChange={doUpdateCheckbox}
          collapseText={newsletterSubscription.datenschutz.collapseText}
          expandText={
            emailInputExists || !layoutIsFooter || isBannerLayout // false <-- das ist die Stelle, um das Einklappen auf der Anmeldeseite zu verhindern...
              ? " "
              : newsletterSubscription.datenschutz.expandText
          }
          autoexpand={!isBannerLayout}
          className={s[layout]}
          collExpClassName={s.collapseExpand}
          labelClassName={
            !emailInputExists && !isBannerLayout && layoutIsFooter
              ? s.collExpLabel
              : undefined
          } // das ist die zweite Stelle, um das Einklappen auf der Anmeldeseite zu verhindern...
          showScrollbars={isBannerLayout}
        />
      </FormRow>
    ) : null;

  const awarenessHintRow = newsletterSubscription.awarenessHint ? (
    <AwarenessRow>
      <FormRow
        label={{
          text: newsletterSubscription.awarenessHint.label,
          for: "newsletterSubscriptionAwarenesshintRow",
        }}
        layout="fluid"
      >
        <Input
          data-test-ase-field-input="email"
          defaultValue=""
          id="newsletterSubscriptionAwarenesshintRow"
          name="awarenessHint"
          placeholder={newsletterSubscription.awarenessHint.label}
        />
      </FormRow>
    </AwarenessRow>
  ) : null;

  const awarenessIdRow = newsletterSubscription.awarenessId ? (
    <AwarenessRow>
      <FormRow
        label={{
          text: newsletterSubscription.awarenessId.label,
          for: "newsletterSubscriptionAwarenessIdRow",
        }}
        layout="fluid"
      >
        <Input
          data-test-ase-field-input="email"
          defaultValue={newsletterSubscription.awarenessId.value}
          id="newsletterSubscriptionAwarenessIdRow"
          name="awarenessId"
          placeholder={newsletterSubscription.awarenessId.label}
          hidden
          readOnly
        />
      </FormRow>
    </AwarenessRow>
  ) : null;

  const submitAction = point
    ? `${submitButton.link}/point_${point}`
    : `${submitButton.link}/point_footer`;

  return newsletterSubscription ? (
    <div
      className={cx(isBannerLayout ? "" : "root", layout)}
      data-test-ase-newsletter-fragment
    >
      {nlHeader}
      {submitButton && (
        <form
          action={submitAction}
          method="post"
          noValidate
          onSubmit={(e) =>
            doSubmitForm(e, submitAction, {
              email: newsletterSubscription.email.value,
              datenschutz: newsletterSubscription.datenschutz.checked,
              awarenessId: newsletterSubscription.awarenessId.value,
              awarenessHint: newsletterSubscription.awarenessHint.value,
              categoryId,
            })
          }
        >
          <div className={s.flexBox}>
            {emailField}
            {awarenessIdRow}
            {awarenessHintRow}
            {!layoutIsFooter ? datenschutz : null}
            {button}
          </div>
          {success}
          {layoutIsFooter ? datenschutz : null}
        </form>
      )}
    </div>
  ) : null;
}

NewsletterSubscriptionFragment.propTypes = {
  doSubmitForm: PropTypes.func,
  doUpdateCheckbox: PropTypes.func,
  doUpdateInput: PropTypes.func,
  layout: PropTypes.oneOf(["footer", "page", "banner", "kurator"]),
  point: PropTypes.string,
  categoryId: PropTypes.string,
  newsletterSubscription: PropTypes.object,
  successMessage: PropTypes.object,
};

export default NewsletterSubscriptionFragment;
