/* eslint-disable import/prefer-default-export */

import { GLOBAL_STATUS_FAILED } from "./status/constants";

export function error(state = false, action) {
  if (action.type === GLOBAL_STATUS_FAILED) {
    return true;
  }
  if (action.type.endsWith("_SUCCESS")) return false;
  return state;
}
