import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classNames from "classnames/bind";
import CountryOption from "../CountryOption";
import CurrentCountry from "../CurrentCountry";
import Headline from "../Headline";
import s from "./CountrySelection.module.css";

const cx = classNames.bind(s);

const CountrySelection = ({
  country,
  countrySelectionOpen,
  onToggleCountrySelection,
  onCloseCountrySelection,
}) => {
  const selector = cx("selector", {
    selectorOpen: countrySelectionOpen,
    selectorClosed: !countrySelectionOpen,
  });

  const selectorOptions = cx("selectorOptions", {
    showOptions: countrySelectionOpen,
    hideOptions: !countrySelectionOpen,
  });

  return (
    <div>
      <Headline>{country.headline}</Headline>
      <div className={s.selectorWrapper}>
        <div className={selector} onMouseLeave={onCloseCountrySelection}>
          <CurrentCountry
            current={country.current}
            isCountrySelectionOpen={countrySelectionOpen}
            toggleCountrySelection={onToggleCountrySelection}
          />
          <ul className={selectorOptions}>
            {country.options.map(({ name, link, icon }) => (
              <CountryOption
                key={link}
                name={name}
                link={link}
                iconName={icon}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

CountrySelection.propTypes = {
  country: PropTypes.object.isRequired,
  countrySelectionOpen: PropTypes.bool.isRequired,
  onToggleCountrySelection: PropTypes.func.isRequired,
  onCloseCountrySelection: PropTypes.func,
};

CountrySelection.defaultProps = {};

export default CountrySelection;
