import React from "react";

import s from "./OffCanvasOverlay.module.css";

type Props = {
  active?: boolean;
  onClick?: (...args: unknown[]) => unknown;
};

function OffCanvasOverlay({ active, onClick }: Props) {
  /* TODO a11y */
  /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
  return !active ? null : <div className={s.root} onClick={onClick} />;
}

export default OffCanvasOverlay;
