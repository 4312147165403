import update from "immutability-helper";
import { validateInput } from "../utils/Validators";

const passwordInputs = [
  { inputPath: "password" },
  { inputPath: "passwordRepetition" },
];

export function validatePasswordRepetition(state) {
  const pwdRep = state.passwordRepetition;
  if (pwdRep.validation && pwdRep.validation.constructor === Array) {
    return validateInput(state, { inputPath: "passwordRepetition" });
  }

  // ab hier alte Validierung

  const password = state.password.value;
  const passwordRepetition = state.passwordRepetition.value;

  let error = "";

  if (!(password === passwordRepetition)) {
    error = state.passwordRepetition.errorInvalid;
  }
  const updateObject = { passwordRepetition: { error: { $set: error } } };
  if (error) {
    updateObject.submitable = { $set: false };
  }
  return update(state, updateObject);
}

export function validateAll(state) {
  let newState = state;
  newState = update(newState, { submitable: { $set: true } });
  passwordInputs.forEach((input) => {
    newState = validateInput(newState, input);
  });
  newState = validatePasswordRepetition(newState);
  return newState;
}

export function validateEmail(state) {
  let newState = state;
  newState = update(newState, { submitable: { $set: true } });
  newState = validateInput(newState, { inputPath: "email" });
  return newState;
}
