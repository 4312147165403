import React from "react";
import classNames from "classnames/bind";
import s from "./Heading.module.css";

type Props = {
  children: React.ReactNode;
  className?: string;
  inline?: boolean;
  inverted?: boolean;
  highContrast?: boolean;
  prio0?: boolean;
  prio1?: boolean;
  prio2?: boolean;
  prio3?: boolean;
  prio4?: boolean;
  skeleton?: boolean;
  tag?: string;
};

/**
 * The block heading is used to title blocks that are difficult to format.
 * Typical use cases are tables or other functional elements on a page.
 * In legacy content, these headings could be used in the busy text. This should now be abandoned.
 */
function Heading({
  children,
  className,
  prio0,
  prio1,
  prio2,
  prio3,
  prio4,
  inverted,
  highContrast,
  tag,
  inline,
  skeleton,
  ...args
}: Props) {
  const cx = classNames.bind(s);
  let classNamesString = cx("root", {
    prio0,
    prio1,
    prio2,
    prio3,
    prio4,
    inverted,
    highContrast,
    inline,
    block: !inline,
  });
  classNamesString = cx(classNamesString, className);

  let tagOveride = tag;
  if ((prio1 && !tag) || (prio0 && !tag)) {
    tagOveride = "h1";
  } else if (prio2 && !tag) {
    tagOveride = "h2";
  } else if (prio3 && !tag) {
    tagOveride = "h3";
  } else if (prio4 && !tag) {
    tagOveride = "h4";
  } else if (!tag) {
    tagOveride = "h3";
  }

  const headingText = !skeleton ? (
    children
  ) : (
    <span className={s.skeleton}>{children}</span>
  );

  return React.createElement(
    tagOveride,
    { ...args, className: classNamesString },
    headingText
  );
}

export default Heading;
