export const filterEmptyOrNeedlessFormValues = ({
  values,
  structureFields,
  additionalFieldNames,
}) =>
  Object.keys(values)
    .filter((fieldName) => {
      if (
        structureFields &&
        !structureFields.some(
          (structureField) => structureField.name === fieldName
        )
      ) {
        if (additionalFieldNames?.length > 0) {
          if (
            !additionalFieldNames.some(
              (additionalFieldName) => additionalFieldName === fieldName
            )
          ) {
            return false;
          }
        } else {
          return false;
        }
      }
      if (values[fieldName] === undefined || values[fieldName] === null) {
        return false;
      }
      if (
        typeof values[fieldName] === "string" &&
        values[fieldName].length === 0
      ) {
        return false;
      }
      if (
        typeof values[fieldName] === "boolean" &&
        values[fieldName] === false
      ) {
        return false;
      }
      return true;
    })
    .reduce((prev, field) => ({ ...prev, [field]: values[field] }), {});
