/* eslint-disable */

import { Base64 } from "js-base64";
import { createHttpClient } from "../apiClient/HttpClient";
import { OPTIN_TRACKING } from "./constants";
import { getServerCookieValue, isLoggedToday } from "./cookie";
import { mapRequestCookiesToAxios } from "../apiClient/server/apicookies";
import { extractPasstroughHeaders } from "../apiClient/headers";

export const TRACKING_OPTIN_PATH = "/optin/access";

const TRACKING_TYPES = [
  "general",
  "basic",
  "google",
  "epoq",
  "fbPixel",
  "pinPixel",
  "adjust",
  "firebase",
  "airship",
];

export function allowedTrackingInArrayBasic(json) {
  let list = [];
  if (json) {
    const data = JSON.parse(json);
    for (const [key, value] of Object.entries(data)) {
      if (TRACKING_TYPES.includes(key)) {
        value ? list.push(key) : null;
      }
    }
  }
  return list;
}

export function allowedTrackingInArray(json) {
  let list = ["min"];
  if (json) {
    const data = JSON.parse(json);
    for (const [key, value] of Object.entries(data)) {
      if (value && TRACKING_TYPES.includes(key)) {
        const shorthandTypeLst = shorthandTrackingType(key, data.version);
        list = Array.from(new Set([...list, ...shorthandTypeLst]));
      }
    }
  }
  return Array.from(new Set(list));
}

// prepare values for backend, change types ex. from google to gg
function shorthandTrackingType(key, version) {
  switch (key) {
    case "basic":
      return ["min"];
    case "general":
      if (version === 1) {
        return ["gg", "min", "epq"];
      }
      if (version === 2) {
        return ["gg", "min"];
      }
      if (version === 3) {
        return ["min"];
      }
      break;
    case "google":
      return ["gg"];
    case "epoq":
      return ["epq"];
    case "fbPixel":
      if (version === 2) {
        return ["fb", "epq"];
      }
      if (version >= 3) {
        return ["fb"];
      }
      break;
    case "pinPixel":
      return ["pin"];
    case "matomo":
      return ["mat"];
    case "adjust":
      return ["adj"];
    case "firebase":
      return ["fbase"];
    case "airship":
      return ["air"];
    default:
      return null;
  }
}

export function logAccess(req, res, apiHost) {
  if (req.headers.cookie) {
    const mfTrackingCookieValue = getServerCookieValue(
      OPTIN_TRACKING,
      req.headers.cookie
    );
    if (mfTrackingCookieValue) {
      if (!isLoggedToday(Base64.decode(mfTrackingCookieValue))) {
        // if is not logged today, call api
        callAccessAndSetCookie(req, res, apiHost);
      }
    } else {
      // if there is no mf tracking cookie, call api
      callAccessAndSetCookie(req, res, apiHost);
    }
  }
}

function callAccessAndSetCookie(req, res, apiHost) {
  const cookies = mapRequestCookiesToAxios(req);
  const httpClient = createHttpClient({
    cookies,
    apiHost,
    passthroughHeaders: extractPasstroughHeaders(req.headers),
  });
  httpClient
    .post(TRACKING_OPTIN_PATH, {}, { apiBase: "/api/ase" })
    .then((response) => {
      const cookieHeaders = response.headers["set-cookie"];
      if (cookieHeaders) {
        if (res.headersSent) {
          console.warn("Can't set cookie-header - headers already sent...");
        } else {
          res.append("set-cookie", cookieHeaders);
        }
      }
    })
    .catch(() => {
      return;
    });
}

/* eslint-enable */
