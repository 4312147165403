// redux actions
export const LOAD_FEEDBACK_STRUCTURE_STARTED =
  "LOAD_FEEDBACK_STRUCTURE_STARTED";
export const LOAD_FEEDBACK_STRUCTURE_SUCCESS =
  "LOAD_FEEDBACK_STRUCTURE_SUCCESS";
export const LOAD_FEEDBACK_STRUCTURE_FAILED = "LOAD_FEEDBACK_STRUCTURE_FAILED";

export const SEND_FEEDBACK_RATING_STARTED = "SEND_FEEDBACK_RATING_STARTED";
export const SEND_FEEDBACK_RATING_SUCCESS = "SEND_FEEDBACK_RATING_SUCCESS";
export const SEND_FEEDBACK_RATING_FAILED = "SEND_FEEDBACK_RATING_FAILED";

export const SEND_FEEDBACK_TEXT_STARTED = "SEND_FEEDBACK_TEXT_STARTED";
export const SEND_FEEDBACK_TEXT_SUCCESS = "SEND_FEEDBACK_TEXT_SUCCESS";
export const SEND_FEEDBACK_TEXT_FAILED = "SEND_FEEDBACK_TEXT_FAILED";

// redux initial state for reducer
export const initialState = {
  structure: null,
  id: null,
  uiState: {
    loading: false,
    ratingSent: false,
    textSent: false,
    unexpectedError: false,
  },
};
