import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import SearchBar from "stelar/components/SearchBar";
import propTypes from "./propTypes";

function LoadingSearchForm({
  placeholder,
  searchString,
  onChange,
  link,
  buttonText,
  onSubmit,
}) {
  function onChangeInput(event) {
    const { value } = event.target;
    onChange(value);
  }
  const inputProps = {
    placeholder,
    value: searchString || "",
    onChange: onChangeInput,
  };
  return (
    <form method="GET" action={link} onSubmit={onSubmit}>
      <SearchBar {...inputProps} buttonText={buttonText} />
    </form>
  );
}

LoadingSearchForm.propTypes = propTypes;

export default LoadingSearchForm;
