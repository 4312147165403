import { setPageTitle } from "shared/webbridge/actions";
import backendUrl from "../utils/AseBackendUrls";
import { openPage } from "../utils/Routing";
import {
  ORDER_SHEET_PAGE__ADD_ORDER_SHEET_ROW,
  ORDER_SHEET_PAGE__ADD_TO_BASKET_FAILED,
  ORDER_SHEET_PAGE__ADD_TO_BASKET_STARTED,
  ORDER_SHEET_PAGE__DEL_ORDER_SHEET_ROW,
  ORDER_SHEET_PAGE__GET_ARTICLE_DATA,
  ORDER_SHEET_PAGE__GET_ARTICLE_DATA_FAILED,
  ORDER_SHEET_PAGE__LOAD_ORDER_SHEET_PAGE,
  ORDER_SHEET_PAGE__RERENDER_STATE,
} from "../constants";

export function loadOrderSheetPage(apiClient, query) {
  return apiClient.get(
    backendUrl.ORDER_SHEET,
    {
      ok: (content) => [
        {
          type: ORDER_SHEET_PAGE__LOAD_ORDER_SHEET_PAGE,
          payload: content.body,
        },
        setPageTitle(content.body?.page?.headData?.title),
      ],
    },
    {
      httpClientOptions: {
        params: query,
      },
    }
  );
}

export function addOrderSheetRow(articleNumberScheme) {
  const inputScheme = articleNumberScheme || {};
  inputScheme.value = "";
  inputScheme.error = "";
  return {
    type: ORDER_SHEET_PAGE__ADD_ORDER_SHEET_ROW,
    inputScheme,
    name: "articleNumber",
  };
}

export function delOrderSheetRow(rowIndex) {
  return {
    type: ORDER_SHEET_PAGE__DEL_ORDER_SHEET_ROW,
    rowIndex,
  };
}

export function getArticleData(
  { articleNumber, quantity, rowIndex, searchLink, variantSelect },
  apiClient
) {
  const postData = { articleNumber, quantity, variantSelect };
  return apiClient.post(searchLink, postData, {
    created: (content) => [
      {
        type: ORDER_SHEET_PAGE__GET_ARTICLE_DATA,
        payload: content.body,
        meta: { rowIndex },
      },
      {
        type: ORDER_SHEET_PAGE__RERENDER_STATE,
        payload: {
          submitable: true,
        },
      },
    ],
    badRequest: (content) => [
      {
        type: ORDER_SHEET_PAGE__GET_ARTICLE_DATA_FAILED,
        payload: content.body,
        meta: { rowIndex },
      },
      {
        type: ORDER_SHEET_PAGE__RERENDER_STATE,
        payload: {
          submitable: true,
        },
      },
    ],
  });
}

function loadedArticles(element) {
  return element.articleName;
}

export function getOrderSheetArticles(state) {
  return state.orderSheetPage.orderSheet.items.filter(loadedArticles);
}

function getArticleParamsForSubmit(orderSheetItem) {
  return {
    articleNumber: orderSheetItem.articleNumber
      ? orderSheetItem.articleNumber.value
      : undefined,
    quantity: orderSheetItem.quantity
      ? orderSheetItem.quantity.value
      : undefined,
    variantSelect: orderSheetItem.variantSelect
      ? orderSheetItem.variantSelect.value
      : undefined,
    freetext: orderSheetItem.variantInput
      ? orderSheetItem.variantInput.value
      : undefined,
  };
}

export function submitForm({ event, routes, link }, apiClient) {
  event.preventDefault();
  return (dispatch, getState) => {
    const orderSheetItems = getOrderSheetArticles(getState());
    const articles = [];
    orderSheetItems.forEach((item) =>
      articles.push(getArticleParamsForSubmit(item))
    );
    const articlesObject = { articles };
    if (articles.length > 0) {
      dispatch([
        { type: ORDER_SHEET_PAGE__ADD_TO_BASKET_STARTED },
        apiClient.post(link, articlesObject, {
          created: (content) => [openPage(routes, content.headers.location)],
          badRequest: (content) => [
            {
              type: ORDER_SHEET_PAGE__ADD_TO_BASKET_FAILED,
              payload: content.body,
            },
          ],
        }),
      ]);
    }
    return true;
  };
}
