import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import Accordion, { AccordionItem } from "stelar/components/Accordion";
import HtmlContent from "stelar/components/HtmlContent";
import Text from "stelar/components/Text";
import imageSizes from "shared/utils/imageSizes";
import { IMAGE_PROFILE_DEFAULTS } from "shared/utils/imageUrlBuilder";
import ElementSectionHeading from "../ElementSectionHeading";
import s from "./ElementAccordion.module.css";
import ContentSpacing from "../../ContentSpacing/ContentSpacing";
import RatioImage from "../../RatioImage";

const cx = classnames.bind(s);

function ElementAccordion({
  heading,
  items,
  fullWidth,
  lazy,
  onLinkClick,
  openedContentAccordionItems,
  closeOrOpenContentAccordionItem,
  onImageLoad,
  loadedImages,
}) {
  if (items.length <= 0) {
    return null;
  }

  const XXLandUpIndentFactor = fullWidth ? 1 : Number((1 - 2 / 12).toFixed(6));
  const LtoXXLIndentFactor = fullWidth ? 1 : Number((1 - 3 / 12).toFixed(6));

  const imgSizes = imageSizes({
    xxxl: `${1500 * XXLandUpIndentFactor}px`,
    xxl: `${100 * XXLandUpIndentFactor}vw`,
    l: `${100 * LtoXXLIndentFactor}vw`,
    default: "100vw",
  });

  return (
    <div
      className={cx("root", { fullWidth, indented: !fullWidth })}
      data-test-sell-element-accordion
    >
      {heading && (
        <ElementSectionHeading heading={heading} fullWidth={fullWidth} />
      )}
      <ContentSpacing alignment="text" flex fullWidth={fullWidth}>
        <div className={s.accordion}>
          <Accordion
            onChange={(itemToOpen) =>
              closeOrOpenContentAccordionItem(itemToOpen)
            }
          >
            {items.map(
              (accordionItem, index) =>
                accordionItem.texts &&
                accordionItem.texts[0] && (
                  <AccordionItem
                    light
                    title={
                      <div data-test-sell-accordion-item-heading>
                        <Text strong className={s.accordionItemHeading}>
                          {accordionItem.heading}
                        </Text>
                      </div>
                    }
                    open={openedContentAccordionItems[accordionItem.heading]}
                    value={accordionItem.heading}
                    key={`accordionItem-${accordionItem.heading}`}
                    data-test-sell-element-accordion-element={`option-${index}`}
                  >
                    {accordionItem.texts.map((text) => (
                      <div className={s.accordionContent} key={text.text}>
                        <div
                          className={cx("text", { withImage: !!text.image })}
                        >
                          <HtmlContent
                            dangerouslySetInnerHTML={{ __html: text.text }}
                            onClick={onLinkClick}
                          />
                        </div>
                        {text.image && (
                          <RatioImage
                            image={text.image}
                            imageProfileDefault={IMAGE_PROFILE_DEFAULTS.kurator}
                            sizes={imgSizes}
                            onImageLoad={onImageLoad}
                            hasLoaded={loadedImages[text.image.pattern]}
                            lazy={lazy}
                            className={s.image}
                          />
                        )}
                      </div>
                    ))}
                  </AccordionItem>
                )
            )}
          </Accordion>
        </div>
      </ContentSpacing>
    </div>
  );
}

ElementAccordion.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.array,
  onLinkClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  openedContentAccordionItems: PropTypes.object,
  closeOrOpenContentAccordionItem: PropTypes.func,
  onImageLoad: PropTypes.func,
  loadedImages: PropTypes.object,
  lazy: PropTypes.bool,
};

export default ElementAccordion;
