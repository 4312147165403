import classnames from "classnames/bind";
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import LazyLoad from "react-lazyload";

import { IMAGE_PROFILE_DEFAULTS } from "shared/utils/imageUrlBuilder";
import { Button } from "stelar/components/Button";
import EnergyEfficiency from "stelar/components/EnergyEfficiency";
import Paragraph from "stelar/components/Paragraph";
import Price from "stelar/components/Price";
import Text from "stelar/components/Text";

import ProductImage from "../ProductImage";
import s from "./SingleHeroArticleTile.module.css";

const cx = classnames.bind(s);

function SingleHeroArticleTile({
  title,
  description,
  article,
  image,
  imagePosition = "left",
  imgSizes,
  cta,
  onLinkClick,
  fullWidth,
  lazy,
}) {
  if (!article) {
    return null;
  }

  const { name, link, price, netWeight, energyEfficiency } = article;
  const normalProductImage = (
    <ProductImage
      image={image}
      imageProfileDefault={IMAGE_PROFILE_DEFAULTS.pdsmain}
      sizes={imgSizes}
      data-test-sell-product-image
    />
  );
  const minimalProductImage = (
    <ProductImage
      image={image}
      imageProfileDefault={IMAGE_PROFILE_DEFAULTS.pdsmain}
      preview
      data-test-sell-product-image
    />
  );

  return (
    <div
      className={cx("root", {
        fullWidth,
        indented: !fullWidth,
        [imagePosition]: true,
      })}
    >
      <a href={link} onClick={onLinkClick} className={s.imageSection}>
        {lazy ? (
          <LazyLoad placeholder={minimalProductImage} once offset={1000}>
            {normalProductImage}
          </LazyLoad>
        ) : (
          normalProductImage
        )}
      </a>
      <div className={s.textSection}>
        <div className={s.title}>
          {title && (
            <Text em tag="h2">
              {title}
            </Text>
          )}
          <Text normal tag="h3">
            <a href={link} data-prio2>
              {name}
            </a>
          </Text>
        </div>
        {description && (
          <div className={s.text}>
            <Paragraph>{description}</Paragraph>
          </div>
        )}
        {price ? (
          <div className={s.wrapper}>
            <Price
              price={price.amount}
              oldPrice={price.old}
              unit={price.base}
              additionalInfos={[netWeight, price.pfand]}
            />
            {energyEfficiency ? (
              <div className={s.eelContainer}>
                <EnergyEfficiency variant="compact" {...energyEfficiency} />
              </div>
            ) : null}
          </div>
        ) : null}
        {cta && cta.text && (
          <div className={s.button}>
            <Button prio2 text={cta.text} href={link} onClick={onLinkClick}>
              {cta.text}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

SingleHeroArticleTile.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  article: PropTypes.object,
  image: PropTypes.object,
  imagePosition: PropTypes.oneOf(["left", "right"]),
  cta: PropTypes.object,
  imgSizes: PropTypes.string,
  onLinkClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  lazy: PropTypes.bool,
};

export default SingleHeroArticleTile;
