import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import s from "./StripeColumnWrapper.module.css";

const cx = classnames.bind(s);

function StripeColumnWrapper({
  children,
  noBorders,
  lastNoBorders,
  forcedHalved,
  forcedOneThirdTwoThird,
}) {
  const amountChildren = React.Children.count(children);
  return (
    <div
      className={cx("root", { borders: !noBorders, noBorders, lastNoBorders })}
    >
      {React.Children.map(children, (child) => {
        if (!child) {
          return null;
        }

        return (
          <div
            className={cx(
              "element",
              `elements_${amountChildren}`,
              { forcedHalved },
              { forcedOneThirdTwoThird }
            )}
          >
            {child}
          </div>
        );
      })}
    </div>
  );
}

StripeColumnWrapper.propTypes = {
  children: PropTypes.node,
  noBorders: PropTypes.bool,
  lastNoBorders: PropTypes.bool,
  forcedHalved: PropTypes.bool,
  forcedOneThirdTwoThird: PropTypes.bool,
};

StripeColumnWrapper.defaultProps = {};

export default StripeColumnWrapper;
