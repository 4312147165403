import update from "immutability-helper";
import { validateCheckbox, validateInput } from "../utils/Validators";

function hasSpeditionArticle(state) {
  return state.furnitureDelivery;
}

export function validateForOrderSubmission(state) {
  let newState = state;
  newState = update(newState, { submitable: { $set: true } });
  if (hasSpeditionArticle(state)) {
    newState = validateInput(newState, {
      inputPath: "furnitureDelivery.phoneNumber",
    });
  }
  newState = validateCheckbox(newState, { checkboxPath: "agb" });
  return newState;
}
