const STORAGE_KEY = "mf-info-panel";
const panelOpenParameter = /[?&]ichwill=neuland/;
const panelCloseParameter = /[?&]reicht=mir/;

function enable() {
  try {
    window.localStorage[STORAGE_KEY] = "true";
  } catch {
    /* failed to set */
  }
}

function disable() {
  delete window.localStorage[STORAGE_KEY];
}

function isEnabled() {
  try {
    return window.localStorage[STORAGE_KEY] === "true";
  } catch {
    return false;
  }
}

export default function debugPanel() {
  const currentUrl = document.location.href;
  if (currentUrl.match(panelOpenParameter)) {
    enable();
  }

  if (currentUrl.match(panelCloseParameter)) {
    disable();
  }

  if (isEnabled()) {
    import(/* webpackChunkName: "debugPanel" */ "./debugPanel").then(
      (panel) => {
        panel.show();
      }
    );
  }
}
