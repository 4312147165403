// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import Form from "stelar/components/Form";
import FormRow from "stelar/components/FormRow";
import Heading from "stelar/components/Heading";
import Message from "stelar/components/Message";
import Paragraph from "stelar/components/Paragraph";
import { Input } from "stelar/components/Input";
import { ButtonLoading } from "stelar/components/Button";
import IconFaq from "stelar/components/Icon/IconFaq";
import Modal from "stelar/components/Modal";
// component
import s from "./GutscheinWertabfrageFragment.module.css";

function GutscheinWertabfrageFragment({
  description,
  doSubmitForm,
  doUpdateInput,
  heading,
  message,
  number,
  pin,
  submitButton,
  submitStarted,
  infoModal,
}) {
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleClickInfoButton = () => {
    setModalOpen(true);
  };

  const handleOnCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <aside className={s.root}>
      {heading && (
        <Heading className={s.center} prio2>
          {heading}
        </Heading>
      )}
      <Paragraph className={s.center}>{description}</Paragraph>
      {message && <Message {...message} />}
      <Form
        action={submitButton.link}
        layout="standard"
        method="post"
        noValidate
        onSubmit={(event) =>
          doSubmitForm({
            event,
            url: submitButton.link,
            values: {
              number: number.value,
              pin: pin.value,
            },
          })
        }
      >
        <span className={s.formCellOverride}>
          <FormRow
            label={{
              error: number.error,
              for: "gutscheinWertabfrageNumber",
              text: number.label,
            }}
            labelSuffix={
              !!infoModal && (
                /* TODO a11y */
                /* eslint-disable-next-line jsx-a11y/control-has-associated-label */
                <button type="button" onClick={handleClickInfoButton}>
                  <IconFaq large />
                </button>
              )
            }
          >
            <Input
              data-test-ase-field-input="number"
              id="gutscheinWertabfrageNumber"
              name="number"
              onChange={doUpdateInput}
              value={number.value}
            />
          </FormRow>
        </span>
        <FormRow
          label={{
            error: pin.error,
            for: "gutscheinWertabfragePin",
            text: pin.label,
          }}
        >
          <Input
            data-test-ase-field-input="pin"
            id="gutscheinWertabfragePin"
            name="pin"
            onChange={doUpdateInput}
            value={pin.value}
          />
        </FormRow>
        <FormRow>
          <ButtonLoading
            data-test-ase-prev-page
            text={submitButton.label || ""}
            type="submit"
            loading={submitStarted}
          />
        </FormRow>
      </Form>
      {!!infoModal && (
        <div className={s.modalOverride}>
          <Modal
            opened={modalOpen}
            onClose={handleOnCloseModal}
            title={infoModal.heading}
          >
            <div style={{ whiteSpace: "pre-line" }}>{infoModal.text}</div>
            {infoModal.imgSrc && (
              <img
                src={infoModal.imgSrc}
                alt={infoModal.imgAlt}
                style={{ width: "100%", marginTop: "1rem" }}
              />
            )}
          </Modal>
        </div>
      )}
    </aside>
  );
}

GutscheinWertabfrageFragment.propTypes = {
  description: PropTypes.string,
  doSubmitForm: PropTypes.func.isRequired,
  doUpdateInput: PropTypes.func.isRequired,
  heading: PropTypes.string,
  message: PropTypes.object,
  number: PropTypes.object,
  pin: PropTypes.object,
  submitButton: PropTypes.object.isRequired,
  submitStarted: PropTypes.bool,
  infoModal: PropTypes.object,
};

export default GutscheinWertabfrageFragment;
