import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import imageSizes from "shared/utils/imageSizes";
import { IMAGE_PROFILE_DEFAULTS } from "shared/utils/imageUrlBuilder";
import s from "./ElementImage.module.css";
import RatioImage from "../../RatioImage";

const cx = classnames.bind(s);

function ElementImage({
  image = {},
  breakpointImages,
  onImageLoad,
  loadedImages = {},
  fullWidth,
  lazy,
}) {
  const XXLandUpIndentFactor = fullWidth ? 1 : Number((1 - 2 / 12).toFixed(6));
  const LtoXXLIndentFactor = fullWidth ? 1 : Number((1 - 3 / 12).toFixed(6));

  const imgSizes = imageSizes({
    xxxl: `${1500 * XXLandUpIndentFactor}px`,
    xxl: `${100 * XXLandUpIndentFactor}vw`,
    l: `${100 * LtoXXLIndentFactor}vw`,
    default: "100vw",
  });

  return (
    <div
      className={cx({ fullWidth, indented: !fullWidth })}
      data-test-sell-element-image
    >
      <RatioImage
        image={image}
        imageProfileDefault={IMAGE_PROFILE_DEFAULTS.kurator}
        breakpointImages={breakpointImages}
        sizes={imgSizes}
        onImageLoad={onImageLoad}
        hasLoaded={loadedImages[image.pattern]}
        lazy={lazy}
      />
    </div>
  );
}

ElementImage.propTypes = {
  image: PropTypes.object,
  breakpointImages: PropTypes.array,
  onImageLoad: PropTypes.func,
  loadedImages: PropTypes.object,
  fullWidth: PropTypes.bool,
  lazy: PropTypes.bool,
};

export default ElementImage;
