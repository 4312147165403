import React from "react";
import classNames from "classnames/bind";
import s from "./Button.module.css";
import s2 from "./ButtonNext.module.css";
import IconRight from "../Icon/IconRight";
import testability from "../testability";

type Props = {
  disabled?: boolean;
  href?: string;
  large?: boolean;
  onClick?: (...args: unknown[]) => unknown;
  prio1?: boolean;
  prio2?: boolean;
  prio3?: boolean;
  prio4?: boolean;
  prio5?: boolean;
  inverted?: boolean;
  text?: string;
  skeleton?: boolean;
};

function ButtonNext({
  disabled,
  href,
  large,
  onClick,
  prio1,
  prio2,
  prio3,
  prio4,
  prio5,
  inverted,
  text,
  skeleton,
  ...dataTest
}: Props) {
  const cx = classNames.bind(s);
  const cx2 = classNames.bind(s2);
  const classnames = cx("root", {
    disabled,
    large,
    prio1: prio1 || !(prio2 || prio3 || prio4 || prio5),
    prio2,
    prio3,
    prio4,
    prio5,
    inverted,
    skeleton,
    regular: !inverted,
  });

  const htmlHref = disabled ? null : href;

  let buttoncontent = (
    <span className={cx(cx("content"), cx2({ content: !prio4 }))}>
      <span className={s2.text}>{text}</span>{" "}
      <span className={s2.icon}>
        <IconRight small />
      </span>
    </span>
  );

  buttoncontent = !skeleton ? (
    buttoncontent
  ) : (
    <span className={s.skeleton}>{buttoncontent}</span>
  );

  let htmlTag = "button";
  if (href) {
    htmlTag = disabled ? "span" : "a";
  }

  return React.createElement(
    htmlTag,
    {
      ...testability(dataTest),
      className: classnames,
      disabled,
      href: htmlHref,
      onClick,
      role: href ? "button" : null,
    },
    buttoncontent
  );
}

ButtonNext.defaultProps = {
  disabled: false,
  href: null,
  large: false,
  prio1: false,
  prio2: false,
  prio3: false,
  prio4: false,
  prio5: false,
  text: "Weiter",
};

export default ButtonNext;
