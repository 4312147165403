import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import Link from "../Link";
import List from "../List";
import Headline from "../Headline";
import ListElement from "../ListElement";
import PaymentList from "../PaymentList";

function ServiceLinks({ payment }) {
  function handleClick(evt) {
    if (window.location.href === evt.target.href)
      window.location = evt.target.href;
  }
  return (
    <div>
      <Headline>{payment.headline}</Headline>
      <List>
        <ListElement>
          <Link href={payment.deliveryCost.link} onClick={handleClick}>
            {payment.deliveryCost.name}
          </Link>
        </ListElement>
        <ListElement>
          <Link href={payment.delivery.link} onClick={handleClick}>
            {payment.delivery.name}
          </Link>
        </ListElement>
      </List>
      <PaymentList payment={payment} />
    </div>
  );
}

ServiceLinks.propTypes = {
  payment: PropTypes.object.isRequired,
};

ServiceLinks.defaultProps = {};

export default ServiceLinks;
