/** 
  Builds a link of a string containing markdown
  This [is](/an){example="foo"} -> This <a href="/an" example="foo">is</a>
  The RegEx only matches if there's a "/" included in the second capture group
  It is able to capture multiple occurences in one string.
  Replaces newlines with br-Tags.
*/
export default (markdownText) => {
  const regex = /\[([^\]]*)\]\((\/[^\)]*)\)(\{([^\}]*)\})*/g; // eslint-disable-line no-useless-escape
  return markdownText
    ? markdownText
        .replace(
          regex,
          '<a data-test-ase-overlay-link="true" href="$2" $4>$1</a>'
        )
        .replace(/\n/g, "<br />")
    : "";
};
