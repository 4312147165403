import React from "react";
import Input from "./Input";

type Props = {
  autoComplete?: string;
  error?: boolean | string;
} & React.ComponentPropsWithoutRef<typeof Input>;

/**
 * InputZip
 */
function InputZip({
  autoComplete = "postal-code",
  error = "",
  ...attrs
}: Props) {
  return (
    <Input
      autoComplete={autoComplete}
      className="zip"
      error={error}
      maxLength={10} // Quelle: https://de.wikipedia.org/wiki/Postleitzahl
      size={5}
      type="text"
      {...attrs}
    />
  );
}

export default InputZip;
