import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import imageSizes from "shared/utils/imageSizes";
import ElementSectionHeading from "../ElementSectionHeading";
import s from "./ElementHeroList.module.css";
import HeroArticleTile from "../../HeroArticleTile";
import ContentSpacing from "../../ContentSpacing/ContentSpacing";

const cx = classnames.bind(s);

const ElementHeroList = ({
  heading,
  heroArticles,
  fullWidth,
  lazy,
  onLinkClick,
}) => {
  const hasArticles = heroArticles.find(
    (heroArt) => heroArt.article && heroArt.article.length >= 1
  );
  if (!hasArticles) {
    return null;
  }

  const imgSizes = imageSizes({
    xxxl: "480px",
    xxl: "33vw",
    xl: fullWidth ? "33vw" : "28vw",
    l: fullWidth ? "41vw" : "37.5vw",
    m: "50vw",
    default: "100vw",
  });

  return (
    <div
      className={cx({ fullWidth, indented: !fullWidth })}
      data-test-sell-element-hero-list
    >
      {heading && (
        <ElementSectionHeading heading={heading} fullWidth={fullWidth} />
      )}
      <ContentSpacing alignment="columns" flex fullWidth={fullWidth}>
        {heroArticles.map(
          (heroArticle) =>
            heroArticle.article &&
            heroArticle.article[0] && (
              <div className={s.article} key={heroArticle.article[0].productId}>
                <HeroArticleTile
                  {...heroArticle}
                  imgSizes={imgSizes}
                  onLinkClick={onLinkClick}
                  lazy={lazy}
                />
              </div>
            )
        )}
      </ContentSpacing>
    </div>
  );
};

ElementHeroList.propTypes = {
  heading: PropTypes.string,
  heroArticles: PropTypes.array,
  fullWidth: PropTypes.bool,
  lazy: PropTypes.bool,
  onLinkClick: PropTypes.func,
};

export default ElementHeroList;
