// vendor
import _get from "lodash.get";
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import Heading from "stelar/components/Heading";
import Text from "stelar/components/Text";
// ase
import InfoBox from "./InfoBox";
import Product from "../../../components/Product";
import s from "./OrderHistoryDetailsFragment.module.css";
import SummaryTable from "../../../components/SummaryTable";

const OrderHistoryDetailsFragment = ({ isLoading, orderDetails, title }) => {
  const {
    bill,
    deliveryDetails,
    lineItems,
    orderNumber,
    orderNumberLabel,
    redemption,
  } = orderDetails;

  const { deliveryMethod, paymentMethod } = deliveryDetails || {};

  const billingAddress = _get(deliveryDetails, "billingAddress.address");
  const billingAddressElement =
    billingAddress &&
    billingAddress.map((addressLine) => (
      <Text key={addressLine} normal skeleton={isLoading}>
        {addressLine}
        <br />
      </Text>
    ));

  const deliveryAddress = _get(deliveryDetails, "deliveryAddress.address");
  const deliveryAddressElement =
    deliveryAddress &&
    deliveryAddress.map((addressLine) => (
      <Text key={addressLine} normal skeleton={isLoading}>
        {addressLine}
        <br />
      </Text>
    ));

  const deliveryMethodInfoBox = deliveryMethod && deliveryMethod.value && (
    <InfoBox heading={deliveryMethod.label} isLoading={isLoading}>
      <Text normal skeleton={isLoading}>
        {deliveryMethod.value}
      </Text>
    </InfoBox>
  );

  const paymentMethodInfoBox = paymentMethod && paymentMethod.value && (
    <InfoBox heading={paymentMethod.label} isLoading={isLoading}>
      <Text normal skeleton={isLoading}>
        {paymentMethod.value}
      </Text>
    </InfoBox>
  );

  if (redemption && redemption.giftCards) {
    redemption.giftCards = []; // don't show actual redemptions
  }

  const redemptionInfoBox =
    redemption && redemption.giftCards ? (
      <InfoBox heading={redemption.label} isLoading={isLoading}>
        {redemption.giftCards.map((giftCard) => (
          <div key={giftCard.number}>
            <Text normal skeleton={isLoading}>
              {giftCard.number}
            </Text>{" "}
            <Text skeleton={isLoading} light>
              {redemption.delimiter}
            </Text>{" "}
            <Text normal skeleton={isLoading}>
              {giftCard.value}
            </Text>
          </div>
        ))}
      </InfoBox>
    ) : null;

  const deliveryDetailsInfoBox = deliveryDetails && (
    <div>
      <Heading prio2 skeleton={isLoading} tag="h3">
        {deliveryDetails.heading}
      </Heading>
      <InfoBox
        heading={deliveryDetails.billingAddress.label}
        isLoading={isLoading}
      >
        {billingAddressElement}
      </InfoBox>
      <InfoBox
        heading={deliveryDetails.deliveryAddress.label}
        isLoading={isLoading}
      >
        {deliveryAddressElement}
      </InfoBox>
    </div>
  );

  return (
    <div className={s.root}>
      <Heading prio1 skeleton={isLoading} tag="h2">
        {title}
        <br />
        <Text small skeleton={isLoading}>
          {orderNumberLabel} {orderNumber}
        </Text>
      </Heading>
      {deliveryDetailsInfoBox}
      {deliveryMethodInfoBox}
      {paymentMethodInfoBox}
      {redemptionInfoBox}
      <ul className={s.products}>
        {lineItems.map((lineItem, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li className={s.product} key={`${lineItem.articleNumber}${index}`}>
            <Product {...lineItem} isLoading={isLoading} />
          </li>
        ))}
      </ul>
      <div className={s.bill}>
        <SummaryTable
          isLoading={isLoading}
          items={bill.lines}
          totalLabel={bill.total.label}
          totalValue={bill.total.value}
          vatLabel={bill.vatLabel}
        />
      </div>
    </div>
  );
};

OrderHistoryDetailsFragment.propTypes = {
  isLoading: PropTypes.bool,
  orderDetails: PropTypes.object,
  title: PropTypes.string.isRequired,
};

export const attrs = ["layout", "orderKey"];
export const stateKey = "ASE_INITIAL_STATE_ORDER_HISTORY_DETAILS_FRAGMENT";
export default OrderHistoryDetailsFragment;
