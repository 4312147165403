import {
  KEYBOARD_ARROW_UP,
  KEYBOARD_ARROW_DOWN,
} from "shared/utils/keyboardNavigation";

export default function getFocus(direction, listLength, currentFocus) {
  if (direction === KEYBOARD_ARROW_DOWN && listLength > 0) {
    if (currentFocus == null) {
      return 0;
    }
    if (currentFocus >= listLength - 1) {
      return listLength - 1;
    }
    return currentFocus + 1;
  }
  if (direction === KEYBOARD_ARROW_UP) {
    if (currentFocus <= 0) {
      return null;
    }
    return currentFocus - 1;
  }
  return null;
}
