import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import jsesc from "jsesc";
import { getBasketCount, getInfo, getWishlistCount } from "../actions";
import useIsClient from "../../utils/useIsClient";
import useIsFirstRender from "../../utils/useIsFirstRender";

const inlineScriptSetup = `
if (typeof OGWebBridge === "undefined") {
  OGWebBridge = {update: function(...params) {
    console.log(\`[Debug] Call: OGWebBridge.update(\${params.map(param=>\`'\${param}'\`).join(", ")})\`);
  }};
}`;

type WebBridgeProps = {
  basketCount: string;
  wishlistCount: string;
  title: string;
  sharing: string;
  info: { userinfo: object; settings: object };
};

type Props = {
  apiClient?: object;
  dispatch?: (...args: unknown[]) => unknown;
  webBridgeProps?: WebBridgeProps;
};

function WebBridgeConnector({ apiClient, dispatch, webBridgeProps }: Props) {
  const isClient = useIsClient();
  const isFirstRender = useIsFirstRender();

  const { basketCount, wishlistCount, title, sharing, info } = webBridgeProps;

  React.useEffect(() => {
    const updateBasketCount = () =>
      getBasketCount(apiClient, dispatch, basketCount);
    const updateWishlistCount = () =>
      getWishlistCount(apiClient, dispatch, wishlistCount);
    const updateInfo = () => getInfo(apiClient, dispatch, info);

    const globalEvents = {
      "ase:lineitemeditfragment:submit": () => updateBasketCount(),
      "ase:basket:changed": () => {
        updateBasketCount();
      },
      "ase:wishlist:changed": () => {
        updateWishlistCount();
      },
      "ase:login:changed": () => {
        updateInfo();
      },
      "sell:cookieBanner:consentchanged": () => {
        updateInfo();
      },
    };

    Object.entries(globalEvents).forEach(([eventKey, eventCalls]) =>
      window.addEventListener(eventKey, eventCalls)
    );

    return () => {
      Object.entries(globalEvents).forEach(([eventKey, eventCalls]) =>
        window.removeEventListener(eventKey, eventCalls)
      );
    };
  }, []);

  const globalWebBridgeCalls = {
    basket: basketCount,
    wishlist: wishlistCount,
    login: !!info?.userinfo,
    userInfo: info?.userinfo,
    settings: info?.settings,
    title,
    sharing,
  };

  Object.entries(globalWebBridgeCalls).forEach(([callKey, value]) => {
    React.useEffect(() => {
      if (isFirstRender || value === undefined || value === null) {
        return;
      }
      let jsonEncodedValue;
      if (typeof value === "object") {
        jsonEncodedValue = JSON.stringify(value);
      }
      // eslint-disable-next-line no-undef
      // @ts-expect-error
      if (OGWebBridge) {
        // @ts-expect-error
        OGWebBridge.update(callKey, `${jsonEncodedValue || value}`);
      }
    }, [value]);
  });

  if (isClient) {
    return null;
  }

  const ssrCalls = Object.entries(globalWebBridgeCalls).reduce(
    (prev, [callKey, value]) => {
      if (value !== undefined && value !== null) {
        let jsonEncodedValue;
        if (typeof value === "object") {
          jsonEncodedValue = jsesc(JSON.stringify(value), {
            quotes: "single",
          });
        }
        return `${prev}OGWebBridge.update('${callKey}', '${
          jsonEncodedValue || value
        }');\n`;
      }
      return prev;
    },
    ""
  );

  return (
    <Helmet>
      <script data-dynamic>{`${inlineScriptSetup}\n${ssrCalls}`}</script>
    </Helmet>
  );
}

const mapStateToProps = (state) => ({
  webBridgeProps: state.webbridge,
});

export default connect(mapStateToProps)(WebBridgeConnector);
