import {
  RESET_FACET_FILTER_DISPLAY,
  TOGGLE_FACET_FILTER_DISPLAY,
  UI_STATE_CLOSE_COUNTRY_SELECTION,
  UI_STATE_CLOSE_OFFCANVAS,
  UI_STATE_CLOSE_OR_OPEN_CONTENT_ACCORDION_ITEM,
  UI_STATE_CLOSE_OR_OPEN_FACET_FILTER,
  UI_STATE_IMAGE_LOADED,
  UI_STATE_OPEN_OFFCANVAS,
  UI_STATE_READY,
  UI_STATE_TOGGLE_COUNTRY_SELECTION,
  UI_STATE_SET_TRACKING_LIST_TYPE,
  UI_STATE_SET_TRACKING_LIST_TYPE_UNDEFINED,
  UI_STATE_REMOVE_TRACKING_LIST_TYPE,
  UI_STATE_RESET_FACET_FILTER,
  UI_STATE_OPEN_LANGUAGE_SELECTION_OFFCANVAS,
  UI_STATE_CLOSE_LANGUAGE_SELECTION_OFFCANVAS,
} from "../../pages/constants";

export function openOffCanvas() {
  return { type: UI_STATE_OPEN_OFFCANVAS };
}

export function closeOffCanvas() {
  return { type: UI_STATE_CLOSE_OFFCANVAS };
}

export function openLanguageSelectionOffCanvas() {
  return { type: UI_STATE_OPEN_LANGUAGE_SELECTION_OFFCANVAS };
}

export function closeLanguageSelectionOffCanvas() {
  return { type: UI_STATE_CLOSE_LANGUAGE_SELECTION_OFFCANVAS };
}

export function imageLoaded(src) {
  return { type: UI_STATE_IMAGE_LOADED, src };
}

export function toggleCountrySelection() {
  return { type: UI_STATE_TOGGLE_COUNTRY_SELECTION };
}

export function closeCountrySelection() {
  return { type: UI_STATE_CLOSE_COUNTRY_SELECTION };
}

export function ready() {
  return { type: UI_STATE_READY };
}

export function toggleFacetFilterDisplay() {
  return {
    type: TOGGLE_FACET_FILTER_DISPLAY,
  };
}

export function resetFacetFilterDisplay() {
  return {
    type: RESET_FACET_FILTER_DISPLAY,
  };
}

export function closeOrOpenFacetFilter(facetName) {
  return {
    type: UI_STATE_CLOSE_OR_OPEN_FACET_FILTER,
    facetName,
  };
}

export function resetFacetFilter() {
  return { type: UI_STATE_RESET_FACET_FILTER };
}

export function closeOrOpenContentAccordionItem(contentAccordionItem) {
  return {
    type: UI_STATE_CLOSE_OR_OPEN_CONTENT_ACCORDION_ITEM,
    contentAccordionItem,
  };
}

export function setTrackingListType(data) {
  if (
    data &&
    data.googleEnhancedEcommerce &&
    data.googleEnhancedEcommerce.payload &&
    data.googleEnhancedEcommerce.payload.ecommerce &&
    data.googleEnhancedEcommerce.payload.ecommerce.click &&
    data.googleEnhancedEcommerce.payload.ecommerce.click.actionField
  ) {
    const {
      googleEnhancedEcommerce: {
        payload: {
          ecommerce: {
            click: {
              actionField: { list },
            },
          },
        },
      },
    } = data;
    if (list) {
      return {
        type: UI_STATE_SET_TRACKING_LIST_TYPE,
        listType: list,
      };
    }
  }
  return {
    type: UI_STATE_SET_TRACKING_LIST_TYPE_UNDEFINED,
  };
}

export function resetTrackingListType() {
  return {
    type: UI_STATE_REMOVE_TRACKING_LIST_TYPE,
  };
}
