import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import CountryFlag from "stelar/components/CountryFlag";
import s from "./CountryOption.module.css";

/**
 * FooterCountryOption
 */
const CountryOption = ({ link, name, iconName, onClick }) => (
  <li className={s.element}>
    <a href={link} onClick={onClick} className={s.countryLink}>
      {iconName !== "mag" ? (
        <span className={s.flagIconContainer}>
          <CountryFlag country={iconName} />
        </span>
      ) : (
        <div />
      )}
      <span className={s.listedCountryName}>{name}</span>
    </a>
  </li>
);

CountryOption.propTypes = {
  link: PropTypes.string,
  name: PropTypes.string,
  iconName: PropTypes.string,
  onClick: PropTypes.func,
};

CountryOption.defaultProps = {};

export default CountryOption;
