import loadable from "@loadable/component";
import { connect } from "react-redux";
import { provideHooks } from "redial";

import Loading from "shared/components/Loading";

import loadReducedLayout from "../../components/ReducedLayout/actions";
import frontendUrls from "../utils/AseFrontendUrls";
import { loadNoScriptPage } from "./actions";

const redial = {
  fetch: ({ apiClient, dispatch, getState }) =>
    Promise.all([
      dispatch(loadNoScriptPage({ apiClient })),
      dispatch(loadReducedLayout(apiClient, getState)),
    ]),
};

const mapStateToProps = (state) => ({
  page: state.noScriptPage.page,
  reducedLayout: state.reducedLayout,
});

const LoadableNoScriptPage = loadable(
  () =>
    import(/* webpackChunkName: "noscript-page" */ "./components/NoScriptPage"),
  {
    fallback: Loading,
  }
);

export default {
  component: provideHooks(redial)(
    connect(mapStateToProps)(LoadableNoScriptPage)
  ),
  path: frontendUrls.NO_SCRIPT,
};
