import loadable from "@loadable/component";
import { connect } from "react-redux";
import { provideHooks } from "redial";

import Loading from "shared/components/Loading";

import frontendUrls from "../utils/AseFrontendUrls";
import {
  changeEmail,
  doRequestUnscribeNewsletter,
  loadNewsletterPage,
  validateInput,
} from "./actions";

const redial = {
  fetch: ({ apiClient, dispatch, query }) =>
    dispatch(loadNewsletterPage(apiClient, query)),
};

const mapDispatchToProps = (dispatch, props) => ({
  doRequestEmail: (args) =>
    dispatch(doRequestUnscribeNewsletter(args, props.apiClient)),
  validateInput: (inputPath, errorPath) =>
    dispatch(validateInput(inputPath, errorPath)),
  changeEmail: (event) =>
    dispatch(changeEmail(event.target.value, props.apiClient)),
});

const mapStateToProps = (state) => ({
  attentionMessage: state.newsletterUnsubscribePage.attentionMessage,
  buttonNextLabel: state.newsletterUnsubscribePage.buttonNextLabel,
  email: state.newsletterUnsubscribePage.email,
  headline: state.newsletterUnsubscribePage.headline,
  page: state.newsletterUnsubscribePage.page,
  pageDescription: state.newsletterUnsubscribePage.pageDescription,
  successMessage: state.newsletterUnsubscribePage.successMessage,
  contentRows: state.newsletterUnsubscribePage.contentRows,
});

const LoadableNewsletterUnsubscribePage = loadable(
  () =>
    import(
      /* webpackChunkName: "newsletterunsubscribe-page" */ "./components/NewsletterUnsubscribePage"
    ),
  {
    fallback: Loading,
  }
);

export default {
  component: provideHooks(redial)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(LoadableNewsletterUnsubscribePage)
  ),
  path: frontendUrls.NEWSLETTER_CANCEL_SUBSCRIPTION,
};
