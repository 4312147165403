// vendor
import update from "immutability-helper";
// ase
import {
  LOAD_NEWSLETTER_SUBSCRIPTION_FRAGMENT_SUCCESS,
  NEWSLETTER_SUBSCRIPTION_FRAGMENT__CHANGE_CHECKBOX,
  NEWSLETTER_SUBSCRIPTION_FRAGMENT__CHANGE_INPUT,
  NEWSLETTER_SUBSCRIPTION_FRAGMENT__SUBSCRIBE,
  NEWSLETTER_SUBSCRIPTION_FRAGMENT__SUBSCRIBE_FAILED,
  NEWSLETTER_SUBSCRIPTION_FRAGMENT__VALIDATE,
} from "../../pages/constants";
import { validateDatenschutz, validateEmail } from "./validator";

const initialState = {
  newsletterSubscription: {
    page: {
      headline: "",
      submitButton: {},
    },
    datenschutz: { label: "" },
    email: {},
  },
};

function newsletterSubscriptionFragment(state = initialState, action) {
  let newState;
  switch (action.type) {
    case NEWSLETTER_SUBSCRIPTION_FRAGMENT__VALIDATE:
      newState = update(state, { submitable: { $set: true } });
      newState = validateDatenschutz(newState);
      return validateEmail(newState);
    case LOAD_NEWSLETTER_SUBSCRIPTION_FRAGMENT_SUCCESS:
      return update(state, {
        newsletterSubscription: { $set: action.payload },
      });
    case NEWSLETTER_SUBSCRIPTION_FRAGMENT__SUBSCRIBE:
      return update(state, {
        newsletterSubscription: {
          email: { $set: "" },
          datenschutz: { $set: "" },
        },
        successMessage: { $set: action.payload.successMessage },
      });
    case NEWSLETTER_SUBSCRIPTION_FRAGMENT__SUBSCRIBE_FAILED:
      return update(state, {
        newsletterSubscription: { $set: action.payload },
      });
    case NEWSLETTER_SUBSCRIPTION_FRAGMENT__CHANGE_INPUT:
      return update(state, {
        newsletterSubscription: {
          email: {
            value: { $set: action.value },
          },
        },
      });
    case NEWSLETTER_SUBSCRIPTION_FRAGMENT__CHANGE_CHECKBOX:
      return update(state, {
        newsletterSubscription: {
          datenschutz: {
            checked: { $set: action.value },
          },
        },
      });
    default:
      return state;
  }
}

export default newsletterSubscriptionFragment;
