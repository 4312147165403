import track from "shared/tracking";
import { parseActionKey } from "shared/utils/actionKey";
import { HYBRID_RECO_TEASER, RECOMMENDATIONS } from "./constants";

function matchProductTile(productObject, identifier) {
  const isProductObject =
    productObject &&
    productObject.name &&
    productObject.image &&
    productObject.link &&
    typeof productObject.link === "string" &&
    productObject.link.includes(identifier);

  return isProductObject;
}

export function findExistingProduct(productList, identifier) {
  let result = null;
  productList.some((product) => {
    if (product && matchProductTile(product, identifier)) {
      result = product;
      return true;
    }
    return false;
  });
  return result;
}

export function sameProductId(keyA, keyB) {
  const firstArticle = parseActionKey(keyA);
  const identifierForFirstArticle =
    `${firstArticle.slug}-a${firstArticle.params.a}` || firstArticle.slug;

  const secondArticle = parseActionKey(keyB);
  const identifierForSecondArticle =
    `${secondArticle.slug}-a${secondArticle.params.a}` || secondArticle.slug;
  return identifierForFirstArticle === identifierForSecondArticle;
}

export function setRedirectedUrl(headers) {
  return typeof headers !== "undefined" &&
    typeof headers.referer !== "undefined"
    ? headers.referer
    : null;
}

export function setCategoryContext(stripes) {
  let categoryContext = null;
  if (stripes) {
    stripes.forEach((item) => {
      if (!categoryContext) {
        if (item.type === HYBRID_RECO_TEASER || item.type === RECOMMENDATIONS) {
          categoryContext = item?.stripeRecoDTO?.categoryContext;
        }
      }
    });
  }
  return categoryContext;
}

export function trackMicroConversion() {
  let productPageViews = sessionStorage.getItem("ppViews");

  if (productPageViews >= 1) {
    productPageViews++; // eslint-disable-line no-plusplus
    sessionStorage.setItem("ppViews", productPageViews);

    // ToDo: Please transfer data structure like googleEnhancedEcommerce/googleTagManager to BE
    const microConversion = {
      adwordsData: {
        gtmId: window.gtmId,
        dataLayer: [
          {
            event: "pds-ads-conversion",
            send_to: "AW-1000483898/GUSDCOuuhIAYELrYiN0D",
          },
        ],
      },
    };
    track(microConversion);
  } else {
    let productPageViews = 1; // eslint-disable-line no-shadow,prefer-const
    sessionStorage.setItem("ppViews", productPageViews);
  }
}
