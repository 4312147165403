/* eslint-disable no-console */
// ase
import backendUrl from "../../pages/utils/AseBackendUrls";
import {
  EMAIL_CHANGE_FRAGMENT__LOAD_DATA,
  SESSION_EXPIRED__LOAD_DATA,
  SESSION_EXPIRED__LOAD_DATA_SUCCESSFUL,
} from "../../pages/constants";

export function loadSessionExpiredData(apiClient) {
  return apiClient.get(backendUrl.SESSION_EXPIRED, {
    ok: (content) => [
      {
        type: SESSION_EXPIRED__LOAD_DATA_SUCCESSFUL,
        payload: content.body,
      },
    ],
  });
}

export function loadEmailChangeFragmentData(apiClient) {
  return apiClient.get(backendUrl.EMAIL_CHANGE, {
    ok: (content) => [
      {
        type: EMAIL_CHANGE_FRAGMENT__LOAD_DATA,
        payload: content.body,
      },
    ],
    forbidden: () => [
      loadSessionExpiredData(apiClient),
      {
        type: SESSION_EXPIRED__LOAD_DATA,
      },
    ],
    unauthorized: () => [
      loadSessionExpiredData(apiClient),
      {
        type: SESSION_EXPIRED__LOAD_DATA,
      },
    ],
  });
}

function loadSessionExpired(apiClient) {
  return [
    {
      type: SESSION_EXPIRED__LOAD_DATA,
    },
    loadSessionExpiredData(apiClient),
  ];
}

export function submitChangeEmail(values, apiClient) {
  return [
    apiClient.post(backendUrl.EMAIL_CHANGE, values, {
      ok: (content) => [
        {
          type: EMAIL_CHANGE_FRAGMENT__LOAD_DATA,
          payload: content.body,
        },
      ],
      badRequest: (content) => [
        {
          type: EMAIL_CHANGE_FRAGMENT__LOAD_DATA,
          payload: content.body,
        },
      ],
      forbidden: () => [loadSessionExpired(apiClient)],
      unauthorized: () => [loadSessionExpired(apiClient)],
    }),
  ];
}
