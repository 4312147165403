/* eslint-disable jsx-a11y/label-has-associated-control */
// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import Heading from "stelar/components/Heading";
import Paragraph from "stelar/components/Paragraph";
// component
import AddressAlternatives from "./AddressAlternatives";

const AddressSuggestions = ({
  addressSuggest,
  editAddressAlternative,
  onSubmit,
}) => {
  const { alternatives, heading, text } = addressSuggest;

  return (
    <div>
      <Heading prio1>{heading}</Heading>
      {text && <Paragraph>{text}</Paragraph>}
      <AddressAlternatives
        alternatives={alternatives}
        editAddressAlternative={editAddressAlternative}
        onSubmit={onSubmit}
      />
    </div>
  );
};

AddressSuggestions.propTypes = {
  addressSuggest: PropTypes.object.isRequired,
  editAddressAlternative: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddressSuggestions;
