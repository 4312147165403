export function round(value) {
  return Math.round(value);
}

export function roundValuesInObject(obj) {
  return Object.keys(obj).reduce((prev, key) => {
    const value = obj[key];
    return { [key]: !Number.isNaN(value) ? round(value) : value };
  }, {});
}

export function getVersion(metric) {
  const matches = metric.id.match(/^(v4-[0-9]*)/);
  return matches?.length > 0 ? matches[0] : null;
}
