import queryString from "query-string";
import { browserHistory } from "react-router"; // eslint-disable-line import/no-extraneous-dependencies
import {
  ADS_PAGE__ADD_TO_BASKET_FAILED,
  ADS_PAGE__ADD_TO_BASKET_STARTED,
  ADS_PAGE__ADD_TO_BASKET_SUCCESS,
  ADS_PAGE__ADD_TO_WISHLIST_FAILED,
  ADS_PAGE__ADD_TO_WISHLIST_STARTED,
  ADS_PAGE__ADD_TO_WISHLIST_SUCCESS,
  ADS_PAGE__LOAD_ADS,
  ADS_PAGE__SELECT_ARTICLE,
  MINI_BASKET_FRAGMENT__LOAD_MINI_BASKET,
} from "../constants";
import backendUrl from "../utils/AseBackendUrls";
import { triggerEvent } from "../utils/BrowserEvents";

function triggerChangedEvent() {
  triggerEvent("ase:basket:changed");
  triggerEvent("ase:wishlist:changed");
}

function loadMiniBasket(apiClient, query) {
  return apiClient.get(
    backendUrl.MINI_BASKET,
    {
      ok: (content) => ({
        type: MINI_BASKET_FRAGMENT__LOAD_MINI_BASKET,
        payload: content.body,
      }),
    },
    {
      httpClientOptions: {
        params: query,
      },
    }
  );
}

export function loadAds({ articleNumber, variant, apiClient, query }) {
  return [
    {
      type: ADS_PAGE__LOAD_ADS,
      articleNumber,
      variant,
    },
    loadMiniBasket(apiClient, query),
  ];
}

export function selectArticle({ event, articleNumber, variant, url }) {
  event.preventDefault();
  browserHistory.push(url);
  return [
    {
      type: ADS_PAGE__SELECT_ARTICLE,
      articleNumber,
      variant,
    },
  ];
}

export function addToBasket({ event, ...params }, apiClient) {
  event.preventDefault();
  return [
    { type: ADS_PAGE__ADD_TO_BASKET_STARTED },
    apiClient.post(
      backendUrl.ADD_TO_BASKET,
      queryString.stringify(params, { sort: false }),
      {
        created: (content) => [
          {
            payload: content.body,
            type: ADS_PAGE__ADD_TO_BASKET_SUCCESS,
          },
          loadMiniBasket(apiClient),
          triggerChangedEvent(),
        ],
      },
      {
        httpClientOptions: {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        },
        handleUnexpected: (error) => ({
          type: ADS_PAGE__ADD_TO_BASKET_FAILED,
          payload: error.response?.data?.globalMessage,
        }),
      }
    ),
  ];
}

export function addToWishList({ event, ...params }, apiClient) {
  event.preventDefault();
  return [
    { type: ADS_PAGE__ADD_TO_WISHLIST_STARTED },
    apiClient.post(
      backendUrl.ADD_TO_WISHLIST,
      queryString.stringify(params, { sort: false }),
      {
        created: (content) => [
          {
            payload: content.body,
            type: ADS_PAGE__ADD_TO_WISHLIST_SUCCESS,
          },
          loadMiniBasket(apiClient),
          triggerChangedEvent(),
        ],
      },
      {
        httpClientOptions: {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        },
        handleUnexpected: (error) => ({
          type: ADS_PAGE__ADD_TO_WISHLIST_FAILED,
          payload: error.response?.data?.globalMessage,
        }),
      }
    ),
  ];
}

export function createBasket({ event, ...params }, apiClient) {
  event.preventDefault();
  const { requestJson } = params;
  return apiClient.post(backendUrl.CREATE_SHARED_BASKET, requestJson);
}
