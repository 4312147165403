// redux actions
export const LOAD_SEARCH_STARTED_REQUEST = "LOAD_SEARCH_STARTED_REQUEST";
export const LOAD_SEARCH_SUCCESS_REQUEST = "LOAD_SEARCH_SUCCESS_REQUEST";
export const LOAD_SEARCH_FAILED_REQUEST = "LOAD_SEARCH_FAILED_REQUEST";

// redux initial state for reducer
export const initialState = {
  data: {},
  loading: false,
};
