import PropTypes from "prop-types";
// vendor
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Helmet from "react-helmet";
// component
import Confirmation from "./Confirmation";
import CatalogOrderForm from "./CatalogOrderForm";
import { formNames } from "../../../pages/constants";
import { skeletonData } from "../constants";

const CatalogOrderFragment = ({
  data,
  virtual,
  confirmAddressAlternative,
  editAddressAlternative,
  submitCatalogOrder,
  updateForm,
  cacheFormValues,
  formCache,
  layout,
  message,
  pageTitle,
  routes,
  ...props
}) => {
  const {
    additionalCatalogs,
    awarenessHint,
    awarenessId,
    catalogOrderSuccess,
    deliveryAddress,
    errorMessage,
    globalMessage,
    heading,
    highlightedCatalog,
    infoMessage,
    submit,
  } = data;

  const { step } = virtual;

  const skeleton = !heading;

  if (step === "confirmation") {
    return <Confirmation {...catalogOrderSuccess} />;
  }

  const catalogOrderFragment = (
    <CatalogOrderForm
      additionalCatalogs={
        skeleton ? skeletonData.additionalCatalogs : additionalCatalogs
      }
      awarenessHint={awarenessHint}
      awarenessId={awarenessId}
      confirmAddressAlternative={({ actionUrl, values }) =>
        confirmAddressAlternative({
          routes,
          link: actionUrl || submit.link,
          values,
        })
      }
      editAddressAlternative={({ actionUrl, values }) =>
        editAddressAlternative({
          routes,
          link: actionUrl,
          values,
        })
      }
      errorMessage={errorMessage}
      formDefinition={deliveryAddress}
      formName={formNames.catalogOrder}
      globalMessage={globalMessage}
      heading={skeleton ? skeletonData.heading : heading}
      highlightedCatalog={highlightedCatalog}
      infoMessage={infoMessage}
      layout={layout === "page" ? "standard" : "fluid"}
      message={message}
      skeleton={skeleton}
      submitButton={submit}
      onSubmit={(values) => {
        submitCatalogOrder({
          routes,
          link: submit.link,
          values,
          defaultCatalog: highlightedCatalog ? highlightedCatalog.name : null,
        });
      }}
      updateForm={updateForm({
        addressStatePath: "deliveryAddress",
        formName: formNames.catalogOrder,
      })}
      updateFormURL={deliveryAddress.updateFormURL}
      cacheFormValues={cacheFormValues}
      formCache={formCache}
      {...props}
    />
  );

  return layout === "page" && pageTitle ? (
    <div>
      <Helmet title={pageTitle} />
      {catalogOrderFragment}
    </div>
  ) : (
    catalogOrderFragment
  );
};

CatalogOrderFragment.propTypes = {
  confirmAddressAlternative: PropTypes.func.isRequired,
  editAddressAlternative: PropTypes.func.isRequired,
  submitCatalogOrder: PropTypes.func,
  updateForm: PropTypes.func,
  cacheFormValues: PropTypes.func,
  formCache: PropTypes.object,
  layout: PropTypes.oneOf(["page", "overlay"]),
  message: PropTypes.object,
  pageTitle: PropTypes.string,
  routes: PropTypes.array,
  data: PropTypes.shape({
    additionalCatalogs: PropTypes.object,
    awarenessHint: PropTypes.object,
    awarenessId: PropTypes.object,
    catalogOrderSuccess: PropTypes.object,
    deliveryAddress: PropTypes.object,
    errorMessage: PropTypes.string,
    globalMessage: PropTypes.object,
    heading: PropTypes.string,
    highlightedCatalog: PropTypes.object,
    infoMessage: PropTypes.string,
    submit: PropTypes.object.isRequired,
  }),
  virtual: PropTypes.shape({
    step: PropTypes.string,
  }),
};

export const stateKey = "ASE_INITIAL_STATE_CATALOG_ORDER_FRAGMENT";
export const attrs = ["layout", "catalogId"];

export default CatalogOrderFragment;
