// vendor
import update from "immutability-helper";
// ase
import {
  EMAIL_CHANGE_FRAGMENT__LOAD_DATA,
  SESSION_EXPIRED__LOAD_DATA,
  SESSION_EXPIRED__LOAD_DATA_SUCCESSFUL,
} from "../../pages/constants";

const initialState = {
  data: {},
  uiState: {
    loginPrompt: false,
    renderAsSkeleton: true,
  },
};

function emailChangeFragment(state = initialState, action) {
  switch (action.type) {
    // LOAD data
    case SESSION_EXPIRED__LOAD_DATA: {
      return update(state, {
        uiState: {
          renderAsSkeleton: { $set: true },
        },
      });
    }
    case SESSION_EXPIRED__LOAD_DATA_SUCCESSFUL: {
      return update(state, {
        data: { $set: action.payload },
        uiState: {
          loginPrompt: { $set: true },
          renderAsSkeleton: { $set: false },
        },
      });
    }
    case EMAIL_CHANGE_FRAGMENT__LOAD_DATA: {
      return update(state, {
        data: { $set: action.payload },
        uiState: {
          renderAsSkeleton: { $set: false },
        },
      });
    }
    default:
      return state;
  }
}

export default emailChangeFragment;
