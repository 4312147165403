import React from "react";
import classNames from "classnames/bind";
import s from "./Button.module.css";
import s2 from "./ButtonWithIcon.module.css";
import IconRight from "../Icon/IconRight";

type Props = {
  disabled?: boolean;
  href?: string;
  icon: React.ReactNode;
  large?: boolean;
  onClick?: (...args: unknown[]) => unknown;
  prio1?: boolean;
  prio2?: boolean;
  prio3?: boolean;
  prio4?: boolean;
  prio5?: boolean;
  inverted?: boolean;
  text?: string;
  className?: string;
  skeleton?: boolean;
  align?: "left" | "center" | "right";
  iconClass?: string;
  iconClassDisabled?: string;
  next?: boolean;
};

function ButtonWithIcon({
  disabled = false,
  href = null,
  icon,
  large = false,
  onClick,
  prio1 = false,
  prio2 = false,
  prio3 = false,
  prio4 = false,
  prio5 = false,
  inverted,
  text = null,
  className,
  skeleton,
  align = "left",
  iconClass,
  iconClassDisabled,
  next,
  ...attrs
}: Props) {
  const cx = classNames.bind(s);
  let rootClass = cx("root", {
    disabled,
    icon,
    large,
    prio1: prio1 || !(prio2 || prio3 || prio4 || prio5),
    prio2,
    prio3,
    prio4,
    prio5,
    inverted,
    skeleton,
    regular: !inverted,
  });
  rootClass = cx(rootClass, className);

  const htmlHref = disabled ? null : href;

  let buttoncontent = (
    <span className={cx(s.content, { [align]: true })}>
      {next && <span className={s2.flexGrow} />}
      <span className={cx(s2.container, { [s2.flexGrow]: next })}>
        <span
          className={cx(s2.icon, {
            [iconClass]: true,
            ...(disabled && { [iconClassDisabled]: true }),
          })}
        >
          {icon}
        </span>{" "}
        <span data-test-text>{text}</span>
      </span>
      {next && (
        <span className={s2.nextIcon}>
          <IconRight small />
        </span>
      )}
    </span>
  );

  buttoncontent = !skeleton ? (
    buttoncontent
  ) : (
    <span className={s.skeleton}>{buttoncontent}</span>
  );

  let htmlTag = "button";
  if (href) {
    htmlTag = disabled ? "span" : "a";
  }

  return React.createElement(
    htmlTag,
    {
      ...attrs,
      className: rootClass,
      disabled,
      href: htmlHref,
      onClick,
      role: href ? "button" : null,
    },
    buttoncontent
  );
}

export default ButtonWithIcon;
