import PropTypes from "prop-types";
// vendor
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import Heading from "stelar/components/Heading";
import Paragraph from "stelar/components/Paragraph";
// ase
import s from "./NewsletterSubscriptionHeader.module.css";

const Header = ({ heading, shortDesc }) => {
  const headline = heading ? (
    <Heading className={s.heading} prio1 tag="h4">
      {heading}
    </Heading>
  ) : null;

  const text = shortDesc ? (
    <Paragraph className={s.text}>{shortDesc}</Paragraph>
  ) : null;

  return (
    <div className={s.root}>
      {headline}
      {text}
    </div>
  );
};

Header.propTypes = {
  heading: PropTypes.string,
  shortDesc: PropTypes.string,
};

export default Header;
