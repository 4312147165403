import React from "react";
import { classNames, IconType } from "../common";

function IconWishList(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 36 36" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path d="M17.6,34.4C2.9,24.8,1.4,15.3,1.4,11.7C1.4,6,5.6,1.3,10.8,1.3c2.8,0,5.4,1.4,7,3.6L18,5.1l0.1-0.1c1.8-2.2,4.4-3.6,7-3.6 c5.1,0,9.4,4.7,9.4,10.4c0,3.6-1.5,13.1-16.2,22.7L18,34.7L17.6,34.4z M10.8,3c-4.3,0-7.7,3.9-7.7,8.7c0,6.9,5.5,14.6,14.8,20.9 l0.1,0.1l0.1-0.1c9.3-6.2,14.8-14,14.8-20.9c0-4.8-3.5-8.7-7.7-8.7c-2.6,0-5.1,1.5-6.5,4.1L18,8.5l-0.7-1.4C15.9,4.5,13.4,3,10.8,3z" />
    </svg>
  );
}

export default IconWishList;
