import { SSR_PREFETCHED } from "shared/ssrPrefetch/constants";
import {
  LOAD_PRODUCT_TEASER_SUCCESS,
  LOAD_PRODUCT_TEASER_STARTED,
  LOAD_PRODUCT_TEASER_FAILED,
  LOAD_RECOMMENDATIONS_TIMEOUT,
  LOAD_RECO_TEASER_SUCCESS,
  LOAD_RECO_TEASER_STARTED,
  LOAD_RECO_TEASER_FAILED,
} from "../../pages/constants";
import { buildCacheKey } from "../sell-recommendations/helper";

export function loadRecommendations(apiClient, location, params, isClient) {
  return [
    { type: LOAD_RECO_TEASER_STARTED },
    apiClient.get(
      `/recommendations/${encodeURIComponent(location)}`,
      {
        ok: (response) => [
          ...(!isClient && {
            type: SSR_PREFETCHED,
            payload: buildCacheKey({
              location,
              article: params.articleNumber,
            }),
          }),
          {
            type: LOAD_RECO_TEASER_SUCCESS,
            payload: {
              ...response.data,
              location,
              articleNumber: params.articleNumber,
            },
          },
        ],
        notFound: () => ({ type: LOAD_RECO_TEASER_FAILED }),
        noContent: () => ({ type: LOAD_RECO_TEASER_FAILED }),
        internalServerError: () => ({ type: LOAD_RECO_TEASER_FAILED }),
      },
      {
        apiBase: "/api/sell",
        httpClientOptions: { params, ...(!isClient && { timeout: 1000 }) },
        handleUnexpected: () => ({
          type: LOAD_RECO_TEASER_FAILED,
          payload: { location, articleNumber: params.articleNumber },
        }),
      }
    ),
  ];
}
export function loadTeaserFromProductServcie(apiClient, teaserType) {
  return [
    { type: LOAD_PRODUCT_TEASER_STARTED },
    apiClient.get(
      `/detail/article/teaser/${encodeURIComponent(teaserType)}`,
      {
        ok: (response) => ({
          type: LOAD_PRODUCT_TEASER_SUCCESS,
          payload: { ...response.data },
        }),
        notFound: () => ({ type: LOAD_PRODUCT_TEASER_FAILED }),
        noContent: () => ({ type: LOAD_PRODUCT_TEASER_FAILED }),
        internalServerError: () => ({ type: LOAD_PRODUCT_TEASER_FAILED }),
        gatewayTimeout: () => ({ type: LOAD_RECOMMENDATIONS_TIMEOUT }),
      },
      {
        apiBase: "/api/sell",
      }
    ),
  ];
}
