/* eslint-disable import/prefer-default-export */
import { legacy_createStore as createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import sideEffectMiddleware from "shared/utils/sideEffectMiddleware";
import testability from "shared/testability/middleware";
import rootReducer from "./reducer";
// import { createLogger } from "redux-logger";

/* eslint-disable no-underscore-dangle */
export function configureStore(initialState = {}) {
  const create =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION__ // eslint-disable-line no-underscore-dangle
      ? window.__REDUX_DEVTOOLS_EXTENSION__({ name: "SELL_PAGES" })(createStore) // eslint-disable-line no-underscore-dangle
      : createStore;

  const createStoreWithMiddleware = applyMiddleware(
    thunkMiddleware,
    sideEffectMiddleware,
    testability
    // createLogger()
  )(create);

  const store = createStoreWithMiddleware(rootReducer, initialState);

  if (module.hot) {
    module.hot.accept(
      "./reducer",
      () => store.replaceReducer(require("./reducer").default) // eslint-disable-line
    );
  }

  return store;
}
/* eslint-enable */
