export const skeletonData = {
  heading: "This is a really long heading to make the overlay spread",
  additionalCatalogs: {
    heading: "This is a somewhat shorter heading",
    shopCatalogs: [
      {
        checked: false,
        label: "label one",
        name: "name one",
      },
      {
        checked: false,
        label: "label twoandahalf",
        name: "name twoandahalf",
      },
      {
        checked: false,
        label: "label three",
        name: "name three",
      },
      {
        checked: false,
        label: "label four",
        name: "name four",
      },
    ],
  },
};
