// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import { ButtonX } from "stelar/components/Button";
// ase
import s from "./TitleBar.module.css";

/**
 * Used for overlays (e.g. MiniBasket)
 */
const TitleBar = ({ closeFunc, closeText, title }) => (
  <header className={s.header}>
    {title && <span className={s.title}>{title}</span>}
    <span className={s.buttonX}>
      <ButtonX onClick={closeFunc} aria-label={closeText} />
    </span>
  </header>
);

TitleBar.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  closeText: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default TitleBar;
