import mq from "./media";

export default function convertSizes(sizes = {}) {
  if (!sizes.default) {
    return null;
  }

  function breakpointSize(breakpoint, size) {
    return size ? `(min-width: ${breakpoint}) ${size}` : "";
  }

  const string = Object.keys(sizes)
    .map((size) => {
      if (mq.breakpoint[size]) {
        return breakpointSize(`${mq.breakpoint[size].min}px`, sizes[size]);
      }
      if (size === "default") {
        return sizes[size];
      }
      return breakpointSize(size, sizes[size]);
    })
    .join(", ");

  return string;
}
