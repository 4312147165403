import React from "react";
import classNames from "classnames/bind";

import Text from "../Text";
import s from "./RadioButtons.module.css";

const cx = classNames.bind(s);

type Props = {
  defaultValue?: string | number;
  selectedValue?: string | number;
  inline?: boolean;
  name: string;
  radioButtons: React.ComponentPropsWithoutRef<"input">[];
  skeleton?: boolean;
} & React.ComponentPropsWithoutRef<"input">;

function RadioButtons({
  defaultValue,
  inline,
  name,
  radioButtons,
  selectedValue,
  skeleton,
  ...attrs
}: Props) {
  const classnames = cx("root", { inline });
  const currentValue = selectedValue || defaultValue;

  function labelElement(radioButton, key) {
    return skeleton ? (
      <Text normal skeleton>
        {radioButton.label}
      </Text>
    ) : (
      <label htmlFor={`${name}-${key}`}>{radioButton.label}</label>
    );
  }

  return (
    <ul className={classnames}>
      {radioButtons.map((radioButton, key) => (
        <li className={s.li} key={radioButton.value as string}>
          <span className={s.col}>
            <input
              {...attrs}
              checked={currentValue === radioButton.value}
              className={s.input}
              id={`${name}-${key}`}
              name={name}
              type="radio"
              value={radioButton.value}
            />
            {labelElement(radioButton, key)}
          </span>
        </li>
      ))}
    </ul>
  );
}

export default RadioButtons;
