import { setPageTitle } from "shared/webbridge/actions";
import backendUrl from "../utils/AseBackendUrls";
import {
  PASSWORD_FORGOT_PAGE__LOAD_PASSWORD_FORGOT_PAGE,
  PASSWORD_FORGOT_PAGE__REQUEST_PASSWORD_CHANGE,
  PASSWORD_FORGOT_PAGE__REQUEST_PASSWORD_RECOVERY_EMAIL,
  PASSWORD_FORGOT_PAGE__REQUEST_PASSWORD_RECOVERY_EMAIL_FAILED,
  PASSWORD_FORGOT_PAGE__VALIDATE_EMAIL,
  PASSWORD_FORGOT_PAGE__VALIDATE_INPUT,
  PASSWORD_FORGOT_PAGE__VALIDATE_PASSWORDREPETITION_FIELD,
  PASSWORD_PAGE__VALIDATE_ALL,
} from "../constants";

export function loadPasswordPage(apiClient, query) {
  return apiClient.get(
    backendUrl.PASSWORD_FORGOT,
    {
      ok: (content) => [
        {
          type: PASSWORD_FORGOT_PAGE__LOAD_PASSWORD_FORGOT_PAGE,
          payload: content.body,
        },
        setPageTitle(content.body?.page?.headData?.title),
      ],
    },
    {
      httpClientOptions: {
        params: query,
      },
    }
  );
}

export function doRequestRecoveryEmail({ event, email }, apiClient) {
  return (dispatch, getState) => {
    event.preventDefault();
    dispatch({
      type: PASSWORD_FORGOT_PAGE__VALIDATE_EMAIL,
    });
    const state = getState();
    if (state.passwordPage.submitable) {
      const postData = { email };
      dispatch(
        apiClient.post(backendUrl.PASSWORD_FORGOT, postData, {
          ok: (content) => [
            {
              type: PASSWORD_FORGOT_PAGE__REQUEST_PASSWORD_RECOVERY_EMAIL,
              payload: content.body,
            },
          ],
          badRequest: (content) => [
            {
              type: PASSWORD_FORGOT_PAGE__REQUEST_PASSWORD_RECOVERY_EMAIL_FAILED,
              payload: content.body,
            },
          ],
        })
      );
    }
    return true;
  };
}

export function doRequestPasswordChange(
  { event, password, passwordRepetition, code, id, submitLink },
  apiClient
) {
  return (dispatch, getState) => {
    event.preventDefault();
    dispatch({
      type: PASSWORD_PAGE__VALIDATE_ALL,
    });
    const state = getState();
    if (state.passwordPage.submitable) {
      const postData = {
        code,
        id,
        password,
        passwordRepetition,
      };
      dispatch(
        apiClient.post(
          submitLink || backendUrl.PASSWORD_PAGE_CHANGE,
          postData,
          {
            ok: (content) => [
              {
                type: PASSWORD_FORGOT_PAGE__REQUEST_PASSWORD_CHANGE,
                payload: content.body,
              },
            ],
            badRequest: (content) => [
              {
                type: PASSWORD_FORGOT_PAGE__REQUEST_PASSWORD_CHANGE,
                payload: content.body,
              },
            ],
          }
        )
      );
    }
    return true;
  };
}

export function validateInput(inputPath) {
  return {
    type: PASSWORD_FORGOT_PAGE__VALIDATE_INPUT,
    inputPath,
  };
}

export function validatePasswordRepetition() {
  return {
    type: PASSWORD_FORGOT_PAGE__VALIDATE_PASSWORDREPETITION_FIELD,
  };
}
