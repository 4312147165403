import track from "../../tracking";

const globalTrackingHandler = {
  all: (content) => {
    const trackingInfo =
      content &&
      content.body &&
      (content.body.trackingInfo ||
        (content.body.page && content.body.page.trackingInfo));
    if (trackingInfo) {
      track(trackingInfo);
    }
    return null; // don't return any action
  },
};

export default globalTrackingHandler;
