// vendor
import update from "immutability-helper";
// ase
import removeItemsByNamesFromValues from "../../util/reduxFormUtils/removeItemsByNamesFromValues";
import replaceIndexKeyWithName from "../../util/reduxFormUtils/replaceIndexKeyWithName";
import {
  REGISTER_PAGE__LOAD_REGISTER_PAGE,
  REGISTER_PAGE__REGISTER_FAILED,
  REGISTER_PAGE__UPDATE_FORM,
  REGISTER_PAGE__RERENDER_STATE,
  REGISTER_PAGE__REGISTER,
} from "../constants";
// component
import initialState from "./initialState";

const registerPage = (state = initialState, action) => {
  let actionPayload;
  let newState;

  switch (action.type) {
    case REGISTER_PAGE__REGISTER:
      return update(state, { registerStarted: { $set: true } });
    case REGISTER_PAGE__LOAD_REGISTER_PAGE:
      actionPayload = { ...action.payload };
      // Ist bereits eine structure vorhanden, ignorieren wir die vom BE,
      // weil sonst die Änderungen im Store vom Besucher verloren gehen.
      // (passiert, wenn er history.back o.Ä. benutzt)
      if (
        state.registerForm &&
        state.registerForm.structure &&
        state.registerForm.structure.length
      ) {
        actionPayload.registerForm.structure = state.registerForm.structure;
      }
      if (actionPayload.registerForm?.values) {
        actionPayload.registerForm.values = replaceIndexKeyWithName(
          action.payload.registerForm.values
        );
      }
      return update(state, { $set: actionPayload });
    case REGISTER_PAGE__REGISTER_FAILED:
      if (Array.isArray(action.payload)) {
        return update(state, {
          registerForm: {
            values: { $set: replaceIndexKeyWithName(action.payload.values) },
          },
          registerStarted: { $set: false },
        });
      }
      return update(state, {
        page: {
          globalMessage: { $set: action.payload.globalMessage },
        },
        registerForm: {
          addressSuggest: { $set: action.payload.addressSuggest },
          message: { $set: action.payload.message },
          values: { $merge: replaceIndexKeyWithName(action.payload.values) },
        },
        registerStarted: { $set: false },
      });
    case REGISTER_PAGE__RERENDER_STATE:
      return update(state, {
        registerForm: {
          submitable: { $set: action.payload },
        },
      });
    case REGISTER_PAGE__UPDATE_FORM:
      // use $set instead of $merge to remove existing data:
      newState = {
        registerForm: { structure: { $set: action.payload.structure } },
      };

      // remove postalCode value if a country has none
      if (action.payload.valuesToRemove) {
        removeItemsByNamesFromValues(
          action.payload.valuesToRemove,
          state.registerForm.values
        );
      }

      return update(state, newState);

    default:
      return state;
  }
};

export default registerPage;
