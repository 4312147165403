import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { connect } from "react-redux";
import prefetch from "shared/ssrPrefetch/prefetch";
import loadLayerContent from "./actions";
import s from "./LayerContentFragment.module.css";
import ContentKurator, {
  SkeletonContentKurator,
} from "../../components/ContentKurator";
import { getSliderActions } from "../../utils/uiState/sliders/actions";
import {
  imageLoaded,
  closeOrOpenContentAccordionItem,
} from "../../utils/uiState/actions";

const redial = {
  fetch: ({ dispatch, apiClient, props, cookies }) =>
    dispatch(loadLayerContent(apiClient, props.content, cookies)),
};

function mapStateToProps({ layerContentFragment }) {
  const { uiState, contents } = layerContentFragment;

  const {
    loadedImages,
    sliders: sliderState,
    openedContentAccordionItems,
  } = uiState;

  const common = {
    loadedImages,
    sliderState,
    openedContentAccordionItems,
  };
  return Array.isArray(contents)
    ? { contents, ...common }
    : { ...contents, ...common };
}

function mapDispatchToProps(dispatch) {
  return {
    onImageLoad: (src) => dispatch(imageLoaded(src)),
    sliderActions: getSliderActions(dispatch),
    toggleContentAccordionItem: (contentAccordionItem) =>
      dispatch(closeOrOpenContentAccordionItem(contentAccordionItem)),
  };
}

function LayerContentFragment({
  kuratorContentsTop,
  kuratorContentsBottom,
  onImageLoad,
  sliderActions,
  loadedImages,
  sliderState,
  openedContentAccordionItems,
  toggleContentAccordionItem,
}) {
  if (!kuratorContentsTop && !kuratorContentsBottom) {
    return <SkeletonContentKurator />;
  }

  const props = {
    onImageLoad,
    loadedImages,
    sliderState,
    sliderActions,
    closeOrOpenContentAccordionItem: toggleContentAccordionItem,
    openedContentAccordionItems,
  };

  function getKuratorContents(kuratorContents = []) {
    return kuratorContents.map((kuratorContent) => (
      <ContentKurator content={kuratorContent} {...props} />
    ));
  }

  return (
    <div className={s.root}>
      {kuratorContentsTop && getKuratorContents(kuratorContentsTop)}
      {kuratorContentsBottom && getKuratorContents(kuratorContentsBottom)}
    </div>
  );
}

LayerContentFragment.propTypes = {
  kuratorContentsTop: PropTypes.array,
  kuratorContentsBottom: PropTypes.array,
  onImageLoad: PropTypes.func,
  sliderActions: PropTypes.object,
  toggleContentAccordionItem: PropTypes.func,
  loadedImages: PropTypes.object,
  sliderState: PropTypes.object,
  openedContentAccordionItems: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(prefetch(LayerContentFragment, redial));
