/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconSearch(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 -0.3 22 22" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path d="M21.4,19.4l-4.3-4.3c0,0,0,0-0.1-0.1c1.1-1.6,1.8-3.5,1.8-5.5c0-5.2-4.2-9.4-9.4-9.4c-5.2,0-9.4,4.2-9.4,9.4c0,5.2,4.2,9.4,9.4,9.4c2.1,0,4-0.7,5.6-1.9c0,0,0,0,0,0l4.3,4.3c0.6,0.6,1.4,0.6,1.9,0.1l0.2-0.2C22.1,20.8,22,20,21.4,19.4zM1.7,9.5c0-4.3,3.5-7.8,7.8-7.8s7.8,3.5,7.8,7.8c0,4.3-3.5,7.8-7.8,7.8S1.7,13.9,1.7,9.5z" />
    </svg>
  );
}

export default IconSearch;
