export const THIS_NOTRACK_COOKIE = "THIS_NOTRACK=";

export const TRACKING_OPT_IN_COOKIE = "mf-tracking-opt-in";
export const TRACKING_OPT_OUT_COOKIE = "mf-tracking-opt-out";
export const OPTIN_TRACKING = "mf-tracking-optin";

export const EPOQ_THIRD_PARTY_COOKIE = "eqe";
export const EPOQ_ID_COOKIE = "epq_id";
export const EPOQ_CUSTOMER_COOKIE = "7cb54b_customer_id";
export const TMP_CUSTOMER_ID_ENABLED = false;

export const E2E_TEST_COOKIE = "e2eTest";
