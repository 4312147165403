import { API_BASE } from "shared/apiClient/constants";
import track from "shared/tracking";
import { triggerCookieBannerConsentChanged } from "shared/utils/customEvents";
import {
  CLOSE_COOKIE_BANNER,
  LOAD_COOKIE_BANNER_FAILED,
  LOAD_COOKIE_BANNER_STARTED,
  LOAD_COOKIE_BANNER_SUCCESS,
  OPEN_COOKIE_BANNER,
} from "../../pages/constants";

const API_ASE_OPTIN_BANNER = "/optin/banner";
const API_ASE_OPTIN_CONSENT = "/optin/consent";
const API_ASE_OPTIN_REJECT = "/optin/reject";

export function loadCookieBanner(apiClient) {
  return [
    { type: LOAD_COOKIE_BANNER_STARTED },
    apiClient.get(
      API_ASE_OPTIN_BANNER,
      {
        ok: (response) => ({
          type: LOAD_COOKIE_BANNER_SUCCESS,
          payload: response.data,
        }),
        notFound: () => ({ type: LOAD_COOKIE_BANNER_FAILED }),
        gatewayTimeout: () => ({ type: LOAD_COOKIE_BANNER_FAILED }),
        serviceUnavailable: () => ({ type: LOAD_COOKIE_BANNER_FAILED }),
      },
      { apiBase: API_BASE.ASE }
    ),
  ];
}

export function cookieBannerConsent(apiClient) {
  return apiClient.post(
    API_ASE_OPTIN_CONSENT,
    {},
    {
      ok: (response) => {
        triggerCookieBannerConsentChanged();
        track({
          googleEnhancedEcommerce: {
            containerId: response.data.gtmId,
            payload: { event: "pageview" },
          },
        });
      },
    },
    { apiBase: API_BASE.ASE }
  );
}

export function cookieBannerReject(apiClient) {
  return apiClient.post(
    API_ASE_OPTIN_REJECT,
    {},
    {
      ok: () => {
        triggerCookieBannerConsentChanged();
      },
    },
    { apiBase: API_BASE.ASE }
  );
}

export function clearCookieBanner() {
  return { type: CLOSE_COOKIE_BANNER };
}

export function openCookieBanner() {
  return { type: OPEN_COOKIE_BANNER };
}
