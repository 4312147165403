import React from "react";
import s from "./LogoCompactStyles.module.css";

function LogoCompact() {
  return (
    <div className={s.root}>
      <svg viewBox="0 0 67.5 53.272" aria-label="Manufactum Logo">
        <path
          fill="#005232"
          d="M59.5 36.531c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z"
          clipPath="none"
        />
        <path
          fill="#005232"
          d="M13.5.031c-1.9-.2-4.7.6-6.1 1.3-1.4.7-3.1 2.1-3.8 4.3-.6 2.3-.2 10.4-.4 11.7-.1 1.4-.8 1-1.1 2.7-.9 5.9-1.1 13.8-1.2 19.6 0 .9-.8 1.7-.9 2.5v1.3c0 2.2.3 4.6.5 5.3.1.7.9 1.2 2 1.6.4.1.9 1.3 1.4 1.4 2.4.6 5.6.6 6.5.4 1.4-.4 4.2-4.2 4.3-5.6.1-1.4-1-4.1-.8-7 .2-2.9.3-12 .3-14.8 0-.3.2-.2.2-.2.8 1.4 2.2 8.6 3.7 11.6.9 1.9 1.8 3.5 3.2 3.9 1.1.3 3.4-.8 4.1-1.2.7-.4 2.5-.3 3.1-.8 2-1.5 4.5-5.7 5.8-8.2.1-.2.2-.2.3 0 .1 1.8-.4 5.7-.7 9.9-.3 4.2.7 7.5 1 8.6.3 1.3 1.1 2.8 2 4.1.8 1.2 7 1.1 8.8-.2 1-.7 1.7-1.8 1.7-2.8 0-1.8 1.1-2.4.8-4.4-.7-3.8-.1-8.7-.2-13.3-.2-5.4-1.1-10.6-1.2-15.3 0-4.4-.7-7.4-1.3-7.8-1.2-.8-1.4-2.1-2.7-2.5-1.2-.4-5.1.7-6.5 1.4-1 .5-1.6 1.7-2.2 2.5-.4.6-1.2.5-1.8 1.4-1.5 2.2-5 9.2-6.7 12.6-.1.3-.3.3-.3 0-1.3-5.1-4.4-15.5-6-19.2-.5-1-1.6-1.2-2.1-2-.7-.6-1.5-2.6-3.7-2.8z"
        />
      </svg>
    </div>
  );
}

export default LogoCompact;
