import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies

import s from "./ContentGutter.module.css";

const ContentGutter = ({ children }) => (
  <div className={s.root}>{children}</div>
);

ContentGutter.propTypes = {
  children: PropTypes.object,
};

export default ContentGutter;
