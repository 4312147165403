import { statusRedirect, statusNotFound } from "shared/utils/status/actions";
import { createActionKey } from "shared/utils/actionKey";
import { setPageTitle } from "shared/webbridge/actions";
import { LOAD_CATEGORY_STARTED, LOAD_CATEGORY_SUCCESS } from "./constants";

// eslint-disable-next-line import/prefer-default-export
export function loadCategory(
  apiClient,
  id,
  urlName,
  {
    p = "1",
    sortby,
    manufacturer,
    targetgroup,
    color,
    material,
    size,
    availability,
    price,
    category,
  } = {}
) {
  const params = {
    p,
    sortby,
    manufacturer,
    targetgroup,
    color,
    material,
    size,
    availability,
    price,
    category,
  };
  const key = createActionKey(id);
  const encodedQuery = encodeURIComponent(id.replace(/\/|\\/g, ""));

  return [
    { type: LOAD_CATEGORY_STARTED, payload: { key } },
    apiClient.get(
      `/category/${encodedQuery}`,
      {
        ok: (response) => {
          const location = `/${response.data.categoryUrlName}-c${id}/`;
          if (urlName && urlName !== response.data.categoryUrlName) {
            return statusRedirect(location, 301);
          }
          return [
            {
              type: LOAD_CATEGORY_SUCCESS,
              payload: {
                jsonData: response.data,
                key,
                currentLocation: location,
              },
            },
            setPageTitle(response.data?.head?.title),
          ];
        },
        notFound: () => statusNotFound(`category ${id} does not exist`),
      },
      {
        apiBase: "/api/sell",
        httpClientOptions: { params },
      }
    ),
  ];
}
