import { validateCheckbox, validateInput } from "../../pages/utils/Validators";

export function validateEmail(state) {
  const newState = validateInput(state, {
    inputPath: "email",
  });
  return newState;
}

export function validateDatenschutz(state) {
  const newState = validateCheckbox(state, {
    checkboxPath: "datenschutz",
  });
  return newState;
}
