import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Paragraph from "stelar/components/Paragraph";
import imageSizes from "shared/utils/imageSizes";
import { IMAGE_PROFILE_DEFAULTS } from "shared/utils/imageUrlBuilder";
import s from "./ElementTripleImage.module.css";
import RatioImage from "../../RatioImage";
import ContentSpacing from "../../ContentSpacing";

function ElementTripleImage({
  firstImage,
  secondImage,
  thirdImage,
  onImageLoad,
  loadedImages = {},
  fullWidth,
  lazy,
}) {
  const imgSizes = imageSizes({
    xxxl: "310px",
    xxl: "21vw",
    xl: fullWidth ? "21vw" : "19vw",
    l: fullWidth ? "28vw" : "25vw",
    m: "33vw",
    default: "100vw",
  });

  function renderImageWithText(column = {}) {
    const { image, text, caption } = column;
    if (!image) {
      return null;
    }

    return (
      <div className={s.column} data-test-sell-element-triple-image-image>
        <div className={s.image}>
          <RatioImage
            image={image}
            imageProfileDefault={IMAGE_PROFILE_DEFAULTS.kurator}
            lazy={lazy}
            sizes={imgSizes}
            onImageLoad={onImageLoad}
            hasLoaded={loadedImages[image && image.pattern]}
          />
        </div>
        {(text || caption) && (
          <div className={s.caption}>
            <Paragraph small>{text || caption}</Paragraph>
          </div>
        )}
      </div>
    );
  }

  return (
    <ContentSpacing
      alignment="columns"
      flex
      fullWidth={fullWidth}
      data-test-sell-element-triple-image
    >
      {renderImageWithText(firstImage)}
      {renderImageWithText(secondImage)}
      {renderImageWithText(thirdImage)}
    </ContentSpacing>
  );
}

ElementTripleImage.propTypes = {
  firstImage: PropTypes.object,
  secondImage: PropTypes.object,
  thirdImage: PropTypes.object,
  onImageLoad: PropTypes.func,
  loadedImages: PropTypes.object,
  fullWidth: PropTypes.bool,
  lazy: PropTypes.bool,
};

export default ElementTripleImage;
