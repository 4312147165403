import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import s from "./List.module.css";

const List = ({ children }) => (
  <div className={s.root}>
    <ul className={s.list}>{children}</ul>
  </div>
);

List.propTypes = {
  children: PropTypes.node,
};

List.defaultProps = {};

export default List;
