export default {
  page: {
    back: {
      label: "………………",
    },
    headline: "………… …………………………………………",
    submitButton: {
      label: "…………………………… …………………",
    },
  },
  agb: {
    label:
      "……… ……………………………………………………………… ………… ……… ……… …………………… ……………………… …………………… ………… ……… ……… …………………………… …………………………………………………… ………………… ……… ……… ………… ……… ……………… ……………………………………",
  },
  basket: {
    einloeseItems: [],
    gutscheinItems: [],
    lineItems: [
      {
        ageRelatedDetails: {
          title: "……………………",
          description: "………………………",
        },
        articleNumber: {
          label: "…………………",
          value: "……………",
        },
        availability: {
          info: "……………………… …… ……… ………………………",
          text: "……………………",
        },
        changeQuantity: "………………",
        dimensions: [],
        imgSrc: "",
        key: "0",
        lineItemId: "0",
        name: "…………………………… ………………………………",
        quantity: {
          label: "……………",
          value: 1,
        },
        removeLink: {
          label: "……………………",
        },
        totalPrice: "……,…… …",
        unitPrice: {
          label: "……………………………",
          value: "……,…… …",
        },
      },
    ],
    summaryItems: [
      {
        label: "…………………………………",
        value: "……,…… …",
      },
      {
        label: "…………………………………",
        value: "…,…… …",
      },
    ],
    total: {
      label: "…………………………",
      value: "……,…… …",
    },
    vatLabel: "…………… ……………",
  },
  customer: {
    billingAddress: {
      addressLines: [
        "…………………………………………… ……",
        "………………… ……………… …………",
        "…………… ………………",
        "……………………………",
      ],
      changeLink: {
        label: "………………",
      },
      headline: "…………………………………………",
      nameLines: ["…………", "……………………… ……………"],
    },
    deliveryAddress: {
      changeLink: {
        label: "………………",
      },
      headline: "…………………………………",
      sameAddressLabel: "……… …………………………………………",
    },
    deliveryMethod: {
      data: [
        {
          label: "………",
          value: "………",
        },
      ],
      headline: "……………………",
      selected: "………",
      submit: {
        label: "………………………… ………………………",
      },
    },
    headline: "………… …………………",
    paymentMethod: {
      changeLink: {
        label: "………………",
      },
      headline: "……………………………",
      value: "……………………",
    },
  },
  newsletter: {
    label:
      "……… ……………… ……… ………………………… ………………………… ……… … … ……… ……… ………………… ………………………… ………………………… ………… ……………………… ……… ……………………… ……………………",
  },
  submitButtonDisabled: true,
  voucher: {},
  createOrderStarted: false,
};
