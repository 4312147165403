import backendUrl from "../../pages/utils/AseBackendUrls";
import {
  NEWSLETTER_SUBSCRIPTION_COMPONENT__CHANGE_CHECKBOX,
  NEWSLETTER_SUBSCRIPTION_COMPONENT__CHANGE_INPUT,
  NEWSLETTER_SUBSCRIPTION_COMPONENT__LOAD_NEWSLETTER_SUBSCRIPTION,
  NEWSLETTER_SUBSCRIPTION_COMPONENT__SUBSCRIBE,
  NEWSLETTER_SUBSCRIPTION_COMPONENT__SUBSCRIBE_FAILED,
  NEWSLETTER_SUBSCRIPTION_COMPONENT__VALIDATE,
} from "../../pages/constants";

export function loadNewsletterSubscriptionData(apiClient) {
  return apiClient.get(backendUrl.NEWSLETTER_SUBSCRIBE, {
    ok: (content) => ({
      type: NEWSLETTER_SUBSCRIPTION_COMPONENT__LOAD_NEWSLETTER_SUBSCRIPTION,
      payload: content.body,
    }),
  });
}

export function validateNewsletterFragment(event) {
  event.preventDefault();
  return {
    type: NEWSLETTER_SUBSCRIPTION_COMPONENT__VALIDATE,
  };
}

export function submit(event, link, values, apiClient) {
  event.preventDefault();
  const url = link || backendUrl.NEWSLETTER_SUBSCRIBE;

  return [
    apiClient.post(url, values, {
      ok: (content) => ({
        type: NEWSLETTER_SUBSCRIPTION_COMPONENT__SUBSCRIBE,
        payload: content.body,
      }),
      badRequest: (content) => ({
        type: NEWSLETTER_SUBSCRIPTION_COMPONENT__SUBSCRIBE_FAILED,
        payload: content.body,
      }),
    }),
  ];
}

export function changeCheckbox(event) {
  return {
    type: NEWSLETTER_SUBSCRIPTION_COMPONENT__CHANGE_CHECKBOX,
    value: event.target.checked,
  };
}

export function changeInput(event, newsletterSubscription) {
  return {
    type: NEWSLETTER_SUBSCRIPTION_COMPONENT__CHANGE_INPUT,
    value: event.target.value,
    props: newsletterSubscription,
  };
}
