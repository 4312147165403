import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import classnames from "classnames/bind";
import CompanyBadge from "stelar/components/CompanyBadge";
import ManufactumSubtitledLogo from "stelar/components/ManufactumSubtitledLogo";
import GoodThingsFromMoasteriesLogo from "stelar/components/GoodThingsFromMoasteriesLogo";
import MagazinProductLogo from "stelar/components/MagazinProductLogo";
import MagazinProductCircleLogo from "stelar/components/MagazinProductLogo/MagazinProductCircleLogo2";
import SpielgutProductLogo from "stelar/components/SpielgutProductLogo";
import MPointLogo from "stelar/components/MPointLogo";
import Paragraph from "stelar/components/Paragraph";
import Flag from "../Flag/Flag";

import s from "./Flags.module.css";

const cx = classnames.bind(s);

function Flags({
  flagNew,
  flagMagazinProduced,
  flagManufactumExclusive,
  flagFromMoasteries,
  flagManufactumProduct,
  flagExclusiveProduct,
  flagMagazinProduct,
  flagMproduct,
  flagSpielgut,
  className,
  exclusiveText = null,
  magazinText = null,
  spielgutText = null,
  topStyle = null,
  ...props
}) {
  if (
    !flagNew &&
    !flagMagazinProduced &&
    !flagManufactumExclusive &&
    !flagFromMoasteries &&
    !flagManufactumProduct &&
    !flagExclusiveProduct &&
    !flagMagazinProduct &&
    !flagMproduct &&
    !flagSpielgut
  )
    return null;
  return (
    <div className={cx("root", topStyle, className)}>
      {flagNew ||
      flagManufactumExclusive ||
      flagFromMoasteries ||
      flagManufactumProduct ||
      flagExclusiveProduct ||
      flagMagazinProduct ||
      flagMproduct ||
      flagSpielgut ? (
        <div {...props}>
          {flagNew ? (
            <div className={cx("flag")}>
              <Flag variant="new" size="small" data-test-sell-new-flag>
                {flagNew}
              </Flag>
            </div>
          ) : null}
          {flagFromMoasteries ? (
            <div className={cx("flag")}>
              <GoodThingsFromMoasteriesLogo
                subtitle="Moasteries"
                size="small"
                data-test-sell-moasteries-flag
              />
            </div>
          ) : null}
          {flagMagazinProduct ? (
            <div className={cx("flag")}>
              <MagazinProductLogo
                subtitle="Magazin"
                size="small"
                data-test-sell-magazin-flag
              />
            </div>
          ) : null}
          {flagMproduct ? (
            <div className={cx("flag")}>
              <MagazinProductCircleLogo
                subtitle="Magazin"
                size="small"
                data-test-sell-magazin-product-flag
              />
            </div>
          ) : null}
          {magazinText && <Paragraph>{magazinText}</Paragraph>}
          {flagManufactumProduct ? (
            <div className={cx("flag")}>
              <MPointLogo
                size="small"
                subtitle="Manufactum Product"
                data-test-sell-manufactum-product-flag
              />
            </div>
          ) : null}
          {flagExclusiveProduct ? (
            <div className={cx("flag")}>
              <Flag
                variant="exclusive"
                size="x-large"
                data-test-sell-exclusive-flag
              >
                {flagExclusiveProduct}
              </Flag>
            </div>
          ) : null}
          {flagManufactumExclusive ? (
            <div className={cx("flag")}>
              <ManufactumSubtitledLogo
                size="x-small"
                subtitle={flagManufactumExclusive}
                data-test-sell-manufactum-exclusive-flag
              />
            </div>
          ) : null}
          {exclusiveText && <Paragraph>{exclusiveText}</Paragraph>}
          {flagSpielgut ? (
            <div className={s.flags}>
              <SpielgutProductLogo
                size="x-large"
                subtitle="Spiel Gut Product"
                data-test-sell-spielgut-product-flag
              />
            </div>
          ) : null}
          {spielgutText && <Paragraph>{spielgutText}</Paragraph>}
        </div>
      ) : null}
      {flagMagazinProduced ? (
        <div className={s.bottom} {...props}>
          <CompanyBadge size="small" company="magazin" />
        </div>
      ) : null}
    </div>
  );
}

Flags.propTypes = {
  flagNew: PropTypes.string,
  flagMagazinProduced: PropTypes.bool,
  flagManufactumExclusive: PropTypes.string,
  flagFromMoasteries: PropTypes.bool,
  flagManufactumProduct: PropTypes.bool,
  flagExclusiveProduct: PropTypes.string,
  flagMagazinProduct: PropTypes.bool,
  flagMproduct: PropTypes.bool,
  flagSpielgut: PropTypes.string,
  className: PropTypes.string,
  exclusiveText: PropTypes.string,
  magazinText: PropTypes.string,
  spielgutText: PropTypes.string,
  topStyle: PropTypes.string,
};

export default Flags;
