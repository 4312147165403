import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import LazyLoad from "react-lazyload";
import { Button } from "stelar/components/Button";
import Text from "stelar/components/Text";
import Paragraph from "stelar/components/Paragraph";
import Price from "stelar/components/Price";
import { IMAGE_PROFILE_DEFAULTS } from "shared/utils/imageUrlBuilder";
import EnergyEfficiency from "stelar/components/EnergyEfficiency";
import ProductImage from "../ProductImage";
import s from "./HeroArticleTile.module.css";

function HeroArticleTile({
  title,
  text,
  elementImage,
  article,
  imgSizes,
  buttonText,
  onLinkClick,
  lazy,
}) {
  if (!article || !article[0]) {
    return null;
  }

  const { name, link, image, price, netWeight, energyEfficiency } = article[0];

  const normalProductImage = elementImage ? (
    <ProductImage
      image={elementImage}
      imageProfileDefault={IMAGE_PROFILE_DEFAULTS.pdsmain}
      sizes={imgSizes}
      data-test-sell-hero-image
    />
  ) : (
    <ProductImage
      image={image}
      imageProfileDefault={IMAGE_PROFILE_DEFAULTS.pdsmain}
      sizes={imgSizes}
      data-test-sell-hero-image
    />
  );
  const minimalProductImage = elementImage ? (
    <ProductImage
      image={elementImage}
      imageProfileDefault={IMAGE_PROFILE_DEFAULTS.pdsmain}
      preview
      data-test-sell-hero-image
    />
  ) : (
    <ProductImage
      image={image}
      imageProfileDefault={IMAGE_PROFILE_DEFAULTS.pdsmain}
      preview
      data-test-sell-hero-image
    />
  );

  return (
    <div className={s.root} data-test-sell-hero-tile>
      <a href={link} onClick={onLinkClick} className={s.image}>
        {lazy ? (
          <LazyLoad placeholder={minimalProductImage} once offset={1000}>
            {normalProductImage}
          </LazyLoad>
        ) : (
          normalProductImage
        )}
      </a>
      <div className={s.title}>
        <Text em tag="h3" data-test-sell-hero-name>
          <a href={link} data-prio2>
            {name}
          </a>
        </Text>
        {title && (
          <Text normal tag="h2" data-test-sell-hero-title>
            <a href={link} data-prio2>
              {title}
            </a>
          </Text>
        )}
      </div>
      {text && (
        <div className={s.text} data-test-sell-hero-text>
          <Paragraph>{text}</Paragraph>
        </div>
      )}
      {buttonText && (
        <div className={s.button}>
          {price ? (
            <div className={s.wrapper}>
              <Price
                price={price.amount}
                oldPrice={price.old}
                unit={price.base}
                additionalInfos={[netWeight, price.pfand]}
              />
              {energyEfficiency ? (
                <div className={s.eelContainer}>
                  <EnergyEfficiency variant="compact" {...energyEfficiency} />
                </div>
              ) : null}
            </div>
          ) : null}
          <Button
            prio3
            text={buttonText}
            href={link}
            onClick={onLinkClick}
            data-test-sell-hero-cta
          />
        </div>
      )}
    </div>
  );
}

HeroArticleTile.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  elementImage: PropTypes.object,
  article: PropTypes.array,
  buttonText: PropTypes.string,
  imgSizes: PropTypes.string,
  onLinkClick: PropTypes.func,
  lazy: PropTypes.bool,
};

export default HeroArticleTile;
