import {
  GLOBAL_MODAL_TYPES,
  openModal,
} from "shared/components/GlobalModal/GlobalModal";

const triggerFunc = (e) => {
  const modalContent = e.target.getAttribute("data-overlay-content-type");
  if (modalContent) {
    e.preventDefault();
    e.stopPropagation();
    openModal(GLOBAL_MODAL_TYPES.CONTENT_FRAGMENT, modalContent);
  }
};

export default triggerFunc;
