import { browserHistory, match } from "react-router"; // eslint-disable-line import/no-extraneous-dependencies

function isAbsolute(url) {
  return url.match(/https?:\/\//);
}

function isKnownRoute(routes, location, callback) {
  if (isAbsolute(location)) {
    callback(false);
  } else {
    match({ routes, location }, (error, redirectLocation, renderProps) => {
      const known = (error || redirectLocation || renderProps) !== undefined;
      callback(known);
    });
  }
}

export function openPage(routes, path, explicitly) {
  if (path === "/") {
    window.location.href = path;
    return;
  }
  isKnownRoute(routes, path, (isKnown) => {
    if (isKnown) {
      browserHistory.push(path);
    } else if (explicitly === undefined || explicitly) {
      window.location.href = path;
    }
  });
}

export function redirectToTarget(routes, result) {
  const path = result?.headers?.location || result?.config?.params?.target;
  openPage(routes, path);
}

export function createHandleClick(routes) {
  return function handleClick(e) {
    // allow open in new tab
    if (e.metaKey || e.ctrlKey) {
      return null;
    }
    const path =
      e.currentTarget.getAttribute("href") || e.target.getAttribute("href");
    // no url found
    if (!path) {
      return null;
    }
    // anchor link
    if (path.startsWith("#")) {
      return null;
    }
    e.preventDefault();
    openPage(routes, path);
    return null;
  };
}

export function isIncludedInPathObject(str, pathObject) {
  return Object.values(pathObject).includes(str);
}
