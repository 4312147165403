/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconX(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 19 19" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path d="M9.5,8.4L1.8,0.7c-0.3-0.3-0.7-0.3-1,0L0.7,0.8c-0.3,0.3-0.3,0.7,0,1l7.7,7.7l-7.7,7.7c-0.3,0.3-0.3,0.7,0,1l0.1,0.1c0.3,0.3,0.7,0.3,1,0l7.7-7.7l7.7,7.7c0.3,0.3,0.7,0.3,1,0l0.1-0.1c0.3-0.3,0.3-0.7,0-1l-7.7-7.7l7.7-7.7c0.3-0.3,0.3-0.7,0-1l-0.1-0.1c-0.3-0.3-0.7-0.3-1,0L9.5,8.4z" />
    </svg>
  );
}

export default IconX;
