import React from "react";
import classNames from "classnames/bind";
import s from "./Input.module.css";

const cx = classNames.bind(s);

type Props = {
  autoComplete?: string;
  className?: string;
  disabled?: boolean;
  error?: boolean | string;
  id: string;
  input?: object;
  meta?: object;
  readonly?: boolean;
  innerRef?: React.Ref<HTMLInputElement>;
} & React.ComponentPropsWithoutRef<"input">;

function Input({
  autoComplete,
  className,
  disabled,
  error = "",
  id,
  input,
  readonly,
  innerRef,
  ...attrs
}: Props) {
  const errorneous = error;

  let rootClasses = cx("root", className, {
    disabled,
    error: errorneous,
  });
  rootClasses = cx(rootClasses);

  return (
    <input
      aria-describedby={errorneous ? `${id}-alert` : null}
      autoComplete={autoComplete}
      className={rootClasses}
      disabled={disabled}
      id={id}
      readOnly={readonly}
      type="text"
      ref={innerRef}
      {...attrs}
      {...input}
    />
  );
}

export default Input;
