import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import HtmlContent from "stelar/components/HtmlContent";
import s from "./ElementLocation.module.css";
import ContentSpacing from "../../ContentSpacing/ContentSpacing";

function ElementLocation({
  mapUrl,
  virtualTourUrl,
  text,
  onLinkClick,
  fullWidth,
}) {
  if (!mapUrl) {
    return null;
  }

  const iframe = (url, testSelector) => (
    <iframe
      title="Virtual Tour"
      src={url}
      style={{ border: 0 }}
      width="100%"
      height="360"
      frameBorder="0"
      {...{
        [testSelector]: true,
      }}
    />
  );

  const textNode = (
    <HtmlContent
      dangerouslySetInnerHTML={{ __html: text }}
      onClick={onLinkClick}
      data-test-sell-location-text
    />
  );
  return (
    <ContentSpacing
      alignment="columns"
      flex
      fullWidth={fullWidth}
      data-test-sell-element-location
    >
      <div className={s.column}>
        {text
          ? textNode
          : iframe(mapUrl, "data-test-sell-location-google-maps")}
      </div>
      <div className={s.column}>
        {text
          ? iframe(mapUrl, "data-test-sell-location-google-maps")
          : iframe(virtualTourUrl, "data-test-sell-location-virtual-tour")}
      </div>
    </ContentSpacing>
  );
}

ElementLocation.propTypes = {
  mapUrl: PropTypes.string,
  virtualTourUrl: PropTypes.string,
  text: PropTypes.string,
  onLinkClick: PropTypes.func,
  fullWidth: PropTypes.bool,
};

ElementLocation.defaultProps = {};

export default ElementLocation;
