import update from "immutability-helper";
import { KUZ_CREDIT_CARD_PAYMENT_PAGE__LOAD_PAGE_V2 } from "../constants";

const initialState = {
  page: {
    headData: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case KUZ_CREDIT_CARD_PAYMENT_PAGE__LOAD_PAGE_V2:
      if (action.payload) {
        const newState = update(state, { $set: action.payload });
        return newState;
      }
      return state;
    default:
      return state;
  }
};
