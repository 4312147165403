import { setPageTitle } from "shared/webbridge/actions";
import backendUrl from "../utils/AseBackendUrls";
import { KUZ_CREDIT_CARD_PAYMENT_PAGE__LOAD_PAGE_V2 } from "../constants";

export function loadKuzCreditCardPaymentPage(apiClient, orderId, query) {
  const appendix = orderId ? `/${orderId}` : "";
  return apiClient.get(
    backendUrl.KUZ_CREDIT_CARD_PAYMENT_V2 + appendix,
    {
      ok: (content) => [
        {
          type: KUZ_CREDIT_CARD_PAYMENT_PAGE__LOAD_PAGE_V2,
          payload: content.body,
        },
        setPageTitle(content.body?.page?.headData?.title),
      ],
    },
    {
      httpClientOptions: {
        params: query,
      },
    }
  );
}
