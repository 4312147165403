import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import s from "./Footnotes.module.css";

function FooterFootnotes({ notes, styleElement = "element", ...props }) {
  if (!notes || notes.length === 0) {
    return null;
  }
  return (
    <ol className={s.root} {...props}>
      {notes.map((note, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className={s[styleElement]} key={`key:${index}`}>
          <span className={s.text}>{note}</span>
        </li>
      ))}
    </ol>
  );
}

FooterFootnotes.propTypes = {
  notes: PropTypes.array,
  styleElement: PropTypes.string,
};

export default FooterFootnotes;
