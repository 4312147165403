import React from "react";
import classNames from "classnames/bind";
import Text from "../Text";
import s from "./Select.module.css";

const cx = classNames.bind(s);

type Props = {
  children?: React.ReactNode;
  dateMonth?: boolean;
  error?: string;
  id?: string;
  input?: unknown;
  name?: string;
  meta?: object;
  selectOptions?: React.ComponentPropsWithoutRef<"option">[];
  skeleton?: boolean;
} & React.ComponentPropsWithoutRef<"select">;

function Select({
  children,
  dateMonth,
  error,
  id,
  input,
  name,
  meta,
  selectOptions,
  skeleton,
  ...attr
}: Props) {
  const errorneous = error;

  let optionCount;
  if (children) {
    optionCount = React.Children.count(children);
  } else if (selectOptions) {
    optionCount = selectOptions.length;
  }

  const nameAttr = name || id;

  const asSelect =
    optionCount > 1 ? (
      <select
        {...attr}
        aria-describedby={errorneous ? `${id}-alert` : null}
        className={cx("select", { dateMonth, error: errorneous, skeleton })}
        id={id}
        name={nameAttr}
      >
        {!children && selectOptions
          ? selectOptions.map((selectOption) => (
              <option
                disabled={selectOption.disabled}
                key={selectOption.value as string}
                value={selectOption.value}
              >
                {selectOption.label}
              </option>
            ))
          : null}
        {children}
      </select>
    ) : null;

  let asText;
  if (optionCount <= 1) {
    let textLabel;
    let inputValue;

    if (children) {
      const reactChildren = React.Children.toArray(children)[0];
      textLabel = reactChildren
        ? (reactChildren as React.ReactElement).props.children
        : null;
      inputValue = reactChildren
        ? (reactChildren as React.ReactElement).props.value
        : undefined;
    } else if (selectOptions[0]) {
      textLabel = selectOptions[0].label;
      inputValue = selectOptions[0].value;
    }

    asText = (
      <div className={s.textContainer}>
        {textLabel ? (
          <Text normal className={s.text}>
            {textLabel}
          </Text>
        ) : null}
        <input
          {...(attr as any)}
          name={nameAttr}
          type="hidden"
          value={inputValue}
        />
      </div>
    );
  }

  return optionCount > 1 || skeleton ? asSelect : asText;
}

Select.defaultProps = {
  error: "",
  meta: {},
};

export default Select;
