import PropTypes from "prop-types";
/* eslint-disable react/no-array-index-key */

import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";

import s from "./ProductList.module.css";
import { SkeletonProductTile } from "../ProductTile";

const cx = classnames.bind(s);

function SkeletonProductList({ fullWidth }) {
  const containerClasses = cx("container", { fullWidth });
  return (
    <div className={s.root}>
      {[0, 1, 2, 3, 0, 1, 2, 3].map((variant, key) => (
        <div className={containerClasses} key={key}>
          <SkeletonProductTile variant={variant} />
        </div>
      ))}
    </div>
  );
}

SkeletonProductList.propTypes = {
  fullWidth: PropTypes.bool,
};

SkeletonProductList.defaultProps = {};

export default SkeletonProductList;
