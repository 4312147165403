import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Headline from "stelar/components/Headline";

function ElementHeadline({ headline, overline }) {
  return (
    <Headline
      headline={headline}
      overline={overline}
      margin
      data-test-sell-element-headline
    />
  );
}

ElementHeadline.propTypes = {
  headline: PropTypes.string,
  overline: PropTypes.string,
};

ElementHeadline.defaultProps = {};

export default ElementHeadline;
