import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies

import ContentHeading from "stelar/components/ContentHeading";
import LoadingSpinner from "stelar/components/LoadingSpinner";
import Message from "stelar/components/Message";
import FeedbackRatingInput from "../../../components/FeedbackRatingInput";
import s from "./FeedbackBox.module.css";

export default function SmileyFeedback({
  structure: { headline, errorMessageTitle, errorMessageText },
  uiState,
  submitRating,
  type,
  id,
}) {
  return (
    <div className={s.feedbackContainer}>
      <ContentHeading prio2>{headline}</ContentHeading>
      {uiState?.unexpectedError && errorMessageTitle && errorMessageText && (
        <Message
          type="error"
          title={errorMessageTitle}
          text={errorMessageText}
        />
      )}
      <div className={s.ratingContainer}>
        {uiState.loading ? (
          <LoadingSpinner size="small" />
        ) : (
          <FeedbackRatingInput
            value={0}
            name="feedbackRating"
            onChange={(ratingValue) => submitRating({ ratingValue, type, id })}
          />
        )}
      </div>
    </div>
  );
}

SmileyFeedback.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  structure: PropTypes.object,
  uiState: PropTypes.object,
  submitRating: PropTypes.func,
};
