import { getVersion, round } from "../utils";

export default function transformTtfbMetric(metric) {
  return {
    name: metric.name,
    value: round(metric.value),
    rating: metric.rating,
    entries: metric.entries?.map((entry) => ({
      name: entry.name,
      entryType: entry.entryType,
      startTime: round(entry.startTime),
      duration: round(entry.duration),
      connectStart: round(entry.connectStart),
      connectEnd: round(entry.connectEnd),
      secureConnectionStart: round(entry.secureConnectionStart),
      requestStart: round(entry.requestStart),
      responseStart: round(entry.responseStart),
      responseEnd: round(entry.responseEnd),
      unloadEventStart: round(entry.unloadEventStart),
      unloadEventEnd: round(entry.unloadEventEnd),
      domInteractive: round(entry.domInteractive),
      domContentLoadedEventStart: round(entry.domContentLoadedEventStart),
      domContentLoadedEventEnd: round(entry.domContentLoadedEventEnd),
      domComplete: round(entry.domComplete),
      type: entry.type,
      redirectCount: round(entry.redirectCount),
    })),
    version: getVersion(metric),
    navigationType: metric.navigationType,
  };
}
