export function categoryIdsInArray(data) {
  if (data) {
    return data
      .map((item) => {
        // return only categoryIds, pls extend BE with categoryId
        const regex = new RegExp("[0-9]+"); // eslint-disable-line prefer-regex-literals
        if (item) {
          const categoryId = item?.link.match(regex);
          if (categoryId) {
            return categoryId.toString();
          }
        }
        return null;
      })
      .filter((item) => typeof item === "string");
  }
  return [];
}
