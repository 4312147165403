import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Text from "stelar/components/Text";
import s from "./Link.module.css";

const Link = ({ href, children, onClick, target }) => (
  <Text normal className={s.text}>
    <a href={href} onClick={onClick} target={target} data-prio2>
      {children}
    </a>
  </Text>
);

Link.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  target: PropTypes.string,
};

Link.defaultProps = {};

export default Link;
