// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import IconBubbleCheck from "stelar/components/Icon/IconBubbleCheck";
import IconCheck from "stelar/components/Icon/IconCheck";
// classnames
import classNames from "classnames/bind";
// ase
import s from "./CheckoutProgressBar.module.css";

const cx = classNames.bind(s);

const basketIcon = (
  <svg viewBox="0 0 36 36">
    <path d="M21.9,11c-0.3-2.7-1.3-4.7-3.9-4.7c-2.5,0-3.6,2-3.9,4.7c1.2,0.2,2.6,0.3,3.9,0.3C19.4,11.3,20.7,11.2,21.9,11 z" />
    <path d="M25,11.6c-0.6,0.2-1.2,0.3-1.9,0.5c0,0.2,0,0.5,0,0.7c0,0.7,0,1.4-0.1,2.1h-1.2c0.1-0.7,0.1-1.4,0.1-2.2 c0-0.1,0-0.3,0-0.4c-1.2,0.2-2.5,0.3-3.9,0.3c-1.4,0-2.8-0.1-4-0.3c0,0.1,0,0.3,0,0.4c0,0.8,0.1,1.5,0.2,2.2H13 c-0.1-0.7-0.1-1.4-0.1-2.1c0-0.2,0-0.5, 0-0.7c-0.6-0.1-1.2-0.3-1.8-0.4c-0.4,0.7-1.7,3.6-2.1,6.4c-0.6,3.7,0.6,6.3,2.5,7.8 c0.7-0.9,3.4-1.5,6.5-1.5c3.2,0,6,0.7,6.6,1.6c1.8-1.5,2.9-4,2.5-7.9C26.8,15.1,25.3,12.1,25,11.6z" />
    <path d="M18,0.5C8.3,0.5,0.5,8.3,0.5,18S8.3,35.5,18,35.5S35.5,27.7,35.5,18S27.7,0.5,18,0.5z M18,29.2 c-5.6,0-11.4-3.2-10.1-11.6c0.5-3.2,1.9-6.6,2.4-7.4c0.8,0.3,1.7,0.5,2.7,0.6c0.4-3.3,1.7-5.7,5-5.7c3.4,0,4.7,2.4,5,5.7 c1-0.2,2-0.4,2.8-0.7c0.3,0.6,2.1,4.1,2.4,7.4C29,26.5,23.6,29.2,18,29.2z" />
  </svg>
);

function CheckoutProgressBar({
  activeStepLabel,
  basketLabel,
  enableUpToStep,
  steps,
  navigate,
}) {
  // eslint-disable-next-line react/prop-types
  function checkoutProgressBarStep({ label, link }, index) {
    // eslint-disable-next-line eqeqeq
    const active = index + 1 == enableUpToStep;
    const done = index + 1 < enableUpToStep;
    const enabled = index < enableUpToStep && enableUpToStep < 5;

    const classes = cx("li", {
      liDisabled: !enabled,
    });

    const icon = done ? <IconBubbleCheck large /> : index + 1;

    const text = active ? (
      <strong className={cx("text", "textActive")}>
        <span className={s.a11yHelper}>{activeStepLabel}:</span>
        {label}
      </strong>
    ) : (
      <span className={cx("text", { textDisabled: !enabled })}>{label}</span>
    );

    // eslint-disable-next-line no-nested-ternary
    const state = active ? "active" : done ? "done" : "disabled";

    const content = enabled ? (
      <a href={link} className={s.liWrap} onClick={navigate}>
        <span
          className={cx("icon", {
            iconDone: done,
            iconNumber: !done,
          })}
        >
          {icon}
        </span>
        {text}
      </a>
    ) : (
      <span className={cx("liWrap")} aria-disabled>
        <span className={cx("icon", "iconDisabled", "iconNumber")}>{icon}</span>
        {text}
      </span>
    );

    return (
      <li
        className={classes}
        key={`${index}`}
        data-test-ase-progressbar-step={`${index + 1}`}
        data-test-ase-progressbar-state={state}
      >
        {content}
      </li>
    );
  }

  return (
    <ol className={s.root}>
      <li className={cx("li", "liFirst")}>
        <a href="/warenkorb" onClick={navigate} className={s.liWrap}>
          <span className={cx("icon", "iconFirst")}>{basketIcon}</span>
          <span className={s.text}>{basketLabel}</span>
        </a>
      </li>
      {steps.map(checkoutProgressBarStep)}
      <li className={cx("li", "liLast", "liDisabled")}>
        <span className={cx("liWrap")}>
          <span
            className={cx("icon", "iconLast", {
              iconDisabled: enableUpToStep < 4,
            })}
          >
            <IconCheck large />
          </span>
        </span>
      </li>
    </ol>
  );
}

CheckoutProgressBar.propTypes = {
  activeStepLabel: PropTypes.string.isRequired,
  basketLabel: PropTypes.string.isRequired,
  enableUpToStep: PropTypes.string.isRequired,
  navigate: PropTypes.func,
  steps: PropTypes.array.isRequired,
};

export default CheckoutProgressBar;
