import update from "immutability-helper";
import {
  initialState,
  LOAD_SEARCH_FAILED_REQUEST,
  LOAD_SEARCH_STARTED_REQUEST,
  LOAD_SEARCH_SUCCESS_REQUEST,
} from "./constants";

const search = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SEARCH_STARTED_REQUEST:
      return update(state, {
        loading: { $set: true },
        uiFeedback: {
          $set: { loading: true },
        },
      });
    case LOAD_SEARCH_SUCCESS_REQUEST:
      return update(state, {
        data: { $set: action.payload },
        loading: { $set: false },
        uiFeedback: {
          $set: { ratingSent: false },
        },
      });
    case LOAD_SEARCH_FAILED_REQUEST:
      return update(state, {
        data: { $set: {} },
        loading: { $set: false },
        uiFeedback: {
          $set: {
            loading: false,
            ratingSent: false,
            textSent: false,
            unexpectedError: true,
          },
        },
      });
    default:
      return state;
  }
};

export default search;
