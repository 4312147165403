import update from "immutability-helper";
import {
  initialState,
  LOAD_CATEGORY_STARTED,
  LOAD_CATEGORY_SUCCESS,
} from "./constants";

const category = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CATEGORY_STARTED: {
      const { key } = action.payload;
      const $splice = [];

      // remove key if it exists
      if (state.keys.includes(key)) {
        $splice.push([state.keys.indexOf(key), 1]);
      }

      // add new key to the front
      $splice.push([0, 0, key]);

      // evtl. remove over limit key
      $splice.push([state.maxKeys, 1]);

      return update(state, { keys: { $splice } });
    }
    case LOAD_CATEGORY_SUCCESS: {
      const data = {};
      // write data if key is present
      const { key, currentLocation } = action.payload;
      if (state.keys.includes(key)) {
        data[key] = { $set: action.payload.jsonData };
      }
      // cleanup: remove data from keys that don't exist any more
      Object.keys(state.data).forEach((k) => {
        if (!state.keys.includes(k)) {
          data[k] = { $set: undefined };
        }
      });

      return update(state, {
        data,
        currentLocation: { $set: currentLocation },
      });
    }

    default:
      return state;
  }
};

export default category;
