import React from "react";
import classNames from "classnames/bind";

import Text from "../Text";
import s from "./Checkbox.module.css";

const cx = classNames.bind(s);

type Props = {
  className?: string;
  error?: boolean | string;
  id?: string;
  input?: object;
  label: React.ReactNode;
  meta?: object;
  name?: string;
  skeleton?: boolean;
  collapseText?: string;
  expandText?: string;
  expanded?: boolean;
  autoexpand?: boolean;
  collExpClassName?: string;
  labelClassName?: string;
  showScrollbars?: boolean;
  scrollbarHeight?: number;
} & React.ComponentPropsWithoutRef<"input">;

function Checkbox({
  className,
  error,
  id,
  input,
  label,
  meta,
  name,
  skeleton,
  collapseText,
  expandText,
  expanded,
  autoexpand,
  collExpClassName,
  labelClassName,
  showScrollbars,
  scrollbarHeight,
  ...attr
}: Props) {
  function hasValue(str) {
    return str && !/^\s*$/.test(str);
  }
  const nameAttr = name || id;
  const collapsible = hasValue(expandText);
  const errorneous = error;

  const classNamesString = cx(
    cx("root", {
      collapsible,
      autoexpand,
    }),
    className
  );

  const collapseId = `${id}_collapse`;
  const collapseInput = collapsible && (
    <input
      className={cx("root", "collapseCheckbox")}
      id={collapseId}
      name={collapseId}
      onChange={() => {}}
      defaultChecked={expanded || false}
      type="checkbox"
    />
  );

  const collapsibleLabels = collapsible && (
    <>
      <span className={cx("root", "flexBreak")} />
      <span>&nbsp;</span>
      {hasValue(collapseText) && (
        <label
          htmlFor={collapseId}
          className={cx(cx("root", "collapseText"), collExpClassName)}
        >
          {collapseText}
        </label>
      )}
      <label
        htmlFor={collapseId}
        className={cx(cx("root", "expandText"), collExpClassName)}
      >
        {expandText}
      </label>
    </>
  );

  const checkboxElement = (
    <input
      className={cx("input", { errorneous })}
      id={id}
      name={nameAttr}
      onChange={() => {}}
      type="checkbox"
      {...attr}
      {...input}
    />
  );

  const labelElement = (
    <label
      id={`${id}_label`}
      className={cx(cx("label", { collapsible }), labelClassName)}
      htmlFor={id}
    >
      <Text normal skeleton={skeleton}>
        {label}
      </Text>
    </label>
  );

  const collapsableLayout = (
    <div className={classNamesString}>
      {checkboxElement}&nbsp;{collapseInput}
      {labelElement}
      {collapsibleLabels}
    </div>
  );

  const scrollableLayout = (
    <div
      className={cx("root", "showScrollbars")}
      style={{ maxHeight: scrollbarHeight || "10rem" }}
    >
      {checkboxElement}&nbsp;{labelElement}
    </div>
  );

  return showScrollbars ? scrollableLayout : collapsableLayout;
}

Checkbox.defaultProps = {
  error: "",
  meta: {},
};

export default Checkbox;
