import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import imageSizes from "shared/utils/imageSizes";
import s from "./ElementSingleTeaser.module.css";
import ContentTeasers from "../../Teasers/ContentTeasers";
import ContentSpacing from "../../ContentSpacing";

function ElementSingleTeaser({ fullWidth, ...props }) {
  const imgSizes = imageSizes({
    xxxl: "474px",
    xxl: "31.6vw",
    xl: fullWidth ? "31.2vw" : "27.2vw",
    l: fullWidth ? "30.7vw" : "34.8vw",
    m: "46vw",
    default: "92vw",
  });
  return (
    <ContentSpacing
      alignment="narrowcolumns"
      className={s.spacing}
      fullWidth={fullWidth}
      data-test-sell-element-single-teaser
    >
      <ContentTeasers
        {...props}
        type="one"
        fullWidth={fullWidth}
        imgSizes={imgSizes}
        customStyle="single"
      />
    </ContentSpacing>
  );
}

ElementSingleTeaser.propTypes = {
  fullWidth: PropTypes.bool,
};

export default ElementSingleTeaser;
