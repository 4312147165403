import PropTypes from "prop-types";

export default {
  placeholder: PropTypes.string,
  buttonText: PropTypes.string,
  link: PropTypes.string,
  onSubmit: PropTypes.func,
  words: PropTypes.array,
  wordsSectionHeading: PropTypes.string,
  products: PropTypes.array,
  productsSectionHeading: PropTypes.string,
  onChange: PropTypes.func,
  onSuggest: PropTypes.func,
  onLinkClick: PropTypes.func,
  onProductSuggest: PropTypes.func,
  onClickSuggestion: PropTypes.func,
  onClearSuggests: PropTypes.func,
  onClearInput: PropTypes.func,
  searchString: PropTypes.string,
  typedSearchString: PropTypes.string,
  focusedSuggestion: PropTypes.number,
  focusedProduct: PropTypes.number,
  onArrowNavigation: PropTypes.func,
  onHoverSuggestion: PropTypes.func,
  onLeaveSuggestion: PropTypes.func,
  productSkeleton: PropTypes.bool,
  eventTracking: PropTypes.object,
};
