import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import {
  CookieBanner,
  CookieBannerLayout,
} from "../../components/CookieBanner";

function CookieBannerFragment({ data, open, uiState, cookieBannerActions }) {
  const { onConfirm, onNeedToShow, onOptOut, onOptIn, onOptInByClick } =
    cookieBannerActions;

  return (
    <CookieBannerLayout
      {...uiState}
      onNeedToShow={onNeedToShow}
      open={open}
      banner={
        data && (
          <CookieBanner
            {...data}
            onConfirm={onConfirm}
            onOptOut={onOptOut}
            onOptIn={onOptIn}
            onOptInByClick={onOptInByClick}
          />
        )
      }
    />
  );
}

CookieBannerFragment.propTypes = {
  data: PropTypes.object,
  open: PropTypes.bool,
  uiState: PropTypes.object,
  cookieBannerActions: PropTypes.object,
};

export default CookieBannerFragment;
