import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import LazyLoad from "react-lazyload";
import RatioImageInner from "./RatioImageInner";

function RatioImage({ preview, lazy, hasLoaded, isInformative, ...props }) {
  const { image, imageProfileDefault } = props;

  const hasPreviewPreset =
    image?.previewPreset || imageProfileDefault?.previewProfile;
  const minimal = hasPreviewPreset ? (
    <RatioImageInner
      {...props}
      image={{ ...image, ...{ presets: null, fallbackPreset: null } }}
      onImageLoad={null}
    />
  ) : null;

  if (preview) {
    return minimal;
  }
  const complete = <RatioImageInner isInformative={isInformative} {...props} />;

  return lazy && minimal ? (
    <LazyLoad placeholder={minimal} once offset={1000} throttle={250}>
      {complete}
    </LazyLoad>
  ) : (
    complete
  );
}

RatioImage.propTypes = {
  preview: PropTypes.bool,
  lazy: PropTypes.bool,
  isInformative: PropTypes.bool,
  ...RatioImageInner.propTypes,
};

export default RatioImage;
