// vendor
import update from "immutability-helper";
// ase
import {
  CUSTOMER_ACCOUNT_PAGE__CHANGE_ADDRESS_SUBMIT_FAILED,
  CUSTOMER_ACCOUNT_PAGE__CHANGE_SELECTION,
  CUSTOMER_ACCOUNT_PAGE__CHANGE_STANDARD,
  CUSTOMER_ACCOUNT_PAGE__DELETE_ADDRESS,
  CUSTOMER_ACCOUNT_PAGE__DO_CHANGE_REQUEST,
  CUSTOMER_ACCOUNT_PAGE__LOAD_CUSTOMER_ACCOUNT,
  CUSTOMER_ACCOUNT_PAGE__LOAD_PAGE,
  CUSTOMER_ACCOUNT_PAGE__NEW_ADDRESS_SUBMIT_FAILED,
  CUSTOMER_ACCOUNT_PAGE__NEWSLETTER_UNSUBSCRIBE_SHOP_ADDR_FAILED,
  CUSTOMER_ACCOUNT_PAGE__NEWSLETTER_UNSUBSCRIBE_SHOP_ADDR_SUCCEEDED,
  CUSTOMER_ACCOUNT_PAGE__TOGGLE_ACCORDION,
  CUSTOMER_ACCOUNT_PAGE__TOGGLE_DELIVERY_MODE,
  CUSTOMER_ACCOUNT_PAGE__UPDATE_FORM,
  CUSTOMER_ACCOUNT_PAGE__CHANGE_ADDRESS_SUBMIT,
  CUSTOMER_ACCOUNT_PAGE__NEW_ADDRESS_SUBMIT,
  CUSTOMER_ACCOUNT_PAGE__POSTALADVERTISE_UNSUBSCRIBE_FAILED,
  CUSTOMER_ACCOUNT_PAGE__POSTALADVERTISE_UNSUBSCRIBE_SUCCEEDED,
} from "../constants";
// component
import removeItemsByNamesFromValues from "../../util/reduxFormUtils/removeItemsByNamesFromValues";
import initialState from "./initialState";

const customerAccountPage = (state = initialState, action) => {
  let actionPayload;
  let openedItem;
  switch (action.type) {
    case CUSTOMER_ACCOUNT_PAGE__NEWSLETTER_UNSUBSCRIBE_SHOP_ADDR_FAILED:
      return update(state, {
        newsletter: { $set: action.payload },
      });
    case CUSTOMER_ACCOUNT_PAGE__NEWSLETTER_UNSUBSCRIBE_SHOP_ADDR_SUCCEEDED:
      return update(state, {
        newsletter: { $set: action.payload },
      });
    case CUSTOMER_ACCOUNT_PAGE__POSTALADVERTISE_UNSUBSCRIBE_SUCCEEDED:
    case CUSTOMER_ACCOUNT_PAGE__POSTALADVERTISE_UNSUBSCRIBE_FAILED:
      return update(state, {
        postalAdvertise: { $set: action.payload },
      });
    case CUSTOMER_ACCOUNT_PAGE__LOAD_CUSTOMER_ACCOUNT:
      actionPayload = { ...action.payload };
      actionPayload.submitStarted = [];
      return update(state, {
        $merge: actionPayload,
      });
    case CUSTOMER_ACCOUNT_PAGE__TOGGLE_ACCORDION:
      if (typeof state.openedItem === "string") {
        openedItem = [state.openedItem];
      } else {
        openedItem = state.openedItem || [];
      }
      if (openedItem.includes(action.itemToOpen)) {
        return update(state, {
          openedItem: {
            $set: openedItem.filter((item) => item !== action.itemToOpen),
          },
        });
      }
      return update(state, {
        openedItem: {
          $set: [...openedItem, action.itemToOpen],
        },
      });
    case CUSTOMER_ACCOUNT_PAGE__TOGGLE_DELIVERY_MODE:
      return update(state, { deliveryMode: { $set: action.deliveryMode } });
    case CUSTOMER_ACCOUNT_PAGE__LOAD_PAGE:
      actionPayload = { ...action.payload };
      // Ist bereits eine structure vorhanden, ignorieren wir die vom BE,
      // weil sonst die Änderungen im Store vom Besucher verloren gehen.
      // (passiert, wenn er history.back o.Ä. benutzt)
      if (
        state.newDeliveryAddress &&
        state.newDeliveryAddress.deliveryAddress &&
        state.newDeliveryAddress.deliveryAddress.structure &&
        state.newDeliveryAddress.deliveryAddress.structure.length
      ) {
        actionPayload.newDeliveryAddress.deliveryAddress.structure =
          state.newDeliveryAddress.deliveryAddress.structure;
      }
      return update(state, { $set: actionPayload });
    case CUSTOMER_ACCOUNT_PAGE__NEW_ADDRESS_SUBMIT:
      if (state.submitStarted.includes("newDeliveryAddress")) {
        return state;
      }
      return update(state, {
        submitStarted: {
          $push: ["newDeliveryAddress"],
        },
      });
    case CUSTOMER_ACCOUNT_PAGE__NEW_ADDRESS_SUBMIT_FAILED:
      return update(state, {
        newDeliveryAddress: {
          deliveryAddress: {
            addressSuggest: { $set: action.payload.addressSuggest },
            message: { $set: action.payload.message },
            values: { $set: action.payload.values },
          },
        },
        submitStarted: {
          $set: state.submitStarted.filter((v) => v !== "newDeliveryAddress"),
        },
      });
    case CUSTOMER_ACCOUNT_PAGE__CHANGE_ADDRESS_SUBMIT:
      if (state.submitStarted.includes("changeDeliveryAddress")) {
        return state;
      }
      return update(state, {
        submitStarted: {
          $push: ["changeDeliveryAddress"],
        },
      });
    case CUSTOMER_ACCOUNT_PAGE__CHANGE_ADDRESS_SUBMIT_FAILED:
      if (Array.isArray(action.payload)) {
        return update(state, {
          changeDeliveryResponse: {
            deliveryAddress: {
              values: { $set: action.payload },
            },
          },
          submitStarted: {
            $set: state.submitStarted.filter(
              (v) => v !== "changeDeliveryAddress"
            ),
          },
        });
      }
      return update(state, {
        page: {
          globalMessage: { $set: action.payload.globalMessage },
        },
        changeDeliveryResponse: {
          deliveryAddress: {
            addressSuggest: { $set: action.payload.addressSuggest },
            message: { $set: action.payload.message },
            values: { $merge: action.payload.values },
          },
        },
        submitStarted: {
          $set: state.submitStarted.filter(
            (v) => v !== "changeDeliveryAddress"
          ),
        },
      });
    case CUSTOMER_ACCOUNT_PAGE__CHANGE_SELECTION:
      return update(state, { selectedOption: { $set: `${action.value}` } });
    case CUSTOMER_ACCOUNT_PAGE__DELETE_ADDRESS:
      return update(state, {
        deliveryAddresses: {
          storedAddresses: { $set: action.payload.storedAddresses },
          message: { $set: action.payload.message },
        },
      });
    case CUSTOMER_ACCOUNT_PAGE__UPDATE_FORM:
      // remove postalCode value if a country has none
      if (action.payload.valuesToRemove) {
        removeItemsByNamesFromValues(
          action.payload.valuesToRemove,
          state[action.addressStatePath].deliveryAddress.values
        );
      }

      return update(state, {
        [action.addressStatePath]: {
          deliveryAddress: {
            structure: { $set: action.payload.structure },
            hint: { $set: action.payload.hint },
          },
          submitButton: { link: { $set: action.payload.updatedFormSubmitUrl } },
        },
      });

    case CUSTOMER_ACCOUNT_PAGE__DO_CHANGE_REQUEST:
      return update(state, {
        changeDeliveryResponse: {
          $set: action.payload,
        },
      });
    case CUSTOMER_ACCOUNT_PAGE__CHANGE_STANDARD:
      return update(state, {
        deliveryAddresses: {
          storedAddresses: { $set: action.payload.storedAddresses },
          message: { $set: action.payload.message },
        },
      });

    default:
      return state;
  }
};

export default customerAccountPage;
