import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import ListInline from "stelar/components/ListInline";
import ListInlineItem from "stelar/components/ListInlineItem";
import Link from "../Link";
import List from "../List";
import ListElement from "../ListElement";

function createLink(element) {
  if (element) {
    return <Link href={element.link}>{element.name}</Link>;
  }
  return null;
}

const ListElementLink = ({ element }) =>
  element ? (
    <ListElement>
      <Link href={element.link}>{element.name}</Link>
    </ListElement>
  ) : null;

function LegalLinks({ country, agb, datenschutz, impressum }) {
  // this differences are based on ticket SELL-4571
  return country &&
    country.current &&
    country.current.icon &&
    (country.current.icon === "com" ||
      country.current.icon === "uk" ||
      country.current.icon === "nl" ||
      country.current.icon === "be" ||
      country.current.icon === "lu" ||
      country.current.icon === "dk") ? (
    <List>
      <ListElementLink element={agb} />
      <ListElementLink element={datenschutz} />
      <ListElementLink element={impressum} />
    </List>
  ) : (
    <ListInline>
      <ListInlineItem>{createLink(agb)}</ListInlineItem>
      <ListInlineItem>{createLink(datenschutz)}</ListInlineItem>
      <ListInlineItem>{createLink(impressum)}</ListInlineItem>
    </ListInline>
  );
}

LegalLinks.propTypes = {
  agb: PropTypes.object,
  country: PropTypes.object,
  datenschutz: PropTypes.object,
  impressum: PropTypes.object,
};

ListElementLink.propTypes = {
  element: PropTypes.object,
};

export default LegalLinks;
