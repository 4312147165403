import { connect } from "react-redux";
import prefetch from "shared/ssrPrefetch/prefetch";
import { getClientsideFetchLocals } from "shared/ssrPrefetch/useFetchLocalsStore";
import { loadEmailChangeFragmentData, submitChangeEmail } from "./actions";
import EmailChangeFragment from "./components/EmailChangeFragment";

const redial = {
  fetch: ({ apiClient, dispatch }) =>
    dispatch(loadEmailChangeFragmentData(apiClient)),
};

const mapDispatchToProps = (dispatch) => {
  const { apiClient } = getClientsideFetchLocals();
  return {
    submitFormData: (formValues) => {
      dispatch(submitChangeEmail(formValues, apiClient));
    },
  };
};

function mapStateToProps(state) {
  return {
    ...state.emailChangeFragment.data,
    ...state.emailChangeFragment.uiState,
  };
}

export const stateKey = "ASE_INITIAL_STATE_EMAILCHANGE_FRAGMENT";

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(prefetch(EmailChangeFragment, redial));
