import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Heading from "stelar/components/Heading";
import Text from "stelar/components/Text";
import AdvantageList from "stelar/components/AdvantageList";
import ListItem from "stelar/components/ListItem";
import { AseNewsletterSubscriptionFragment } from "shared/components/registry";
import { NEWSLETTER_SKELETON } from "./constants";
import s from "./ElementNewsletterSignOnPage.module.css";

function ElementNewsletterSignOnPage({
  headline,
  image,
  advantages,
  categoryId,
}) {
  const skeletonData = NEWSLETTER_SKELETON;
  const advantagesList = advantages.items || skeletonData.advantages.items;

  return headline ? (
    <div className={s.root}>
      <div className={s.inner}>
        <div className={s.textWrapper}>
          <div className={s.textPadding}>
            <Heading prio0 skeleton={!headline} data-test-sell-title>
              {headline || skeletonData.headline}
            </Heading>
            <div className={s.listTitle}>
              <Text strong skeleton={!advantages.headline}>
                {advantages.headline || skeletonData.advantages.headline}
              </Text>
            </div>
            <AdvantageList checked>
              {advantagesList.map((item) => (
                <ListItem key={item} skeleton={!advantages.items}>
                  {item}
                </ListItem>
              ))}
            </AdvantageList>
            <div className={s.newsletterWrapper}>
              <AseNewsletterSubscriptionFragment
                layout="page"
                point="content"
                categoryId={categoryId}
              />
            </div>
          </div>
        </div>
        <div className={s.imageWrapper}>
          <div
            className={s.imagePreview}
            style={{ backgroundImage: `url(${image.imagePreview})` }}
          />
          <div
            className={s.image}
            style={{ backgroundImage: `url(${image.imageUrl})` }}
          />
          <div
            className={s.highResImage}
            style={{ backgroundImage: `url(${image.highResImageUrl})` }}
          />
        </div>
      </div>
    </div>
  ) : null;
}

ElementNewsletterSignOnPage.propTypes = {
  headline: PropTypes.string,
  image: PropTypes.object,
  advantages: PropTypes.object,
  categoryId: PropTypes.string,
};

ElementNewsletterSignOnPage.defaultProps = {
  advantages: {},
  image: {},
};

export default ElementNewsletterSignOnPage;
