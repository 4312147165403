/* eslint-disable no-console */

export default () => {
  const appReady = () => {
    const elems = document.querySelectorAll('meta[name="sell:testable"]');
    return Array.prototype.every.call(
      elems,
      (el) => el.getAttribute("content") === "true"
    );
  };

  const testability = {
    whenStable: (callback) => {
      const test = () => {
        if (appReady()) {
          console.log("whenStable", "ready!");
          callback();
        } else {
          console.log("whenStable", "not ready, trying later ...");
          setTimeout(test, 20);
        }
      };
      test();
    },
  };

  window.getAllAngularTestabilities = () => [testability];
  window.getAngularTestability = () => testability;
};
