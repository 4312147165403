// vendor
import update from "immutability-helper";
// ase
import removeItemsByNamesFromValues from "../../util/reduxFormUtils/removeItemsByNamesFromValues";
import {
  CATALOG_ORDER_FRAGMENT__LOAD_DATA,
  CATALOG_ORDER_FRAGMENT__ORDER_CATALOGS,
  CATALOG_ORDER_FRAGMENT__SHOW_CONFIRMATION,
  CATALOG_ORDER_FRAGMENT__UPDATE_FORM,
  CATALOG_ORDER_FRAGMENT,
  CATALOG_ORDER_FRAGMENT__CACHE_FORM_VALUE,
  CATALOG_ORDER_FRAGMENT__CACHE_FORM_CLEAR,
} from "../../pages/constants";
// component
import initialState from "./initialState";

function catalogOrderFragment(state = initialState, action) {
  switch (action.type) {
    case CATALOG_ORDER_FRAGMENT__LOAD_DATA: {
      return update(state, {
        data: { $set: action.payload },
        virtual: { skeleton: { $set: false }, step: { $set: "form" } },
      });
    }
    case CATALOG_ORDER_FRAGMENT__CACHE_FORM_VALUE: {
      return update(state, {
        formCache: { $set: action.values },
      });
    }
    case CATALOG_ORDER_FRAGMENT__CACHE_FORM_CLEAR: {
      return update(state, {
        formCache: { $set: {} },
      });
    }
    case CATALOG_ORDER_FRAGMENT__ORDER_CATALOGS: {
      if (Array.isArray(action.payload)) {
        let errorMessage = null;
        const errors = action.payload;
        const selectedCatalogsForm = errors.filter(
          (formRow) => formRow.name === "selectedCatalogs"
        );
        if (selectedCatalogsForm.length > 0) {
          errorMessage = selectedCatalogsForm[0].error;
        }
        return update(state, {
          data: {
            errorMessage: { $set: errorMessage },
            deliveryAddress: { values: { $set: errors } },
          },
        });
      }
      return update(state, {
        data: {
          errorMessage: { $set: action.payload.values[0].error },
          globalMessage: { $set: action.payload.globalMessage },
          message: { $set: action.payload.message },
          deliveryAddress: {
            addressSuggest: { $set: action.payload.addressSuggest },
            message: { $set: action.payload.message },
            values: { $merge: action.payload.values },
          },
        },
      });
    }
    case CATALOG_ORDER_FRAGMENT__SHOW_CONFIRMATION: {
      return update(state, {
        data: { $set: action.payload },
        virtual: { step: { $set: "confirmation" } },
      });
    }
    case CATALOG_ORDER_FRAGMENT__UPDATE_FORM: {
      const newState = {
        data: {
          [action.addressStatePath]: {
            structure: { $set: action.payload.structure },
          },
        },
      };
      // remove postalCode value if a country has none
      if (action.payload.valuesToRemove) {
        removeItemsByNamesFromValues(
          action.payload.valuesToRemove,
          state.data[action.addressStatePath].values
        );
      }
      return update(state, newState);
    }
    case CATALOG_ORDER_FRAGMENT:
      return update(state, { $merge: action.payload });
    default:
      return state;
  }
}

export default catalogOrderFragment;
