import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import List from "../List";
import Headline from "../Headline";
import ListElement from "../ListElement";
import Link from "../Link";

function createLink(element, index) {
  if (element) {
    return (
      <ListElement>
        <Link href={element.link} key={`ListElement:${index}`}>
          {element.name}
        </Link>
      </ListElement>
    );
  }
  return null;
}

function CompanyLinks({
  aboutUs,
  brotundbutter,
  compliance,
  hausnachrichten,
  headline,
  magazin,
  presse,
  sitemap,
  stellenangebote,
}) {
  const companyElementsInArray = [
    aboutUs,
    stellenangebote,
    compliance,
    hausnachrichten,
    presse,
    brotundbutter,
    magazin,
    sitemap,
  ];
  return (
    <div>
      <Headline>{headline}</Headline>
      <List>
        {companyElementsInArray.map((element, index) =>
          createLink(element, index)
        )}
      </List>
    </div>
  );
}

CompanyLinks.propTypes = {
  aboutUs: PropTypes.object,
  brotundbutter: PropTypes.object,
  compliance: PropTypes.object,
  hausnachrichten: PropTypes.object,
  headline: PropTypes.string,
  magazin: PropTypes.object,
  presse: PropTypes.object,
  sitemap: PropTypes.object,
  stellenangebote: PropTypes.object,
};

export default CompanyLinks;
