import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import s from "./ContentTeasers.module.css";
import ContentTeaser from "../ContentTeaser";

const cx = classnames.bind(s);

function ContentTeasers({
  teasers,
  type,
  fullWidth,
  imgSizes,
  onLinkClick,
  onImageLoad,
  loadedImages = {},
  lazy,
  customStyle,
}) {
  return (
    <ul
      className={cx("root", {
        fullWidth,
        indented: !fullWidth,
        [`teasers-${type}`]: true,
      })}
      data-test-sell-element-teasers
    >
      {teasers &&
        teasers.map((teaser) => (
          <li className={s.teaser} key={teaser.id}>
            <ContentTeaser
              variant={type === "one" ? "side-by-side" : "stacked"}
              {...teaser}
              imgSizes={imgSizes}
              onLinkClick={onLinkClick}
              onImageLoad={onImageLoad}
              loadedImages={loadedImages}
              lazy={lazy}
              customStyle={customStyle}
            />
          </li>
        ))}
    </ul>
  );
}

ContentTeasers.propTypes = {
  customStyle: PropTypes.string,
  teasers: PropTypes.array,
  type: PropTypes.oneOf(["one", "two", "three", "four"]),
  fullWidth: PropTypes.bool,
  lazy: PropTypes.bool,
  imgSizes: PropTypes.string,
  onLinkClick: PropTypes.func,
  onImageLoad: PropTypes.func,
  loadedImages: PropTypes.object,
};

export default ContentTeasers;
