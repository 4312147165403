import React from "react";
import classNames from "classnames/bind";
import s from "./AccordionButton.module.css";

const cx = classNames.bind(s);

type Props = {
  disabled?: boolean;
  small?: boolean;
  iconPosition?: "left" | "right";
  light?: boolean;
  open?: boolean;
  icon?: React.ReactNode;
  onClick?: (...args: unknown[]) => unknown;
  title?: React.ReactNode;
} & Pick<React.ComponentPropsWithoutRef<"button">, "id" | "tabIndex">;

/**
 * AccordionButton
 */
function AccordionButton({
  icon,
  title,
  iconPosition,
  onClick,
  disabled,
  light,
  open,
  small,
  ...props
}: Props) {
  const buttonTabIndex = !disabled ? 0 : -1;

  return (
    <button
      className={cx("button", {
        [`icon-${iconPosition}`]: true,
        light,
        open,
        small,
      })}
      onClick={onClick}
      tabIndex={buttonTabIndex}
      type="button"
      disabled={disabled}
      {...props}
    >
      <span className={s.icon}>{icon}</span>
      <span className={s.title}>{title}</span>
    </button>
  );
}

export default AccordionButton;
