import React from "react";
import Input from "./Input";

type Props = {
  autoComplete?: string;
  error?: boolean | string;
} & React.ComponentPropsWithoutRef<typeof Input>;

/**
 * InputDateMonthTxt
 * the month of a date as free text input
 */
function InputDateMonthTxt({
  autoComplete = "bday-month",
  error = "",
  ...attrs
}: Props) {
  return (
    <Input
      autoComplete={autoComplete}
      className="dateMonth"
      error={error}
      maxLength={2}
      size={2}
      type="tel"
      {...attrs}
    />
  );
}

export default InputDateMonthTxt;
