import { connect } from "react-redux";
import update from "immutability-helper";
import NewsletterSubscription from "./components/NewsletterSubscription";
import { changeCheckbox, changeInput } from "./actions";

const mapDispatchToProps = (dispatch, props) => ({
  changeCheckboxFunc: (event) => dispatch(changeCheckbox(event)),
  changeInputFunc: (event) =>
    dispatch(changeInput(event, props.newsletterSubscription.email)),
});

function mapStateToProps(state, props) {
  const merged = update(props.newsletterSubscription || {}, {
    email: { $merge: state.newsletterSubscription.email },
    datenschutz: { $merge: state.newsletterSubscription.datenschutz },
    successMessage: { $set: state.newsletterSubscription.successMessage },
  });

  return {
    successMessage: merged.successMessage,
    newsletterSubscription: merged,
  };
}

export const attrs = ["layout"];
export const stateKey = "ASE_INITIAL_STATE_NEWSLETTER_SUBSCRIPTION";

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsletterSubscription);
