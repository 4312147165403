import {
  GLOBAL_STATUS_REDIRECT,
  GLOBAL_STATUS_NOTFOUND,
  GLOBAL_STATUS_FAILED,
  CLEAR_GLOBAL_STATUS,
  GLOBAL_STATUS_UNAUTHORIZED,
} from "./constants";

export default function statusReducer(state = null, action) {
  switch (action.type) {
    case GLOBAL_STATUS_REDIRECT: {
      const { code, location } = action.payload;
      return { code, location };
    }
    case GLOBAL_STATUS_UNAUTHORIZED: {
      return { code: 401, location: action.payload };
    }
    case GLOBAL_STATUS_NOTFOUND: {
      return { code: 404, message: action.payload.message };
    }
    case GLOBAL_STATUS_FAILED: {
      return { code: 500, message: action.payload.message };
    }
    case CLEAR_GLOBAL_STATUS: {
      return null;
    }
    default:
      return state;
  }
}
