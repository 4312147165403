// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import Message from "stelar/components/Message";
// ase
import AddressSuggestions from "../AddressSuggestions";

const AddressFormSuggestionsWrap = ({
  children,
  confirmAddressAlternative,
  editAddressAlternative,
  formDefinition,
  message,
}) => {
  const { addressSuggest } = formDefinition;

  return (
    <div>
      {message && (
        <Message
          text={message.text}
          title={message.title}
          type={message.type}
        />
      )}
      {addressSuggest ? (
        <AddressSuggestions
          addressSuggest={addressSuggest}
          editAddressAlternative={editAddressAlternative}
          onSubmit={confirmAddressAlternative}
        />
      ) : (
        children
      )}
    </div>
  );
};

AddressFormSuggestionsWrap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  confirmAddressAlternative: PropTypes.func.isRequired,
  editAddressAlternative: PropTypes.func.isRequired,
  formDefinition: PropTypes.object.isRequired,
  message: PropTypes.object,
};

export default AddressFormSuggestionsWrap;
