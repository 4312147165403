// ase
import { setPageTitle } from "shared/webbridge/actions";
import backendUrl from "../utils/AseBackendUrls";
import { openPage } from "../utils/Routing";
import {
  CHANGE_BILLING_ADDRESS_PAGE__CHANGE_BILLING_ADDRESS_FAILED,
  CHANGE_BILLING_ADDRESS_PAGE__CHANGE_BILLING_ADDRESS,
  CHANGE_BILLING_ADDRESS_PAGE__LOAD_CHANGE_BILLING_ADDRESS_PAGE,
  CHANGE_BILLING_ADDRESS_PAGE__VALIDATE_INPUT,
} from "../constants";

export function loadChangeBillingAddressPage(apiClient, query) {
  return apiClient.get(
    backendUrl.CHANGE_BILLING_ADDRESS,
    {
      ok: (content) => [
        {
          type: CHANGE_BILLING_ADDRESS_PAGE__LOAD_CHANGE_BILLING_ADDRESS_PAGE,
          payload: content.body,
        },
        setPageTitle(content.body?.page?.headData?.title),
      ],
    },
    {
      httpClientOptions: {
        params: query,
      },
    }
  );
}

export function submitChangeBillingAddress(
  { routes, link, values },
  apiClient
) {
  return [
    { type: CHANGE_BILLING_ADDRESS_PAGE__CHANGE_BILLING_ADDRESS },
    apiClient.post(link, values, {
      accepted: (content) => [openPage(routes, content.headers.location)],
      badRequest: (content) => [
        {
          type: CHANGE_BILLING_ADDRESS_PAGE__CHANGE_BILLING_ADDRESS_FAILED,
          payload: content.body,
        },
        setPageTitle(content.body?.page?.headData?.title),
      ],
    }),
  ];
}

export function confirmAddressAlternative({ link, routes, values }, apiClient) {
  const valuesArray = values.slice();
  valuesArray.push({ name: "action", value: "CONFIRMATION" });
  const valuesDict = valuesArray.reduce((result, item) => {
    const key = item.name;
    // eslint-disable-next-line no-param-reassign
    result[key] = item.value;
    return result;
  }, {});
  return apiClient.post(link, valuesDict, {
    accepted: (content) => [openPage(routes, content.headers.location)],
    badRequest: (content) => [
      {
        type: CHANGE_BILLING_ADDRESS_PAGE__CHANGE_BILLING_ADDRESS_FAILED,
        payload: content.body,
      },
      setPageTitle(content.body?.page?.headData?.title),
    ],
  });
}

export function editAddressAlternative({ link, routes, values }, apiClient) {
  const valuesArray = values.slice();
  valuesArray.push({ name: "action", value: "EDIT" });
  const valuesDict = valuesArray.reduce((result, item) => {
    const key = item.name;
    // eslint-disable-next-line no-param-reassign
    result[key] = item.value;
    return result;
  }, {});
  return apiClient.post(link, valuesDict, {
    accepted: (content) => [openPage(routes, content.headers.location)],
    badRequest: (content) => [
      {
        type: CHANGE_BILLING_ADDRESS_PAGE__CHANGE_BILLING_ADDRESS_FAILED,
        payload: content.body,
      },
      setPageTitle(content.body?.page?.headData?.title),
    ],
  });
}

export function validateInput(inputPath) {
  return {
    type: CHANGE_BILLING_ADDRESS_PAGE__VALIDATE_INPUT,
    inputPath,
  };
}
