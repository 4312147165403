/* eslint-disable react/no-array-index-key */
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index";
import IconRight from "stelar/components/Icon/IconRight";
import IconLeft from "stelar/components/Icon/IconLeft";
import isEqual from "lodash.isequal";
import { fadeAnimationHandler } from "./helper";
import Slide from "./Slide";
import "./carousel.module.css";
import SlideMosaic from "./SlideMosaic";

class AsyncStage extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { slides } = this.props;
    const nextSlides = nextProps.slides;
    return !isEqual(slides, nextSlides);
  }

  render() {
    const {
      slides,
      imgSizes,
      onLinkClick,
      lazy,
      onImageLoad,
      loadedImages = {},
    } = this.props;
    if (!slides || slides.length === 0) {
      return null;
    }

    if (slides.length === 1) {
      if (slides[0]?.mosaics) {
        return (
          <SlideMosaic
            mosaics={slides[0].mosaics}
            imgSizes={imgSizes}
            onImageLoad={onImageLoad}
            loadedImages={loadedImages}
            onLinkClick={onLinkClick}
            lazy={lazy}
            isSingleSlide
          />
        );
      }
      return (
        <Slide
          {...slides[0]}
          imgSizes={imgSizes}
          onImageLoad={onImageLoad}
          loadedImages={loadedImages}
          onLinkClick={onLinkClick}
          lazy={lazy}
        />
      );
    }

    const renderedSlides = slides.map((slide) => {
      if (slide.mosaics) {
        return (
          <SlideMosaic
            mosaics={slide.mosaics}
            imgSizes={imgSizes}
            onImageLoad={onImageLoad}
            loadedImages={loadedImages}
            onLinkClick={onLinkClick}
            lazy={lazy}
          />
        );
      }
      return (
        <Slide
          key={slide?.image?.pattern}
          {...slide}
          imgSizes={imgSizes}
          onImageLoad={onImageLoad}
          loadedImages={loadedImages}
          onLinkClick={onLinkClick}
          lazy={lazy}
        />
      );
    });

    return (
      <Carousel
        animationHandler={fadeAnimationHandler}
        autoPlay
        infiniteLoop
        interval={5000}
        showThumbs={false}
        showStatus={false}
        stopOnHover
        useKeyboardArrows={false}
        renderArrowPrev={(clickHandler) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <span className="arrow-left" onClick={clickHandler}>
            <IconLeft large />
          </span>
        )}
        renderArrowNext={(clickHandler) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <span className="arrow-right" onClick={clickHandler}>
            <IconRight large />
          </span>
        )}
        transitionTime={500}
        swipeable={false}
      >
        {renderedSlides}
      </Carousel>
    );
  }
}

AsyncStage.propTypes = {
  slides: PropTypes.array,
  onLinkClick: PropTypes.func,
  lazy: PropTypes.bool,
  imgSizes: PropTypes.string,
  onImageLoad: PropTypes.func,
  loadedImages: PropTypes.object,
};

export default AsyncStage;
