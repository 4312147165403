/* eslint-disable camelcase */
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import { IMAGE_PROFILE_DEFAULTS } from "shared/utils/imageUrlBuilder";
import RatioImage from "../RatioImage";

export default function Slide({
  image = {},
  link = {},
  imgSizes,
  breakpointImages,
  onLinkClick,
  onImageLoad,
  loadedImages = {},
  lazy,
  tracking,
}) {
  function onClick(e) {
    e.preventDefault();
    onLinkClick(e, tracking);
  }
  const imageNode = (
    <RatioImage
      image={image}
      imageProfileDefault={IMAGE_PROFILE_DEFAULTS.kurator}
      breakpointImages={breakpointImages}
      onImageLoad={onImageLoad}
      hasLoaded={loadedImages[image.pattern]}
      sizes={imgSizes}
      lazy={lazy}
      data-test-sell-stage-slide
    />
  );

  return link.link ? (
    <a href={link.link} onClick={onClick}>
      {imageNode}
    </a>
  ) : (
    imageNode
  );
}

Slide.propTypes = {
  image: PropTypes.object,
  link: PropTypes.object,
  imgSizes: PropTypes.string,
  breakpointImages: PropTypes.array,
  onLinkClick: PropTypes.func,
  onImageLoad: PropTypes.func,
  loadedImages: PropTypes.object,
  lazy: PropTypes.bool,
  tracking: PropTypes.object,
};
