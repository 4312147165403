import React from "react";
import HeaderIcon from "../HeaderIcon";

const icon = (
  <svg viewBox="-6 -7 36 36">
    <path
      d="M11.7,23.9C1.1,17,0,10.1,0,7.5C0,3.4,3,0,6.8,0c2,0,3.9,1,5.1,2.6L12,2.7l0.1-0.1C13.4,1,15.3,0,17.2,0
    C20.9,0,24,3.4,24,7.5c0,2.6-1.1,9.5-11.7,16.4L12,24.1L11.7,23.9z M6.8,1.2C3.7,1.2,1.2,4,1.2,7.5c0,5,4,10.6,10.7,15.1l0.1,0.1
    l0.1-0.1c6.7-4.5,10.7-10.1,10.7-15.1c0-3.5-2.5-6.3-5.6-6.3c-1.9,0-3.7,1.1-4.7,3l-0.5,1l-0.5-1C10.5,2.3,8.7,1.2,6.8,1.2z"
    />
  </svg>
);

type Props = {
  active?: boolean;
  text?: string;
};

function HeaderWishlist(props: Props) {
  return (
    <HeaderIcon
      {...props}
      icon={icon}
      testSelector="data-test-stelar-header-wishlist"
    />
  );
}

export default HeaderWishlist;
