// vendor
import { connect } from "react-redux";
import prefetch from "shared/ssrPrefetch/prefetch";
import { getClientsideFetchLocals } from "shared/ssrPrefetch/useFetchLocalsStore";
// component
import {
  loadNewsletterSubscriptionFragmentData,
  submitForm,
  updateCheckbox,
  updateInput,
} from "./actions";
import NewsletterSubscriptionFragment from "./components/NewsletterSubscriptionFragment";
import { buildCacheKey } from "./helper";

export const redial = {
  fetch: ({ apiClient, dispatch, getState, query, isClient }) => {
    if (
      getState().newsletterSubsciption &&
      getState().newsletterSubsciption.page.headline
    ) {
      return null;
    }
    return dispatch(
      loadNewsletterSubscriptionFragmentData(
        apiClient,
        query?.layout || "footer",
        isClient
      )
    );
  },
};

const mapDispatchToProps = (dispatch) => {
  const { apiClient } = getClientsideFetchLocals();
  return {
    doUpdateCheckbox: (event) => dispatch(updateCheckbox(event)),
    doUpdateInput: (event) => dispatch(updateInput(event)),
    doSubmitForm: (event, link, data) =>
      dispatch(submitForm(event, link, data, apiClient)),
  };
};

function mapStateToProps(state, props) {
  const ssrPrefetched = state.ssrPrefetched[buildCacheKey(props)];

  return {
    ...state.newsletterSubscriptionFragment,
    ssrPrefetched,
  };
}

export const attrs = ["layout", "point", "categoryId"];
export const stateKey = "ASE_INITIAL_STATE_NEWSLETTER_SUBSCRIPTION_FRAGMENT";

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(prefetch(NewsletterSubscriptionFragment, redial, { buildCacheKey }));
