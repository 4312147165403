/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import { getEnv } from "../utils/envVariables";
import sendLogEntry, { CLIENTSIDE_LOG_SERVICE_PATH } from "./logService";

export function logger(callingModule, team) {
  const logToConsole =
    (level) =>
    (message, ...args) => {
      console[level](message, ...args);
    };
  return {
    error: logToConsole("error"),
    warn: logToConsole("error"),
    info: logToConsole("log"),
    debug: logToConsole("log"),
  };
}

export async function sendClientSideError(message) {
  if (process.env.APP_ENV !== "browser") {
    return;
  }
  let resultMessage = message;
  let stackTrace;
  if (message instanceof Error) {
    resultMessage = message.message;
    stackTrace = message.stack;
  }

  await sendLogEntry({
    url: CLIENTSIDE_LOG_SERVICE_PATH,
    payload: {
      type: "error",
      message: resultMessage,
      stackTrace,
      origin: "client",
    },
    config: {
      headers: {
        "x-mf-team": getEnv("TEAM"),
        "x-mf-build-number": getEnv("BUILD_NUMBER"),
      },
    },
  });
}

export default logger;
