export function createActionKey(id, params = {}, isDetail = false) {
  const paramString = Object.keys(params)
    .map((key) => {
      if (isDetail) {
        if (key === "voucher_amount" || key === "c") {
          return null;
        }
      }
      return params[key] ? `|${key}=${encodeURIComponent(params[key])}` : "";
    })
    .join("");
  return `${encodeURIComponent(id)}${paramString}`;
}

export function parseActionKey(key) {
  const [slug, ...rest] = key.split("|");
  const params = {};
  rest.forEach((p) => {
    const [k, v] = p.split("=");
    params[k] = decodeURIComponent(v);
  });
  return {
    slug: decodeURIComponent(slug),
    params,
  };
}
