// vendor
import classNames from "classnames/bind";
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// ase
import RenderTarget from "shared/components/RenderTarget";
import CheckoutProgressBar from "../CheckoutProgressBar";
import Footer from "../Footer";
import Header from "../Header";

import style from "./ReducedLayout.module.css";

const cx = classNames.bind(style);

const ReducedLayout = ({
  checkoutProgressBar,
  children,
  footer,
  footnotes,
  header,
  isLoading,
  navigate,
  orderProgressStep,
  marginBottomAuto,
}) => {
  const checkoutProgress =
    orderProgressStep && checkoutProgressBar ? (
      <div className={style.resetFlex}>
        <CheckoutProgressBar
          enableUpToStep={orderProgressStep}
          isLoading={isLoading}
          navigate={navigate}
          {...checkoutProgressBar}
        />
      </div>
    ) : null;

  return (
    <div className={style.root}>
      <RenderTarget web>
        <Header isLoading={isLoading} {...header} />
      </RenderTarget>
      {checkoutProgress}
      <div
        className={cx("reducedLayout", {
          "margin-bottom-auto": marginBottomAuto,
        })}
      >
        <div className={style.block}>{children}</div>
      </div>
      <RenderTarget web>
        <Footer footnotes={footnotes} isLoading={isLoading} {...footer} />
      </RenderTarget>
    </div>
  );
};

ReducedLayout.propTypes = {
  checkoutProgressBar: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  footer: PropTypes.object,
  footnotes: PropTypes.array,
  header: PropTypes.object,
  isLoading: PropTypes.bool,
  orderProgressStep: PropTypes.string,
  navigate: PropTypes.func,
  marginBottomAuto: PropTypes.bool,
};

export default ReducedLayout;
