// vendor
import update from "immutability-helper";
// ase
import {
  PAYMENT_PAGE__CHANGE_PAYMENT,
  PAYMENT_PAGE__LOAD_PAYMENT_PAGE,
  PAYMENT_PAGE__SET_PAYMENT,
  PAYMENT_PAGE__VALIDATE_INPUT,
} from "../constants";
import { validateInput } from "../utils/Validators";
// component
import initialState from "./initialState";

const payment = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_PAGE__LOAD_PAYMENT_PAGE:
      if (action.payload) {
        return update(state, { $set: action.payload });
      }
      return state;
    case PAYMENT_PAGE__VALIDATE_INPUT:
      return update(state, { $set: validateInput(state, action) });
    case PAYMENT_PAGE__CHANGE_PAYMENT:
      return update(state, { paymentMethod: { $set: action.paymentMethod } });
    case PAYMENT_PAGE__SET_PAYMENT:
      return update(state, { $set: action.payload });
    default:
      return state;
  }
};

export default payment;
