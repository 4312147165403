const initialState = {
  page: {
    back: {
      label: "………………",
    },
    headline: "…………… ……………… ……… ………… ……………………………",
    submitButton: {
      label: "…………………………",
    },
  },
  paymentMethod: "invoice",
  directDebit: {
    headline: "…………………………",
    accountHolder: {},
    iban: {},
    bic: {},
  },
  creditCard: {
    headline: "……………………………",
  },
  cashOnDelivery: {
    headline: "……………………………",
  },
  invoice: {
    headline: "……………………",
  },
  paypal: {
    headline: "………………",
  },
};

export default initialState;
