// App
export const APP_CLOSE_OFFCANVAS = "APP_CLOSE_OFFCANVAS";
export const APP_OPEN_OFFCANVAS = "APP_OPEN_OFFCANVAS";
export const APP_SET_RENDER_TARGET = "APP_SET_RENDER_TARGET";

// Pages
export const ADS_PAGE__ADD_TO_BASKET_STARTED =
  "ADS_PAGE__ADD_TO_BASKET_STARTED";
export const ADS_PAGE__ADD_TO_BASKET_SUCCESS =
  "ADS_PAGE__ADD_TO_BASKET_SUCCESS";
export const ADS_PAGE__ADD_TO_BASKET_FAILED = "ADS_PAGE__ADD_TO_BASKET_FAILED";
export const ADS_PAGE__ADD_TO_WISHLIST_STARTED =
  "ADS_PAGE__ADD_TO_WISHLIST_STARTED";
export const ADS_PAGE__ADD_TO_WISHLIST_SUCCESS =
  "ADS_PAGE__ADD_TO_WISHLIST_SUCCESS";
export const ADS_PAGE__ADD_TO_WISHLIST_FAILED =
  "ADS_PAGE__ADD_TO_WISHLIST_FAILED";
export const ADS_PAGE__LOAD_ADS = "ADS_PAGE__LOAD_ADS";
export const ADS_PAGE__PAGE_NAME = "ADS";
export const ADS_PAGE__SELECT_ARTICLE = "ADS_PAGE__SELECT_ARTICLE";

export const CREDIT_CARD_PAYMENT_PAGE__LOAD_PAGE =
  "CREDIT_CARD_PAYMENT_PAGE__LOAD_PAGE";
export const KUZ_CREDIT_CARD_PAYMENT_PAGE__LOAD_PAGE =
  "KUZ_CREDIT_CARD_PAYMENT_PAGE__LOAD_PAGE";
export const KUZ_CREDIT_CARD_PAYMENT_PAGE__LOAD_PAGE_V2 =
  "KUZ_CREDIT_CARD_PAYMENT_PAGE__LOAD_PAGE_V2";

export const BASKET_PAGE__ADD_SHOP_CATALOG = "BASKET_PAGE__ADD_SHOP_CATALOG";
export const BASKET_PAGE__DELETE_GUTSCHEIN_ITEM =
  "BASKET_PAGE__DELETE_GUTSCHEIN_ITEM";
export const BASKET_PAGE__DELETE_LINE_ITEM = "BASKET_PAGE__DELETE_LINE_ITEM";
export const BASKET_PAGE__LOAD_BASKET = "BASKET_PAGE__LOAD_BASKET";
export const BASKET_PAGE__PAGE_NAME = "BASKET";
export const BASKET_PAGE__SHOW_MESSAGE = "BASKET_PAGE__SHOW_MESSAGE";
export const BASKET_PAGE__TOGGLE_AGE_RELATED_ADVICE =
  "BASKET_PAGE__TOGGLE_AGE_RELATED_ADVICE";
export const BASKET_PAGE__UPDATE_LINE_ITEM = "BASKET_PAGE__UPDATE_LINE_ITEM";
export const BASKET_PAGE__UPDATE_LINE_ITEM_FAILED =
  "BASKET_PAGE__UPDATE_LINE_ITEM_FAILED";
export const BASKET_PAGE__UPDATE_LINE_ITEM_QUANTITY =
  "BASKET_PAGE__UPDATE_LINE_ITEM_QUANTITY";
export const BASKET_PAGE__UPDATE_LINE_ITEM_EEG_RETURN =
  "BASKET_PAGE__UPDATE_LINE_ITEM_EEG_RETURN";
export const BASKET_PAGE__VALIDATE_INPUT = "BASKET_PAGE__VALIDATE_INPUT";
export const BASKET_PAGE__NAVIGATE_WHEN_UPDATED =
  "BASKET_PAGE__NAVIGATE_WHEN_UPDATED";

export const CHANGE_BILLING_ADDRESS_PAGE__CHANGE_BILLING_ADDRESS =
  "CHANGE_BILLING_ADDRESS_PAGE__CHANGE_BILLING_ADDRESS";
export const CHANGE_BILLING_ADDRESS_PAGE__CHANGE_BILLING_ADDRESS_FAILED =
  "CHANGE_BILLING_ADDRESS_PAGE__CHANGE_BILLING_ADDRESS_FAILED";
export const CHANGE_BILLING_ADDRESS_PAGE__LOAD_CHANGE_BILLING_ADDRESS_PAGE =
  "CHANGE_BILLING_ADDRESS_PAGE__LOAD_CHANGE_BILLING_ADDRESS_PAGE";
export const CHANGE_BILLING_ADDRESS_PAGE__UPDATE_FORM =
  "CHANGE_BILLING_ADDRESS_PAGE__UPDATE_FORM";
export const CHANGE_BILLING_ADDRESS_PAGE__VALIDATE_ALL =
  "CHANGE_BILLING_ADDRESS_PAGE__VALIDATE_ALL";
export const CHANGE_BILLING_ADDRESS_PAGE__VALIDATE_INPUT =
  "CHANGE_BILLING_ADDRESS_PAGE__VALIDATE_INPUT";

export const CUSTOMER_ACCOUNT_PAGE__LOAD_CUSTOMER_ACCOUNT =
  "CUSTOMER_ACCOUNT_PAGE__LOAD_CUSTOMER_ACCOUNT";
export const CUSTOMER_ACCOUNT_PAGE__NEWSLETTER_UNSUBSCRIBE_SHOP_ADDR_FAILED =
  "CUSTOMER_ACCOUNT_PAGE__NEWSLETTER_UNSUBSCRIBE_SHOP_ADDR_FAILED";
export const CUSTOMER_ACCOUNT_PAGE__NEWSLETTER_UNSUBSCRIBE_SHOP_ADDR_SUCCEEDED =
  "CUSTOMER_ACCOUNT_PAGE__NEWSLETTER_UNSUBSCRIBE_SHOP_ADDR_SUCCEEDED";

export const CUSTOMER_ACCOUNT_PAGE__POSTALADVERTISE_UNSUBSCRIBE_SUCCEEDED =
  "CUSTOMER_ACCOUNT_PAGE__POSTALADVERTISE_UNSUBSCRIBE_SUCCEEDED";
export const CUSTOMER_ACCOUNT_PAGE__POSTALADVERTISE_UNSUBSCRIBE_FAILED =
  "CUSTOMER_ACCOUNT_PAGE__POSTALADVERTISE_UNSUBSCRIBE_FAILED";

export const CUSTOMER_ACCOUNT_PAGE__PAGE_NAME = "CUSTOMER_ACCOUNT";
export const CUSTOMER_ACCOUNT_PAGE__TOGGLE_ACCORDION =
  "CUSTOMER_ACCOUNT_PAGE__TOGGLE_ACCORDION";
export const CUSTOMER_ACCOUNT_PAGE__TOGGLE_DELIVERY_MODE =
  "CUSTOMER_ACCOUNT_PAGE__TOGGLE_DELIVERY_MODE";
export const CUSTOMER_ACCOUNT_PAGE__CHANGE_SELECTION =
  "CUSTOMER_ACCOUNT_PAGE__CHANGE_SELECTION";
export const CUSTOMER_ACCOUNT_PAGE__DELETE_ADDRESS =
  "CUSTOMER_ACCOUNT_PAGE__DELETE_ADDRESS";
export const CUSTOMER_ACCOUNT_PAGE__CHOOSE_ADDRESS =
  "CUSTOMER_ACCOUNT_PAGE__CHOOSE_ADDRESS";
export const CUSTOMER_ACCOUNT_PAGE__NEW_ADDRESS_SUBMIT =
  "CUSTOMER_ACCOUNT_PAGE__NEW_ADDRESS_SUBMIT";
export const CUSTOMER_ACCOUNT_PAGE__NEW_ADDRESS_SUBMIT_FAILED =
  "CUSTOMER_ACCOUNT_PAGE__NEW_ADDRESS_SUBMIT_FAILED";
export const CUSTOMER_ACCOUNT_PAGE__LOAD_PAGE =
  "CUSTOMER_ACCOUNT_PAGE__LOAD_PAGE";
export const CUSTOMER_ACCOUNT_PAGE__CHANGE_ADDRESS_SUBMIT =
  "CUSTOMER_ACCOUNT_PAGE__CHANGE_ADDRESS_SUBMIT";
export const CUSTOMER_ACCOUNT_PAGE__CHANGE_ADDRESS_SUBMIT_FAILED =
  "CUSTOMER_ACCOUNT_PAGE__CHANGE_ADDRESS_SUBMIT_FAILED";
export const CUSTOMER_ACCOUNT_PAGE__UPDATE_FORM =
  "CUSTOMER_ACCOUNT_PAGE__UPDATE_FORM";
export const CUSTOMER_ACCOUNT_PAGE__DO_CHANGE_REQUEST =
  "CUSTOMER_ACCOUNT_PAGE__DO_CHANGE_REQUEST";
export const CUSTOMER_ACCOUNT_PAGE__CHANGE_STANDARD =
  "CUSTOMER_ACCOUNT_PAGE__CHANGE_STANDARD";
export const CUSTOMER_ACCOUNT_PAGE__CHANGE_SUBMIT_LINK =
  "CUSTOMER_ACCOUNT_PAGE__CHANGE_SUBMIT_LINK";
export const CUSTOMER_ACCOUNT_PAGE__SET_OVERLAY =
  "CUSTOMER_ACCOUNT_PAGE__SET_OVERLAY";

export const ACCOUNT_DELETION_PAGE__LOAD_PAGE =
  "ACCOUNT_DELETION_PAGE__LOAD_PAGE";
export const ACCOUNT_DELETION_PAGE__SUBMIT = "ACCOUNT_DELETION_PAGE__SUBMIT";
export const ACCOUNT_DELETION_PAGE__SUBMIT_FAILED =
  "ACCOUNT_DELETION_PAGE__SUBMIT_FAILED";

export const DIALOG_FRAGMENT_LOAD_DATA = "DIALOG_FRAGMENT_LOAD_DATA";

export const DELIVERY_ADDRESS_PAGE__CHANGE_SELECTION =
  "DELIVERY_ADDRESS_PAGE__CHANGE_SELECTION";
export const DELIVERY_ADDRESS_PAGE__NEW_ADDRESS_SUBMIT =
  "DELIVERY_ADDRESS_PAGE__NEW_ADDRESS_SUBMIT";
export const DELIVERY_ADDRESS_PAGE__NEW_ADDRESS_SUBMIT_FAILED =
  "DELIVERY_ADDRESS_PAGE__NEW_ADDRESS_SUBMIT_FAILED";
export const DELIVERY_ADDRESS_PAGE__CHANGE_ADDRESS_SUBMIT =
  "DELIVERY_ADDRESS_PAGE__CHANGE_ADDRESS_SUBMIT";
export const DELIVERY_ADDRESS_PAGE__CHANGE_ADDRESS_SUBMIT_FAILED =
  "DELIVERY_ADDRESS_PAGE__CHANGE_ADDRESS_SUBMIT_FAILED";
export const DELIVERY_ADDRESS_PAGE__CHOOSE_ADDRESS =
  "DELIVERY_ADDRESS_PAGE__CHOOSE_ADDRESS";
export const DELIVERY_ADDRESS_PAGE__DELETE_ADDRESS =
  "DELIVERY_ADDRESS_PAGE__DELETE_ADDRESS";
export const DELIVERY_ADDRESS_PAGE__LOAD_PAGE =
  "DELIVERY_ADDRESS_PAGE__LOAD_PAGE";
export const DELIVERY_ADDRESS_PAGE__LOAD_PAGE_FAILED =
  "DELIVERY_ADDRESS_PAGE__LOAD_PAGE_FAILED";
export const DELIVERY_ADDRESS_PAGE__PACKSTATION_SUBMIT =
  "DELIVERY_ADDRESS_PAGE__PACKSTATION_SUBMIT";
export const DELIVERY_ADDRESS_PAGE__PACKSTATION_SUBMIT_FAILED =
  "DELIVERY_ADDRESS_PAGE__PACKSTATION_SUBMIT_FAILED";
export const DELIVERY_ADDRESS_PAGE__SELECT_ADDRESS =
  "DELIVERY_ADDRESS_PAGE__SELECT_ADDRESS";
export const DELIVERY_ADDRESS_PAGE__SELECT_ADDRESS_DEPRECATED =
  "DELIVERY_ADDRESS_PAGE__SELECT_ADDRESS_DEPRECATED";
export const DELIVERY_ADDRESS_PAGE__UPDATE_FORM =
  "DELIVERY_ADDRESS_PAGE__UPDATE_FORM";
export const DELIVERY_ADDRESS_PAGE__SET_OVERLAY =
  "DELIVERY_ADDRESS_PAGE__SET_OVERLAY";

export const LAST_CHECK_PAGE__CHANGE_DELIVERY_METHOD =
  "LAST_CHECK_PAGE__CHANGE_DELIVERY_METHOD";
export const LAST_CHECK_PAGE__CREATE_ORDER_STARTED =
  "LAST_CHECK_PAGE__CREATE_ORDER_STARTED";
export const LAST_CHECK_PAGE__CREATE_ORDER_SUCCESS =
  "LAST_CHECK_PAGE__CREATE_ORDER_SUCCESS";
export const LAST_CHECK_PAGE__CREATE_ORDER_FAILED =
  "LAST_CHECK_PAGE__CREATE_ORDER_FAILED";
export const LAST_CHECK_PAGE__DELETE_GUTSCHEIN_ITEM =
  "LAST_CHECK_PAGE__DELETE_GUTSCHEIN_ITEM";
export const LAST_CHECK_PAGE__DELETE_LINE_ITEM =
  "LAST_CHECK_PAGE__DELETE_LINE_ITEM";
export const LAST_CHECK_PAGE__UPDATE_LINE_ITEM =
  "LAST_CHECK_PAGE__UPDATE_LINE_ITEM";
export const LAST_CHECK_PAGE__UPDATE_LINE_ITEM_QUANTITY =
  "LAST_CHECK_PAGE__UPDATE_LINE_ITEM_QUANTITY";
export const LAST_CHECK_PAGE__UPDATE_LINE_ITEM_EEG_RETURN =
  "LAST_CHECK_PAGE__UPDATE_LINE_ITEM_EEG_RETURN";
export const LAST_CHECK_PAGE__LOAD_LAST_CHECK_PAGE =
  "LAST_CHECK_PAGE__LOAD_LAST_CHECK_PAGE";
export const LAST_CHECK_PAGE__PAGE_NAME = "LAST_CHECK";
export const LAST_CHECK_PAGE__TOGGLE_AGE_RELATED_ADVICE =
  "LAST_CHECK_PAGE__TOGGLE_AGE_RELATED_ADVICE";
export const LAST_CHECK_PAGE__UPDATE_DELIVERY_METHOD =
  "LAST_CHECK_PAGE__UPDATE_DELIVERY_METHOD";
export const LAST_CHECK_PAGE__VALIDATE_ALL = "LAST_CHECK_PAGE__VALIDATE_ALL";
export const LAST_CHECK_PAGE__VALIDATE_FOR_ORDER_SUBMISSION =
  "LAST_CHECK_PAGE__VALIDATE_FOR_ORDER_SUBMISSION";
export const LAST_CHECK_PAGE__VALIDATE_INPUT =
  "LAST_CHECK_PAGE__VALIDATE_INPUT";

export const LOGIN_PAGE__LOAD_LOGIN_PAGE = "LOGIN_PAGE__LOAD_LOGIN_PAGE";
export const LOGIN_PAGE__LOGIN = "LOGIN_PAGE__LOGIN";
export const LOGIN_PAGE__LOGIN_FAILED = "LOGIN_PAGE__LOGIN_FAILED";
export const LOGIN_PAGE__PAGE_NAME = "LOGIN";
export const LOGIN_PAGE__REGISTER = "LOGIN_PAGE__REGISTER";
export const LOGIN_PAGE__REGISTER_FAILED = "LOGIN_PAGE__REGISTER_FAILED";
export const LOGIN_PAGE__TOGGLE_ACCORDION = "LOGIN_PAGE__TOGGLE_ACCORDION";
export const LOGIN_PAGE__VALIDATE_INPUT = "LOGIN_PAGE__VALIDATE_INPUT";
export const LOGIN_PAGE__VALIDATE_LOGIN = "LOGIN_PAGE__VALIDATE_LOGIN";
export const LOGIN_PAGE__VALIDATE_REGISTER = "LOGIN_PAGE__VALIDATE_REGISTER";

export const NEWSLETTER_PAGE__LOAD_NEWSLETTER_PAGE =
  "NEWSLETTER_PAGE__LOAD_NEWSLETTER_PAGE";

export const NEWSLETTER_UNSUBSCRIBE_PAGE__CHANGE_EMAIL =
  "NEWSLETTER_UNSUBSCRIBE_PAGE__CHANGE_EMAIL";
export const NEWSLETTER_UNSUBSCRIBE_PAGE__LOAD_PAGE =
  "NEWSLETTER_UNSUBSCRIBE_PAGE__LOAD_PAGE";
export const NEWSLETTER_UNSUBSCRIBE_PAGE__PAGE_NAME = "NEWSLETTER_UNSUBSCRIBE";
export const NEWSLETTER_UNSUBSCRIBE_PAGE__REQUEST_NEWSLETTER =
  "NEWSLETTER_UNSUBSCRIBE_PAGE__REQUEST_NEWSLETTER";
export const NEWSLETTER_UNSUBSCRIBE_PAGE__REQUEST_NEWSLETTER_FAILED =
  "NEWSLETTER_UNSUBSCRIBE_PAGE__REQUEST_NEWSLETTER_FAILED";
export const NEWSLETTER_UNSUBSCRIBE_PAGE__VALIDATE_ALL =
  "NEWSLETTER_UNSUBSCRIBE_PAGE__VALIDATE_ALL";
export const NEWSLETTER_UNSUBSCRIBE_PAGE__VALIDATE_EMAIL =
  "NEWSLETTER_UNSUBSCRIBE_PAGE__VALIDATE_EMAIL";

export const UWG7_MAIL_UNSUBSCRIBE_PAGE__LOAD_PAGE =
  "UWG7_MAIL_UNSUBSCRIBE_PAGE__LOAD_PAGE";

export const NO_SCRIPT_PAGE__LOAD_PAGE = "NO_SCRIPT_PAGE__LOAD_PAGE";

export const ORDER_CONFIRMATION_PAGE__LOAD_ORDER_CONFIRMATION_PAGE =
  "ORDER_CONFIRMATION_PAGE__LOAD_ORDER_CONFIRMATION_PAGE";
export const ORDER_CONFIRMATION_PAGE__PAGE_NAME = "ORDER_CONFIRMATION";

export const ORDER_HISTORY_PAGE__LOAD_ORDER_HISTORY_PAGE =
  "ORDER_HISTORY_PAGE__LOAD_ORDER_HISTORY_PAGE";
export const ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING =
  "ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING";
export const ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING_SUCCESS =
  "ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING_SUCCESS";
export const ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING_FAILED =
  "ORDER_HISTORY_PAGE__LOAD_SHIPMENT_TRACKING_FAILED";
export const ORDER_HISTORY_PAGE__OPEN_ORDER = "ORDER_HISTORY_PAGE__OPEN_ORDER";
export const ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL =
  "ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL";
export const ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL_SUCCESS =
  "ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL_SUCCESS";
export const ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL_FAILED =
  "ORDER_HISTORY_PAGE__REQUEST_RETURNVOUCHER_BY_EMAIL_FAILED";
export const ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL =
  "ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL";
export const ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL_SUCCESS =
  "ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL_SUCCESS";
export const ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL_FAILED =
  "ORDER_HISTORY_PAGE__REQUEST_SHIPMENT_LABEL_BY_EMAIL_FAILED";

export const DEBITOR_OVERVIEW_PAGE__LOAD_PAGE =
  "DEBITOR_OVERVIEW_PAGE__LOAD_PAGE";
export const DEBITOR_OVERVIEW_PAGE__LOAD_REFUND_OVERLAY =
  "DEBITOR_OVERVIEW_PAGE__LOAD_REFUND_OVERLAY";
export const DEBITOR_OVERVIEW_PAGE__LOAD_REFUND_OVERLAY_SUCCESS =
  "DEBITOR_OVERVIEW_PAGE__LOAD_REFUND_OVERLAY_SUCCESS";
export const DEBITOR_OVERVIEW_PAGE__REQUEST_REFUND =
  "DEBITOR_OVERVIEW_PAGE__REQUEST_REFUND";
export const DEBITOR_OVERVIEW_PAGE__REQUEST_REFUND_SUCCESS =
  "DEBITOR_OVERVIEW_PAGE__REQUEST_REFUND_SUCCESS";

export const ORDER_SHEET_PAGE__ADD_ORDER_SHEET_ROW =
  "ORDER_SHEET_PAGE__ADD_ORDER_SHEET_ROW";
export const ORDER_SHEET_PAGE__ADD_TO_BASKET_STARTED =
  "ORDER_SHEET_PAGE__ADD_TO_BASKET_STARTED";
export const ORDER_SHEET_PAGE__ADD_TO_BASKET_FAILED =
  "ORDER_SHEET_PAGE__ADD_TO_BASKET_FAILED";
export const ORDER_SHEET_PAGE__DEL_ORDER_SHEET_ROW =
  "ORDER_SHEET_PAGE__DEL_ORDER_SHEET_ROW";
export const ORDER_SHEET_PAGE__GET_ARTICLE_DATA =
  "ORDER_SHEET_PAGE__GET_ARTICLE_DATA";
export const ORDER_SHEET_PAGE__GET_ARTICLE_DATA_FAILED =
  "ORDER_SHEET_PAGE__GET_ARTICLE_DATA_FAILED";
export const ORDER_SHEET_PAGE__LOAD_ORDER_SHEET_PAGE =
  "ORDER_SHEET_PAGE__LOAD_ORDER_SHEET_PAGE";
export const ORDER_SHEET_PAGE__PAGE_NAME = "ORDER_SHEET";
export const ORDER_SHEET_PAGE__RERENDER_STATE =
  "ORDER_SHEET_PAGE__RERENDER_STATE";

export const PAYMENT_PAGE__CHANGE_PAYMENT = "PAYMENT_PAGE__CHANGE_PAYMENT";
export const PAYMENT_PAGE__LOAD_PAYMENT_PAGE =
  "PAYMENT_PAGE__LOAD_PAYMENT_PAGE";
export const PAYMENT_PAGE__PAGE_NAME = "PAYMENT";
export const PAYMENT_PAGE__SET_PAYMENT = "PAYMENT_PAGE__SET_PAYMENT";
export const PAYMENT_PAGE__VALIDATE_INPUT = "PAYMENT_PAGE__VALIDATE_INPUT";

export const PASSWORD_PAGE__PAGE_NAME = "PASSWORD";
export const PASSWORD_PAGE__VALIDATE_ALL = "PASSWORD_PAGE__VALIDATE_ALL";

export const PASSWORD_FORGOT_PAGE__LOAD_PASSWORD_FORGOT_PAGE =
  "PASSWORD_FORGOT_PAGE__LOAD_PASSWORD_FORGOT_PAGE";
export const PASSWORD_FORGOT_PAGE__REQUEST_PASSWORD_CHANGE =
  "PASSWORD_FORGOT_PAGE__REQUEST_PASSWORD_CHANGE";
export const PASSWORD_FORGOT_PAGE__REQUEST_PASSWORD_RECOVERY_EMAIL =
  "PASSWORD_FORGOT_PAGE__REQUEST_PASSWORD_RECOVERY_EMAIL";
export const PASSWORD_FORGOT_PAGE__REQUEST_PASSWORD_RECOVERY_EMAIL_FAILED =
  "PASSWORD_FORGOT_PAGE__REQUEST_PASSWORD_RECOVERY_EMAIL_FAILED";
export const PASSWORD_FORGOT_PAGE__VALIDATE_EMAIL =
  "PASSWORD_FORGOT_PAGE__VALIDATE_EMAIL";
export const PASSWORD_FORGOT_PAGE__VALIDATE_INPUT =
  "PASSWORD_FORGOT_PAGE__VALIDATE_INPUT";
export const PASSWORD_FORGOT_PAGE__VALIDATE_PASSWORDREPETITION_FIELD =
  "PASSWORD_FORGOT_PAGE__VALIDATE_PASSWORDREPETITION_FIELD";

export const REGISTER_BY_KUNDENKARTE_PAGE__LOAD_LOGIN_PAGE =
  "REGISTER_BY_KUNDENKARTE_PAGE__LOAD_LOGIN_PAGE";
export const REGISTER_BY_KUNDENKARTE_PAGE__PAGE_NAME =
  "REGISTER_BY_KUNDENKARTE_PAGE";
export const REGISTER_BY_KUNDENKARTE_PAGE__REGISTER_FAILED =
  "REGISTER_BY_KUNDENKARTE_PAGE__REGISTER_FAILED";
export const REGISTER_BY_KUNDENKARTE_PAGE__VALIDATE_INPUT =
  "REGISTER_BY_KUNDENKARTE_PAGE__VALIDATE_INPUT";
export const REGISTER_BY_KUNDENKARTE_PAGE__VALIDATE_REGISTER =
  "REGISTER_BY_KUNDENKARTE_PAGE__VALIDATE_REGISTER";

export const REGISTER_BY_KUNDENKARTE_ERFOLG_PAGE__VALIDATE_REGISTER =
  "REGISTER_BY_KUNDENKARTE_ERFOLG_PAGE__VALIDATE_REGISTER";
export const REGISTER_BY_KUNDENKARTE_ERFOLG_PAGE__VALIDATE_INPUT =
  "REGISTER_BY_KUNDENKARTE_ERFOLG_PAGE__VALIDATE_INPUT";
export const REGISTER_BY_KUNDENKARTE_ERFOLG_PAGE__REGISTER_SUCCEEDED =
  "REGISTER_BY_KUNDENKARTE_ERFOLG_PAGE__REGISTER_FAILED";
export const REGISTER_BY_KUNDENKARTE_ERFOLG_PAGE__REGISTER_FAILED =
  "REGISTER_BY_KUNDENKARTE_ERFOLG_PAGE__REGISTER_FAILED";
export const REGISTER_BY_KUNDENKARTE_ERFOLG_PAGE__PAGE_NAME =
  "REGISTER_BY_KUNDENKARTE_ERFOLG_PAGE";
export const REGISTER_BY_KUNDENKARTE_ERFOLG_PAGE__LOAD_LOGIN_PAGE =
  "REGISTER_BY_KUNDENKARTE_ERFOLG_PAGE__LOAD_LOGIN_PAGE";

export const REGISTER_PAGE__LOAD_REGISTER_PAGE =
  "REGISTER_PAGE__LOAD_REGISTER_PAGE";
export const REGISTER_PAGE__REGISTER = "REGISTER_PAGE__REGISTER";
export const REGISTER_PAGE__REGISTER_FAILED = "REGISTER_PAGE__REGISTER_FAILED";
export const REGISTER_PAGE__RERENDER_STATE = "REGISTER_PAGE__RERENDER_STATE";
export const REGISTER_PAGE__UPDATE_FORM = "REGISTER_PAGE__UPDATE_FORM";
export const REGISTER_PAGE__VALIDATE_ALL = "REGISTER_PAGE__VALIDATE_ALL";
export const REGISTER_PAGE__VALIDATE_INPUT = "REGISTER_PAGE__VALIDATE_INPUT";
export const REGISTER_PAGE__VALIDATE_PASSWORD_REPETITION =
  "REGISTER_PAGE__VALIDATE_PASSWORD_REPETITION";

export const WISHLIST_PAGE__RENDER_WISHLIST_PAGE =
  "WISHLIST_PAGE__RENDER_WISHLIST_PAGE";
export const WISHLIST_PAGE__SHOW_MESSAGE = "WISHLIST_PAGE__SHOW_MESSAGE";
export const WISHLIST_PAGE__TOGGLE_AGE_RELATED_ADVICE =
  "WISHLIST_PAGE__TOGGLE_AGE_RELATED_ADVICE";

export const FRAGMENTS_PAGE__LOAD_FRAGMENT = "FRAGMENTS_PAGE__LOAD_FRAGMENT";

// generator-marker

// Components
export const REDUCED_LAYOUT_COMPONTENT__LOAD_REDUCED_LAYOUT =
  "REDUCED_LAYOUT_COMPONTENT__LOAD_REDUCED_LAYOUT";

export const GUTSCHEIN_FORM_COMPONENT__LOAD_DATA =
  "GUTSCHEIN_FORM_COMPONENT__LOAD_DATA";
export const GUTSCHEIN_FORM_COMPONENT__ENABLE_FORM =
  "GUTSCHEIN_FORM_COMPONENT__ENABLE_FORM";
export const GUTSCHEIN_FORM_COMPONENT__DISABLE_FORM =
  "GUTSCHEIN_FORM_COMPONENT__DISABLE_FORM";
export const GUTSCHEIN_FORM_COMPONENT__TOGGLE_DISPLAY =
  "GUTSCHEIN_FORM_COMPONENT__TOGGLE_DISPLAY";

export const NEWSLETTER_SUBSCRIPTION_COMPONENT__COMPONENT_NAME =
  "NEWSLETTER_SUBSCRIPTION_COMPONENT__COMPONENT_NAME";
export const NEWSLETTER_SUBSCRIPTION_COMPONENT__CHANGE_CHECKBOX =
  "NEWSLETTER_SUBSCRIPTION_COMPONENT__CHANGE_CHECKBOX";
export const NEWSLETTER_SUBSCRIPTION_COMPONENT__CHANGE_INPUT =
  "NEWSLETTER_SUBSCRIPTION_COMPONENT__CHANGE_INPUT";
export const NEWSLETTER_SUBSCRIPTION_COMPONENT__LOAD_NEWSLETTER_SUBSCRIPTION =
  "NEWSLETTER_SUBSCRIPTION_COMPONENT__LOAD_NEWSLETTER_SUBSCRIPTION";
export const NEWSLETTER_SUBSCRIPTION_COMPONENT__SUBSCRIBE =
  "NEWSLETTER_SUBSCRIPTION_COMPONENT__SUBSCRIBE";
export const NEWSLETTER_SUBSCRIPTION_COMPONENT__SUBSCRIBE_FAILED =
  "NEWSLETTER_SUBSCRIPTION_COMPONENT__SUBSCRIBE_FAILED";
export const NEWSLETTER_SUBSCRIPTION_COMPONENT__VALIDATE =
  "NEWSLETTER_SUBSCRIPTION_COMPONENT__VALIDATE";

// Fragments

export const ADDRESS_SUGGESTIONS_FRAGMENT__LOAD_DATA =
  "ADDRESS_SUGGESTIONS_FRAGMENT__LOAD_DATA";

export const CATALOG_ORDER_FRAGMENT = "CATALOG_ORDER_FRAGMENT";
export const CATALOG_ORDER_FRAGMENT__LOAD_DATA =
  "CATALOG_ORDER_FRAGMENT__LOAD_DATA";
export const CATALOG_ORDER_FRAGMENT__CACHE_FORM_VALUE =
  "CATALOG_ORDER_FRAGMENT__CACHE_FORM_VALUE";
export const CATALOG_ORDER_FRAGMENT__CACHE_FORM_CLEAR =
  "CATALOG_ORDER_FRAGMENT__CACHE_FORM_CLEAR";
export const CATALOG_ORDER_FRAGMENT__ORDER_CATALOGS =
  "CATALOG_ORDER_FRAGMENT__ORDER_CATALOGS";
export const CATALOG_ORDER_FRAGMENT__SHOW_CONFIRMATION =
  "CATALOG_ORDER_FRAGMENT__SHOW_CONFIRMATION";
export const CATALOG_ORDER_FRAGMENT__SHOW_NO_SELECTION_ERROR =
  "CATALOG_ORDER_FRAGMENT__SHOW_NO_SELECTION_ERROR";
export const CATALOG_ORDER_FRAGMENT__UPDATE_FORM =
  "CATALOG_ORDER_FRAGMENT__UPDATE_FORM";

export const EMAIL_CHANGE_FRAGMENT__LOAD_DATA =
  "EMAIL_CHANGE_FRAGMENT__LOAD_DATA";

export const GUTSCHEIN_WERTABFRAGE_FRAGMENT__CHANGE_INPUT =
  "GUTSCHEIN_WERTABFRAGE_FRAGMENT__CHANGE_INPUT";
export const GUTSCHEIN_WERTABFRAGE_FRAGMENT__GET =
  "GUTSCHEIN_WERTABFRAGE_FRAGMENT__GET";
export const GUTSCHEIN_WERTABFRAGE_FRAGMENT__POST =
  "GUTSCHEIN_WERTABFRAGE_FRAGMENT__POST";
export const GUTSCHEIN_WERTABFRAGE_FRAGMENT__POST_SUCCESS =
  "GUTSCHEIN_WERTABFRAGE_FRAGMENT__POST_SUCCESS";
export const GUTSCHEIN_WERTABFRAGE_FRAGMENT__POST_FAILED =
  "GUTSCHEIN_WERTABFRAGE_FRAGMENT__POST_FAILED";
export const GUTSCHEIN_WERTABFRAGE_FRAGMENT__VALIDATE =
  "GUTSCHEIN_WERTABFRAGE_FRAGMENT__VALIDATE";

export const LINE_ITEM_EDIT_FRAGMENT__CHANGE_INPUT =
  "LINE_ITEM_EDIT_FRAGMENT__CHANGE_INPUT";
export const LINE_ITEM_EDIT_FRAGMENT__GET = "LINE_ITEM_EDIT_FRAGMENT__GET";
export const LINE_ITEM_EDIT_FRAGMENT__POST = "LINE_ITEM_EDIT_FRAGMENT__POST";
export const LINE_ITEM_EDIT_FRAGMENT__POST_FAILED =
  "LINE_ITEM_EDIT_FRAGMENT__POST_FAILED";
export const LINE_ITEM_EDIT_FRAGMENT__VALIDATE =
  "LINE_ITEM_EDIT_FRAGMENT__VALIDATE";

export const MINI_ACCOUNT_FRAGMENT__GET = "MINI_ACCOUNT_FRAGMENT__GET";

export const MINI_BASKET_FRAGMENT__LOAD_MINI_BASKET =
  "MINI_BASKET_FRAGMENT__LOAD_MINI_BASKET";
export const MINI_BASKET_FRAGMENT__LOAD_MINI_BASKET_DATA =
  "MINI_BASKET_FRAGMENT__LOAD_MINI_BASKET_DATA";

export const MINI_WISH_LIST_FRAGMENT__GET = "MINI_WISH_LIST_FRAGMENT__GET";

export const NEWSLETTER_SUBSCRIPTION_FRAGMENT__CHANGE_CHECKBOX =
  "NEWSLETTER_SUBSCRIPTION_FRAGMENT__CHANGE_CHECKBOX";
export const NEWSLETTER_SUBSCRIPTION_FRAGMENT__CHANGE_INPUT =
  "NEWSLETTER_SUBSCRIPTION_FRAGMENT__CHANGE_INPUT";
export const LOAD_NEWSLETTER_SUBSCRIPTION_FRAGMENT_STARTED =
  "LOAD_NEWSLETTER_SUBSCRIPTION_FRAGMENT_STARTED";
export const LOAD_NEWSLETTER_SUBSCRIPTION_FRAGMENT_SUCCESS =
  "LOAD_NEWSLETTER_SUBSCRIPTION_FRAGMENT_SUCCESS";
export const NEWSLETTER_SUBSCRIPTION_FRAGMENT__SUBSCRIBE =
  "NEWSLETTER_SUBSCRIPTION_FRAGMENT__SUBSCRIBE";
export const NEWSLETTER_SUBSCRIPTION_FRAGMENT__SUBSCRIBE_FAILED =
  "NEWSLETTER_SUBSCRIPTION_FRAGMENT__SUBSCRIBE_FAILED";
export const NEWSLETTER_SUBSCRIPTION_FRAGMENT__VALIDATE =
  "NEWSLETTER_SUBSCRIPTION_FRAGMENT__VALIDATE";

export const OFF_CANVAS_ITEMS_FRAGMENT__LOAD_DATA =
  "OFF_CANVAS_ITEMS_FRAGMENT__LOAD_DATA";
export const OFF_CANVAS_ITEMS_FRAGMENT__LOAD_MY_ACCOUNT_DATA =
  "OFF_CANVAS_ITEMS_FRAGMENT__LOAD_MY_ACCOUNT_DATA";
export const OFF_CANVAS_ITEMS_FRAGMENT__LOAD_BASKET_DATA =
  "OFF_CANVAS_ITEMS_FRAGMENT__LOAD_BASKET_DATA";

export const ORDER_HISTORY_DETAILS_FRAGMENT__LOAD_DATA_STARTED =
  "ORDER_HISTORY_DETAILS_FRAGMENT__LOAD_DATA_STARTED";
export const ORDER_HISTORY_DETAILS_FRAGMENT__LOAD_DATA_SUCCESS =
  "ORDER_HISTORY_DETAILS_FRAGMENT__LOAD_DATA_SUCCESS";

export const PASSWORD_CHANGE_FRAGMENT__LOAD_DATA =
  "PASSWORD_CHANGE_FRAGMENT__LOAD_DATA";

export const ACCOUNT_DELETION_FRAGMENT__LOAD_DATA =
  "ACCOUNT_DELETION_FRAGMENT__LOAD_DATA";
export const ACCOUNT_DELETION_FRAGMENT__SUBMIT =
  "ACCOUNT_DELETION_FRAGMENT__SUBMIT";
export const ACCOUNT_DELETION_FRAGMENT__SUBMIT_FAILED =
  "ACCOUNT_DELETION_FRAGMENT__SUBMIT_FAILED";

export const SESSION_EXPIRED__LOAD_DATA = "SESSION_EXPIRED__LOAD_DATA";
export const SESSION_EXPIRED__LOAD_DATA_SUCCESSFUL =
  "SESSION_EXPIRED__LOAD_DATA_SUCCESSFUL";

// Form Names
export const formNames = {
  addCatalog: "addCatalog",
  addressAlternative: "addressAlternative",
  billingAddress: "billingAddress",
  catalogOrder: "catalogOrder",
  changeBillingAddress: "changeBillingAddress",
  deliveryAddresses: "deliveryAddresses",
  emailChange: "emailChange",
  gutschein: "gutschein",
  newAddress: "newAddress",
  newDeliveryAddress: "newDeliveryAddress",
  changeDeliveryAddress: "changeDeliveryAddress",
  newPackstation: "newPackstation",
  passwordChange: "passwordChange",
  registerForm: "registerForm",
};
