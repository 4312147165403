// vendor
import { connect } from "react-redux";
import prefetch from "shared/ssrPrefetch/prefetch";
import { getClientsideFetchLocals } from "shared/ssrPrefetch/useFetchLocalsStore";
// ase
import { loadAccountDeletionFragmentData, submitDeletion } from "./actions";
import AccountDeletionFragment from "./components/AccountDeletionFragment";

const redial = {
  fetch: ({ apiClient, dispatch, query }) =>
    dispatch(loadAccountDeletionFragmentData(apiClient, query)),
};

const mapDispatchToProps = (dispatch) => {
  const { apiClient } = getClientsideFetchLocals();
  return {
    submitDeletion: (args) => dispatch(submitDeletion(args, apiClient)),
  };
};

function mapStateToProps(state) {
  return {
    ...state.accountDeletionFragment,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(prefetch(AccountDeletionFragment, redial));
