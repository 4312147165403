/* eslint-disable */

import _get from "lodash.get";
import isPlainObject from "lodash.isplainobject";
import isString from "lodash.isstring";

export function getProperty(object, path, defaultValue) {
  return get(object, path, defaultValue, getProperty.name);
}

export function getPropertyOrArray(object, path) {
  return get(object, path, [], getPropertyOrArray.name);
}

export function getPropertyOrObject(object, path) {
  return get(object, path, {}, getPropertyOrObject.name);
}

export function getPropertyOrString(object, path) {
  return get(object, path, "", getPropertyOrString.name);
}

function get(object = {}, path = "", defaultValue, caller = "unknown") {
  // Expected Object e.g. : { foo: bar }
  if (!isPlainObject(object)) {
    throw new TypeError(
      `Invalid Argument supplied to method "${caller}". First Argument should be an Object. Found: ${typeof object}`
    );
  }
  if (!isString(path)) {
    throw new TypeError(
      `Invalid Argument supplied to method "${caller}". Second Argument should be an Path-String. Found: ${typeof path}`
    );
  }
  return _get(object, path, defaultValue);
}
