import PropTypes from "prop-types";
// vendor
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classNames from "classnames/bind";
// mf-stelar
import EnergyEfficiencyLabel from "stelar/components/EnergyEfficiencyLabel";
// ase
import h from "../../helpers";
import s from "./MiniProductDescription.module.css";

const cx = classNames.bind(s);

const MiniProductDescription = ({
  dimensions,
  energyEfficiencyLabel,
  engraving,
  imgSrc,
  itemStatus,
  manufacturer,
  name,
  nameAdditional,
  netWeight,
  quantity,
  totalPrice,
}) => {
  const hasDimensions = dimensions && dimensions.length > 0;

  function buildDimension(dimension) {
    return (
      <div key={dimension.label} className={s.dimension}>
        <span className={s.key}>{dimension.label}</span>{" "}
        <span
          className={s.val}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: dimension.value }}
        />
      </div>
    );
  }

  const productDimensions = hasDimensions
    ? dimensions.map((dimension) => buildDimension(dimension))
    : null;

  const productQuantity = quantity ? (
    <div>
      <span className={s.key}>{quantity.label}</span>{" "}
      <span className={s.val}>{h.stabilize(quantity.value)}</span>
    </div>
  ) : null;

  const productEngraving = engraving ? (
    <div className={s.productEngraving}>{`„${engraving}“`}</div>
  ) : null;

  const productManufacturer = manufacturer ? (
    <div className={s.manufacturer}>{manufacturer}</div>
  ) : null;

  const productNetWeight = netWeight ? (
    <div className={s.todo}>
      {netWeight.label} {h.stabilize(netWeight.value)}
    </div>
  ) : null;

  const productName = name ? (
    // eslint-disable-next-line react/no-danger
    <span dangerouslySetInnerHTML={{ __html: name }} />
  ) : null;

  const productNameAdditional = nameAdditional ? (
    // eslint-disable-next-line react/no-danger
    <span dangerouslySetInnerHTML={{ __html: ` ${nameAdditional}` }} />
  ) : null;

  const productItemStatus = itemStatus ? (
    <div data-test-ase-article-number>{itemStatus}</div>
  ) : null;

  const productEnergyEfficiencyLabel = energyEfficiencyLabel ? (
    <EnergyEfficiencyLabel
      eeClass={energyEfficiencyLabel.eeClass}
      altText={energyEfficiencyLabel.labelText}
      {...energyEfficiencyLabel.image}
    />
  ) : null;

  // const productUnitPrice = unitPrice ? <Price unitPrice={unitPrice} /> : null;

  const group2 = <div className={s.group2}>{totalPrice}</div>;

  const rootClasses = imgSrc
    ? classNames(cx("root"))
    : classNames(cx("root"), cx("withoutImage"));

  return (
    <div className={rootClasses}>
      <div className={s.group1}>
        {productManufacturer}
        <div className={s.name} data-test-ase-article-name>
          {productName}
          {productNameAdditional}
          {productEnergyEfficiencyLabel}
        </div>
        {productQuantity}
        {productNetWeight}
        {productEngraving}
        {productItemStatus}
        {productDimensions}
      </div>
      {group2}
    </div>
  );
};

MiniProductDescription.propTypes = {
  dimensions: PropTypes.array,
  energyEfficiencyLabel: PropTypes.object,
  engraving: PropTypes.string,
  imgSrc: PropTypes.string,
  itemStatus: PropTypes.string,
  manufacturer: PropTypes.string,
  name: PropTypes.string,
  nameAdditional: PropTypes.string,
  netWeight: PropTypes.object,
  quantity: PropTypes.object,
  totalPrice: PropTypes.string,
};

export default MiniProductDescription;
