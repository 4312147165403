import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies

import Button from "stelar/components/Button/Button";
import LoadingButton from "stelar/components/Button/ButtonLoading";
import ButtonGroup from "stelar/components/ButtonGroup";
import Form from "stelar/components/Form";
import FormRow from "stelar/components/FormRow";
import Message from "stelar/components/Message";
import Text from "stelar/components/Text";
import TextArea from "stelar/components/TextArea";
import s from "./FeedbackBox.module.css";

export default function TextFeedback({
  structure,
  uiState,
  submitText,
  type,
  id,
}) {
  const [textValue, setTextValue] = React.useState("");
  const [textfieldTouched, setTextfieldTouched] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

  const {
    commentHeadline,
    countMaxAllowedChars,
    commentPlaceholderText,
    commentHint,
    maxCharsText,
    errorMessageTitle,
    errorMessageText,
    submitButtonText,
    cancelButtonText,
    successMessageText,
  } = structure;

  if ((uiState?.textSent || showSuccessMessage) && successMessageText) {
    return (
      <div className={s.feedbackContainer}>
        <Message type="success" text={successMessageText} />
      </div>
    );
  }

  return (
    <div className={s.feedbackContainer}>
      {uiState?.unexpectedError && errorMessageTitle && errorMessageText && (
        <Message
          type="error"
          title={errorMessageTitle}
          text={errorMessageText}
        />
      )}
      <Form layout="compact">
        <FormRow>
          <Text em className={s.headline}>
            {commentHeadline}
          </Text>
          <TextArea
            id="comment"
            placeholder={commentPlaceholderText}
            name="comment"
            value={textValue}
            rows={textfieldTouched ? 6 : 2}
            onChange={(e) =>
              setTextValue(e.target.value.slice(0, countMaxAllowedChars))
            }
            onFocus={() => setTextfieldTouched(true)}
          />
          <div className={s.hintContainer}>
            <Text className={s.hintText} small>
              {commentHint}
            </Text>
            <Text className={s.counter} small>
              {textValue?.length}
              {maxCharsText}
            </Text>
          </div>
        </FormRow>
        <FormRow>
          <ButtonGroup
            narrow
            buttonLeft={
              <Button
                prio2
                text={cancelButtonText}
                onClick={(e) => {
                  e.preventDefault();
                  setShowSuccessMessage(true);
                }}
              />
            }
            buttonRight={
              <LoadingButton
                loading={uiState.loading}
                text={submitButtonText}
                onClick={(e) => {
                  e.preventDefault();
                  if (textValue?.length > 0) {
                    submitText({ type, id, text: textValue });
                  } else {
                    setShowSuccessMessage(true);
                  }
                }}
              />
            }
          />
        </FormRow>
      </Form>
    </div>
  );
}

TextFeedback.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  structure: PropTypes.object,
  uiState: PropTypes.object,
  submitText: PropTypes.func,
};
