import React from "react"; // eslint-disable-line import/no-extraneous-dependencies

const CookiesContext = React.createContext<{ serverSideCookies?: string }>({});

export function getCookieString(req) {
  let cookies = req?.headers?.cookie || "";
  if (typeof document !== "undefined") {
    cookies = document?.cookie || "";
  }
  return cookies;
}

type Props = {
  children?: React.ReactNode;
  initialCookies?: string;
};

export function CookiesProvider({ children, initialCookies }: Props) {
  const serverSideCookies = initialCookies;
  const value = React.useMemo(
    () => ({ serverSideCookies }),
    [serverSideCookies]
  );
  return (
    <CookiesContext.Provider value={value}>{children}</CookiesContext.Provider>
  );
}

export function useCookies() {
  const context = React.useContext(CookiesContext);
  if (context === undefined) {
    // eslint-disable-next-line no-console
    console.error("must be used within CookiesProvider");
    return {};
  }
  return context;
}
