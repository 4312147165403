// vendor
import PropTypes from "prop-types";
import React, { useCallback } from "react"; // eslint-disable-line import/no-extraneous-dependencies
// ase
import NewsletterSubscription from "../../../components/NewsletterSubscription";
// component
import ConfirmationAndContact from "./ConfirmationAndContact";
import PrepaymentInfo from "./PrepaymentInfo";
import ContentRowsMapper from "../../../components/ContentRowsMapper";

const CustomContentRowsMapper = ({
  contentRows,
  isLoading,
  submitNewsletter,
}) => {
  const additionalHandlers = {
    ConfirmationAndContact: useCallback(
      (contentRow) => (
        <ConfirmationAndContact
          isLoading={isLoading}
          key="ConfirmationAndContact"
          {...contentRow}
        />
      ),
      [contentRows]
    ),
    PrepaymentInfo: useCallback(
      (contentRow) => <PrepaymentInfo key="PrepaymentInfo" {...contentRow} />,
      [contentRows]
    ),
    NewsletterManagement: useCallback(
      (contentRow) => (
        <NewsletterSubscription
          isLoading={isLoading}
          key="NewsletterSubscription"
          layout="contentRow"
          newsletterSubscription={contentRow}
          submitNewsletter={submitNewsletter}
        />
      ),
      [contentRows]
    ),
  };

  return (
    <ContentRowsMapper
      contentRows={contentRows}
      additionalHandlers={additionalHandlers}
    />
  );
};

CustomContentRowsMapper.propTypes = {
  contentRows: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  submitNewsletter: PropTypes.func.isRequired,
};

export default CustomContentRowsMapper;
