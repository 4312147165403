// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import IconBubbleCheck from "stelar/components/Icon/IconBubbleCheck";
// ase
import overlayTrigger from "../../../util/overlayTrigger";
// component
import s from "./Benefits.module.css";

const benefitItem = (benefit, index) => (
  <li className={s.item} key={index}>
    <span className={s.icon}>
      <IconBubbleCheck large />
    </span>
    <span className={s.text} data-overlay-content-type="contact">
      {benefit}
    </span>
  </li>
);

const benefitList = (list) => (
  <ul className={s.list}>{list.map(benefitItem)}</ul>
);

const Benefits = ({ benefits, link }) => {
  if (!benefits || !benefits.length > 0) return null;

  return link ? (
    <a href={link} className={s.root} onClick={overlayTrigger}>
      {benefitList(benefits)}
    </a>
  ) : (
    <div>{benefitList(benefits)}</div>
  );
};

Benefits.propTypes = {
  benefits: PropTypes.array,
  link: PropTypes.string,
};

export default Benefits;
