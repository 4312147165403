// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import Text from "stelar/components/Text";
// ase
import s from "./CatalogTile.module.css";

const CatalogTile = ({ imageUrl, label }) => (
  <div className={s.root}>
    <img alt={label} className={s.img} src={imageUrl} />
    <div className={s.figcaption}>
      <Text normal>{label}</Text>
    </div>
  </div>
);

CatalogTile.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default CatalogTile;
