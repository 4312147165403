import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import imageSizes from "shared/utils/imageSizes";
import ElementSectionHeading from "../ElementSectionHeading";
import s from "./ElementSingleHeroTile.module.css";
import SingleHeroArticleTile from "../../SingleHeroArticleTile";
import ContentSpacing from "../../ContentSpacing/ContentSpacing";

const cx = classnames.bind(s);

const ElementSingleHeroTile = ({
  heading,
  title,
  description,
  cta,
  article,
  image,
  imagePosition,
  fullWidth,
  lazy,
  onLinkClick,
}) => {
  if (article.length <= 0) {
    return null;
  }

  const imgSizes = imageSizes({
    xxxl: "480px",
    xxl: "33vw",
    xl: fullWidth ? "33vw" : "28vw",
    l: fullWidth ? "41vw" : "37.5vw",
    m: "50vw",
    default: "100vw",
  });

  return (
    <div
      className={cx({ fullWidth, indented: !fullWidth })}
      data-test-sell-element-single-hero-tile
    >
      {heading && (
        <ElementSectionHeading heading={heading} fullWidth={fullWidth} />
      )}
      <ContentSpacing alignment="text" flex fullWidth={fullWidth}>
        <SingleHeroArticleTile
          title={title}
          description={description}
          article={article[0]}
          image={image}
          imagePosition={imagePosition}
          cta={cta}
          imgSizes={imgSizes}
          onLinkClick={onLinkClick}
          fullWidth={fullWidth}
          lazy={lazy}
        />
      </ContentSpacing>
    </div>
  );
};

ElementSingleHeroTile.propTypes = {
  heading: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  cta: PropTypes.object,
  image: PropTypes.object,
  imagePosition: PropTypes.oneOf(["left", "right"]),
  article: PropTypes.array,
  onLinkClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  lazy: PropTypes.bool,
};

export default ElementSingleHeroTile;
