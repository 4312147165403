import CountryFlag from "stelar/components/CountryFlag";
import Headline from "stelar/components/Headline";
import Modal from "stelar/components/Modal";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react"; // eslint-disable-line import/no-extraneous-dependencies

import { connect } from "react-redux";
import prefetch from "shared/ssrPrefetch/prefetch";
import {
  getParsedObjectFromCookie,
  updateCookieValue,
} from "shared/utils/cookie";
import loadLanguageSuggestion from "./actions";

import s from "./LanguageSuggestionFragment.module.css";

export const LANG_SUGGEST_COOKIE = "mf-langsuggest";

const redial = {
  fetch: ({ dispatch, apiClient, cookies }) => {
    if (cookies) {
      dispatch(loadLanguageSuggestion(apiClient));
    }
  },
};

function mapStateToProps(state) {
  return state.languageSuggestionFragment;
}

function updateHideCookie() {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + 90 * 24 * 60 * 60 * 1000); // 90 days
  updateCookieValue(LANG_SUGGEST_COOKIE, { hidden: true }, expirationDate);
}

function LanguageSuggestionFragment({
  headline,
  subheadline,
  closeButtonLabel,
  languageOptions,
}) {
  const [opened, setOpen] = useState(false);

  useEffect(() => {
    if (languageOptions) {
      const cookieValue = getParsedObjectFromCookie(LANG_SUGGEST_COOKIE);
      if (cookieValue?.hidden) {
        updateHideCookie();
      }
      setOpen(!cookieValue?.hidden);
    }
  }, [languageOptions]);

  const onClose = () => {
    setOpen(false);
    updateHideCookie();
  };

  const onSelectLanguage = (isCurrent) => (e) => {
    if (isCurrent) {
      e.preventDefault();
      onClose();
    }
  };

  return (
    languageOptions && (
      <Modal
        opened={opened}
        onClose={onClose}
        closeButtonLabel={closeButtonLabel}
        withoutHeader
      >
        <Headline prio2 headline={headline} />
        <Headline prio3 headline={subheadline} />
        <ul className={s.selection}>
          {languageOptions?.map((languageOption) => (
            <li>
              <a
                href={languageOption.url}
                onClick={onSelectLanguage(languageOption.current)}
              >
                <span className={s.flagContainer}>
                  <CountryFlag country={languageOption.tenantFlagIso2} />
                </span>
                <div>{languageOption.text}</div>
              </a>
            </li>
          ))}
        </ul>
      </Modal>
    )
  );
}

LanguageSuggestionFragment.propTypes = {
  headline: PropTypes.string,
  subheadline: PropTypes.string,
  closeButtonLabel: PropTypes.string,
  languageOptions: PropTypes.array,
};

export default connect(mapStateToProps)(
  prefetch(LanguageSuggestionFragment, redial)
);
