import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import s from "./IconLink.module.css";

const IconLink = ({ link, name, icon, iconRight, onClick }) => {
  let leftIcon = "";
  let rightIcon = "";
  if (iconRight) {
    rightIcon = <span className={s.iconContainer}>{icon}</span>;
  } else {
    leftIcon = <span className={s.iconContainer}>{icon}</span>;
  }
  return (
    <a href={link} onClick={onClick} className={s.iconLink} data-prio2>
      {leftIcon}
      <span className={s.text}>{name}</span>
      {rightIcon}
    </a>
  );
};

IconLink.propTypes = {
  link: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.node,
  iconRight: PropTypes.bool,
  onClick: PropTypes.func,
};

IconLink.defaultProps = {};

export default IconLink;
