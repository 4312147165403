import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import imageSizes from "shared/utils/imageSizes";
import s from "./ElementQuadTeaser.module.css";
import ContentTeasers from "../../Teasers/ContentTeasers";

const cx = classnames.bind(s);

function ElementQuadTeaser({ fullWidth, ...props }) {
  const XXLandUpIndentFactor = fullWidth ? 1 : Number((1 - 2 / 12).toFixed(6));
  const LtoXXLIndentFactor = fullWidth ? 1 : Number((1 - 3 / 12).toFixed(6));
  const imgSizes = imageSizes({
    xxxl: `${350 * XXLandUpIndentFactor}px`,
    xxl: `${25 * XXLandUpIndentFactor}vw`,
    xl: `${25 * LtoXXLIndentFactor}vw`,
    l: fullWidth ? "25vw" : `${50 * LtoXXLIndentFactor}vw`,
    m: "50vw",
    s: "50vw",
    default: "100vw",
  });
  return (
    <div
      className={cx("root", {
        fullWidth,
        indented: !fullWidth,
      })}
      data-test-sell-element-quad-teaser
    >
      <ContentTeasers
        {...props}
        type="four"
        fullWidth={fullWidth}
        imgSizes={imgSizes}
        customStyle="quadTeaser"
      />
    </div>
  );
}

ElementQuadTeaser.propTypes = {
  fullWidth: PropTypes.bool,
};

export default ElementQuadTeaser;
