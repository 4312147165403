import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classNames from "classnames/bind";
import IconLeft from "stelar/components/Icon/IconLeft";
import IconRight from "stelar/components/Icon/IconRight";
import s from "./SliderButton.module.css";

const cx = classNames.bind(s);

function SliderButton({ prev, next, onClick, testSelector }) {
  const classnames = cx("root", { prev, next });
  let icon;
  if (prev) {
    icon = <IconLeft />;
  } else if (next) {
    icon = <IconRight />;
  }

  const testPropButton = testSelector ? { [testSelector]: true } : null;

  return (
    <button
      className={classnames}
      onClick={onClick}
      {...testPropButton}
      type="button"
    >
      <span className={s.background}>
        <span className={s.iconWrapper}>{icon}</span>
      </span>
    </button>
  );
}

SliderButton.propTypes = {
  prev: PropTypes.bool,
  next: PropTypes.bool,
  onClick: PropTypes.func,
  testSelector: PropTypes.string,
};

export default SliderButton;
