import React from "react";
import classNames from "classnames/bind";

import s from "./LoadingSpinner.module.css";

const cx = classNames.bind(s);

type Props = {
  size?: "small" | "medium" | "large";
};

function LoadingSpinner({ size }: Props) {
  const contentSize = size || "medium";
  return (
    <div className={cx("container", contentSize)}>
      <svg className={cx("throbber")} viewBox="25 25 50 50">
        <circle
          className={cx("throbber--circle")}
          cx="50"
          cy="50"
          fill="none"
          r="20"
          strokeMiterlimit="10"
          strokeWidth="3"
        />
      </svg>
    </div>
  );
}

export default LoadingSpinner;
