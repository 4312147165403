const initialState = {
  title: "Ihre Bestellung vom …….…….…………",
  orderDetails: {
    orderNumberLabel: "Auftragsnummer",
    orderNumber: "…………………………",
    deliveryDetails: {
      heading: "Ihre Angaben",
      billingAddress: {
        label: "Rechnungsadresse",
        address: [
          "…………",
          "……………………… ……………",
          "………………-………-……. ……",
          "…………… ………………",
        ],
      },
      deliveryAddress: {
        label: "Lieferadresse",
        address: [
          "…………",
          "……………………… …………………",
          "…… ………………………… …",
          "…………… ………………………",
        ],
      },
      deliveryMethod: {
        label: "Versandart",
        value: "DHL",
      },
      paymentMethod: {
        label: "Zahlungsart",
        value: "Rechnung",
      },
    },
    redemption: null,
    lineItems: [
      {
        name: "…………………………",
        manufacturer: null,
        unitPrice: {
          label: "Einzelpreis",
          value: "…,…… €",
        },
        variant: null,
        size: null,
        articleNumber: {
          label: "Art.Nr.",
          value: "……………",
        },
        pdsLink: "/sku-a_____",
        netWeight: null,
        engraving: null,
        ageRelatedDetails: null,
        basePrice: null,
        deposit: null,
        quantity: {
          label: "Menge",
          value: "…",
        },
        totalPrice: "6,90 €",
        energyEfficiency: null,
        itemStatus: "=Voraussichtlicher Versandtermin: …….…….…………",
        shippingDate: null,
        parcelTracking: {
          label: "Sendung verfolgen",
          link: "http://nolp.dhl.de/ne…tt-online-public/set_identcodes.do?lang=DE&piececode=___",
        },
        parcelTrackingNumber: "……………………………………………………",
      },
      {
        name: "………………-……………………",
        manufacturer: null,
        unitPrice: {
          label: "Einzelpreis",
          value: "…,…… €",
        },
        variant: null,
        size: null,
        articleNumber: {
          label: "Art.Nr.",
          value: "……………",
        },
        pdsLink: "/sku-a_____",
        netWeight: null,
        engraving: null,
        ageRelatedDetails: null,
        basePrice: null,
        deposit: null,
        quantity: {
          label: "Menge",
          value: "…",
        },
        totalPrice: "…,…… €",
        energyEfficiency: null,
        itemStatus: "=Voraussichtlicher Versandtermin: …….…….…………",
        shippingDate: null,
        parcelTracking: {
          label: "Sendung verfolgen",
          link: "http://nolp.dhl.de/ne…tt-online-public/set_identcodes.do?lang=DE&piececode=___",
        },
        parcelTrackingNumber: "……………………………………………………",
      },
    ],
    bill: {
      total: {
        label: "Gesamtsumme",
        value: "……,…… €",
      },
      vatLabel: "inkl. MwSt.",
      lines: [
        {
          label: "Zwischensumme",
          value: "……,…… €",
        },
        {
          label: "Versandkosten",
          value: "…,…… €",
        },
      ],
    },
  },
};

export default initialState;
