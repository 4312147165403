// vendor
import update from "immutability-helper";
// ase
import removeItemsByNamesFromValues from "../../util/reduxFormUtils/removeItemsByNamesFromValues";
import {
  CHANGE_BILLING_ADDRESS_PAGE__CHANGE_BILLING_ADDRESS_FAILED,
  CHANGE_BILLING_ADDRESS_PAGE__CHANGE_BILLING_ADDRESS,
  CHANGE_BILLING_ADDRESS_PAGE__LOAD_CHANGE_BILLING_ADDRESS_PAGE,
  CHANGE_BILLING_ADDRESS_PAGE__UPDATE_FORM,
  CHANGE_BILLING_ADDRESS_PAGE__VALIDATE_INPUT,
} from "../constants";
import { validateInput } from "../utils/Validators";
// component
import initialState from "./initialState";

const changeBillingAddressPage = (state = initialState, action) => {
  let actionPayload;
  let newState;

  switch (action.type) {
    case CHANGE_BILLING_ADDRESS_PAGE__LOAD_CHANGE_BILLING_ADDRESS_PAGE:
      actionPayload = { ...action.payload };
      actionPayload.submitStarted = false;
      // Ist bereits eine structure vorhanden, ignorieren wir die vom BE,
      // weil sonst die Änderungen im Store vom Besucher verloren gehen.
      // (passiert, wenn er history.back o.Ä. benutzt)
      if (
        state.addressForm &&
        state.addressForm.structure &&
        state.addressForm.structure.length
      ) {
        actionPayload.addressForm.structure = state.addressForm.structure;
      }
      return update(state, { $set: actionPayload });
    case CHANGE_BILLING_ADDRESS_PAGE__CHANGE_BILLING_ADDRESS:
      return update(state, {
        submitStarted: { $set: true },
      });
    case CHANGE_BILLING_ADDRESS_PAGE__CHANGE_BILLING_ADDRESS_FAILED:
      if (Array.isArray(action.payload)) {
        return update(state, {
          addressForm: { values: { $set: action.payload } },
          submitStarted: { $set: false },
        });
      }
      return update(state, {
        page: {
          globalMessage: { $set: action.payload.globalMessage },
        },
        addressForm: {
          addressSuggest: { $set: action.payload.addressSuggest },
          message: { $set: action.payload.message },
          values: { $merge: action.payload.values },
        },
        submitStarted: { $set: false },
      });
    case CHANGE_BILLING_ADDRESS_PAGE__VALIDATE_INPUT:
      return validateInput(state, action);
    case CHANGE_BILLING_ADDRESS_PAGE__UPDATE_FORM:
      newState = {
        addressForm: { structure: { $set: action.payload.structure } },
      };
      // remove postalCode value if a country has none
      if (action.payload.valuesToRemove) {
        removeItemsByNamesFromValues(
          action.payload.valuesToRemove,
          state.addressForm.values
        );
      }

      return update(state, newState);

    default:
      return state;
  }
};

export default changeBillingAddressPage;
