// vendor
import update from "immutability-helper";
// ase
import {
  ACCOUNT_DELETION_FRAGMENT__LOAD_DATA,
  ACCOUNT_DELETION_FRAGMENT__SUBMIT,
  ACCOUNT_DELETION_FRAGMENT__SUBMIT_FAILED,
} from "../../pages/constants";

const initialState = {
  formDefinition: {},
  submitStarted: false,
};

function accountDeletionFragment(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_DELETION_FRAGMENT__LOAD_DATA:
      return update(state, { $set: action.payload });
    case ACCOUNT_DELETION_FRAGMENT__SUBMIT:
      return update(state, {
        submitStarted: { $set: true },
      });
    case ACCOUNT_DELETION_FRAGMENT__SUBMIT_FAILED:
      return update(state, {
        formDefinition: { $set: action.payload.formDefinition },
        page: { $set: action.payload?.page },
        submitStarted: { $set: false },
      });

    default:
      return state;
  }
}

export default accountDeletionFragment;
