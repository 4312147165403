import update from "immutability-helper";
import * as types from "./constants";

const initialState = {};

function ssrPrefetched(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.SSR_PREFETCHED: {
      const data = {
        [payload]: true,
      };
      return update(state, { $merge: data });
    }

    case types.SSR_PREFETCH_RESET: {
      const data = {
        [payload]: false,
      };
      return update(state, { $merge: data });
    }

    default:
      return state;
  }
}

export default ssrPrefetched;
