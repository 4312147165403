export const ruleValidation = (field) => ({
  required: field.required ? field.errorEmpty : false,
  pattern: {
    value: new RegExp(field.validation),
    message: field.errorInvalid,
  },
});

export const ruleValidationWhenVisible = (field, isVisible) => {
  if (!isVisible) {
    return {
      required: false,
      pattern: {
        value: /.*/,
      },
    };
  }
  return ruleValidation(field);
};

export const ruleValidationRegistration = (context, field) => {
  const { getValues } = context;
  return {
    validate: {
      ...field?.validation?.map((entry) => (value) => {
        if (!field.required && (!value || value?.length === 0)) {
          return true;
        }
        if (entry.requiredCheckbox && value !== true) {
          return entry.errorMessage;
        }
        if (entry.fieldToMatch) {
          return getValues(entry.fieldToMatch) === value || entry.errorMessage;
        }
        return new RegExp(entry.value).test(value) || entry.errorMessage;
      }),
    },
  };
};
