import { imageLoaded, setTrackingListType } from "../../utils/uiState/actions";
import {
  addToBasket,
  addToNotepad,
  afterChangeCallback,
  changeFreeInput,
  changeVoucherAmount,
  closeAddToCartModal,
  resetRecoDetailpageState,
} from "./actions";
import { getSliderActions } from "../../utils/uiState/sliders/actions";

export default function mapDispatchToProps(dispatch, { apiClient }) {
  return {
    onImageLoad: (src) => dispatch(imageLoaded(src)),
    afterChangeCallback: (event) => dispatch(afterChangeCallback(event)),
    onClickAddToBasket: (
      url,
      params,
      quantity,
      overlay,
      clickedElement,
      googleTrackingData
    ) =>
      dispatch(
        addToBasket(
          apiClient,
          url,
          params,
          quantity,
          overlay,
          clickedElement,
          googleTrackingData
        )
      ),
    onCloseAddToCartModal: () => dispatch(closeAddToCartModal()),
    onClickAddToNotepad: (url, params) =>
      dispatch(addToNotepad(apiClient, url, params)),
    onChangeVoucherAmount: (voucherAmount) =>
      dispatch(changeVoucherAmount(voucherAmount)),
    onChangeFreeInput: (freeInput) => dispatch(changeFreeInput(freeInput)),
    sliderActions: getSliderActions(dispatch),
    setTrackingListType: (trackingData) =>
      dispatch(setTrackingListType(trackingData)),
    resetIsRecoLoaded: () => dispatch(resetRecoDetailpageState()),
  };
}
