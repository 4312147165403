import React from "react";
import classNames from "classnames/bind";

import Text from "../Text";
import s from "./FormRow.module.css";

const cx = classNames.bind(s);

function getError(label, key, skeleton) {
  if (!label.error) return null;
  return !skeleton ? (
    <div
      className={s.errorMessage}
      data-test-stelar-field-error={label.for}
      id={`${label.for}-alert`}
      key={key}
      role="alert"
    >
      {label.error}
    </div>
  ) : (
    <Text key={key} small skeleton>
      {label.error}{" "}
    </Text>
  );
}

function getErrors(labels, skeleton) {
  return labels.map((label, key) => getError(label, key, skeleton));
}

function createLabelTag(label, key, skeleton) {
  if (!label.text) return null;
  return !skeleton ? (
    <label
      className={cx({
        error: label.error,
        light: label.light,
        wrap: label.wrap,
        "grey-200": label["grey-200"],
      })}
      htmlFor={label.for}
      key={key}
    >
      {label.text}{" "}
    </label>
  ) : (
    <Text key={key} normal skeleton>
      {label.text}{" "}
    </Text>
  );
}

function createLabelTags(labels, skeleton) {
  return labels.map((label, key) => createLabelTag(label, key, skeleton));
}

type Props = {
  children?: React.ReactNode;
  inverted?: boolean;
  label?: object;
  labels?: unknown[];
  labelSuffix?: React.ReactNode;
  layout?: "compact" | "fluid" | "standard" | "fixed";
  skeleton?: boolean;
};

function FormRow({
  children,
  inverted,
  skeleton,
  layout = "standard",
  label,
  labels,
  labelSuffix,
}: Props) {
  let errors;
  let labelTags;

  if (label) {
    labelTags = createLabelTag(label, null, skeleton);
    errors = getError(label, null, skeleton);
  }

  if (labels && labels.length > 0) {
    labelTags = createLabelTags(labels, skeleton);
    errors = getErrors(labels, skeleton);
  }

  const labelSuffixTag =
    labelSuffix && skeleton ? (
      <Text small skeleton>
        {labelSuffix}
      </Text>
    ) : (
      labelSuffix
    );

  const formCellLabelContainer =
    layout !== "compact" || labelTags ? (
      <div className={`${s.formCell} ${s.formCellLabels}`}>
        {labelTags}
        {labelSuffixTag}
      </div>
    ) : null;

  return (
    <div className={cx("root", layout, { inverted })}>
      {formCellLabelContainer}
      <div className={`${s.formCell} ${s.formCellInputs}`}>
        <div className={s.justified}>
          {children}
          {errors}
        </div>
      </div>
    </div>
  );
}

FormRow.displayName = "FormRow";

export default FormRow;
