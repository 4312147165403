import React from "react";
import classNames from "classnames/bind";
import s from "./AccordionItem.module.css";

import IconUp from "../Icon/IconUp";
import IconDown from "../Icon/IconDown";
import AccordionButton from "./AccordionButton";

const cx = classNames.bind(s);

type Props = {
  children?: React.ReactNode;
  controlId?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  iconOpen?: React.ReactNode;
  iconPosition?: "left" | "right";
  isInput?: boolean;
  light?: boolean;
  name?: string;
  onClick?: (...args: unknown[]) => unknown;
  open?: boolean;
  skeleton?: boolean;
  smallAccordionItem?: boolean;
  subTitle?: string;
  testStelarAccordionItemId?: string;
  title?: React.ReactNode;
  value?: string;
};

/**
 * AccordionItem
 */

function AccordionItem({
  children,
  controlId,
  disabled,
  icon,
  iconOpen,
  iconPosition,
  isInput,
  light,
  name,
  onClick,
  open,
  skeleton,
  smallAccordionItem,
  subTitle,
  testStelarAccordionItemId,
  title,
  value,
  ...props
}: Props) {
  function renderArrowIcon() {
    let bullet = null;
    if (!open || disabled) {
      bullet = <IconDown small />;
    } else {
      bullet = <IconUp small />;
    }

    const containerClasses = cx("iconContainer", { disabled });
    return <span className={containerClasses}>{bullet}</span>;
  }

  function titleSection() {
    let iconElement = icon || null;
    if (open && iconOpen) {
      iconElement = iconOpen;
    }

    const titleClass = cx("title");
    return (
      <span>
        <span className={titleClass}>
          <span className={cx({ skeleton })}>{title}</span>
          {iconElement && !skeleton && (
            <span className={s.icon}>{iconElement}</span>
          )}
        </span>
        {subTitle && (
          <span
            className={cx("subtitle", {
              open,
            })}
          >
            <span className={cx({ skeleton })}>{subTitle}</span>
          </span>
        )}
      </span>
    );
  }

  const rootClasses = cx("root", {
    smallAccordionItem,
    light,
    [`icon-${iconPosition}`]: true,
  });
  const buttonTabIndex = !disabled ? 0 : -1;
  const contentClasses = cx("content", { open, disabled });
  const controlReference = isInput
    ? `${name}-option-${controlId}`
    : `option-${controlId}`;
  const a11yLabelId = `accordion-item-${testStelarAccordionItemId}`;

  function handleOnClick(event) {
    if (!disabled) {
      onClick(value, event);
    }
  }
  return (
    <div className={rootClasses} {...props}>
      {isInput ? (
        <>
          <input
            id={a11yLabelId}
            checked={open}
            className={cx(s.visuallyHidden)}
            disabled={disabled}
            name={name}
            onClick={handleOnClick}
            type="radio"
            value={value}
          />
          <h3
            className={cx("clickArea", { open, disabled }, "radioHeading")}
            data-test-stelar-accordion-item-id={testStelarAccordionItemId}
          >
            <label htmlFor={a11yLabelId} className={s.label}>
              {titleSection()}
            </label>
          </h3>
        </>
      ) : (
        <h3
          className={cx("clickArea", { open, disabled })}
          data-test-stelar-accordion-item-id={testStelarAccordionItemId}
        >
          <AccordionButton
            onClick={handleOnClick}
            tabIndex={buttonTabIndex}
            icon={renderArrowIcon()}
            title={titleSection()}
            open={open}
            disabled={disabled}
            light={light}
            small={smallAccordionItem}
            iconPosition={iconPosition}
            aria-controls={children ? controlReference : null}
            aria-expanded={open}
            id={a11yLabelId}
          />
        </h3>
      )}
      {children && (
        <div
          aria-hidden={!open}
          className={contentClasses}
          id={children ? controlReference : null}
          role="region"
          aria-labelledby={a11yLabelId}
        >
          {children}
        </div>
      )}
    </div>
  );
}

export default AccordionItem;
