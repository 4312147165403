// vendor
import { connect } from "react-redux";
import { provideHooks } from "redial";
import merge from "lodash.merge";
import loadable from "@loadable/component";

import track from "shared/tracking";
import Loading from "shared/components/Loading";
import changeInputAction from "shared/utils/actionReducer/changeInput/ChangeInputAction";

import frontendUrls from "../utils/AseFrontendUrls";
import { updateFormStructure } from "../../util/reduxFormUtils/updateFormStructure";
import {
  CUSTOMER_ACCOUNT_PAGE__PAGE_NAME,
  CUSTOMER_ACCOUNT_PAGE__UPDATE_FORM,
} from "../constants";
import {
  changeStandard,
  confirmAddressAlternative,
  confirmChangeAddressAlternative,
  deleteAddress,
  doChangeRequest,
  doChooseAddress,
  editAddressAlternative,
  loadCustomerAccount,
  openItem,
  setOverlayOpen,
  submitChangeDeliveryAddress,
  submitNewDeliveryAddress,
  submitSelectedAddress,
  toggleAccordion,
  toggleDeliveryMode,
  unsubscribe,
  unsubscribePostalAdvertise,
} from "./actions";

const page = CUSTOMER_ACCOUNT_PAGE__PAGE_NAME;

const redial = {
  fetch: ({ apiClient, dispatch, query }) =>
    dispatch(loadCustomerAccount(apiClient, query)),
  defer: ({ getState }) =>
    track(getState()?.customerAccountPage?.page?.trackingInfo),
};

const mapDispatchToProps = (dispatch, props) => ({
  changeInput: (path, field) => changeInputAction(dispatch)(page, path, field),
  changeStandard: (params) => dispatch(changeStandard(params, props.apiClient)),
  doUnsubscribe: (event) => dispatch(unsubscribe(event, props.apiClient)),
  doUnsubscribePostalAdvertise: (event) =>
    dispatch(unsubscribePostalAdvertise(event, props.apiClient)),
  toggleAccordion: (itemToOpen) => dispatch(toggleAccordion(itemToOpen)),
  toggleDeliveryMode: (deliveryMode) =>
    dispatch(toggleDeliveryMode(deliveryMode)),
  confirmAddressAlternative: (args) =>
    dispatch(confirmAddressAlternative(args, props.apiClient)),
  confirmChangeAddressAlternative: (args) =>
    dispatch(confirmChangeAddressAlternative(args, props.apiClient)),
  deleteAddress: (args) => dispatch(deleteAddress(args, props.apiClient)),
  editAddressAlternative: (args) =>
    dispatch(editAddressAlternative(args, props.apiClient)),
  openItem: (args) => dispatch(openItem(args)),
  doChooseAddress: (args) => dispatch(doChooseAddress(args, props.apiClient)),
  submitNewDeliveryAddress: (args) =>
    dispatch(submitNewDeliveryAddress(args, props.apiClient)),
  submitSelectedAddress: (args) =>
    dispatch(submitSelectedAddress(args, props.apiClient)),
  submitChangeDeliveryAddress: (args) =>
    dispatch(submitChangeDeliveryAddress(args, props.apiClient)),
  updateForm:
    ({ addressStatePath, formName }) =>
    ({ updateFormURL, formValues, newValues }) =>
      dispatch(
        updateFormStructure(
          {
            addressStatePath,
            formName,
            formValues,
            newValues,
            type: CUSTOMER_ACCOUNT_PAGE__UPDATE_FORM,
            updateFormURL,
          },
          props.apiClient
        )
      ),
  storeValue: (path, field) => changeInputAction(dispatch)(page, path, field),
  handleChangeRequest: (args) =>
    dispatch(doChangeRequest(args, props.apiClient)),
  setOverlayOpen: (args) => dispatch(setOverlayOpen(args)),
});

const mapStateToProps = (state) => ({
  ...state.customerAccountPage,
  isLoading: !state.customerAccountPage.page.headData,
});

const LoadableCustomerAccountPage = loadable(
  () =>
    import(
      /* webpackChunkName: "customeraccount-page" */ "./components/CustomerAccountPage"
    ),
  {
    fallback: Loading,
  }
);

export default {
  component: provideHooks(redial)(
    connect(
      mapStateToProps,
      mapDispatchToProps,
      merge
    )(LoadableCustomerAccountPage)
  ),
  path: frontendUrls.CUSTOMER_ACCOUNT,
};
