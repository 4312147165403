import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies

import ProductTile from "./ProductTile";
import skeletonProductData from "./skeletonData";

const variants = [
  skeletonProductData(1),
  skeletonProductData(2),
  skeletonProductData(3),
  skeletonProductData(4),
];

function SkeletonProductTile({ variant }) {
  return <ProductTile {...variants[variant]} skeleton />;
}

SkeletonProductTile.propTypes = {
  variant: PropTypes.oneOf([0, 1, 2, 3]),
};

export default SkeletonProductTile;
