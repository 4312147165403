/* eslint-disable react/prop-types */
// vendor
import classNames from "classnames/bind";
// mf-stelar
import { InputPassword } from "stelar/components/Input";
// ase
import Text from "stelar/components/Text";
import React, { useCallback } from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { Controller } from "react-hook-form";
import { ruleValidationRegistration } from "../form/ruleGenerator";
import { resolveInputType } from "../inputTypes";
import markdownLinkBuilder from "../markdownLinkBuilder";
import overlayTrigger from "../overlayTrigger";
import { filterEmptyOrNeedlessFormValues } from "./filterEmptyOrNeedlessFormValues";

const reloadForm = (
  event,
  formValues,
  canAlterForm,
  formName,
  updateForm,
  updateFormURL
) => {
  if (updateForm && updateFormURL && canAlterForm) {
    const newValues = {};
    newValues[event.currentTarget.name] = event.currentTarget.value;
    updateForm({
      formValues,
      updateFormURL,
      formName,
      newValues,
    });
  }
};

const trimSpaces = (value) =>
  value && String(value).replace(/\s+/g, " ").trimLeft();

export const errorByValueMapOrForm = (
  element,
  valueMap,
  formErrors,
  dirtyFields
) => {
  const fieldHadClientInteraction =
    dirtyFields &&
    Object.keys(dirtyFields).some((fieldName) => fieldName === element.name);

  if (
    !fieldHadClientInteraction &&
    valueMap &&
    valueMap[element.name] &&
    valueMap[element.name].error
  ) {
    return valueMap[element.name].error;
  }
  return (formErrors && formErrors[element.name]?.message) || "";
};

export const buildFormRow = ({
  row,
  formName,
  updateForm,
  updateFormURL,
  valueMap,
  styles,
  context,
}) => {
  const { control, errors, getValues, dirtyFields } = context;
  return row.map((element) => {
    const typeConfig = resolveInputType(element.type);
    const Component = typeConfig && typeConfig.type;

    const controlledComponent = ({ controllerProps, componentProps }) => (
      <Controller
        key={element.name}
        name={element.name}
        control={control}
        {...controllerProps}
        // eslint-disable-next-line react/jsx-no-bind
        render={({ field }) => {
          const errorMessage = errorByValueMapOrForm(
            element,
            valueMap,
            errors,
            dirtyFields
          );

          // trim outer spaces
          const trimmedOnChange = useCallback(
            (event) => {
              reloadForm(
                event,
                filterEmptyOrNeedlessFormValues({ values: getValues() }),
                element.canAlterForm,
                formName,
                updateForm,
                updateFormURL
              );
              if (typeConfig.type !== InputPassword && !typeConfig.bool) {
                return field.onChange(trimSpaces(event.target.value));
              }
              return field.onChange(event);
            },
            [field]
          );

          if (typeConfig.radio) {
            return (
              <Component
                {...componentProps}
                inline
                radioButtons={element.values}
                name={field.name}
                selectedValue={field.value || element.defaultValue.value}
                onBlur={field.onBlur}
                onChange={trimmedOnChange}
                error={errorMessage}
              />
            );
          }

          if (typeConfig.bool) {
            return (
              <Component
                {...componentProps}
                onBlur={field.onBlur}
                onChange={field.onChange}
                checked={!!field.value}
                ref={null} // ref is only needed for react-hook-form error display, which we don't use
                error={errorMessage}
              />
            );
          }

          let fallbackValue = "";
          if (typeConfig.select) {
            fallbackValue = "0";
          }

          return (
            <Component
              {...componentProps}
              {...field}
              value={field.value || fallbackValue}
              onChange={trimmedOnChange}
              {...(typeConfig.numeric && { inputmode: "decimal" })}
              ref={null} // ref is only needed for react-hook-form error display, which we don't use
              error={errorMessage}
            />
          );
        }}
      />
    );

    const cx = styles && classNames.bind(styles);

    const config = {
      "data-test-ase-field-input": element.name,
      id: element.name,
      key: element.name,
    };

    if (typeConfig && typeConfig.styleOverrideAllowed && cx) {
      config.className = cx(`formfield-${element.name}`);
    }

    if (element.autoComplete) {
      config.autoComplete = element.autoComplete;
    }

    if (element.preventPaste) {
      config.onPaste = (e) => e.preventDefault();
    }

    if (typeConfig && typeConfig.select) {
      // Für den Edge-Case, dass das aktuell ausgewählte Land eigentlich nicht valide ist, wird das aktuelle Land der Liste hinzugefügt.
      const initialValue = valueMap[element.name]
        ? valueMap[element.name].value
        : "";
      const selectOptions = (
        !initialValue || element.options.find((e) => e.id === initialValue)
          ? element.options
          : element.options.concat({
              id: initialValue,
              label: initialValue,
              disabled: true,
              value: initialValue,
            })
      ).map((e) => {
        e.value = e.id;
        return e;
      });

      return controlledComponent({
        controllerProps: {
          rules: ruleValidationRegistration(context, element),
        },
        componentProps: { ...{ selectOptions }, ...config },
      });
    }
    if (typeConfig && typeConfig.bool) {
      return controlledComponent({
        controllerProps: {
          rules: ruleValidationRegistration(context, element),
        },
        componentProps: {
          ...{
            labelClassName: cx && cx(`formfield-${element.name}-label`),
            label: (
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: markdownLinkBuilder(element.label),
                }}
                onClick={overlayTrigger}
                role="presentation"
              />
            ),
            collapseText: element.collapseText,
            expandText: element.expandText,
            autoexpand: true,
          },
          ...config,
        },
      });
    }

    if (typeConfig && typeConfig.radio) {
      return controlledComponent({
        controllerProps: {
          rules: ruleValidationRegistration(context, element),
        },
        componentProps: {
          ...config,
        },
      });
    }

    if (typeConfig && typeConfig.hidden) {
      return controlledComponent({
        controllerProps: {},
        componentProps: { ...{ type: "hidden" }, ...config },
      });
    }

    if (typeConfig && typeConfig.textonly) {
      return (
        <Text key={element.name} normal>
          {element.text}
        </Text>
      );
    }

    if (Component) {
      const missingDefaultValue = {};
      if (getValues(element.name) === undefined) {
        missingDefaultValue.defaultValue = "";
      }
      return controlledComponent({
        controllerProps: {
          rules: ruleValidationRegistration(context, element),
        },
        componentProps: { ...{ placeholder: element.placeholder }, ...config },
      });
    }
    return null;
  });
};
