import queryString from "query-string";

const paramsSerializer = (params) => {
  // eslint-disable-next-line prefer-const
  let filteredParams = Object.entries(params).reduce((prev, [key, value]) => {
    if (typeof value === "object" && value !== null) {
      // eslint-disable-next-line no-param-reassign
      prev[key] = Array.from(new Set(value).values());
    } else {
      // eslint-disable-next-line no-param-reassign
      prev[key] = value;
    }

    return prev;
  }, {});

  return queryString.stringify(filteredParams, { sort: false });
};

export default paramsSerializer;
