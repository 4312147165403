// vendor
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
// mf-stelar
import Heading from "stelar/components/Heading";
import List from "stelar/components/List";
import ListItem from "stelar/components/ListItem";
import Paragraph from "stelar/components/Paragraph";
// ase
import s from "./Confirmation.module.css";
import Address from "../../../components/Address";

const Confirmation = ({
  addressHeader,
  catalogHeader,
  catalogsOrdered,
  deliveryAddressLines,
  infoMessage,
  title,
}) => {
  const address = <Address address={deliveryAddressLines} />;

  const orderedCatalogs = catalogsOrdered.map((catalogName) => (
    <ListItem key={catalogName}>{catalogName}</ListItem>
  ));

  return (
    <div>
      <Heading prio1>{title}</Heading>
      <Paragraph>{infoMessage}</Paragraph>
      <div className={s.summary}>
        <div className={s.catalogs}>
          <Heading prio2>{catalogHeader}</Heading>
          <List bullets>{orderedCatalogs}</List>
        </div>
        <div className={s.address}>
          <Heading prio2>{addressHeader}</Heading>
          {address}
        </div>
      </div>
    </div>
  );
};

Confirmation.propTypes = {
  addressHeader: PropTypes.string,
  catalogHeader: PropTypes.string,
  catalogsOrdered: PropTypes.array,
  deliveryAddressLines: PropTypes.array,
  infoMessage: PropTypes.string,
  title: PropTypes.string,
};

export default Confirmation;
