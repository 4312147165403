// vendor
import { connect } from "react-redux";
import prefetch from "shared/ssrPrefetch/prefetch";
import { getClientsideFetchLocals } from "shared/ssrPrefetch/useFetchLocalsStore";
// component
import GutscheinWertabfrageFragment from "./GutscheinWertabfrageFragment";
import {
  loadGutscheinWertabfrageFragmentData,
  submitForm,
  updateInput,
} from "./actions";

const redial = {
  fetch: ({ apiClient, dispatch }) =>
    dispatch(loadGutscheinWertabfrageFragmentData(apiClient)),
};

const mapDispatchToProps = (dispatch) => {
  const { apiClient } = getClientsideFetchLocals();
  return {
    doUpdateInput: (args) => dispatch(updateInput(args)),
    doSubmitForm: (params) => dispatch(submitForm(params, apiClient)),
  };
};

function mapStateToProps(state) {
  return {
    ...state.gutscheinWertabfrageFragment.data,
    ...state.gutscheinWertabfrageFragment.uiState,
    submitStarted: state.gutscheinWertabfrageFragment.submitStarted,
  };
}

export const stateKey = "ASE_INITIAL_STATE_GUTSCHEIN_WERTABFRAGE_FRAGMENT";

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(prefetch(GutscheinWertabfrageFragment, redial));
