import { createApiClient } from "./baseApiClient";
import { createHttpClient as createAseHttpClient } from "./HttpClient";
import globalDeviceIdentHandler from "./responseHandler/globalDeviceIdentHandler";
import globalTrackingHandler from "./responseHandler/globalTrackingHandler";

const client = {
  pages: ({ apiBase, queryParams = {}, verbose }) =>
    createApiClient(
      createAseHttpClient({
        apiHost: window.location.origin,
        cookies: document.cookie,
        apiBase,
        queryParams,
        isClientCall: true,
        verbose,
      }),
      [globalTrackingHandler, globalDeviceIdentHandler]
    ),
};

export { client };
