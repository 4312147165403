/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconBubbleInfo(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 5.5 22" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path d="M0,0h5.5v4.7H0V0z M0,6.9h5.5V22H0V6.9z" />
    </svg>
  );
}

export default IconBubbleInfo;
