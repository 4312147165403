const notEmptyString = " ";

const initialState = {
  context: "",
  data: {
    heading: "…………………… …………………",
    number: {
      value: notEmptyString,
      label: "………………",
    },
    pin: {
      value: notEmptyString,
      label: "………",
    },
    submitButton: {
      label: "……………………",
    },
  },
  redirectTarget: "",
  uistate: {
    disabled: true,
    displayed: false,
  },
};

export default initialState;
