import React, { useEffect, useState } from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { Button } from "stelar/components/Button";
import Paragraph from "stelar/components/Paragraph";
import { getEnv } from "../../utils/envVariables";
import SkeletonBox from "../SkeletonBox";
import s from "./Loading.module.css";

type Props = {
  error?: boolean;
};

function Loading({ error }: Props) {
  const [newVersionChecked, setNewVersionChecked] = useState(false);
  useEffect(() => {
    async function run() {
      setNewVersionChecked(true);
      const requestedBuildNumber = getEnv("BUILD_NUMBER");
      const request = await fetch(`/checkbn?no=${requestedBuildNumber}`);
      const response = await request.json();
      if (response?.shouldRefresh) {
        // @ts-expect-error
        window.location.reload(true);
      }
    }
    if (error) {
      run();
    }
  }, [error]);

  if (!error) {
    return <SkeletonBox />;
  }

  return (
    newVersionChecked && (
      <SkeletonBox type="page">
        <div className={s.root}>
          <Paragraph>
            Leider ist beim Laden der gewünschten Seite ein Fehler aufgetreten.
            Bitte versuchen Sie es erneut.
          </Paragraph>
          <Paragraph>
            Unfortunately there was an error loading the desired page. Please
            try again.
          </Paragraph>
          <Button
            prio3
            onClick={() => {
              // @ts-expect-error
              window.location.reload(true);
            }}
            text="Erneut versuchen / retry"
          />
        </div>
      </SkeletonBox>
    )
  );
}

export default Loading;
