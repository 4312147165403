// vendor
import { connect } from "react-redux";
import prefetch from "shared/ssrPrefetch/prefetch";
// component
import getFragment from "./actions";
import OrderHistoryDetailsFragment from "./components/OrderHistoryDetailsFragment";

const redial = {
  fetch: ({ apiClient, dispatch, props: { orderKey } }) =>
    dispatch(getFragment(apiClient, orderKey)),
};

function mapStateToProps(state) {
  return {
    ...state.orderHistoryDetailsFragment,
    isLoading: state.orderHistoryDetailsFragment.title.includes("…"),
  };
}

export { attrs, stateKey } from "./components/OrderHistoryDetailsFragment";

export default connect(mapStateToProps)(
  prefetch(OrderHistoryDetailsFragment, redial)
);
