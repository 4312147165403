import update from "immutability-helper";
import {
  LOAD_LAYER_ADDTOCART_SUCCESS,
  LOAD_LAYER_ADDTOCART_STARTED,
} from "../../pages/constants";

const initialState = {
  data: {
    image: {},
    productName: "",
    proceedText: "weiter einkaufen",
    backText: "zurück",
  },
  loading: true,
};

const addToCartFragment = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case LOAD_LAYER_ADDTOCART_STARTED: {
      const articleNumber = payload.sku || "";

      const data = {
        data: initialState.data,
        loading: true,
        sku: articleNumber,
      };

      return update(state, { $set: data });
    }

    case LOAD_LAYER_ADDTOCART_SUCCESS: {
      return update(state, {
        loading: { $set: false },
        data: { $set: payload },
      });
    }

    default:
      return state;
  }
};

export default addToCartFragment;
