import React from "react";
import classNames from "classnames/bind";
import s from "./ButtonGroup.module.css";

const cx = classNames.bind(s);

type Props = {
  buttonLeft?: React.ReactNode;
  buttonRight?: React.ReactNode;
  narrow?: boolean;
  className?: string;
};

function ButtonGroup({ buttonLeft, buttonRight, narrow, className }: Props) {
  let classNamesString = cx("root", {
    recommendedOnly: !buttonLeft,
    narrow,
    fullWidth: !narrow,
  });
  classNamesString = cx(classNamesString, className);

  const recommended = buttonRight ? (
    <div className={s.recommended} data-layout-buttongroup-recommended>
      {buttonRight}
    </div>
  ) : null;

  const alternative = buttonLeft ? (
    <div className={s.alternative} data-layout-buttongroup-alternative>
      {buttonLeft}
    </div>
  ) : null;

  return (
    <div className={classNamesString} data-layout-buttongroup>
      {recommended}
      {alternative}
    </div>
  );
}

export default ButtonGroup;
