/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconMenuDE(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 28 28" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <rect y="13.05" width="27.51" height="1.49" />
      <rect y="19.05" width="27.51" height="1.49" />
      <rect x="0.04" y="25.05" width="27.51" height="1.49" />
      <path
        d="M4.3,9L2.4,3.4h0V9h-1V2.3H3L4.9,7.6h0L6.8,2.3H8.4V9H7.3V3.4h0L5.3,9h-1Z"
        transform="translate(-0.2 -0.5)"
      />
      <path
        d="M13.4,9H9.6V2.3h3.8V3.2H10.7V5h2.5V6H10.7V8.1h2.7V9Z"
        transform="translate(-0.2 -0.5)"
      />
      <path
        d="M20,9H18.7L15.4,3.7h0V4c0,0.6.1,1.1,0.1,1.6V9h-1V2.3h1.3L19,7.6h0V2.3h1V9Z"
        transform="translate(-0.2 -0.5)"
      />
      <path
        d="M26.6,2.3V6.6a2.77,2.77,0,0,1-.3,1.3,2,2,0,0,1-.9.9,3.19,3.19,0,0,1-1.4.3,2.35,2.35,0,0,1-1.9-.7,2.14,2.14,0,0,1-.7-1.8V2.3h1.1V6.5a1.34,1.34,0,0,0,.4,1.2,2.14,2.14,0,0,0,1.2.4,1.43,1.43,0,0,0,1.6-1.6V2.3h0.9Z"
        transform="translate(-0.2 -0.5)"
      />
      <path
        d="M22.5,1A0.52,0.52,0,0,1,22.7.6a0.85,0.85,0,0,1,.8,0,0.48,0.48,0,0,1,.1.4,0.48,0.48,0,0,1-.1.4,0.48,0.48,0,0,1-.4.1,0.6,0.6,0,0,1-.4-0.1A0.34,0.34,0,0,1,22.5,1Zm1.8,0A0.52,0.52,0,0,1,24.5.6a0.85,0.85,0,0,1,.8,0,0.48,0.48,0,0,1,.1.4,0.52,0.52,0,0,1-.2.4,0.48,0.48,0,0,1-.4.1,0.6,0.6,0,0,1-.4-0.1A0.48,0.48,0,0,1,24.3,1Z"
        transform="translate(-0.2 -0.5)"
      />
    </svg>
  );
}

export default IconMenuDE;
