// vendor
import update from "immutability-helper";
// ase
import {
  PASSWORD_CHANGE_FRAGMENT__LOAD_DATA,
  SESSION_EXPIRED__LOAD_DATA,
  SESSION_EXPIRED__LOAD_DATA_SUCCESSFUL,
} from "../../pages/constants";

const initialState = {
  data: {},
  uiState: {
    loginPrompt: false,
  },
};

function handle401And403(state) {
  return update(state, {
    uiState: {
      renderAsSkeleton: { $set: true },
    },
  });
}

function handleSessionExpired(state, data) {
  return update(state, {
    data: { $set: data },
    uiState: {
      loginPrompt: { $set: true },
      renderAsSkeleton: { $set: false },
    },
  });
}

function passwordChangeFragment(state = initialState, action) {
  switch (action.type) {
    // LOAD data
    case SESSION_EXPIRED__LOAD_DATA: {
      return handle401And403(state);
    }
    case SESSION_EXPIRED__LOAD_DATA_SUCCESSFUL: {
      return handleSessionExpired(state, action.payload);
    }
    case PASSWORD_CHANGE_FRAGMENT__LOAD_DATA: {
      return update(state, {
        data: { $set: action.payload },
        uiState: {
          renderAsSkeleton: { $set: false },
        },
      });
    }

    default:
      return state;
  }
}

export default passwordChangeFragment;
