import AdvantageList from "stelar/components/AdvantageList";
import ButtonLoading from "stelar/components/Button/ButtonLoading";
import Form from "stelar/components/Form";
import FormRow from "stelar/components/FormRow";
import InputEmail from "stelar/components/Input/InputEmail";
import ListItem from "stelar/components/ListItem";
import Text from "stelar/components/Text";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { Controller, useForm } from "react-hook-form";
import { ruleValidation } from "../../../util/form/ruleGenerator";
import errorFromServerOrForm from "../../../util/reduxFormUtils/errorFromServerOrForm";

const RegisterContainer = ({
  doRegister,
  isLoading,
  register,
  routes,
  target,
  registerStarted,
  otrToken,
}) => {
  const isMounted = useRef(false);
  const defaultValues = {
    newemail: register?.newmail?.value || "",
  };

  const {
    handleSubmit: handleRegister,
    control,
    reset,
  } = useForm({
    mode: "onTouched",
    defaultValues,
  });

  useEffect(() => {
    if (isMounted.current) {
      reset(defaultValues);
    }
  }, [register]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  const onSubmit = (data) => {
    doRegister({
      routes,
      newemail: data.newemail,
      target,
      otrToken,
    });
  };

  const advantages = register.advantages ? (
    <>
      <Text em>{register.advantagesLabel}</Text>
      <AdvantageList>
        {register.advantages.map((advantage) => (
          <ListItem key={advantage}>{advantage}</ListItem>
        ))}
      </AdvantageList>
    </>
  ) : null;

  return (
    <>
      <Form
        action={register.submitLink.link}
        layout="compact"
        method="post"
        onSubmit={handleRegister(onSubmit)}
      >
        <Controller
          name="newemail"
          control={control}
          rules={ruleValidation(register.newemail)}
          // eslint-disable-next-line react/jsx-no-bind
          render={({ field, fieldState: { isDirty, error } }) => {
            const errorMessage = errorFromServerOrForm({
              field: register.newemail,
              error,
              isDirty,
            });
            return (
              <FormRow
                layout="compact"
                label={{
                  error: errorMessage,
                  for: "register-email",
                  text: register.newemail.label,
                }}
                skeleton={isLoading}
              >
                <input name="_otr" type="hidden" value={otrToken} />
                <input name="target" type="hidden" value={target || "/"} />
                <InputEmail
                  data-test-ase-field-input="register-email"
                  id="register-email"
                  error={errorMessage}
                  {...field}
                  ref={null} // ref is only needed for react-hook-form error display, which we don't use
                />
              </FormRow>
            );
          }}
        />
        <FormRow skeleton={isLoading}>
          <ButtonLoading
            data-test-ase-button
            data-test-ase-register-action
            prio2
            skeleton={isLoading}
            text={register.submitLink.label}
            type="submit"
            loading={registerStarted}
          />
        </FormRow>
      </Form>
      {advantages}
    </>
  );
};

RegisterContainer.propTypes = {
  doRegister: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  register: PropTypes.object.isRequired,
  routes: PropTypes.array,
  target: PropTypes.string,
  registerStarted: PropTypes.bool,
  otrToken: PropTypes.string,
};

export default RegisterContainer;
