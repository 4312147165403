/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconArrowUp(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 15 18" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path d="M13.9,7.1l-6-6C7.8,1,7.7,1,7.5,1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0C7.4,1,7.3,1,7.3,1c0,0-0.1,0-0.1,0.1 c0,0-0.1,0-0.1,0.1l-6,6C0.9,7.3,0.9,7.7,1.1,8s0.6,0.2,0.8,0L6.9,3v13.4c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6V3l5,5 c0.2,0.2,0.6,0.2,0.8,0S14.1,7.3,13.9,7.1z" />
    </svg>
  );
}

export default IconArrowUp;
