import React from "react";
import classNames from "classnames/bind";
import s from "./CategoryHeading.module.css";

type Props = {
  children: React.ReactNode;
  skeleton?: boolean;
  prio0?: boolean;
  prio1?: boolean;
  prio2?: boolean;
  prio3?: boolean;
  prio4?: boolean;
  tag?: string;
};

/**
 * The heading always comes in combination with a heading. Preferably 'Prio0'.
 * All heading priorities can also be used for category headings.
 */
function CategoryHeading({
  children,
  skeleton,
  tag,
  prio0,
  prio1,
  prio2,
  prio3,
  prio4,
  ...attrs
}: Props) {
  const cx = classNames.bind(s);
  const rootClass = cx("root", { skeleton, prio0, prio1, prio2, prio3, prio4 });

  let tagOverride = "p";

  if (tag) {
    tagOverride = tag;
  }

  const node = skeleton ? <span>{children}</span> : children;

  return React.createElement(
    tagOverride,
    { ...attrs, className: rootClass },
    node
  );
}

export default CategoryHeading;
