// vendor
import { connect } from "react-redux";
import { provideHooks } from "redial";
import merge from "lodash.merge";
import track from "shared/tracking";
import loadable from "@loadable/component";
import Loading from "shared/components/Loading";
// ase
import frontendUrls from "../utils/AseFrontendUrls";
import loadReducedLayout from "../../components/ReducedLayout/actions";
import {
  confirmAddressAlternative,
  editAddressAlternative,
  loadRegisterPage,
  submitRegistration,
} from "./actions";
import { REGISTER_PAGE__UPDATE_FORM } from "../constants";
import { updateFormStructure } from "../../util/reduxFormUtils/updateFormStructure";

const redial = {
  fetch: ({ dispatch, apiClient, getState, query }) =>
    Promise.all([
      dispatch(loadRegisterPage(apiClient, query)),
      dispatch(loadReducedLayout(apiClient, getState)),
    ]),
  defer: ({ getState }) => track(getState()?.registerPage?.page?.trackingInfo),
};

const mapDispatchToProps = (dispatch, props) => ({
  confirmAddressAlternative: (args) =>
    dispatch(confirmAddressAlternative(args, props.apiClient)),
  editAddressAlternative: (args) =>
    dispatch(editAddressAlternative(args, props.apiClient)),
  submitForm: (args) => dispatch(submitRegistration(args, props.apiClient)),
  updateForm:
    ({ addressStatePath, formName }) =>
    ({ updateFormURL, formValues, newValues }) => {
      dispatch(
        updateFormStructure(
          {
            addressStatePath,
            formName,
            formValues,
            newValues,
            type: REGISTER_PAGE__UPDATE_FORM,
            updateFormURL,
          },
          props.apiClient
        )
      );
    },
});

const mapStateToProps = (state) => ({
  page: state.registerPage.page,
  reducedLayout: state.reducedLayout,
  registerForm: state.registerPage.registerForm,
  target: state.registerPage.target,
  registerStarted: state.registerPage.registerStarted,
  otrToken: state.httpContext.otrToken,
});

const LoadableRegisterPage = loadable(
  () =>
    import(/* webpackChunkName: "register-page" */ "./components/RegisterPage"),
  {
    fallback: Loading,
  }
);

const props = connect(mapStateToProps, mapDispatchToProps, merge);
export default {
  component: provideHooks(redial)(props(LoadableRegisterPage)),
  path: frontendUrls.REGISTER,
};
