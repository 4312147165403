// vendor
// ase
import backendUrl from "../../pages/utils/AseBackendUrls";

import {
  PASSWORD_CHANGE_FRAGMENT__LOAD_DATA,
  SESSION_EXPIRED__LOAD_DATA,
  SESSION_EXPIRED__LOAD_DATA_SUCCESSFUL,
} from "../../pages/constants";

export function loadSessionExpiredData(apiClient) {
  return apiClient.get(backendUrl.SESSION_EXPIRED, {
    ok: (content) => ({
      payload: content.body,
      type: SESSION_EXPIRED__LOAD_DATA_SUCCESSFUL,
    }),
  });
}

function loadSessionExpired(apiClient) {
  return [
    {
      type: SESSION_EXPIRED__LOAD_DATA,
    },
    loadSessionExpiredData(apiClient),
  ];
}

export function loadPasswordChangeFragmentData(apiClient) {
  return apiClient.get(backendUrl.PASSWORD_CHANGE, {
    ok: (content) => [
      {
        type: PASSWORD_CHANGE_FRAGMENT__LOAD_DATA,
        payload: content.body,
      },
    ],
    forbidden: () => [
      loadSessionExpiredData(apiClient),
      {
        type: SESSION_EXPIRED__LOAD_DATA,
      },
    ],
    unauthorized: () => [
      loadSessionExpiredData(apiClient),
      {
        type: SESSION_EXPIRED__LOAD_DATA,
      },
    ],
  });
}

export function submitChangePassword(values, apiClient) {
  return [
    apiClient.post(backendUrl.PASSWORD_CHANGE, values, {
      ok: (content) => [
        {
          type: PASSWORD_CHANGE_FRAGMENT__LOAD_DATA,
          payload: content.body,
        },
      ],
      badRequest: (content) => [
        {
          type: PASSWORD_CHANGE_FRAGMENT__LOAD_DATA,
          payload: content.body,
        },
      ],
      forbidden: () => [loadSessionExpired(apiClient)],
      unauthorized: () => [loadSessionExpired(apiClient)],
    }),
  ];
}
