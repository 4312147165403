import merge from "lodash.merge";
import { connect } from "react-redux";
import loadable from "@loadable/component";
import { provideHooks } from "redial";

import changeInputAction from "shared/utils/actionReducer/changeInput/ChangeInputAction";
import Loading from "shared/components/Loading";

import {
  addToBasket,
  addToWishList,
  createBasket,
  loadAds,
  selectArticle,
} from "./actions";

const redial = {
  fetch: ({ dispatch, params: { articleNumber, variant }, apiClient, query }) =>
    dispatch(loadAds({ articleNumber, variant, apiClient, query })),
};

const page = "ADS";

const mapDispatchToProps = (dispatch, props) => ({
  addToBasket: (params) => {
    dispatch(addToBasket(params, props.apiClient));
  },
  addToWishList: (params) => {
    dispatch(addToWishList(params, props.apiClient));
  },
  createBasket: (params) => {
    dispatch(createBasket(params, props.apiClient));
  },
  selectArticle: (articleNumber, variant, url) => (event) =>
    dispatch(selectArticle({ event, articleNumber, variant, url })),
  selectedArticle: {
    freeInput: {
      change: changeInputAction(dispatch)(
        page,
        "virtual.selectedArticle",
        "freeInput"
      ),
    },
    quantity: {
      change: changeInputAction(dispatch)(
        page,
        "virtual.selectedArticle",
        "quantity"
      ),
    },
    voucherAmount: {
      change: changeInputAction(dispatch)(
        page,
        "virtual.selectedArticle",
        "voucherAmount"
      ),
    },
    target: {
      change: changeInputAction(dispatch)(
        page,
        "virtual.selectedArticle",
        "target"
      ),
    },
    jsonInput: {
      change: changeInputAction(dispatch)(
        page,
        "virtual.selectedArticle",
        "jsonInput"
      ),
    },
  },
});

const mapStateToProps = (state) => ({
  miniBasket: state.ads.miniBasket,
  articles: state.ads.data.articles,
  selectedArticle: state.ads.virtual.selectedArticle,
  globalMessage: state.ads.virtual.globalMessage,
});

const LoadableAdsPage = loadable(
  () => import(/* webpackChunkName: "ads-page" */ "./components/Ads"),
  {
    fallback: Loading,
  }
);

export default {
  path: "/ads/(:articleNumber(/:variant))",
  component: provideHooks(redial)(
    connect(mapStateToProps, mapDispatchToProps, merge)(LoadableAdsPage)
  ),
};
