function noop() {}

export const animateScrollTo = (
  elementScroll,
  to,
  duration,
  callback,
  handleScroll
) => {
  const element =
    elementScroll || document.scrollingElement || document.documentElement;
  const start = element.scrollTop;
  const change = to - start;
  const startDate = +new Date();

  if (typeof callback !== "function") {
    callback = noop; // eslint-disable-line no-param-reassign
  }
  if (typeof handleScroll !== "function") {
    handleScroll = noop; // eslint-disable-line no-param-reassign
  }
  // t = current time; b = start value; c = change in value; d = duration
  const easeInOutQuad = (t, b, c, d) => {
    let t2 = t;
    t2 /= d / 2;
    if (t2 < 1) {
      callback(null, element);
      handleScroll();
      return (c / 2) * t2 * t2 + b;
    }
    t2 -= 1;
    return (-c / 2) * (t2 * (t2 - 2) - 1) + b;
  };

  const animateScroll = () => {
    const currentDate = +new Date();
    const currentTime = currentDate - startDate;
    element.scrollTop = parseInt(
      easeInOutQuad(currentTime, start, change, duration),
      10
    );
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      element.scrollTop = to;
    }
  };
  animateScroll();
};

export function scrollDocument(y = 0, duration = 750, callback) {
  const { documentElement, body } = window.document;
  const element = body && body.scrollTop ? body : documentElement;

  animateScrollTo(element, y, duration, callback);
}

/*
 * SELL-4298 | we want to reset the scroll areas of facet filters, ex. color
 * if you step on an other category and you manipulate already the filters, they are not on scroll level top
 * we have to use the following solution to solve this bug: https://stackoverflow.com/questions/17047855/scrolltop-scrollleft-do-not-work-on-displaynone-elements
 * */
export function resetScrollPositionInFacetFilter(item, callback) {
  if (typeof window !== "undefined" && window.document) {
    const elementNode = window.document.getElementById(
      `facetTextListId-${item}`
    ); // eslint-disable-line react/no-find-dom-node
    if (elementNode) {
      elementNode.parentElement.replaceChild(elementNode, elementNode);
      // scrollElement(elementNode, 0, 0, callback);
      animateScrollTo(elementNode, 0, 0, callback);
    }
  }
}

export function initScrollPositionInFacetFilter(category) {
  if (category && category.facets && category.facets.facets) {
    category.facets.facets.forEach((item) => {
      resetScrollPositionInFacetFilter(item.name);
    });
  }
}
