// vendor
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
// mf-stelar
import Button from "stelar/components/Button/Button";
import Checkbox from "stelar/components/Checkbox";
import Form from "stelar/components/Form";
import FormRow from "stelar/components/FormRow";
import ContentHeading from "stelar/components/ContentHeading";
import Message from "stelar/components/Message";
import Paragraph from "stelar/components/Paragraph";
import Text from "stelar/components/Text";
import { Input, InputEmail } from "stelar/components/Input";
// ase
import overlayTrigger from "../../../util/overlayTrigger";
import markdown from "../../../util/markdownLinkBuilder";
// component
import s from "./NewsletterSubscription.module.css";

function AwarenessRow({ children }) {
  return <div className={s.awarenessRow}>{children}</div>;
}

AwarenessRow.propTypes = {
  children: PropTypes.object,
};

function NewsletterSubscription({
  changeCheckboxFunc,
  changeInputFunc,
  isLoading,
  newsletterSubscription,
  submitNewsletter,
  successMessage,
}) {
  const { heading, text } = newsletterSubscription;

  const submitEnabled =
    newsletterSubscription.datenschutz.checked &&
    !newsletterSubscription.email.error;

  const buttonRow =
    newsletterSubscription && !successMessage ? (
      <FormRow layout="fluid">
        <Button
          className={s.button}
          data-test-ase-prev-page
          disabled={!submitEnabled}
          prio1
          skeleton={isLoading}
          text={newsletterSubscription.subscribeButton.label || ""}
          type="submit"
        />
      </FormRow>
    ) : null;

  const emailRow = newsletterSubscription.email ? (
    <FormRow
      label={{
        error: newsletterSubscription.email.error,
        text: newsletterSubscription.email.label,
        for: "newsletterSubscriptionEmail",
      }}
      layout="fluid"
      skeleton={isLoading}
    >
      <InputEmail
        data-test-ase-field-input="email"
        defaultValue={newsletterSubscription.email.value}
        id="newsletterSubscriptionEmail"
        name="email"
        onChange={changeInputFunc}
        placeholder={newsletterSubscription.email.label}
        skeleton={isLoading ? {} : null}
      />
    </FormRow>
  ) : null;

  const awarenessHintRow = newsletterSubscription.awarenessHint ? (
    <AwarenessRow>
      <FormRow
        label={{
          text: newsletterSubscription.awarenessHint.label,
          for: "newsletterSubscriptionAwarenesshint",
        }}
        layout="fluid"
        skeleton={isLoading}
      >
        <Input
          data-test-ase-field-input="email"
          defaultValue=""
          id="newsletterSubscriptionAwarenesshint"
          name="awarenessHint"
          placeholder={newsletterSubscription.awarenessHint.label}
          skeleton={isLoading}
        />
      </FormRow>
    </AwarenessRow>
  ) : null;

  const awarenessIdRow = newsletterSubscription.awarenessId ? (
    <AwarenessRow>
      <FormRow
        label={{
          text: newsletterSubscription.awarenessId.label,
          for: "newsletterSubscriptionAwarenessId",
        }}
        layout="fluid"
        skeleton={isLoading}
      >
        <Input
          data-test-ase-field-input="email"
          defaultValue={newsletterSubscription.awarenessId.value}
          id="newsletterSubscriptionAwarenessId"
          name="awarenessId"
          placeholder={newsletterSubscription.awarenessId.label}
          skeleton={isLoading}
          value={newsletterSubscription.awarenessId.value}
          readOnly
          hidden
        />
      </FormRow>
    </AwarenessRow>
  ) : null;

  const datenschutzLabel = newsletterSubscription.datenschutz.label ? (
    <Text
      className={s.privacyLabel}
      onClick={overlayTrigger}
      skeleton={isLoading}
      small
      highContrast
    >
      <span
        // omitting this span causes console warning
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: markdown(newsletterSubscription.datenschutz.label),
        }}
      />
    </Text>
  ) : null;

  const datenschutzRow =
    newsletterSubscription.datenschutz &&
    newsletterSubscription.datenschutz.label ? (
      <FormRow
        label={{
          error: newsletterSubscription.datenschutz.error,
          for: "datenschutz",
        }}
        layout="fluid"
      >
        <Checkbox
          labelClassName={s.collExpLabel}
          data-test-ase-field-input="datenschutz"
          id="datenschutz"
          label={datenschutzLabel}
          onChange={changeCheckboxFunc}
          skeleton={isLoading}
          collapseText={newsletterSubscription.datenschutz.collapseText}
          expandText={newsletterSubscription.datenschutz.expandText}
          autoexpand
        />
      </FormRow>
    ) : null;

  return newsletterSubscription ? (
    <section className={s.root}>
      {heading && (
        <ContentHeading prio1 skeleton={isLoading}>
          {heading}
        </ContentHeading>
      )}
      {text && <Paragraph skeleton={isLoading}>{text}</Paragraph>}
      <Form
        action={newsletterSubscription.subscribeButton.link}
        layout="fluid"
        method="post"
        noValidate
        onSubmit={(e) =>
          submitNewsletter(e, newsletterSubscription.subscribeButton.link, {
            email: newsletterSubscription.email.value,
            datenschutz: newsletterSubscription.datenschutz.checked,
            awarenessId: newsletterSubscription.awarenessId?.value,
            awarenessHint: newsletterSubscription.awarenessHint?.value,
          })
        }
      >
        {emailRow}
        {datenschutzRow}
        {awarenessIdRow}
        {awarenessHintRow}
        {buttonRow}
      </Form>
      {successMessage && <Message text={successMessage.text} type="success" />}
    </section>
  ) : null;
}

NewsletterSubscription.propTypes = {
  changeCheckboxFunc: PropTypes.func,
  changeInputFunc: PropTypes.func,
  isLoading: PropTypes.bool,
  newsletterSubscription: PropTypes.object,
  submitNewsletter: PropTypes.func,
  successMessage: PropTypes.object,
};

export default NewsletterSubscription;
