import { buildCacheKey } from "./helper";

export default function mapStateToProps(state, props) {
  const {
    serverskeleton: serverSkeletonString,
    heading,
    skeleton,
    article,
  } = props;
  const { loadedSliders, sliderPositions } = state.uiState.sliders;
  const sliderState = {
    loadedSliders,
    sliderPositions,
  };

  const ssrPrefetched = state.ssrPrefetched[buildCacheKey(props)];

  return {
    recommendations: state.recommendationsFragment.recommendations,
    article,
    skeleton,
    sliderState,
    serverSkeleton: serverSkeletonString === "true",
    ssrPrefetched,
    ...(heading ? { heading } : {}),
  };
}
