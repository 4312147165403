import update from "immutability-helper";
import { CREDIT_CARD_PAYMENT_PAGE__LOAD_PAGE } from "../constants";

const initialState = {
  page: {
    headData: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREDIT_CARD_PAYMENT_PAGE__LOAD_PAGE:
      if (action.payload) {
        const newState = update(state, { $set: action.payload });
        return newState;
      }
      return state;
    default:
      return state;
  }
};
