import React from "react";

import s from "./MediaPlayer.module.css";

type Props = {
  type?: string;
  audio?: string;
  video?: string;
};

function MediaPlayer({ type, audio, video }: Props) {
  if (audio && video) {
    console.warn("<MediaPlayer> must have only one media type. ");
  } else if (!audio && !video) {
    console.warn(
      "<MediaPlayer> must have one of media types ['audio/mpeg', 'audio/ogg', 'audio/wav', video/mp4', 'video/webm', 'video/ogg]. "
    );
  }

  let mediaTag;

  if (audio) {
    mediaTag = (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <audio className={s.audio} controls controlsList="nodownload">
        <source src={audio} type={type} />
        Your browser does not support this video.
      </audio>
    );
  } else if (video) {
    mediaTag = (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video width="100%" height="100%" controls>
        <source src={video} type={type} />
        Your browser does not support this video.
      </video>
    );
  }

  return (
    <div className={s.root}>
      <div className={s.wrap}>{mediaTag}</div>
    </div>
  );
}

export default MediaPlayer;
