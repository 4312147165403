import loadable from "@loadable/component";
import merge from "lodash.merge";
import { connect } from "react-redux";
import { provideHooks } from "redial";
import track from "shared/tracking";
import Loading from "shared/components/Loading";

import frontendUrls from "../utils/AseFrontendUrls";
import { loadPage } from "./actions";

const redial = {
  fetch: ({ apiClient, dispatch, query }) =>
    dispatch(loadPage(apiClient, query)),
  defer: ({ getState }) =>
    track(getState()?.debitorOverviewPage?.page?.trackingInfo),
};

const mapDispatchToProps = () =>
  // dispatch, props
  ({
    // loadRefundOverlay: () => dispatch(loadRefundOverlay(props.apiClient)),
    // requestRefund: (args) => dispatch(requestRefund(args, props.apiClient)),
  });

const mapStateToProps = (state) => ({
  ...state.debitorOverviewPage,
  isLoading: !state.debitorOverviewPage.page,
});

const LoadableDebitorOverviewPage = loadable(
  () =>
    import(
      /* webpackChunkName: "debitoroverview-page" */ "./components/DebitorOverviewPage"
    ),
  {
    fallback: Loading,
  }
);

export default {
  path: frontendUrls.DEBITOR_OVERVIEW,
  component: provideHooks(redial)(
    connect(
      mapStateToProps,
      mapDispatchToProps,
      merge
    )(LoadableDebitorOverviewPage)
  ),
};
