import track from "shared/tracking";
import { provideHooks } from "redial";
import { connect } from "react-redux";
import loadable from "@loadable/component";
import { initScrollPositionInFacetFilter } from "shared/utils/scrollTo";
import Loading from "shared/components/Loading";
import { loadSearch } from "./actions";
import navigationActions from "../../utils/uiState/navigation/actions";
import {
  imageLoaded,
  toggleFacetFilterDisplay,
  resetFacetFilterDisplay,
  closeOrOpenFacetFilter,
  setTrackingListType,
  resetFacetFilter,
} from "../../utils/uiState/actions";
import { getSliderActions } from "../../utils/uiState/sliders/actions";
import { isQueryAttributesEmpty } from "../../utils/uiState/facetFilter/helper";
import { openCookieBanner } from "../../fragments/sell-cookie-banner/actions";

const { updateCurrent, compactTogglePath } = navigationActions();

const redial = {
  fetch: ({ dispatch, query, apiClient }) => {
    if (isQueryAttributesEmpty(query)) {
      dispatch(resetFacetFilterDisplay()); // reset ui checkboxes for facet filters
      dispatch(resetFacetFilter()); // reset ui accordion for clicked filters
    }
    return dispatch(loadSearch(apiClient, query));
  },
  done: ({ dispatch, getState, query }) => {
    dispatch(updateCurrent());
    track(getState().search.data.tracking);
    const searchData = getState().search.data;
    if (searchData && isQueryAttributesEmpty(query)) {
      initScrollPositionInFacetFilter(searchData);
    }
    dispatch(openCookieBanner());
    dispatch(compactTogglePath());
  },
};

function mapDispatchToProps(dispatch) {
  return {
    onImageLoad: (src) => dispatch(imageLoaded(src)),
    sliderActions: getSliderActions(dispatch),
    toggleListDisplay: (currentState) =>
      dispatch(toggleFacetFilterDisplay(currentState)),
    closeOrOpenFacetFilter: (facetName) =>
      dispatch(closeOrOpenFacetFilter(facetName)),
    setTrackingListType: (trackingData) =>
      dispatch(setTrackingListType(trackingData)),
  };
}

function mapStateToProps(state) {
  const {
    loadedImages,
    sliders,
    initialFacetFilterDisplay,
    closedFacetFilters,
  } = state.uiState;
  const { loadedSliders, sliderPositions } = sliders;
  const { data, loading, uiFeedback, feedbackId, feedbackStructure } =
    state.search;
  const feedbackMetadata = [
    {
      key: "query",
      value: data.facets?.originalQuery ? data.facets?.originalQuery : "empty",
    },
  ];

  return {
    ...data,
    sliderState: {
      loadedSliders,
      sliderPositions,
    },
    loading,
    loadedImages,
    initialFacetFilterDisplay,
    closedFacetFilters,
    uiFeedback,
    feedbackId,
    feedbackStructure,
    feedbackMetadata,
  };
}

const LoadableSearch = loadable(
  () => import(/* webpackChunkName: "search-page" */ "./components/Search"),
  {
    fallback: Loading,
  }
);

export default provideHooks(redial)(
  connect(mapStateToProps, mapDispatchToProps)(LoadableSearch)
);
