import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { Button } from "stelar/components/Button";
import s from "./ElementProductList.module.css";
import ProductList from "../../ProductList";
import ElementSectionHeading from "../ElementSectionHeading/ElementSectionHeading";

const ElementProductList = ({
  headline,
  heading,
  products,
  articles,
  cta,
  fullWidth,
  onLinkClick,
}) => {
  if (
    (!products || products.length === 0) &&
    (!articles || articles.length === 0)
  ) {
    return null;
  }

  const testAttributes = {
    ...(products ? { "data-test-sell-element-productlist": true } : {}),
    ...(articles ? { "data-test-sell-element-articlelist": true } : {}),
  };

  return (
    <div className={s.root} {...testAttributes}>
      {(headline || heading) && (
        <ElementSectionHeading
          heading={headline || heading}
          fullWidth={fullWidth}
        />
      )}
      <ProductList
        products={products || articles}
        fullWidth={fullWidth}
        onLinkClick={onLinkClick}
      />
      {cta && (
        <div className={s.cta}>
          <Button prio3 href={cta.link} title={cta.altText} text={cta.text} />
        </div>
      )}
    </div>
  );
};

ElementProductList.propTypes = {
  headline: PropTypes.string,
  heading: PropTypes.string,
  products: PropTypes.array,
  articles: PropTypes.array,
  cta: PropTypes.object,
  fullWidth: PropTypes.bool,
  onLinkClick: PropTypes.func,
};

export default ElementProductList;
