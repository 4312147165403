/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconBubbleBang(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 8 27.8" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path d="M0,0h8L6.5,19.1H1.5L0,0z M0.4,27.8v-6h7.3v6H0.4z" />
    </svg>
  );
}

export default IconBubbleBang;
