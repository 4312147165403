// vendor
import update from "immutability-helper";
// ase
import frontendUrls from "../../pages/utils/AseFrontendUrls";

import {
  BASKET_PAGE__LOAD_BASKET,
  GUTSCHEIN_FORM_COMPONENT__LOAD_DATA,
  GUTSCHEIN_FORM_COMPONENT__TOGGLE_DISPLAY,
  GUTSCHEIN_FORM_COMPONENT__ENABLE_FORM,
  GUTSCHEIN_FORM_COMPONENT__DISABLE_FORM,
  LAST_CHECK_PAGE__LOAD_LAST_CHECK_PAGE,
} from "../../pages/constants";
// component
import initialState from "./initialState";

const gutscheinForm = (state = initialState, action) => {
  switch (action.type) {
    case BASKET_PAGE__LOAD_BASKET:
      return update(state, {
        context: { $set: "basket" },
        redirectTarget: { $set: `?target=${frontendUrls.BASKET.DE}` }, // FIXME
      });
    case LAST_CHECK_PAGE__LOAD_LAST_CHECK_PAGE:
      return update(state, {
        context: { $set: "lastcheck" },
        redirectTarget: { $set: `?target=${frontendUrls.LAST_CHECK.DE}` }, // FIXME
      });
    case GUTSCHEIN_FORM_COMPONENT__LOAD_DATA:
      return update(state, {
        data: { $set: action.payload },
        uistate: { disabled: { $set: false } },
      });
    case GUTSCHEIN_FORM_COMPONENT__ENABLE_FORM:
      return update(state, {
        uistate: { disabled: { $set: false } },
      });
    case GUTSCHEIN_FORM_COMPONENT__DISABLE_FORM:
      return update(state, {
        uistate: { disabled: { $set: true } },
      });
    case GUTSCHEIN_FORM_COMPONENT__TOGGLE_DISPLAY:
      return update(state, {
        uistate: { displayed: { $apply: (value) => !value } },
      });
    default:
      return state;
  }
};

export default gutscheinForm;
