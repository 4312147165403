import axios from "axios";

/**
 * create new instance of axios with custom configuration
 * ex. SELL: { apiHost: "de-test-shop.mfdp.io, apiBase: "api/sell", ... }
 * @param {Object} config
 * @returns {AxiosInstance}
 */

export default function instanceCreator(config) {
  const instance = axios.create(config);

  // More default attributees for axios
  // https://axios-http.com/docs/config_defaults
  if (config.apiHost?.length > 0) {
    instance.defaults.baseURL = config.apiHost;
  }
  instance.defaults.withCredentials = true;
  return instance;
}
