import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";

function StaticSlider({ children, className, styles }) {
  return (
    <div className={classnames(styles.root, className)}>
      {React.Children.map(children, (Item, key) => {
        const classNamesOld = Item.props.className;
        return React.cloneElement(Item, {
          className: classnames(styles.slide, classNamesOld),
          // eslint-disable-next-line react/no-array-index-key
          key,
        });
      })}
    </div>
  );
}

StaticSlider.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  styles: PropTypes.object,
};

StaticSlider.defaultProps = {
  styles: {},
  className: "",
};

export default StaticSlider;
