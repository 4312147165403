import { validateInput } from "../../pages/utils/Validators";

export function validateNumber(state) {
  const newState = validateInput(state, {
    inputPath: "data.number",
  });
  return newState;
}

export function validatePin(state) {
  const newState = validateInput(state, {
    inputPath: "data.pin",
  });
  return newState;
}
