export const Headers = {
  X_API_BACKEND: "x-api-backend",
  X_MF_CONTENT_LANGUAGE: "x-mf-content-language",
  X_MF_MANDANT: "x-mf-mandant",
  X_MF_LANG: "x-mf-lang",
  X_REAL_IP: "x-real-ip",
  REFERER: "referer",
  X_MF_ORIG_URI: "x-mf-orig-uri",
  USER_AGENT: "user-agent",
  X_MF_DEVICE: "x-mf-device",
  X_XSRF_TOKEN: "x-xsrf-token",
  X_MF_FE_VERSION: "x-mf-fe-version",
  X_MF_FE_UPGRADE: "x-mf-fe-upgrade",
  X_MF_FE_HARD_NAV: "x-mf-fe-hard-nav",
};

export const defaultHeaders = [
  Headers.USER_AGENT,
  Headers.REFERER,
  Headers.X_API_BACKEND,
  Headers.X_REAL_IP,
  Headers.X_XSRF_TOKEN,
];

export const extractPasstroughHeaders = (requestHeaders) => {
  const headerNamesForPassthrough = [...defaultHeaders];
  return Object.entries(requestHeaders).reduce((prev, [header, value]) => {
    if (
      header.toLocaleLowerCase().startsWith("x-mf-") ||
      headerNamesForPassthrough.find(
        (allowedHeader) => allowedHeader === header
      )
    ) {
      // eslint-disable-next-line no-param-reassign
      prev[header] = value;
    }
    return prev;
  }, {});
};
