export { default as Input } from "./Input";
export { default as InputCity } from "./InputCity";
export { default as InputDateDay } from "./InputDateDay";
export { default as InputDateMonth } from "./InputDateMonth";
export { default as InputDateMonthTxt } from "./InputDateMonthTxt";
export { default as InputDateYear } from "./InputDateYear";
export { default as InputEmail } from "./InputEmail";
export { default as InputHouseNumber } from "./InputHouseNumber";
export { default as InputPassword } from "./InputPassword";
export { default as InputStreet } from "./InputStreet";
export { default as InputTel } from "./InputTel";
export { default as InputZip } from "./InputZip";
