import React from "react";
import HeaderIcon from "../HeaderIcon";

const icon = (
  <svg viewBox="0 0 36 36">
    <path d="M22.2 15.5c1.1-1.2 1.7-2.8 1.7-4.6 0-3.7-2.6-6.7-5.8-6.7-3.2-.1-5.8 2.9-5.8 6.6 0 1.9.6 3.5 1.6 4.7-4.3 1.3-7.3 5.2-7.3 8.7 0 3.6 5.1 3.9 11.4 3.9 6.3.1 11.4-.5 11.4-3.9 0-3.5-2.9-7.4-7.2-8.7zm-8.6-4.3c0-2.7 1.9-5.5 4.4-5.5 2.4 0 4.5 2.8 4.5 5.5 0 1.7-.8 3.1-2 4-.5.2-1.1.5-1.1 1.1 0 .2.3.5.4.7l.7 2.5-2.5 1.2-2.3-1.2.6-2.6c0-.1.3-.4.3-.6 0-.5-.6-.9-1-1.1-1.2-.9-2-2.4-2-4zm12 14.9c-.2.1-.8.2-1.3.3l-.1-1.5c0-.2-.2-.4-.5-.4h-.2c-.2 0-.4.2-.4.5l.1 1.6c-1.5.1-3.2.2-5.1.2-1.9 0-4.1-.1-5.8-.2l.1-1.5c.1-.3-.1-.5-.4-.5h-.2c-.3-.1-.5.1-.5.4l-.1 1.5c-.3-.1-.7-.2-.8-.2-1.5-.4-2.6-.9-2.6-2 0-3.2 2.7-6.3 6.3-7.4 0 0 .9-.2 1 0 .1.2-.8 3.5-.8 3.5l3 1.7v2.2c0 .5.2.8.7.8h.1c.5-.1.7-.3.7-.9l-.2-2.1 3.1-1.9s-.8-2.7-.8-3.3c0-.2.9 0 .9 0 3.6 1.1 6.2 4.2 6.3 7.2 0 .8-1 1.6-2.5 2z" />
  </svg>
);

type Props = {
  active?: boolean;
  text?: string;
};

function HeaderAccount(props: Props) {
  return (
    <HeaderIcon
      {...props}
      icon={icon}
      testSelector="data-test-stelar-header-account"
    />
  );
}

export default HeaderAccount;
