import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Text from "stelar/components/Text";
import Price from "stelar/components/Price";
import classnames from "classnames/bind";
import s from "./ProductTileText.module.css";

const cx = classnames.bind(s);

function ProductTileText({
  name,
  manufacturer,
  nameAdditional,
  additionalVariationsText,
  price,
  netWeight,
  skeleton,
  type,
}) {
  return (
    <div className={cx("root", { skeleton })}>
      <div className={s.manufacturer}>
        {manufacturer ? (
          <Text light skeleton={skeleton} data-test-sell-product-manufacturer>
            {manufacturer}
          </Text>
        ) : null}
      </div>
      <div className={cx("name", type)}>
        <Text normal skeleton={skeleton} data-test-sell-product-name>
          {name}
        </Text>
        {nameAdditional ? (
          <Text
            normal
            skeleton={skeleton}
            data-test-sell-product-nameadditional
          >
            {` ${nameAdditional}`}
          </Text>
        ) : null}
      </div>
      {price ? (
        <Price
          price={price.amount}
          oldPrice={price.old}
          unit={price.base}
          additionalInfos={[netWeight, price.pfand]}
          skeleton={skeleton}
        />
      ) : null}
      <div className={s.additionalVariations}>
        {additionalVariationsText ? (
          <Text
            normal
            additionalVariations
            skeleton={skeleton}
            data-test-sell-product-additional-variants
          >
            {additionalVariationsText}
          </Text>
        ) : null}
      </div>
    </div>
  );
}

ProductTileText.propTypes = {
  name: PropTypes.string,
  manufacturer: PropTypes.string,
  nameAdditional: PropTypes.string,
  additionalVariationsText: PropTypes.string,
  price: PropTypes.object,
  netWeight: PropTypes.string,
  skeleton: PropTypes.bool,
  type: PropTypes.string,
};

export default ProductTileText;
